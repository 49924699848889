import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@material-ui/core';
import { Loader } from '../../../components';

import GenericTable, { TableInput } from '../../../components/generictable';
import { range } from '../../../utils/commonFunctions';
import {
  setDataForms,
  changeTab,
  resetDataForms
} from './../../../store/dataForms/actions';
export class DpassFranchisee extends Component {
  componentDidMount() {
    this.props.setDataForms(this.props.location.pathname);
  }

  componentWillUnmount() {
    this.props.resetDataForms();
  }

  renderSection1 = section => {
    const cols = Object.entries(section.columnHeaders);
    const nCols = cols.length;
    return (
      <Table
        data-test='component-table'
        className='data-form-table dpass-franchisee section-0'>
        <TableHead data-test='component-table-head'>
          <TableRow
            className='header-1'
            style={{ borderBottom: '1px solid #d0c9c9' }}>
            <TableCell />
            <TableCell colSpan={4} className='col-1'>
              Service Establishment Information
            </TableCell>
            <TableCell colSpan={5}>Terminal Information</TableCell>
          </TableRow>
          <TableRow data-test='component-table-head-row' className='header-2'>
            <TableCell className='col-0'>{section.sectionName}</TableCell>
            {cols.map(([colId, colName], cIndex) => {
              return (
                <TableCell
                  key={colId}
                  data-test='component-table-head-cell'
                  className={`col-${cIndex + 1}`}>
                  {colName}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody data-test='component-table-body'>
          {Object.entries(section.rowHeaders).map(
            ([rowKey, rowVal], rindex, array) => {
              const isLastRow = rindex === array.length - 1;
              return (
                <TableRow
                  key={rowKey}
                  data-test='component-table-body-row'
                  className={`dataform-row row-${rindex}`}>
                  <td
                    className={`row-label row-${rindex} col-0 ${
                      isLastRow ? 'bold-row-total' : ''
                    }`}
                    aria-label={`row ${rowVal}`}>
                    {rowVal}
                  </td>
                  {range(nCols).map(col => (
                    <td
                      key={col}
                      className={`row-${rindex} col-${col + 1} ${
                        isLastRow ? 'bold-row-total' : ''
                      }`}>
                      <TableInput
                        index={col + rindex * nCols}
                        sectionIndex={0}
                      />
                    </td>
                  ))}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    );
  };

  render() {
    const { forms, activeTab, changeTab } = this.props;
    const form = forms ? forms.tabs[activeTab] : undefined;

    if (!forms || !form.sections) {
      return (
        <Grid
          container
          item
          justify='center'
          alignItems='center'
          data-test='component-franchise'
          data-testid='component-se-sales-volume'>
          <Loader data-test='component-loader' />
        </Grid>
      );
    }

    return (
      <div
        className={`table-wrapper ${
          this.props.location.pathname.split('/')[3]
        } tab-${activeTab}`}
        data-testid='component-se-sales-volume'>
        {forms.tabs.length > 1 && (
          <div className='tabs'>
            {forms.tabs.map((tab, index) => (
              <button
                key={tab.tabIdentifier}
                type='button'
                data-test='DpassFranchisee-button'
                className={index === activeTab ? 'active' : ''}
                onClick={() => changeTab(index)}>
                <span>{tab.tabName}</span>
                {tab.hasError && <span className='error-dot' />}
              </button>
            ))}
          </div>
        )}
        <div className='d-pass-franchise-table'>
          {activeTab === 0 ? (
            this.renderSection1(form.sections[0])
          ) : (
            <GenericTable />
          )}
        </div>
      </div>
    );
  }
}

DpassFranchisee.propTypes = {
  tableName: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    forms: state.dataForms.form,
    activeTab: state.dataForms.activeTab,
    tableName: 'DpassFranchisee'
  };
};

export const mapDispatchToProps = {
  setDataForms,
  changeTab,
  resetDataForms
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DpassFranchisee));
