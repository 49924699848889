import roles, { rolesPrecedence } from '../../roles';

const hasAccessByRole = (role, action) => roles[role].has(action);

const hasAccess = (roles, action, orgId) => {
  for (const role of rolesPrecedence) {
    if (roles && roles[role]) {
      if (hasAccessByRole(role, action)) {
        if (
          !orgId ||
          roles[role].includes(orgId) ||
          roles[role].includes('*')
        ) {
          return true;
        }
      }
    }
  }
  return false;
};

export const getUserAdminAccesses = props => {
  const { role, AdminAccessDetails, orgId } = props;
  const userAccessFeatures = {};
  AdminAccessDetails?.categories?.forEach(tag => {
    userAccessFeatures[tag] = [];
  });
  AdminAccessDetails?.features?.forEach(feature => {
    if (hasAccess(role, feature.accessKey, orgId)) {
      userAccessFeatures[feature.tag].push({ feature });
    }
  });
  return userAccessFeatures;
};

const CanActivate = props => {
  const { children, userRoles, action, orgId, unAuthorizedView } = props;
  if (hasAccess(userRoles, action, orgId)) {
    return children;
  }
  return unAuthorizedView;
};

export default CanActivate;
