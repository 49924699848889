import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { LinkButton } from '../../../components/material';
import { SuccessIcon } from '../../../components/icons';

const Success = props => {
  const { firstName, lastName } = props;
  return (
    <div className='w-100' style={{ alignSelf: 'center' }}>
      <div style={{ marginBottom: 20 }}>
        <SuccessIcon data-test='success-icon' />
      </div>
      <Typography
        data-test='user-greet-text'
        variant='h4'
        color='secondary'
        align='center'>
        Hey! {`${firstName} ${lastName}`}
      </Typography>
      <Typography
        data-test='registration-details-submitted-text'
        align='center'
        style={{
          color: '#68C172',
          marginBottom: 30,
          lineHeight: '30px',
          marginTop: 20,
          fontSize: 20
        }}>
        Your registration details has been submitted successfully to your Local
        Admin(s)
      </Typography>
      <Typography
        data-test='email-notification-text'
        align='center'
        style={{ marginBottom: 30 }}>
        You will receive an email after approval(s).
      </Typography>
      <Grid container justify='center'>
        <LinkButton to='/' color='primary' variant='contained'>
          Go to Login
        </LinkButton>
      </Grid>
    </div>
  );
};

export default Success;
