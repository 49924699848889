import React from 'react';
import { Grid, Button, InputAdornment, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FieldValidations } from '../../components';
import { TextField } from '../../components/material';
import { passwordRequirement } from '../../constants/passwordRule';
const passwordRequirements = passwordRequirement;
const UpdatePasswordForm = props => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handlePasswordChange,
    passwordHidden,
    toggleShowPassword,
    handleCancelPasswordChange,
    touched,
    isValid,
    dirty
  } = props;
  return (
    <form noValidate aria-label='password update form' onSubmit={handleSubmit}>
      <Grid container>
        <Grid item className='password-change-grid-item' md={3}>
          <TextField
            required
            data-test='current-password-input-field'
            name='currentPassword'
            label='Current Password'
            type='password'
            onChange={handleChange}
          />
        </Grid>
        <Grid item className='password-change-grid-item' md={9} />
        <Grid item className='password-change-grid-item' md={3}>
          <TextField
            required
            data-test='new-password-input-field'
            name='password'
            label='New Password'
            type={passwordHidden ? 'password' : 'text'}
            error={Boolean(errors.password && touched.password)}
            value={values.password}
            onChange={handlePasswordChange}
          />
        </Grid>
        <Grid item className='password-change-grid-item' md={3}>
          <TextField
            required
            data-test='conform-password-input-field'
            name='conformPassword'
            label='Confirm New Password'
            type={passwordHidden ? 'password' : 'text'}
            error={Boolean(errors.conformPassword && touched.conformPassword)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    edge='end'
                    onClick={toggleShowPassword}
                    onMouseDown={toggleShowPassword}>
                    {passwordHidden ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            value={values.conformPassword}
            onChange={handlePasswordChange}
          />
        </Grid>
        <Grid
          item
          className='password-change-grid-item profile-password-change-button-container'
          md={6}>
          <Button
            color='primary'
            type='submit'
            size='small'
            variant='contained'
            data-test='update-button'
            disabled={!isValid || !dirty}>
            Update
          </Button>
          <Button
            color='secondary'
            variant='outlined'
            size='small'
            data-test='cancel-button'
            onClick={handleCancelPasswordChange}>
            Cancel
          </Button>
        </Grid>
        <div className='my-profile-password-field-validations'>
          <FieldValidations
            title='Password Requirements:'
            value={values.password}
            validations={passwordRequirements}
            extra={[
              {
                label: 'Password and Confirm password should match',
                valid: Boolean(
                  values.password && values.password === values.conformPassword
                )
              }
            ]}
          />
        </div>
      </Grid>
    </form>
  );
};

UpdatePasswordForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  passwordHidden: PropTypes.bool.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
  handleCancelPasswordChange: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired
};
export default UpdatePasswordForm;
