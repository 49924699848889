import React from 'react';
import { Tooltip, Icon } from '@material-ui/core';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error-icon.svg';
import './tooltip.css';

const ErrorTooltip = ({ message }) => {
  if (!message) {
    return null;
  }
  return (
    <Tooltip
      interactive
      data-test='component-ErrorTooltip'
      title={message}
      placement='right'
      classes={{
        tooltip: 'grid-tooltip-error',
        arrow: 'grid-tooltip-error-arrow'
      }}>
      <Icon>
        <ErrorIcon />
      </Icon>
    </Tooltip>
  );
};

export default ErrorTooltip;
