import { base as theme } from './base';

export const checkbox = {
  MuiCheckbox: {
    root: {
      color: `${theme.palette.input.hover}`,
      backgroundColor: 'transparent',
      marginRight: 7,
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    checked: {
      color: 'transparent !important',
      '&:hover': {
        backgroundColor: 'transparent !important'
      }
    }
  }
};
