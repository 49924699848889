const actions = {
  SET_DATA_FORM: 'SET_DATA_FORM',
  CLEAR_DATA_FORM: 'CLEAR_DATA_FORM',
  CHANGE_TAB: 'CHANGE_TAB',
  CROSS_CHECK: 'CROSS_CHECK',
  UPDATE_FIELD: 'UPDATE_FIELD',
  UPDATE_INTES_FIELD: 'UPDATE_INTES_FIELD',
  UPDATE_ISSUER_FRAUD_FIELD: 'UPDATE_ISSUER_FRAUD_FIELD',
  RESET_DATA_FORMS: 'RESET_DATA_FORMS',
  UPDATE_INTES_DATA: 'UPDATE_INTES_DATA',
  UPDATE_ISSUER_FRAUD_DATA: 'UPDATE_ISSUER_FRAUD_DATA',
  SHOW_AUTO_SAVE: 'SHOW_AUTO_SAVE',
  UPDATE_ROYALTY_FIELD: 'UPDATE_ROYALTY_FIELD',
  DELETE_ROYALTY_FORM_ROW: 'DELETE_ROYALTY_FORM_ROW',
  UPDATE_ROYALTY_FIELD_FROM_API: 'UPDATE_ROYALTY_FIELD_FROM_API',
  UPDATE_ROYALTY_DATA: 'UPDATE_ROYALTY_DATA',
  SHOW_CODE_EXISTS: 'SHOW_CODE_EXISTS',
  SHOW_DESC_EXISTS: 'SHOW_DESC_EXISTS',
  SHOW_FRAUD_EXISTS: 'SHOW_FRAUD_EXISTS',
  DELETE_INTES_FORM_ROW: 'DELETE_INTES_FORM_ROW',
  DELETE_ISSUER_FRAUD_FORM_ROW: 'DELETE_ISSUER_FRAUD_FORM_ROW',
  UPDATE_INTES_FIELD_FROM_API: 'UPDATE_INTES_FIELD_FROM_API'
};

export default actions;
