import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';

import PropTypes from 'prop-types';

const Dropdown = props => {
  const { placeholder, options, value } = props;
  return (
    <TextField
      data-test='component-Dropdown'
      {...props}
      select
      SelectProps={{
        MenuProps: {
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }
      }}>
      {placeholder && value === 'none' && (
        <MenuItem disabled value='none'>
          {placeholder}
        </MenuItem>
      )}
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired
    })
  ),
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any
};

Dropdown.defaultProps = {
  placeholder: '',
  label: '',
  disabled: false,
  required: false,
  error: false,
  options: [],
  value: ''
};

export default Dropdown;
