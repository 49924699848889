import React from 'react';
import { Table, TableContainer, TableBody } from '@material-ui/core';
import FormContainer from './FormContainer';
import TableHeaders from './TableHeaders';
import ProductLineList from './ProductLineList';
import { toastFunc } from '../../../components/ToastComponent/toastFunction';
import * as Yup from 'yup';
import { withFormik } from 'formik';

export const brandingType = [
  {
    value: 'DCI Brand',
    label: 'DCI Brand'
  },
  {
    value: 'Co Brand',
    label: 'Co Brand'
  }
];
const ProductLineTable = props => {
  const {
    data,
    isValid,
    dirty,
    tableHeaders,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    errors,
    touched,
    createProducts,
    getAllProducts
  } = props;
  return (
    <>
      <TableContainer
        className='product-line-table'
        data-test='product-line-table'>
        <Table data-test='product-line-main-table'>
          <TableHeaders
            data-test='product-line-table-header'
            headers={tableHeaders}
          />
          <TableBody data-test='product-line-table-body'>
            <FormContainer
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              resetForm={resetForm}
              branding={brandingType}
              errors={errors}
              touched={touched}
              isValid={isValid}
              dirty={dirty}
            />

            <ProductLineList
              data={data}
              createProducts={createProducts}
              getAllProducts={getAllProducts}
              isValid={isValid}
              dirty={dirty}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const formConfig = withFormik({
  mapPropsToValues: () => ({
    pname: '',
    pcode: '',
    brand: ''
  }),
  validationSchema: Yup.object().shape({
    pname: Yup.string()
      .required('Enter Product Name')
      .matches(/^[aA-zZ\s]+$/, { message: 'Enter Valid Product Name' }),
    pcode: Yup.string()
      .required('Enter Product Code')
      .matches(/(?!^\d+$)^.+$/, { message: 'Only Numbers are not allowed' }),
    brand: Yup.string().required()
  }),
  mapPropsToStatus: () => ({}),
  handleSubmit: (values, formikbag) => {
    let brandCode = '';
    if (values.brand === 'DCI Brand') {
      brandCode = 'D';
    } else if (values.brand === 'Co Brand') {
      brandCode = 'C';
    } else {
      brandCode = '';
    }
    const addedProductLine = {
      cardBrandCode: brandCode,
      cardBrandName: values.brand,
      cardBusinessCode: formikbag.props.productBuisnessCode,
      cardProductBandMapId: 0,
      cardProductName: values.pname,
      productOperationCode: values.pcode
    };
    formikbag.props.createProducts(addedProductLine).then(response => {
      if (response.status === 200) {
        toastFunc({
          content: 'New Product has been added successfully',
          config: { className: 'toast-container accept' },
          toastType: 'success'
        });
        formikbag.resetForm();
        formikbag.props.getAllProducts();
      } else if (response.status === 400) {
        toastFunc({
          content: `${response.data.errorMessage}`,
          config: { className: 'toast-container reject' },
          toastType: 'error'
        });
      } else {
        toastFunc({
          content: 'Something went wrong. Please try again.',
          config: { className: 'toast-container reject' },
          toastType: 'error'
        });
      }
    });
  }
});

export default formConfig(ProductLineTable);
