/* eslint-disable */

import React, { Component } from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import UserPersonalDataSection from './UserPersonalDataSection';
import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ActionBar, LastUpdated, Modal } from '../../../../components';
import { updateUser } from '../../../../store/admin/actions';
import UserUpdateProfileModal from '../../../../components/UserUpdateProfileModal/UserUpdateProfileModal';
import { userDetailsSchema } from '../../../../constants/validations/admin/user';
import { isUserExistsApi } from '../../../../api/auth';
import { createStatusFromErrors } from '../../../../utils/commonFunctions';
import ImpersonateUserButton from '../../../../components/Impersonate/ImpersonateUserButton';
import UserImpersonateModal from '../../../../components/UserImpersonateModal/UserImpersonateModal';
import { impersonateUserApi } from '../../../../api/admin';
import { withRouter } from 'react-router-dom';
import './EditUser.css';
import { toastFunc } from '../../../../components/ToastComponent/toastFunction';
export class FormContainer extends Component {
  state = {
    modalIsOpen: false,
    impersonateModalOpen: false,
    impersonateData: {},
    modalIsInactive: false,
    isFormChanged: false
  };

  preFormSubmit = e => {
    e.preventDefault();
    if (!this.props.isValid) {
      this.props.handleSubmit();
    } else {
      this.openModal();
    }
  };

  formSubmit = () => {
    this.closeModal();
    this.props.handleSubmit();
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  toggleInactiveModal = () => {
    this.setState({ modalIsInactive: !this.state.modalIsInactive });
  };

  openImpersonateModal = e => {
    e.preventDefault();
    this.setState({ impersonateModalOpen: true });
  };

  closeImpersonateModal = e => {
    e.preventDefault();
    this.setState({ impersonateModalOpen: false });
  };

  handleBlur = e => {
    const { handleBlur, status, setStatus, values } = this.props;

    e.persist();
    if (e.target.name === 'userLoginId' && values.userLoginId !== '') {
      const body = {
        userLoginId: values.userLoginId
      };
      isUserExistsApi(body)
        .then(res => {
          if (res) {
            setStatus({
              ...status,
              userLoginId: 'User ID exists. Please enter a different User ID.'
            });
          }
        })
        .catch(err => {
          setStatus(createStatusFromErrors(err));
        });
    }
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };

  impersonateUser = (impersonateUserProfileId, userName) => {
    const body = {
      doImpersonate: true,
      impersonateUserProfileId
    };
    impersonateUserApi(body)
      .then(res => {
        if (res) {
          const impData = {
            isImpersonating: true,
            redirectUrl: this.props.location.pathname,
            userName: userName
          };
          localStorage.setItem('impersonateUserData', JSON.stringify(impData));
          this.props.history.replace('/');
        } else {
          this.props.history.replace('/error');
        }
      })
      .catch(err => {
        this.props.history.replace('/error');
      });
  };
  componentDidMount() {
    const impersonateData = localStorage.getItem('impersonateUserData')
      ? JSON.parse(localStorage.getItem('impersonateUserData'))
      : {};
    if (
      impersonateData &&
      impersonateData.isImpersonating != undefined &&
      !impersonateData.isImpersonating
    ) {
      localStorage.removeItem('impersonateUserData');
    }
  }
  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.values.emailId) !==
        JSON.stringify(this.props.values.emailId) ||
      JSON.stringify(prevProps.values.firstName) !==
        JSON.stringify(this.props.values.firstName) ||
      JSON.stringify(prevProps.values.jobTitle) !==
        JSON.stringify(this.props.values.jobTitle) ||
      JSON.stringify(prevProps.values.lastName) !==
        JSON.stringify(this.props.values.lastName) ||
      JSON.stringify(prevProps.values.prefix) !==
        JSON.stringify(this.props.values.prefix) ||
      JSON.stringify(prevProps.values.middleName) !==
        JSON.stringify(this.props.values.middleName) ||
      JSON.stringify(prevProps.values.lockStatus) !==
        JSON.stringify(this.props.values.lockStatus)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isFormChanged: true });
    }
  }
  toggleHandle = () => {
    const { values, toggleactiveButtonStatus } = this.props;
    if (values.active.length === 1) {
      this.toggleInactiveModal();
    }
    if (values.active.length === 0) {
      toggleactiveButtonStatus(1);
    }
  };
  render() {
    const {
      errors,
      values,
      status,
      handleChange,
      handleReset,
      form,
      touched,
      handleSubmit,
      dirty,
      roleMap,
      impersonateStatus,
      userStatus,
      impersonate,
      toggleactiveButtonStatus,
      activeButtonStatus,
      isValid
    } = this.props;
    const { isFormChanged } = this.state;
    const allActiveOrg =
      form &&
      form.orgRoleProfiles &&
      form.orgRoleProfiles.some(orgRole =>
        orgRole.organizationProfiles.some(org => org.status == 'A')
      );
    return (
      <>
        <Modal
          data-test='audit-report-modal'
          isOpen={this.state.modalIsInactive}
          submitBtnLabel='Submit'
          onCancel={() => {
            toggleactiveButtonStatus(3);
            handleReset();
            this.toggleInactiveModal();
          }}
          onClose={() => {
            toggleactiveButtonStatus(3);
            handleReset();
            this.toggleInactiveModal();
          }}
          onSubmit={() => {
            handleSubmit();
            this.toggleInactiveModal();
            toggleactiveButtonStatus(2);
          }}>
          <> This action will remove user roles assigned</>
        </Modal>
        <form
          noValidate
          aria-label='my profile form'
          style={{ width: '100%' }}
          onReset={handleReset}
          onSubmit={this.preFormSubmit}
          data-test='form-container-component'>
          <Grid
            item
            md={12}
            className='create-user-header-item'
            data-test='create-user-header-item'>
            <ActionBar
              left1={
                <LastUpdated
                  type='modify'
                  user={form.lastModifiedUserBy}
                  time={form.formattedLastModifiedDate}
                />
              }
              left2={
                <LastUpdated
                  type='approve'
                  user={form.registrationApprovedUserBy}
                  time={form.formattedRegistrationApprovedDate}
                />
              }
              right={
                <>
                  <div className='user-edit-action-buttons'>
                    {!roleMap && !impersonate && (
                      <>
                        <Button
                          color='secondary'
                          variant='outlined'
                          type='reset'
                          size='small'
                          className='user-reset-btn'
                          data-test='user-reset-btn'>
                          Reset
                        </Button>
                        <Button
                          color='primary'
                          variant='contained'
                          type='submit'
                          size='small'
                          className='user-update-btn'
                          data-test='user-update-btn'
                          disabled={!dirty || !isValid || !isFormChanged}>
                          Update
                        </Button>
                      </>
                    )}
                    {this.state.impersonateData &&
                    !this.state.impersonateData.isImpersonating &&
                    form.internalUserFlag === 'N' &&
                    form.accountStatus === 'A' &&
                    form.lockStatus === 'F' &&
                    impersonateStatus &&
                    !form.loggedInUser ? (
                      <ImpersonateUserButton
                        onSubmit={this.openImpersonateModal}
                        className={'user-impersonate-button'}
                      />
                    ) : null}
                  </div>
                </>
              }
            />
          </Grid>
          <Grid
            item
            md={12}
            className='create-user-body-item user-personal-data'
            data-test='user-personal-data'>
            <UserPersonalDataSection
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              user={form}
              status={status}
              roleMap={roleMap}
              handleBlur={this.handleBlur}
              data-test='User-Personal-Data-Section'
              toggleHandle={this.toggleHandle}
              userStatus={userStatus}
              impersonate={impersonate}
            />
          </Grid>
          <UserUpdateProfileModal
            isOpen={this.state.modalIsOpen}
            userProfileId={form.userProfileId}
            userLoginId={form.userLoginId}
            onConfirm={this.formSubmit}
            activeButtonStatus={activeButtonStatus}
            allActiveOrg={allActiveOrg}
            onCancel={this.closeModal}
            data-test='User-update-profile-modal'
          />
          <UserImpersonateModal
            isOpen={this.state.impersonateModalOpen}
            userProfileId={form.userProfileId}
            userName={form.firstName + ' ' + form.lastName}
            onConfirm={this.impersonateUser}
            onCancel={this.closeImpersonateModal}
            data-test='User-Impersonate-modal'
          />
        </form>
      </>
    );
  }
}

const formConfig = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ form }) => {
    return {
      userLoginId: form && form.userLoginId ? form.userLoginId : '',
      internalUserFlag: 'false',
      salutation: form && form.prefix ? form.prefix : 'none',
      firstName: form && form.firstName ? form.firstName : '',
      middleName: form && form.middleName ? form.middleName : '',
      lastName: form && form.lastName ? form.lastName : '',
      jobTitle: form && form.jobTitle ? form.jobTitle : '',
      emailId: form && form.emailId ? form.emailId : '',
      active: form && form.accountStatus === 'A' ? ['active'] : [],
      lockStatus: form && form.lockStatus === 'T' ? ['active'] : [],
      loggedInUser: form && form.loggedInUser ? form.loggedInUser : false
    };
  },
  mapPropsToStatus: () => ({}),
  validationSchema: userDetailsSchema,
  validateOnMount: true,
  handleSubmit: (values, formikBag) => {
    const user = { ...values };
    const UserDetails = Object.fromEntries(
      Object.entries(user).map(([k, v]) =>
        k === 'salutation' ? ['prefix', v] : [k, v]
      )
    );
    UserDetails.lockStatus = values.lockStatus.length ? 'T' : 'F';
    UserDetails.accountStatus = values.active.length ? 'A' : 'I';
    delete UserDetails.active;
    UserDetails.usrProfileId = formikBag.props.form.userProfileId;
    UserDetails.usrLoginId = UserDetails.userLoginId;
    delete UserDetails.userLoginId;
    return formikBag.props.updateUser(UserDetails).then(res => {
      if (res.data === true) {
        toastFunc({
          content: 'Profile has been Updated Successfully',
          config: { className: 'toast-container accept' }
        });
      } else {
        const err = {};
        err.response = res;
        formikBag.setStatus(createStatusFromErrors(err));
      }
    });
  }
});

FormContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
  updateUser
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(formConfig(FormContainer)));
