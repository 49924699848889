import React, { Component } from 'react';
import { TableRow, TableCell, Typography, Chip, Grid } from '@material-ui/core';
import { MultipleCombobox } from '../../../components/material';
import ErrorTooltip from '../../../components/formik/ErrorTooltip';
import { productLineUserRestrict } from '../../../constants/roles';
class ProductLinesRow extends Component {
  onCommercialSelectAll = organizationProfileId => {
    const commercialProducts = this.props
      .transform(this.props.org, 'CM')
      .map(com => com.id);
    let commercial = this.props.products.commercial.filter(
      product => !commercialProducts.includes(product.id)
    );
    commercial = commercial.filter(({ id }) => id !== 0);
    const e = { target: { value: commercial } };
    this.props.onCommercialChange(e, organizationProfileId);
  };

  onCommercialUnselectAll = organizationProfileId => {
    this.props.unSelectProductsAll(organizationProfileId, 'CM');
  };

  onConsumerSelectAll = organizationProfileId => {
    const consumerProducts = this.props
      .transform(this.props.org, 'CN')
      .map(con => con.id);
    let consumer = this.props.products.consumer.filter(
      product => !consumerProducts.includes(product.id)
    );
    consumer = consumer.filter(({ id }) => id !== 0);
    const e = { target: { value: consumer } };
    this.props.onConsumerChange(e, organizationProfileId);
  };

  onConsumerUnselectAll = organizationProfileId => {
    this.props.unSelectProductsAll(organizationProfileId, 'CN');
  };

  render() {
    const {
      org,
      readOnly,
      onConsumerChange,
      onCommercialChange,
      transform,
      products,
      onProductRemoved,
      userRoles,
      highestRole
    } = this.props;
    const isDataEntry = productLineUserRestrict.includes(highestRole);
    const commercial = transform(org, 'CM');
    const consumer = transform(org, 'CN');
    const commercialIds = commercial.map(com => com.id);
    const consumerIds = consumer.map(con => con.id);
    const error = ![...commercialIds, ...consumerIds].length;
    const errorMsg = error ? true : undefined;
    const orgType = org ? org.orgTypeInfo.orgTypNm : '';
    return (
      <TableRow data-test='product-lines-table-row-component'>
        <TableCell
          style={{ verticalAlign: 'top' }}
          data-test='product-lines-table-cell'>
          <Typography
            variant='body2'
            color='secondary'
            display='inline'
            data-test='product-lines-table-Typography'>
            {org.organizationProfileName}{' '}
          </Typography>
          {errorMsg && orgType !== 'Acquirer' && (
            <span
              style={{ marginLeft: 5, position: 'relative', top: 5 }}
              data-test='product-lines-table-span-error'>
              <ErrorTooltip message='Please select at least one product' />
            </span>
          )}
        </TableCell>
        <TableCell
          style={{ verticalAlign: 'top' }}
          data-test='product-lines-table-TableCell-com'>
          {!isDataEntry ? (
            <>
              {(!readOnly || userRoles === false) && (
                <MultipleCombobox
                  orgProductLineDataStatus
                  label='Select/Type'
                  data-test='product-lines-multocombobox-TableCell-com'
                  options={products.commercial.filter(
                    product => !commercialIds.includes(product.id)
                  )}
                  value={[]}
                  onChange={e =>
                    onCommercialChange(e, org.organizationProfileId)
                  }
                  onSelectAll={() =>
                    this.onCommercialSelectAll(org.organizationProfileId)
                  }
                  onUnselectAll={() =>
                    this.onCommercialUnselectAll(org.organizationProfileId)
                  }
                />
              )}
              {commercial.length > 0 &&
                commercial.map(com => (
                  <Chip
                    key={com.id}
                    label={`${com.label} - ${com.brandName}`}
                    style={{ margin: 5 }}
                    onDelete={
                      readOnly && userRoles !== false
                        ? undefined
                        : () => onProductRemoved(com, org.organizationProfileId)
                    }
                  />
                ))}
            </>
          ) : (
            <>
              {commercial.length > 0 &&
                commercial.map(com => (
                  <Chip
                    key={com.id}
                    label={`${com.label} - ${com.brandName}`}
                    style={{ margin: 5 }}
                  />
                ))}
            </>
          )}
        </TableCell>
        <TableCell
          style={{ verticalAlign: 'top' }}
          data-test='product-lines-table-TableCell-con'>
          {!isDataEntry ? (
            <>
              {(!readOnly || userRoles === false) && (
                <MultipleCombobox
                  orgProductLineDataStatus
                  data-test='product-lines-multocombobox-TableCell-con'
                  label='Select/Type'
                  options={products.consumer.filter(
                    product => !consumerIds.includes(product.id)
                  )}
                  value={[]}
                  onChange={e => onConsumerChange(e, org.organizationProfileId)}
                  onSelectAll={() =>
                    this.onConsumerSelectAll(org.organizationProfileId)
                  }
                  onUnselectAll={() =>
                    this.onConsumerUnselectAll(org.organizationProfileId)
                  }
                />
              )}
              {consumer.length > 0 &&
                consumer.map(con => (
                  <Chip
                    key={con.id}
                    label={`${con.label} - ${con.brandName}`}
                    style={{ margin: 5 }}
                    onDelete={
                      readOnly && userRoles !== false
                        ? undefined
                        : () => onProductRemoved(con, org.organizationProfileId)
                    }
                  />
                ))}
            </>
          ) : (
            <>
              {' '}
              {consumer.length > 0 &&
                consumer.map(con => (
                  <Chip
                    key={con.id}
                    label={`${con.label} - ${con.brandName}`}
                    style={{ margin: 5 }}
                  />
                ))}
            </>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

export default ProductLinesRow;
