import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const AuditReportHeader = () => (
  <Grid
    item
    md={12}
    className='audit-report-header-container'
    data-test='audit-report-header-container'>
    <Grid
      container
      justify='space-between'
      data-test='audit-report-inner-title'
      alignItems='center'>
      <Typography
        variant='h6'
        className='audit-report-header-title'
        data-test='audit-report-header-title'>
        Audit Report
      </Typography>
    </Grid>
  </Grid>
);

export default AuditReportHeader;
