import React from 'react';
import { Grid } from '@material-ui/core';
import BrowseFile from './BrowseFile';
import PropTypes from 'prop-types';
import FileUploading from './FileUploading';
import UploadSuccess from './UploadSuccess';
import UploadError from './UploadError';
const FileUpload = props => {
  return (
    <Grid
      container
      role='region'
      aria-labelledby='file-upload-div-caption'
      className='file-upload-main'
      data-test='file-upload-main'>
      <Grid
        item
        md={12}
        className='upload-main-item'
        data-test='upload-main-item'>
        <div className='upload-content' data-test='upload-content'>
          {props.phase === 'initial' && (
            <BrowseFile
              fileSelectHandler={props.fileSelectHandler}
              data-test='browse-file'
            />
          )}
          {props.phase === 'uploading' && (
            <FileUploading
              fileName={props.fileName}
              fileSize={props.fileSize}
              cancelHandler={props.cancelHandler}
              successHandler={props.successHandler}
              data-test='file-uploading'
            />
          )}
          {props.phase === 'success' && (
            <UploadSuccess
              fileName={props.fileName}
              fileSize={props.fileSize}
              fileSelectHandler={props.fileSelectHandler}
              successMessage={props.successMessage}
              data-test='upload-success'
            />
          )}
          {props.phase === 'error' && (
            <UploadError
              fileSelectHandler={props.fileSelectHandler}
              errorMessage={props.errorMessage}
              data-test='upload-error'
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

FileUpload.propTypes = {
  phase: PropTypes.string.isRequired,
  fileSelectHandler: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  successHandler: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired
};

export default FileUpload;
