import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import { slugToIDs } from '../../../constants/dataForms';
import { COMMON_TEXT } from '../../../constants/text';
import DisplayFormsList from './DisplayFormsList';
import DisplayQuaterForms from './DisplayQuaterForms';
import DisplayAnnualForms from './DisplayAnnualForms';

const DisplayForms = props => {
  const {
    forms,
    onClose,
    form,
    setDataForms,
    resetDataForms,
    formType
  } = props;
  const monthlyForms = 'monthlyDataForms';
  const quarterlyForms = 'quarterlyDataForms';
  const annuallyForms = 'annuallyDataForms';
  const handler = formName => {
    if (form && formName === form.formName) {
      onClose(COMMON_TEXT.manageData);
      resetDataForms();
      setDataForms(slugToIDs[form.formId]);
    } else {
      onClose(COMMON_TEXT.manageData);
    }
  };

  if (formType === monthlyForms && Boolean(forms.length)) {
    return (
      <Grid
        item
        md
        className='data-entry-form-container'
        data-test='data-forms-container'>
        <DisplayFormsList
          data-test='forms-list-container'
          forms={forms}
          handler={handler}
        />
      </Grid>
    );
  }

  if (formType === quarterlyForms && Boolean(forms.length)) {
    return (
      <DisplayQuaterForms
        data-test='quaterly-forms-container'
        forms={forms}
        handler={handler}
      />
    );
  }

  if (formType === annuallyForms && Boolean(forms.length)) {
    return (
      <DisplayAnnualForms
        data-test='annually-forms-container'
        forms={forms}
        handler={handler}
      />
    );
  }

  return (
    <Grid item md>
      <Grid
        container
        alignItems='center'
        justify='center'
        className='data-entry-forms-empty-container'
        data-test='data-forms-empty-container'>
        <div className='data-entry-forms-empty'>
          <WarningIcon className='megamenu-warning-icon' />
          <Typography color='secondary' variant='body2' display='inline'>
            No Data Found
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

DisplayForms.propTypes = {
  forms: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DisplayForms;
