import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import { Combobox, Dropdown } from '../../../../components/material';
import { withFormik } from 'formik';
import { isEqual } from 'lodash';

export const SearchForm = props => {
  const {
    handleChange,
    handleSubmit,
    resetForm,
    organizations,
    regions,
    countries,
    types,
    clearButton,
    defaultData,
    isFormSubmitted,
    getSearchedOrganizationProfile
  } = props;
  let { values, dirty, searchedOrganization } = props;

  const typesObject = types.map(type => ({
    label: type.label,
    value: type.id
  }));

  const clearHandeler = () => {
    getSearchedOrganizationProfile({});
    resetForm();
    clearButton();
  };

  if (
    searchedOrganization != undefined &&
    Object.keys(searchedOrganization).length > 0 &&
    JSON.stringify(values) != JSON.stringify(searchedOrganization)
  ) {
    dirty = true;
  }

  if (
    searchedOrganization != undefined &&
    Object.keys(searchedOrganization).length > 0 &&
    isEqual(values, {
      organizationName: {
        value: '',
        label: ''
      },
      organizationRegion: '',
      organizationTerritory: {
        value: '',
        label: ''
      },
      organizationType: ''
    })
  ) {
    values = searchedOrganization;
  }
  useEffect(() => {
    if (defaultData || !isFormSubmitted) {
      clearHandeler();
    }
  }, [defaultData, isFormSubmitted]);
  return (
    <form
      noValidate
      aria-label='business profile search form'
      data-test='business-profile-search-form'
      onSubmit={handleSubmit}>
      <Grid
        container
        justify='space-around'
        className='search-fields-container'>
        <Grid item className='each-field-container' md={3}>
          <Combobox
            options={organizations}
            label='Organization Profile Name'
            value={values.organizationName}
            name='organizationName'
            onChange={handleChange}
          />
        </Grid>
        <Grid item className='each-field-container' md={3}>
          <Dropdown
            options={regions}
            label='Organization Region'
            placeholder='Please Select'
            value={values.organizationRegion}
            name='organizationRegion'
            style={{ width: '100%' }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item className='each-field-container' md={3}>
          <Combobox
            options={countries}
            label='Operating Country / Territory'
            value={values.organizationTerritory}
            name='organizationTerritory'
            onChange={handleChange}
          />
        </Grid>
        <Grid item className='each-field-container' md={3}>
          <Dropdown
            options={typesObject}
            label='Organization Type'
            placeholder='Please Select'
            name='organizationType'
            value={values.organizationType}
            style={{ width: '100%' }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container className='search-bprofile-buttons'>
        <Grid item md={8} />
        <Grid item md={4} className='search-bprofile-button-container-item'>
          <Button
            color='secondary'
            variant='outlined'
            size='small'
            data-test='reset-button'
            disabled={!dirty}
            onClick={clearHandeler}>
            Clear
          </Button>
          <Button
            color='primary'
            type='submit'
            size='small'
            disabled={!dirty}
            data-test='submit-button'
            variant='contained'>
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
SearchForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  organizations: PropTypes.array,
  regions: PropTypes.array,
  countries: PropTypes.array,
  types: PropTypes.array,
  searchedOrganization: PropTypes.object
};
SearchForm.defaultProps = {
  organizations: [],
  regions: [],
  countries: [],
  types: []
};

export const mapPropsToValues = () => ({
  organizationName: { value: '', label: '' },
  organizationRegion: '',
  organizationTerritory: { value: '', label: '' },
  organizationType: ''
});
export const handleSubmit = (values, formikBag) => {
  const orgData = {
    organizationProfileId:
      values.organizationName && values.organizationName.id
        ? values.organizationName.id
        : null,
    regionTypeCode: values.organizationRegion
      ? values.organizationRegion
      : null,
    countryIsoCode:
      values.organizationTerritory && values.organizationTerritory.value
        ? values.organizationTerritory.value
        : null,
    orgTypCde: values.organizationType ? values.organizationType : null
  };
  const pageInfo = {
    pageNumber: 0,
    rowsPerPage: 5
  };
  formikBag.props.getOrganizationSearchList({
    organizationalSearchVO: orgData,
    paginationInfo: pageInfo
  });
  formikBag.props.setSubmitted(true);
  formikBag.props.getSearchedOrganizationProfile(values);
};
const formConfig = withFormik({
  mapPropsToValues,
  handleSubmit
});
export default formConfig(SearchForm);
