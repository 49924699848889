/* eslint-disable max-lines-per-function */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './UserSearch.css';
import { Grid, TableRow, TableCell, TablePagination } from '@mui/material';
import { Button } from '@material-ui/core';
import MaterialTable from '../../../../components/material/MaterialTable';
import UserImpersonateModal from '../../../../components/UserImpersonateModal/UserImpersonateModal';
import { impersonateUserApi } from '../../../../api/admin';
import Header from './UserSearchHeader';
import UserSearchButtonContainer from './UserSearchButtonContainer';
import UserSearchCriteria from './UserSearchCriteria';
import { clearSearchProfileList } from '../../../../store/admin/actions';

const getTextColor = value => {
  switch (value) {
    case 'A':
      return '#239B06';
    case 'P':
      return '#CC8007';
    default:
      return '#EB4D4D ';
  }
};
const getStatus = value => {
  switch (value) {
    case 'A':
      return 'Active';
    case 'P':
      return 'Pending for Approval';
    default:
      return 'Inactive';
  }
};

export class UserSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      accountStatus: '',
      userLoginId: '',
      organizationProfile: '',
      submittedOnce: false,
      impersonateModalOpen: false,
      impersonateUserId: '',
      impersonateUserName: '',
      impersonateData: {},
      controller: {
        pageNumber: 0,
        rowsPerPage: 5
      },
      dataLoaded: 0,
      searchedUserProfileDefault: {},
      formReset: false,
      formSubmitted: false
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  componentDidMount() {
    const { searchedUserProfile, location } = this.props;
    this.setState({
      formSubmitted: false
    });
    if (location && location.state && location.state.formSearched === true) {
      this.setState({
        formReset: true
      });
    }
    this.props.getAdminOrganizationNames();
    const impersonateData = localStorage.getItem('impersonateUserData')
      ? JSON.parse(localStorage.getItem('impersonateUserData'))
      : {};
    if (
      impersonateData &&
      impersonateData.isImpersonating != undefined &&
      !impersonateData.isImpersonating
    ) {
      this.setState(
        {
          firstName: impersonateData.firstName,
          lastName: impersonateData.lastName,
          accountStatus: impersonateData.accountStatus,
          organizationProfile: impersonateData.organizationProfile,
          userLoginId: this.state.userLoginId
        },
        () => {
          this.searchForProfileHandler(new Event('submit'));
          localStorage.removeItem('impersonateUserData');
        }
      );
    }
    if (
      searchedUserProfile != undefined &&
      Object.keys(searchedUserProfile).length > 0 &&
      location &&
      location.state &&
      location.state.formSearched === true
    ) {
      this.setState(
        {
          firstName: searchedUserProfile.searchUserVO.firstName,
          lastName: searchedUserProfile.searchUserVO.lastName,
          accountStatus: searchedUserProfile.searchUserVO.accountStatus,
          organizationProfile:
            searchedUserProfile.searchUserVO.organizationProfile,
          userLoginId: searchedUserProfile.searchUserVO.userLoginId
        },
        () => {
          this.searchForProfileHandler(new Event('submit'));
        }
      );
    }
  }

  handlePageChange = async (event, newPage) => {
    const { controller } = this.state;
    await this.setState({
      controller: {
        ...controller,
        pageNumber: newPage
      }
    });
    this.searchForProfileUpdate();
    this.setState({
      dataLoaded: 1
    });
  };

  handleChangeRowsPerPage = async event => {
    const { controller } = this.state;
    await this.setState({
      controller: {
        ...controller,
        rowsPerPage: parseInt(event.target.value, 10),
        pageNumber: 0
      }
    });
    this.searchForProfileUpdate();
    this.setState({
      dataLoaded: 1
    });
  };

  userClickedHandler = id => {
    this.props.history.push(`/admin/users/edit/${id}`);
  };

  openImpersonateModal = (key, userName) => e => {
    e.preventDefault();
    this.setState({
      impersonateModalOpen: true,
      impersonateUserId: key,
      impersonateUserName: userName
    });
  };

  closeImpersonateModal = e => {
    e.preventDefault();
    this.setState({
      impersonateModalOpen: false,
      impersonateUserId: '',
      impersonateUserName: ''
    });
  };

  impersonateUser = (impersonateUserProfileId, userName) => {
    const body = {
      doImpersonate: true,
      impersonateUserProfileId
    };
    impersonateUserApi(body)
      .then(res => {
        if (res) {
          const impData = {
            isImpersonating: true,
            redirectUrl: this.props.location.pathname,
            userName,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            accountStatus: this.state.accountStatus,
            organizationProfile: this.state.organizationProfile
          };
          localStorage.setItem('impersonateUserData', JSON.stringify(impData));
          this.props.history.replace('/');
        } else {
          this.props.history.replace('/error');
        }
      })
      .catch(err => {
        this.props.history.replace('/error');
      });
  };

  searchForProfileUpdate = async () => {
    const { controller } = this.state;

    const userProfile = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      userLoginId: this.state.userLoginId,
      accountStatus: this.state.accountStatus,
      organizationProfile: this.state.organizationProfile,
      organizationProfileId: this.state.organizationProfile.id
    };
    const pageInfo = {
      pageNumber: controller.pageNumber,
      rowsPerPage: controller.rowsPerPage
    };
    this.setState({ formSubmitted: true, submittedOnce: true });
    await this.props.getUserProfileSearchResults({
      searchUserVO: userProfile,
      paginationInfo: pageInfo
    });
    this.props.getSearchedUserProfile({
      searchUserVO: userProfile,
      paginationInfo: pageInfo
    });
    this.setState({ formSubmitted: false });
    this.setState({
      dataLoaded: 1
    });
  };

  searchForProfileHandler = async e => {
    e.preventDefault();
    await this.setState({
      controller: {
        rowsPerPage: 5,
        pageNumber: 0
      }
    });
    this.searchForProfileUpdate();
  };

  clearFieldsHandler = () => {
    this.setState({
      firstName: '',
      lastName: '',
      accountStatus: '',
      organizationProfile: {},
      submittedOnce: false,
      formSubmitted: false,
      userLoginId: ''
    });
    this.props.clearSearchProfileList();
    this.setState({
      controller: {
        rowsPerPage: 5,
        pageNumber: 0
      }
    });
    this.setState({
      dataLoaded: 0
    });
    this.setState({
      formReset: false,
      formSubmitted: false
    });
  };

  componentWillUnmount() {
    this.setState({
      dataLoaded: 0
    });
  }

  updateSearchCriteriaHandler(id, value) {
    this.setState({ [id]: value });
    this.setState({
      controller: {
        rowsPerPage: 5,
        pageNumber: 0
      }
    });
  }

  renderImpersonateButton(user) {
    const isEligibleImpersonation =
      this.state.impersonateData &&
      !this.state.impersonateData.isImpersonating &&
      user.internalUserFlag === 'N' &&
      user.accountStatus === 'A' &&
      user.userLockInd === 'F' &&
      !user.loggedInUser &&
      Object.keys(this.props.roleMap)[0] !== 'HA';

    if (isEligibleImpersonation) {
      return (
        <Button
          variant='outlined'
          size='small'
          color='secondary'
          data-test='impersonate-button'
          onClick={this.openImpersonateModal(
            user.key,
            user.firstName + ' ' + user.lastName
          )}>
          Impersonate
        </Button>
      );
    }
    return (
      <Button
        disabled
        variant='outlined'
        size='small'
        color='secondary'
        data-test='impersonate-button'>
        Impersonate
      </Button>
    );
  }

  render() {
    const { userProfiles, userPageInfo } = this.props;
    const { controller, formReset } = this.state;
    const tableHeaders = [
      'User ID',
      'First Name',
      'Last Name',
      'Email ID',
      'Status',
      ''
    ];
    return (
      <Grid container item data-test='user-search-component'>
        <Grid
          container
          className='user-search-container'
          alignItems='flex-start'
          data-test='user-search-criteria-grid'
          alignContent='flex-start'>
          <Header data-test='user-search-header' roles={this.props.roleMap} />
          <Grid
            container
            item
            className='user-search-criteria-container'
            data-test='user-search-criteria-container'>
            <form
              style={{ width: '100%' }}
              data-test='user-search-criteria-form'
              onSubmit={this.searchForProfileHandler}>
              <UserSearchCriteria
                adminsOrganizations={this.props.usersOrganizations}
                firstName={this.state.firstName}
                lastName={this.state.lastName}
                userLoginId={this.state.userLoginId}
                status={this.state.accountStatus}
                businessName={this.state.organizationProfile}
                data-test='user-search-criteria'
                onSearchCriteriaChange={(elementId, value) =>
                  this.updateSearchCriteriaHandler(elementId, value)
                }
              />
              <UserSearchButtonContainer
                data-test='user-search-button-container'
                clearFields={this.clearFieldsHandler}
                userProfile={this.state}
                onSubmit={this.searchForProfileHandler}
              />
              <UserImpersonateModal
                data-test='user-search-impersonate-modal'
                isOpen={this.state.impersonateModalOpen}
                userProfileId={this.state.impersonateUserId}
                userName={this.state.impersonateUserName}
                onConfirm={this.impersonateUser}
                onCancel={this.closeImpersonateModal}
              />
            </form>
          </Grid>
          {this.state.submittedOnce ||
          (formReset && userPageInfo.totalNumberOfRows && userProfiles) ? (
            <>
              <MaterialTable
                data-test='user-search-material-table'
                tableHeaders={tableHeaders}
                headerAlign='center'
                count={userPageInfo.totalNumberOfRows}>
                {userProfiles.map(user => (
                  <TableRow
                    key={user.key}
                    style={{ inlineSize: '150px', overflow: 'hidden' }}>
                    <TableCell align='center' style={{ width: '10%' }}>
                      {user.internalUserFlag === 'Y' ? (
                        <Link
                          style={{
                            textDecoration: '',
                            pointerEvents: 'none',
                            color: 'black'
                          }}
                          to='/'>
                          {user.userLoginId}
                        </Link>
                      ) : (
                        <Link
                          style={{
                            textDecoration: 'none',
                            pointerEvents: 'auto',
                            color: '#2477ab'
                          }}
                          to={`/admin/users/edit/${user.key}`}>
                          {user.userLoginId}
                        </Link>
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        inlineSize: '150px',
                        overflow: 'hidden',
                        width: '20%'
                      }}
                      align='center'>
                      {user.firstName}
                    </TableCell>
                    <TableCell
                      style={{
                        inlineSize: '150px',
                        overflow: 'hidden',
                        width: '20%'
                      }}
                      align='center'>
                      {user.lastName}
                    </TableCell>
                    <TableCell
                      style={{
                        inlineSize: '150px',
                        overflow: 'hidden',
                        width: '20%'
                      }}
                      align='center'>
                      {user.emailId}
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{
                        color: getTextColor(user.accountStatus),
                        width: '15%'
                      }}>
                      {getStatus(user.accountStatus)}
                    </TableCell>
                    {this.props.roleMap === 'Portal Admin' && (
                      <TableCell align='center' style={{ width: '15%' }}>
                        {this.renderImpersonateButton(user)}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </MaterialTable>
              <span className='user-Table-Pagination'>
                <TablePagination
                  component='div'
                  page={controller.pageNumber}
                  count={userPageInfo.totalNumberOfRows}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  rowsPerPage={controller.rowsPerPage}
                  onPageChange={this.handlePageChange}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </span>
            </>
          ) : null}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    usrProfId: state.user.usrProfId,
    roleMap: state.user.highestRole
  };
};
const mapDispatchToProps = {
  clearSearchProfileList
};

UserSearch.propTypes = {
  getAdminOrganizationNames: PropTypes.func.isRequired,
  getUserProfileSearchResults: PropTypes.func.isRequired,
  clearSearchProfileList: PropTypes.func.isRequired,
  usrProfId: PropTypes.any,
  history: PropTypes.object.isRequired,
  usersOrganizations: PropTypes.array.isRequired,
  userProfiles: PropTypes.array,
  searchedUserProfile: PropTypes.object
};

UserSearch.defaultProps = {
  userProfiles: [],
  usrProfId: ''
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserSearch));
