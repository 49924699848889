import React from 'react';
import styled, { keyframes } from 'styled-components';

import { padNumber } from '../../utils/numbers';
import PropTypes from 'prop-types';
import './index.css';

const animateItem = val => {
  return keyframes`0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(${val}deg);
        }`;
};

const FullDiv = styled.div`
  animation: ${props => animateItem(props.value)} ease-in-out 3s;
  transform: rotate(${props => props.value}deg);
`;

const FillDiv = styled.div`
  animation: ${props => animateItem(props.value)} ease-in-out 3s;
  transform: rotate(${props => props.value}deg);
`;

const CircularProgress = ({ value, degreeValue }) => {
  return (
    <div className='circle-wrap' data-test='circular-progress-component'>
      <div className='circle'>
        <FullDiv
          className='mask full'
          value={degreeValue}
          data-test='circular-progress-full-div'>
          <FillDiv
            className='fill'
            value={degreeValue}
            data-test='circular-progress-fill-div'
          />
        </FullDiv>
        <div className='mask half'>
          <FillDiv className='fill' value={degreeValue} />
        </div>
        <div data-test='inner-cercle-value' className='inside-circle'>
          {padNumber(value)}
        </div>
      </div>
    </div>
  );
};

CircularProgress.propTypes = {
  value: PropTypes.number.isRequired,
  degreeValue: PropTypes.number.isRequired
};

export default CircularProgress;
