import React, { Component } from 'react';
import { TableRow, TableCell, TableHead } from '@material-ui/core';

class TableHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isAdd: false
    };
  }

  render() {
    const { headers } = this.props;
    return (
      <TableHead data-test='product-line-table-header'>
        <TableRow
          className='product-line-table-header'
          data-test='product-line-table-header-row'>
          {headers.map((k, index) => (
            <TableCell
              key={index}
              className='product-line-tablehead '
              data-test='product-line-tablehead '
              style={{
                width: `${100 / headers.length}%`
              }}>
              {k.title}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

export default TableHeader;
