import { errorTypes } from './types';

export const initialState = {
  status: false,
  networkError: false,
  globalError: '',
  loginFormError: '',
  announcementError: false,
  myProfileError: false,
  registrationForm: {},
  myProfileFormError: {},
  formErrors: {
    errorMap: {},
    errorMessage: ''
  }
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case errorTypes.GLOBAL_ERROR:
      return {
        ...state,
        globalError: action.error
      };
    case errorTypes.SET_LOGIN_FORM_ERROR:
      return {
        ...state,
        loginFormError: action.error
      };
    case errorTypes.RESET_LOGIN_FORM_ERROR:
      return {
        ...state,
        loginFormError: ''
      };
    case errorTypes.SET_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        announcementError: true
      };
    case errorTypes.RESET_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        announcementError: false
      };
    case errorTypes.NETWORK_ERROR:
      return { ...state, networkError: true };
    case errorTypes.NETWORK_SUCCESS:
      return { ...state, networkError: false };

    case errorTypes.SET_REGISTRATION_ERRORS:
      return {
        ...state,
        registrationForm: action.errors
      };
    case errorTypes.SET_MY_PROFILE_ERROR:
      return {
        ...state,
        myProfileError: true,
        myProfileFormError: action.errors
      };
    case errorTypes.RESET_MY_PROFILE_ERROR:
      return { ...state, myProfileError: false, myProfileFormError: {} };

    case errorTypes.SET_FORM_ERRORS:
    case errorTypes.RESET_FORM_ERRORS:
      return { ...state, ...action.error };

    default:
      return state;
  }
};

export default errorReducer;
