import {
  getParentOrganizationsApi,
  getContractByIdApi,
  getChildOrganizationsByParentOrgIdApi,
  getContractByOrgId,
  getExclusionTypesApi
} from '../../api/contracts';
import {
  GET_PARENT_ORGANIZATIONS,
  GET_CHILD_ORGANIZATIONS,
  GET_CONTRACTS_BY_PARENT_ORGANIZATION,
  GET_CONTRACT,
  GET_EXCLUSION_TYPES,
  RESET_CHILD_ORGANIZATIONS,
  RESET_CONTRACT,
  RESET_EXCLUSION_TYPES
} from './types';

export const getParentOrganizations = () => async dispatch => {
  try {
    const response = [];
    const payload = await getParentOrganizationsApi();
    if (payload !== undefined && payload !== null) {
      Object.entries(payload).forEach(([k, v]) => {
        response.push({
          id: k,
          label: v,
          value: v
        });
      });
    }

    dispatch({
      type: GET_PARENT_ORGANIZATIONS,
      payload: response
    });
  } catch (err) {}
};

export const getChildOrganizationsByParentOrgId = globalnetOrganizationId => async dispatch => {
  try {
    const response = [];
    const payload = await getChildOrganizationsByParentOrgIdApi(
      globalnetOrganizationId
    );
    if (payload !== undefined && payload !== null) {
      Object.entries(payload).forEach(([k, v]) => {
        response.push({
          id: k,
          label: v,
          value: k
        });
      });
    }

    if (response.length > 0) {
      response.unshift({ id: 0, value: '', label: '' });
    }

    dispatch({
      type: GET_CHILD_ORGANIZATIONS,
      payload: response
    });
  } catch (err) {}
};
export const getContractsByParentOrganization = orgId => async (
  dispatch,
  getState
) => {
  try {
    let response = {};
    const contractList = [];
    const payload = await getContractByOrgId(orgId);

    if (payload !== undefined && payload !== null) {
      const parentOrgObj = getState().contracts.parentOrganizations.filter(
        ({ id }) => id === orgId
      );
      const parentOrganizationName = parentOrgObj[0].value;
      if (payload.status === 404 && payload.statusText === 'Not Found') {
        response = {
          organizationProfileName: parentOrganizationName,
          contractList: []
        };
      } else {
        payload.forEach(data => {
          contractList.push({
            contractId: data.contractId,
            effectiveStartDate: data.effectiveStartDate,
            effectiveEndDate: data.effectiveEndDate,
            status: data.active
          });
        });
        response = {
          organizationProfileName: parentOrganizationName,
          contractList
        };
      }
    }
    dispatch({
      type: GET_CONTRACTS_BY_PARENT_ORGANIZATION,
      payload: response
    });
  } catch (err) {}
};

export const getContractById = contractId => async dispatch => {
  try {
    const payload = await getContractByIdApi(contractId);
    dispatch({
      type: GET_CONTRACT,
      payload: payload.data
    });
  } catch (err) {}
};

export const getExclusionTypes = () => async dispatch => {
  try {
    const response = [];
    const payload = await getExclusionTypesApi();
    if (payload !== undefined && payload !== null) {
      payload.forEach(data => {
        response.push({
          label: data.contractDesc,
          value: data.contractId
        });
      });
    }

    dispatch({
      type: GET_EXCLUSION_TYPES,
      payload: response
    });
  } catch (err) {}
};

export const clearContract = () => ({
  type: RESET_CONTRACT
});

export const clearChildOrganization = () => ({
  type: RESET_CHILD_ORGANIZATIONS
});

export const clearExclusionTypes = () => ({
  type: RESET_EXCLUSION_TYPES
});
