import React, { Component } from 'react';
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton
} from '@material-ui/core';
import { Switch } from '../../../../components/material';
import { TrashIcon } from '../../../../components/icons';
import RoyaltyExclusionTermsForm from '../RoyaltyExclusionTermsForm';

class RoyaltyExclusionTerms extends Component {
  render() {
    const {
      exclusionTypesList,
      handleChange,
      values,
      onDeleteRoyaltyExclusionTerms,
      onAddRoyaltyExclusionTerms
    } = this.props;

    const filteredExclusionTypesList = exclusionTypesList.filter(
      type =>
        !values.exclusionTypes.map(ex => ex.exclusionCode).includes(type.id)
    );

    return (
      <Grid container data-test='component-RoyaltyExclusionTerms'>
        <Grid item md={12} className='row-header-bar'>
          <Typography variant='h6'>Royalty Exclusion Terms</Typography>
        </Grid>
        <Grid item md={12} className='border-bottom px-15'>
          <Grid container className='row' alignItems='center'>
            <Grid item md={11} className='pl-15'>
              <Typography variant='h6'>
                Include Central Submission Volume in Royalty Additions Screen
              </Typography>
            </Grid>
            <Grid item md={1} className='pr-15'>
              <Switch
                id='isIncludeCentralSubmissionVolume'
                name='isIncludeCentralSubmissionVolume'
                value={values.isIncludeCentralSubmissionVolume}
                checked={values.isIncludeCentralSubmissionVolume}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} className='border-bottom px-15'>
          <Grid container className='row' alignItems='center'>
            <Grid item md={11} className='pl-15'>
              <Typography variant='h6'>
                Authorize Franchise for Manual Royalty Exclusion Input
              </Typography>
            </Grid>
            <Grid item md={1} className='pr-15'>
              <Switch
                id='isAllowManulaRoyalityExclusion'
                name='isAllowManulaRoyalityExclusion'
                value={values.isAllowManulaRoyalityExclusion}
                checked={values.isAllowManulaRoyalityExclusion}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>

        <TableContainer>
          <Table s className='royalty-exclusion-terms-table'>
            <TableBody>
              <RoyaltyExclusionTermsForm
                exclusionTypesList={filteredExclusionTypesList}
                onAddRoyaltyExclusionTerms={onAddRoyaltyExclusionTerms}
              />
              {values.exclusionTypes &&
                values.exclusionTypes.length > 0 &&
                values.exclusionTypes.map((v, k) => (
                  <TableRow key={v.exclusionCode}>
                    <TableCell>
                      <Typography variant='h6' className='pl-15'>
                        {v.exclusionName}
                      </Typography>
                    </TableCell>
                    <TableCell />

                    <TableCell style={{ textAlign: 'right' }}>
                      <IconButton
                        data-test='icon-button-component'
                        style={{ marginRight: '-3px' }}
                        className='icon-button'
                        onClick={() =>
                          onDeleteRoyaltyExclusionTerms(v.exclusionCode)
                        }>
                        <TrashIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  }
}

export default RoyaltyExclusionTerms;
