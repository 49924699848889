import { connect } from 'react-redux';
import ExchangeRates from './ExchangeRates';

import {
  checkExchangeRatesLoaded,
  getExchangeRates,
  loadExchangeRates,
  setRatesFormData,
  clearloadExchangeRates
} from '../../../store/admin/actions';

export const mapStateToProps = state => {
  return {
    exchangeRates: state.admin.exchangeRates,
    isLoaded: state.admin.exchangeRatesLoaded,
    searchedRatesForm: state.admin.searchedRatesForm
  };
};

export default connect(mapStateToProps, {
  getExchangeRates,
  checkExchangeRatesLoaded,
  loadExchangeRates,
  setRatesFormData,
  clearloadExchangeRates
})(ExchangeRates);
