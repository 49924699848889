import { userActions } from './types';

export const initialState = {
  isProfileUpdated: false,
  isPasswordUpdated: false,
  highestRole: '',
  userRoleMap: {}
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActions.SET_USER_PROFILE:
      return {
        ...state,
        ...action.payload
      };
    case userActions.UPDATE_USER_PROFILE:
      return {
        ...state,
        isProfileUpdated: action.payload
      };
    case userActions.CHANGE_PASS_PROFILE:
      return {
        ...state,
        isPasswordUpdated: action.payload
      };
    case userActions.CHANGE_PASS_TIME:
      return {
        ...state,
        passwordLastUpdatedTimeStamp: action.payload
      };
    case userActions.RESET_USER_PROFILE:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
