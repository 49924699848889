/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import './Home.css';
import NavigationButtons from './NavigationButtons';
import AnnouncementBanner from './AnnouncementBanner';
import ImportantDates from './ImportantDates';
import FranchiseComponent from './FranchiseComponent';
import { padNumber } from '../../utils/numbers';
import { MegaMenu } from '../../components';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';

const Home = props => {
  const {
    announcement,
    announcementError,
    admin,
    selectedMegaMenu,
    franchisesData,
    importantDates,
    filterFranchises,
    openMegaMenu,
    user,
    fetchAnnouncement,
    fetchImportantDates,
    fetchFranchisesData,
    manageDataMenuActive,
    getMegaMenuOrganizationNames
  } = props;

  const [impersonate, setImpersonate] = useState(false);

  useEffect(() => {
    document.title = 'GlobalNet | Dashboard';
    fetchAnnouncement();
    fetchImportantDates();
    fetchFranchisesData();
    getMegaMenuOrganizationNames();
    if (localStorage.getItem('planYear')) {
      localStorage.removeItem('planYear');
    }
    if (localStorage.getItem('formType')) {
      localStorage.removeItem('formType');
    }
    const impersonateData = localStorage.getItem('impersonateUserData')
      ? JSON.parse(localStorage.getItem('impersonateUserData'))
      : {};
    setImpersonate(Boolean(Object.keys(impersonateData).length > 0));
  }, []);

  if (impersonate === false && user.consentFlag === false) {
    return (
      <TermsAndConditions
        data-test='component-terms-conditons-modal'
        user={user}
      />
    );
  }
  return (
    <>
      <Grid container data-test='component-home' className='padding'>
        <Grid item md={12}>
          <Grid
            container
            className={
              admin ? 'important-date-grid admin' : 'important-date-grid'
            }
            justify='center'>
            <Grid item sm={9}>
              {announcementError ? (
                <NavigationButtons
                  admin={admin}
                  reviews={franchisesData.stats.overDue || '2'}
                  openMegaMenu={openMegaMenu}
                  selectedMegaMenu={selectedMegaMenu}
                />
              ) : (
                <AnnouncementBanner announcement={announcement} />
              )}
            </Grid>
            <Grid item sm={3}>
              <ImportantDates dates={importantDates} />
            </Grid>
          </Grid>
        </Grid>
        {announcementError && <MegaMenu style={{ marginTop: -35 }} />}
        {!announcementError && (
          <>
            <Grid item md={12} className='navigation-buttons-container'>
              <NavigationButtons
                fullWidth
                admin={admin}
                reviews={padNumber(franchisesData.stats.overDue)}
                openMegaMenu={openMegaMenu}
                selectedMegaMenu={selectedMegaMenu}
                manageDataMenuActive={manageDataMenuActive}
              />
            </Grid>
            <MegaMenu style={{ marginTop: -30 }} />
          </>
        )}
        <Grid item sm={12} className='dci-gray-border margin br-18'>
          <FranchiseComponent
            data={franchisesData}
            onFilter={filterFranchises}
          />
        </Grid>
      </Grid>
    </>
  );
};

Home.propTypes = {
  announcement: PropTypes.object.isRequired,
  announcementError: PropTypes.bool.isRequired,
  importantDates: PropTypes.array.isRequired,
  franchisesData: PropTypes.object.isRequired,
  selectedMegaMenu: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool,
  admin: PropTypes.bool,
  fetchAnnouncement: PropTypes.func.isRequired,
  fetchImportantDates: PropTypes.func.isRequired,
  fetchFranchisesData: PropTypes.func.isRequired,
  filterFranchises: PropTypes.func.isRequired,
  getMegaMenuOrganizationNames: PropTypes.func.isRequired,
  openMegaMenu: PropTypes.func.isRequired
};

Home.defaultProps = {
  admin: false,
  isAuthenticated: false
};

export default Home;
