import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IssuerFraudTable from './IssuerFraudTable';
import { Loader } from '../../../components';
import {
  getCountriesData,
  getTypeOfFraudData
} from '../../../store/formsListNav/actions';
import {
  setDataForms,
  resetDataForms,
  updateIssuersFraudForm,
  deleteIssuerFraudFormRow
} from './../../../store/dataForms/actions';
import { Grid } from '@material-ui/core';
export class IssuerFraud extends React.Component {
  state = {
    columnHeaders: {
      1200: 'Country',
      1201: 'Type of Fraud',
      1202: 'Description of Others',
      1203: 'Monetary Value',
      1204: 'No. of Cases',
      1205: 'Actions'
    }
  };

  componentDidMount() {
    this.props.setDataForms(this.props.location.pathname);
  }

  render() {
    const {
      formObject,
      getCountriesData,
      countriesDropdownData,
      getTypeOfFraudData,
      typeOfFraudDropdownData,
      updateIssuersFraudForm,
      deleteIssuerFraudFormRow,
      total
    } = this.props;
    const { columnHeaders } = this.state;

    if (!formObject || !formObject.status) {
      return (
        <Grid
          container
          item
          justify='center'
          alignItems='center'
          data-test='component-franchise'
          data-testid='component-qr-code'>
          <Loader data-test='component-loader' />
        </Grid>
      );
    }

    return (
      <IssuerFraudTable
        form={formObject}
        countriesDropdownData={countriesDropdownData}
        getCountriesData={getCountriesData}
        getTypeOfFraudData={getTypeOfFraudData}
        typeOfFraudDropdownData={typeOfFraudDropdownData}
        updateIssuersFraudForm={updateIssuersFraudForm}
        columnHeaders={columnHeaders}
        deleteIssuerFraudFormRow={deleteIssuerFraudFormRow}
        total={total}
      />
    );
  }
}

export const mapStateToProps = state => {
  return {
    formObject: state.dataForms?.form || {},
    total: state.dataForms.form?.total || {},
    tableName: 'issuer-fraud',
    countriesDropdownData: state.formsListNav?.countriesDropdownData || [],
    typeOfFraudDropdownData: state.formsListNav?.typeOfFraudDropdownData || []
  };
};

export default connect(mapStateToProps, {
  setDataForms,
  updateIssuersFraudForm,
  deleteIssuerFraudFormRow,
  resetDataForms,
  getCountriesData,
  getTypeOfFraudData
})(withRouter(IssuerFraud));
