import React, { Component, Fragment } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import { TextField, LinkButton } from '../../components/material';
import { landingPageText } from '../../constants/text';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { legacyUserResetApi } from '../../api/auth';
import { forgotPasswordSchema } from '../../constants/validations/forgotPassword';
import { createStatusFromErrors } from '../../utils/commonFunctions';
import { ErrorContainer } from '../../components';
import Success from './Success';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
export class UseridVerification extends Component {
  componentDidMount() {
    document.title = 'GlobalNet | User Id Verification';
    localStorage.clear();
  }

  handleBlur = e => {
    const { handleBlur, status, setStatus } = this.props;
    e.persist();
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };

  renderForm = () => {
    return (
      <Fragment>
        <Grid
          container
          justify='space-between'
          alignItems='center'
          className='login-form-title'>
          <Typography
            data-test='forgot-password-title'
            variant='h5'
            aria-label='forgot-password-form forgot-password-title'>
            {landingPageText.userIDVerificationText}
          </Typography>
          <Typography
            data-test='forgot-password-text'
            variant='h6'
            aria-label='forgot-password-form forgot-password-text'
            style={{ marginTop: '10px' }}>
            {landingPageText.userIDValidationText}
          </Typography>
        </Grid>
        <Grid item container direction='column'>
          <Grid item container>
            <TextField
              fullWidth
              required
              id='username'
              className='form-input'
              data-test='user-id-input'
              label={landingPageText.userIdLabel}
              name='userLoginId'
              onBlur={this.handleBlur}
            />
          </Grid>
          <Grid item container>
            <TextField
              fullWidth
              required
              id='userEmailId'
              className='form-input'
              data-test='email-id-input'
              label={landingPageText.emailIdLabel}
              name='emailId'
              onBlur={this.handleBlur}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  render() {
    const { status, handleSubmit } = this.props;
    if (status.success) {
      return <Success requestName='Temporary Password' />;
    }
    return (
      <>
        <form
          noValidate
          data-test='component-forgot-password-form'
          className='login-form'
          aria-label='forgot-password'
          onSubmit={handleSubmit}>
          <Grid container direction='column'>
            <ErrorContainer
              message={status.root}
              data-test='component-error-container'
            />
            {this.renderForm()}
            <Grid item className='login-form-actions'>
              <Grid container justify='flex-start'>
                <Grid item>
                  <Button
                    color='primary'
                    size='small'
                    variant='contained'
                    type='submit'
                    data-test='forgot-password-submit-button'
                    style={{ height: 40 }}>
                    {landingPageText.submit}
                  </Button>
                </Grid>
                <Grid item style={{ marginLeft: '15px' }}>
                  <LinkButton
                    to='/'
                    color='secondary'
                    size='small'
                    variant='outlined'
                    type='button'
                    data-test='forgot-password-cancel-button'
                    style={{ height: 40 }}>
                    {landingPageText.cancel}
                  </LinkButton>
                </Grid>
                <Typography
                  data-test='forgot-sso-text'
                  variant='h6'
                  aria-label='forgot-userid-form forgot-userid-text'
                  className='forgot-sso-text'>
                  <InfoOutlinedIcon style={{ fontSize: '100%' }} />{' '}
                  {landingPageText.ssoPasswordText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

UseridVerification.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const formConfig = withFormik({
  mapPropsToValues: () => ({
    userLoginId: '',
    emailId: ''
  }),

  mapPropsToStatus: () => ({}),

  validationSchema: forgotPasswordSchema,

  handleSubmit: (values, formikBag) => {
    const body = {
      userLoginId: values.userLoginId,
      userEmailId: values.emailId
    };
    legacyUserResetApi(body)
      .then(res => {
        if (res.includes('success') && !res.includes('First Time Visitor')) {
          formikBag.setStatus({ success: res });
        } else {
          const errorRes = {
            data: {
              errorCode: null,
              errorMessage: res,
              errorMap: null
            }
          };
          formikBag.setStatus(createStatusFromErrors(errorRes));
        }
      })
      .catch(err => {
        formikBag.setStatus(createStatusFromErrors(err));
      });
  }
});

export const UseridVerificationWithFormik = formConfig(UseridVerification);

export default UseridVerificationWithFormik;
