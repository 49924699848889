export const pdfLinks = [
  {
    id: 1,
    name: 'GlobalNet 2.0 User Manual',
    fileName: 'GlobalNet_2_0_User_manual.pdf'
  },
  {
    id: 2,
    name: 'GlobalNet 2.0 Training Deck',
    fileName: 'GlobalNet_2_0_Training_deck.pdf'
  },
  {
    id: 3,
    name: 'GlobalNet 2.0 FAQ',
    fileName: 'External_GlobalNet_2_0_FAQs.pdf'
  },
  {
    id: 4,
    name: 'GlobalNet Industry Code Mapping Guide',
    fileName: 'GlobalNet_Industry_Code_Mapping_Guide.pdf'
  }
];

export const videoLinks = [
  {
    label: 'Video 1',
    vidPath: 'https://www.youtube.com/embed/2BWvPE_EB7Y'
  }
];
