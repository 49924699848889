// Refer the object keys from constant/dataForms

export const totalDetails = {
  5: {
    tabs: {
      0: {
        sections: {
          0: {
            columnTotal: [
              {
                rowHeaderId: '6'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          1: {
            columnTotal: [
              {
                rowHeaderId: '11'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          2: {
            columnTotal: [],
            rowTotal: [],
            grandTotal: []
          }
        }
      },
      1: {
        sections: {
          0: {
            columnTotal: [
              {
                rowHeaderId: '24'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          1: {
            columnTotal: [
              {
                rowHeaderId: '29'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          2: {
            columnTotal: [],
            rowTotal: [],
            grandTotal: []
          }
        }
      },
      2: {
        sections: {
          0: {
            columnTotal: [
              {
                rowHeaderId: '42'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          1: {
            columnTotal: [
              {
                rowHeaderId: '47'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          2: {
            columnTotal: [],
            rowTotal: [],
            grandTotal: []
          }
        }
      },
      3: {
        sections: {
          0: {
            columnTotal: [
              {
                rowHeaderId: '60'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          1: {
            columnTotal: [
              {
                rowHeaderId: '65'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          2: {
            columnTotal: [],
            rowTotal: [],
            grandTotal: []
          }
        }
      }
    }
  },
  20: {
    tabs: {
      0: {
        sections: {
          0: {
            columnTotal: [
              {
                rowHeaderId: '602'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          1: {
            columnTotal: [
              {
                rowHeaderId: '614'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          2: {
            columnTotal: [
              {
                rowHeaderId: '617'
              }
            ],
            rowTotal: [],
            grandTotal: []
          }
        }
      },
      1: {
        sections: {
          0: {
            columnTotal: [
              {
                rowHeaderId: '620'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          1: {
            columnTotal: [
              {
                rowHeaderId: '632'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          2: {
            columnTotal: [
              {
                rowHeaderId: '635'
              }
            ],
            rowTotal: [],
            grandTotal: []
          }
        }
      },
      2: {
        sections: {
          0: {
            columnTotal: [
              {
                rowHeaderId: '638'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          1: {
            columnTotal: [
              {
                rowHeaderId: '650'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          2: {
            columnTotal: [
              {
                rowHeaderId: '653'
              }
            ],
            rowTotal: [],
            grandTotal: []
          }
        }
      },
      3: {
        sections: {
          0: {
            columnTotal: [
              {
                rowHeaderId: '656'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          1: {
            columnTotal: [
              {
                rowHeaderId: '668'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          2: {
            columnTotal: [
              {
                rowHeaderId: '671'
              }
            ],
            rowTotal: [],
            grandTotal: []
          }
        }
      }
    }
  },
  25: {
    tabs: {
      0: {
        sections: {
          0: {
            columnTotal: [
              {
                rowHeaderId: '820'
              }
            ],
            rowTotal: [
              {
                columnHeaderId: '806'
              }
            ],
            grandTotal: [
              {
                rowHeaderId: '820',
                columnHeaderId: '806'
              }
            ]
          }
        }
      }
    }
  },
  30: {
    tabs: {
      0: {
        sections: {
          0: {
            columnTotal: [
              {
                rowHeaderId: '1219'
              }
            ],
            rowTotal: [
              {
                columnHeaderId: '1204'
              }
            ],
            grandTotal: [
              {
                rowHeaderId: '1219',
                columnHeaderId: '1204'
              }
            ]
          }
        }
      }
    }
  },
  45: {
    tabs: {
      0: {
        sections: {
          0: {
            columnTotal: [],
            rowTotal: [],
            grandTotal: []
          }
        }
      },
      1: {
        sections: {
          0: {
            columnTotal: [
              {
                rowHeaderId: '1423'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          1: {
            columnTotal: [
              {
                rowHeaderId: '1427'
              }
            ],
            rowTotal: [],
            grandTotal: []
          }
        }
      }
    }
  },
  55: {
    tabs: {
      0: {
        sections: {
          0: {
            columnTotal: [
              {
                rowHeaderId: '2002'
              }
            ],
            rowTotal: [],
            grandTotal: []
          },
          1: {
            columnTotal: [],
            rowTotal: [],
            grandTotal: []
          }
        }
      }
    }
  },
  70: {
    tabs: {
      0: {
        sections: {
          0: {
            columnTotal: [
              {
                rowHeaderId: '1807'
              }
            ],
            rowTotal: [
              {
                columnHeaderId: '1819'
              }
            ],
            grandTotal: [
              {
                rowHeaderId: '1807',
                columnHeaderId: '1819'
              }
            ]
          },
          1: {
            columnTotal: [
              {
                rowHeaderId: '1810'
              }
            ],
            rowTotal: [
              {
                columnHeaderId: '1819'
              }
            ],
            grandTotal: [
              {
                rowHeaderId: '1810',
                columnHeaderId: '1819'
              }
            ]
          },
          2: {
            columnTotal: [
              {
                rowHeaderId: '1813'
              },
              {
                rowHeaderId: '1814'
              }
            ],
            rowTotal: [
              {
                columnHeaderId: '1819'
              }
            ],
            grandTotal: [
              {
                rowHeaderId: '1813',
                columnHeaderId: '1819'
              },
              {
                rowHeaderId: '1814',
                columnHeaderId: '1819'
              }
            ]
          }
        }
      }
    }
  },
  75: {
    tabs: {
      0: {
        sections: {
          0: {
            columnTotal: [
              {
                rowHeaderId: '2107'
              }
            ],
            rowTotal: [
              {
                columnHeaderId: '2108'
              }
            ],
            grandTotal: [
              {
                rowHeaderId: '2107',
                columnHeaderId: '2108'
              }
            ]
          },
          1: {
            columnTotal: [
              {
                rowHeaderId: '2115'
              }
            ],
            rowTotal: [
              {
                columnHeaderId: '2108'
              }
            ],
            grandTotal: [
              {
                rowHeaderId: '2115',
                columnHeaderId: '2108'
              }
            ]
          }
        }
      }
    }
  }
};
