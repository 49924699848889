import React, { Component } from 'react';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import Header from './UnlockFranchiseeHeader';
import NoSelectedFranchisee from './NoSelectedFranchisee';
import SelectedFranchisee from './SelectedFranchisee';
import './UnlockFranchisee.css';
import OrganizationsList from './OrganizationsList';
import { withFormik } from 'formik';
import { unlockOrganizationApi } from '../../../api/organizations';
import { toastFunc } from '../../../components/ToastComponent/toastFunction';
export class UnlockFranchisee extends Component {
  state = {
    organisationID: '',
    organisationName: '',
    successMessage: ''
  };

  componentDidMount() {
    this.props.getMegaMenuOrganizationNames();
  }

  setOrganisationID = id => {
    this.setState({ organisationID: id });
  };

  setOrganisationName = name => {
    this.setState({ organisationName: name });
  };

  handleSubmit = () => {
    this.props.values.orgName = this.state.organisationID;
    this.props.handleSubmit();
  };

  render() {
    const {
      megaMenuOrganizationNames,
      values,
      handleChange,
      resetForm,
      errors,
      isValid,
      dirty
    } = this.props;
    return (
      <Grid container item data-test='user-search-component'>
        <Grid
          container
          className='franchisees-container'
          alignItems='flex-start'
          alignContent='flex-start'
          data-test='franchisees-container'>
          <Header data-test='franchisees-header' />
          <Grid
            container
            item
            className='franchisees-bottom-container'
            data-test='franchisees-bottom-container'>
            <Grid
              item
              md={3}
              className='franchisees-component-left-section'
              data-test='franchisees-component-left-section'>
              <OrganizationsList
                setOrganisationID={this.setOrganisationID}
                setOrganisationName={this.setOrganisationName}
                megaMenuOrganizationNames={megaMenuOrganizationNames}
              />
            </Grid>
            <Grid
              item
              md={9}
              className='franchisees-component-right-section'
              data-test='franchisees-component-right-section'>
              {this.state.organisationID.length > 0 ? (
                <SelectedFranchisee
                  orgID={this.state.organisationID}
                  orgName={this.state.organisationName}
                  successMessage={this.props.status.message}
                  megaMenuOrganizationNames={megaMenuOrganizationNames}
                  values={values}
                  handleChange={handleChange}
                  handleSubmit={this.handleSubmit}
                  resetForm={resetForm}
                  errors={errors}
                  isValid={isValid}
                  dirty={dirty}
                />
              ) : (
                <NoSelectedFranchisee />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const formConfig = withFormik({
  mapPropsToValues: () => ({
    orgName: '',
    month: '',
    year: ''
  }),
  validationSchema: Yup.object().shape({
    orgName: Yup.string(),
    year: Yup.string().required(),
    month: Yup.string().required()
  }),
  mapPropsToStatus: () => ({}),
  handleSubmit: (values, formikbag) => {
    const valueUnlockOrg = {
      month: values.month,
      orgProfileId: values.orgName,
      year: values.year
    };
    formikbag.setStatus({ message: '' });
    unlockOrganizationApi(valueUnlockOrg).then(response => {
      if (response.status === 200) {
        formikbag.setStatus({
          message: response.data
            ? response.data.message
              ? response.data.message
              : response.data
            : response.data
        });
      } else if (response.status === 400) {
        toastFunc({
          content: `${response.data.errorMessage}`,
          config: { className: 'toast-container reject' },
          toastType: 'error'
        });
      } else {
        toastFunc({
          content: 'Something went wrong. Please try again.',
          config: { className: 'toast-container reject' },
          toastType: 'error'
        });
      }
    });
  }
});

export default formConfig(UnlockFranchisee);
