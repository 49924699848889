import {
  GET_PENDING_APPROVAL_USERS,
  GET_USER_PROFILE,
  GET_USER_PAGE_INFO,
  GET_SEARCHED_USER_PROFILE,
  GET_SINGLE_PENDING_APPROVAL_USER,
  ADD_ORGANIZATION_DATA,
  GET_ADMIN_ORGANIZATION_NAMES,
  MANAGE_ORGANIZATION_DATA,
  ORG_ROLE_PROFILES_FOR_ADD,
  GET_ALL_ANNOUNCEMENTS,
  GET_MEGA_MENU_ORGANIZATION_NAMES,
  GET_ORG_PARAMS,
  GET_AUDIT_REPORT,
  GET_EXCHANGE_RATES,
  CHECK_EXCHANGE_RATES,
  SEARCHED_RATES_FORM,
  CLEAR_EXCHANGE_RATES
} from './types';

export const initialState = {
  totalAdminOrganizationalNames: [],
  pendingApprovals: {
    all: [],
    selected: undefined
  },
  orgRoleProfilesForSave: [],
  userProfile: [],
  userPageInfo: [],
  searchedUserProfile: {},
  megaMenuOrganizationNames: [],
  intesDropdownData: [],
  orgParams: [],
  auditReport: [],
  exchangeRates: [],
  exchangeRatesLoaded: undefined,
  searchedRatesForm: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PENDING_APPROVAL_USERS:
      return {
        ...state,
        pendingApprovals: { ...state.pendingApprovals, all: action.payload }
      };
    case GET_SINGLE_PENDING_APPROVAL_USER:
      return {
        ...state,
        pendingApprovals: {
          ...state.pendingApprovals,
          selected: action.payload
        }
      };

    case ADD_ORGANIZATION_DATA:
      return {
        ...state,
        pendingApprovals: {
          ...state.pendingApprovals,
          selected: action.payload
        }
      };
    case GET_ADMIN_ORGANIZATION_NAMES:
      return {
        ...state,
        totalAdminOrganizationalNames: action.payload
      };
    case MANAGE_ORGANIZATION_DATA:
      return {
        ...state
      };
    case GET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload
      };
    case GET_USER_PAGE_INFO:
      return {
        ...state,
        userPageInfo: action.payload
      };
    case GET_SEARCHED_USER_PROFILE:
      return {
        ...state,
        searchedUserProfile: action.payload
      };
    case ORG_ROLE_PROFILES_FOR_ADD:
      return {
        ...state,
        orgRoleProfilesForSave: action.payload
      };
    case GET_ALL_ANNOUNCEMENTS:
      return {
        ...state,
        announcementList: action.payload
      };
    case GET_MEGA_MENU_ORGANIZATION_NAMES:
      return {
        ...state,
        megaMenuOrganizationNames: action.payload
      };
    case GET_ORG_PARAMS:
      return {
        ...state,
        orgParams: action.payload
      };
    case GET_AUDIT_REPORT:
      return {
        ...state,
        auditReport: action.payload
      };
    case GET_EXCHANGE_RATES:
      return {
        ...state,
        exchangeRates: action.payload
      };
    case SEARCHED_RATES_FORM:
      return {
        ...state,
        searchedRatesForm: action.payload
      };
    case CHECK_EXCHANGE_RATES:
      return {
        ...state,
        exchangeRatesLoaded: action.payload
      };
    case CLEAR_EXCHANGE_RATES:
      return {
        ...state,
        exchangeRates: []
      };
    default:
      return state;
  }
};
