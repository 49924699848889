import { base as theme } from './base';

export const button = {
  MuiButton: {
    root: {
      fontSize: theme.typography.button.fontSize,
      fontWeight: 'bold',
      height: 51,
      padding: '13px 22px 15px 22px'
    },
    sizeSmall: {
      height: 30,
      fontSize: theme.typography.button.fontSizeSmall,
      padding: '6px 20px 7px 20px',
      borderRadius: 6
    },
    containedPrimary: {
      backgroundColor: theme.palette.button.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.button.primary.hover
      },
      '&$disabled': {
        opacity: 0.5,
        backgroundColor: theme.palette.button.primary.main,
        color: theme.palette.white
      }
    },
    outlinedSecondary: {
      color: theme.palette.button.secondary.main,
      borderColor: theme.palette.button.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.button.secondary.main,
        color: theme.palette.white
      },
      '&$disabled': {
        opacity: 0.5,
        color: theme.palette.button.secondary.main,
        borderColor: theme.palette.button.secondary.main
      }
    },
    label: {
      height: 20
    }
  }
};
