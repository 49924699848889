import { connect } from 'formik';

import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MenuList, TextField, ClickAwayListener } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

export const Combobox = props => {
  const {
    name,
    onChange,
    label,
    placeholder,
    disabled,
    formik: { touched, errors, status }
  } = props;
  const rootProps = { ...props };
  delete rootProps.onChange;
  delete rootProps.required;
  delete rootProps.error;

  const [open, setOpen] = useState(false);

  const error =
    touched[name] && Boolean(errors[name] || status[name] || status.root);
  const message = touched[name] && (errors[name] || status[name]);

  const inputProps = {
    error: props.error || error,
    required: props.required,
    helperText: message
  };
  return (
    <ClickAwayListener
      data-test='component-Combobox'
      onClickAway={() => setOpen(false)}>
      <Autocomplete
        data-test='component-autocomplete'
        autoComplete
        open={open}
        ListboxComponent={MenuList}
        getOptionLabel={option => option.label || placeholder}
        renderInput={params => {
          return (
            <TextField {...params} {...inputProps} fullWidth label={label} />
          );
        }}
        renderOption={option => option.label}
        closeIcon={<SearchIcon />}
        clearText='Search'
        onChange={(e, val) => {
          const event = {
            target: {
              name,
              value: val
            }
          };
          onChange(event);
        }}
        {...rootProps}
        onClick={() => (!disabled ? setOpen(true) : setOpen(false))}
      />
    </ClickAwayListener>
  );
};

Combobox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func
};

Combobox.defaultProps = {
  label: '',
  placeholder: '',
  error: false,
  disabled: false,
  required: false,
  onChange: () => {}
};
export default connect(Combobox);
