export const SET_FORMS_LIST_IN_MENU = 'SET_FORMS_LIST_IN_MENU';
export const SET_UPLOADFORMS_LIST_IN_MENU = 'SET_UPLOADFORMS_LIST_IN_MENU';
export const SAVE_ORG_NAME_AND_ID = 'SAVE_ORG_NAME_AND_ID';
export const GET_ROYALTY_DROPDOWN_DATA = 'GET_ROYALTY_DROPDOWN_DATA';
export const GET_INTES_DROPDOWN_DATA = 'GET_INTES_DROPDOWN_DATA';
export const GET_COUNTRIES_DROPDOWN_DATA = 'GET_COUNTRIES_DROPDOWN_DATA';
export const GET_TYPE_OF_FRAUD_DROPDOWN_DATA =
  'GET_TYPE_OF_FRAUD_DROPDOWN_DATA';
export const RESET_FORMS_LIST_IN_MENU = 'RESET_FORMS_LIST_IN_MENU';
export const SET_FORM_TYPE_IN_MENU = 'SET_FORM_TYPE_IN_MENU';
export const SAVE_SELECTED_FORM = 'SAVE_SELECTED_FORM';
