import React from 'react';
import { toast } from 'react-toastify';
import ToastComponent from './ToastComponent';

export const toastFunc = ({ content, config, toastType }) => {
  toast(<ToastComponent content={content} toastType={toastType} />, {
    closeButton: false,
    hideProgressBar: true,
    className: 'toast-container accept',
    bodyClassName: 'toast-body',
    ...config
  });
};
