import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { withFormik } from 'formik';
import { PageSkeleton, ActionBar, Modal } from '../../../../components';
import ContractNotes from './ContractNotes';
import RoyaltyRateScale from '../RoyaltyRateScale';
import RoyaltyExclusionTerms from './RoyaltyExclusionTerms';
import ContractInfo from './ContractInfo';
import {
  getParentOrganizations,
  getExclusionTypes,
  getChildOrganizationsByParentOrgId
} from '../../../../store/contracts/actions';

import { createContractApi } from '../../../../api/contracts';
import { createStatusFromErrors } from '../../../../utils/commonFunctions';
import { createContractSchema } from '../../../../constants/validations/admin/contracts';
import { toastFunc } from '../../../../components/ToastComponent/toastFunction';

const NavButtons = ({
  primaryButtonOnClick,
  secondaryButtonOnClick,
  isValid
}) => {
  return (
    <ActionBar
      primaryButton={{
        label: 'Submit',
        disabled: !isValid,
        onClick: primaryButtonOnClick
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: secondaryButtonOnClick
      }}
    />
  );
};

export class Create extends Component {
  state = {
    modalOpen: false,
    contractNotes: ''
  };

  componentDidMount() {
    this.props.getParentOrganizations();
    this.props.getExclusionTypes();
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }));
  };

  handleBlur = e => {
    const { handleBlur, status, setStatus } = this.props;
    e.persist();
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };

  addRoyaltyRateScale = data => {
    const { setFieldValue, values } = this.props;
    setFieldValue('royaltyRateScale', [...values.royaltyRateScale, data]);
  };

  addRoyaltyExclusionTerms = ({ exclusionCode }) => {
    const { setFieldValue, values } = this.props;
    setFieldValue('exclusionTypes', [
      ...values.exclusionTypes,
      {
        exclusionCode: exclusionCode.value,
        exclusionName: exclusionCode.label
      }
    ]);
  };

  handleChange = e => {
    const { setFieldValue, values } = this.props;
    const { name, value } = e.target;
    if (value && name === 'globalNetOrganizationId') {
      const { id } = value;
      this.props.getChildOrganizationsByParentOrgId(id);
      setFieldValue('organizationProfileList', {});
    }

    if (value && name === 'organizationProfileId') {
      let obj = {};
      value.length > 0 &&
        value.forEach(val => {
          const { id, label } = val;
          const newObj = { [id]: label };
          obj = { ...obj, ...newObj };
        });
      setFieldValue('organizationProfileList', {
        ...values.organizationProfileList,
        ...obj
      });
    }

    this.props.handleChange(e);
  };

  deleteChildOrganizationProfile = (e, key) => {
    const { setFieldValue, values } = this.props;
    const filterValues = {};
    Object.entries(values.organizationProfileList)
      .filter(([k]) => k !== key)
      .forEach(([k, v]) => {
        filterValues[k] = v;
      });
    setFieldValue('organizationProfileList', filterValues);
  };

  deleteRoyaltyRateScale = tierNumber => {
    const { setFieldValue, values } = this.props;
    setFieldValue(
      'royaltyRateScale',
      values.royaltyRateScale.filter(data => data.tireNumber !== tierNumber)
    );
  };

  deleteRoyaltyExclusionTerms = exclusionCode => {
    const { setFieldValue, values } = this.props;
    const filteredExclusionTypes = values.exclusionTypes.filter(
      type => String(type.exclusionCode) !== String(exclusionCode)
    );
    setFieldValue('exclusionTypes', filteredExclusionTypes);
  };

  onChildOrgUnSelectAll = () => {
    const { setFieldValue } = this.props;
    setFieldValue('organizationProfileList', {});
  };

  onChildOrgSelectAll = () => {
    const { setFieldValue, childOrganizations } = this.props;

    let obj = {};
    childOrganizations.length > 0 &&
      childOrganizations.forEach(val => {
        const { id, label } = val;
        if (id !== 0) {
          const newObj = { [id]: label };
          obj = { ...obj, ...newObj };
        }
      });
    setFieldValue('organizationProfileList', obj);
  };

  render() {
    const { organizationId } = this.props.match.params;

    const {
      errors,
      touched,
      isValid,
      values,
      status,
      parentOrganizations,
      childOrganizations,
      exclusionTypesList,
      handleChange,
      handleSubmit,
      history
    } = this.props;

    return (
      <PageSkeleton title='Create New Contract' data-test='component-create'>
        <Modal
          isOpen={this.state.modalOpen}
          submitBtnLabel='Confirm'
          onSubmit={() => {
            this.toggleModal();
            handleSubmit();
          }}
          onCancel={this.toggleModal}
          onClose={this.toggleModal}>
          <Typography color='secondary' variant='body1'>
            Are you sure you want to create a new Contract?
          </Typography>
        </Modal>
        <Grid container direction='row'>
          <Grid item md={12} className='contract-container'>
            <Grid container>
              <Grid item md={12}>
                <NavButtons
                  primaryButtonOnClick={this.toggleModal}
                  secondaryButtonOnClick={history.goBack}
                  isValid={isValid}
                />
              </Grid>
              <Grid item md={12}>
                <ContractInfo
                  organizationId={organizationId}
                  parentOrganizations={parentOrganizations}
                  childOrganizations={childOrganizations}
                  values={values}
                  errors={errors}
                  touched={touched}
                  status={status}
                  handleChange={this.handleChange}
                  handleBlur={this.handleBlur}
                  deleteChildOrganizationProfile={
                    this.deleteChildOrganizationProfile
                  }
                  onChildOrgSelectAll={this.onChildOrgSelectAll}
                  onChildOrgUnSelectAll={this.onChildOrgUnSelectAll}
                />
              </Grid>

              <Grid item md={12}>
                <RoyaltyExclusionTerms
                  exclusionTypesList={exclusionTypesList}
                  values={values}
                  handleChange={handleChange}
                  onAddRoyaltyExclusionTerms={this.addRoyaltyExclusionTerms}
                  onDeleteRoyaltyExclusionTerms={
                    this.deleteRoyaltyExclusionTerms
                  }
                />
              </Grid>
              <Grid item md={12}>
                <RoyaltyRateScale
                  mode='create'
                  royaltyRateScale={values.royaltyRateScale}
                  onAddRoyaltyRateScale={this.addRoyaltyRateScale}
                  onDeleteRoyaltyRateScale={this.deleteRoyaltyRateScale}
                />
              </Grid>

              <Grid item md={12}>
                <ContractNotes
                  values={values}
                  errors={errors}
                  touched={touched}
                  status={status}
                  handleChange={handleChange}
                  handleBlur={this.handleBlur}
                />
              </Grid>
              <Grid item md={12}>
                <NavButtons
                  primaryButtonOnClick={this.toggleModal}
                  secondaryButtonOnClick={history.goBack}
                  isValid={isValid}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageSkeleton>
    );
  }
}

const mapStateToProps = state => ({
  parentOrganizations: state.contracts.parentOrganizations,
  exclusionTypesList: state.contracts.exclusionTypesList,
  childOrganizations: state.contracts.childOrganizations
});

const formConfig = withFormik({
  mapPropsToValues: () => ({
    globalNetOrganizationId: '',
    organizationProfileId: '',
    organizationProfileList: {},
    effectiveStartDate: new Date(),
    isIncludeCentralSubmissionVolume: false,
    isAllowManulaRoyalityExclusion: false,
    effectiveEndDate: new Date(),
    notes: '',
    royaltyRateScale: [],
    exclusionTypes: [],
    contractType: ''
  }),
  mapPropsToStatus: () => ({}),
  isInitialValid: false,
  validateOnChange: true,
  validationSchema: createContractSchema,

  handleSubmit: (values, formikBag) => {
    if (Object.keys(values.organizationProfileList).length === 0) {
      return formikBag.setErrors({
        organizationProfileId:
          'Please enter a value for Organization Profile Name.'
      });
    }

    const body = {
      globalNetOrganizationId: values.globalNetOrganizationId.id,
      organizationProfileList: values.organizationProfileList,
      effectiveStartDate: moment(values.effectiveStartDate).format(
        'DD/MM/YYYY'
      ),
      effectiveEndDate: moment(values.effectiveEndDate).format('DD/MM/YYYY'),

      allowManualRoyalityExclusion: values.isAllowManulaRoyalityExclusion,
      includeCentralSubmissionVolume: values.isIncludeCentralSubmissionVolume,
      exclusionTypes: values.exclusionTypes,
      royaltyRateScale: values.royaltyRateScale,
      contractNotes: [
        {
          actions: [
            {
              actionName: 'Create Contract',
              notes: values.notes
            }
          ]
        }
      ],
      contractTypeCode: values.contractType
    };

    createContractApi(body).then(response => {
      if (response.status === 200) {
        toastFunc({
          content: 'Contract has been Created Successfully',
          config: { className: 'toast-container accept' }
        });

        formikBag.resetForm();
        formikBag.props.history.replace(
          `/admin/contract/${values.globalNetOrganizationId.id}`
        );
      } else if (response.status === 500) {
        formikBag.props.history.push('/error');
      } else {
        const err = {};
        err.response = response;
        formikBag.setStatus(createStatusFromErrors(err));
        if (
          response &&
          response.data &&
          response.data.errorMap &&
          response.data.errorMap['Effective Start Date and Effective End Date']
        ) {
          toastFunc({
            content: `${response.data.errorMap['Effective Start Date and Effective End Date']}`,
            config: { className: 'toast-container reject' },
            toastType: 'error'
          });
        } else if (response && response.data && response.data.errorMessage) {
          toastFunc({
            content: `${response.data.errorMessage}`,
            config: { className: 'toast-container reject' },
            toastType: 'error'
          });
        }
      }
    });
  }
});

export default connect(mapStateToProps, {
  getParentOrganizations,
  getExclusionTypes,
  getChildOrganizationsByParentOrgId
})(withRouter(formConfig(Create)));
