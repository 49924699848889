import { base as theme } from './base';

export const menu = {
  MuiMenu: {
    paper: {
      borderRadius: 0,
      border: `1px solid ${theme.palette.secondary.main}`,
      padding: 0,
      maxHeight: 'calc(100% - 400px)'
    }
  },

  MuiMenuItem: {
    root: {
      marginBottom: 1,
      fontSize: 15,
      width: '-webkit-fill-available'
    }
  },

  MuiList: {
    padding: {
      padding: '0px !important'
    }
  },
  MuiListItem: {
    root: {
      '&$focusVisible': {
        backgroundColor: `${theme.palette.input.selected} !important`
      },
      '&$selected': {
        backgroundColor: theme.palette.input.selected,
        color: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.input.selected
        }
      },
      '&:hover': {
        backgroundColor: theme.palette.input.selected
      }
    },
    button: {
      '&:hover': {
        backgroundColor: theme.palette.input.selected
      },
      '&:focusVisible': {
        backgroundColor: theme.palette.input.selected
      }
    }
  },
  MuiSelect: {
    root: {
      '&:focus $select': {}
    },
    select: {
      '&:focus': {
        backgroundColor: 'white'
      }
    }
  }
};
