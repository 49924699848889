import React, { Component } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './PageError.css';

import { LinkButton } from '../../components/material';
import { ReactComponent as SadSmiley } from '../../assets/icons/sad-smiley.svg';
import { resetFormErrors } from '../../store/error/actions';

export class PageError extends Component {
  static propTypes = {
    isUserAuthenticated: PropTypes.bool.isRequired,
    errorCode: PropTypes.string
  };

  static defaultProps = {
    errorCode: '“Error Code”'
  };

  componentWillUnmount() {
    this.props.resetFormErrors();
  }

  render() {
    const { isUserAuthenticated, errorCode } = this.props;
    return (
      <Paper elevation={8} className='page-error'>
        <SadSmiley />
        <Typography variant='h2' color='primary'>
          Oops!
        </Typography>
        <Typography variant='h5' color='secondary'>
          We are unable to serve your request at this time
        </Typography>
        <Typography variant='body1' className='error-description'>
          Please contact Help Desk with&nbsp;
          <Typography component='span' className='error-code'>
            {errorCode}
          </Typography>
        </Typography>
        <Typography variant='body1' className='error-description'>
          {this.props.errorMessage}
        </Typography>
        <LinkButton
          to={isUserAuthenticated ? '/home' : '/'}
          variant='outlined'
          color='secondary'
          size='small'
          className='action-button'>
          Go to {isUserAuthenticated ? 'Homepage' : 'Login'}
        </LinkButton>
      </Paper>
    );
  }
}

export const mapStateToProps = state => {
  return {
    isUserAuthenticated: state.auth.isUserAuthenticated,
    errorCode: state.errors.formErrors?.errorCode,
    errorMessage: state.errors.formErrors?.errorMessage
  };
};

const mapDispatchToProps = {
  resetFormErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(PageError);
