export const typography = {
  fontFamily: 'Meta Offc Pro, Arial',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,

  button: {
    textTransform: 'none',
    fontSize: 16,
    fontSizeSmall: 13
  },

  h1: {
    fontSize: 46,
    fontWeight: 300,
    lineHeight: '54px'
  },

  h2: {
    fontSize: 34,
    fontWeight: 300,
    lineHeight: '44px'
  },

  h3: {
    fontSize: 30,
    fontWeight: 700,
    lineHeight: '40px'
  },

  h4: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '34px'
  },

  h5: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '30px'
  },
  h6: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px'
  },

  body1: {
    fontSize: 16,
    lineHeight: '30px'
  },

  body2: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '22px'
  },

  caption: {
    fontSize: 12
  }
};
