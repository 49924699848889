import axios from 'axios';
import store from '../store';
import { logoutApi } from '../api/auth.js';

const endpoint = () => {
  if (!window.location.host.includes('localhost')) {
    return window._env_.REACT_APP_BACKEND_API;
  } else {
    return process.env.REACT_APP_BACKEND_API;
  }
};

const api = () => {
  const authState = store.getState().auth;
  const axiosInstance = authState.isUserAuthenticated
    ? axios.create({
        baseURL: endpoint(),
        withCredentials: true,
        headers: {
          'X-CSRF-TOKEN': authState.token
        }
      })
    : axios.create({
        baseURL: endpoint(),
        withCredentials: true
      });

  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      if (
        error.response &&
        error.response.status === 401 &&
        authState.isUserAuthenticated
      ) {
        store.dispatch({ type: 'LOGOUT' });
        store.dispatch({ type: 'GLOBAL_ERROR', error: error.response.data });
        if (error.response.config.url !== 'logout') {
          logoutApi();
        }
      }
      return Promise.reject(error);
    }
  );

  if (authState.isUserAuthenticated) {
    store.dispatch({ type: 'RESET_IDLE_TIMER', payload: true });
  }
  return axiosInstance;
};

export default api;
