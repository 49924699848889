/**
 * @function padNumber A utility function to ensure that a number will always
 * consist of two digits (by using leading zero)
 * @param {number} number Number to pad with zeros
 * @returns {string} string with number padded with zero
 */

export const padNumber = number => {
  return number < 10 ? '0' + number : number;
};
