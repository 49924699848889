import React, { Component } from 'react';
import { Typography, IconButton, TableRow, TableCell } from '@material-ui/core';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Combobox } from '../../../components/material';
import { CirclePlusIcon } from '../../../components/icons';

export class RoyaltyExclusionTermsForm extends Component {
  render() {
    const {
      exclusionTypesList,
      handleChange,
      handleSubmit,
      values,
      isValid
    } = this.props;

    return (
      <TableRow
        data-test='component-RoyaltyExclusionTermsForm'
        className='px-15 border-bottom'
        style={{
          backgroundColor: '#F2F9FC'
        }}>
        <TableCell>
          <Typography variant='h6' className='pl-15'>
            Exclusion Type
          </Typography>
        </TableCell>
        <TableCell>
          <Combobox
            name='exclusionCode'
            id='exclusionCode'
            label='Select'
            options={exclusionTypesList}
            className='bg-white'
            value={values.exclusionCode}
            onChange={handleChange}
          />
        </TableCell>
        <TableCell style={{ textAlign: 'right' }}>
          <IconButton
            style={{ marginRight: '-10px' }}
            disabled={!isValid}
            className='icon-button'
            onClick={handleSubmit}>
            <CirclePlusIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

const formConfig = withFormik({
  mapPropsToValues: () => ({
    exclusionCode: ''
  }),
  validationSchema: yup.object().shape({
    exclusionCode: yup.object().required()
  }),

  isInitialValid: false,
  mapPropsToStatus: () => ({}),
  handleSubmit: (values, formikBag) => {
    formikBag.resetForm();
    formikBag.props.onAddRoyaltyExclusionTerms(values);
  }
});

export default formConfig(RoyaltyExclusionTermsForm);
