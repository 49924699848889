import React, { Fragment, useRef, useState, useEffect } from 'react';

import { Grid, Button, Typography, FormHelperText } from '@material-ui/core';
import AnnouncementButtons from './AnnouncementButtons';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Switch } from '../../../components/material';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { announcementValidationSchema } from '../../../constants/validations/admin/announcement';
import { withFormik } from 'formik';
import FormikTextField from '../../../components/material/TextField';
import FormikDatePicker from '../../../components/material/DatePicker';
import formText from '../../../constants/text/admin';
import fieldErrors from '../../../constants/fieldErrors';
import { toastFunc } from '../../../components/ToastComponent/toastFunction';
import {
  readFileDataAsBase64,
  validateFileSizeAndFormat,
  createStatusFromErrors
} from '../../../utils/commonFunctions';
import moment from 'moment';

const styles = theme => ({
  input: {
    height: 140,
    alignItems: 'flex-start',
    font: '400 18px Meta Offc Pro'
  },
  inputFont: {
    font: '400 18px Meta Offc Pro'
  }
});
export const SelectedAnnouncement = withStyles(styles)(props => {
  const {
    classes,
    submitAnnouncement,
    values,
    handleReset,
    dirty,
    isValid
  } = props;
  const [isScheduled, setIsScheduled] = useState(values.scheduled);
  const [isStatusActive, setIsStatusActive] = useState(values.activeStatus);

  useEffect(() => {
    setIsScheduled(values.scheduled);
    setIsStatusActive(values.activeStatus);
  }, [values]);

  const removeImage = () => {
    const { setFieldValue } = props;
    setFieldValue('announcementImage', '');
    setFieldValue('announcementImageString', '');
  };
  const selectedAnnouncementUploadImageHandler = async event => {
    const file = event.target.files[0];
    const fileAsBase64 = await readFileDataAsBase64(event);
    const base64Result = fileAsBase64.split(',')[1];
    const imageType = fileAsBase64.substring(
      'data:image/'.length,
      fileAsBase64.indexOf(';base64')
    );
    const validMessage = validateFileSizeAndFormat(
      file.size,
      imageType,
      fieldErrors
    );
    if (validMessage === 'valid') {
      const { setFieldValue } = props;
      setFieldValue('announcementImage', fileAsBase64);
      setFieldValue('announcementImageString', base64Result);
      setFieldValue('imageErrorMessage', '');
    } else {
      const { setFieldValue } = props;
      setFieldValue('imageErrorMessage', validMessage);
    }
  };
  const fileInput = useRef(null);
  const handleBrowse = () => {
    fileInput.current.click();
  };

  const handleBlur = e => {
    const { handleBlur, status, setStatus } = props;
    e.persist();
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };

  const handleSchedule = e => {
    const { setFieldValue } = props;
    setIsScheduled(!isScheduled);
    setFieldValue('scheduled', !isScheduled);
  };

  const handleStatus = e => {
    const { setFieldValue } = props;
    setIsStatusActive(!isStatusActive);
    setFieldValue('activeStatus', !isStatusActive);
  };

  return (
    <Grid
      item
      xs
      className='announcements-listing-container'
      data-test='main-grid-component'>
      <form onSubmit={props.handleSubmit}>
        <AnnouncementButtons
          clearForm={handleReset}
          submitAnnouncement={submitAnnouncement}
          clearResetLabel='Reset'
          addUpdateLabel='Update'
          values={values}
          mode='update'
          dirty={dirty}
          valid={isValid}
        />
        <Grid
          container
          xs={12}
          direction='row'
          justify='space-between'
          className='announcements-selected-criteria'>
          <Grid item xs={6} style={{ marginTop: '5%' }}>
            <FormikTextField
              required
              id='announcement-title-selected'
              data-test='announcement-title'
              label='Announcement Title'
              name='announcementTitle'
              className='user-search-textbox'
              aria-describedby='selected-announcement-title-helper-text'
              helperText='Max Character Limit: 4000'
              InputLabelProps={{ shrink: true }}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6} style={{ marginTop: '3%' }}>
            <Switch
              data-test='form-switch-component'
              id='active-switch'
              label='Active'
              name='activeStatus'
              value=''
              className='login-form-switch'
              labelPlacement='top'
              checked={isStatusActive}
              onChange={handleStatus}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '3%' }}>
            <FormikTextField
              multiline
              required
              id='announcement-desc'
              data-test='announcement-desc-input'
              label='Announcement Description'
              name='announcementDescription'
              className='announcements-selected-description'
              aria-describedby='selected-annoucement-description-helper-text'
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: classes.input }}
              helperText='Max Character Limit: 4000'
              rowsMax={5}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6} style={{ marginTop: '3%' }}>
            <OutlinedInput
              readOnly
              data-test='lastname-input'
              label='Upload Image'
              name='lastname'
              className='user-search-textbox'
              value='Upload Image'
              style={{ paddingRight: 0 }}
              aria-describedby='standard-weight-helper-text'
              inputProps={{
                'aria-label': 'weight'
              }}
              endAdornment={
                <>
                  <input
                    ref={fileInput}
                    accept='image/*'
                    className={classes.input}
                    style={{ display: 'none' }}
                    id='selected-announcement-button-file'
                    type='file'
                    name='uploadImage'
                    onBlur={handleBlur}
                    onChange={e => selectedAnnouncementUploadImageHandler(e)}
                    onClick={event => {
                      event.target.value = null;
                    }}
                  />
                  <Button
                    style={{
                      fontWeight: 'normal',
                      height: 43,
                      borderRadius: 0,
                      width: '70%'
                    }}
                    color='secondary'
                    variant='contained'
                    onClick={handleBrowse}>
                    Browse
                  </Button>
                </>
              }
            />
            <FormHelperText
              id='selected-annoucement-image-helper-text'
              error={values.imageErrorMessage}
              className='form-helper-text'>
              {values.imageErrorMessage
                ? values.imageErrorMessage
                : formText.maxImageSize}
            </FormHelperText>
          </Grid>
          <Grid item xs={6} style={{ marginTop: '2%' }}>
            <Switch
              data-test='switch-component'
              id='schedule'
              label='Scheduled'
              name='scheduled'
              className='login-form-switch'
              value=''
              labelPlacement='top'
              checked={isScheduled}
              onChange={handleSchedule}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={5} style={{ marginTop: '3%' }}>
            {values.announcementImage ? (
              <Fragment>
                <img
                  src={values.announcementImage ? values.announcementImage : ''}
                  style={{ maxWidth: '260px', maxHeight: '180px' }}
                  alt='announcement banner'
                />
                <Typography
                  data-test='remove-text-component'
                  variant='h6'
                  className='announcements-image-remove-text'
                  onClick={removeImage}>
                  Remove
                </Typography>
              </Fragment>
            ) : null}
          </Grid>
          <Grid item xs={3} style={{ marginTop: '5%' }}>
            <FormikDatePicker
              autoOk
              disablePast
              required
              variant='inline'
              inputVariant='outlined'
              label='Start Date (dd/mm/yyyy)'
              format='dd/MM/yyyy'
              name='announcementStartDate'
              disabled={!isScheduled}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <CalendarIcon />
                  </InputAdornment>
                )
              }}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={3} style={{ marginTop: '5%', marginRight: '2%' }}>
            <FormikDatePicker
              autoOk
              disablePast
              required
              variant='inline'
              inputVariant='outlined'
              label='End Date (dd/mm/yyyy)'
              format='dd/MM/yyyy'
              name='announcementEndDate'
              disabled={!isScheduled}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <CalendarIcon />
                  </InputAdornment>
                )
              }}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
});

SelectedAnnouncement.propTypes = {};

const formConfig = withFormik({
  mapPropsToValues: ({ selectedAnnouncement }) => {
    let startDate;
    let endDate;
    if (selectedAnnouncement.scheduled === true) {
      startDate = selectedAnnouncement.announcementStartDate.split('/');
      endDate = selectedAnnouncement.announcementEndDate.split('/');
      return {
        announcementTitle: selectedAnnouncement.announcementTitle,
        imageErrorMessage: '',
        announcementDescription: selectedAnnouncement.announcementDescription,
        announcementStartDate: new Date(
          startDate[2],
          startDate[1] - 1,
          startDate[0]
        ),
        announcementImage: selectedAnnouncement.imageUrl || '',
        announcementImageString: selectedAnnouncement.announcementImage,
        announcementEndDate: new Date(endDate[2], endDate[1] - 1, endDate[0]),
        scheduled: selectedAnnouncement.scheduled,
        activeStatus: selectedAnnouncement.announcementStatus === 'A'
      };
    }
    return {
      announcementTitle: selectedAnnouncement.announcementTitle,
      imageErrorMessage: '',
      announcementDescription: selectedAnnouncement.announcementDescription,
      announcementStartDate: new Date(),
      announcementImage: selectedAnnouncement.imageUrl || '',
      announcementImageString: selectedAnnouncement.announcementImage,
      announcementEndDate: new Date(),
      scheduled: selectedAnnouncement.scheduled,
      activeStatus: selectedAnnouncement.announcementStatus === 'A'
    };
  },

  mapPropsToStatus: ({ selectedAnnouncement }) => ({
    scheduled: selectedAnnouncement.scheduled
  }),

  validationSchema: announcementValidationSchema,

  validateOnChange: true,
  enableReinitialize: true,

  handleSubmit: (values, formikBag) => {
    const announcement = {
      announcementId: formikBag.props.selectedAnnouncement.announcementId,
      announcementDescription: values.announcementDescription,
      announcementEndDate: moment(values.announcementEndDate).format(
        'DD/MM/YYYY'
      ),
      announcementImage: values.announcementImageString,
      announcementStartDate: moment(values.announcementStartDate).format(
        'DD/MM/YYYY'
      ),
      announcementTitle: values.announcementTitle,
      scheduled: values.scheduled,
      announcementStatus: values.activeStatus === true ? 'A' : 'I',
      imageType: 'png',
      imageName: 'testimage'
    };
    formikBag.props
      .updateAnnouncement(announcement)
      .then(response => {
        if (response.status === 200) {
          toastFunc({
            content: 'Announcement successfully updated',
            config: { className: 'toast-container accept' },
            toastType: 'success'
          });
          formikBag.resetForm();
        } else if (response.status === 500) {
          toastFunc({
            content: 'Error updating announcement',
            config: { className: 'toast-container reject' },
            toastType: 'error'
          });
        } else if (response.status === 400) {
          const err = {};
          err.response = response;
          formikBag.setStatus(createStatusFromErrors(err));
          const errorMessage = response.data.errorMap.announcementStatus
            ? response.data.errorMap.announcementStatus
            : null;
          if (errorMessage) {
            toastFunc({
              content: errorMessage,
              config: { className: 'toast-container reject' },
              toastType: 'error'
            });
          }
        } else {
          const errorMessage = response.data.errorMap.announcementStatus
            ? response.data.errorMap.announcementStatus
            : 'Error updating announcement';
          toastFunc({
            content: errorMessage,
            config: { className: 'toast-container reject' },
            toastType: 'error'
          });
        }
      })
      .catch(err => {
        formikBag.setStatus(createStatusFromErrors(err));
      });
  }
});

export default formConfig(SelectedAnnouncement);
