/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines-per-function */
import React, { Component } from 'react';
import { Typography, Grid, Button, Tooltip } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login } from '../../store/auth';
import { setUserProfile } from '../../store/user';
import { loginApi } from '../../api/auth';
import { toastFunc } from '../../components/ToastComponent/toastFunction';
import { Switch, LinkButton, TextField } from '../../components/material';
import { landingPageText } from '../../constants/text';
import { ErrorContainer } from '../../components';
import { createStatusFromErrors } from '../../utils/commonFunctions';
import { loginValidationSchema } from '../../constants/validations/login';
import { setGlobalError } from '../../store/error/actions';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { tokenConfigLength } from '../../constants/tokenConfig';
import errors from '../../constants/fieldErrors';
import { passwordMinLength } from '../../constants/passwordRule';
import CircularProgress from '@mui/material/CircularProgress';
class LoginForm extends Component {
  state = {
    submitOnce: false,
    loader: false
  };
  componentDidMount() {
    document.title = 'GlobalNet | Login';
    localStorage.clear();
  }

  componentDidUpdate(prevProps) {
    if (this.props.status !== prevProps.status && this.props.status) {
      this.setState({ submitOnce: false, loader: false });
    }
  }

  handleBlur = e => {
    const {
      handleBlur,
      status,
      setStatus,
      globalError,
      setGlobalError
    } = this.props;
    e.persist();
    setStatus({ ...status, root: '', [e.target.name]: '' });
    if (globalError.errorMessage) {
      setGlobalError('');
    }
    handleBlur(e);
  };

  handleSubmitForm = e => {
    e.preventDefault();
    this.setState({ submitOnce: true, loader: true });
    this.props.handleSubmit(e);
    setTimeout(() => {
      this.setState({ loader: false });
    }, 5000);
  };

  render() {
    const { status, globalError, values, handleChange } = this.props;
    const { submitOnce, loader } = this.state;
    const { loginOktaToken } = errors;
    const internalUserStatus =
      values.internalUser && values.internalUser.length > 0;
    const internalUserActive =
      values.internalUser && values.internalUser.length > 0;
    const isDisabled =
      submitOnce ||
      (internalUserActive
        ? !(
            values.userId.length > 1 &&
            values.password.length > passwordMinLength - 1 &&
            values.oktaToken.length > 1
          )
        : !(values.userId.length > 1 && values.password.length > 1));
    return (
      <>
        <form
          noValidate
          data-test='component-login-form'
          className='login-form'
          aria-label='login'
          onSubmit={this.handleSubmitForm}>
          <Grid
            container
            direction='column'
            data-test='component-login-form-column'>
            <ErrorContainer
              message={
                status.root
                  ? status.root
                  : globalError.errorMessage
                  ? globalError.errorMessage
                  : ''
              }
              data-test='component-error-container'
            />

            <Grid
              container
              alignItems='center'
              className='login-form-title'
              data-test='login-form-title'>
              <Typography
                data-test='form-title'
                variant='h5'
                aria-label='login-form form-title'>
                {landingPageText.loginToYourAccount}
              </Typography>
              <Switch
                id='internalUser'
                label='Internal User'
                name='internalUser'
                value='internalUser'
                checked={Boolean(values.internalUser.length)}
                className='login-form-switch-layout'
                data-test='login-form-switch'
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              container
              direction='column'
              data-test='login-form-input'>
              <Grid item container data-test='login-form-input-grid'>
                <TextField
                  fullWidth
                  required
                  id='username'
                  className='form-input'
                  data-test='user-id-input'
                  label={landingPageText.userIdLabel}
                  name='userId'
                  onBlur={this.handleBlur}
                />
              </Grid>
              <Grid
                item
                md
                data-test='user-id-input-container'
                className={internalUserStatus ? 'two-column-break ' : ''}>
                <TextField
                  required
                  fullWidth={!internalUserStatus}
                  id='password'
                  className='form-input form-input-align password-input'
                  data-test='password-input'
                  label={landingPageText.passwordLabel}
                  type='password'
                  name='password'
                  onBlur={this.handleBlur}
                />
                {internalUserStatus && (
                  <Tooltip title={loginOktaToken.empty}>
                    <TextField
                      required
                      id='oktaToken'
                      className='form-input okta-input `${}`'
                      data-test='okta-token-input'
                      label='Okta Token'
                      name='oktaToken'
                      inputProps={{
                        maxLength: tokenConfigLength,
                        inputMode: 'numeric'
                      }}
                      onChange={event => {
                        const regex = /^[0-9]*$/;
                        if (
                          event.target.value === '' ||
                          regex.test(event.target.value)
                        ) {
                          handleChange(event);
                        }
                      }}
                      onBlur={this.handleBlur}
                    />
                  </Tooltip>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              className='login-form-actions'
              data-test='login-form-actions'>
              <Grid
                container
                justify='space-between'
                direction='row-reverse'
                data-test='login-form-actions-grid'>
                <Grid
                  item
                  container
                  md={6}
                  justify='flex-end'
                  alignItems='center'
                  data-test='login-form-actions-flex'>
                  <Button
                    fullWidth
                    disabled={isDisabled}
                    color='primary'
                    variant='contained'
                    type='submit'
                    data-test='login-button'>
                    {isDisabled && loader ? (
                      <CircularProgress style={{ color: 'white' }} />
                    ) : (
                      <>{landingPageText.loginButton}</>
                    )}
                  </Button>
                </Grid>
                <Grid item sm={6} data-test='grid-login-button'>
                  <Grid
                    container
                    direction='column'
                    data-test='grid-forgot-user-id-link'>
                    <Typography
                      variant='body2'
                      data-test='grid-forgot-user-id-body'>
                      <Link
                        to={values.internalUser.length ? '' : '/forgot-userid'}
                        data-test='forgot-user-id-link'
                        className={
                          values.internalUser.length
                            ? 'disable-forget-container'
                            : ''
                        }>
                        {landingPageText.forgotUserId}
                      </Link>
                    </Typography>
                    <Typography
                      varant='body2'
                      data-test='grid-forgot-user-id-body2'>
                      <Link
                        to={
                          values.internalUser.length ? '' : '/forgot-password'
                        }
                        data-test='reset-password-link'
                        className={
                          values.internalUser.length
                            ? 'disable-forget-container'
                            : ''
                        }>
                        {landingPageText.forgotPassword}
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <div className='seperator' data-test='grid-forgot-user-id-seperator' />
        <LinkButton
          fullWidth
          to='/register'
          variant='outlined'
          color='secondary'
          data-test='signup-button'>
          {landingPageText.firstTimeVisitor}
          <InfoOutlinedIcon
            className='fa-circle-info'
            data-test='info-icon-button'
            fontSize='small'
          />
        </LinkButton>
        <span className='first-Time-Visitor-Remark'>
          <InfoOutlinedIcon
            className='fa-circle-remark'
            data-test='info-icon-remark'
            style={{ fontSize: '100%', paddingRight: '3px' }}
          />
          {landingPageText.firstTimeVisitorRemark}
        </span>
      </>
    );
  }
}

LoginForm.propTypes = {
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

const formConfig = withFormik({
  mapPropsToValues: () => ({
    userId: '',
    password: '',
    oktaToken: '',
    internalUser: []
  }),

  mapPropsToStatus: () => ({}),

  validationSchema: loginValidationSchema,

  validateOnChange: false,

  handleSubmit: (values, formikBag) => {
    const user = {
      userName: values.userId,
      password: values.password,
      intUserInd: values.internalUser.length ? 'Y' : 'N',
      oktaToken: values.internalUser.length ? values.oktaToken : ''
    };
    loginApi(user)
      .then(res => {
        if (res.data.usrChangePass) {
          const data = {
            userLoginId: res.data.usrLognId,
            userProfileId: res.data.usrProfId,
            changePasswordMsg: res.data.changePassMsg
          };
          formikBag.props.setUserProfile(data);
        } else {
          formikBag.props.login(
            res.headers['x-csrf-token'],
            res.data.ddidStatus == 'review'
          );
          formikBag.props.setUserProfile(res.data);
        }
      })
      .catch(err => {
        const {
          data: { errorMessage, errorCode },
          status
        } = err.response;
        if (errorCode == 403 && errorMessage == 'Legacy user') {
          formikBag.props.history.push('/userid-verification');
        } else if (status === 401 || status === 400) {
          formikBag.setStatus(createStatusFromErrors(err));
        } else if (status === 500) {
          toastFunc({
            content: errorMessage,
            config: { className: 'toast-container reject' },
            toastType: 'error'
          });
        }
      });
  }
});

const mapStateToProps = state => ({
  error: state.errors.formErrors,
  globalError: state.errors.globalError
});

const mapDispatchToProps = {
  login,
  setUserProfile,
  setGlobalError
};

export const LoginFormWithFormik = withRouter(formConfig(LoginForm));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormWithFormik);
