import React from 'react';
import { Grid } from '@material-ui/core';
import EmptyDetailsScreen from '../../../components/EmptyDetailsScreen/EmptyDetailsScreen';

function NoSelectedAnnouncement(props) {
  return (
    <Grid
      container
      item
      direction='column'
      xs={5}
      className='announcements-listing-container'
      style={{ marginRight: '1%', border: 'none' }}
      wrap='nowrap'>
      <Grid item className='announcements-list-header'>
        <p>Announcements List</p>
      </Grid>
      <EmptyDetailsScreen
        content='Select Announcement to View Details'
        arrowDirection='Up'
        contentVariant='h4'
      />
    </Grid>
  );
}

NoSelectedAnnouncement.propTypes = {};

export default NoSelectedAnnouncement;
