import { base as theme } from './base';

export const Switch = {
  MuiSwitch: {
    root: {
      padding: 0,
      height: 24,
      width: 53
    },
    switchBase: {
      padding: 0,
      '&$checked': {
        transform: 'translateX(30px)',
        '& + $track': {
          backgroundColor: `${theme.palette.success.main} !important`,
          opacity: 1,
          border: 'none'
        }
      },
      '&$disabled': {
        '& + $track': {
          opacity: '0.4 !important'
        }
      }
    },
    track: {
      height: 24,
      width: 53,
      opacity: 1,
      backgroundColor: theme.palette.text.primary,
      borderRadius: 13,
      '&:focus': {
        outline: '-webkit-focus-ring-color auto 1px'
      }
    },
    thumb: {
      height: 14,
      width: 14,
      margin: 5,
      position: 'relative',
      backgroundColor: theme.palette.white,
      '&:after': {
        content: '"No"',
        color: theme.palette.white,
        fontSize: 13,
        display: 'inherit',
        position: 'absolute',
        left: 20,
        lineHeight: '1.2'
      },
      '&:before': {
        content: '"Yes"',
        color: theme.palette.white,
        fontSize: 13,
        display: 'inherit',
        position: 'absolute',
        right: 20,
        lineHeight: '1.2'
      }
    }
  }
};
