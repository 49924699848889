import React, { Component, Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextFieldSearch from './TextField';
import { getMegaMenuOrganizationNames } from '../../../store/admin/actions';
import { sortObject } from '../../../utils/commonFunctions';

export class OrganizationsList extends Component {
  state = {
    searchText: ''
  };

  handleChange = e => {
    this.setState({
      searchText: e.target.value
    });
  };

  getOrgIdFromUrl = url => {
    const urlId = url.split('/');
    return urlId[3];
  };

  render() {
    const { history } = this.props;
    let megaMenuOrganizationNames = this.props.megaMenuOrganizationNames.filter(
      org => {
        return org.id !== 0;
      }
    );

    if (this.state.searchText) {
      megaMenuOrganizationNames = megaMenuOrganizationNames.filter(org => {
        return org.label
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase());
      });
    }

    megaMenuOrganizationNames =
      megaMenuOrganizationNames.length > 0 &&
      sortObject(megaMenuOrganizationNames, 'label');

    return (
      <Fragment>
        <Grid
          container
          direction='column'
          className='contract-list-container'
          data-test='component-Parent-Organizations'>
          <Grid item md={12} data-test='header-component'>
            <Grid container className='list-header' data-test='list-header'>
              <Grid item md={12} data-test='inner-header'>
                <TextFieldSearch
                  value={this.state.searchText}
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <Grid item md={12} data-test='selected-Organizations'>
              {!megaMenuOrganizationNames && this.state.searchText !== '' && (
                <Grid
                  container
                  className='parent-organization-no-records-container'
                  data-test='parent-organization-no-records-container'>
                  <Grid item md={12} data-test='selected-second-Organization'>
                    <Typography
                      variant='h6'
                      className='no-records-item'
                      data-test='no-records-item'>
                      No Records Found
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {megaMenuOrganizationNames.length > 0 && (
                <Grid container className='org-scroll' data-test='org-scroll'>
                  {megaMenuOrganizationNames.map(list => {
                    return (
                      <Grid
                        key={list.id}
                        item
                        md={12}
                        data-test='contract-org-container'
                        className={`contract-org-container ${this.getOrgIdFromUrl(
                          history.location.pathname
                        ) === String(list.id) && 'active'}`}
                        onClick={() => {
                          this.props.setOrganisationID(`${list.id}`);
                          this.props.setOrganisationName(`${list.label}`);
                        }}>
                        <Typography
                          variant='h6'
                          className='search-list'
                          data-test='search-list'>
                          {list.label}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  megaMenuOrganizationNames: state.admin.megaMenuOrganizationNames
});

export default connect(mapStateToProps, { getMegaMenuOrganizationNames })(
  withRouter(OrganizationsList)
);
