import React from 'react';
import { Grid } from '@material-ui/core';
import { Dropdown, TextField } from '../../components/material';
import BlockHeader from './BlockHeader';
import PersonalDetailsReadOnly from './PersonalDetailsReadOnly';
import PropTypes from 'prop-types';
import { salutationOptions } from '../../constants/text';

const PersonalDetails = props => {
  const {
    values,
    handleChange,
    edit,
    errors,
    touched,
    handleBlur,
    impersonate
  } = props;
  return (
    <div aria-label='Personal Data Section'>
      <BlockHeader title='Personal Data' />
      {edit === true && !impersonate ? (
        <Grid
          container
          data-test='editable-personal-details'
          className='personal-data-inputs-container'>
          <Grid item className='besic-details-grid-item' md={4}>
            <TextField
              required
              fullWidth
              disabled
              label='User ID'
              data-test='userId'
              name='userLoginId'
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item className='besic-details-grid-item' md={4}>
            <div className='profile-details-dropdown-container'>
              <Dropdown
                fullWidth
                options={salutationOptions}
                placeholder='Please Select'
                label='Salutation'
                id='salutation'
                name='salutation'
                data-test='salutation'
                value={values.salutation}
                error={errors.salutation && touched.salutation}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </Grid>
          <Grid item className='besic-details-grid-item' md={4} />
          <Grid item className='besic-details-grid-item' md={4}>
            <TextField
              required
              name='firstName'
              label='First Name'
              data-test='firstName'
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item className='besic-details-grid-item' md={4}>
            <TextField
              name='middleName'
              label='Middle Name'
              data-test='middleName'
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
          <Grid item className='besic-details-grid-item' md={4}>
            <TextField
              required
              name='lastName'
              label='Last Name'
              data-test='lastName'
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
          <Grid item className='besic-details-grid-item' md={4}>
            <TextField
              required
              name='emailId'
              label='Email ID'
              data-test='emailId'
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
          <Grid item className='besic-details-grid-item' md={4}>
            <TextField
              required
              name='jobTitle'
              label='Job Title'
              data-test='jobTitle'
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
          <Grid item className='besic-details-grid-item' md={4} />
        </Grid>
      ) : (
        <PersonalDetailsReadOnly
          data-test='readonly-personal-details'
          values={values}
        />
      )}
    </div>
  );
};
PersonalDetails.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired
};
export default PersonalDetails;
