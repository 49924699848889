import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import DisplayFormsList from './DisplayFormsList';

const DisplayQuaterForms = props => {
  const { forms, handler } = props;
  const formTypes = [
    'Income Statement',
    'Balance Sheet Schedule',
    'Balance Sheet'
  ];
  // FormData Object holds the forms with respect to form types
  const formData = {};
  formTypes.forEach(form => {
    formData[form] = [];
  });
  // Populate formData object with key as form type and value as forms array
  forms.forEach(form => {
    const { formName } = form;
    const data = formName.split(':');
    formData[data[0]].push({ ...form, formName: data[1] });
  });
  return (
    <Grid
      container
      justify='flex-start'
      className='data-entry-form-container'
      data-test='data-forms-container'
      wrap='wrap'
      spacing={4}
      style={{ marginTop: '0px' }}>
      {formTypes.map((form, index) => {
        return (
          formData[form].length > 0 && (
            <Grid key={index} item>
              <Typography variant='h5' className='header-text-font'>
                {form}
              </Typography>
              <DisplayFormsList forms={formData[form]} handler={handler} />
            </Grid>
          )
        );
      })}
    </Grid>
  );
};

export default DisplayQuaterForms;
