import {
  SEARCH_ORGANIZATION_START,
  SEARCH_ORGANIZATION_SUCCESS,
  SEARCH_ORGANIZATION_ERROR,
  GET_TOTAL_ORGANIZATION_REGIONS,
  GET_TOTAL_ORGANIZATION_COUNTRIES,
  GET_TOTAL_ORGANIZATION_TYPES,
  GET_TOTAL_CURRENCIES,
  GET_TOTAL_PRODUCTS,
  ADD_PRODUCT_LINE,
  GET_ORGANIZATION,
  CLEAR_ORGANIZATION,
  GET_SEARCHED_ORGANIZATION_PROFILE,
  GET_TOTAL_EXCHANGE_REQUEST
} from './types';

export const initialState = {
  organizationIsSearching: false,
  organizationSearchError: false,
  organizationSearchList: [],
  totalOrganizationalRegions: [],
  totalOrganizationalCountries: [],
  totalOrganizationalTypes: [],
  totalCurrencies: [],
  totalProducts: {
    consumer: [],
    commercial: []
  },
  orgProfile: {},
  searchedOrganization: {},
  totalExchangeRequest: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_ORGANIZATION_START:
      return {
        ...state,
        organizationIsSearching: true,
        organizationSearchError: false,
        organizationSearchList: []
      };
    case SEARCH_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        organizationIsSearching: false,
        organizationSearchError: false,
        organizationSearchList: action.payload
      };
    }
    case GET_SEARCHED_ORGANIZATION_PROFILE: {
      return {
        ...state,
        searchedOrganization: action.payload
      };
    }
    case SEARCH_ORGANIZATION_ERROR: {
      return {
        ...state,
        organizationIsSearching: false,
        organizationSearchError: true,
        organizationSearchList: []
      };
    }
    case GET_TOTAL_ORGANIZATION_REGIONS:
      return {
        ...state,
        totalOrganizationalRegions: action.payload
      };
    case GET_TOTAL_ORGANIZATION_COUNTRIES:
      return {
        ...state,
        totalOrganizationalCountries: action.payload
      };
    case GET_TOTAL_ORGANIZATION_TYPES:
      return {
        ...state,
        totalOrganizationalTypes: action.payload
      };

    case GET_TOTAL_CURRENCIES:
      return {
        ...state,
        totalCurrencies: action.payload
      };

    case GET_TOTAL_PRODUCTS:
      return {
        ...state,
        totalProducts: action.payload
      };

    case ADD_PRODUCT_LINE:
      return {
        ...state,
        totalProducts: action.payload
      };

    case GET_ORGANIZATION:
      return {
        ...state,
        orgProfile: action.payload
      };

    case CLEAR_ORGANIZATION:
      return {
        ...state,
        orgProfile: {}
      };
    case GET_TOTAL_EXCHANGE_REQUEST:
      return {
        ...state,
        totalExchangeRequest: action.payload
      };

    default:
      return state;
  }
};
