import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import './ProgressStone.css';
import { ReactComponent as PendingIcon } from '../../assets/icons/pending.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-icon.svg';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
const QontoConnector = withStyles({
  active: {
    '& $line': {
      borderColor: '#2477AB'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#2477AB'
    }
  },
  line: {
    borderColor: '#C5C8C8',
    borderWidth: 2
  }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: '#C5C8C8',
    height: 28,
    display: 'flex',
    borderRadius: '18px',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    backgroundColor: 'white',
    fontSize: '14px',
    padding: '0 8px 0 2px'
  },
  active: {
    color: '#fff',
    backgroundColor: '#2477AB'
  },

  completed: {
    color: '#fff',
    backgroundColor: '#2477AB'
  }
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  const rootClasses = [classes.root];

  const icons = {
    1: (
      <>
        <Grid
          item
          container
          alignItems='center'
          justify='space-around'
          className='icon-container'>
          <Link to='/manage-data/upload'>
            {!active && !completed && <PendingIcon data-test='pending-icon' />}
            {active && <EditIcon data-test='edit-icon' />}
            {completed && <SuccessIcon data-test='success-icon' />}
            <Grid item>
              <Typography variant='caption'>Upload File</Typography>
            </Grid>
          </Link>
        </Grid>
      </>
    ),
    2: (
      <>
        <Grid
          item
          container
          alignItems='center'
          justify='space-around'
          className='icon-container'>
          {!active && !completed && <PendingIcon data-test='pending-icon' />}
          {active && <EditIcon data-test='edit-icon' />}
          {completed && <SuccessIcon data-test='success-icon' />}
          <Grid item>
            <Typography variant='caption'>Review Data</Typography>
          </Grid>
        </Grid>
      </>
    ),
    3: (
      <>
        <Grid
          item
          container
          alignItems='center'
          justify='space-around'
          className='icon-container'>
          {!active && !completed && <PendingIcon data-test='pending-icon' />}
          {active && <EditIcon data-test='edit-icon' />}
          {completed && <SuccessIcon data-test='success-icon' />}
          <Grid item>
            <Typography variant='caption'>Submit Data</Typography>
          </Grid>
        </Grid>
      </>
    )
  };

  if (active) {
    rootClasses.push(classes.active);
  }
  if (completed) {
    rootClasses.push(classes.completed);
  }

  return (
    <>
      <div className={rootClasses.join(' ')}>{icons[String(props.icon)]}</div>
    </>
  );
}

const ProgressStone = props => {
  const { activeStep } = props;

  const steps = ['', '', ''];

  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      data-test='component-progress-stone'>
      <Grid item md={12} data-test='sub-component'>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
          className='stepper-wrapper'
          data-test='stepper-wrapper'>
          {steps.map(label => (
            <Step key={label} data-test='step-wrapper'>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};

ProgressStone.propTypes = {
  activeStep: PropTypes.number.isRequired
};
QontoStepIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  icon: PropTypes.number.isRequired
};
export default ProgressStone;
