import React from 'react';
import { Typography } from '@material-ui/core';
import './LastUpdated.css';

interface Props {
  type: 'approve' | 'modify';
  time: string;
  user: string;
}

const LastUpdated = (props: Props) => {
  const { type, time, user } = props;
  return (
    <>
      <Typography variant='body2' className='last-updated-text'>
        {type === 'approve'
          ? 'Approved on (dd/mm/yyyy)'
          : 'Last modified (dd/mm/yyyy)'}
      </Typography>
      <Typography variant='h6' style={{ fontWeight: 'bold', color: '#022A48' }}>
        {time}
        <Typography
          variant='body2'
          display='inline'
          className='last-updated-text'>
          &nbsp;by&nbsp;
        </Typography>
        {user}
      </Typography>
    </>
  );
};

export default LastUpdated;
