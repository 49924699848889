import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { LinkButton } from '../../components/material';
import { SuccessIcon } from '../../components/icons';

const Success = props => {
  const { requestName } = props;
  const userIdOrTempPasswordReq =
    requestName === 'User ID' || requestName === 'Temporary Password';
  const changePasswordReq = requestName === 'Change Password';
  return (
    <div
      className='w-100'
      style={{ alignSelf: 'center' }}
      data-test='success-container'>
      <div style={{ marginBottom: 20 }} data-test='success-icon-container'>
        <SuccessIcon data-test='success-icon' />
      </div>
      <Typography
        data-test='success-icon-typo'
        align='center'
        style={{
          color: '#68C172',
          marginBottom: 30,
          lineHeight: '30px',
          marginTop: 20,
          fontSize: 25
        }}>
        {userIdOrTempPasswordReq &&
          'Your request has been submitted successfully'}
        {changePasswordReq && 'New Password created successfully.'}
      </Typography>
      <Typography
        align='center'
        style={{ marginBottom: 30, fontSize: 18 }}
        data-test='success-icon-typo'>
        {userIdOrTempPasswordReq &&
          `You will receive an email with ${requestName}.`}
        {changePasswordReq && 'Please login with the new password.'}
      </Typography>
      <Grid container justify='center' data-test='success-link-container'>
        <LinkButton
          to='/'
          color='primary'
          variant='contained'
          data-test='success-link'
          onClick={() => changePasswordReq && props.loginClickHandler()}>
          Go to Login
        </LinkButton>
      </Grid>
    </div>
  );
};

export default Success;
