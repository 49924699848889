import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const PersonalDetailsReadOnly = props => {
  const { values } = props;
  return (
    <Grid
      container
      className='personal-data-inputs-container'
      data-test='personal-data-read-only'>
      <Grid item className='besic-details-grid-item' md={3}>
        <Typography variant='body2' className='personal-data-field-name'>
          Salutation
        </Typography>
        <Typography variant='h6' className='personal-data-field-value'>
          {!values.salutation ||
          values.salutation === '' ||
          values.salutation === null ||
          values.salutation === 'none'
            ? '-'
            : values.salutation}
        </Typography>
      </Grid>
      <Grid item className='besic-details-grid-item' md={3}>
        <Typography variant='body2' className='personal-data-field-name'>
          First Name
        </Typography>
        <Typography variant='h6' className='personal-data-field-value'>
          {values.firstName}
        </Typography>
      </Grid>
      <Grid item className='besic-details-grid-item' md={3}>
        <Typography variant='body2' className='personal-data-field-name'>
          Middle Name
        </Typography>
        <Typography variant='h6' className='personal-data-field-value'>
          {values.middleName && values.middleName !== ''
            ? values.middleName
            : '-'}
        </Typography>
      </Grid>
      <Grid item className='besic-details-grid-item' md={3}>
        <Typography variant='body2' className='personal-data-field-name'>
          Last name
        </Typography>
        <Typography variant='h6' className='personal-data-field-value'>
          {values.lastName}
        </Typography>
      </Grid>
      {/* 2nd row */}
      <Grid item className='besic-details-grid-item' md={3}>
        <Typography variant='body2' className='personal-data-field-name'>
          Email ID
        </Typography>
        <Typography variant='h6' className='personal-data-field-value'>
          {values.emailId}
        </Typography>
      </Grid>
      <Grid item className='besic-details-grid-item' md={3}>
        <Typography variant='body2' className='personal-data-field-name'>
          Job Title
        </Typography>
        <Typography variant='h6' className='personal-data-field-value'>
          {values.jobTitle}
        </Typography>
      </Grid>
      <Grid item className='besic-details-grid-item' md={6} />
    </Grid>
  );
};

PersonalDetailsReadOnly.propTypes = {
  values: PropTypes.object.isRequired
};
export default PersonalDetailsReadOnly;
