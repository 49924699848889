import React, { Fragment, useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Button
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { TextField, LinkButton } from '../../components/material';
import { withFormik } from 'formik';
import { ErrorContainer, FieldValidations } from '../../components';
import Success from './Success';
import { passwordRequirement } from '../../constants/passwordRule';
import { updatePasswordSchema } from '../../constants/validations/updatePassword';
import { landingPageText } from '../../constants/text';
import { resetUserProfile, setUserProfile } from '../../store/user';
import { changePasswordApi } from '../../api/user';
import { createStatusFromErrors } from '../../utils/commonFunctions';

const passwordRequirements = passwordRequirement;

export const ChangePassword = props => {
  const {
    values,
    status,
    isValid,
    dirty,
    handleSubmit,
    setUserProfile,
    resetUserProfile,
    userLoginId,
    userProfileId,
    changePasswordMsg
  } = props;
  const [passwordHidden, setPasswordHidden] = useState(true);
  const toggleShowPassword = () => {
    setPasswordHidden(prevState => !prevState);
  };

  const handlePasswordBlur = e => {
    const { handleBlur, status, setStatus } = props;
    e.persist();
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };

  const handleCancelBtnClick = () => {
    resetUserProfile();
  };

  const refreshHandler = () => {
    localStorage.setItem('refresh', true);
    localStorage.setItem(
      'user',
      JSON.stringify({ userLoginId, userProfileId, changePasswordMsg })
    );
  };

  useEffect(() => {
    window.addEventListener('beforeunload', refreshHandler);
    document.title = 'GlobalNet | Change Password';
    const refresh = localStorage.getItem('refresh');
    const user = JSON.parse(localStorage.getItem('user') || null);
    if (refresh && user) {
      setUserProfile(user);
      localStorage.clear();
    }

    return () => {
      window.removeEventListener('beforeunload', refreshHandler);
    };
  }, [userLoginId, userProfileId, changePasswordMsg]);

  const renderForm = () => {
    return (
      <Fragment>
        <Grid container direction='column' className='login-form-title'>
          <Typography
            data-test='change-password-title'
            variant='h5'
            aria-label='change-password-form change-password-title'>
            {landingPageText.changePassword}
          </Typography>
          <Typography
            data-test='change-password-text'
            variant='h6'
            aria-label='change-password-form change-password-text'>
            {changePasswordMsg}
          </Typography>
        </Grid>
        <Grid container direction='column' className='change-password-actions'>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextField
                fullWidth
                required
                disabled
                type='text'
                name='userId'
                id='userId'
                className='form-input'
                label='USER ID'
                value={userLoginId}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                required
                type='password'
                className='form-input'
                label={
                  changePasswordMsg.includes('expired')
                    ? 'Old Password'
                    : 'Temporary Password'
                }
                name='currentPassword'
                onBlur={handlePasswordBlur}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextField
                fullWidth
                required
                className='form-input'
                type={passwordHidden ? 'password' : 'text'}
                label='New Password'
                name='password'
                onBlur={handlePasswordBlur}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                required
                id='confirmPassword'
                type={passwordHidden ? 'password' : 'text'}
                label='Confirm New Password'
                name='conformPassword'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        edge='end'
                        onClick={toggleShowPassword}
                        onMouseDown={toggleShowPassword}>
                        {passwordHidden ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                onBlur={handlePasswordBlur}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <FieldValidations
                title={landingPageText.passwordRequirements}
                validations={passwordRequirements}
                value={values.password}
                extra={[
                  {
                    label: 'Password and Confirm password should match',
                    valid: Boolean(values.password === values.conformPassword)
                  }
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  };
  if (status.isPasswordUpdated) {
    return (
      <Success
        requestName='Change Password'
        loginClickHandler={resetUserProfile}
      />
    );
  }
  return (
    <>
      <form
        noValidate
        data-test='component-change-password-form'
        className='login-form'
        aria-label='change-password'
        onSubmit={handleSubmit}>
        <Grid container direction='column'>
          <ErrorContainer
            message={status.root}
            data-test='component-error-container'
          />
          {renderForm()}
          <Grid item className='change-password-actions'>
            <Grid container justify='flex-start' spacing={2}>
              <Grid item>
                <Button
                  color='primary'
                  size='small'
                  variant='contained'
                  type='submit'
                  data-test='submit-button'
                  disabled={!isValid || !dirty}>
                  {landingPageText.submit}
                </Button>
              </Grid>
              <Grid item>
                <LinkButton
                  to='/'
                  color='secondary'
                  variant='outlined'
                  size='small'
                  data-test='cancel-button'
                  onClick={handleCancelBtnClick}>
                  {landingPageText.cancel}
                </LinkButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

const formConfig = withFormik({
  mapPropsToValues: () => ({
    currentPassword: '',
    password: '',
    conformPassword: ''
  }),
  mapPropsToStatus: () => ({}),
  validationSchema: updatePasswordSchema,
  handleSubmit: (values, formikBag) => {
    const rbody = {
      userProfileId: formikBag.props.userProfileId,
      currentPassword: values.currentPassword,
      newPassword: values.password,
      conformNewPassword: values.conformPassword
    };
    changePasswordApi(rbody)
      .then(res => {
        if (res) {
          formikBag.setStatus({ isPasswordUpdated: true });
        }
      })
      .catch(err => {
        const {
          data: { errorMessage, errorMap }
        } = err.response;
        if (!errorMessage) {
          err.response.data.errorMessage = Object.values(errorMap)[0];
          err.response.data.errorMap = null;
        }
        formikBag.setStatus(createStatusFromErrors(err));
      });
  }
});

export const mapStateToProps = state => ({
  userLoginId: state.user.userLoginId,
  userProfileId: state.user.userProfileId,
  changePasswordMsg: state.user.changePasswordMsg
});

export const ChangePasswordWithFormik = formConfig(ChangePassword);

export default connect(mapStateToProps, { resetUserProfile, setUserProfile })(
  ChangePasswordWithFormik
);
