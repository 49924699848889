/* eslint-disable complexity */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ActionButtons from './ActionButtons';
import FranchiseFlowNavigation from './FranchiseFlowNavigation';
import Routes from './Routes';
import SubmitSuccess from './SubmitSuccess';
import GetAppIcon from '@material-ui/icons/GetApp';
import './DataForms.css';
import { ucFirstString } from '../../utils/commonFunctions';
import { Grid, Button, Tooltip } from '@material-ui/core';
import { Loader, Modal } from '../../components';
import {
  slugToIDs,
  quarterlyFormsIDs,
  annualFormsIDs
} from '../../constants/dataForms';
import { saveFormApi, downloadSubmittedFormApi } from '../../api/dataForms';
import { COMMON_TEXT } from '../../constants/text';
import {
  RPT_PRD_NEXT_MONTH_ERR,
  RPT_PRD_PREVIOUS_MONTH_ERR,
  BAD_REQUEST,
  INT_SERVER_ERR
} from '../../constants/errors';
import HOME_TEXT from '../../constants/text/home';
import { blobToObject } from '../../store/upload/actions';
import ForecastToggleButton from './ForecastToggleButton';
import { Buffer } from 'buffer';
import { toastFunc } from '../../components/ToastComponent/toastFunction';
export class DataForms extends Component {
  constructor(props) {
    super(props);
    this.pageRefreshHandler = this.pageRefreshHandler.bind(this);
    const pathArr = props.location.pathname.split('/');
    const currentLink = props.allForms.findIndex(form => {
      const foundElement = Object.keys(slugToIDs).find(
        k => slugToIDs[k] === pathArr[3]
      );
      const formName = foundElement !== undefined && slugToIDs[foundElement];
      return form.formId === formName;
    });
    this.state = {
      currentLink: currentLink >= 0 ? currentLink : 0,
      entryType: pathArr[2],
      allForms: props.allForms,
      nextFormAfterIssuer: '',
      clearDataModalOpen: false,
      warningModalOpen: false,
      errorModalOpen: false,
      nextModalOpen: false,
      emptyFieldsModalOpen: false,
      issuerFraudModalOpen: false,
      issuerFraudCrossCheckModalOpen: false,
      submitFormsModalOpen: false,
      submitSuccessData: false,
      forecastToggleStatus: true,
      loading: false
    };
    document.title = `GlobalNet |  ${ucFirstString(
      pathArr[2]
    )} Entry - Franchise`;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.allForms.length === 0) {
      const pathArr = this.props.location.pathname.split('/');
      const currentLink = this.props.allForms.findIndex(form => {
        const foundElement = Object.keys(slugToIDs).find(
          k => slugToIDs[k] === pathArr[3]
        );
        const formName = foundElement !== undefined && slugToIDs[foundElement];
        return form.formId === formName;
      });
      if (currentLink !== prevState.currentLink) {
        this.setState({ currentLink });
      }
    }
    if (this.props.formErrors !== prevProps.formErrors) {
      const { errorCode, errorMessage } = this.props.formErrors;
      if (errorCode === BAD_REQUEST) {
        this.toggleErrorModel();
      } else if (errorCode === INT_SERVER_ERR) {
        throw new Error(errorMessage);
      }
    }
  }

  pageRefreshHandler() {
    localStorage.setItem('formType', this.props.formType);
    const tempOrgReportingPeriod = this.props.tempFormsListNav
      ? this.props.tempFormsListNav.orgReportingPeriod
      : {};
    const orgReportingPeriod = this.props.formsListNav
      ? this.props.formsListNav.orgReportingPeriod
      : {};
    if (tempOrgReportingPeriod && orgReportingPeriod) {
      if (
        tempOrgReportingPeriod.orgProfileId !== orgReportingPeriod.orgProfileId
      ) {
        const {
          orgName,
          orgProfileId,
          reportingMonth,
          reportingYear
        } = tempOrgReportingPeriod;
        localStorage.setItem(
          'orgReportingPeriod',
          JSON.stringify({
            orgProfileId,
            reportingMonth,
            reportingYear
          })
        );
        localStorage.setItem('orgName', orgName);
      }
    }
  }

  updateCurrentLink = id => {
    this.setState(prevState => {
      return {
        ...prevState,
        currentLink: id
      };
    });
  };

  decrementLink = () => {
    this.setState(prevState => ({ currentLink: prevState.currentLink - 1 }));
  };

  incrementLink = () => {
    this.setState(prevState => ({ currentLink: prevState.currentLink + 1 }));
  };

  navigateToLastForm = () => {
    this.setState({ submitSuccessData: false });
  };

  emptyFieldsHandler = () => {
    const { form } = this.props;
    return form.tableData.filter(
      field =>
        !field.monetaryValue ||
        !field.noOfCases ||
        field.monetaryValue === '0' ||
        field.noOfCases === '0'
    );
  };

  incrementLinkHandler = async nextPagePath => {
    const { setDataForm, form, history, setQuarterlyForm } = this.props;
    const formId = form?.formId;
    const slug = formId ? slugToIDs[formId] : undefined;
    let updatedForm;
    let hasError = false;
    let formData;
    if (form) {
      if (form.formEditable) {
        if (slug === 'intes' && form.fieldDetails.length === 0) {
          this.toggleEmptyFieldsModal();
          return;
        }
        if (slug === 'issuer-fraud') {
          const emptyFormRows = this.emptyFieldsHandler();
          if (emptyFormRows.length > 0) {
            this.toggleIssuerFraudModal(nextPagePath);
            return;
          }
          formData = form;
        } else if (slug === 'se-sales-volume') {
          formData = form;
          formData = {
            ...formData,
            tabs: formData.tabs.map(tab => {
              if (tab.tabName === null) {
                return {
                  ...tab,
                  tabName: 'Summary'
                };
              }
              return tab;
            })
          };
        } else {
          formData = form;
        }
        try {
          updatedForm = await saveFormApi(formData);
          updatedForm = setQuarterlyForm(updatedForm);
        } catch (err) {}
      }
    }

    if (updatedForm) {
      if (
        slugToIDs[updatedForm.formId] === 'intes' ||
        slugToIDs[updatedForm.formId] === 'issuer-fraud' ||
        slugToIDs[updatedForm.formId] === 'royality-additions-exclusions'
      ) {
        setDataForm(updatedForm);
      } else if (updatedForm.tabs) {
        updatedForm.tabs.forEach(tab => {
          if (tab.hasError) {
            hasError = true;
          }
        });
      }
    }
    history.push(nextPagePath);
    this.incrementLink();
  };

  setCurrentLink = () => {
    const { allForms, entryType } = this.state;
    const {
      location: { pathname }
    } = this.props;
    if (pathname.split('/').length > 2 && entryType === 'manual') {
      const currentPath = pathname.split('/')[2];
      const currentLink = allForms.map(e => e.path).indexOf(currentPath);
      this.setState({ currentLink });
    }
    if (pathname.split('/').length > 3 && entryType === 'upload') {
      const currentPath = pathname.split('/')[3];
      const currentLink = allForms.map(e => e.path).indexOf(currentPath);
      this.setState({ currentLink });
    }
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.pageRefreshHandler);
    const { allForms, setFormsListInMenu } = this.props;
    if (allForms.length === 0) {
      setFormsListInMenu();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.pageRefreshHandler);
  }

  clearDataHandler = () => {};

  toggleClearDataModal = () => {
    this.setState(prevState => ({
      clearDataModalOpen: !prevState.clearDataModalOpen
    }));
  };

  toggleWarningModel = () => {
    this.setState(prevState => ({
      warningModalOpen: !prevState.warningModalOpen
    }));
  };

  toggleErrorModel = () => {
    this.setState(prevState => ({
      errorModalOpen: !prevState.errorModalOpen
    }));
  };

  toggleNextModal = () => {
    this.setState(prevState => ({
      nextModalOpen: !prevState.nextModalOpen
    }));
  };

  toggleEmptyFieldsModal = () => {
    this.setState(prevState => ({
      emptyFieldsModalOpen: !prevState.emptyFieldsModalOpen
    }));
  };

  toggleIssuerFraudModal = (nextPagePath = '') => {
    this.setState(prevState => ({
      issuerFraudModalOpen: !prevState.issuerFraudModalOpen,
      nextFormAfterIssuer: nextPagePath
    }));
  };

  toggleIssuerFraudCrossCheckModal = () => {
    this.setState(prevState => ({
      issuerFraudCrossCheckModalOpen: !prevState.issuerFraudCrossCheckModalOpen
    }));
  };

  toggleSubmitFormsModal = () => {
    this.setState(prevState => ({
      submitFormsModalOpen: !prevState.submitFormsModalOpen
    }));
  };

  issuerFraudValidationHandler = () => {
    const { form, crossCheck } = this.props;
    if (form.tableData.length === 0) {
      this.toggleEmptyFieldsModal();
      return;
    }
    const emptyFormRows = this.emptyFieldsHandler();
    if (emptyFormRows.length > 0) {
      this.toggleIssuerFraudCrossCheckModal();
      return;
    }
    crossCheck();
  };

  errorModalHandler = () => {
    const { errorMessage } = this.props.formErrors;
    const { resetFormErrors, openMegaMenu } = this.props;
    if (
      RPT_PRD_NEXT_MONTH_ERR === errorMessage ||
      RPT_PRD_PREVIOUS_MONTH_ERR === errorMessage
    ) {
      resetFormErrors();
      this.toggleErrorModel();
      this.props.history.push('/home');
      setTimeout(() => {
        openMegaMenu(HOME_TEXT.manageData);
      }, 100);
    } else {
      resetFormErrors();
      this.toggleErrorModel();
    }
  };

  successSubmitModalHandler = () => {
    this.setState({ submitSuccessData: false });
    this.props
      .submitDataForms()
      .then(response => {
        if (response.status === 200) {
          this.setState({ submitSuccessData: true });
          if (
            typeof this.props.setDataSubmittedStep !== 'undefined' &&
            typeof this.props.setDataSubmittedStep === 'function'
          ) {
            this.props.setDataSubmittedStep();
          }
          this.toggleSubmitFormsModal();
        } else {
          this.setState({ submitSuccessData: false });
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  successDownloadButton = async () => {
    this.setState({ loading: true });
    const { orgReportingPeriod, formType } = this.props.formsListNav;
    try {
      const response = await downloadSubmittedFormApi({
        orgReportingPeriod,
        formType
      });
      if (response !== undefined) {
        const content = response.data.content;
        const fileName = response.data.fileName;
        const decodedData = Buffer.from(content, 'base64');
        const type = { type: 'application/vnd.ms-excel' };
        blobToObject(decodedData, fileName, type);
        toastFunc({
          content: 'Download Completed',
          config: { className: 'toast-container accept' }
        });
      }
    } catch (error) {
      console.error('Error downloading form:', error);
      toastFunc({
        content: 'Download Failed. Please try again later!',
        config: { className: 'toast-container reject' },
        toastType: 'error'
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  forecastToggleStatusHandler = () => {
    this.setState(prevState => ({
      forecastToggleStatus: !prevState.forecastToggleStatus
    }));
  };

  render() {
    const {
      isUploadedData,
      match,
      clearDataForm,
      allForms,
      allUploadForms,
      currentForm,
      crossCheck,
      form,
      showAutoSave,
      showCodeExists,
      showDescExists,
      showFraudExists,
      setDataForm,
      history,
      formErrors,
      formType,
      userRole,
      activeTab,
      setDataForms,
      userRoleMap,
      formID
    } = this.props;
    const {
      currentLink,
      clearDataModalOpen,
      warningModalOpen,
      errorModalOpen,
      nextModalOpen,
      emptyFieldsModalOpen,
      issuerFraudModalOpen,
      issuerFraudCrossCheckModalOpen,
      submitFormsModalOpen,
      nextFormAfterIssuer,
      entryType,
      submitSuccessData,
      forecastToggleStatus,
      loading
    } = this.state;
    let isSubmit;
    let downloadButtonStatus;
    const yearStatus =
      parseInt(this.props.formsListNav.orgReportingPeriod.reportingYear) + 1 ===
      JSON.parse(localStorage.getItem('planYear'));
    const yearToggleCheck = localStorage.getItem('planYear')
      ? yearStatus
      : forecastToggleStatus;

    if (entryType === 'manual') {
      if (allForms) {
        if (currentLink === allForms.length - 1) {
          downloadButtonStatus = true;
        }
      }
      if (
        currentLink === allForms.length - 1 ||
        (form &&
          form.status != null &&
          form.status.includes('Submitted to DGN')) ||
        (form && form.status != null && form.status.includes('Complete GM'))
      ) {
        isSubmit = true;
      }
    } else {
      if (allUploadForms) {
        if (currentLink === allUploadForms.length - 1) {
          downloadButtonStatus = true;
        }
      }
      if (
        currentLink === allUploadForms.length - 1 ||
        (form &&
          form.status != null &&
          form.status.includes('Submitted to DGN')) ||
        (form && form.status != null && form.status.includes('Complete GM'))
      ) {
        isSubmit = true;
      }
    }
    if (allForms.length === 0) {
      return (
        <Grid
          container
          item
          justify='center'
          alignItems='center'
          data-test='franchise-flow-component'>
          <Loader data-test='component-loader' />
        </Grid>
      );
    }

    if (entryType === 'upload' && !isUploadedData) {
      return <Redirect to='/upload' />;
    }

    return (
      <>
        {loading && (
          <Modal
            isOpen={loading}
            data-test='clear-formdata-modal'
            isOkButton={false}
            onCancel={false}
            onSubmit={false}>
            <Loader data-test='component-loader' />
          </Modal>
        )}
        {submitSuccessData ? (
          <div className='franchise-flow' data-test='franchise-flow-component'>
            <SubmitSuccess
              data-test='franchise-flow-submit-component'
              orgReportingPeriod={this.props.formsListNav.orgReportingPeriod}
              currentForm={currentLink}
              allForms={entryType === 'manual' ? allForms : allUploadForms}
              navigateToLastForm={this.navigateToLastForm}
              successDownloadButton={this.successDownloadButton}
            />
          </div>
        ) : (
          <div
            className='franchise-flow'
            data-test='franchise-flow-component-inner'>
            <Modal
              isOpen={clearDataModalOpen}
              data-test='clear-formdata-modal'
              submitBtnLabel='Confirm'
              onSubmit={() => {
                clearDataForm(entryType);
                this.toggleClearDataModal();
              }}
              onClose={this.toggleClearDataModal}
              onCancel={this.toggleClearDataModal}>
              <>Are you sure you want to clear the form data?</>
            </Modal>
            <Modal
              isOpen={nextModalOpen}
              data-test='next-form-modal'
              submitBtnLabel='Confirm'
              onSubmit={() => {
                this.incrementLink();
                this.toggleNextModal();
              }}
              onClose={this.toggleNextModal}
              onCancel={this.toggleNextModal}>
              <>Do you want to proceed to next form?</>
            </Modal>
            <Modal
              isOpen={emptyFieldsModalOpen}
              submitBtnLabel='OK'
              data-test='empty-Fields-Modal-Open'
              onSubmit={this.toggleEmptyFieldsModal}
              onClose={this.toggleEmptyFieldsModal}
              onCancel={false}>
              <>Form fields are empty, Please enter atleast one entry</>
            </Modal>
            <Modal
              isOpen={issuerFraudModalOpen}
              data-test='issuer-fraud-save-modal'
              submitBtnLabel='OK'
              onSubmit={async () => {
                this.toggleIssuerFraudModal();
                let issuerUpdatedForm;
                try {
                  issuerUpdatedForm = await saveFormApi(form);
                } catch (err) {}
                if (issuerUpdatedForm) {
                  setDataForm(issuerUpdatedForm);
                  history.push(nextFormAfterIssuer);
                  this.incrementLink();
                }
              }}
              onClose={this.toggleIssuerFraudModal}
              onCancel={this.toggleIssuerFraudModal}>
              <>{COMMON_TEXT.issuerFraudConfirmationMsg}</>
            </Modal>
            <Modal
              isOpen={issuerFraudCrossCheckModalOpen}
              data-test='issuer-fraud-cross-modal'
              submitBtnLabel='OK'
              onSubmit={() => {
                crossCheck();
                this.toggleIssuerFraudCrossCheckModal();
              }}
              onClose={this.toggleIssuerFraudCrossCheckModal}
              onCancel={this.toggleIssuerFraudCrossCheckModal}>
              <>{COMMON_TEXT.issuerFraudConfirmationMsg}</>
            </Modal>
            <Modal
              isOpen={submitFormsModalOpen}
              data-test='submit-formdata-modal'
              submitBtnLabel='Confirm'
              onSubmit={this.successSubmitModalHandler}
              onClose={this.toggleSubmitFormsModal}
              onCancel={this.toggleSubmitFormsModal}>
              <>
                Are you sure you want to submit the forms for reporting period -{' '}
                {HOME_TEXT.monthsList[Number(form?.reportingMonth) - 1]}{' '}
                {form?.reportingYear}?
              </>
            </Modal>
            <Modal
              isOpen={warningModalOpen}
              data-test='warning-Modal-Open'
              submitBtnLabel='Confirm'
              onSubmit={this.toggleWarningModel}
              onClose={this.toggleWarningModel}
              onCancel={this.toggleWarningModel}>
              <></>
            </Modal>
            <Modal
              isOpen={errorModalOpen}
              data-test='error-Modal-Open'
              submitBtnLabel='OK'
              onSubmit={this.errorModalHandler}
              onClose={this.errorModalHandler}
              onCancel={this.errorModalHandler}>
              <>{formErrors.errorMessage}</>
            </Modal>

            <FranchiseFlowNavigation
              currentForm={currentLink}
              data-test='Franchise-Flow-Navigation'
              decrementLink={this.decrementLink}
              incrementLink={this.incrementLinkHandler}
              updateCurrentLink={this.updateCurrentLink}
              allForms={entryType === 'manual' ? allForms : allUploadForms}
              form={form}
            />
            {form?.formEditable && (
              <ActionButtons
                activeTab={activeTab}
                downloadButton={downloadButtonStatus}
                data-test='Action-Buttons'
                clearDataHandler={
                  (((form &&
                    form.formId === '60' &&
                    form.fieldDetails.length === 0) ||
                    (form &&
                      form.formId === '65' &&
                      form.tableData.length === 0)) &&
                    this.toggleEmptyFieldsModal) ||
                  this.toggleClearDataModal
                }
                crossCheckHandler={
                  (form &&
                    form.formId === '60' &&
                    form.fieldDetails.length === 0 &&
                    this.toggleEmptyFieldsModal) ||
                  (form &&
                    form.formId === '65' &&
                    this.issuerFraudValidationHandler) ||
                  crossCheck
                }
                isUploadedData={isUploadedData}
                showAutoSave={showAutoSave}
                showCodeExists={showCodeExists}
                showDescExists={showDescExists}
                showFraudExists={showFraudExists}
                isSubmit={isSubmit}
                toggleSubmitFormsModal={this.toggleSubmitFormsModal}
                role={userRole}
                userRoleMap={userRoleMap}
                formID={formID}
                isSubmitted={
                  (quarterlyFormsIDs.includes(parseInt(form.formId)) ||
                    annualFormsIDs.includes(parseInt(form.formId))) &&
                  (Boolean(form?.tabs[activeTab]?.submitted) ||
                    Boolean(form?.tabs[activeTab]?.isTotal))
                }
                isSubmitAllow={
                  Boolean(formType === 'monthlyDataForms') ||
                  Boolean(Number(form.reportingMonth) % 3 === 0)
                }
              />
            )}
            {isSubmit && !form?.formEditable && (
              <div
                className='action-buttons'
                data-test='component-franchise-flow-action-buttons'>
                <Tooltip
                  placement='bottom'
                  title='Download'
                  data-test='tooltip-submit-button'>
                  <Button
                    color='primary'
                    size='small'
                    variant='contained'
                    aria-label='submit'
                    data-test='submit-button'
                    onClick={this.successDownloadButton}>
                    <GetAppIcon data-test='download-icon' />
                  </Button>
                </Tooltip>
              </div>
            )}
            {formType === 'annuallyDataForms' && (
              <ForecastToggleButton
                form={form}
                orgReportingPeriod={this.props.formsListNav.orgReportingPeriod}
                setDataForms={setDataForms}
                toggleStatus={yearToggleCheck}
                toggleStatusHandler={this.forecastToggleStatusHandler}
                data-test='Forecast-Toggle-Buttons'
              />
            )}
            <Routes path={match.path} firstForm={allForms[0]} />
            {form?.formEditable && (
              <ActionButtons
                isFooterBlock
                activeTab={activeTab}
                data-test='Action-Buttons'
                downloadButton={downloadButtonStatus}
                clearDataHandler={
                  (((form &&
                    form.formId === '60' &&
                    form.fieldDetails.length === 0) ||
                    (form &&
                      form.formId === '65' &&
                      form.tableData.length === 0)) &&
                    this.toggleEmptyFieldsModal) ||
                  this.toggleClearDataModal
                }
                isUploadedData={isUploadedData}
                isSubmit={isSubmit}
                crossCheckHandler={
                  (form &&
                    form.formId === '60' &&
                    form.fieldDetails.length === 0 &&
                    this.toggleEmptyFieldsModal) ||
                  (form &&
                    form.formId === '65' &&
                    this.issuerFraudValidationHandler) ||
                  crossCheck
                }
                toggleSubmitFormsModal={this.toggleSubmitFormsModal}
                role={userRole}
                userRoleMap={userRoleMap}
                formID={formID}
                isSubmitted={
                  (quarterlyFormsIDs.includes(parseInt(form.formId)) ||
                    annualFormsIDs.includes(parseInt(form.formId))) &&
                  (Boolean(form.tabs[activeTab]?.submitted) ||
                    Boolean(form.tabs[activeTab]?.isTotal))
                }
                isSubmitAllow={
                  Boolean(formType === 'monthlyDataForms') ||
                  Boolean(Number(form.reportingMonth) % 3 === 0)
                }
              />
            )}
            {isSubmit && !form?.formEditable && (
              <div
                className='action-buttons footer-block'
                data-test='component-franchise-flow-action-buttons'>
                <Tooltip
                  placement='bottom'
                  title='Download'
                  data-test='tooltip-submit-button'>
                  <Button
                    color='primary'
                    size='small'
                    variant='contained'
                    aria-label='submit'
                    data-test='submit-button'
                    onClick={this.successDownloadButton}>
                    <GetAppIcon data-test='download-icon' />
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default DataForms;
