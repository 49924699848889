import React from 'react';
import {
  Radio as MaterialRadio,
  FormControlLabel,
  Typography,
  withStyles
} from '@material-ui/core';

import PropTypes from 'prop-types';

const styles = theme => ({
  icon: {
    width: 18,
    height: 18,
    border: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.input.hover,
    borderRadius: '50%',
    boxSizing: 'border-box'
  },
  checkedBorder: {
    padding: '2px'
  },

  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    display: 'block'
  },

  disabled: {
    opacity: 0.5
  }
});

export const Radio = props => {
  const { label, classes, disabled, labelStyle, labelClassName } = props;

  const rootProps = { ...props };
  delete rootProps.classes;
  delete rootProps.labelStyle;
  delete rootProps.labelClassName;
  return (
    <FormControlLabel
      data-test='component-Radio'
      {...rootProps}
      control={
        <MaterialRadio
          icon={
            <span
              className={`${classes.icon} ${disabled ? classes.disabled : ''}`}
            />
          }
          checkedIcon={
            <span
              className={`${classes.icon} ${classes.checkedBorder} ${
                disabled ? classes.disabled : ''
              }`}>
              <span
                className={`${classes.checkedIcon} ${
                  disabled ? classes.disabled : ''
                }`}
              />
            </span>
          }
        />
      }
      label={
        <Typography
          variant='body2'
          style={labelStyle}
          className={labelClassName}>
          {label}
        </Typography>
      }
    />
  );
};

Radio.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelStyle: PropTypes.object,
  labelClassName: PropTypes.string,
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func
};

Radio.defaultProps = {
  name: '',
  checked: false,
  disabled: false,
  label: '',
  labelStyle: {},
  labelClassName: '',
  value: '',
  onChange: () => {}
};

export default withStyles(styles)(Radio);
