import { connect } from 'react-redux';
import UserLog from './UserLog';
import {
  getUserProfileLogsResults,
  getAdminOrganizationNames,
  clearLogsProfileList
} from '../../../../store/admin/actions';

export const mapStateToProps = state => {
  return {
    searchedUserProfileLogs: state.admin.searchedUserProfile,
    userProfilesLog: state.admin.userProfile,
    usersOrganizations: state.admin.totalAdminOrganizationalNames
  };
};

export default connect(mapStateToProps, {
  getUserProfileLogsResults: userProfile =>
    getUserProfileLogsResults(userProfile),
  getAdminOrganizationNames,
  clearLogsProfileList
})(UserLog);
