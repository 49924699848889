import api from './';

const orgEndpoints = {
  getTotalOrganizationRoles: 'org/roles',
  getOrganizationNames: 'auth/orgs',
  downloadHelpDocuments: 'auth/help/templates',
  getUserRoles: 'auth/userRoles'
};

export const getTotalOrganizationRolesApi = async () => {
  try {
    const response = await api().get(orgEndpoints.getTotalOrganizationRoles);
    return response.data;
  } catch (e) {}
};

export const getTotalOrganizationNamesApi = async () => {
  const res = await api().get(orgEndpoints.getOrganizationNames);
  return res.data;
};
export const getUserRolesApi = async () => {
  const res = await api().get(orgEndpoints.getUserRoles);
  return res.data;
};

export const downloadHelpDocumentsApi = async fileName => {
  try {
    const response = await api().get(orgEndpoints.downloadHelpDocuments, {
      params: {
        fileName
      }
    });
    return response.data;
  } catch (e) {}
};
