import React from 'react';
import { connect } from 'formik';
import { TextField as Input, InputAdornment } from '@material-ui/core';
import ErrorTooltip from './ErrorTooltip';
import PropTypes from 'prop-types';
import WarningTooltip from './WarningTooltip';

export const TextField = props => {
  const fieldProps = { ...props };
  delete fieldProps.formik;
  delete fieldProps.inputRef;
  const {
    name,
    formik: { values, handleChange, handleBlur, touched, errors, status }
  } = props;
  const isError = Boolean(
    touched[name] &&
      Boolean(
        errors[name] || status[name] || status.root || values[name] === '-'
      )
  );
  let endAdornment;
  if (isError) {
    if (values[name] === '-') {
      errors[name] = 'Invalid Value';
    }
    endAdornment = (
      <ErrorTooltip message={touched[name] && (errors[name] || status[name])} />
    );
  } else if (fieldProps.warning) {
    endAdornment = <WarningTooltip message={fieldProps.warning} />;
  } else {
    endAdornment = '';
  }
  return (
    <Input
      ref={props.inputRef}
      data-test='component-TextFieldGrid'
      value={values[name]}
      error={isError}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end' style={{ marginRight: 7 }}>
            {endAdornment}
          </InputAdornment>
        )
      }}
      onChange={handleChange}
      onBlur={handleBlur}
      {...fieldProps}
    />
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired
};

export default connect(TextField);
