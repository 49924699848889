import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import './CreateUser.css';
import {
  UserOrganizationalRolesHeader,
  UserOrganizationalRolesForm,
  UserOrganizationalRolesList,
  CanActivate
} from '../../../../components';

import { getTotalOrganizationRoles } from '../../../../store/global/actions';
import {
  getAdminOrganizationNames,
  addOrganizationData,
  orgRoleProfilesRearrange,
  clearOrganizationData,
  modifyOrganizationData,
  deleteOrganizationData
} from '../../../../store/admin/actions';
import FormContainer from './FormContainer';
import PageError from '../../../PageError';

export class CreateUser extends Component {
  state = {
    orgProfilesLength: 0
  };

  static propTypes = {
    totalOrganizationalNames: PropTypes.array,
    totalOrganizationalRoles: PropTypes.array,
    organizationRoles: PropTypes.array,
    getTotalOrganizationRoles: PropTypes.func.isRequired,
    getAdminOrganizationNames: PropTypes.func.isRequired,
    addOrganizationData: PropTypes.func.isRequired
  };

  static defaultProps = {
    totalOrganizationalNames: [],
    totalOrganizationalRoles: [],
    organizationRoles: []
  };

  componentDidMount() {
    this.props.getTotalOrganizationRoles();
    this.props.getAdminOrganizationNames();
  }

  componentWillUnmount() {
    this.props.clearOrganizationData();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.organizationRoles) !==
      JSON.stringify(this.props.organizationRoles)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ orgProfilesLength: this.props.organizationRoles.length });
    }
  }

  render() {
    const {
      totalOrganizationalNames,
      totalOrganizationalRoles,
      addOrganizationData,
      organizationRoles,
      modifyOrganizationData,
      deleteOrganizationData
    } = this.props;

    const orgArrRoles = orgRoleProfilesRearrange(organizationRoles);
    let arr = [];
    orgArrRoles.forEach(element => {
      arr = [...arr, ...element.organizationProfiles];
    });

    const filtertotalOrganizationalNamesFinal = totalOrganizationalNames.filter(
      elem =>
        !arr.find(
          ({ organizationProfileId }) => elem.value === organizationProfileId
        )
    );

    return (
      <CanActivate
        action='admin:createuser'
        unAuthorizedView={<PageError errorCode='401' />}>
        <Grid container item data-test='create-user-component'>
          <Grid
            container
            className='create-user-component'
            alignItems='flex-start'
            alignContent='flex-start'>
            <Grid item md={12} className='create-user-form-container'>
              <Grid container>
                <FormContainer
                  orgProfilesLength={this.state.orgProfilesLength}
                />
                <Grid
                  item
                  md={12}
                  className='create-user-body-item org-item-section'>
                  <UserOrganizationalRolesHeader />
                  <UserOrganizationalRolesForm
                    totalOrganizationalNames={
                      filtertotalOrganizationalNamesFinal
                    }
                    totalOrganizationalRoles={totalOrganizationalRoles}
                    addOrganizationData={addOrganizationData}
                    pageType='add'
                  />
                  <UserOrganizationalRolesList
                    totalOrganizationalRoles={totalOrganizationalRoles}
                    orgRoleProfiles={orgArrRoles}
                    pageType='add'
                    modifyOrganizationData={modifyOrganizationData}
                    deleteOrganizationData={deleteOrganizationData}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CanActivate>
    );
  }
}

export const mapStateToProps = state => ({
  totalOrganizationalNames: state.admin.totalAdminOrganizationalNames,
  totalOrganizationalRoles: state.global.totalOrganizationalRoles,
  organizationRoles: state.admin.orgRoleProfilesForSave
});

export default connect(mapStateToProps, {
  getAdminOrganizationNames,
  getTotalOrganizationRoles,
  addOrganizationData,
  clearOrganizationData,
  modifyOrganizationData,
  deleteOrganizationData
})(CreateUser);
