export const slugToIDs = {
  5: 'card-member',
  7: 'card-member-summary',
  10: 'se-sales-volume',
  12: 'se-sales-volume-summary',
  15: 'se-transactions',
  17: 'se-transactions-summary',
  20: 'card-volume',
  21: 'card-volume-summary',
  25: 'se-discount-revenue',
  30: 'se-membership-count',
  35: 'se-activation',
  40: 'agency-volume-transaction-and-discount-revenue',
  45: 'dpass-franchisee',
  50: 'dpass-aquirer',
  55: 'qr-code',
  60: 'intes',
  65: 'issuer-fraud',
  70: 'royalty-sales-volume-fees',
  75: 'royalty-sales-loans',
  80: 'royality-additions-exclusions',
  100: 'income-statement-revenue-and-funding',
  101: 'income-statement-credit-losses-and-expenses',
  102: 'income-statement-operating-expenses-and-net-income',
  103: 'income-statement-supplemental-information',
  104: 'balance-sheet-schedule-assets-and-liabilities',
  105: 'balance-sheet-schedule-shareholders-equity',
  106: 'balance-sheet-assets',
  107: 'balance-sheet-liabilities',
  108: 'balance-sheet-shareholders-equity',
  300: 'annual-income-statement-revenue-and-funding',
  301: 'annual-income-statement-revenue-and-funding',
  302: 'annual-income-statement-credit-losses-and-expenses',
  303: 'annual-income-statement-credit-losses-and-expenses',
  304: 'annual-income-statement-operating-expenses-and-net-income',
  305: 'annual-income-statement-operating-expenses-and-net-income',
  306: 'annual-income-statement-supplemental-information',
  307: 'annual-income-statement-supplemental-information',
  308: 'annual-balance-sheet-schedule-assets-and-liabilities',
  309: 'annual-balance-sheet-schedule-assets-and-liabilities',
  310: 'annual-balance-sheet-schedule-shareholders-equity',
  311: 'annual-balance-sheet-schedule-shareholders-equity',
  312: 'annual-balance-sheet-assets',
  313: 'annual-balance-sheet-assets',
  314: 'annual-balance-sheet-liabilities',
  315: 'annual-balance-sheet-liabilities',
  316: 'annual-balance-sheet-shareholders-equity',
  317: 'annual-balance-sheet-shareholders-equity',
  318: 'annual-statistical-information',
  319: 'annual-statistical-information'
};

export const quarters = {
  Q1: ['01', '02', '03'],
  Q2: ['04', '05', '06'],
  Q3: ['07', '08', '09'],
  Q4: ['10', '11', '12']
};

export const quarterlyFormsIDs = [100, 101, 102, 103, 104, 105, 106, 107, 108];
export const annualFormsIDs = [
  200,
  201,
  202,
  203,
  204,
  300,
  301,
  302,
  303,
  304,
  305,
  306,
  307,
  308,
  309,
  310,
  311,
  312,
  313,
  314,
  315,
  316,
  317,
  318,
  319
];

export const roleToSubmit = {
  'Data Entry': 'General Manager',
  'Data Entry User': 'General Manager',
  'General Manager': 'DGN'
};

export const roleNotToSubmit = ['Portal Admin', 'Local User Admin'];

export const formsExceptionCheck = (formId, sectionIndex) => {
  switch (formId) {
    case 'card-member':
      if (sectionIndex === 2) {
        return false;
      }
      return true;
    case 'se-transactions':
    case 'se-transactions-summary':
      return false;
    default:
      return true;
  }
};

export const formsRowCheck = (formId, sectionIndex, array) => {
  switch (formId) {
    case 'card-member':
      if (sectionIndex === 0) {
        return 0;
      }
      return false;
    case 'royalty-sales-volume-fees':
      if (sectionIndex === 2) {
        return array.length - 2;
      }
      return false;
    default:
      return false;
  }
};

export const formsNegativeCheck = (formId, sectionIndex, rowName, colName) => {
  switch (formId) {
    case 'card-member':
      if (sectionIndex === 0) {
        if (
          rowName === 'Voluntary Attrition' ||
          rowName === 'Involuntary Attrition'
        ) {
          return true;
        }
      }
      return false;
    case 'se-membership-count':
      if (colName === 'Attrition') {
        return true;
      }
      return false;
    default:
      return false;
  }
};
