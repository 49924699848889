import React, { ReactNode } from 'react';
import { Grid, Button } from '@material-ui/core';

import './ActionBar.css';

interface ButtonProps {
  label: string;
  disabled?: boolean;
  onClick?: any;
  type: 'button' | 'submit' | 'reset';
}

interface Props {
  left1?: ReactNode;
  left2?: ReactNode;
  right?: ReactNode;
  border: boolean;
  rounded: 'top' | 'bottom' | 'all' | 'none';
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
}

const ActionBar = (props: Props) => {
  const {
    left1,
    left2,
    rounded,
    right,
    primaryButton,
    secondaryButton,
    border
  } = props;
  return (
    <Grid
      container
      alignItems='center'
      data-test='action-bar-component'
      className={`action-bar rounded-${rounded} ${border ? 'border' : ''}`}>
      {left1 && (
        <Grid item sm>
          {left1}
        </Grid>
      )}

      {left2 && (
        <Grid item sm>
          {left2}
        </Grid>
      )}

      <Grid item sm>
        {right ? (
          right
        ) : (
          <Grid container justify='flex-end'>
            {secondaryButton && secondaryButton.label && (
              <Button
                variant='outlined'
                color='secondary'
                size='small'
                disabled={secondaryButton.disabled}
                style={{ marginRight: 15 }}
                onClick={secondaryButton.onClick}>
                {secondaryButton.label}
              </Button>
            )}
            {primaryButton && primaryButton.label && (
              <Button
                variant='contained'
                color='primary'
                size='small'
                disabled={primaryButton.disabled}
                type={primaryButton.type}
                onClick={primaryButton.onClick}>
                {primaryButton.label}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

ActionBar.defaultProps = {
  rounded: 'none',
  type: 'submit'
};

export default ActionBar;
