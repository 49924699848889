import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import ForgotUserId from './ForgotUserId';
import ForgotPassword from './ForgotPassword';
import UseridVerification from './UseridVerification';
import LoginForm from './LoginForm';
import Registration from './Registration';
import Logo from '../../assets/logo/Discover_Global_Network_Logo_RGB.png';
import ChangePassword from './ChangePassword';
import { landingPageText } from '../../constants/text';

export const FormContainer = props => {
  const { location } = props;
  let formComponent;
  if (location.pathname === '/') {
    formComponent = <LoginForm data-test='component-login-form' />;
  } else if (location.pathname === '/register') {
    formComponent = <Registration data-test='component-register-form' />;
  } else if (location.pathname === '/forgot-userid') {
    formComponent = <ForgotUserId data-test='component-forgot-userid-form' />;
  } else if (location.pathname === '/userid-verification') {
    formComponent = (
      <UseridVerification data-test='component-userid-verification-form' />
    );
  } else if (location.pathname === '/forgot-password') {
    formComponent = (
      <ForgotPassword data-test='component-forgot-password-form' />
    );
  } else {
    formComponent = (
      <ChangePassword data-test='component-change-password-form' />
    );
  }
  return (
    <Paper
      role='region'
      className='right-panel'
      elevation={1}
      data-test='component-form-container'>
      <Grid
        container
        className='form-container'
        direction='row'
        justify='flex-start'>
        <img
          src={Logo}
          alt='Discover Global Network Logo. GlobalNet. Our Partner Reporting Solution'
          className='login-header-logo'
        />
        {formComponent}
        <div className='seperator' />
        <div className='help-text'>
          <Link to='/login-help-page' data-test='login-help-link'>
            {landingPageText.help}
          </Link>
        </div>
      </Grid>
    </Paper>
  );
};

export default withRouter(FormContainer);
