import React, { Component, Fragment } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import DataUpload from './DataUpload';
import { setUploadFormsListInMenu } from '../../store/formsListNav/actions';
import DataForms from '../DataForms';
import { ProgressStone } from '../../components';

export class UploadFlow extends Component {
  state = {
    activeStep: 0
  };

  componentDidMount() {
    document.title = 'GlobalNet | Upload';
    const { forms, setUploadFormsListInMenu } = this.props;
    if (forms.length > 0) {
      const isSummaryFormsExists = forms.some(form =>
        form.formName.includes('Summary')
      );
      if (isSummaryFormsExists) {
        const formsWithoutSummary = forms.filter(
          form => !form.formName.includes('Summary')
        );
        setUploadFormsListInMenu(formsWithoutSummary);
      } else {
        setUploadFormsListInMenu(forms);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    this.setState({
      activeStep: this.getCurrentStep()
    });
  }

  setDataSubmittedStep = () => {
    this.setState({
      activeStep: 3
    });
  };

  getCurrentStep = () => {
    const {
      location: { pathname }
    } = this.props;

    const paths = pathname.split('/');
    if (paths[3] === 'review') {
      return 1;
    }
  };

  handleReset = () => {
    this.props.history.push('/manage-data/upload');
  };

  render() {
    return (
      <Fragment>
        <Grid
          container
          data-test='progress-section'
          className='upload-progress'
          alignItems='center'
          justify='center'>
          <Grid item md={12} data-test='progress-stone-grid'>
            <ProgressStone
              activeStep={this.state.activeStep}
              data-test='progress-stone'
            />
          </Grid>
        </Grid>
        <Switch>
          <Route
            exact
            path='/manage-data/upload'
            render={() => <DataUpload />}
          />
          <Route
            path='/manage-data/upload/review'
            render={() => (
              <DataForms
                isUploadedData
                handleReset={this.handleReset}
                setDataSubmittedStep={this.setDataSubmittedStep}
              />
            )}
          />
        </Switch>
      </Fragment>
    );
  }
}

export const mapStateToProps = state => ({
  forms: state.formsListNav.forms
});

export const mapDispatchToProps = {
  setUploadFormsListInMenu
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadFlow)
);
