// Optional leading -, 0-9, and empty string
export const integerRegex = /^-?\d*?$/;
export const decimalRegex = /^-?\d*.?\d{0,2}$/;
export const decimalLength10Regex = /^-?\d{0,10}(\.\d{0,2})?$/;
export const decimal3Length12Regex = /^-?\d{0,12}(\.\d{0,3})?$/;
export const integerLength16Regex = /^-\d{0,15}$|^(\d{0,16})$|(^$)/;
export const integerLength12Regex = /^-\d{0,11}$|^(\d{0,12})$|(^$)/;
export const integerLength10Regex = /^-?\d{0,10}$|^(\d{0,10})$|(^$)/;
export const integerLength9Regex = /^-?\d{0,9}$|^(\d{0,9})$|(^$)/;
export const numberFormatRegex = /\B(?=(\d{3})+(?!\d))/g;
export const removeCommasRegex = /,/g;
export const initialSpacesRegex = /^\s+$/;
