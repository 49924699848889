export const GET_PARENT_ORGANIZATIONS = 'GET_PARENT_ORGANIZATIONS';
export const GET_CHILD_ORGANIZATIONS = 'GET_CHILD_ORGANIZATIONS';
export const GET_ALL_CONTRACTS = 'GET_ALL_CONTRACTS';
export const CREATE_CONTRACT = 'CREATE_CONTRACT';
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const GET_CONTRACT = 'GET_CONTRACT';
export const GET_CONTRACTS_BY_PARENT_ORGANIZATION =
  'GET_CONTRACTS_BY_PARENT_ORGANIZATION';
export const GET_EXCLUSION_TYPES = 'GET_EXCLUSION_TYPES';
export const RESET_EXCLUSION_TYPES = 'RESET_EXCLUSION_TYPES';
export const RESET_CONTRACT = 'RESET_CONTRACT';
export const RESET_CHILD_ORGANIZATIONS = 'RESET_CHILD_ORGANIZATIONS';
