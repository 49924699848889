import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  setDataForms,
  resetDataForms,
  updateRoyaltyForm,
  deleteRoyaltyFormRow,
  resetShowContractExists,
  resetShowRoyaltyDescExists
} from '../../../store/dataForms/actions';
import RoyalityExclusionsTable from './RoyalityExclusionsAdditionsTable';
import { Loader, Modal } from '../../../components';
import { slugToIDs } from '../../../constants/dataForms';
import { getRoyaltyDropDownData } from '../../../store/formsListNav/actions';
import { Grid } from '@material-ui/core';
import TEXT from '../../../constants/text/common';
export class RoyalityExclusionsAdditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      royaltyErrorModalOpen: false
    };
  }

  componentDidMount() {
    this.props.setDataForms(this.props.location.pathname);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (this.props.showContractExists || this.props.showRoyaltyDescExists) {
        this.setState(prevState => ({
          royaltyErrorModalOpen: !prevState.royaltyErrorModalOpen
        }));
      }
    }
  }

  componentWillUnmount() {
    this.props.resetDataForms();
  }

  handleAdditions(
    { fieldDetails, total },
    formObject,
    columnHeaders,
    tableName,
    isHeader,
    royaltyDescDropDown,
    getRoyaltyDropDownData,
    updateRoyaltyForm,
    deleteRoyaltyFormRow,
    sectionIndex,
    formType
  ) {
    return (
      <RoyalityExclusionsTable
        columnHeaders={columnHeaders}
        fieldDetails={fieldDetails}
        isHeader={isHeader}
        form={formObject}
        tableName={tableName}
        updateRoyaltyForm={updateRoyaltyForm}
        getRoyaltyDropDownData={getRoyaltyDropDownData}
        deleteRoyaltyFormRow={deleteRoyaltyFormRow}
        dropDownData={royaltyDescDropDown}
        sectionIndex={sectionIndex}
        total={total}
        formType={formType === true}
      />
    );
  }

  toggleErrorModal = () => {
    this.setState(prevState => ({
      royaltyErrorModalOpen: !prevState.royaltyErrorModalOpen
    }));
  };

  resetRoyaltyErrorMsg = () => {
    const {
      showContractExists,
      showRoyaltyDescExists,
      resetShowRoyaltyDescExists,
      resetShowContractExists
    } = this.props;
    if (showContractExists) {
      resetShowContractExists();
    }
    if (showRoyaltyDescExists) {
      resetShowRoyaltyDescExists();
    }
  };

  render() {
    if (
      this.props.sections.length === 0 ||
      this.props.tableName !== 'royality-additions-exclusions'
    ) {
      return (
        <Grid
          container
          item
          justify='center'
          alignItems='center'
          data-test='component-royalty'
          data-testid='component-royalty-id'>
          <Loader data-test='component-loader-royalty' />
        </Grid>
      );
    }
    const {
      sections,
      tableName,
      royaltyDescDropDown,
      deleteRoyaltyFormRow,
      getRoyaltyDropDownData,
      updateRoyaltyForm,
      formObject,
      showContractExists,
      showRoyaltyDescExists
    } = this.props;
    const { royaltyErrorModalOpen } = this.state;
    let errorMsg;
    if (showContractExists || showRoyaltyDescExists) {
      errorMsg = TEXT.royaltyTypeDuplicateErrorMsg;
    }
    const additionSectionHeaders = {
      1: 'Addition(s)',
      2: 'Description of Others',
      3: 'Value',
      4: 'Actions'
    };
    const exclusionSectionHeaders = {
      1: 'Exclusion(s)',
      2: 'Description of Others',
      3: 'Value',
      4: 'Actions'
    };
    const additionSection = sections[0];
    const exclusionSection = sections[1];
    const royaltyExclusionDropDown = royaltyDescDropDown.filter(
      data => data.type === 'E' && data.contractId !== 'RE-CS-ORA'
    );
    const royaltyAdditionDropDown = royaltyDescDropDown.filter(
      data => data.type === 'A' && data.contractId !== 'CS-ORA'
    );
    return (
      <>
        <Modal
          isOpen={royaltyErrorModalOpen}
          data-test='royalty-error-modal'
          submitBtnLabel='OK'
          onSubmit={() => {
            this.resetRoyaltyErrorMsg();
            this.toggleErrorModal();
          }}
          onClose={() => {
            this.resetRoyaltyErrorMsg();
            this.toggleErrorModal();
          }}
          onCancel={() => {
            this.resetRoyaltyErrorMsg();
            this.toggleErrorModal();
          }}>
          <>{errorMsg}</>
        </Modal>
        {this.handleAdditions(
          additionSection,
          formObject,
          additionSectionHeaders,
          tableName,
          true,
          royaltyAdditionDropDown,
          getRoyaltyDropDownData,
          updateRoyaltyForm,
          deleteRoyaltyFormRow,
          0,
          false
        )}
        {this.handleAdditions(
          exclusionSection,
          formObject,
          exclusionSectionHeaders,
          tableName,
          false,
          royaltyExclusionDropDown,
          getRoyaltyDropDownData,
          updateRoyaltyForm,
          deleteRoyaltyFormRow,
          1,
          true
        )}
      </>
    );
  }
}

export const mapStateToProps = state => {
  return {
    sections: state.dataForms.form?.sections || [],
    formObject: state.dataForms?.form || {},
    tableName:
      (state.dataForms.form && slugToIDs[state.dataForms.form.formId]) ||
      'royality-additions-exclusions',
    royaltyDescDropDown: state.formsListNav?.royaltyDescDropDown || [],
    showContractExists: state.dataForms.message?.showContractExists,
    showRoyaltyDescExists: state.dataForms.message?.showRoyaltyDescExists
  };
};

export default connect(mapStateToProps, {
  setDataForms,
  updateRoyaltyForm,
  resetDataForms,
  getRoyaltyDropDownData,
  deleteRoyaltyFormRow,
  resetShowRoyaltyDescExists,
  resetShowContractExists
})(withRouter(RoyalityExclusionsAdditions));
