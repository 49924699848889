import { COMMON_TEXT } from '../../constants/text';
import { uiActions } from './types';

export const closeMegaMenu = path => dispatch => {
  if (path !== undefined) {
    if (path.includes(COMMON_TEXT.manageData)) {
      dispatch(setDataMenuActive());
    }
    if (path.includes(COMMON_TEXT.settings)) {
      dispatch(setSettingsMenuActive());
    }
    if (path.includes(COMMON_TEXT.admin)) {
      dispatch(setAdminMenuActive());
    }
  }
  dispatch({
    type: uiActions.CLOSE_MEGA_MENU
  });
};

export const openMegaMenu = (menu, path) => (dispatch, getState) => {
  const { ui } = getState();
  dispatch(setMenuInactive());
  if (ui.selectedMegaMenu === menu) {
    dispatch(closeMegaMenu(path));
    return;
  }
  dispatch({
    type: uiActions.OPEN_MEGA_MENU,
    payload: {
      menu
    }
  });
};

export const setAdminMenuActive = () => ({
  type: uiActions.SET_ADMIN_MENU_ACTIVE
});

export const setDataMenuActive = () => ({
  type: uiActions.SET_DATA_MENU_ACTIVE
});

export const setSettingsMenuActive = () => ({
  type: uiActions.SET_SETTINGS_MENU_ACTIVE
});

export const setMenuInactive = () => ({
  type: uiActions.SET_MENU_INACTIVE
});
export const setManageDataActive = () => ({
  type: uiActions.SET_MANAGE_DATA_MENU_ACTIVE
});
