import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const LinkButton = props => {
  return (
    <Button component={Link} {...props} data-test='component-LinkButton' />
  );
};

LinkButton.propTypes = {
  to: PropTypes.string
};

LinkButton.defaultProps = {
  to: '/'
};

export default LinkButton;
