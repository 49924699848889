/* eslint-disable max-lines-per-function */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';

import './EditUser.css';
import {
  Loader,
  PageSkeleton,
  UserOrganizationalRolesSection,
  CanActivate
} from '../../../../components';

import { getTotalOrganizationRoles } from '../../../../store/global/actions';
import {
  getAdminOrganizationNames,
  addOrganizationData,
  selectPendingUser,
  manageOrgnizationData,
  generateTempararyPassword
} from '../../../../store/admin/actions';
import FormContainer from './FormContainer';
import GenerateTemparoryPasswordModal from '../../../../components/GenerateTemparoryPasswordModal/GenerateTemparoryPasswordModal';
import { ReactComponent as BackButtonIcon } from '../../../../assets/icons/back-icon.svg';
import ProfileRole from '../../../Profile/ProfileRole';
import { toastFunc } from '../../../../components/ToastComponent/toastFunction';
import PageError from '../../../PageError';

export class EditUser extends Component {
  state = {
    canSubmit: true,
    modalIsOpen: false,
    formSearched: false,
    userRole: false,
    impersonateStatus: false,
    impersonate: '',
    activeButtonStatus: false
  };

  static propTypes = {
    totalAdminOrganizationalNames: PropTypes.array,
    totalOrganizationalRoles: PropTypes.array,
    getTotalOrganizationRoles: PropTypes.func.isRequired,
    getAdminOrganizationNames: PropTypes.func.isRequired,
    addOrganizationData: PropTypes.func.isRequired,
    manageOrgnizationData: PropTypes.func.isRequired,
    user: PropTypes.object,
    selectPendingUser: PropTypes.func.isRequired,
    generateTempararyPassword: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  static defaultProps = {
    totalAdminOrganizationalNames: [],
    totalOrganizationalRoles: [],
    user: {}
  };

  componentDidMount() {
    this.props.getTotalOrganizationRoles();
    this.props.getAdminOrganizationNames();
    this.props.selectPendingUser(this.props.match.params.id);
    this.setState({ formSearched: true });
    if (['GU', 'PS'].includes(Object.keys(this.props.roleMap)[0])) {
      this.setState({ userRole: true });
    }
    if (['PA'].includes(Object.keys(this.props.roleMap)[0])) {
      this.setState({ impersonateStatus: true });
    }
    const impersonateData = localStorage.getItem('impersonateUserData')
      ? JSON.parse(localStorage.getItem('impersonateUserData'))
      : {};
    this.setState({
      impersonate: Boolean(Object.keys(impersonateData).length > 0)
    });
  }

  openModal = e => {
    e.preventDefault();
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  generateTempararyPassword = id => {
    this.closeModal();
    this.props
      .generateTempararyPassword({
        userProfileId: id
      })
      .then(res => {
        if (res) {
          toastFunc({
            content: 'Temporary password generated Successfully',
            config: { className: 'toast-container accept' }
          });
          this.props.selectPendingUser(id);
        }
      })
      .catch(e => {
        toastFunc({
          content: e.response.statusText,
          config: { className: 'toast-container reject' }
        });
      });
  };

  handleHeaderLeft = () => {
    const { history } = this.props;
    const { formSearched } = this.state;
    return (
      <Fragment>
        <Link
          to={{
            pathname: '/admin/users/search',
            state: { formSearched }
          }}
          onClick={() => history.replace('/admin/users/search')}>
          <div className='organization-profile-backbutton'>
            <BackButtonIcon />
          </div>
          <div className='oragnization-profile-backtext'>
            <Typography variant='body2'>Back to Search</Typography>
          </div>
        </Link>
      </Fragment>
    );
  };

  toggleactiveButtonStatus = statusCode => {
    if (statusCode === 1) {
      this.setState({ activeButtonStatus: true });
    } else if (statusCode === 2) {
      this.setState({ activeButtonStatus: false });
    } else {
      this.setState({ activeButtonStatus: false });
    }
  };

  render() {
    const {
      totalAdminOrganizationalNames,
      totalOrganizationalRoles,
      addOrganizationData,
      manageOrgnizationData,
      user
    } = this.props;
    const {
      userRole,
      impersonateStatus,
      impersonate,
      activeButtonStatus
    } = this.state;
    const { isLoggedInUser } = user;
    const allInactiveOrg =
      user.orgRoleProfiles &&
      user.orgRoleProfiles.every(org =>
        org.organizationProfiles.every(org => org.status === 'I')
      );
    if (!user || Object.keys(user).length === 0) {
      return <Loader data-test='edit-user-component-loader' />;
    }
    return (
      <CanActivate
        action='admin:edit'
        unAuthorizedView={
          <PageError data-test='edit-user-error' errorCode='401' />
        }>
        <PageSkeleton
          headerLeft={this.handleHeaderLeft()}
          title='Profile Search Results - View Profile'
          container={{ style: { padding: 30 } }}
          data-test='edit-user-component'>
          <Grid
            container
            style={{ border: '1px solid #d9e7ef', borderRadius: '9px' }}>
            {isLoggedInUser && user.orgRoleProfilesWithoutModify.length !== 0 && (
              <Grid
                item
                md={12}
                className='create-user-body-item org-item-section'>
                <ProfileRole roles={user.orgRoleProfilesWithoutModify} />
              </Grid>
            )}
            {!isLoggedInUser && (
              <>
                <FormContainer
                  canSubmit={this.state.canSubmit}
                  form={user}
                  roleMap={userRole}
                  impersonateStatus={impersonateStatus}
                  totalOrganizationalNames={totalAdminOrganizationalNames}
                  userStatus={user.accountStatus}
                  impersonate={impersonate}
                  toggleactiveButtonStatus={this.toggleactiveButtonStatus}
                  activeButtonStatus={activeButtonStatus}
                />
                <Grid
                  item
                  md={12}
                  className='create-user-body-item org-item-section'>
                  <UserOrganizationalRolesSection
                    pageType='edit'
                    user={user}
                    roleMap={userRole}
                    totalOrganizationalNames={totalAdminOrganizationalNames}
                    totalOrganizationalRoles={totalOrganizationalRoles}
                    addOrganizationData={addOrganizationData}
                    manageOrgnizationData={manageOrgnizationData}
                    userStatus={user.accountStatus}
                    impersonate={impersonate}
                    activeButtonStatus={this.state.activeButtonStatus}
                  />
                </Grid>

                <Grid item md={12} className='user-personal-data-container'>
                  <Typography variant='h6'>Password Update</Typography>
                </Grid>
                <Grid item md={12} className='user-personal-data-body-section'>
                  <Typography variant='h6'>Last Updated on</Typography>
                  <Typography
                    variant='h6'
                    style={{ color: '#022A48', fontWeight: '600' }}>
                    {user.passwordLastUpdatedTimeStamp === null ||
                    user.passwordLastUpdatedTimeStamp === undefined
                      ? 'NA'
                      : user.passwordLastUpdatedTimeStamp}
                    <span style={{ color: '#63666B', fontWeight: '400' }}>
                      {' '}
                      by{' '}
                    </span>
                    {user.passwordLastUpdatedBy}
                  </Typography>
                </Grid>
                <Grid item md={12} className='user-personal-data-body-section'>
                  <Button
                    disabled={
                      this.props.user.accountStatus === 'I' ||
                      userRole ||
                      impersonate ||
                      allInactiveOrg
                    }
                    color='primary'
                    variant='contained'
                    size='small'
                    data-test='generate-temporary-password-modal'
                    onClick={e => {
                      this.openModal(e);
                    }}>
                    Generate Temporary Password
                  </Button>
                </Grid>
              </>
            )}
          </Grid>

          {!isLoggedInUser && !impersonate && (
            <GenerateTemparoryPasswordModal
              isOpen={this.state.modalIsOpen}
              userProfileId={user.userProfileId}
              userLoginId={user.userLoginId}
              onConfirm={this.generateTempararyPassword}
              onCancel={this.closeModal}
            />
          )}
        </PageSkeleton>
      </CanActivate>
    );
  }
}

const mapStateToProps = state => ({
  totalAdminOrganizationalNames: state.admin.totalAdminOrganizationalNames,
  user: state.admin.pendingApprovals.selected,
  totalOrganizationalRoles: state.global.totalOrganizationalRoles,
  roleMap: state.user.userRoleMap
});

export default connect(mapStateToProps, {
  getAdminOrganizationNames,
  getTotalOrganizationRoles,
  addOrganizationData,
  selectPendingUser,
  manageOrgnizationData,
  generateTempararyPassword
})(withRouter(EditUser));
