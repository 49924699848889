import React, { useState, useEffect } from 'react';
import { withFormik } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import { landingPageText } from '../../../constants/text';
import {
  personalDetailsSchema,
  accountInfoSchema
} from '../../../constants/validations/registration';
import {
  isValidUserProfileApi,
  generateUserIdApi,
  registerUser
} from '../../../api/auth';
import { createStatusFromErrors } from '../../../utils/commonFunctions';
import { LinkButton } from '../../../components/material';
import PersonalDetails from './PersonalDetails';
import RegistrationWarning from './RegistrationWarning';
import AccountInfo from './AccountInfo';
import { VALIDATE_ERR } from '../../../constants/errors';

export const RegistrationForm = props => {
  const {
    page,
    orgs,
    touched,
    errors,
    status,
    values,
    setFieldTouched,
    handleSubmit,
    onPrevious,
    dirty,
    isValid,
    totalUserRoles
  } = props;

  const [luaStatus, setLuaStatus] = useState(3);

  useEffect(() => {
    const buisnessNameLength = values.businessName.length;
    const buisnessWithLua = values.businessName.filter(
      id => id.luaavailable === true
    ).length;
    const buisnessWithoutLua = values.businessName.filter(
      id => id.luaavailable === false
    ).length;
    if (buisnessNameLength > 0) {
      if (buisnessWithLua >= 1 && buisnessWithoutLua >= 1) {
        setLuaStatus(2);
      } else if (buisnessWithLua >= 1) {
        setLuaStatus(1);
      } else {
        setLuaStatus(3);
      }
    }
  }, [values.businessName]);

  useEffect(() => {
    const statusCheck = Object.keys(status).some(key => status[key].length > 0);
    localStorage.setItem('statusError', statusCheck);
  }, [status]);

  const handleBlur = e => {
    const { handleBlur, status, setStatus } = props;
    e.persist();
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };

  const handleChange = e => {
    const { handleChange } = props;
    handleChange(e);
  };
  return (
    <>
      <RegistrationWarning
        data-test='registration-error-container'
        status={status}
        luaStatus={luaStatus}
        values={values}
      />
      <Grid
        container
        justify='space-between'
        data-test='registration-container'
        style={{ marginBottom: 10 }}>
        <Grid item data-test='registration-register-text-item'>
          <Typography data-test='registration-register-text' variant='h5'>
            {landingPageText.register}
          </Typography>
        </Grid>
        <Grid item data-test='registration-form-subheading-item'>
          <Typography
            align='right'
            className='form-subheading'
            data-test='registration-form-subheading'>
            (
            <Typography
              color='primary'
              component='span'
              className='form-subheading'
              data-test='registration-form-subheading-typo'>
              {page}&nbsp;
            </Typography>
            of 2)
          </Typography>
          <Typography
            align='right'
            data-test='registration-form-subheading-right'>
            {page === 1
              ? landingPageText.personalDetails
              : landingPageText.accountInfoAndSecurity}
          </Typography>
        </Grid>
      </Grid>
      <form noValidate onSubmit={handleSubmit}>
        {page === 1 ? (
          <PersonalDetails
            orgs={orgs}
            values={values}
            status={status}
            touched={touched}
            errors={errors}
            totalUserRoles={totalUserRoles}
            handleChange={handleChange}
            handleBlur={handleBlur}
            dirty={dirty}
            isValid={isValid}
            luaStatus={luaStatus}
            data-test='registration-Personal-Details'
          />
        ) : (
          <AccountInfo
            data-test='registration-account-info'
            values={values}
            status={status}
            touched={touched}
            errors={errors}
            setFieldTouched={setFieldTouched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            dirty={dirty}
            isValid={isValid}
            onPrevious={onPrevious}
          />
        )}
      </form>
      {page === 1 && (
        <>
          <div
            className='seperator'
            data-test='registration-account-seperator'
          />
          <LinkButton
            fullWidth
            to='/'
            variant='outlined'
            color='secondary'
            data-test='registration-account-seperator-link'>
            {landingPageText.alreadyHaveAnAccount}
          </LinkButton>
        </>
      )}
    </>
  );
};

const formConfig = withFormik({
  mapPropsToValues: () => ({
    businessName: [],
    salutation: '',
    firstName: '',
    middleName: '',
    lastName: '',
    jobTitle: '',
    emailId: '',
    userLoginId: '',
    password: '',
    conformPassword: '',
    roleLabel: []
  }),

  mapPropsToStatus: () => ({}),

  validationSchema: ({ page }) => {
    if (page === 1) {
      return personalDetailsSchema;
    }
    return accountInfoSchema;
  },

  validateOnChange: false,

  handleSubmit: (values, formikBag) => {
    const { page, totalUserRoles } = formikBag.props;
    const removeProperties = (obj, propertyNames) => {
      if (obj) {
        propertyNames.forEach(propertyName => {
          delete obj[propertyName];
        });
        return obj;
      }
    };
    if (page === 1) {
      const businessName = values.businessName
        .filter(id => id.luaavailable === true)
        .map(({ id, label }) => ({
          organizationProfileId: parseInt(id),
          organizationProfileName: label
        }));
      const rbody = {
        orgRoleProfiles: [
          {
            organizationProfileVO: businessName
          }
        ],
        firstName: values.firstName,
        lastName: values.lastName,
        jobTitle: values.jobTitle,
        emailId: values.emailId
      };

      const prevReqBody = JSON.parse(localStorage.getItem('prevReqBody'));
      const statusError = localStorage.getItem('statusError');
      if (
        prevReqBody &&
        prevReqBody.firstName === rbody.firstName &&
        prevReqBody.lastName === rbody.lastName &&
        prevReqBody.emailId === rbody.emailId &&
        !statusError
      ) {
        formikBag.props.onNext();
      } else {
        localStorage.setItem('prevReqBody', JSON.stringify(rbody));
        if (
          prevReqBody &&
          prevReqBody.firstName === rbody.firstName &&
          prevReqBody.lastName === rbody.lastName &&
          values.userLoginId
        ) {
          isValidUserProfileApi(rbody)
            .then(res => {
              if (res === true) {
                formikBag.props.onNext();
              } else {
                formikBag.setStatus({ emailId: VALIDATE_ERR });
              }
            })
            .catch(err => {
              formikBag.setStatus(createStatusFromErrors(err));
            });
        } else {
          isValidUserProfileApi(rbody)
            .then(res => {
              if (res === true) {
                generateUserIdApi(rbody)
                  .then(res => {
                    if (res) {
                      values.userLoginId = res;
                      formikBag.props.onNext();
                    }
                  })
                  .catch(err => {
                    formikBag.setStatus(createStatusFromErrors(err));
                  });
              } else {
                formikBag.setStatus({ emailId: VALIDATE_ERR });
              }
            })
            .catch(err => {
              formikBag.setStatus(createStatusFromErrors(err));
            });
        }
      }
    } else {
      const businessName = values.businessName
        .filter(id => id.luaavailable === true)
        .map(({ id, label }) => ({
          organizationProfileId: parseInt(id),
          organizationProfileName: label
        }));
      const preValues = { ...values };
      const filterDetails = totalUserRoles.find(
        id => id.value === values.roleLabel
      );
      const roleDetails = removeProperties(filterDetails, [
        'id',
        'value',
        'label'
      ]);
      const detailsValues = removeProperties(preValues, ['roleLabel']);
      const rbody = {
        ...detailsValues,
        orgRoleProfiles: [
          {
            organizationProfileVO: businessName,
            organizationRoleVO: roleDetails
          }
        ],
        prefix: values.salutation
      };

      registerUser(rbody)
        .then(res => {
          localStorage.clear();
          formikBag.props.register(values);
        })
        .catch(err => {
          formikBag.setStatus(createStatusFromErrors(err));
        });
    }
  }
});

export default formConfig(RegistrationForm);
