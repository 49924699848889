import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { PlaceHolder } from '..';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/up-arrow.svg';
const EmptyDetailsScreen = ({ content, arrowDirection, contentVariant }) => {
  return (
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
      className='empty-user-details-screen-container'
      data-test='component-empty-detail-screen'>
      {arrowDirection === 'Up' ? (
        <Grid item>
          <ArrowUp />
        </Grid>
      ) : null}
      <Grid item>
        <Grid
          container
          justify='center'
          alignItems='center'
          style={{ textAlign: 'center', opacity: '0.3' }}>
          {arrowDirection === 'Left' ? (
            <Grid item>
              <ArrowLeft />
            </Grid>
          ) : null}
          <Grid item>
            <PlaceHolder />
            <PlaceHolder />
            <PlaceHolder />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography
          variant={contentVariant}
          style={{ marginTop: '10px', opacity: '0.4' }}>
          {content}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmptyDetailsScreen;
