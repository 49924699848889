import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Modal } from '../index';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { toastFunc } from '../../components/ToastComponent/toastFunction';
import Box from '@material-ui/core/Box';
import { MESSAGES } from '../../constants/text';

let runningTime = 0;
const IdleTimer = props => {
  const {
    logoutUser,
    usrProfId,
    sessionTime,
    decisionTime,
    promptTimeout,
    isResetTimer,
    resetTimer,
    extendSessionApi
  } = props;
  const [counter, setCounter] = useState(decisionTime);
  const [timer, setTimer] = useState(sessionTime);
  const [open, setOpen] = useState(false);

  const onPrompt = () => {
    setOpen(true);
    setCounter(decisionTime);
  };

  const onIdle = () => {
    setOpen(false);
    toastFunc({
      content: MESSAGES.decisionTimeout,
      config: { className: 'toast-container reject' },
      toastType: 'error'
    });
    setCounter(0);
    logoutUser();
  };

  const onActive = () => {
    setOpen(false);
  };

  const onAction = () => {
    if (runningTime === 0) {
      runningTime = sessionTime;
    }
    if (!isPrompted()) {
      runningTime -= 250;
      setTimer(runningTime);
      reset();
    }
  };

  const { getRemainingTime, isPrompted, activate, reset } = useIdleTimer({
    timeout: timer,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive,
    onAction
  });

  const handleStillHere = () => {
    extendSessionApi()
      .then(res => {
        if (res.data === true) {
          toastFunc({
            content: MESSAGES.sessionExtendSuccess,
            config: { className: 'toast-container accept' },
            toastType: 'success'
          });
          setOpen(false);
          runningTime = sessionTime;
          activate();
        } else if (res.data === false) {
          toastFunc({
            content: MESSAGES.sessionMaxExtended,
            config: { className: 'toast-container reject' },
            toastType: 'error'
          });
          setOpen(false);
          logoutUser();
        }
      })
      .catch(err => {
        setOpen(false);
        if (err?.response?.status) {
          toastFunc({
            content: err?.response?.data?.errorMessage,
            config: { className: 'toast-container reject' },
            toastType: 'error'
          });
          logoutUser();
        }
      });
  };
  const handleLogOff = () => {
    setOpen(false);
    toastFunc({
      content: MESSAGES.sessionLoggedOut,
      config: { className: 'toast-container reject' },
      toastType: 'error'
    });
    logoutUser();
  };

  useEffect(() => {
    if (isResetTimer) {
      resetTimer();
      runningTime = sessionTime;
      setTimer(sessionTime);
    }
    const interval = setInterval(() => {
      if (isPrompted()) {
        setCounter(Math.ceil(getRemainingTime() / 1000) - 1);
      } else {
        runningTime = getRemainingTime() - promptTimeout;
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted, counter, isResetTimer]);

  return (
    <div data-test='idle-timer'>
      {usrProfId && (
        <Modal
          data-test='idle-timer-modal'
          isOpen={open}
          submitBtnLabel='Log-off'
          cancelBtnLabel='Keep me Logged-in'
          onSubmit={handleLogOff}
          onCancel={handleStillHere}>
          <div
            className='circularProgressWithLabel'
            data-test='circularProgressWithLabel'>
            <span data-test='circular-Progress-With-Label-span'>
              Are You Still There? Your session is about to expire due to <br />
              inactivity in approximately {counter} seconds.
            </span>
            <Box
              position='relative'
              data-test='Progress-Box'
              display='inline-flex'>
              <CircularProgress
                variant='static'
                data-test='Progress-Box-circular-Box'
                size={100}
                thickness={5}
                color='primary'
                value={(counter / decisionTime) * 100}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position='absolute'
                display='flex'
                alignItems='center'
                justifyContent='center'
                data-test='circular-Progress-BOX-Label'>
                <Typography
                  variant='h2'
                  component='div'
                  color='primary'
                  data-test='circular-Progress-With-Label-typo'>
                  <b>{`${counter}`}</b>
                </Typography>
              </Box>
            </Box>
          </div>
        </Modal>
      )}
    </div>
  );
};
export { IdleTimer };
export default IdleTimer;
