import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PendingApprovals from './PendingApprovals';
import UserSearch from './UserSearch';
import UserLog from './UserLog';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import PageError from '../../PageError';
import { CanActivate } from '../../../components';

const RoutesArray = [
  { path: '', component: CreateUser, accessKey: 'admin:createuser' },
  { path: 'create', component: CreateUser, accessKey: 'admin:createuser' },
  { path: 'search', component: UserSearch, accessKey: 'admin:search' },
  { path: 'user-logs', component: UserLog, accessKey: 'admin:user-logs' },
  { path: 'edit/:id', component: EditUser, accessKey: 'admin:edit' },
  {
    path: 'approval',
    component: PendingApprovals,
    accessKey: 'user-profile:approval'
  }
];

const Routes = props => {
  const { url } = props.match;
  return (
    <>
      <Switch>
        {RoutesArray.map(route => {
          return (
            <Route
              key={route.path}
              exact
              path={`${url}/${route.path}`}
              render={props => (
                <CanActivate
                  action={route.accessKey}
                  unAuthorizedView={<PageError errorCode='401' />}>
                  <route.component {...props} />
                </CanActivate>
              )}
            />
          );
        })}
      </Switch>
    </>
  );
};

export default Routes;
