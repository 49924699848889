/* eslint-disable max-lines-per-function */
import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withFormik } from 'formik';
import { ActionBar, LastUpdated, Modal } from '../../../../components';
import OrganizationDetailsForm from '../OrganizationDetailsForm';
import OrganizationProfileTable from '../OrganizationProfileTable';
import ProductLines from '../ProductLines';
import {
  updateOrgProfileApi,
  addOrgProfileApi,
  deleteOrgProfileApi,
  updateOrganizationApi
} from '../../../../api/organizations';
import { createStatusFromErrors } from '../../../../utils/commonFunctions';
import { organizationSchema } from '../../../../constants/validations/admin/organization';
import { toastFunc } from '../../../../components/ToastComponent/toastFunction';

export class EditOrganization extends Component {
  state = {
    modalOpen: false,
    filteredProducts: []
  };

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }));
  };

  handleBlur = e => {
    const { handleBlur, status, setStatus } = this.props;
    e.persist();
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };

  addOrgProfile = orgProfile => {
    const { regions, orgTypes, setFieldValue, values } = this.props;

    const newProfile = {
      organizationProfileId: Date().toString(),
      organizationProfileName: orgProfile.organizationProfileName,
      operatingCountryCodeInfo: {
        countryIsoName: orgProfile.operatingCountry.label,
        countryIsoCode: orgProfile.operatingCountry.value
      },
      orgRegionInfo: {
        regionTypeCode: orgProfile.region,
        regionTypeDescription: regions.find(
          region => region.value === orgProfile.region
        ).label
      },
      currencyInfo: {
        currencyTypeCode: orgProfile.currency.value,
        currencyName: orgProfile.currency.label
      },
      orgTypeInfo: {
        orgTypCde: orgTypes.find(org => org.value === orgProfile.entityType).id,
        orgTypNm: orgTypes.find(org => org.value === orgProfile.entityType)
          .label
      },
      iicISOCode: orgProfile.iicCode,
      iicDXSCode: orgProfile.dxsCode,
      activeStatus: orgProfile.extras.includes('active') ? 'T' : 'F',
      sendToFinancialForce: orgProfile.extras.includes('financialForce')
        ? 'T'
        : 'F',
      productInfo: [],
      deleteAllowed: true,
      status: 'A'
    };
    setFieldValue('orgProfiles', [...values.orgProfiles, newProfile]);
  };

  editOrgProfile = profileId => {
    const { setFieldValue, values } = this.props;
    setFieldValue(
      'orgProfiles',
      values.orgProfiles.map(org => {
        if (org.organizationProfileId !== profileId) {
          return org;
        }
        return {
          ...org,
          isEditable: true
        };
      })
    );
  };

  cancelEditOrgProfile = profileId => {
    const { setFieldValue, values } = this.props;
    setFieldValue(
      'orgProfiles',
      values.orgProfiles.map(org => {
        if (org.organizationProfileId !== profileId) {
          return org;
        }
        return {
          ...org,
          isEditable: false
        };
      })
    );
  };

  updateOrgProfile = orgProfile => {
    const { values, orgTypes, setFieldValue } = this.props;
    setFieldValue(
      'orgProfiles',
      values.orgProfiles.map(org => {
        if (org.organizationProfileId !== orgProfile.organizationProfileId) {
          return org;
        }
        return {
          ...org,
          organizationProfileName: orgProfile.organizationProfileName,
          currencyInfo: {
            currencyTypeCode: orgProfile.currency.value,
            currencyName: orgProfile.currency.label
          },
          orgTypeInfo: {
            orgTypCde: orgTypes.find(org => org.value === orgProfile.entityType)
              .id,
            orgTypNm: orgTypes.find(org => org.value === orgProfile.entityType)
              .label
          },
          activeStatus: orgProfile.extras.includes('active') ? 'T' : 'F',
          sendToFinancialForce: orgProfile.extras.includes('financialForce')
            ? 'T'
            : 'F',
          isEditable: false,
          status: org.status === 'A' ? 'A' : 'U'
        };
      })
    );
  };

  deleteOrgProfile = (profileId, hardDelete = false) => {
    const { setFieldValue, values } = this.props;

    if (hardDelete) {
      setFieldValue('deletedOrgProfiles', [
        ...values.deletedOrgProfiles,
        values.orgProfiles.find(org => org.organizationProfileId === profileId)
      ]);
    }
    setFieldValue(
      'orgProfiles',
      values.orgProfiles.filter(org => org.organizationProfileId !== profileId)
    );
  };

  onProductChange = (products, id, type) => {
    const { setFieldValue, values } = this.props;
    setFieldValue(
      'orgProfiles',
      values.orgProfiles.map(org => {
        if (org.organizationProfileId !== id) {
          return org;
        }
        return {
          ...org,
          productInfo: [
            ...org.productInfo,
            ...products.map(product => ({
              ...product,
              productOperationCode: 'A'
            }))
          ],
          status: org.status === 'A' ? 'A' : 'U'
        };
      })
    );
  };

  onProductRemoved = (product, orgId) => {
    const { setFieldValue, values } = this.props;
    setFieldValue(
      'orgProfiles',
      values.orgProfiles.map(org => {
        if (org.organizationProfileId !== orgId) {
          return org;
        }
        return {
          ...org,
          productInfo: org.productInfo
            .filter(
              prod =>
                !(
                  prod.cardProductBandMapId === product.id &&
                  prod.productOperationCode === 'A'
                )
            )
            .map(prod => {
              if (prod.cardProductBandMapId !== product.id) {
                return prod;
              }
              return {
                ...prod,
                productOperationCode: 'D'
              };
            }),
          status: org.status === 'A' ? 'A' : 'U'
        };
      })
    );
  };

  unSelectProductsAll = (orgId, cardBusinessCode) => {
    const { setFieldValue, values } = this.props;
    setFieldValue(
      'orgProfiles',
      values.orgProfiles.map(org => {
        if (org.organizationProfileId !== orgId) {
          return org;
        }

        return {
          ...org,
          productInfo: org.productInfo
            .filter(
              prod =>
                !(
                  prod.cardBusinessCode === cardBusinessCode &&
                  prod.productOperationCode === 'A'
                )
            )
            .map(prod => {
              if (prod.cardBusinessCode !== cardBusinessCode) {
                return prod;
              }
              return {
                ...prod,
                productOperationCode: 'D'
              };
            }),
          status: org.status === 'A' ? 'A' : 'U'
        };
      })
    );
  };

  componentDidUpdate(prevProps) {
    const { orgProfiles } = this.props.values;
    const prevOrgProfiles = prevProps.values.orgProfiles;

    if (prevOrgProfiles !== orgProfiles) {
      const deletedProducts = orgProfiles
        .filter(item =>
          ['Franchise', 'Issuer', 'Net to Net'].includes(
            item.orgTypeInfo.orgTypNm
          )
        )
        .map(item => ({
          ...item,
          productLength:
            item.productInfo && item.productInfo.length > 0
              ? item.productInfo.filter(
                  product =>
                    product.productOperationCode === 'E' ||
                    product.productOperationCode === 'A'
                ).length
              : 0
        }));
      const filteredProducts = deletedProducts.filter(
        product =>
          ['Franchise', 'Issuer', 'Net to Net'].includes(
            product.orgTypeInfo.orgTypNm
          ) && product.productLength === 0
      );
      this.setState({
        filteredProducts
      });
    }
  }

  render() {
    const {
      handleChange,
      setErrors,
      values,
      readOnly,
      countries,
      products,
      orgTypes,
      regions,
      currencies,
      organization,
      dirty,
      isValid,
      touched,
      errors,
      status,
      handleSubmit,
      handleReset,
      userRoles,
      highestRole
    } = this.props;
    const { filteredProducts } = this.state;
    return (
      <div className='organization-container' data-test='edit-org-component'>
        <Modal
          data-test='create-org-modal'
          isOpen={this.state.modalOpen}
          submitBtnLabel='Update'
          isOkButton={filteredProducts.length > 0}
          onClose={this.toggleModal}
          onCancel={this.toggleModal}
          onSubmit={() => {
            this.toggleModal();
            handleSubmit();
          }}>
          {filteredProducts.length > 0 ? (
            <Typography variant='body1' color='secondary'>
              Please add at least one product for following orgs:
              <ul>
                {filteredProducts.map(orgProfile => (
                  <li key={orgProfile.organizationProfileId}>
                    {orgProfile.organizationProfileName}
                  </li>
                ))}
              </ul>
            </Typography>
          ) : (
            <Typography variant='body1' color='secondary'>
              Are you sure you want to update Organization Profile?
            </Typography>
          )}
        </Modal>
        <Grid container direction='column'>
          <ActionBar
            left1={
              <LastUpdated
                type='modify'
                time={organization.lastUpdatedDate}
                user={organization.lastUpdatedByProfileId}
              />
            }
            primaryButton={
              readOnly && userRoles !== false
                ? undefined
                : {
                    label: 'Update',
                    disabled: !isValid,
                    onClick: this.toggleModal
                  }
            }
            secondaryButton={
              readOnly && userRoles !== false
                ? undefined
                : {
                    label: 'Reset',
                    disabled: !dirty,
                    onClick: handleReset
                  }
            }
            rounded='all'
          />
        </Grid>

        <Grid item md={12}>
          <OrganizationDetailsForm
            data-test='org-details-form'
            readOnly={readOnly}
            values={values}
            errors={errors}
            touched={touched}
            status={status}
            countries={countries}
            handleBlur={this.handleBlur}
            handleChange={handleChange}
            orgProfiles={values.orgProfiles}
          />
        </Grid>
        <Grid item md={12}>
          <OrganizationProfileTable
            readOnly={readOnly}
            orgProfiles={values.orgProfiles}
            countries={countries}
            orgTypes={orgTypes}
            regions={regions}
            currencies={currencies}
            onOrgProfileAdd={this.addOrgProfile}
            onOrgProfileEdit={this.editOrgProfile}
            onOrgProfileEditCancel={this.cancelEditOrgProfile}
            onOrgProfileDelete={this.deleteOrgProfile}
            onOrgProfileUpdate={this.updateOrgProfile}
          />
        </Grid>
        <Grid item md={12}>
          <ProductLines
            data-test='org-products'
            readOnly={readOnly}
            userRoles={userRoles}
            setErrors={setErrors}
            errors={errors}
            orgProfiles={values.orgProfiles.filter(
              org => org.orgTypeInfo.orgTypNm !== 'Aquirer'
            )}
            unSelectProductsAll={this.unSelectProductsAll}
            products={products}
            onProductLineChange={this.onProductChange}
            onProductRemoved={this.onProductRemoved}
            highestRole={highestRole}
          />
        </Grid>
      </div>
    );
  }
}

const formConfig = withFormik({
  mapPropsToValues: ({ organization }) => ({
    organizationName: organization.organizationName
      ? organization.organizationName
      : '',
    orgLegalName: organization.orgLegalName,
    headquarterCountry:
      organization.headQuarterCountryCodeInfo &&
      organization.headQuarterCountryCodeInfo.countryIsoCode !== 'null'
        ? organization.headQuarterCountryCodeInfo.countryIsoCode
        : '',
    orgProfiles: organization.organizationProfileList
      ? organization.organizationProfileList
      : [],
    deletedOrgProfiles: []
  }),
  mapPropsToStatus: () => ({}),

  isInitialValid: false,

  validationSchema: organizationSchema,

  handleSubmit: (values, formikBag) => {
    const { deletedOrgProfiles } = values;

    const addedOrgProfiles = values.orgProfiles.filter(
      org => org.status === 'A'
    );

    const updatedOrgProfiles = values.orgProfiles.filter(
      org => org.status === 'U'
    );
    if (values.orgProfiles.length === 0) {
      return toastFunc({
        content: 'Please add Organization Profile',
        config: { className: 'toast-container reject' },
        toastType: 'error'
      });
    }

    /* Update Parent details apis call */
    const parentBody = {
      orgLegalName: values.orgLegalName,
      globalnetOrganizationId:
        formikBag.props.organization.globalnetOrganizationId,
      organizationName: values.organizationName,
      headQuarterCountryCodeInfo: {
        countryIsoCode: values.headquarterCountry
      }
    };
    (async () => {
      let p1;
      let p2;
      let p3;
      let p4;

      try {
        const {
          organization: {
            orgLegalName,
            organizationName,
            headQuarterCountryCodeInfo: { countryIsoCode }
          }
        } = formikBag.props;
        if (
          orgLegalName !== values.orgLegalName ||
          organizationName !== values.organizationName ||
          countryIsoCode !== values.headquarterCountry
        ) {
          p1 = Promise.resolve(updateOrganizationApi(parentBody));
        }
        if (addedOrgProfiles.length) {
          /* Add Child Profile */
          const addedOrgProfilesBody = {
            globalOrganizationId:
              formikBag.props.organization.globalnetOrganizationId,
            organizationProfileInfoVOlist: addedOrgProfiles.map(profile => ({
              iicDXSCode: profile.iicDXSCode,
              iicISOCode: profile.iicISOCode,
              organizationProfileName: profile.organizationProfileName,
              activeStatus:
                profile.activeStatus === 'T' || profile.activeStatus === 'Y'
                  ? 'T'
                  : 'F',
              sendToFinancialForce:
                profile.sendToFinancialForce === 'T' ||
                profile.sendToFinancialForce === 'Y'
                  ? 'T'
                  : 'F',
              orgTypeInfo: {
                orgTypCde: profile.orgTypeInfo.orgTypCde
              },
              currencyInfo: {
                currencyTypeCode: profile.currencyInfo.currencyTypeCode
              },
              orgRegionInfo: {
                regionTypeCode: profile.orgRegionInfo.regionTypeCode
              },
              operatingCountryCodeInfo: {
                countryIsoCode: profile.operatingCountryCodeInfo.countryIsoCode
              },
              productInfo: profile.productInfo.map(product => ({
                cardProductBandMapId: product.cardProductBandMapId,
                productOperationCode: product.productOperationCode
              }))
            }))
          };
          p2 = Promise.resolve(addOrgProfileApi(addedOrgProfilesBody));
        }

        if (updatedOrgProfiles.length) {
          const updatedOrgProfilesBody = {
            organizationProfileInfoVOlist: updatedOrgProfiles.map(profile => ({
              iicDXSCode: profile.iicDXSCode,
              iicISOCode: profile.iicISOCode,
              organizationProfileName: profile.organizationProfileName,
              organizationProfileId: profile.organizationProfileId,
              activeStatus:
                profile.activeStatus === 'T' || profile.activeStatus === 'Y'
                  ? 'T'
                  : 'F',
              sendToFinancialForce:
                profile.sendToFinancialForce === 'T' ||
                profile.sendToFinancialForce === 'Y'
                  ? 'T'
                  : 'F',
              orgTypeInfo: {
                orgTypCde: profile.orgTypeInfo.orgTypCde
              },
              currencyInfo: {
                currencyTypeCode: profile.currencyInfo.currencyTypeCode
              },
              productInfo: profile.productInfo
                .filter(product => product.productOperationCode)
                .map(product => ({
                  cardProductBandMapId: product.cardProductBandMapId,
                  productOperationCode: product.productOperationCode
                }))
            }))
          };
          p3 = Promise.resolve(updateOrgProfileApi(updatedOrgProfilesBody));
        }

        if (deletedOrgProfiles.length) {
          const deletedOrgProfilesBody = {
            organizationProfileInfoVOlist: deletedOrgProfiles.map(profile => ({
              organizationProfileId: profile.organizationProfileId
            }))
          };
          p4 = Promise.resolve(deleteOrgProfileApi(deletedOrgProfilesBody));
        }
        return Promise.all([p1, p2, p3, p4])
          .then(values => {
            toastFunc({
              content: 'Organizations has been Updated Successfully',
              config: { className: 'toast-container accept' }
            });
            formikBag.props.clearOrgProfile();
          })
          .catch(error => {
            formikBag.setStatus(createStatusFromErrors(error));
          });
      } catch (e) {
        formikBag.props.history.replace('/error');
      }
    })();
  }
});

export default formConfig(EditOrganization);
