import React from 'react';
import { Tooltip, Icon } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';
import './tooltip.css';

const WarningTooltip = ({ message }) => {
  if (!message) {
    return null;
  }
  return (
    <Tooltip
      interactive
      data-test='component-WarningTooltip'
      title={message}
      placement='right'
      classes={{
        tooltip: 'grid-tooltip-warning',
        arrow: 'grid-tooltip-warning-arrow'
      }}>
      <Icon data-test='icon-WarningTooltip'>
        <ReportProblemOutlined style={{ fill: '#f3b962' }} />
      </Icon>
    </Tooltip>
  );
};

export default WarningTooltip;
