import React, { Component } from 'react';
import { Modal } from '..';
import { Grid, Typography } from '@material-ui/core';
import LockIcon from '../../assets/icons/generate_password.png';

import PropTypes from 'prop-types';

class UserUpdateProfileModal extends Component {
  render() {
    const {
      isOpen,
      onCancel,
      onConfirm,
      userLoginId,
      userProfileId,
      allActiveOrg
    } = this.props;
    return (
      <>
        <Modal
          title=''
          isOpen={isOpen}
          submitBtnLabel='Confirm'
          data-test='component-create-confirm-modal'
          submitDisabled={!allActiveOrg}
          onCancel={onCancel}
          onSubmit={() => onConfirm(userProfileId)}
          onClose={onCancel}>
          <Grid
            container
            justify='center'
            className='reject-modal-container'
            direction='column'
            alignItems='center'>
            <Grid item md={12}>
              <img
                src={LockIcon}
                alt='title'
                style={{ height: '40px', width: '40px' }}
              />
            </Grid>
            <Grid item md={12}>
              <Typography
                variant='h6'
                style={{ textAlign: 'center', marginTop: '20px' }}>
                Are you sure you want to update below profile
              </Typography>
              <Typography
                variant='h6'
                style={{
                  textAlign: 'center',
                  color: '#FF6000',
                  fontWeight: '600',
                  margin: '7px'
                }}>
                {userLoginId}
              </Typography>
              {!allActiveOrg && (
                <Typography
                  variant='h6'
                  style={{
                    textAlign: 'center',
                    color: 'red',
                    fontSize: '12px',
                    fontWeight: '600',
                    margin: '7px',
                    marginTop: '10px'
                  }}>
                  User does not have any approved org profile*
                </Typography>
              )}
            </Grid>
          </Grid>
        </Modal>
      </>
    );
  }
}

UserUpdateProfileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  userLoginId: PropTypes.string.isRequired,
  userProfileId: PropTypes.any.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default UserUpdateProfileModal;
