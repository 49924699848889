import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IdleTimerContainer from './IdleTimerContainer';
import { logoutUser } from '../../store/auth';
import { getUser } from '../../store/user';
import { resetTimer, getSessionTime } from '../../store/global';

export const mapStateToProps = state => ({
  usrProfId: state.user.usrProfId,
  isResetTimer: state.global.isResetTimer,
  sessionTime: state.global.sessionTime
});

export const mapDispatchToProps = {
  getUser,
  logoutUser,
  resetTimer,
  getSessionTime
};

export { IdleTimerContainer };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IdleTimerContainer)
);
