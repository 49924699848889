import * as Yup from 'yup';
import errors from './fieldErrors';

const {
  businessName,
  firstName,
  lastName,
  middleName,
  jobTitle,
  email,
  otpPin,
  userLoginId,
  password,
  confirmPassword,
  organizationName,
  orgLegalName,
  organizationProfileName,
  region,
  currency,
  operatingCountry,
  entityType,
  royaltyRateScaleTireNumber,
  royaltyRateScaleFrom,
  royaltyRateScaleRate,
  contractNotes,
  effectiveStartDate,
  effectiveEndDate,
  contractType
} = errors;

const emailRegexComponents = [
  '^\\w+',
  '([\\.-]?\\w+)*',
  '@\\w+',
  '([\\.-]?\\w+)*',
  '(\\.\\w{2,})+$'
];
const emailRegex = new RegExp(emailRegexComponents.join(''));
export default {
  businessName: Yup.array()
    .required(businessName.empty)
    .label('BusinessName'),
  salutation: Yup.string().label('Salutation'),
  firstName: Yup.string()
    .required(firstName.empty)
    .max(40, firstName.invalid)
    .min(1, firstName.invalid)
    .matches(/^[a-zA-Z]+(?:[\s\-']{1}[a-zA-Z]+)*$/, {
      message: firstName.invalid,
      excludeEmptyString: true
    }),
  middleName: Yup.string().matches(/^[a-zA-Z]+(?:[\s\-']{1}[a-zA-Z]+)*$/, {
    message: middleName.invalid,
    excludeEmptyString: true
  }),
  lastName: Yup.string()
    .required(lastName.empty)
    .min(1, lastName.invalid)
    .max(40, lastName.invalid)
    .matches(/^[a-zA-Z]+(?:[\s\-']{1}[a-zA-Z]+)*$/, {
      message: lastName.invalid,
      excludeEmptyString: true
    }),
  jobTitle: Yup.string()
    .required(jobTitle.empty)
    .max(100, jobTitle.invalid)
    .min(1, jobTitle.invalid)
    .matches(/^[A-Za-z]+[A-Za-z'-.\s]*$/, {
      message: jobTitle.invalid,
      excludeEmptyString: true
    }),
  emailId: Yup.string()
    .email(email.invalid)
    .required(email.empty)
    .max(100, email.invalid)
    .min(7, email.invalid)
    .matches(emailRegex, {
      message: email.invalid,
      excludeEmptyString: true
    }),
  otpPin: Yup.string()
    .required(otpPin.empty)
    .matches(/^[1-9]\d{5}$/, {
      message: otpPin.invalid,
      excludeEmptyString: true
    }),
  userLoginId: Yup.string()
    .required(userLoginId.empty)
    .max(20, userLoginId.invalid)
    .min(5, userLoginId.invalid)
    .matches(/^[A-Za-z0-9_]*$/, {
      message: userLoginId.invalid,
      excludeEmptyString: true
    })
    .test(
      '_ at start and end',
      userLoginId.invalid,
      value => !(value && value.search(/(^_.*)|(.*_$)/) !== -1)
    )
    .test(
      'consecutive _',
      userLoginId.invalid,
      value => !(value && value.search(/(_)\1/) !== -1)
    ),

  password: Yup.string()
    .required(password.empty)
    .min(15, password.invalid)
    .max(20, password.invalid)
    .matches(/[A-Z]/, {
      message: password.invalid,
      excludeEmptyString: true
    })
    .matches(/[a-z]/, {
      message: password.invalid,
      excludeEmptyString: true
    })
    .matches(/\d/, {
      message: password.invalid,
      excludeEmptyString: true
    })
    .matches(/[-!$%^&*()_+|~=`{}\[\]:\/;?,.@#]/, {
      message: password.invalid,
      excludeEmptyString: true
    })
    .test(
      'consecutive character',
      password.invalid,
      value => !(value && value.search(/(.)\1/) !== -1)
    ),
  conformPassword: Yup.string()
    .required(confirmPassword.empty)
    .min(15, confirmPassword.invalid)
    .max(20, confirmPassword.invalid)
    .matches(/[A-Z]/, {
      message: confirmPassword.invalid,
      excludeEmptyString: true
    })
    .matches(/[a-z]/, {
      message: confirmPassword.invalid,
      excludeEmptyString: true
    })
    .matches(/\d/, {
      message: confirmPassword.invalid,
      excludeEmptyString: true
    })
    .matches(/[-!$%^&*()_+|~=`{}\[\]:\/;?,.@#]/, {
      message: confirmPassword.invalid,
      excludeEmptyString: true
    })
    .test(
      'consecutive character',
      confirmPassword.invalid,
      value => !(value && value.search(/(.)\1/) !== -1)
    )
    .oneOf(
      [Yup.ref('password')],
      'Passwords do not match. Please enter matching passwords.'
    ),
  organizationName: Yup.string()
    .required(organizationName.empty)
    .matches(/^[a-zA-Z0-9]+(?:[-_ ][a-zA-Z0-9]+)*$/, organizationName.invalid)
    .label('organizationName'),
  orgLegalName: Yup.string()
    .required(orgLegalName.empty)
    .matches(/^[a-zA-Z0-9]+(?:[-_ ][a-zA-Z0-9]+)*$/, orgLegalName.invalid)
    .label('orgLegalName'),
  headquarterCountry: Yup.string()
    .required('Please select a value for HeadQuarter Country')
    .label('headquarterCountry'),
  organizationProfileName: Yup.string()
    .required(organizationProfileName.empty)
    .matches(
      /^[a-zA-Z0-9 |,|.|*|%|&|#|'|$|:|(|)|?|^|\||_|\-|/]*$/,
      organizationProfileName.invalid
    ),
  region: Yup.string().required(region.empty),
  currency: Yup.object().required(currency.empty),
  operatingCountry: Yup.object().required(operatingCountry.empty),
  entityType: Yup.string().required(entityType.empty),
  iicCode: Yup.string()
    .required('Please enter a value ISO Institution Identification Code (IIC).')
    .matches(
      /^\d*$/,
      'ISO Institution Identification Code (IIC) contains Invalid characters. Please enter a valid ISO Institution Identification Code (IIC).'
    )
    .min(
      11,
      'ISO Institution Identification Code (IIC) must contains exact 11 characters'
    )
    .max(
      11,
      'ISO Institution Identification Code (IIC) must contains exact 11 characters'
    )
    .label('iicCode'),
  dxsCode: Yup.string()
    .required(
      'Please enter a value for DXS Institution Identification Code (IIC)'
    )
    .matches(
      /^[a-zA-Z0-9][a-zA-Z0-9 ]*$/,
      'DXS Institution Identification Code (IIC) contains Invalid characters. Please enter a valid DXS Institution Identification Code (IIC).'
    )
    .min(
      2,
      'DXS Institution Identification Code (IIC) must contains at least 2 characters.'
    )
    .max(
      3,
      'DXS Institution Identification Code (IIC) must contains at most 3 characters.'
    )
    .label('dxsCode'),
  royaltyRateScaleTireNumber: Yup.string()
    .matches(/^\d{1,2}$|^.{0}$/, royaltyRateScaleTireNumber.invalid)
    .label('tierNumber'),
  royaltyRateScaleFrom: Yup.string()
    .matches(/^\d{0,19}$/, royaltyRateScaleFrom.invalid)
    .label('from'),
  royaltyRateScaleRate: Yup.string()
    .matches(/(\d|\.)/, royaltyRateScaleRate.invalidChar)
    .matches(/(^\d{1,4}\.\d{1,7}$|^.{0}$)/, royaltyRateScaleRate.invalid)
    .label('rate'),
  contractOrganizationName: Yup.object()
    .required(organizationName.empty)
    .nullable(),
  contractNotes: Yup.string()
    .required(contractNotes.empty)
    .min(1, contractNotes.length)
    .max(4000, contractNotes.length)
    .matches(/[\w,.*%&#$:()?^|\s_\-']+/, {
      message: contractNotes.invalid,
      excludeEmptyString: true
    }),
  contractType: Yup.string().required(contractType.empty),
  effectiveStartDate: Yup.date().max(
    Yup.ref('effectiveEndDate'),
    effectiveStartDate.invalid
  ),
  effectiveEndDate: Yup.date().min(
    Yup.ref('effectiveStartDate'),
    effectiveEndDate.invalid
  )
};
