import React, { PureComponent } from 'react';
import {
  Checkbox as MaterialCheckbox,
  FormControlLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import { ReactComponent as CheckboxCheckedIcon } from '../../assets/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxDisabledIcon } from '../../assets/icons/checkbox-disabled.svg';

import PropTypes from 'prop-types';

const styles = {
  icon: {
    height: 24,
    width: 24
  }
};

export class Checkbox extends PureComponent {
  render() {
    const { classes, disabled, label } = this.props;

    const rootProps = { ...this.props };
    delete rootProps.classes;

    return (
      <FormControlLabel
        data-test='component-Checkbox'
        {...rootProps}
        control={
          <MaterialCheckbox
            checkedIcon={
              disabled ? (
                <CheckboxDisabledIcon
                  data-test='checkbox-checked-disabled-icon'
                  className={classes.icon}
                />
              ) : (
                <CheckboxCheckedIcon
                  data-test='checkbox-checked-icon'
                  className={classes.icon}
                />
              )
            }
          />
        }
        label={
          <Typography variant='body2' data-test='checkbox-label'>
            {label}
          </Typography>
        }
      />
    );
  }
}

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func
};

Checkbox.defaultProps = {
  name: '',
  checked: false,
  disabled: false,
  label: '',
  value: '',
  onChange: () => {}
};

export default withStyles(styles)(Checkbox);
