import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PageError from '../../PageError';
import { CanActivate } from '../../../components/';

import SearchOrganization from './Search';
import CreateOrganization from './Create';
import EditOrganization from './Edit';

const RoutesArray = [
  { path: '', component: CreateOrganization, accessKey: 'org-profile:create' },
  {
    path: 'search',
    component: SearchOrganization
  },
  {
    path: 'create',
    component: CreateOrganization
  },
  {
    path: 'edit/:id',
    component: EditOrganization
  }
];

const Routes = props => {
  const { url } = props.match;
  return (
    <CanActivate
      action='org-profile:navigate'
      unAuthorizedView={<PageError errorCode='401' />}>
      <Switch>
        {RoutesArray.map(route => {
          return (
            <Route
              key={route.path}
              exact
              path={`${url}/${route.path}`}
              render={props => <route.component {...props} />}
            />
          );
        })}
      </Switch>
    </CanActivate>
  );
};

export default Routes;
