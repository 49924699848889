/* istanbul ignore file */
export const LABELS = {
  PASSWORD: 'Password',
  PASSREQ: 'Password Requirements'
};

export default {
  welcome: 'WELCOME TO',
  welcomeHeading: 'GlobalNet',
  welcomeSubHeading: 'Partner Reporting Solution',
  firstTimeVisitor: 'First Time Visitor? Sign Up',
  firstTimeVisitorRemark: 'Discover Users request access via IDM',
  forgotUserId: 'Forgot User ID?',
  forgotPassword: 'Forgot Password?',
  resetPassword: 'Reset Password?',
  changePassword: 'Change Password',
  changePasswordText: 'Your password has expired, please change it',
  internalUser: 'Internal User',
  loginToYourAccount: 'Login to your account',
  forgotUserIdTitle: 'Forgot User Id',
  forgotUserIdText:
    'Please provide your email address and we will send you a message with your User ID',
  forgotPasswordText:
    'Please provide your User ID and email address and we will send you a message with your Password',
  userIDVerificationText: 'User Id Verification',
  userIDValidationText:
    'Welcome first time user. As a one time verification check we would like to verify the email address associated with your user id',
  ssoPasswordText: 'Discover Users can use their SSO sign in credentials',
  userIdLabel: 'User ID',
  emailId: 'Email ID',
  passwordLabel: LABELS.PASSWORD,
  help: 'Help',
  loginButton: 'Login',
  alreadyHaveAnAccount: 'Already have an account? Sign In',
  register: 'Registration',
  next: 'Next',
  submit: 'Submit',
  cancel: 'Cancel',
  luaWarning:
    'Registration request can be submitted for the organization/s which has Local User Administrator.',
  luaPassingWarning:
    'Your request will be proceeded for the organization/s which have Local User Administrator.',
  personalDetails: 'Personal Details',
  businessNameLabel: 'Business Name',
  salutationLabel: 'Salutation',
  roleLabel: 'Requested Role',
  firstNameLabel: 'First Name',
  middleNameLabel: 'Middle Name',
  lastNameLabel: 'Last Name',
  jobTitleLabel: 'Job Title',
  emailIdLabel: 'Email ID',
  setUserIdLabel: 'User Login ID',
  createPasswordLabel: 'Create Password',
  confirmPasswordLabel: 'Confirm Password',
  accountInfoAndSecurity: 'Account info & Security',
  changePersonalDetails: 'Change Personal Details',
  userIdRequirements: 'User ID Requirements',
  passwordRequirements: LABELS.PASSREQ,
  applicationRole: 'Application Role',
  otpTitle: 'Secured Authentication',
  otpText:
    'To complete your login, please enter the PIN received in your email',
  otpLabel: 'PIN',
  resendOtp: 'Resend OTP'
};
