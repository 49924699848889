import React, { useEffect } from 'react';
import { PageSkeleton } from '../../../components';
import ExtensionRequestTable from './ExtensionRequestTable';
import './ExtensionRequestTable.css';

const tableHeaders = [
  {
    title: 'Participants/Franchise Name',
    key: 'Product',
    value: ''
  },
  {
    title: 'Screen Category',
    key: 'Code',
    value: ''
  },
  {
    title: 'Extension expiration date',
    key: 'Branding',
    value: ''
  },
  {
    title: 'Comment',
    key: 'Code',
    value: ''
  },
  {
    title: 'Waiver Form',
    key: 'form',
    value: ''
  },
  {
    title: ''
  }
];

export const brandingType = [
  {
    value: 'Monthly',
    label: 'Monthly'
  },
  {
    value: 'Quarterly',
    label: 'Quarterly'
  },
  {
    value: 'Annually',
    label: 'Annually'
  }
];
export const ExtensionRequest = props => {
  const {
    usersOrganizations,
    getAdminOrganizationNames,
    createExtensionRequest,
    getAllExchangeRequest,
    extensionRequestUpdate,
    exchangeRequestData
  } = props;

  useEffect(() => {
    getAdminOrganizationNames();
    getAllExchangeRequest();
  }, []);

  return (
    <PageSkeleton title='Extension Requests' data-test='Extension-Request'>
      <ExtensionRequestTable
        tableHeaders={tableHeaders}
        usersOrganizations={usersOrganizations}
        brandingType={brandingType}
        createExtensionRequest={createExtensionRequest}
        getAllExchangeRequest={getAllExchangeRequest}
        exchangeRequestData={exchangeRequestData}
        extensionRequestUpdate={extensionRequestUpdate}
      />
    </PageSkeleton>
  );
};

export default ExtensionRequest;
