import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { countToPercentage } from '../../utils/commonFunctions';
import CircularProgress from '../../components/CircularProgress';

const CircularDivComponent = ({
  totalValue,
  value,
  textContent,
  onClickFilter
}) => {
  const degreeValue = countToPercentage(totalValue, value);
  return (
    <Grid
      container
      justify='space-between'
      alignItems='center'
      className='circular-div-container'
      data-test='component-circular-div'>
      <Grid item className='circular-progress-div'>
        <CircularProgress value={value} degreeValue={degreeValue} />
      </Grid>
      <Grid
        item
        className='circular-div-title-section'
        tabIndex='0'
        onKeyUp={e => (e.keyCode === 13 ? onClickFilter() : false)}
        onClick={onClickFilter}>
        <Typography variant='body1' className='circular-div-title'>
          {textContent}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CircularDivComponent;
