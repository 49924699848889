/* eslint-disable max-lines-per-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, Grid, Button } from '@material-ui/core';
import BlockHeader from './BlockHeader';
import PropTypes from 'prop-types';
import UpdatePasswordForm from './UpDatePasswordForm';
import { withFormik } from 'formik';
import {
  changepasswordProfile,
  setUpdateUserPasswordValue
} from '../../store/user';
import { toast } from 'react-toastify';
import ToastComponent from '../../components/ToastComponent/ToastComponent';
import { passwordRequirement } from '../../constants/passwordRule';
import { updatePasswordSchema } from '../../constants/validations/updatePassword';
const passwordRequirements = passwordRequirement;

export class UpdatePassword extends Component {
  state = {
    passwordHidden: true,
    validations: {
      password: [...passwordRequirements]
    }
  };

  toggleShowPassword = () => {
    this.setState(prevState => ({
      passwordHidden: !prevState.passwordHidden
    }));
  };

  handlePasswordChange = e => {
    e.persist();
    const { handleChange } = this.props;
    this.getPasswordValidations(e);
    handleChange(e);
  };

  handleCancelPasswordChange = () => {
    const { resetForm, toggleDiv } = this.props;
    resetForm({
      values: {
        currentPassword: '',
        password: '',
        conformPassword: ''
      }
    });
    toggleDiv();
  };

  getPasswordValidations = () => {
    const { validations } = this.state;
    const {
      values: { password }
    } = this.props;
    return validations.password.map(validation => {
      let valid;
      if (password && password.search(validation.pattern) !== -1) {
        valid = true;
      } else {
        valid = false;
      }
      return {
        ...validation,
        valid
      };
    });
  };

  componentDidUpdate(prevProps) {
    const {
      myProfileError,
      myProfileErrorMsg,
      isPasswordUpdated,
      setUpdateUserPasswordValue
    } = this.props;
    if (myProfileError !== prevProps.myProfileError) {
      if (myProfileError) {
        const [errorMessage] = Object.values(myProfileErrorMsg);
        toast(<ToastComponent content={errorMessage} toastType='error' />, {
          closeButton: false,
          hideProgressBar: true,
          className: 'toast-container reject',
          bodyClassName: 'toast-body'
        });
      }
    }
    if (isPasswordUpdated !== prevProps.isPasswordUpdated) {
      if (isPasswordUpdated) {
        setUpdateUserPasswordValue(isPasswordUpdated);
        toast(<ToastComponent content='User password updated Successfully' />, {
          closeButton: false,
          hideProgressBar: true,
          className: 'toast-container accept',
          bodyClassName: 'toast-body'
        });
        const { resetForm, toggleDiv } = this.props;
        resetForm({
          values: {
            currentPassword: '',
            password: '',
            conformPassword: ''
          }
        });
        toggleDiv();
      }
    }
  }

  render() {
    const {
      edit,
      toggleDiv,
      passwordData,
      values,
      handleChange,
      handleSubmit,
      errors,
      touched,
      updateInfo,
      dirty,
      isValid,
      impersonate
    } = this.props;
    const { passwordHidden } = this.state;
    const fromattedUpdateInfo =
      updateInfo.timeStamp !== null || updateInfo.timeStamp !== undefined
        ? updateInfo.timeStamp
        : 'NA';
    return (
      <div aria-label='password update' data-test='update-password-component'>
        <BlockHeader title='Password Update' />
        <Grid
          container
          className='personal-data-inputs-container password-elements-container'>
          {edit === true && (
            <>
              <Grid item className='password-change-grid-item' md={3}>
                <Typography
                  className='profile-password-last-update'
                  variant='body1'>
                  Last Updated on
                </Typography>
                <Typography
                  className='profile-password-last-update last-update-body'
                  variant='body1'>
                  <span className='heavy-txt'>{fromattedUpdateInfo}</span> by{' '}
                  <span className='heavy-txt'>{` ${updateInfo.updatedBy}`}</span>
                </Typography>
                <Grid item className='password-change-grid-item' md={9}>
                  {!passwordData.showPasswordSection && (
                    <div className='toggle-pass-div'>
                      <Button
                        color='primary'
                        variant='contained'
                        size='small'
                        data-test='change-password-button'
                        disabled={impersonate}
                        onClick={toggleDiv}>
                        Change Password
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>

              {passwordData.showPasswordSection === true && (
                <Grid
                  item
                  className='password-change-grid-item'
                  md={12}
                  data-test='password-change-grid-container'>
                  <UpdatePasswordForm
                    values={values}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    handlePasswordChange={this.handlePasswordChange}
                    passwordHidden={passwordHidden}
                    toggleShowPassword={this.toggleShowPassword}
                    getPasswordValidations={this.getPasswordValidations}
                    handleCancelPasswordChange={this.handleCancelPasswordChange}
                    touched={touched}
                    dirty={dirty}
                    isValid={isValid}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </div>
    );
  }
}

UpdatePassword.propTypes = {
  edit: PropTypes.bool.isRequired,
  toggleDiv: PropTypes.func.isRequired,
  passwordData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  updateInfo: PropTypes.object.isRequired
};
const formConfig = withFormik({
  mapPropsToValues: () => ({
    currentPassword: '',
    password: '',
    conformPassword: ''
  }),
  mapPropsToStatus: () => ({}),
  validationSchema: updatePasswordSchema,
  handleSubmit: (values, formikBag) => {
    const rbody = {
      userProfileId: formikBag.props.userProfileId,
      currentPassword: values.currentPassword,
      newPassword: values.password,
      conformNewPassword: values.conformPassword
    };
    formikBag.props.changepasswordProfile(rbody);
  }
});

export const mapStateToProps = state => {
  return {
    userProfileId: state.user.usrProfId,
    isPasswordUpdated: state.user.isPasswordUpdated
  };
};
const mapDispatchToProps = {
  changepasswordProfile,
  setUpdateUserPasswordValue
};
export const UpdatePasswordWithFormik = formConfig(UpdatePassword);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePasswordWithFormik);
