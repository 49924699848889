import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input, IconButton, InputAdornment } from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search-white.svg';
import './TextFieldSearch.css';

const TextFieldSearch = ({ onChange, value }) => {
  return (
    <Fragment>
      <Input
        disableUnderline
        fullWidth
        type='search'
        className='textfield-search'
        value={value}
        placeholder='Search'
        endAdornment={
          <InputAdornment>
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        onChange={onChange}
      />
    </Fragment>
  );
};

TextFieldSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

TextFieldSearch.defaultProps = {
  value: ''
};

export default TextFieldSearch;
