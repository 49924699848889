/* eslint-disable max-lines-per-function */
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { Dropdown, TextField } from '../../../components/material';
import './Organization.css';

const OrganizationDetailsForm = props => {
  const {
    readOnly,
    values,
    countries,
    handleBlur,
    touched,
    handleChange,
    errors,
    status,
    location,
    orgProfiles = []
  } = props;

  const selectedOrg = orgProfiles.filter(
    org => org.organizationProfileId === location.state?.organizationProfileId
  );
  const selectedOrgName =
    selectedOrg.length > 0 ? selectedOrg[0].organizationProfileName : '';
  const colSize = selectedOrgName.length > 0 ? 3 : 4;

  return (
    <Fragment>
      <div className='oraganization-profile-form-header'>
        <Typography className='txt'>Organization Details</Typography>
      </div>
      <div className='organization-details-form'>
        <Grid container justify='space-around'>
          {selectedOrgName && (
            <Grid item md={colSize} className='field'>
              <>
                <Typography
                  variant='body2'
                  className={`${selectedOrgName ? 'edit-check' : ''}`}>
                  Organization Profile Name
                </Typography>
                <Typography
                  variant='body1'
                  className='organization-page-main-text edit-mode'>
                  {selectedOrgName}
                </Typography>
              </>
            </Grid>
          )}
          <Grid item md={colSize} className='field'>
            {readOnly ? (
              <>
                <Typography variant='body2'>
                  Parent Organization Name
                </Typography>
                <Typography
                  variant='body1'
                  className='organization-page-main-text edit-mode'>
                  {values.organizationName}
                </Typography>
              </>
            ) : (
              <TextField
                fullWidth
                required
                id='organization-name'
                label='Parent Organization Name'
                name='organizationName'
                onBlur={handleBlur}
              />
            )}
          </Grid>
          <Grid item md={colSize} className='field'>
            {readOnly ? (
              <>
                <Typography variant='body2'>
                  Parent Organization Legal Name
                </Typography>
                <Typography
                  variant='body1'
                  className='organization-page-main-text edit-mode'>
                  {values.orgLegalName}
                </Typography>
              </>
            ) : (
              <TextField
                fullWidth
                required
                id='organizationlegalname'
                label='Parent Organization Legal Name'
                name='orgLegalName'
                onBlur={handleBlur}
              />
            )}
          </Grid>
          <Grid item md={colSize} className='field'>
            {readOnly ? (
              <>
                <Typography variant='body2'>
                  Parent Headquarter Country
                </Typography>
                <Typography
                  variant='body1'
                  className='organization-page-main-text edit-mode'>
                  {values.headquarterCountry}
                </Typography>
              </>
            ) : (
              <Dropdown
                fullWidth
                required
                id='headquarter-country'
                options={countries}
                label='Parent Headquarter Country'
                value={values.headquarterCountry}
                error={
                  touched.headquarterCountry &&
                  Boolean(
                    errors.headquarterCountry || status.headquarterCountry
                  )
                }
                helperText={
                  touched.headquarterCountry &&
                  (errors.headquarterCountry || status.headquarterCountry)
                }
                name='headquarterCountry'
                onBlur={handleBlur}
                onChange={handleChange}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};
export default withRouter(OrganizationDetailsForm);
