/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

const DataTips = props => {
  return (
    <div className=''>
      <Grid
        container
        className='data-tips'
        direction='column'
        justify='space-between'>
        <Grid item role='region' aria-labelledby='data-tips-heading'>
          <Typography
            className='tips-heading'
            data-test='tips-heading'
            color='primary'
            varient='h5'
            id='data-tips-heading'>
            {props.title}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container className='tips-container' data-test='tips-container'>
            {props.tips.map((tip, index) => (
              <Fragment key={index}>
                <Grid
                  item
                  className='data-tip'
                  data-test={`tip-${index}`}
                  xs={12}>
                  <Typography variant='caption' className='data-tip-txt'>
                    {index + 1}. {tip}
                  </Typography>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

DataTips.propTypes = {
  tips: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  showDownload: PropTypes.bool.isRequired
};
export default DataTips;
