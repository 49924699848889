/* istanbul ignore file */
/* eslint-disable max-lines-per-function */
import React, { Component } from 'react';
import { TextField, Button, Chip, Avatar, Grid } from '@material-ui/core';
import {
  Checkbox,
  Combobox,
  Dropdown,
  Radio,
  MultipleDropdown,
  Switch,
  MultipleCombobox
} from './components/material';

const currencies = [
  {
    value: 'USD',
    label: '$'
  },
  {
    value: 'EUR',
    label: '€'
  },
  {
    value: 'BTC',
    label: '฿'
  },
  {
    value: 'JPY',
    label: '¥'
  }
];

const topFilms = [
  { label: 'Rio', value: 'Rio' },
  { label: 'Her', value: 'Her' },
  { label: 'Kni', value: '' },
  { label: 'Men', value: 'Men' },
  { label: 'Fiction', value: 'Fiction' }
];

class Inputs extends Component {
  state = {
    currency: '',
    multiDropdownValue: ['none'],
    checked: false,
    currencyCombo: 'none',
    gender: '',
    favSports: ['football'],
    multiCombo: []
  };

  handleCheckboxChange = e => {
    const { checked, value } = e.target;
    if (checked) {
      this.setState(prevState => ({
        favSports: [...prevState.favSports, value]
      }));
    } else {
      this.setState(prevState => ({
        favSports: prevState.favSports.filter(sport => sport !== value)
      }));
    }
  };

  handleRadioChange = e => {
    const { value } = e.target;
    this.setState({
      gender: value
    });
  };

  handleChange = e => {
    this.setState({ currency: e.target.value });
  };

  handleMultipleChange = e => {
    const { value } = e.target;
    this.setState({ multiDropdownValue: value });
  };

  handleComboboxChange = e => {
    this.setState({ currencyCombo: e.target.value });
  };

  handleMultiComboboxChange = e => {
    this.setState({
      multiCombo: e.target.value
    });
  };

  toggle = () => {
    this.setState(prevState => ({
      checked: !prevState.checked
    }));
  };

  render() {
    const {
      currency,
      multiDropdownValue,
      checked,
      currencyCombo,
      favSports,
      gender,
      multiCombo
    } = this.state;
    return (
      <div style={{ padding: 40, width: '-webkit-fill-available' }}>
        <Grid container justify='space-between'>
          <TextField multiline label='Text' />
          <TextField error label='Text' />
          <TextField required label='Text' />
          <TextField required disabled label='Text' />
        </Grid>
        <br />
        <Grid container justify='space-between'>
          <Dropdown
            options={currencies}
            label='Dropdown'
            // Placeholder='Please Select'
            value={currency}
            onChange={this.handleChange}
          />
          <Dropdown
            required
            options={currencies}
            label='Required'
            placeholder='Please Select'
            value={currency}
            onChange={this.handleChange}
          />
          <Dropdown
            disabled
            options={currencies}
            label='Disabled'
            placeholder='Please Select'
            value={currency}
            onChange={this.handleChange}
          />
          <Dropdown
            error
            options={currencies}
            label='Error'
            placeholder='Please Select'
            value={currency}
            onChange={this.handleChange}
          />
        </Grid>
        <br />
        <Grid container justify='space-between'>
          <MultipleDropdown
            name='name'
            label='Multi Dropdown'
            placeholder='Please Select'
            value={multiDropdownValue}
            options={currencies}
            onChange={this.handleMultipleChange}
          />
          <MultipleDropdown
            required
            name='name'
            label='Required'
            placeholder='Please Select'
            value={multiDropdownValue}
            options={currencies}
            onChange={this.handleMultipleChange}
          />
          <MultipleDropdown
            disabled
            name='name'
            label='Disabled'
            placeholder='Please Select'
            value={multiDropdownValue}
            options={currencies}
            onChange={this.handleMultipleChange}
          />
          <MultipleDropdown
            error
            name='name'
            label='Error'
            placeholder='Please Select'
            value={multiDropdownValue}
            options={currencies}
            onChange={this.handleMultipleChange}
          />
        </Grid>
        <br />
        <Grid container justify='space-between'>
          <Combobox
            name='name'
            label='Combobox'
            placeholder='Please Select'
            value={currencyCombo}
            options={currencies}
            style={{ width: 200 }}
            onChange={this.handleComboboxChange}
          />
          <Combobox
            required
            name='name'
            label='Required'
            placeholder='Please Select'
            value={currencyCombo}
            options={currencies}
            style={{ width: 200 }}
            onChange={this.handleComboboxChange}
          />
          <Combobox
            disabled
            name='name'
            label='Disabled'
            placeholder='Please Select'
            value={currencyCombo}
            options={currencies}
            style={{ width: 200 }}
            onChange={this.handleComboboxChange}
          />
          <Combobox
            error
            name='name'
            label='Error'
            placeholder='Please Select'
            value={currencyCombo}
            options={currencies}
            style={{ width: 200 }}
            onChange={this.handleComboboxChange}
          />
        </Grid>
        <br />
        <Grid container justify='space-between'>
          <MultipleCombobox
            limit={1}
            options={topFilms}
            label='Multi Combobox'
            style={{ width: 290 }}
            value={multiCombo}
            onChange={this.handleMultiComboboxChange}
          />
          <MultipleCombobox
            required
            limit={1}
            options={topFilms}
            label='Required'
            style={{ width: 290 }}
            value={multiCombo}
            onChange={this.handleMultiComboboxChange}
          />
          <MultipleCombobox
            disabled
            limit={1}
            options={topFilms}
            label='Disabled'
            style={{ width: 290 }}
            value={multiCombo}
            onChange={this.handleMultiComboboxChange}
          />
          <MultipleCombobox
            error
            limit={1}
            options={topFilms}
            label='Error'
            style={{ width: 290 }}
            value={multiCombo}
            onChange={this.handleMultiComboboxChange}
          />
        </Grid>
        <br />
        <Grid container justify='space-between'>
          <Button color='primary' variant='contained'>
            Primary Button
          </Button>
          <Button color='primary' variant='contained' size='small'>
            Primary Button
          </Button>
          <Button disabled color='primary' variant='contained'>
            Primary Button
          </Button>
          <Button disabled color='primary' variant='contained' size='small'>
            Primary Button
          </Button>
        </Grid>
        <br />
        <Grid container justify='space-between'>
          <Button color='secondary' variant='outlined'>
            Secondary Button
          </Button>
          <Button color='secondary' variant='outlined' size='small'>
            Secondary Button
          </Button>
          <Button disabled color='secondary' variant='outlined'>
            Secondary Button
          </Button>
          <Button disabled color='secondary' variant='outlined' size='small'>
            Secondary Button
          </Button>
        </Grid>
        <br /> <br />
        <Grid container justify='space-between'>
          <Chip label='CCB-China' />
          <Chip label='CCB-China' avatar={<Avatar>A</Avatar>} />
          <Chip
            label='CCB-China'
            avatar={<Avatar>A</Avatar>}
            onDelete={() => {
              console.log('deleted');
            }}
          />
          <Chip
            disabled
            label='CCB-China'
            avatar={<Avatar>A</Avatar>}
            onDelete={() => {
              console.log('deleted');
            }}
          />
        </Grid>
        <br />
        <Grid container justify='space-between'>
          <Checkbox
            name='favSports'
            label='Cricket'
            value='cricket'
            checked={favSports.includes('cricket')}
            onChange={this.handleCheckboxChange}
          />
          <br />
          <Checkbox
            disabled
            name='favSports'
            label='Football'
            value='football'
            checked={favSports.includes('football')}
            onChange={this.handleCheckboxChange}
          />
          <br />
          <Checkbox
            disabled
            name='favSports'
            label='Hockey'
            value='hockey'
            checked={favSports.includes('hockey')}
            onChange={this.handleCheckboxChange}
          />
        </Grid>
        <br />
        <Grid container justify='space-between'>
          <Radio
            name='gender'
            label='Male'
            value='male'
            checked={gender === 'male'}
            onChange={this.handleRadioChange}
          />
          <Radio
            name='gender'
            label='Female'
            value='female'
            checked={gender === 'female'}
            onChange={this.handleRadioChange}
          />
          <Radio
            disabled
            name='gender'
            label='Other'
            value='other'
            checked={gender === 'other'}
            onChange={this.handleRadioChange}
          />
        </Grid>
        <Grid container justify='space-between'>
          <Switch checked={checked} label='Hello' onChange={this.toggle} />
          <Switch
            checked={checked}
            label='Hello'
            labelPlacement='top'
            onChange={this.toggle}
          />
          <Switch
            checked={checked}
            label='Hello'
            labelPlacement='end'
            onChange={this.toggle}
          />
          <Switch
            checked={checked}
            label='Hello'
            labelPlacement='bottom'
            onChange={this.toggle}
          />
        </Grid>
        <br />
        <Grid container justify='space-between'>
          <Switch
            disabled
            checked={checked}
            label='Hello'
            onChange={this.toggle}
          />
          <Switch
            disabled
            checked={checked}
            label='Hello'
            labelPlacement='top'
            onChange={this.toggle}
          />
          <Switch
            disabled
            checked={checked}
            label='Hello'
            labelPlacement='end'
            onChange={this.toggle}
          />
          <Switch
            disabled
            checked={checked}
            label='Hello'
            labelPlacement='bottom'
            onChange={this.toggle}
          />
        </Grid>
      </div>
    );
  }
}

export default Inputs;
