/* istanbul ignore file */
export default {
  lastModified: 'Last Modified',
  reject: 'Reject',
  approve: 'Approve',
  personalData: 'Personal Data',
  businessName: 'Business Name',
  userId: 'User ID',
  salutation: 'Salutation',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  emailId: 'Email ID',
  jobTitle: 'Job Title',
  applicationRole: 'Application Role',
  organizationRoles: 'Organizational Roles',
  role: 'Role',
  grantableOrganizations: 'Grantable Organizations for the Selected Role',
  maxImageSize: '(Max Image Size: 5mb and Allowed formats: .jpg, .jpeg, .png)'
};
