import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getTotalOrganizationNames,
  getTotalUserRoles
} from '../../../store/global/actions';
import Success from './Success';
import RegistrationForm from './RegistrationForm';

export class Registration extends Component {
  state = {
    page: 1
  };

  componentDidMount() {
    document.title = 'GlobalNet | Register';
    localStorage.clear();
    this.props.getTotalOrganizationNames();
    this.props.getTotalUserRoles();
  }

  handleNext = () => {
    this.setState(prevState => ({
      page: prevState.page + 1
    }));
  };

  handlePrevious = () => {
    this.setState(prevState => ({
      page: prevState.page - 1
    }));
  };

  register = user => {
    this.setState({ user, page: 3 });
  };

  render() {
    const { page, user } = this.state;
    const { orgs, totalUserRoles } = this.props;
    if (page === 3) {
      return (
        <div className='registration w-100'>
          <Success firstName={user.firstName} lastName={user.lastName} />
        </div>
      );
    }
    return (
      <div className='registration w-100'>
        <RegistrationForm
          page={page}
          orgs={orgs}
          totalUserRoles={totalUserRoles}
          register={this.register}
          onNext={this.handleNext}
          onPrevious={this.handlePrevious}
        />
      </div>
    );
  }
}
const mapDispatchToProps = {
  getTotalOrganizationNames,
  getTotalUserRoles
};
export const mapStateToProps = state => ({
  orgs: state.global.totalOrganizationalNames,
  totalUserRoles: state.global.totalUserRoles
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
