import React from 'react';
import { Grid } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import './LandingPage.css';

import FormContainer from './FormContainer';
import WelcomeBanner from './WelcomeBanner';

const LandingPage = props => {
  const { isUserAuthenticated } = props;
  if (isUserAuthenticated) {
    return <Redirect to='/home' />;
  }
  return (
    <Grid
      container
      className='landing'
      data-test='component-landing'
      role='main'>
      <Grid item xl={8} lg={8} md={6} className='h-100'>
        <WelcomeBanner data-test='component-welcome-banner' />
      </Grid>
      <Grid item sm md lg xl className='h-100'>
        <FormContainer data-test='component-form-container' />
      </Grid>
    </Grid>
  );
};

LandingPage.propTypes = {
  isUserAuthenticated: PropTypes.bool.isRequired
};

export default LandingPage;
