import {
  SET_FORMS_LIST_IN_MENU,
  SET_UPLOADFORMS_LIST_IN_MENU,
  SAVE_ORG_NAME_AND_ID,
  GET_INTES_DROPDOWN_DATA,
  GET_COUNTRIES_DROPDOWN_DATA,
  GET_TYPE_OF_FRAUD_DROPDOWN_DATA,
  GET_ROYALTY_DROPDOWN_DATA,
  RESET_FORMS_LIST_IN_MENU,
  SET_FORM_TYPE_IN_MENU,
  SAVE_SELECTED_FORM
} from './types';
import {
  formsListApi,
  getIntesDataApi,
  countriesApi,
  typeOfFraudApi,
  getRoyaltyDropDown
} from '../../api/dataForms';
import { slugToIDs } from '../../constants/dataForms';
import { catchError, resetFormErrors } from '../error/actions';

export const setFormsListInMenu = data => async (dispatch, getState) => {
  const isFormErrorExists = getState().errors.formErrors.errorMessage;
  if (isFormErrorExists) {
    dispatch(resetFormErrors());
  }
  try {
    if (data) {
      localStorage.setItem('orgReportingPeriod', JSON.stringify(data));
      localStorage.removeItem('activeTab');
    } else {
      data = localStorage.getItem('orgReportingPeriod')
        ? JSON.parse(localStorage.getItem('orgReportingPeriod'))
        : {};
      const orgName = localStorage.getItem('orgName')
        ? localStorage.getItem('orgName')
        : '';
      data = { ...data, orgName };
      if (localStorage.getItem('formType')) {
        dispatch(setFormType(localStorage.getItem('formType')));
        // LocalStorage.removeItem('formType');
      }
      localStorage.setItem('isRefresh', true);
    }
    data = {
      ...data,
      formType: localStorage.getItem('formType')
        ? localStorage.getItem('formType')
        : ''
    };
    const response = await formsListApi(data);
    const { formType, allFormTypes } = getState().formsListNav;
    const allDataForms = {
      monthlyDataForms: [],
      quarterlyDataForms: [],
      annuallyDataForms: []
    };
    const payload = {};
    allFormTypes.forEach(type => {
      response[type].forEach(res => {
        allDataForms[type].push({
          ...res,
          formId: slugToIDs[res.formId]
        });
      });
    });
    data = { ...data, formType };
    payload.allDataForms = allDataForms;
    payload.orgReportingPeriod = { ...data };
    if (payload.allDataForms.monthlyDataForms.length > 0) {
      const isSummaryFormsExists = payload.allDataForms.monthlyDataForms.some(
        form => form.formName.includes('Summary')
      );
      if (isSummaryFormsExists) {
        const formsWithoutSummary = payload.allDataForms.monthlyDataForms.filter(
          form => !form.formName.includes('Summary')
        );
        payload.uploadedForm = formsWithoutSummary;
      } else {
        payload.uploadedForm = payload.allDataForms.monthlyDataForms;
      }
    }
    if (
      payload.allDataForms.quarterlyDataForms.length === 0 &&
      formType === allFormTypes[1]
    ) {
      dispatch(setFormType(allFormTypes[0]));
    }
    if (
      payload.allDataForms.annuallyDataForms.length === 0 &&
      formType === allFormTypes[1]
    ) {
      dispatch(setFormType(allFormTypes[0]));
    }
    dispatch({
      type: SET_FORMS_LIST_IN_MENU,
      payload
    });
  } catch (e) {
    const forms = getState().formsListNav.forms;
    if (forms.length > 0) {
      dispatch({
        type: RESET_FORMS_LIST_IN_MENU
      });
    }
    dispatch(catchError(e, 'FORM'));
  }
};

export const saveOrgNameAndId = (orgName, orgId) => async dispatch => {
  if (orgName) {
    localStorage.setItem('orgName', orgName);
  }
  if (orgId) {
    localStorage.setItem('orgId', orgId);
  }
  dispatch({
    type: SAVE_ORG_NAME_AND_ID,
    payload: { orgName, orgId }
  });
};
export const saveSelectedForm = selectedOrgReportedPeriod => async dispatch => {
  if (selectedOrgReportedPeriod) {
    localStorage.setItem(
      'selectedOrgReportedPeriod',
      selectedOrgReportedPeriod
    );
  }
  dispatch({
    type: SAVE_SELECTED_FORM,
    payload: selectedOrgReportedPeriod
  });
};

export const getRoyaltyDropDownData = () => async dispatch => {
  const data = localStorage.getItem('orgReportingPeriod')
    ? JSON.parse(localStorage.getItem('orgReportingPeriod'))
    : {};
  try {
    const royaltyDescDropDown = await getRoyaltyDropDown(data.orgProfileId);
    if (royaltyDescDropDown) {
      dispatch({
        type: GET_ROYALTY_DROPDOWN_DATA,
        payload: royaltyDescDropDown
      });
    }
  } catch (err) {}
};

export const setTempFormsListInMenu = data => async dispatch => {
  if (data.forms.length > 0) {
    const isSummaryFormsExists = data.forms.some(form =>
      form.formName.includes('Summary')
    );
    if (isSummaryFormsExists) {
      const formsWithoutSummary = data.forms.filter(
        form => !form.formName.includes('Summary')
      );
      data.uploadedForm = formsWithoutSummary;
    } else {
      data.uploadedForm = data.forms;
    }
  }
  dispatch({
    type: SET_FORMS_LIST_IN_MENU,
    payload: data
  });
};
export const setUploadFormsListInMenu = data => dispatch => {
  dispatch({
    type: SET_UPLOADFORMS_LIST_IN_MENU,
    payload: data
  });
};

export const getIntesData = () => async dispatch => {
  try {
    const intesData = await getIntesDataApi();
    if (intesData) {
      dispatch({
        type: GET_INTES_DROPDOWN_DATA,
        payload: intesData
      });
    }
  } catch (err) {}
};

export const getCountriesData = () => async dispatch => {
  try {
    const countriesData = await countriesApi();
    if (countriesData) {
      dispatch({
        type: GET_COUNTRIES_DROPDOWN_DATA,
        payload: countriesData
      });
    }
  } catch (err) {}
};

export const getTypeOfFraudData = () => async dispatch => {
  try {
    const typeOfFraudData = await typeOfFraudApi();
    if (typeOfFraudData) {
      dispatch({
        type: GET_TYPE_OF_FRAUD_DROPDOWN_DATA,
        payload: typeOfFraudData
      });
    }
  } catch (err) {}
};

export const setFormType = formType => ({
  type: SET_FORM_TYPE_IN_MENU,
  payload: formType
});
