import * as Yup from 'yup';
import rules from '../../fieldRules';

const {
  organizationName,
  orgLegalName,
  headquarterCountry,
  organizationProfileName,
  iicCode,
  dxsCode,
  region,
  currency,
  operatingCountry,
  entityType
} = rules;

export const organizationSchema = Yup.object().shape({
  organizationName,
  orgLegalName,
  headquarterCountry
});
export const childOrganizationSchema = ({ orgProfiles }) =>
  Yup.object().shape({
    organizationProfileName: organizationProfileName.notOneOf(
      orgProfiles.map(profile => profile.organizationProfileName),
      'Organizational Name already registered. Please enter a different Organization Name.'
    ),
    region,
    currency,
    operatingCountry,
    entityType,
    iicCode,
    dxsCode
  });
export const childOrganizationEditSchema = ({ org, orgProfiles }) =>
  Yup.object().shape({
    organizationProfileName: organizationProfileName.notOneOf(
      orgProfiles
        .filter(
          profile => profile.organizationProfileId !== org.organizationProfileId
        )
        .map(profile => profile.organizationProfileName),
      'Organizational Name already registered. Please enter a different Organization Name.'
    ),
    currency,
    entityType
  });
