import React, { Component } from 'react';
import { isEqual } from 'lodash';
import ErrorTooltip from '../../../components/formik/ErrorTooltip';
import WarningTooltip from '../../../components/formik/WarningTooltip';
import { TextField, InputAdornment } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  numberFormatRegex,
  removeCommasRegex,
  integerLength10Regex,
  initialSpacesRegex
} from '../../../utils/regexes';
import {
  updateRoyaltyForm,
  updateRoyaltyFieldValue,
  updateRoyaltyFormField
} from '../../../store/dataForms/actions';
import TEXT from '../../../constants/text/common';

let prevValue = null;

export class RoyaltyTableInput extends Component {
  componentDidMount() {
    prevValue = null;
  }

  shouldComponentUpdate(nextProps) {
    if (isEqual(this.props.field, nextProps.field)) {
      return false;
    }
    return true;
  }

  onChange = e => {
    let value = e.target.value;
    const { index, sectionIndex, name, updateRoyaltyFieldValue } = this.props;
    if (name === 'value') {
      if (value) {
        value = value.replace(removeCommasRegex, '');
      }
      if (integerLength10Regex.test(value)) {
        updateRoyaltyFieldValue(value, name, index, sectionIndex);
      }
    } else if (name === 'othDescription') {
      updateRoyaltyFieldValue(value, name, index, sectionIndex);
    }
  };

  onBlur = e => {
    const {
      form,
      field,
      index,
      sectionIndex,
      updateRoyaltyFieldValue,
      updateRoyaltyFormField,
      total,
      name
    } = this.props;
    let error = '';
    let value = e.target.value;
    if (value === '-') {
      error = 'Invalid Value';
      updateRoyaltyFieldValue(value, name, index, sectionIndex, error);
      return;
    }
    if (prevValue === e.target.value) {
      return;
    }
    if (name === 'othDescription') {
      if (initialSpacesRegex.test(value)) {
        updateRoyaltyFieldValue(
          value,
          name,
          index,
          sectionIndex,
          TEXT.royaltyOthDescRequiredErrorMsg
        );
        return;
      }
    }
    if (name === 'value') {
      if (value) {
        value = value.replace(removeCommasRegex, '');
      }
      if (prevValue) {
        prevValue = prevValue.replace(removeCommasRegex, '');
      }
    }
    const body = {
      ...form,
      sections: [
        {
          sectionIndex,
          total,
          fieldDetails: [
            {
              ...field,
              prevValue,
              [name]: value,
              operationCode: 'U'
            }
          ]
        }
      ]
    };
    updateRoyaltyFormField(body, name, index, sectionIndex);
  };

  valuesWithComma(value) {
    let updatedValue = '';
    if (value) {
      updatedValue = value.toString().replace(numberFormatRegex, ',');
    }
    return updatedValue;
  }

  saveInitialValue = e => {
    prevValue = e.target.value;
  };

  render() {
    const { field, name, form } = this.props;
    const isDescDisable =
      name === 'othDescription' && field.orgCntrctExclTypId !== 'OTH';
    const textAlignment = name === 'othDescription' ? 'left' : 'right';
    const maxLength = name === 'othDescription' ? 50 : 25;
    if (!field) {
      return null;
    }
    let endAdornment = '';
    if (name === 'value') {
      if (field.errorvalue) {
        endAdornment = <ErrorTooltip message={field.errorvalue} />;
      } else if (field.warning) {
        endAdornment = <WarningTooltip message={field.warning} />;
      }
    } else if (name === 'othDescription') {
      if (field.orgCntrctExclTypId === 'OTH' && field.othDescription === '') {
        endAdornment = (
          <ErrorTooltip message={TEXT.royaltyOthDescRequiredErrorMsg} />
        );
      } else if (field.errorothDescription) {
        endAdornment = <ErrorTooltip message={field.errorothDescription} />;
      }
    }

    return form.editable ? (
      <TextField
        fullWidth
        data-test='component-RoyaltyTableInput'
        error={
          (((name === 'value' && field.errorvalue) ||
            (name === 'othDescription' &&
              field.orgCntrctExclTypId === 'OTH' &&
              field.othDescription === '') ||
            (name === 'othDescription' && field.errorothDescription)) &&
            true) ||
          false
        }
        value={
          (name === 'othDescription' && field.othDescription) ||
          (name === 'value' && this.valuesWithComma(field.value)) ||
          ''
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' style={{ marginRight: 7 }}>
              {endAdornment}
            </InputAdornment>
          )
        }}
        inputProps={{
          maxLength,
          style: { textAlign: textAlignment, color: '#022a48' }
        }}
        disabled={isDescDisable}
        onChange={this.onChange}
        onFocus={this.saveInitialValue}
        onBlur={this.onBlur}
      />
    ) : (
      <div
        className={`MuiTypography-root intes-table-form-fields MuiTypography-body1 ${
          name === 'othDescription' ? 'royality-addition-tablebody-actions' : ''
        }`}
        data-test='royalty-readonly-field'>
        {(name === 'othDescription' && field.othDescription) ||
          (name === 'value' && this.valuesWithComma(field.value)) ||
          ''}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  form: {
    formId: state.dataForms.form?.formId || '',
    status: state.dataForms.form?.status || '',
    orgProfileId: state.dataForms.form?.orgProfileId || '',
    reportingMonth: state.dataForms.form?.reportingMonth || '',
    reportingYear: state.dataForms.form?.reportingYear || '',
    editable: state.dataForms.form?.formEditable || false
  }
});

const mapDispatchToProps = {
  updateRoyaltyForm,
  updateRoyaltyFormField,
  updateRoyaltyFieldValue
};

export default connect(mapStateToProps, mapDispatchToProps)(RoyaltyTableInput);
