import { base as theme } from './base';

export const chip = {
  MuiChip: {
    root: {
      height: 30,
      fontSize: 15,
      padding: 0,
      paddingRight: 10,
      backgroundColor: `${theme.palette.chip.background}`,
      borderColor: theme.palette.chip.border,
      '&:focus': {
        backgroundColor: theme.palette.chip.background,
        borderColor: theme.palette.chip.border
      }
    },

    deletable: {
      '&:focus': {
        backgroundColor: theme.palette.chip.background,
        borderColor: theme.palette.chip.border
      }
    },

    deleteIcon: {
      height: 11,
      width: 11,
      margin: 0,
      padding: '1px 1px 1px 10px'
    },
    label: {
      color: theme.palette.secondary.main,
      paddingLeft: 10,
      paddingRight: 0
    },
    avatar: {
      backgroundColor: `${theme.palette.success.main} !important`,
      color: `${theme.palette.white} !important`,
      height: '18px !important',
      width: '18px !important',
      fontSize: 13
    }
  }
};
