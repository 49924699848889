import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import ProfileHead from './ProfileHead';
import PersonalDetails from './PersonalDetails';
import {
  updateUserProfile,
  setUpdateUserProfileValue,
  fetchUserProfile
} from '../../store/user';
import { userDetailsSchema } from '../../constants/validations/admin/user';
import { isUserExistsApi } from '../../api/auth';
import { createStatusFromErrors } from '../../utils/commonFunctions';
import { toastFunc } from '../../components/ToastComponent/toastFunction';

export class FormContainer extends Component {
  componentDidUpdate() {
    this.props.setUpdateUserProfileValue(this.props.isProfileUpdated);
    if (this.props.isProfileUpdated === true) {
      try {
        this.props.fetchUserProfile(this.props.user.usrProfId);
      } catch (e) {}
    }
  }

  handleBlur = e => {
    const { handleBlur, status, setStatus, values } = this.props;

    e.persist();
    if (e.target.name === 'userLoginId' && values.userLoginId !== '') {
      const body = {
        userLoginId: values.userLoginId
      };
      isUserExistsApi(body)
        .then(res => {
          if (!res) {
            setStatus({
              ...status,
              userLoginId: 'User ID exists. Please enter a different User ID.'
            });
          }
        })
        .catch(err => {
          setStatus(createStatusFromErrors(err));
        });
    }
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };

  render() {
    const {
      errors,
      touched,
      values,
      status,
      handleChange,
      handleSubmit,
      edit,
      dirty,
      isValid,
      user,
      impersonate
    } = this.props;
    return (
      <form
        noValidate
        aria-label='my profile form'
        data-test='my-profile-form'
        onSubmit={handleSubmit}>
        <ProfileHead
          user={user}
          handleReset={this.props.resetForm}
          formDirty={dirty}
          formValid={isValid}
          hasButton={edit}
        />
        <PersonalDetails
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          edit={edit}
          data-test='personal-details-component'
          status={status}
          handleBlur={this.handleBlur}
          impersonate={impersonate}
        />
      </form>
    );
  }
}

FormContainer.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setUpdateUserProfileValue: PropTypes.func.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  resetForm: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
};
const formConfig = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ form, user }) => {
    if (Object.keys(user).length === 0) {
      return {
        ...form
      };
    }
    return {
      userLoginId: user.userLoginId ? user.userLoginId : '',
      firstName: user.firstName ? user.firstName : '',
      middleName: user.middleName ? user.middleName : '',
      lastName: user.lastName ? user.lastName : '',
      emailId: user.emailId ? user.emailId : '',
      jobTitle: user.jobTitle ? user.jobTitle : '',
      salutation: user.prefix ? user.prefix : 'none'
    };
  },
  mapPropsToStatus: () => ({}),
  validationSchema: userDetailsSchema,
  handleSubmit: (values, formikBag) => {
    const rbody = {
      usrProfileId: formikBag.props.user.usrProfId,
      usrLoginId: values.userLoginId,
      prefix: values.salutation,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      jobTitle: values.jobTitle,
      emailId: values.emailId,
      intUserInd: values.internalUserFlag ? 'Y' : 'N'
    };

    return formikBag.props.updateUserProfile(rbody).then(res => {
      if (res.data === true) {
        toastFunc({
          content: 'Profile has been Updated Successfully',
          config: { className: 'toast-container accept' }
        });
      } else {
        const err = {};
        err.response = res;
        formikBag.setStatus(createStatusFromErrors(err));
      }
    });
  }
});
export const mapStateToProps = state => {
  return {
    isProfileUpdated: state.user.isProfileUpdated
  };
};
const mapDispatchToProps = {
  setUpdateUserProfileValue,
  updateUserProfile,
  fetchUserProfile
};
export const FormContainerWithFormik = formConfig(FormContainer);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormContainerWithFormik);
