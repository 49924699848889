/* eslint-disable max-lines-per-function */
import {
  GET_PENDING_APPROVAL_USERS,
  GET_SINGLE_PENDING_APPROVAL_USER,
  GET_ADMIN_ORGANIZATION_NAMES,
  CREATE_USER,
  GET_USER_PROFILE,
  GET_USER_PAGE_INFO,
  GET_SEARCHED_USER_PROFILE,
  ORG_ROLE_PROFILES_FOR_ADD,
  GET_ALL_ANNOUNCEMENTS,
  GET_MEGA_MENU_ORGANIZATION_NAMES,
  GET_ORG_PARAMS,
  GET_AUDIT_REPORT,
  GET_EXCHANGE_RATES,
  CHECK_EXCHANGE_RATES,
  SEARCHED_RATES_FORM,
  CLEAR_EXCHANGE_RATES
} from './types';

import {
  pendingApprovalUsersApi,
  getUserProfileSearchApi,
  getSingleUserApi,
  getAdminOrganizationNamesApi,
  createUserApi,
  updateUserApi,
  manageOrgnizationDataApi,
  generateTempararyPasswordApi,
  getAllAnnouncementsApi,
  getMegaMenuOrganizationNamesApi,
  getOrgParamsApi,
  getUserProfileLogsApi,
  getAuditReportApi,
  getExchangeRatesApi,
  checkExchangeRatesApi,
  loadExchangeRatesApi
} from '../../api/admin';

export const orgRoleProfilesRearrange = (orgRoleProfiles, roleType) => {
  const response = orgRoleProfiles.map(orgRoleProfile => {
    const organizationProfiles = orgRoleProfile.organizationProfileVO.map(
      organizationProfile => {
        return {
          organizationProfileId: organizationProfile.organizationProfileId,
          organizationProfileName: organizationProfile.organizationProfileName,
          status: organizationProfile.status,
          localUserAdmin: false
        };
      }
    );

    return {
      roleTypeCode: orgRoleProfile.organizationRoleVO.roleTypeCode,
      roleTypeName: orgRoleProfile.organizationRoleVO.roleTypeName,
      organizationProfiles
    };
  });
  const responseWithoutOA = response.filter(
    orgRoleProfile => orgRoleProfile.roleTypeCode !== 'OA'
  );

  const responseOnlyOA = response.find(
    orgRoleProfile => orgRoleProfile && orgRoleProfile.roleTypeCode === 'OA'
  );

  responseWithoutOA.forEach(data1 => {
    data1.organizationProfiles.forEach(data2 => {
      let status = false;
      if (responseOnlyOA) {
        status = responseOnlyOA.organizationProfiles.some(oaProf => {
          return (
            oaProf.organizationProfileId === data2.organizationProfileId &&
            oaProf.status === 'A'
          );
        });
      }
      data2.localUserAdmin = status;
    });
  });
  const responseWithOA = responseWithoutOA;
  if (responseOnlyOA) {
    responseWithOA.push(responseOnlyOA);
    return responseWithOA;
  }
  return responseWithoutOA;
};

export const getAdminOrganizationNames = () => async dispatch => {
  try {
    let payload = await getAdminOrganizationNamesApi();
    if (payload !== undefined && payload !== null) {
      payload = payload.map(
        ({ organizationProfileId, organizationProfileName }) => ({
          id: organizationProfileId,
          label: organizationProfileName,
          value: organizationProfileId
        })
      );
    } else {
      payload = [];
    }
    dispatch({
      type: GET_ADMIN_ORGANIZATION_NAMES,
      payload
    });
  } catch (err) {}
};

export const getPendingApprovalUsers = () => async dispatch => {
  try {
    const payload = await pendingApprovalUsersApi();
    dispatch({
      type: GET_PENDING_APPROVAL_USERS,
      payload
    });
  } catch (err) {}
};

export const selectPendingUser = id => async (dispatch, getState) => {
  const roleType = getState().user.highestRole;
  try {
    let payload = await getSingleUserApi(id);
    let isLoggedInUser = false;
    if (Number(id) === getState().user.usrProfId) {
      isLoggedInUser = true;
    }

    payload = {
      ...payload,
      isLoggedInUser,
      orgRoleProfilesWithoutModify: payload.orgRoleProfiles,
      orgRoleProfiles: orgRoleProfilesRearrange(
        payload.orgRoleProfiles,
        roleType
      )
    };

    dispatch({
      type: GET_SINGLE_PENDING_APPROVAL_USER,
      payload
    });
  } catch (err) {}
};

export const addOrganizationData = orgData => async (dispatch, getState) => {
  try {
    const {
      orgAdminRoles,
      otherRoles,
      status,
      userProfileId,
      pageType
    } = orgData;

    const organizationProfileVO = [];
    otherRoles.profileData.forEach(data => {
      organizationProfileVO.push({
        organizationProfileId: data.value,
        organizationProfileName: data.label,
        rejectedReason: '',
        status
      });
    });

    let adminOrgFinalData;

    if (orgAdminRoles.profileData.length > 0) {
      const organizationProfileVOAdmin = [];
      orgAdminRoles.profileData.forEach(data => {
        organizationProfileVOAdmin.push({
          organizationProfileId: data.value,
          organizationProfileName: data.label,
          rejectedReason: '',
          status
        });
      });
      adminOrgFinalData = {
        organizationProfileVO: organizationProfileVOAdmin,
        organizationRoleVO: {
          roleTypeCode: orgAdminRoles.roleData.value,
          roleTypeName: orgAdminRoles.roleData.label
        }
      };
    }

    const data = {
      userProfileId,
      orgRoleProfiles: [
        {
          organizationProfileVO,
          organizationRoleVO: {
            roleTypeCode: otherRoles.roleData.value,
            roleTypeName: otherRoles.roleData.label
          }
        }
      ]
    };
    if (adminOrgFinalData !== undefined) {
      data.orgRoleProfiles.push(adminOrgFinalData);
    }

    if (pageType !== 'add') {
      const payload = await manageOrgnizationDataApi(data);
      if (payload) {
        const payload = await pendingApprovalUsersApi();
        dispatch({
          type: GET_PENDING_APPROVAL_USERS,
          payload
        });
        let singlePayload = await getSingleUserApi(data.userProfileId);

        singlePayload = {
          ...singlePayload,
          orgRoleProfiles: orgRoleProfilesRearrange(
            singlePayload.orgRoleProfiles
          )
        };

        dispatch({
          type: GET_SINGLE_PENDING_APPROVAL_USER,
          payload: singlePayload
        });
      }
    }

    if (pageType === 'add') {
      const oldData = getState().admin.orgRoleProfilesForSave;
      const r = [...oldData, ...data.orgRoleProfiles];

      const y = [];

      r.forEach(obj => {
        y.push({ organizationRoleVO: obj.organizationRoleVO });
      });

      const jsonObject = y.map(JSON.stringify);
      const uniqueSet = new Set(jsonObject);
      const uniqueArray = Array.from(uniqueSet).map(JSON.parse);

      uniqueArray.forEach(obj => {
        const k = [];
        r.filter(
          a =>
            a.organizationRoleVO.roleTypeCode ===
            obj.organizationRoleVO.roleTypeCode
        ).forEach(x => {
          k.push(...x.organizationProfileVO);
        });
        const u = new Set(k.map(JSON.stringify));
        const organizationProfileVO = Array.from(u).map(JSON.parse);
        obj.organizationProfileVO = organizationProfileVO;
      });

      dispatch({
        type: ORG_ROLE_PROFILES_FOR_ADD,
        payload: uniqueArray
      });
    }
  } catch (err) {}
};

export const modifyOrganizationData = data => (dispatch, getState) => {
  const orgRoleProfiles = getState().admin.orgRoleProfilesForSave;
  const { status, organizationProfileId, roleTypeCode } = data;
  let newOrgRoleProfiles;
  if (status === 'A') {
    const organizationRoleVO = {
      roleTypeCode
    };
    const organizationProfileVO = {
      organizationProfileId,
      organizationProfileName: '',
      rejectedReason: '',
      status
    };
    const luaRoleExists = orgRoleProfiles.some(
      ({ organizationRoleVO: { roleTypeCode } }) => {
        return roleTypeCode === 'OA';
      }
    );
    if (luaRoleExists) {
      newOrgRoleProfiles = orgRoleProfiles.map(orgRoleProfile => {
        if (orgRoleProfile.organizationRoleVO.roleTypeCode === 'OA') {
          return {
            ...orgRoleProfile,
            organizationProfileVO: [
              ...orgRoleProfile.organizationProfileVO,
              organizationProfileVO
            ]
          };
        }
        return orgRoleProfile;
      });
    } else {
      newOrgRoleProfiles = [
        ...orgRoleProfiles,
        {
          organizationRoleVO,
          organizationProfileVO: [organizationProfileVO]
        }
      ];
    }
  } else {
    newOrgRoleProfiles = orgRoleProfiles
      .map(orgRoleProfile => {
        if (orgRoleProfile.organizationRoleVO.roleTypeCode === 'OA') {
          return {
            ...orgRoleProfile,
            organizationProfileVO: orgRoleProfile.organizationProfileVO.filter(
              org => org.organizationProfileId !== organizationProfileId
            )
          };
        }
        return orgRoleProfile;
      })
      .filter(
        ({ organizationProfileVO }) => organizationProfileVO.length !== 0
      );
  }
  dispatch({
    type: ORG_ROLE_PROFILES_FOR_ADD,
    payload: newOrgRoleProfiles
  });
};

export const deleteOrganizationData = id => (dispatch, getState) => {
  const orgRoleProfiles = getState().admin.orgRoleProfilesForSave;
  const newOrgRoleProfiles = orgRoleProfiles
    .map(orgRoleProfile => {
      const organizationProfileVO = orgRoleProfile.organizationProfileVO.filter(
        org => org.organizationProfileId !== id
      );
      return {
        ...orgRoleProfile,
        organizationProfileVO
      };
    })
    .filter(({ organizationProfileVO }) => organizationProfileVO.length !== 0);
  dispatch({
    type: ORG_ROLE_PROFILES_FOR_ADD,
    payload: newOrgRoleProfiles
  });
};

export const manageOrgnizationData = data => async (dispatch, getState) => {
  try {
    const payload = await manageOrgnizationDataApi(data);
    if (payload.status === 200) {
      const checkPARole = getState().user.userRoleMap.PA;
      if (!checkPARole) {
        const payload = await pendingApprovalUsersApi();
        dispatch({
          type: GET_PENDING_APPROVAL_USERS,
          payload
        });
      }

      let singlePayload = await getSingleUserApi(data.userProfileId);

      singlePayload = {
        ...singlePayload,
        orgRoleProfiles: orgRoleProfilesRearrange(singlePayload.orgRoleProfiles)
      };

      dispatch({
        type: GET_SINGLE_PENDING_APPROVAL_USER,
        payload: singlePayload
      });
    }
    return Promise.resolve(payload);
  } catch (err) {}
};

export const getUserProfileSearchResults = userInfo => async dispatch => {
  try {
    const response = await getUserProfileSearchApi(userInfo);
    const userProfilesWithKey = [];
    if (response !== undefined && response !== null) {
      response.searchUserVO.map(data => {
        const {
          userProfileId,
          organizationProfileId,
          ...userProfilesWithoutIds
        } = data;
        return userProfilesWithKey.push({
          key: data.userProfileId,
          ...userProfilesWithoutIds
        });
      });
    }
    dispatch({
      type: GET_USER_PROFILE,
      payload: userProfilesWithKey
    });
    dispatch({
      type: GET_USER_PAGE_INFO,
      payload: response.paginationInfo
    });
  } catch (err) {}
};
export const getUserProfileLogsResults = userInfo => async dispatch => {
  try {
    const response = await getUserProfileLogsApi(userInfo);
    const userProfilesWithKey = [];
    if (response !== undefined && response !== null) {
      response.map(data => {
        const {
          userProfileId,
          organizationProfileId,
          ...userProfilesWithoutIds
        } = data;
        return userProfilesWithKey.push({
          key: data.userProfileId,
          ...userProfilesWithoutIds
        });
      });
    }

    dispatch({
      type: GET_USER_PROFILE,
      payload: userProfilesWithKey
    });
  } catch (err) {
    return err.response;
  }
};

export const getSearchedUserProfile = userInfo => async dispatch => {
  try {
    dispatch({
      type: GET_SEARCHED_USER_PROFILE,
      payload: userInfo
    });
  } catch (err) {}
};

export const createUser = user => async (dispatch, getState) => {
  try {
    user.orgRoleProfiles = getState().admin.orgRoleProfilesForSave;

    const response = await createUserApi(user);
    if (response.status === 200) {
      dispatch({
        type: CREATE_USER,
        payload: response.data
      });
      dispatch({
        type: ORG_ROLE_PROFILES_FOR_ADD,
        payload: []
      });
      return Promise.resolve(response);
    }
    return Promise.resolve(response);
  } catch (err) {
    console.log(err.reponse); // eslint-disable-line no-console
  }
};

export const updateUser = user => async dispatch => {
  try {
    const response = await updateUserApi(user);
    if (response.data === true) {
      let payload = await getSingleUserApi(user.usrProfileId);

      payload = {
        ...payload,
        orgRoleProfiles: orgRoleProfilesRearrange(payload.orgRoleProfiles)
      };

      dispatch({
        type: GET_SINGLE_PENDING_APPROVAL_USER,
        payload
      });
    }
    return Promise.resolve(response);
  } catch (err) {
    console.log(err.response); // eslint-disable-line no-console
  }
};

export const generateTempararyPassword = user => () => {
  return new Promise((resolve, reject) => {
    generateTempararyPasswordApi(user)
      .then(response => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
};

export const clearSearchProfileList = () => async dispatch => {
  try {
    dispatch({
      type: GET_USER_PROFILE,
      payload: undefined
    });
  } catch (err) {}
};
export const clearLogsProfileList = () => async dispatch => {
  try {
    dispatch({
      type: GET_USER_PROFILE,
      payload: []
    });
  } catch (err) {}
};

export const getAllAnnouncements = () => async dispatch => {
  try {
    const response = await getAllAnnouncementsApi();
    dispatch({
      type: GET_ALL_ANNOUNCEMENTS,
      payload: response.data
    });
    return Promise.resolve(response);
  } catch (err) {}
};

export const clearOrganizationData = () => async dispatch => {
  dispatch({
    type: ORG_ROLE_PROFILES_FOR_ADD,
    payload: []
  });
};

export const getMegaMenuOrganizationNames = () => async dispatch => {
  try {
    let payload = await getMegaMenuOrganizationNamesApi();
    if (payload !== undefined && payload !== null) {
      payload = payload.map(
        ({ organizationProfileId, organizationProfileName }) => ({
          id: organizationProfileId,
          label: organizationProfileName,
          value: organizationProfileId
        })
      );
    } else {
      payload = [];
    }
    dispatch({
      type: GET_MEGA_MENU_ORGANIZATION_NAMES,
      payload
    });
  } catch (err) {}
};

export const getOrgParams = defaultOrgName => async dispatch => {
  try {
    const organizationName = await getOrgParamsApi(defaultOrgName);
    dispatch({
      type: GET_ORG_PARAMS,
      payload: organizationName
    });
  } catch (e) {
    console.log(e.message); // eslint-disable-line no-console
  }
};

export const getAuditReport = data => async dispatch => {
  try {
    const auditReport = await getAuditReportApi(data);
    dispatch({
      type: GET_AUDIT_REPORT,
      payload: auditReport
    });
    return auditReport;
  } catch (e) {
    return Promise.reject(e); // eslint-disable-line no-console
  }
};

export const getExchangeRates = data => async dispatch => {
  try {
    const exchangeRates = await getExchangeRatesApi(data);
    dispatch({
      type: GET_EXCHANGE_RATES,
      payload: exchangeRates.data
    });
    return exchangeRates;
  } catch (e) {
    return Promise.reject(e); // eslint-disable-line no-console
  }
};

export const loadExchangeRates = () => async dispatch => {
  try {
    const exchangeRates = await loadExchangeRatesApi();
    dispatch({
      type: GET_EXCHANGE_RATES,
      payload: exchangeRates.data
    });
    return exchangeRates;
  } catch (e) {
    return Promise.reject(e); // eslint-disable-line no-console
  }
};
export const clearloadExchangeRates = () => async dispatch => {
  dispatch({
    type: CLEAR_EXCHANGE_RATES
  });
};

export const setRatesFormData = data => async dispatch => {
  dispatch({
    type: SEARCHED_RATES_FORM,
    payload: data
  });
};

export const checkExchangeRatesLoaded = data => async dispatch => {
  try {
    const exchangeRates = await checkExchangeRatesApi(data);
    dispatch({
      type: CHECK_EXCHANGE_RATES,
      payload: exchangeRates.data
    });
    return exchangeRates;
  } catch (e) {
    return Promise.reject(e); // eslint-disable-line no-console
  }
};
