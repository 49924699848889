import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { getUserAdminAccesses } from '../../CanActivate/CanActivate';
import { Link } from 'react-router-dom';
import { CanActivate } from '../..';
import { COMMON_TEXT } from '../../../constants/text';
import { AdminAccessDetails } from './AdminAccessInfo';

const AdminMegaMenu = props => {
  const { role } = props;
  const categories = AdminAccessDetails.categories;
  const userAdminDetails = getUserAdminAccesses({ AdminAccessDetails, role });
  return (
    <Grid
      item
      md
      className='admin-menu-padding'
      data-test='component-business-admin-mega-menu'>
      <Grid container className='megamenu-section'>
        <Grid
          container
          justify='flex-start'
          className='admin-menu-container'
          data-test='admin-forms-container'
          wrap='wrap'
          spacing={4}>
          {categories.map((category, index) => {
            return (
              userAdminDetails[category].length > 0 && (
                <Grid key={index} item className='admin-menu-categories'>
                  {category !== '' && (
                    <Typography
                      variant='body2'
                      color='primary'
                      data-test='business-settings-heading'
                      className='admin-menu-header'>
                      {category}
                    </Typography>
                  )}
                  {userAdminDetails[category].map(category => (
                    <CanActivate
                      action={category.feature?.accessKey}
                      unAuthorizedView={null}>
                      <Typography
                        key={category.feature?.title}
                        variant='body2'
                        className='admin-menu-features'>
                        <Link
                          to={category.feature?.link}
                          data-test='business-profile-option'
                          onClick={() => props.onClose(COMMON_TEXT.admin)}>
                          {category.feature?.title}
                        </Link>
                      </Typography>
                    </CanActivate>
                  ))}
                </Grid>
              )
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminMegaMenu;
