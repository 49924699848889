import React from 'react';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-icon.svg';
import { TextFieldGrid } from '../../../components/formik';
import {
  maxAppDate,
  presentDate,
  minAppDate
} from '../../../constants/exchangeRates';
import {
  TableRow,
  TableCell,
  Button,
  Grid,
  InputAdornment
} from '@material-ui/core';
import ComboBox from '../../../components/material/Combobox';
import {
  Switch,
  DatePicker as FormikDatePicker,
  MultipleCombobox
} from '../../../components/material';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
const FormContainer = props => {
  const {
    values,
    handleChange,
    handleBlur,
    branding,
    isValid,
    dirty,
    handleCurrentChange,
    handleClearChange
  } = props;
  return (
    <TableRow data-test='exchange-rate-main-container'>
      <TableCell data-test='Form-Container-Table-combobox'>
        <ComboBox
          required
          id='orgProfId'
          data-test='org-search-combobox'
          name='orgProfId'
          className='buisness-search-textbox'
          label='Participant Name'
          options={props.adminsOrganizations}
          value={values.orgProfId}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell data-test='Form-Container-Table-multibox'>
        <MultipleCombobox
          required
          limit={1}
          id='formTypeCategory'
          options={branding}
          data-test='org-search-multibox'
          placeholder='Please Select'
          label='Select Category'
          name='formTypeCategory'
          value={values.formTypeCategory}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </TableCell>

      <TableCell data-test='Form-Container-Table-datePicker'>
        <Grid
          className='datepicker-container'
          data-test='datepicker-container-grid'>
          <FormikDatePicker
            autoOk
            variant='inline'
            inputVariant='outlined'
            format='dd/MM/yyyy'
            data-test='user-datepicker-container'
            required
            label='Select date'
            name='extensionExpiryDate'
            minDate={
              !values.extensionRequestIndicator ? presentDate : minAppDate
            }
            maxDate={values.endDate === '' ? maxAppDate : values.endDate}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <CalendarIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </TableCell>
      <TableCell data-test='Form-Container-Table-comment'>
        <TextFieldGrid
          required
          id='extensionRequestNote'
          data-test='extensionRequestNote-input'
          label='Comment'
          name='extensionRequestNote'
          value={values.extensionRequestNote}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell
        className='switch-toggle'
        data-test='extensionRequestNote-switch-toggle'>
        <Switch
          id='extensionRequestIndicator'
          name='extensionRequestIndicator'
          checked={values.extensionRequestIndicator}
          onChange={handleChange}
          value={values.extensionRequestIndicator}
          onBlur={handleBlur}
          data-test='extensionRequestNote-switch-toggle-input'
        />
      </TableCell>
      <TableCell
        className='reset-button-container'
        data-test='reset-button-container'>
        <Button
          onClick={handleCurrentChange}
          className='add-icon-button'
          disabled={
            !isValid || !dirty || !/\S/.test(values.extensionRequestNote)
          }
          data-test='Form-Container-Icon'>
          <AddIcon data-test='Icon' />
        </Button>
        <Button
          color='secondary'
          variant='outlined'
          type='reset'
          size='small'
          className='user-reset-btn'
          onClick={handleClearChange}
          data-test='clear-button'>
          Clear
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(FormContainer);
