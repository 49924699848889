import * as Yup from 'yup';
import errors from '../fieldErrors';
const { userLoginId, loginPassword } = errors;
export const loginValidationSchema = Yup.object().shape({
  userId: Yup.string()
    .required(userLoginId.empty)
    .label('User ID'),
  password: Yup.string()
    .required(loginPassword.empty)
    .label('Password')
});
