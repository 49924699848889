export const noHeaderPath = [
  '/',
  '/register',
  '/forgot-userid',
  '/forgot-password',
  '/change-password',
  '/userid-verification'
];

export const noMegaMenuPath = [...noHeaderPath, '/home', '/otp'];
