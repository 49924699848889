import actions from './types';
import {
  getFormApi,
  clearDataApi,
  crossCheckApi,
  updateDataFieldApi,
  updateIntesDataFormApi,
  updateIssuersFraudDataFormApi,
  updateRoyaltyFormApi,
  submitDataFormsApi,
  saveFormApi
} from '../../api/dataForms';
import {
  quarterlyFormsIDs,
  quarters,
  slugToIDs
} from '../../constants/dataForms';
import { catchError } from '../error/actions';
import TEXT from '../../constants/text/common';
import { forecast } from '../../constants/text/forecast';

export const uploadReviewUrl = path => {
  if (path.includes('review')) {
    return path.split('/')[4];
  }
  return path.split('/')[3];
};

export const setQuarterlyForm = form => {
  if (form && quarterlyFormsIDs.includes(parseInt(form.formId))) {
    let actualQuarterNumber;
    for (const quarter in quarters) {
      if (quarters[quarter].includes(form.reportingMonth)) {
        actualQuarterNumber = quarter[1];
        break;
      }
    }
    form = {
      ...form,
      tabs: form.tabs.map(tab => {
        const [tabNameWithQuarter] = tab.tabName.split(' ');
        const tabIdentifier = tab.tabIdentifier;
        if (quarters[tabNameWithQuarter]) {
          return {
            ...tab,
            actual: tabIdentifier[1] === actualQuarterNumber,
            submitted:
              tabIdentifier[1] < actualQuarterNumber ||
              (tabIdentifier[1] === actualQuarterNumber &&
                form.status === 'Submitted to DGN')
          };
        }
        if (tabIdentifier === 'T6') {
          return {
            ...tab,
            isTotal: true
          };
        }
        return tab;
      })
    };
  }
  return form;
};

export const setDataForm = (form, tempFormsListNav, tab) => ({
  type: actions.SET_DATA_FORM,
  payload: { form, tempFormsListNav },
  tab
});

export const setDataForms = (path, currentOrgYear = null) => async (
  dispatch,
  getState
) => {
  const slug = uploadReviewUrl(path);
  const id = Object.keys(slugToIDs).find(k => slugToIDs[k] === slug);
  let { orgReportingPeriod, formType } = getState().formsListNav;
  const tempFormsListNav = getState().formsListNav || {};
  if (!orgReportingPeriod) {
    orgReportingPeriod = localStorage.getItem('orgReportingPeriod')
      ? JSON.parse(localStorage.getItem('orgReportingPeriod'))
      : {};
  }
  let forecastSelectedYear;
  let currentForm;
  if (formType === 'annuallyDataForms') {
    const forecastOrgYear = parseInt(orgReportingPeriod.reportingYear) + 1;
    forecastSelectedYear =
      forecastOrgYear === currentOrgYear
        ? forecast.firstYear
        : forecast.secondYear;
    currentForm = tempFormsListNav.allDataForms.annuallyDataForms.find(
      form => form.formId === slug
    );
  }
  try {
    let form = await getFormApi(
      id,
      orgReportingPeriod,
      forecastSelectedYear,
      currentForm?.formName
    );
    form = setQuarterlyForm(form);
    if (slugToIDs[form.formId] === 'intes' && form.fieldDetails === null) {
      form.fieldDetails = [];
    }
    form = modifyForm(form, id, path.split('/')[2]);
    if (
      path.includes('review') &&
      form.tabs?.length &&
      form.tabs[form.tabs.length - 1].tabName === 'Summary'
    ) {
      form.tabs.unshift(form.tabs.pop());
    }
    const refresh = localStorage.getItem('isRefresh');
    let activeTab = 0;
    if (refresh) {
      activeTab = parseInt(localStorage.getItem('activeTab')) || 0;
      localStorage.removeItem('isRefresh');
    }
    dispatch(setDataForm(form, tempFormsListNav, activeTab));
  } catch (err) {
    dispatch(catchError(err, 'FORM'));
  }
};

export const updateFieldValue = (
  value,
  valIndex,
  sectionIndex,
  rowIndex,
  error
) => ({
  type: actions.UPDATE_FIELD,
  payload: {
    value,
    valIndex,
    sectionIndex,
    rowIndex,
    error
  }
});

export const updateIntesFieldValue = (value, name, index, error) => ({
  type: actions.UPDATE_INTES_FIELD,
  payload: {
    value,
    name,
    index,
    error
  }
});

export const changeTab = (index, path = null) => async dispatch => {
  if (path && path.includes('upload') && index === 0) {
    dispatch(setDataForms(path));
  }
  localStorage.setItem('activeTab', index);
  dispatch({
    type: actions.CHANGE_TAB,
    payload: index
  });
};

export const resetDataForms = () => ({
  type: actions.RESET_DATA_FORMS
});

export const clearDataForm = entryType => async (dispatch, getState) => {
  const dataforms = getState().dataForms;
  const tempFormsListNav = getState().formsListNav;
  const { form, activeTab } = dataforms;
  const { formId } = form;
  let formName = dataforms.form.formName;
  if (!formName) {
    formName = slugToIDs[formId];
  }
  let body;
  if (
    slugToIDs[formId] === 'intes' ||
    slugToIDs[formId] === 'issuer-fraud' ||
    slugToIDs[formId] === 'royality-additions-exclusions'
  ) {
    body = {
      formId,
      reportingMonth: form.reportingMonth,
      reportingYear: form.reportingYear,
      orgProfileId: form.orgProfileId
    };
  } else {
    body = {
      formId: form.formId,
      reportingMonth: form.reportingMonth,
      reportingYear: form.reportingYear,
      tabIdentifier: form.tabs[activeTab].tabIdentifier,
      orgProfileId: form.orgProfileId,
      formType: form.formType,
      formName: form.formName
    };
  }
  try {
    const res = await clearDataApi(body);
    if (res) {
      let updatedForm = await getFormApi(
        form.formId,
        tempFormsListNav.orgReportingPeriod,
        form.formType,
        form.formName
      );
      updatedForm = modifyForm(updatedForm, formId, entryType);
      updatedForm = setQuarterlyForm(updatedForm);
      dispatch(setDataForm(updatedForm, tempFormsListNav));
    }
  } catch (err) {}
};

export const updateDataField = body => async (dispatch, getState) => {
  try {
    const res = await updateDataFieldApi(body);
    if (!res) {
      return;
    }
    const oldTimeOut = getState().dataForms.message?.timeOutId;
    if (oldTimeOut) {
      clearTimeout(oldTimeOut);
    }
    const timeOutId = setTimeout(() => {
      dispatch({
        type: actions.SHOW_AUTO_SAVE,
        payload: { timeOutId: null, showAutoSave: false }
      });
    }, 5000);
    dispatch({
      type: actions.SHOW_AUTO_SAVE,
      payload: { timeOutId, showAutoSave: true }
    });
  } catch (err) {}
};

const modifyForm = (updatedForm, formId, path) => {
  if (
    Object.keys(updatedForm).length > 0 &&
    slugToIDs[formId] === 'se-sales-volume' &&
    path === 'manual'
  ) {
    updatedForm = {
      ...updatedForm,
      tabs: updatedForm.tabs.filter(tab => tab.tabName !== 'Summary')
    };
  }

  if (
    Object.keys(updatedForm).length > 0 &&
    slugToIDs[formId] === 'se-sales-volume-summary'
  ) {
    const tabCount = updatedForm.tabs.length;
    for (let i = 0; i < tabCount - 1; i++) {
      updatedForm.tabs.shift();
    }
    updatedForm = {
      ...updatedForm,
      tabs: updatedForm.tabs.map(tab => ({
        ...tab,
        tabName: null
      }))
    };
  }

  if (
    Object.keys(updatedForm).length > 0 &&
    slugToIDs[formId] === 'se-transactions' &&
    path === 'manual'
  ) {
    updatedForm = {
      ...updatedForm,
      tabs: updatedForm.tabs.filter(tab => tab.tabName !== 'Summary')
    };
  }

  if (
    Object.keys(updatedForm).length > 0 &&
    slugToIDs[formId] === 'se-transactions-summary'
  ) {
    const tabCount = updatedForm.tabs.length;
    for (let i = 0; i < tabCount - 1; i++) {
      updatedForm.tabs.shift();
    }
    updatedForm = {
      ...updatedForm,
      tabs: updatedForm.tabs.map(tab => ({
        ...tab,
        tabName: null
      }))
    };
  }
  if (
    ((Object.keys(updatedForm).length > 0 &&
      slugToIDs[formId] === 'card-member') ||
      (Object.keys(updatedForm).length > 0 &&
        slugToIDs[formId] === 'card-volume')) &&
    path === 'manual'
  ) {
    updatedForm = {
      ...updatedForm,
      tabs: updatedForm.tabs.filter(tab => tab.tabName !== 'Summary')
    };
  }

  if (
    Object.keys(updatedForm).length > 0 &&
    (slugToIDs[formId] === 'card-member-summary' ||
      slugToIDs[formId] === 'card-volume-summary')
  ) {
    const tabCount = updatedForm.tabs.length;
    for (let i = 0; i < tabCount - 1; i++) {
      updatedForm.tabs.shift();
    }
    updatedForm = {
      ...updatedForm,
      tabs: updatedForm.tabs.map(tab => ({
        ...tab,
        tabName: null
      }))
    };
  }

  if (
    Object.keys(updatedForm).length > 0 &&
    (slugToIDs[formId] === 'card-member' ||
      slugToIDs[formId] === 'se-sales-volume' ||
      slugToIDs[formId] === 'se-transactions' ||
      slugToIDs[formId] === 'card-volume') &&
    updatedForm.tabs[updatedForm.tabs.length - 1].tabName === 'Summary'
  ) {
    updatedForm.tabs.unshift(updatedForm.tabs.pop());
  }
  return updatedForm;
};

export const clearDataForms = () => ({});

export const updateIntesFormField = (data, index) => async (
  dispatch,
  getState
) => {
  try {
    const res = await updateIntesDataFormApi(data);
    if (res.status !== 200) {
      return;
    }
    const updatedData = res.data.fieldDetails.filter(
      field => field.intlEstabCde === data.fieldDetails[0].intlEstabCde
    )[0];
    const oldTimeOut = getState().dataForms.message?.timeOutId;
    if (oldTimeOut) {
      clearTimeout(oldTimeOut);
    }
    const timeOutId = setTimeout(() => {
      dispatch({
        type: actions.SHOW_AUTO_SAVE,
        payload: { timeOutId: null, showAutoSave: false }
      });
    }, 5000);
    dispatch({
      type: actions.SHOW_AUTO_SAVE,
      payload: { timeOutId, showAutoSave: true }
    });
    dispatch({
      type: actions.UPDATE_INTES_FIELD_FROM_API,
      payload: { updatedData, index }
    });
  } catch (e) {}
};

export const updateIntesForm = data => async (dispatch, getState) => {
  const fieldDetails = getState().dataForms.form.fieldDetails;
  const intesCodeExist = fieldDetails.filter(
    field => field.intlEstabCde === data.fieldDetails[0].intlEstabCde
  )[0];

  if (intesCodeExist) {
    const oldTimeOut = getState().dataForms.message?.timeOutId;
    if (oldTimeOut) {
      clearTimeout(oldTimeOut);
    }
    const timeOutId = setTimeout(() => {
      dispatch({
        type: actions.SHOW_CODE_EXISTS,
        payload: { timeOutId: null, showCodeExists: false }
      });
    }, 8000);
    dispatch({
      type: actions.SHOW_CODE_EXISTS,
      payload: { timeOutId, showCodeExists: true }
    });
    return;
  }

  try {
    const res = await updateIntesDataFormApi(data);
    if (res.status !== 200) {
      return;
    }
    const payload = {
      updatedFields: res.data.fieldDetails.filter(
        field => field.intlEstabCde === data.fieldDetails[0].intlEstabCde
      )[0],
      total: res.data.total
    };
    dispatch({
      type: actions.UPDATE_INTES_DATA,
      payload
    });
  } catch (e) {}
};

export const deleteIntesFormRow = (index, data) => async dispatch => {
  try {
    const res = await updateIntesDataFormApi(data);
    if (res.status !== 200) {
      return;
    }
    const payload = {
      index: index,
      total: res.data.total
    };
    dispatch({
      type: actions.DELETE_INTES_FORM_ROW,
      payload
    });
  } catch (e) {}
};

export const updateIssuersFraudForm = data => async (dispatch, getState) => {
  const tableData = getState().dataForms.form.tableData;
  const formTableData = data.tableData[0];
  let dataExist = false;
  let descExist = false;
  tableData.forEach(field => {
    if (
      formTableData.countryName === field.countryName &&
      formTableData.typeOfFraudName === field.typeOfFraudName
    ) {
      if (formTableData.typeOfFraudName !== 'OTHER_FRAUD_AMT') {
        dataExist = true;
      }
      if (
        formTableData.typeOfFraudName === 'OTHER_FRAUD_AMT' &&
        formTableData.description === field.description
      ) {
        descExist = true;
      }
    }
  });
  if (dataExist) {
    const oldTimeOut = getState().dataForms.message?.timeOutId;
    if (oldTimeOut) {
      clearTimeout(oldTimeOut);
    }
    const timeOutId = setTimeout(() => {
      dispatch({
        type: actions.SHOW_FRAUD_EXISTS,
        payload: { timeOutId: null, showFraudExists: false }
      });
    }, 8000);
    dispatch({
      type: actions.SHOW_FRAUD_EXISTS,
      payload: { timeOutId, showFraudExists: true }
    });
    return;
  }
  if (descExist) {
    const oldTimeOut = getState().dataForms.message?.timeOutId;
    if (oldTimeOut) {
      clearTimeout(oldTimeOut);
    }
    const timeOutId = setTimeout(() => {
      dispatch({
        type: actions.SHOW_DESC_EXISTS,
        payload: { timeOutId: null, showDescExists: false }
      });
    }, 8000);
    dispatch({
      type: actions.SHOW_DESC_EXISTS,
      payload: { timeOutId, showDescExists: true }
    });
    return;
  }
  try {
    const res = await updateIssuersFraudDataFormApi(data);
    if (res.status !== 200) {
      return;
    }
    const payload = {
      updatedFields: res.data.tableData[0],
      total: res.data.total
    };
    dispatch({
      type: actions.UPDATE_ISSUER_FRAUD_DATA,
      payload
    });
  } catch (e) {}
};

export const updateIssuersFraudFieldValue = (value, name, index, error) => ({
  type: actions.UPDATE_ISSUER_FRAUD_FIELD,
  payload: {
    value,
    name,
    index,
    error
  }
});

export const updateIssuersFraudFormField = (data, name, index) => async (
  dispatch,
  getState
) => {
  const formtableData = data.tableData[0];
  let descDuplicateValue = false;
  if (
    name === 'description' &&
    formtableData.typeOfFraudName === 'OTHER_FRAUD_AMT'
  ) {
    const tableData = getState().dataForms.form.tableData;
    tableData.forEach((field, idx) => {
      if (
        idx !== index &&
        field.countryName === formtableData.countryName &&
        field.typeOfFraudName === formtableData.typeOfFraudName &&
        field.description === formtableData.description
      ) {
        descDuplicateValue = true;
      }
    });
    if (descDuplicateValue) {
      dispatch({
        type: actions.UPDATE_ISSUER_FRAUD_FIELD,
        payload: {
          name,
          index,
          error: 'Same type of Fraud already exists for selected country'
        }
      });
      return;
    }
  }
  try {
    const res = await updateIssuersFraudDataFormApi(data);
    if (res.status !== 200) {
      return;
    }
    const oldTimeOut = getState().dataForms.message?.timeOutId;
    if (oldTimeOut) {
      clearTimeout(oldTimeOut);
    }
    const timeOutId = setTimeout(() => {
      dispatch({
        type: actions.SHOW_AUTO_SAVE,
        payload: { timeOutId: null, showAutoSave: false }
      });
    }, 5000);
    dispatch({
      type: actions.SHOW_AUTO_SAVE,
      payload: { timeOutId, showAutoSave: true }
    });
  } catch (e) {}
};

export const deleteIssuerFraudFormRow = (index, data) => async dispatch => {
  try {
    const res = await updateIssuersFraudDataFormApi(data);
    if (res.status !== 200) {
      return;
    }
    const payload = {
      index: index,
      total: res.data.total
    };
    dispatch({
      type: actions.DELETE_ISSUER_FRAUD_FORM_ROW,
      payload
    });
  } catch (e) {}
};

export const crossCheck = () => async (dispatch, getState) => {
  const { form } = getState().dataForms;
  const tempFormsListNav = getState().formsListNav;
  try {
    let updatedForm = await crossCheckApi(form);
    updatedForm = setQuarterlyForm(updatedForm);
    dispatch(setDataForm(updatedForm, tempFormsListNav));
  } catch (err) {}
};

export const updateRoyaltyFieldValue = (
  value,
  name,
  index,
  sectionIndex,
  error
) => async dispatch => {
  const payload = {
    value,
    name,
    index,
    sectionIndex,
    error
  };
  dispatch({
    type: actions.UPDATE_ROYALTY_FIELD,
    payload
  });
};

export const updateRoyaltyForm = data => async (dispatch, getState) => {
  const index = data.sections[0].sectionIndex;
  const fieldDetails = getState().dataForms.form.sections[index].fieldDetails;
  const formFieldDetail = data.sections[0].fieldDetails[0];
  let dataExists = false;
  let descExists = false;
  fieldDetails.forEach(field => {
    if (
      formFieldDetail.orgCntrctExclTypId === field.orgCntrctExclTypId &&
      formFieldDetail.orgCntrctExclTypId !== 'OTH'
    ) {
      dataExists = true;
    }
    if (
      formFieldDetail.orgCntrctExclTypId === 'OTH' &&
      formFieldDetail.othDescription === field.othDescription
    ) {
      descExists = true;
    }
  });
  if (dataExists) {
    dispatch({
      type: actions.SHOW_CODE_EXISTS,
      payload: { showContractExists: true }
    });
    return;
  }
  if (descExists) {
    dispatch({
      type: actions.SHOW_CODE_EXISTS,
      payload: { showRoyaltyDescExists: true }
    });
    return;
  }

  try {
    const res = await updateRoyaltyFormApi(data);
    if (res.status !== 200) {
      return;
    }
    const details = res.data.sections[0].fieldDetails[0];
    const idx = res.data.sections[0].sectionIndex;
    const tot = res.data.sections[0].total;
    dispatch({
      type: actions.UPDATE_ROYALTY_DATA,
      payload: {
        details,
        idx,
        tot
      }
    });
  } catch (e) {
    dispatch(catchError(e, 'FORM'));
  }
};

export const resetShowContractExists = () => dispatch => {
  dispatch({
    type: actions.SHOW_CODE_EXISTS,
    payload: { showContractExists: false }
  });
};

export const resetShowRoyaltyDescExists = () => dispatch => {
  dispatch({
    type: actions.SHOW_CODE_EXISTS,
    payload: { showRoyaltyDescExists: false }
  });
};

export const deleteRoyaltyFormRow = (index, data) => async dispatch => {
  try {
    const res = await updateRoyaltyFormApi(data);
    if (res.status !== 200) {
      return;
    }
    const tot = res.data.sections[0].total;
    const secIdx = res.data.sections[0].sectionIndex;
    const payload = {
      secIdx,
      index,
      tot
    };
    dispatch({
      type: actions.DELETE_ROYALTY_FORM_ROW,
      payload
    });
  } catch (e) {}
};

export const updateRoyaltyFormField = (
  data,
  name,
  index,
  sectionIndex
) => async (dispatch, getState) => {
  const formFieldData = data.sections[0].fieldDetails[0];
  let descExists = false;
  if (name === 'othDescription' && formFieldData.orgCntrctExclTypId === 'OTH') {
    const fieldData = getState().dataForms.form.sections[sectionIndex]
      .fieldDetails;
    fieldData.forEach((field, idx) => {
      if (
        idx !== index &&
        field.orgCntrctExclTypDsc === formFieldData.orgCntrctExclTypDsc &&
        field.othDescription.toUpperCase() ===
          formFieldData.othDescription.toUpperCase()
      ) {
        descExists = true;
      }
    });
    if (descExists) {
      dispatch({
        type: actions.UPDATE_ROYALTY_FIELD,
        payload: {
          name,
          index,
          sectionIndex,
          error: TEXT.royaltyTypeDuplicateErrorMsg
        }
      });
      return;
    }
  }
  try {
    const res = await updateRoyaltyFormApi(data);
    if (res.status !== 200) {
      return;
    }
    const updatedData = res.data.sections[0].fieldDetails[0];
    const tot = res.data.sections[0].total;
    const oldTimeOut = getState().dataForms.message?.timeOutId;
    if (oldTimeOut) {
      clearTimeout(oldTimeOut);
    }
    const timeOutId = setTimeout(() => {
      dispatch({
        type: actions.SHOW_AUTO_SAVE,
        payload: { timeOutId: null, showAutoSave: false }
      });
    }, 5000);
    dispatch({
      type: actions.SHOW_AUTO_SAVE,
      payload: { timeOutId, showAutoSave: true }
    });
    dispatch({
      type: actions.UPDATE_ROYALTY_FIELD_FROM_API,
      payload: { updatedData, sectionIndex, index, tot }
    });
  } catch (e) {}
};

export const submitDataForms = () => async (dispatch, getState) => {
  const { form } = getState().dataForms;
  try {
    const updatedForm = await saveFormApi(form);
    if (updatedForm) {
      return await submitDataFormsApi(updatedForm);
    }
  } catch (err) {
    dispatch(catchError(err, 'FORM'));
  }
};
