import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFormik } from 'formik';
import {
  PageSkeleton,
  ActionBar,
  Modal,
  CanActivate
} from '../../../../components';
import { Grid, Typography } from '@material-ui/core';
import OrganizationDetailsForm from '../OrganizationDetailsForm';
import OrganizationProfileTable from '../OrganizationProfileTable';
import ProductLines from '../ProductLines';
import { createOrganizationApi } from '../../../../api/organizations';
import { createStatusFromErrors } from '../../../../utils/commonFunctions';
import { organizationSchema } from '../../../../constants/validations/admin/organization';
import { toastFunc } from '../../../../components/ToastComponent/toastFunction';
import PageError from '../../../PageError';

export class AddOrganization extends Component {
  state = {
    modalOpen: false,
    filteredProducts: []
  };

  componentDidMount() {
    const {
      getTotalOrganizationCountries,
      getTotalOrganizationRegions,
      getTotalOrganizationTypes,
      getTotalCurrencies,
      getTotalProducts
    } = this.props;

    getTotalOrganizationCountries();
    getTotalOrganizationRegions();
    getTotalOrganizationTypes();
    getTotalCurrencies();
    getTotalProducts();
  }

  handleBlur = e => {
    const { handleBlur, status, setStatus } = this.props;
    e.persist();
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };

  addOrgProfile = orgProfile => {
    const { regions, orgTypes, setFieldValue, values } = this.props;

    const newProfile = {
      organizationProfileId: Date().toString(),
      organizationProfileName: orgProfile.organizationProfileName,
      operatingCountryCodeInfo: {
        countryIsoName: orgProfile.operatingCountry.label,
        countryIsoCode: orgProfile.operatingCountry.value
      },
      orgRegionInfo: {
        regionTypeCode: orgProfile.region,
        regionTypeDescription: regions.find(
          region => region.value === orgProfile.region
        ).label
      },
      currencyInfo: {
        currencyTypeCode: orgProfile.currency.value,
        currencyName: orgProfile.currency.label
      },
      orgTypeInfo: {
        orgTypCde: orgTypes.find(org => org.value === orgProfile.entityType).id,
        orgTypNm: orgTypes.find(org => org.value === orgProfile.entityType)
          .label
      },
      iicISOCode: orgProfile.iicCode,
      iicDXSCode: orgProfile.dxsCode,
      activeStatus: orgProfile.extras.includes('active') ? 'T' : 'F',
      sendToFinancialForce: orgProfile.extras.includes('financialForce')
        ? 'T'
        : 'F',
      productInfo: [],
      deleteAllowed: true
    };
    setFieldValue('orgProfiles', [...values.orgProfiles, newProfile]);
  };

  editOrgProfile = profileId => {
    const { setFieldValue, values } = this.props;
    setFieldValue(
      'orgProfiles',
      values.orgProfiles.map(org => {
        if (org.organizationProfileId !== profileId) {
          return org;
        }
        return {
          ...org,
          isEditable: true
        };
      })
    );
  };

  cancelEditOrgProfile = profileId => {
    const { setFieldValue, values } = this.props;
    setFieldValue(
      'orgProfiles',
      values.orgProfiles.map(org => {
        if (org.organizationProfileId !== profileId) {
          return org;
        }
        return {
          ...org,
          isEditable: false
        };
      })
    );
  };

  updateOrgProfile = orgProfile => {
    const { values, orgTypes, setFieldValue } = this.props;
    setFieldValue(
      'orgProfiles',
      values.orgProfiles.map(org => {
        if (org.organizationProfileId !== orgProfile.organizationProfileId) {
          return org;
        }
        return {
          ...org,
          organizationProfileName: orgProfile.organizationProfileName,
          currencyInfo: {
            currencyTypeCode: orgProfile.currency.value,
            currencyName: orgProfile.currency.label
          },
          orgTypeInfo: {
            orgTypCde: orgTypes.find(org => org.value === orgProfile.entityType)
              .id,
            orgTypNm: orgTypes.find(org => org.value === orgProfile.entityType)
              .label
          },
          activeStatus: orgProfile.extras.includes('active') ? 'T' : 'F',
          sendToFinancialForce: orgProfile.extras.includes('financialForce')
            ? 'T'
            : 'F',
          isEditable: false,
          status: org.status === 'A' ? 'A' : 'U'
        };
      })
    );
  };

  deleteOrgProfile = (profileId, hardDelete = false) => {
    const { setFieldValue, values } = this.props;

    if (hardDelete) {
      setFieldValue('deletedOrgProfiles', [
        ...values.deletedOrgProfiles,
        values.orgProfiles.find(org => org.organizationProfileId === profileId)
      ]);
    }
    setFieldValue(
      'orgProfiles',
      values.orgProfiles.filter(org => org.organizationProfileId !== profileId)
    );
  };

  onProductChange = (products, id, type) => {
    const { setFieldValue, values } = this.props;
    setFieldValue(
      'orgProfiles',
      values.orgProfiles.map(org => {
        if (org.organizationProfileId !== id) {
          return org;
        }
        return {
          ...org,
          productInfo: [
            ...org.productInfo,
            ...products.map(product => ({
              ...product,
              productOperationCode: 'A'
            }))
          ],
          status: org.status === 'A' ? 'A' : 'U'
        };
      })
    );
  };

  onProductRemoved = (product, orgId) => {
    const { setFieldValue, values } = this.props;
    setFieldValue(
      'orgProfiles',
      values.orgProfiles.map(org => {
        if (org.organizationProfileId !== orgId) {
          return org;
        }
        return {
          ...org,
          productInfo: org.productInfo
            .filter(
              prod =>
                !(
                  prod.cardProductBandMapId === product.id &&
                  prod.productOperationCode === 'A'
                )
            )
            .map(prod => {
              if (prod.cardProductBandMapId !== product.id) {
                return prod;
              }
              return {
                ...prod,
                productOperationCode: 'D'
              };
            }),
          status: org.status === 'A' ? 'A' : 'U'
        };
      })
    );
  };

  unSelectProductsAll = (orgId, cardBusinessCode) => {
    const { setFieldValue, values } = this.props;
    setFieldValue(
      'orgProfiles',
      values.orgProfiles.map(org => {
        if (org.organizationProfileId !== orgId) {
          return org;
        }
        return {
          ...org,
          productInfo: org.productInfo
            .filter(
              prod =>
                !(
                  prod.cardBusinessCode === cardBusinessCode &&
                  prod.productOperationCode === 'A'
                )
            )
            .map(prod => {
              if (prod.cardBusinessCode !== cardBusinessCode) {
                return prod;
              }
              return {
                ...prod,
                productOperationCode: 'D'
              };
            }),
          status: org.status === 'A' ? 'A' : 'U'
        };
      })
    );
  };

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }));
  };
  componentDidUpdate(prevProps) {
    const { orgProfiles } = this.props.values;
    const prevOrgProfiles = prevProps.values.orgProfiles;
    if (prevOrgProfiles !== orgProfiles) {
      const deletedProducts = orgProfiles
        .filter(item =>
          ['Franchise', 'Issuer', 'Net to Net'].includes(
            item.orgTypeInfo.orgTypNm
          )
        )
        .map(item => ({
          ...item,
          productLength:
            item.productInfo && item.productInfo.length > 0
              ? item.productInfo.filter(
                  product =>
                    product.productOperationCode === 'E' ||
                    product.productOperationCode === 'A'
                ).length
              : 0
        }));
      const filteredProducts = deletedProducts.filter(
        product =>
          ['Franchise', 'Issuer', 'Net to Net'].includes(
            product.orgTypeInfo.orgTypNm
          ) && product.productLength === 0
      );
      this.setState({
        filteredProducts
      });
    }
  }
  render() {
    const {
      errors,
      touched,
      dirty,
      isValid,
      values,
      status,
      countries,
      currencies,
      regions,
      orgTypes,
      products,
      setErrors,
      handleChange,
      handleReset,
      handleSubmit,
      highestRole
    } = this.props;
    const { filteredProducts } = this.state;
    return (
      <CanActivate
        action='org-profile:create'
        unAuthorizedView={<PageError errorCode='401' />}>
        <PageSkeleton
          title='Add Organization Profile'
          data-test='create-org-component'>
          <Modal
            data-test='create-org-modal'
            isOpen={this.state.modalOpen}
            submitBtnLabel='Confirm'
            isOkButton={filteredProducts.length > 0}
            onClose={this.toggleModal}
            onCancel={this.toggleModal}
            onSubmit={() => {
              this.toggleModal();
              handleSubmit();
            }}>
            {filteredProducts.length > 0 ? (
              <Typography variant='body1' color='secondary'>
                Please add at least one product for following orgs:
                <ul>
                  {filteredProducts.map(orgProfile => (
                    <li key={orgProfile.organizationProfileId}>
                      {orgProfile.organizationProfileName}
                    </li>
                  ))}
                </ul>
              </Typography>
            ) : (
              <Typography color='secondary' variant='body1'>
                Are you sure you want to create a new Business Profile?
              </Typography>
            )}
          </Modal>
          <Grid container className='organization-container'>
            <Grid item md={12}>
              <ActionBar
                primaryButton={{
                  label: 'Submit',
                  disabled: !isValid,
                  onClick: this.toggleModal
                }}
                secondaryButton={{
                  label: 'Reset',
                  disabled: !dirty,
                  onClick: handleReset
                }}
              />
            </Grid>
            <Grid item md={12}>
              <OrganizationDetailsForm
                data-test='org-details-form'
                handleChange={handleChange}
                handleBlur={this.handleBlur}
                values={values}
                errors={errors}
                touched={touched}
                status={status}
                countries={countries}
              />
            </Grid>
            <Grid item md={12}>
              <OrganizationProfileTable
                add
                orgProfiles={values.orgProfiles}
                countries={countries}
                orgTypes={orgTypes}
                regions={regions}
                currencies={currencies}
                onOrgProfileAdd={this.addOrgProfile}
                onOrgProfileEdit={this.editOrgProfile}
                onOrgProfileEditCancel={this.cancelEditOrgProfile}
                onOrgProfileDelete={this.deleteOrgProfile}
                onOrgProfileUpdate={this.updateOrgProfile}
              />
            </Grid>
            <Grid item md={12}>
              <ProductLines
                data-test='org-products'
                orgProfiles={values.orgProfiles.filter(
                  org => org.orgTypeInfo.orgTypNm !== 'Aquirer'
                )}
                unSelectProductsAll={this.unSelectProductsAll}
                setErrors={setErrors}
                errors={errors}
                products={products}
                onProductLineChange={this.onProductChange}
                onProductRemoved={this.onProductRemoved}
                highestRole={highestRole}
              />
            </Grid>
          </Grid>
        </PageSkeleton>
      </CanActivate>
    );
  }
}

const formConfig = withFormik({
  mapPropsToValues: () => ({
    organizationName: '',
    orgLegalName: '',
    headquarterCountry: '',
    orgProfiles: []
  }),

  mapPropsToStatus: () => ({}),

  isInitialValid: false,
  validationSchema: organizationSchema,

  handleSubmit: (values, formikBag) => {
    if (values.orgProfiles.length === 0) {
      return toastFunc({
        content: 'Please add Organization Profile',
        config: { className: 'toast-container reject' },
        toastType: 'error'
      });
    }
    const orgProfiles = values.orgProfiles.map(profile => ({
      sendToFinancialForce:
        profile.sendToFinancialForce === 'T' ||
        profile.sendToFinancialForce === 'Y'
          ? 'T'
          : 'F',
      activeStatus:
        profile.activeStatus === 'T' || profile.activeStatus === 'Y'
          ? 'T'
          : 'F',
      organizationProfileName: profile.organizationProfileName,
      iicISOCode: profile.iicISOCode,
      iicDXSCode: profile.iicDXSCode,
      operatingCountryCodeInfo: {
        countryIsoCode: profile.operatingCountryCodeInfo.countryIsoCode
      },
      orgRegionInfo: {
        regionTypeCode: profile.orgRegionInfo.regionTypeCode
      },
      currencyInfo: {
        currencyTypeCode: profile.currencyInfo.currencyTypeCode
      },
      orgTypeInfo: {
        orgTypCde: profile.orgTypeInfo.orgTypCde
      },
      productInfo: profile.productInfo.map(product => ({
        cardProductBandMapId: product.cardProductBandMapId
      }))
    }));

    const body = {
      headQuarterCountryCodeInfo: {
        countryIsoCode: values.headquarterCountry
      },
      orgLegalName: values.orgLegalName,
      organizationName: values.organizationName,
      organizationProfileList: orgProfiles
    };

    createOrganizationApi(body).then(response => {
      if (response.status === 200) {
        toastFunc({
          content: 'Organization has been Created Successfully',
          config: { className: 'toast-container accept' }
        });

        formikBag.resetForm();
        formikBag.props.history.replace(
          `/admin/organization/edit/${response.data}`
        );
      } else {
        const err = {};
        err.response = response;
        formikBag.setStatus(createStatusFromErrors(err));
      }
    });
  }
});

export default withRouter(formConfig(AddOrganization));
