import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { MenuList } from '@mui/material';
import PropTypes from 'prop-types';
import './material.css';
import { styled } from '@mui/material/styles';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

const checkUrlForTestAdmin = () => window.location.href.includes('admin');

const StyledAutocomplete = styled(Autocomplete)(({ urlContainsTestAdmin }) => ({
  '& .MuiAutocomplete-inputRoot': {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
      paddingTop: urlContainsTestAdmin ? 10 : 5,
      borderRadius: '0px !important',
      fontFamily: 'Meta Offc Pro, Arial',
      fontSize: 14,
      fontWeight: 500
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1a587e',
      border: '1px solid',
      borderRadius: '0px !important'
    }
  }
}));

const DropdownSearch = props => {
  const { name, onChange, label } = props;
  const rootProps = { ...props };
  const urlContainsTestAdmin = checkUrlForTestAdmin();
  delete rootProps.onChange;
  delete rootProps.required;
  delete rootProps.error;
  const inputProps = { error: props.error, required: props.required };

  return (
    <StyledAutocomplete
      autoComplete
      selectOnFocus
      disableClearable
      urlContainsTestAdmin={urlContainsTestAdmin}
      data-test='component-Combobox'
      ListboxComponent={MenuList}
      id={name}
      getOptionLabel={option => option.label}
      renderInput={params => (
        <TextField {...params} {...inputProps} fullWidth label={label} />
      )}
      clearText='Search'
      closeIcon={<SearchIcon />}
      onChange={(e, val) => {
        const updatedEvent = {
          ...e,
          target: {
            ...e.target,
            value: val?.value,
            name: 'organization'
          }
        };
        onChange(updatedEvent);
      }}
      {...rootProps}
    />
  );
};

DropdownSearch.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func
};

DropdownSearch.defaultProps = {
  label: 'Organisation',
  error: false,
  required: false,
  onChange: () => {}
};

export default DropdownSearch;
