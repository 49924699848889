import actions from './types';
import { slugToIDs } from '../../constants/dataForms';

export const initialState = {
  form: undefined,
  message: {}
};

const annualBalanceSheetAssets = 'annual-balance-sheet-assets';
const annualBalanceSheetLiabilities = 'annual-balance-sheet-liabilities';
const annualBalanceSheetShareholdersEquity =
  'annual-balance-sheet-shareholders-equity';
const annualStatisticalInformation = 'annual-statistical-information';

const updateFieldWithTotal = (state, payload, effectiveValue, totalIndex) => {
  return {
    ...state,
    form: {
      ...state.form,
      tabs: state.form.tabs.map((tab, tabIndex) => {
        if (state.activeTab !== tabIndex) {
          return tab;
        }
        return {
          ...tab,
          hasError: Boolean(payload.error),
          sections: tab.sections.map((section, sIndex) => {
            if (payload.sectionIndex !== sIndex) {
              return section;
            }

            return {
              ...section,
              fieldDetails: section.fieldDetails.map((field, fieldIndex) => {
                if (
                  payload.valIndex !== fieldIndex &&
                  totalIndex !== fieldIndex
                ) {
                  return field;
                }
                if (payload.valIndex === fieldIndex) {
                  return {
                    ...field,
                    value: payload.value,
                    error: payload.error
                  };
                }
                return {
                  ...field,
                  value: String(Number(field.value) + effectiveValue)
                };
              })
            };
          })
        };
      })
    }
  };
};

const updateFieldWithRowAndColumnTotal = (
  state,
  payload,
  effectiveValue,
  totalIndexForRow,
  totalIndexForColumn,
  totalIndexforRowAndColTotal,
  indexForAttributionColumn
) => {
  return {
    ...state,
    form: {
      ...state.form,
      tabs: state.form.tabs.map((tab, tabIndex) => {
        if (state.activeTab !== tabIndex) {
          return tab;
        }
        return {
          ...tab,
          hasError: Boolean(payload.error),
          sections: tab.sections.map((section, sIndex) => {
            if (payload.sectionIndex !== sIndex) {
              return section;
            }

            return {
              ...section,
              fieldDetails: section.fieldDetails.map((field, fieldIndex) => {
                if (
                  payload.valIndex !== fieldIndex &&
                  totalIndexForRow !== fieldIndex &&
                  totalIndexForColumn !== fieldIndex &&
                  totalIndexforRowAndColTotal !== fieldIndex
                ) {
                  return field;
                }
                if (payload.valIndex === fieldIndex) {
                  return {
                    ...field,
                    value: payload.value,
                    error: payload.error
                  };
                }
                if (
                  payload.valIndex == indexForAttributionColumn &&
                  (fieldIndex == totalIndexForRow ||
                    fieldIndex == totalIndexforRowAndColTotal)
                ) {
                  return {
                    ...field,
                    value: String(Number(field.value) - effectiveValue)
                  };
                }
                return {
                  ...field,
                  value: String(Number(field.value) + effectiveValue)
                };
              })
            };
          })
        };
      })
    }
  };
};

const updateFieldWithColumnTotal = (state, payload) => {
  const { value, sectionIndex, valIndex } = payload;
  const originalValue =
    state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
      valIndex
    ].value;

  const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const numOriginalValue = Number.isNaN(Number(originalValue))
    ? 0
    : Number(originalValue);

  const effectiveValue = numValue - numOriginalValue;

  const nRows = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].rowHeaders
  ).length;
  const nCols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;

  const totalIndex = (nRows - 1) * nCols + (valIndex % nCols);

  return updateFieldWithTotal(state, payload, effectiveValue, totalIndex);
};

const clearDataHandler = state => {
  return {
    ...state,
    form: {
      ...state.form,
      tabs: state.form.tabs.map((tab, index) => {
        if (index !== state.activeTab) {
          return tab;
        }
        return state.tempForm.tabs[state.activeTab];
      })
    }
  };
};

const defaultFieldUpdater = (state, payload) => {
  return {
    ...state,
    form: {
      ...state.form,
      tabs: state.form.tabs.map((tab, tabIndex) => {
        if (state.activeTab !== tabIndex) {
          return tab;
        }
        return {
          ...tab,
          hasError: Boolean(payload.error),
          sections: tab.sections.map((section, sectionIndex) => {
            if (payload.sectionIndex !== sectionIndex) {
              return section;
            }

            return {
              ...section,
              fieldDetails: section.fieldDetails.map((field, fieldIndex) => {
                if (payload.valIndex !== fieldIndex) {
                  return field;
                }
                return {
                  ...field,
                  value: payload.value,
                  error: payload.error
                };
              })
            };
          })
        };
      })
    }
  };
};

const updateQRCode = (state, payload) => {
  const { value, sectionIndex, valIndex } = payload;

  let effectiveValue;
  if (sectionIndex === 0 && valIndex <= 1) {
    const originalValue =
      state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
        valIndex
      ].value;

    const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
    const numOriginalValue = Number.isNaN(Number(originalValue))
      ? 0
      : Number(originalValue);
    effectiveValue = numValue - numOriginalValue;
  }

  return {
    ...state,
    form: {
      ...state.form,
      tabs: state.form.tabs.map((tab, tabIndex) => {
        if (state.activeTab !== tabIndex) {
          return tab;
        }
        return {
          ...tab,
          hasError: Boolean(payload.error),
          sections: tab.sections.map((section, sectionIndex) => {
            if (payload.sectionIndex !== sectionIndex) {
              return section;
            }

            return {
              ...section,
              fieldDetails: section.fieldDetails.map((field, fieldIndex) => {
                if (sectionIndex === 0 && fieldIndex === 2) {
                  return {
                    ...field,
                    value: effectiveValue
                      ? String(Number(field.value) + effectiveValue)
                      : field.value
                  };
                }

                if (payload.valIndex !== fieldIndex) {
                  return field;
                }

                return {
                  ...field,
                  value: payload.value,
                  error: payload.error
                };
              })
            };
          })
        };
      })
    }
  };
};

const updateIntes = (state, payload) => {
  const { value, name, index, error } = payload;
  const colValues = state.form.fieldDetails.map((x, idx) =>
    index !== idx ? x[name] : value
  );
  const totalColAmt = colValues.reduce(
    (total, curr) => Number(curr) + total,
    0
  );
  if (name === 'currMthlySalesVolAmt') {
    const originalValue = state.form.fieldDetails[index][name];
    const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
    const numOriginalValue = Number.isNaN(Number(originalValue))
      ? 0
      : Number(originalValue);
    const effectiveValue = numValue - numOriginalValue;
    const lastColValues = state.form.fieldDetails.map((x, idx) =>
      index !== idx ? x['newYtdTotal'] : value
    );
    const lastColTot = lastColValues.reduce(
      (total, curr) => Number(curr) + total,
      0
    );
    return {
      ...state,
      form: {
        ...state.form,
        fieldDetails: state.form.fieldDetails.map((field, idx) => {
          if (idx === index) {
            return {
              ...field,
              [name]: value,
              newYtdTotal: String(
                Number(state.form.fieldDetails[index].newYtdTotal) +
                  effectiveValue
              ),
              ['error' + name]: error
            };
          }
          return field;
        }),
        total: {
          ...state.form.total,
          [name]: totalColAmt,
          newYtdTotal: lastColTot
        }
      }
    };
  }
  return {
    ...state,
    form: {
      ...state.form,
      fieldDetails: state.form.fieldDetails.map((field, idx) => {
        if (idx === index) {
          return {
            ...field,
            [name]: value,
            ['error' + name]: error
          };
        }
        return field;
      }),
      total: {
        ...state.form.total,
        [name]: totalColAmt
      }
    }
  };
};

const updateIssuerFraud = (state, payload) => {
  const { value, index, name, error } = payload;
  const colValues = state.form.tableData.map((x, idx) =>
    index !== idx ? x[name] : value
  );
  const totalColAmt = colValues.reduce(
    (total, curr) => Number(curr) + total,
    0
  );
  return {
    ...state,
    form: {
      ...state.form,
      tableData: state.form.tableData.map((field, idx) => {
        if (idx === index) {
          return {
            ...field,
            [name]: value,
            ['error' + name]: error
          };
        }
        return field;
      }),
      total: {
        ...state.form.total,
        [name]: totalColAmt
      }
    }
  };
};

const updateCardMember = (state, payload) => {
  if (payload.sectionIndex === 2) {
    return defaultFieldUpdater(state, payload);
  }

  const { value, sectionIndex, valIndex, rowIndex } = payload;
  const originalValue =
    state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
      valIndex
    ].value;
  const nRows = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].rowHeaders
  ).length;
  const nCols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;

  const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const numOriginalValue = Number.isNaN(Number(originalValue))
    ? 0
    : Number(originalValue);
  let effectiveValue = numValue - numOriginalValue;
  if (sectionIndex === 0 && (rowIndex === 2 || rowIndex === 3)) {
    effectiveValue = -effectiveValue;
  }
  const totalIndex = (nRows - 1) * nCols + (valIndex % nCols);
  return updateFieldWithTotal(state, payload, effectiveValue, totalIndex);
};

const updateCardVolume = (state, payload) => {
  const { value, sectionIndex, valIndex } = payload;
  const originalValue =
    state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
      valIndex
    ].value;
  const nRows = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].rowHeaders
  ).length;
  const nCols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;

  const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const numOriginalValue = Number.isNaN(Number(originalValue))
    ? 0
    : Number(originalValue);
  const effectiveValue = numValue - numOriginalValue;
  const totalIndex = (nRows - 1) * nCols + (valIndex % nCols);
  return updateFieldWithTotal(state, payload, effectiveValue, totalIndex);
};

const updateSeDiscountRevenue = (state, payload) => {
  const { value, sectionIndex, valIndex } = payload;
  const originalValue =
    state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
      valIndex
    ].value;

  const nRows = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].rowHeaders
  ).length;
  const nCols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;
  const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const numOriginalValue = Number.isNaN(Number(originalValue))
    ? 0
    : Number(originalValue);

  const effectiveValue = numValue - numOriginalValue;

  const totalIndexForRow = Math.floor(valIndex / nCols) * nCols + (nCols - 2);

  const totalIndexForColumn = (nRows - 1) * nCols + (valIndex % nCols);

  const totalIndexforRowAndColTotal = (nRows * 4 - 1) * (nCols - 6);

  return updateFieldWithRowAndColumnTotal(
    state,
    payload,
    effectiveValue,
    totalIndexForRow,
    totalIndexForColumn,
    totalIndexforRowAndColTotal
  );
};

const updateSeMembershipCount = (state, payload) => {
  const { value, sectionIndex, valIndex } = payload;
  const originalValue =
    state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
      valIndex
    ].value;

  const nRows = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].rowHeaders
  ).length;
  const nCols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;

  const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const numOriginalValue = Number.isNaN(Number(originalValue))
    ? 0
    : Number(originalValue);

  const indexForAttributionColumn =
    slugToIDs[state.form.formId] === 'se-membership-count'
      ? Math.floor(valIndex / nCols) * nCols + (nCols - 3)
      : null;

  const effectiveValue = numValue - numOriginalValue;

  const totalIndexForRow = Math.floor(valIndex / nCols) * nCols + (nCols - 1);

  const totalIndexForColumn = (nRows - 1) * nCols + (valIndex % nCols);

  const totalIndexforRowAndColTotal = nRows * nCols - 1;

  return updateFieldWithRowAndColumnTotal(
    state,
    payload,
    effectiveValue,
    totalIndexForRow,
    totalIndexForColumn,
    totalIndexforRowAndColTotal,
    indexForAttributionColumn
  );
};

const updateRoyaltySalesVolume = (state, payload) => {
  const { value, sectionIndex, valIndex } = payload;
  const originalValue =
    state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
      valIndex
    ].value;
  const nSections = Object.keys(state.form.tabs[state.activeTab].sections);
  const nRows = state.form.tabs[state.activeTab].sections.map(
    section => Object.keys(section.rowHeaders).length
  );
  const nCols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;
  const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const numOriginalValue = Number.isNaN(Number(originalValue))
    ? 0
    : Number(originalValue);

  let effectiveValue = numValue - numOriginalValue;
  let totalIndexs = [];
  let mapIndexs = new Map();

  if (sectionIndex === Number(nSections[0])) {
    totalIndexs.push(7 * nCols + (valIndex % nCols)); // Row total(1st section in last row)
    totalIndexs.push(7 * nCols + (nCols - 1)); // Column total(1st section in last row)
    totalIndexs.push(Math.floor(valIndex / nCols) * nCols + (nCols - 1)); // For column total of current index
    mapIndexs.set(0, totalIndexs);
    totalIndexs = [];
    totalIndexs.push(2 * nCols + (valIndex % nCols));
    totalIndexs.push(2 * nCols + (nCols - 1));
    mapIndexs.set(1, totalIndexs);
    totalIndexs = [];
  } else if (sectionIndex === Number(nSections[1])) {
    totalIndexs.push(2 * nCols + (valIndex % nCols));
    totalIndexs.push(2 * nCols + (nCols - 1));
    totalIndexs.push(Math.floor(valIndex / nCols) * nCols + (nCols - 1)); // For column total of current index
    mapIndexs.set(1, totalIndexs);
    totalIndexs = [];
  } else {
    totalIndexs.push(Math.floor(valIndex / nCols) * nCols + (nCols - 1)); // For column total of current index
    totalIndexs.push((nRows[2] - 2) * nCols + (nCols - 1)); // Row total(2nd last row of 3rd section)
    totalIndexs.push((nRows[2] - 2) * nCols + (valIndex % nCols)); // Column total(2nd last row of 3rd section in grandtotal column)
  }
  totalIndexs.push((nRows[2] - 1) * nCols + (nCols - 1)); // Row total(last row of 3rd section)
  totalIndexs.push((nRows[2] - 1) * nCols + (valIndex % nCols)); // Column total(last row of 3rd section in grandtotal column)
  mapIndexs.set(2, totalIndexs);

  return updateRoyaltySalesVolumeTabFields(
    state,
    payload,
    effectiveValue,
    mapIndexs
  );
};

const updateRoyaltySalesVolumeTabFields = (
  state,
  payload,
  effectiveValue,
  mapIndexs
) => {
  let totalIndexs = [];
  return {
    ...state,
    form: {
      ...state.form,
      tabs: state.form.tabs.map((tab, tabIndex) => {
        if (state.activeTab !== tabIndex) {
          return tab;
        }
        return {
          ...tab,
          hasError: Boolean(payload.error),
          sections: tab.sections.map((section, sIndex) => {
            if (!mapIndexs.has(sIndex)) {
              return section;
            }
            let slength = section.fieldDetails.length;
            let colSize = Object.keys(section.columnHeaders).length;
            let lastRowIndexs = Array.from(
              { length: colSize },
              (_, index) => index + (slength - colSize)
            );
            totalIndexs = mapIndexs.get(sIndex);
            mapIndexs.delete(sIndex);
            return {
              ...section,
              fieldDetails: section.fieldDetails.map((field, fieldIndex) => {
                if (
                  payload.valIndex !== fieldIndex &&
                  !totalIndexs.includes(fieldIndex)
                ) {
                  return field;
                }
                if (
                  payload.valIndex === fieldIndex &&
                  payload.sectionIndex === sIndex
                ) {
                  return {
                    ...field,
                    value: payload.value,
                    error: payload.error
                  };
                }
                if (
                  payload.valIndex === fieldIndex &&
                  payload.sectionIndex !== sIndex &&
                  !lastRowIndexs.includes(fieldIndex)
                ) {
                  return field;
                }
                return {
                  ...field,
                  value: String(Number(field.value) + effectiveValue)
                };
              })
            };
          })
        };
      })
    }
  };
};

const updateRoyalty = (state, payload) => {
  const { value, name, sectionIndex, index, error } = payload;
  if (name === 'value') {
    const originalValue =
      state.form.sections[sectionIndex].fieldDetails[index].value;
    const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
    const numOriginalValue = Number.isNaN(Number(originalValue))
      ? 0
      : Number(originalValue);
    const effectiveValue = numValue - numOriginalValue;
    return {
      ...state,
      form: {
        ...state.form,
        sections: state.form.sections.map((section, sectionIdx) => {
          if (sectionIdx === sectionIndex) {
            return {
              ...section,
              total: section.total + effectiveValue,
              fieldDetails: section.fieldDetails.map((field, idx) => {
                if (idx === index) {
                  return {
                    ...field,
                    [name]: value,
                    ['error' + name]: error
                  };
                }
                return field;
              })
            };
          }
          return section;
        })
      }
    };
  }
  return {
    ...state,
    form: {
      ...state.form,
      sections: state.form.sections.map((section, sectionIdx) => {
        if (sectionIdx === sectionIndex) {
          return {
            ...section,
            fieldDetails: section.fieldDetails.map((field, idx) => {
              if (idx === index) {
                return {
                  ...field,
                  [name]: value,
                  ['error' + name]: error
                };
              }
              return field;
            })
          };
        }
        return section;
      })
    }
  };
};
const updateTabFields = (
  state,
  payload,
  effectiveValue,
  totalIndexs,
  totalNegativeIndexs = []
) => {
  return {
    ...state,
    form: {
      ...state.form,
      tabs: state.form.tabs.map((tab, tabIndex) => {
        if (state.activeTab !== tabIndex) {
          return tab;
        }
        return {
          ...tab,
          hasError: Boolean(payload.error),
          sections: tab.sections.map((section, sIndex) => {
            return {
              ...section,
              fieldDetails: section.fieldDetails.map((field, fieldIndex) => {
                if (
                  totalNegativeIndexs.length > 0 &&
                  totalNegativeIndexs.includes(fieldIndex)
                ) {
                  return {
                    ...field,
                    value: String(Number(field.value) - effectiveValue)
                  };
                }
                if (
                  payload.valIndex !== fieldIndex &&
                  !totalIndexs.includes(fieldIndex)
                ) {
                  return field;
                }
                if (payload.valIndex === fieldIndex) {
                  return {
                    ...field,
                    value: payload.value,
                    error: payload.error
                  };
                }
                return {
                  ...field,
                  value: String(Number(field.value) + effectiveValue)
                };
              })
            };
          })
        };
      })
    }
  };
};

const totalTabFieldIndexs = (state, payload, totalIndexs) => {
  const { sectionIndex, valIndex } = payload;
  const tabsLength = state.form.tabs.length;
  const nRows = Object.keys(
    state.form.tabs[tabsLength - 1].sections[sectionIndex].rowHeaders
  ).length;
  const nCols = Object.keys(
    state.form.tabs[tabsLength - 1].sections[sectionIndex].columnHeaders
  ).length;
  let forecastPos = Object.values(
    state.form.tabs[tabsLength - 1].sections[sectionIndex].columnHeaders
  ).indexOf('Forecast Total');
  forecastPos = forecastPos !== -1 ? forecastPos : 1;
  let actualPos = Object.values(
    state.form.tabs[tabsLength - 1].sections[sectionIndex].columnHeaders
  ).indexOf('Actual Total');
  actualPos = actualPos !== -1 ? actualPos : 0;
  let totalPos = Object.values(
    state.form.tabs[tabsLength - 1].sections[sectionIndex].columnHeaders
  ).indexOf('Totals');
  totalPos = totalPos !== -1 ? totalPos : nCols - 1;
  const indexs = [];
  const negIndexs = [];
  const fieldPos = state.form.tabs[state.activeTab].actual
    ? actualPos
    : forecastPos;
  indexs.push((nRows - 1) * nCols + totalPos);
  indexs.push((nRows - 1) * nCols + fieldPos);
  if (totalIndexs.length === 2) {
    indexs.push((nRows - 3) * nCols + totalPos);
    indexs.push((nRows - 3) * nCols + fieldPos);
    indexs.push(Math.floor(valIndex / nCols) * nCols + fieldPos);
    indexs.push(Math.floor(valIndex / nCols) * nCols + totalPos);
  } else {
    negIndexs.push(Math.floor(valIndex / nCols) * nCols + fieldPos);
    negIndexs.push(Math.floor(valIndex / nCols) * nCols + totalPos);
  }
  return [indexs, negIndexs];
};

const annualTotalTabFieldIndexs = (state, payload, totalIndexs) => {
  const { sectionIndex, valIndex } = payload;
  const tabsLength = state.form.tabs.length;
  const nRows = Object.keys(
    state.form.tabs[tabsLength - 1].sections[sectionIndex].rowHeaders
  ).length;
  const acols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;
  const indexs = [];
  const negIndexs = [];
  indexs.push(nRows - 1);
  if (totalIndexs.length === 2) {
    indexs.push(nRows - 3);
    indexs.push(Math.floor(valIndex / acols));
  } else {
    negIndexs.push(Math.floor(valIndex / acols));
  }
  return [indexs, negIndexs];
};

const updateTotalTabFields = (
  state,
  payload,
  effectiveValue,
  totalIndexs,
  totalNegIndexs
) => {
  return {
    ...state,
    form: {
      ...state.form,
      tabs: state.form.tabs.map((tab, tabIndex) => {
        if (state.form.tabs.length - 1 !== tabIndex) {
          return tab;
        }
        return {
          ...tab,
          hasError: Boolean(payload.error),
          sections: tab.sections.map(section => {
            return {
              ...section,
              fieldDetails: section.fieldDetails.map((field, fieldIndex) => {
                if (totalIndexs.includes(fieldIndex)) {
                  return {
                    ...field,
                    value: String(Number(field.value) + effectiveValue)
                  };
                }
                if (totalNegIndexs.includes(fieldIndex)) {
                  return {
                    ...field,
                    value: String(Number(field.value) - effectiveValue)
                  };
                }
                return field;
              })
            };
          })
        };
      })
    }
  };
};

const updateRevenueFunding = (state, payload) => {
  const { value, sectionIndex, valIndex } = payload;
  const originalValue =
    state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
      valIndex
    ].value;

  const nRows = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].rowHeaders
  ).length;
  const nCols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;
  const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const numOriginalValue = Number.isNaN(Number(originalValue))
    ? 0
    : Number(originalValue);

  let effectiveValue = numValue - numOriginalValue;
  const totalIndexs = [(nRows - 1) * nCols + (valIndex % nCols)];
  if (valIndex === (nRows - 2) * nCols + (valIndex % nCols)) {
    effectiveValue = -effectiveValue;
  } else {
    totalIndexs[1] = (nRows - 3) * nCols + (valIndex % nCols);
  }
  state = updateTabFields(state, payload, effectiveValue, totalIndexs);
  let totalTabIndexs;
  if (
    slugToIDs[state.form.formId] ===
    'annual-income-statement-revenue-and-funding'
  ) {
    totalTabIndexs = annualTotalTabFieldIndexs(state, payload, totalIndexs);
  } else {
    totalTabIndexs = totalTabFieldIndexs(state, payload, totalIndexs);
  }
  return updateTotalTabFields(
    state,
    payload,
    effectiveValue,
    totalTabIndexs[0],
    totalTabIndexs[1]
  );
};

const updateFieldsOfTotalTab = state => {
  const totalTab = state.form.tabs.length;
  if (totalTab < 4) {
    return state;
  }
  const totalMap = new Map();
  totalMap.set('Actual Total', new Map());
  totalMap.set('Forecast Total', new Map());
  state.form.tabs.forEach(tab => {
    if (tab.tabIdentifier[1] < totalTab) {
      const tabType =
        Boolean(tab.actual) || Boolean(tab.submitted)
          ? 'Actual Total'
          : 'Forecast Total';
      tab.sections.forEach(section => {
        section.fieldDetails.forEach(field => {
          if (totalMap.get(tabType).has(field.rowHeaderName)) {
            totalMap
              .get(tabType)
              .set(
                field.rowHeaderName,
                Number(totalMap.get(tabType).get(field.rowHeaderName)) +
                  Number(field.value)
              );
          } else {
            totalMap.get(tabType).set(field.rowHeaderName, Number(field.value));
          }
        });
      });
    } else {
      tab.sections.forEach(section => {
        section.fieldDetails.forEach(field => {
          if (field.columnHeaderName === 'Totals') {
            field.value = String(
              Number(totalMap.get('Actual Total').get(field.rowHeaderName)) +
                Number(totalMap.get('Forecast Total').get(field.rowHeaderName))
            );
          } else if (
            field.columnHeaderName === 'Actual Total' ||
            field.columnHeaderName === 'Forecast Total'
          ) {
            field.value = String(
              Number(
                totalMap.get(field.columnHeaderName).get(field.rowHeaderName)
              )
            );
          }
        });
      });
    }
  });
  return state;
};

const annualUpdateFieldsOfTotalTab = (
  state,
  effectiveValue,
  annualTotalTabIndexs,
  annualNegativeIndexs = []
) => {
  const totalTab = state.form.tabs.length;
  if (totalTab < 4) {
    return state;
  }

  return {
    ...state,
    form: {
      ...state.form,
      tabs: state.form.tabs.map((tab, tabIndex) => {
        if (tabIndex < totalTab - 1) {
          return tab;
        }
        return {
          ...tab,
          sections: tab.sections.map(section => {
            return {
              ...section,
              fieldDetails: section.fieldDetails.map((field, fieldIndex) => {
                if (
                  annualTotalTabIndexs.length > 0 &&
                  annualTotalTabIndexs.includes(fieldIndex)
                ) {
                  return {
                    ...field,
                    value: String(Number(field.value) + effectiveValue)
                  };
                }
                if (
                  slugToIDs[state.form.formId] ===
                  'annual-income-statement-operating-expenses-and-net-income'
                ) {
                  if (
                    annualNegativeIndexs.length > 0 &&
                    annualNegativeIndexs.includes(fieldIndex)
                  ) {
                    return {
                      ...field,
                      value: String(Number(field.value) - effectiveValue)
                    };
                  }
                }
                return field;
              })
            };
          })
        };
      })
    }
  };
};

const updateCreditLossesAndExpense = (state, payload) => {
  const { value, sectionIndex, valIndex } = payload;
  const originalValue =
    state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
      valIndex
    ].value;

  const nRows = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].rowHeaders
  ).length;
  const nCols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;
  const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const numOriginalValue = Number.isNaN(Number(originalValue))
    ? 0
    : Number(originalValue);

  let effectiveValue = numValue - numOriginalValue;
  const totalIndexs = [];
  const annualTotalTabIndexs = [];
  if (valIndex <= 6 * nCols + (valIndex % nCols)) {
    if (valIndex === 5 * nCols + (valIndex % nCols)) {
      totalIndexs.push(6 * nCols + (valIndex % nCols));
      annualTotalTabIndexs.push(6);
      annualTotalTabIndexs.push(5);
    } else {
      if (valIndex === 3 * nCols + (valIndex % nCols)) {
        effectiveValue = -effectiveValue;
      }
      totalIndexs.push(4 * nCols + (valIndex % nCols));
      totalIndexs.push(6 * nCols + (valIndex % nCols));
      annualTotalTabIndexs.push(parseInt(valIndex / nCols));
      annualTotalTabIndexs.push(4);
      annualTotalTabIndexs.push(6);
    }
  } else {
    totalIndexs.push((nRows - 1) * nCols + (valIndex % nCols));
    annualTotalTabIndexs.push(parseInt(valIndex / nCols));
    annualTotalTabIndexs.push(nRows - 1);
  }

  state = updateTabFields(state, payload, effectiveValue, totalIndexs);
  if (
    slugToIDs[state.form.formId] ===
    'annual-income-statement-credit-losses-and-expenses'
  ) {
    return annualUpdateFieldsOfTotalTab(
      state,
      effectiveValue,
      annualTotalTabIndexs
    );
  }
  return updateFieldsOfTotalTab(state);
};

const updateOperatingExpensesAndNetIncome = (state, payload) => {
  const { value, sectionIndex, valIndex } = payload;
  const originalValue =
    state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
      valIndex
    ].value;
  const nRows = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].rowHeaders
  ).length;
  const nCols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;
  const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const numOriginalValue = Number.isNaN(Number(originalValue))
    ? 0
    : Number(originalValue);
  const effectiveValue = numValue - numOriginalValue;
  const totalIndexs = [];
  const negativeIndexs = [];
  const annualTotalTabIndexs = [];
  const annualNegativeIndexs = [];
  if (valIndex <= 2 * nCols + (valIndex % nCols)) {
    totalIndexs.push(3 * nCols + (valIndex % nCols));
    totalIndexs.push(9 * nCols + (valIndex % nCols));
    negativeIndexs.push(10 * nCols + (valIndex % nCols));
    negativeIndexs.push(13 * nCols + (valIndex % nCols));
    negativeIndexs.push((nRows - 1) * nCols + (valIndex % nCols));
    annualTotalTabIndexs.push(parseInt(valIndex / nCols));
    annualTotalTabIndexs.push(3);
    annualTotalTabIndexs.push(9);
    annualNegativeIndexs.push(10);
    annualNegativeIndexs.push(13);
    annualNegativeIndexs.push(nRows - 1);
  } else if (valIndex <= 7 * nCols + (valIndex % nCols)) {
    totalIndexs.push(8 * nCols + (valIndex % nCols));
    totalIndexs.push(9 * nCols + (valIndex % nCols));
    negativeIndexs.push(10 * nCols + (valIndex % nCols));
    negativeIndexs.push(13 * nCols + (valIndex % nCols));
    negativeIndexs.push((nRows - 1) * nCols + (valIndex % nCols));
    annualTotalTabIndexs.push(parseInt(valIndex / nCols));
    annualTotalTabIndexs.push(8);
    annualTotalTabIndexs.push(9);
    annualNegativeIndexs.push(10);
    annualNegativeIndexs.push(13);
    annualNegativeIndexs.push(nRows - 1);
  } else if (valIndex <= 12 * nCols + (valIndex % nCols)) {
    totalIndexs.push(13 * nCols + (valIndex % nCols));
    totalIndexs.push((nRows - 1) * nCols + (valIndex % nCols));
    annualTotalTabIndexs.push(parseInt(valIndex / nCols));
    annualTotalTabIndexs.push(13);
    annualTotalTabIndexs.push(nRows - 1);
  } else {
    annualTotalTabIndexs.push(parseInt(valIndex / nCols));
    negativeIndexs.push((nRows - 1) * nCols + (valIndex % nCols));
    annualNegativeIndexs.push(nRows - 1);
  }
  state = updateTabFields(
    state,
    payload,
    effectiveValue,
    totalIndexs,
    negativeIndexs
  );
  if (
    slugToIDs[state.form.formId] ===
    'annual-income-statement-operating-expenses-and-net-income'
  ) {
    return annualUpdateFieldsOfTotalTab(
      state,
      effectiveValue,
      annualTotalTabIndexs,
      annualNegativeIndexs
    );
  }
  return updateFieldsOfTotalTab(state);
};

const updateSupplementalTotalTabFields = (
  state,
  payload,
  effectiveValue,
  totalIndexs,
  sectionIndex
) => {
  return {
    ...state,
    form: {
      ...state.form,
      tabs: state.form.tabs.map((tab, tabIndex) => {
        if (state.form.tabs.length - 1 !== tabIndex) {
          return tab;
        }
        return {
          ...tab,
          hasError: Boolean(payload.error),
          sections: tab.sections.map((section, sIndex) => {
            if (sectionIndex !== sIndex) {
              return section;
            }
            return {
              ...section,
              fieldDetails: section.fieldDetails.map((field, fieldIndex) => {
                if (totalIndexs.has(fieldIndex)) {
                  if (sectionIndex === 0) {
                    return {
                      ...field,
                      value: String(totalIndexs.get(fieldIndex))
                    };
                  }
                  return {
                    ...field,
                    value: String(Number(field.value) + Number(effectiveValue))
                  };
                }
                return field;
              })
            };
          })
        };
      })
    }
  };
};

const updateAnnualSupplementalTotalTabFields = (
  state,
  payload,
  effectiveVal,
  totalIndexs,
  curRow
) => {
  return {
    ...state,
    form: {
      ...state.form,
      tabs: state.form.tabs.map((tab, tabIndex) => {
        if (state.form.tabs.length - 1 !== tabIndex) {
          return tab;
        }
        return {
          ...tab,
          hasError: Boolean(payload.error),
          sections: tab.sections.map((section, sIndex) => {
            return {
              ...section,
              fieldDetails: section.fieldDetails.map((field, fieldIndex) => {
                if (totalIndexs.has(fieldIndex)) {
                  if (curRow === 0) {
                    return {
                      ...field,
                      value: String(
                        parseFloat(
                          Number(field.value) + Number(effectiveVal)
                        ).toFixed(3)
                      )
                    };
                  }
                  if (curRow > 0 && curRow < 6) {
                    return {
                      ...field,
                      value: String(
                        parseFloat(
                          Number(field.value) + Number(effectiveVal)
                        ).toFixed(2)
                      )
                    };
                  }
                  return {
                    ...field,
                    value: String(Number(field.value) + Number(effectiveVal))
                  };
                }
                return field;
              })
            };
          })
        };
      })
    }
  };
};

const updateSupplementalInformation = (state, payload) => {
  const { value, sectionIndex, valIndex } = payload;
  const originalValue =
    state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
      valIndex
    ].value;
  const tabType = state.form.tabs[state.activeTab].actual
    ? 'Actual Total'
    : 'Forecast Total';
  let actualTabsCount = 0;
  state.form.tabs.map((tab, index) => {
    if (tab.actual) {
      actualTabsCount = Number(index) + 1;
    }
  });
  const nCols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;
  const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const numOriginalValue = Number.isNaN(Number(originalValue))
    ? 0
    : Number(originalValue);

  const effectiveValue = numValue - numOriginalValue;
  const totalIndexs = new Map();
  let numOfMonths = Number(actualTabsCount) * 3;
  const totalMonths = numOfMonths === 12 ? 15 : 12;
  const curRow = parseInt(valIndex / nCols);
  if (tabType === 'Actual Total') {
    totalIndexs.set(curRow * nCols, effectiveValue);
  } else {
    numOfMonths = numOfMonths === 12 ? 3 : 12 - numOfMonths;
    totalIndexs.set(1 + curRow * nCols, effectiveValue);
  }
  totalIndexs.set(2 + curRow * nCols, effectiveValue);
  // Setting the value to field
  state = defaultFieldUpdater(state, payload);
  if (sectionIndex === 0) {
    const totalTab = state.form.tabs.length;
    const totalMap = new Map();
    totalMap.set('Actual Total', new Map());
    totalMap.set('Forecast Total', new Map());
    state.form.tabs.forEach(tab => {
      if (tab.tabIdentifier[1] < totalTab) {
        const type =
          Boolean(tab.actual) || Boolean(tab.submitted)
            ? 'Actual Total'
            : 'Forecast Total';
        tab.sections.map((section, sIndex) => {
          if (sIndex === sectionIndex) {
            section.fieldDetails.map((field, fieldIndex) => {
              if (parseInt(fieldIndex / nCols) === curRow) {
                if (totalMap.get(type).has(curRow)) {
                  totalMap
                    .get(type)
                    .set(
                      curRow,
                      Number(totalMap.get(type).get(curRow)) +
                        Number(field.value)
                    );
                } else {
                  totalMap.get(type).set(curRow, Number(field.value));
                }
              }
            });
          }
        });
      }
    });
    let value = parseFloat(
      Math.round((totalMap.get(tabType).get(curRow) / numOfMonths) * 100) / 100
    ).toFixed(2);
    const total =
      Number(totalMap.get('Actual Total').get(curRow)) +
      Number(totalMap.get('Forecast Total').get(curRow));
    let totalValue = parseFloat(
      Math.round((total / totalMonths) * 100) / 100
    ).toFixed(2);
    if (curRow === 6) {
      value = parseFloat(value).toFixed(0);
      totalValue = parseFloat(totalValue).toFixed(0);
    }
    if (tabType === 'Actual Total') {
      totalIndexs.set(curRow * nCols, value);
    } else {
      totalIndexs.set(1 + curRow * nCols, value);
    }
    totalIndexs.set(2 + curRow * nCols, totalValue);
  }

  return updateSupplementalTotalTabFields(
    state,
    payload,
    effectiveValue,
    totalIndexs,
    sectionIndex
  );
};

const updateAnnualSupplementalInformation = (state, payload) => {
  const { value, sectionIndex, valIndex } = payload;
  const originalValue =
    state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
      valIndex
    ].value;
  let actualTabsCount = 0;
  state.form.tabs.map((tab, index) => {
    if (tab.actual) {
      actualTabsCount = Number(index) + 1;
    }
  });
  const nCols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;
  const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const numOriginalValue = Number.isNaN(Number(originalValue))
    ? 0
    : Number(originalValue);

  const effectiveValue = numValue - numOriginalValue;
  const totalIndexs = new Map();
  const numOfMonths = Number(actualTabsCount) * 3;
  const totalMonths = numOfMonths === 12 ? 15 : 12;
  const curRow = parseInt(valIndex / nCols);

  state = defaultFieldUpdater(state, payload);

  let effectiveVal = effectiveValue;
  if (curRow === 0) {
    effectiveVal = parseFloat(
      Math.round((effectiveValue / totalMonths) * 1000) / 1000
    ).toFixed(3);
    totalIndexs.set(curRow * 2, effectiveVal);
  } else if (curRow > 0 && curRow < 6) {
    effectiveVal = parseFloat(
      Math.round((effectiveValue / totalMonths) * 100) / 100
    ).toFixed(2);
    totalIndexs.set(curRow * 2, effectiveVal);
  } else if (curRow === 6) {
    effectiveVal = parseFloat(
      Math.round((effectiveValue / totalMonths) * 100) / 100
    ).toFixed(0);
    totalIndexs.set(curRow * 2, effectiveVal);
  } else {
    totalIndexs.set(curRow * 2 + 1, effectiveVal);
  }

  return updateAnnualSupplementalTotalTabFields(
    state,
    payload,
    effectiveVal,
    totalIndexs,
    curRow
  );
};

const updateBalanceSheetShedulersEOPandBOPTabFields = (
  state,
  payload,
  effectiveValue,
  isNegative,
  totalIndexs,
  sectionIndex
) => {
  return {
    ...state,
    form: {
      ...state.form,
      tabs: state.form.tabs.map((tab, tabIndex) => {
        if (!totalIndexs.has(tabIndex)) {
          return tab;
        }
        return {
          ...tab,
          hasError: Boolean(payload.error),
          sections: tab.sections.map((section, sIndex) => {
            if (sectionIndex !== sIndex) {
              return section;
            }
            return {
              ...section,
              fieldDetails: section.fieldDetails.map((field, fieldIndex) => {
                if (
                  Boolean(isNegative) &&
                  tabIndex === state.form.tabs.length - 1
                ) {
                  if (totalIndexs.get('negative').includes(fieldIndex)) {
                    return {
                      ...field,
                      value: String(
                        Number(field.value) - Number(effectiveValue)
                      )
                    };
                  }
                }
                if (totalIndexs.get(tabIndex).includes(fieldIndex)) {
                  return {
                    ...field,
                    value: String(Number(field.value) + Number(effectiveValue))
                  };
                }
                return field;
              })
            };
          })
        };
      })
    }
  };
};

const updateBalanceSheetScheduleForms = (state, payload) => {
  const { value, sectionIndex, valIndex } = payload;
  const curTab = state.activeTab;
  const originalValue =
    state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
      valIndex
    ].value;
  const nRows = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].rowHeaders
  ).length;
  const nCols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;
  const curRow = parseInt(valIndex / nCols);
  const curCol = valIndex - parseInt(valIndex / nCols) * nCols;

  const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const numOriginalValue = Number.isNaN(Number(originalValue))
    ? 0
    : Number(originalValue);
  let effectiveValue = numValue - numOriginalValue;
  const totalIndexs = new Map();
  const annualtotalIndexs = new Map();
  const totalCurTabIndex = [];
  const annualtotalCurTabIndex = [];
  const totalTabIndexs = [];
  const annualtotalTabIndexs = [];
  const totalIndex = [];
  const annualtotalIndex = [];
  const negativeIndex = [];
  const annualnegativeIndex = [];
  let isNegative = false;
  if (sectionIndex === 0 && curRow === 2) {
    effectiveValue = -effectiveValue;
    isNegative = true;
  }
  if (sectionIndex === 1 && (curRow === 4 || curRow === 6)) {
    effectiveValue = -effectiveValue;
    isNegative = true;
  }
  if (sectionIndex === 2 && (curRow === 2 || curRow === 3)) {
    effectiveValue = -effectiveValue;
    isNegative = true;
  }
  for (let index = 0; index < nCols; index++) {
    totalIndex.push(index);
    totalIndex.push((nRows - 1) * nCols + index);
    annualtotalIndex.push(index);
    annualtotalIndex.push((nRows - 1) * nCols + index);
  }
  if (isNegative) {
    negativeIndex.push(curRow * nCols + 2);
    annualnegativeIndex.push(curRow);
  } else {
    totalTabIndexs.push(curRow * nCols + 2);
    annualtotalTabIndexs.push(curRow);
  }
  if (state.form.tabs[state.activeTab].actual) {
    if (isNegative) {
      negativeIndex.push(curRow * nCols);
      annualnegativeIndex.push(curRow);
    } else {
      totalTabIndexs.push(curRow * nCols);
      annualtotalTabIndexs.push(curRow);
    }
    totalTabIndexs.push(1);
    totalTabIndexs.push((nRows - 1) * nCols);
    totalTabIndexs.push((nRows - 1) * nCols + 1);
    totalTabIndexs.push((nRows - 1) * nCols + 2);
    annualtotalTabIndexs.push(1);
    annualtotalTabIndexs.push(nRows - 1);
  } else {
    if (isNegative) {
      negativeIndex.push(curRow * nCols + 1);
      annualnegativeIndex.push(curRow);
    } else {
      totalTabIndexs.push(curRow * nCols + 1);
      annualtotalTabIndexs.push(curRow);
    }
    totalTabIndexs.push((nRows - 1) * nCols + 1);
    totalTabIndexs.push((nRows - 1) * nCols + 2);
    annualtotalTabIndexs.push(nRows - 1);
  }
  if (parseInt(curCol) === 0) {
    totalCurTabIndex.push(1);
    totalCurTabIndex.push(2);
    totalCurTabIndex.push((nRows - 1) * nCols);
    totalCurTabIndex.push((nRows - 1) * nCols + 1);
    totalCurTabIndex.push((nRows - 1) * nCols + 2);
    annualtotalCurTabIndex.push(1);
    annualtotalCurTabIndex.push(2);
    annualtotalCurTabIndex.push((nRows - 1) * nCols);
    annualtotalCurTabIndex.push((nRows - 1) * nCols + 1);
    annualtotalCurTabIndex.push((nRows - 1) * nCols + 2);
  } else if (parseInt(curCol) === 1) {
    totalCurTabIndex.push(2);
    totalCurTabIndex.push((nRows - 1) * nCols + 1);
    totalCurTabIndex.push((nRows - 1) * nCols + 2);
    annualtotalCurTabIndex.push(2);
    annualtotalCurTabIndex.push((nRows - 1) * nCols + 1);
    annualtotalCurTabIndex.push((nRows - 1) * nCols + 2);
  } else {
    totalCurTabIndex.push((nRows - 1) * nCols + 2);
    annualtotalCurTabIndex.push((nRows - 1) * nCols + 2);
  }
  state.form.tabs.map((tab, tabIndex) => {
    if (tabIndex === curTab) {
      totalIndexs.set(curTab, totalCurTabIndex);
      annualtotalIndexs.set(curTab, annualtotalCurTabIndex);
    } else if (tabIndex > curTab) {
      if (tabIndex === state.form.tabs.length - 1) {
        totalIndexs.set(tabIndex, totalTabIndexs);
        annualtotalIndexs.set(tabIndex, annualtotalTabIndexs);
      } else {
        totalIndexs.set(tabIndex, totalIndex);
        annualtotalIndexs.set(tabIndex, annualtotalIndex);
      }
    }
  });
  if (isNegative) {
    totalIndexs.set('negative', negativeIndex);
    annualtotalIndexs.set('negative', annualnegativeIndex);
  }
  state = defaultFieldUpdater(state, payload);
  if (
    slugToIDs[state.form.formId] ===
    'annual-balance-sheet-schedule-assets-and-liabilities'
  ) {
    return updateBalanceSheetShedulersEOPandBOPTabFields(
      state,
      payload,
      effectiveValue,
      isNegative,
      annualtotalIndexs,
      sectionIndex
    );
  }
  return updateBalanceSheetShedulersEOPandBOPTabFields(
    state,
    payload,
    effectiveValue,
    isNegative,
    totalIndexs,
    sectionIndex
  );
};

const updateBalanceSheetScheduleShareHolderEquity = (state, payload) => {
  const { value, sectionIndex, valIndex } = payload;
  const curTab = state.activeTab;
  const originalValue =
    state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
      valIndex
    ].value;
  const nRows = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].rowHeaders
  ).length;
  const nCols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;
  const curRow = parseInt(valIndex / nCols);
  const curCol = valIndex - parseInt(valIndex / nCols) * nCols;

  const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const numOriginalValue = Number.isNaN(Number(originalValue))
    ? 0
    : Number(originalValue);
  let effectiveValue = numValue - numOriginalValue;
  const totalIndexs = new Map();
  const annualtotalIndexs = new Map();
  const totalCurTabIndex = [];
  const annualtotalCurTabIndex = [];
  const totalTabIndexs = [];
  const annualtotalTabIndexs = [];
  const totalIndex = [];
  const annualtotalIndex = [];
  const negativeIndex = [];
  const annualnegativeIndex = [];
  let isNegative = false;
  if ([0, 2, 4, 5].includes(sectionIndex) && curRow === 2) {
    effectiveValue = -effectiveValue;
    isNegative = true;
  }
  if (
    (sectionIndex === 1 || sectionIndex === 3) &&
    (curRow === 2 || curRow === 3)
  ) {
    effectiveValue = -effectiveValue;
    isNegative = true;
  }
  if (sectionIndex === 6 && (curRow === 1 || curRow === 3)) {
    effectiveValue = -effectiveValue;
    isNegative = true;
  }
  for (let index = 0; index < nCols; index++) {
    totalIndex.push(index);
    totalIndex.push((nRows - 1) * nCols + index);
    annualtotalIndex.push(index);
    annualtotalIndex.push((nRows - 1) * nCols + index);
  }
  if (isNegative) {
    negativeIndex.push(curRow * nCols + 2);
    annualnegativeIndex.push(curRow);
  } else {
    totalTabIndexs.push(curRow * nCols + 2);
    annualtotalTabIndexs.push(curRow);
  }
  if (state.form.tabs[state.activeTab].actual) {
    if (isNegative) {
      negativeIndex.push(curRow * nCols);
      annualnegativeIndex.push(curRow);
    } else {
      totalTabIndexs.push(curRow * nCols);
      annualtotalTabIndexs.push(curRow);
    }
    totalTabIndexs.push(1);
    totalTabIndexs.push((nRows - 1) * nCols);
    totalTabIndexs.push((nRows - 1) * nCols + 1);
    totalTabIndexs.push((nRows - 1) * nCols + 2);
    annualtotalTabIndexs.push(1);
    annualtotalTabIndexs.push(nRows - 1);
  } else {
    if (isNegative) {
      negativeIndex.push(curRow * nCols + 1);
      annualnegativeIndex.push(curRow);
    } else {
      totalTabIndexs.push(curRow * nCols + 1);
      annualtotalTabIndexs.push(curRow);
    }
    totalTabIndexs.push((nRows - 1) * nCols + 1);
    totalTabIndexs.push((nRows - 1) * nCols + 2);
    annualtotalTabIndexs.push(nRows - 1);
  }
  if (parseInt(curCol) === 0) {
    totalCurTabIndex.push(1);
    totalCurTabIndex.push(2);
    totalCurTabIndex.push((nRows - 1) * nCols);
    totalCurTabIndex.push((nRows - 1) * nCols + 1);
    totalCurTabIndex.push((nRows - 1) * nCols + 2);
    annualtotalCurTabIndex.push(1);
    annualtotalCurTabIndex.push(2);
    annualtotalCurTabIndex.push((nRows - 1) * nCols);
    annualtotalCurTabIndex.push((nRows - 1) * nCols + 1);
    annualtotalCurTabIndex.push((nRows - 1) * nCols + 2);
  } else if (parseInt(curCol) === 1) {
    totalCurTabIndex.push(2);
    totalCurTabIndex.push((nRows - 1) * nCols + 1);
    totalCurTabIndex.push((nRows - 1) * nCols + 2);
    annualtotalCurTabIndex.push(2);
    annualtotalCurTabIndex.push((nRows - 1) * nCols + 1);
    annualtotalCurTabIndex.push((nRows - 1) * nCols + 2);
  } else {
    totalCurTabIndex.push((nRows - 1) * nCols + 2);
    annualtotalCurTabIndex.push((nRows - 1) * nCols + 2);
  }
  state.form.tabs.map((tab, tabIndex) => {
    if (tabIndex === curTab) {
      totalIndexs.set(curTab, totalCurTabIndex);
      annualtotalIndexs.set(curTab, annualtotalCurTabIndex);
    } else if (tabIndex > curTab) {
      if (tabIndex === state.form.tabs.length - 1) {
        totalIndexs.set(tabIndex, totalTabIndexs);
        annualtotalIndexs.set(tabIndex, annualtotalTabIndexs);
      } else {
        totalIndexs.set(tabIndex, totalIndex);
        annualtotalIndexs.set(tabIndex, annualtotalIndex);
      }
    }
  });
  if (isNegative) {
    totalIndexs.set('negative', negativeIndex);
    annualtotalIndexs.set('negative', annualnegativeIndex);
  }
  state = defaultFieldUpdater(state, payload);
  if (
    slugToIDs[state.form.formId] ===
    'annual-balance-sheet-schedule-shareholders-equity'
  ) {
    return updateBalanceSheetShedulersEOPandBOPTabFields(
      state,
      payload,
      effectiveValue,
      isNegative,
      annualtotalIndexs,
      sectionIndex
    );
  }
  return updateBalanceSheetShedulersEOPandBOPTabFields(
    state,
    payload,
    effectiveValue,
    isNegative,
    totalIndexs,
    sectionIndex
  );
};

const fetchBalanceSheetTotalIndexs = (
  state,
  curRow,
  nRows,
  nCols,
  valIndex
) => {
  let totalIndexs = [(nRows - 1) * nCols + (valIndex % nCols)];
  switch (slugToIDs[state.form.formId]) {
    case 'balance-sheet-assets':
    case annualBalanceSheetAssets:
      if (curRow < 10) {
        totalIndexs[1] = 10 * nCols + (valIndex % nCols);
      }
      break;
    case 'balance-sheet-liabilities':
    case annualBalanceSheetLiabilities:
      if (curRow < 9) {
        totalIndexs[1] = 9 * nCols + (valIndex % nCols);
      }
      break;
    case 'balance-sheet-shareholders-equity':
      if (curRow >= 15 && curRow < 20) {
        totalIndexs[0] = 20 * nCols + (valIndex % nCols);
      }
      if (curRow < 15) {
        totalIndexs = [];
      }
      break;
    case annualBalanceSheetShareholdersEquity:
      if (curRow >= 10 && curRow < 19) {
        totalIndexs[0] = 19 * nCols + (valIndex % nCols);
      }
      break;
  }
  return totalIndexs;
};

const updateBalanceSheetFields = (state, payload) => {
  const { value, sectionIndex, valIndex } = payload;
  const formType = state.tempFormsListNav.formType;
  const id = state.form.formId;
  const originalValue =
    state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
      valIndex
    ].value;
  const nRows = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].rowHeaders
  ).length;
  const nCols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;
  const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const numOriginalValue = Number.isNaN(Number(originalValue))
    ? 0
    : Number(originalValue);
  const curRow = parseInt(valIndex / nCols);
  const effectiveValue = numValue - numOriginalValue;
  const totalIndexs = fetchBalanceSheetTotalIndexs(
    state,
    curRow,
    nRows,
    nCols,
    valIndex
  );
  if (formType === 'annuallyDataForms') {
    state = updateTabFields(state, payload, effectiveValue, totalIndexs);
    const annualTotalTabIndexs = [];
    switch (slugToIDs[id]) {
      case annualBalanceSheetAssets:
        if (valIndex < 10 * nCols + (valIndex % nCols)) {
          annualTotalTabIndexs.push(parseInt(valIndex / nCols));
          annualTotalTabIndexs.push(10);
          annualTotalTabIndexs.push(nRows - 1);
          break;
        }
        annualTotalTabIndexs.push(parseInt(valIndex / nCols));
        annualTotalTabIndexs.push(nRows - 1);
        break;
      case annualBalanceSheetLiabilities:
        if (valIndex < 9 * nCols + (valIndex % nCols)) {
          annualTotalTabIndexs.push(parseInt(valIndex / nCols));
          annualTotalTabIndexs.push(9);
          annualTotalTabIndexs.push(nRows - 1);
          break;
        }
        annualTotalTabIndexs.push(parseInt(valIndex / nCols));
        annualTotalTabIndexs.push(nRows - 1);
        break;
      case annualBalanceSheetShareholdersEquity:
        if (
          valIndex >= 10 * nCols + (valIndex % nCols) &&
          valIndex < 19 * nCols + (valIndex % nCols)
        ) {
          annualTotalTabIndexs.push(parseInt(valIndex / nCols));
          annualTotalTabIndexs.push(19);
          break;
        }
        annualTotalTabIndexs.push(parseInt(valIndex / nCols));
        annualTotalTabIndexs.push(nRows - 1);
        break;
      default:
        break;
    }
    return annualUpdateFieldsOfTotalTab(
      state,
      effectiveValue,
      annualTotalTabIndexs
    );
  }
  return updateTabFields(state, payload, effectiveValue, totalIndexs);
};

const updateStatisticalTabFields = (
  state,
  payload,
  effectiveValue,
  totalIndexs,
  flag
) => {
  const required = [0, 1, 2, 6, 7, 8];
  const notRequired = [0, 1, 2];

  const checkField = (field, fieldIndex, sIndex) => {
    if (payload.valIndex !== fieldIndex && !totalIndexs.includes(fieldIndex)) {
      return {
        ifCondition: true,
        result: field
      };
    }
    if (sIndex === payload.sectionIndex && payload.valIndex === fieldIndex) {
      return {
        ifCondition: true,
        result: {
          ...field,
          value: payload.value,
          error: payload.error
        }
      };
    }
    return {
      ifCondition: false
    };
  };

  return {
    ...state,
    form: {
      ...state.form,
      tabs: state.form.tabs.map((tab, tabIndex) => {
        if (state.activeTab !== tabIndex) {
          return tab;
        }
        return {
          ...tab,
          hasError: Boolean(payload.error),
          sections: tab.sections.map((section, sIndex) => {
            switch (
              `${sIndex}${flag}` // Switch-case is used here to improve cognitive complexity
            ) {
              case '22':
              case '21':
                return {
                  ...section,
                  fieldDetails: section.fieldDetails.map(
                    (field, fieldIndex) => {
                      const thingsToCheck = checkField(
                        field,
                        fieldIndex,
                        sIndex
                      );
                      switch (
                        `${thingsToCheck.ifCondition}${notRequired.includes(
                          fieldIndex
                        )}`
                      ) {
                        case 'truetrue':
                        case 'truefalse':
                          return thingsToCheck.result;
                        case 'falsetrue':
                          return field;
                        default:
                          return {
                            ...field,
                            value: String(Number(field.value) + effectiveValue)
                          };
                      }
                    }
                  )
                };
              case '31':
                return {
                  ...section,
                  fieldDetails: section.fieldDetails.map(
                    (field, fieldIndex) => {
                      const thingsToCheck = checkField(
                        field,
                        fieldIndex,
                        sIndex
                      );
                      switch (
                        `${required.includes(fieldIndex)}${
                          thingsToCheck.ifCondition
                        }`
                      ) {
                        case 'truefalse':
                          return {
                            ...field,
                            value: String(Number(field.value) + effectiveValue)
                          };
                        case 'truetrue':
                          return thingsToCheck.result;
                        default:
                          return field;
                      }
                    }
                  )
                };
              case '30':
                return {
                  ...section,
                  fieldDetails: section.fieldDetails.map(
                    (field, fieldIndex) => {
                      const thingsToCheck = checkField(
                        field,
                        fieldIndex,
                        sIndex
                      );
                      if (thingsToCheck.ifCondition === true) {
                        return thingsToCheck.result;
                      }
                      return {
                        ...field,
                        value: String(Number(field.value) + effectiveValue)
                      };
                    }
                  )
                };
              default:
                return section;
            }
          })
        };
      })
    }
  };
};

const updateFieldsOfStatisticalTotalTab = (
  state,
  effectiveValue,
  flag,
  annualTotalTabIndexs = []
) => {
  const totalTab = state.form.tabs.length;
  const required = [0, 2];
  if (totalTab < 4) {
    return state;
  }

  const getTabSection = (section, require = false) => ({
    ...section,
    fieldDetails: section.fieldDetails.map((field, fieldIndex) => {
      if (
        (!require || required.includes(fieldIndex)) &&
        annualTotalTabIndexs.includes(fieldIndex)
      ) {
        return {
          ...field,
          value: String(Number(field.value) + effectiveValue)
        };
      }
      return field;
    })
  });

  return {
    ...state,
    form: {
      ...state.form,
      tabs: state.form.tabs.map((tab, tabIndex) => {
        if (tabIndex < totalTab - 1) {
          return tab;
        }
        return {
          ...tab,
          sections: tab.sections.map((section, sIndex) => {
            switch (
              `${sIndex}${flag}` // Switch-case is used here to improve cognitive complexity
            ) {
              case '22':
              case '21':
                return getTabSection(section);
              case '31':
                annualTotalTabIndexs.push(0);
                return getTabSection(section, true);
              case '30':
                return getTabSection(section);
              default:
                return section;
            }
          })
        };
      })
    }
  };
};

const updateStatisticalSection = (
  nCols,
  curRow,
  curCol,
  nRows,
  effectiveValue,
  state,
  payload
) => {
  const { sectionIndex } = payload;
  const curTab = state.activeTab;
  const annualtotalIndexs = new Map();
  const annualtotalCurTabIndex = [];
  const annualtotalTabIndexs = [];
  const annualtotalIndex = [];
  const annualnegativeIndex = [];
  let isNegative = false;
  for (let index = 0; index < nCols; index++) {
    annualtotalIndex.push(index);
    annualtotalIndex.push((nRows - 1) * nCols + index);
  }
  switch (`${sectionIndex}${curRow}`) {
    case '02':
    case '03':
    case '12':
      effectiveValue = -effectiveValue;
      isNegative = true;
      break;
    default:
      break;
  }
  if (isNegative) {
    annualnegativeIndex.push(curRow);
  } else {
    annualtotalTabIndexs.push(curRow);
  }
  if (state.form.tabs[state.activeTab].actual) {
    annualtotalTabIndexs.push(1);
  }
  annualtotalTabIndexs.push(nRows - 1);
  switch (parseInt(curCol)) {
    case 0:
      annualtotalCurTabIndex.push(1);
      annualtotalCurTabIndex.push(2);
      annualtotalCurTabIndex.push((nRows - 1) * nCols);
      annualtotalCurTabIndex.push((nRows - 1) * nCols + 1);
      annualtotalCurTabIndex.push((nRows - 1) * nCols + 2);
      break;
    case 1:
      annualtotalCurTabIndex.push(2);
      annualtotalCurTabIndex.push((nRows - 1) * nCols + 1);
      annualtotalCurTabIndex.push((nRows - 1) * nCols + 2);
      break;
    default:
      annualtotalCurTabIndex.push((nRows - 1) * nCols + 2);
      break;
  }
  state.form.tabs.map((tab, tabIndex) => {
    switch (true) {
      case tabIndex === curTab:
        annualtotalIndexs.set(curTab, annualtotalCurTabIndex);
        break;
      case tabIndex > curTab:
        if (tabIndex === state.form.tabs.length - 1) {
          annualtotalIndexs.set(tabIndex, annualtotalTabIndexs);
        } else {
          annualtotalIndexs.set(tabIndex, annualtotalIndex);
        }
        break;
      default:
        break;
    }
  });
  if (isNegative) {
    annualtotalIndexs.set('negative', annualnegativeIndex);
  }
  state = defaultFieldUpdater(state, payload);
  return updateBalanceSheetShedulersEOPandBOPTabFields(
    state,
    payload,
    effectiveValue,
    isNegative,
    annualtotalIndexs,
    sectionIndex
  );
};

const updateStatisticalInformation = (state, payload) => {
  const { value, sectionIndex, valIndex } = payload;
  const originalValue =
    state.form.tabs[state.activeTab].sections[sectionIndex].fieldDetails[
      valIndex
    ].value;
  const nRows = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].rowHeaders
  ).length;
  const nCols = Object.keys(
    state.form.tabs[state.activeTab].sections[sectionIndex].columnHeaders
  ).length;
  const curRow = parseInt(valIndex / nCols);
  const curCol = valIndex - parseInt(valIndex / nCols) * nCols;

  const numValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const numOriginalValue = Number.isNaN(Number(originalValue))
    ? 0
    : Number(originalValue);
  const effectiveValue = numValue - numOriginalValue;
  switch (sectionIndex) {
    case 0:
    case 1:
      return updateStatisticalSection(
        nCols,
        curRow,
        curCol,
        nRows,
        effectiveValue,
        state,
        payload
      );
    default:
      const required = [0, 1, 2];
      const totalIndexs = [(nRows - 1) * nCols + (valIndex % nCols)];
      let flag = 0;
      const annualTotalTabIndexs = [];
      if (sectionIndex === 2 && required.includes(valIndex)) {
        totalIndexs[1] = 0 * nCols + (valIndex % nCols);
        flag = 1;
      } else if (sectionIndex === 2 && !required.includes(valIndex)) {
        flag = 2;
      }
      annualTotalTabIndexs.push(parseInt(valIndex / nCols));
      annualTotalTabIndexs.push(2);
      state = updateStatisticalTabFields(
        state,
        payload,
        effectiveValue,
        totalIndexs,
        flag
      );
      return updateFieldsOfStatisticalTotalTab(
        state,
        effectiveValue,
        flag,
        annualTotalTabIndexs
      );
  }
};

const updateFieldHandler = (state, payload) => {
  switch (slugToIDs[state.form.formId]) {
    case 'card-member':
    case 'dpass-aquirer':
    case 'se-activation':
    case 'se-sales-volume':
    case 'agency-volume-transaction-and-discount-revenue':
      return updateCardMember(state, payload);

    case 'royalty-sales-loans':
    case 'se-membership-count':
      return updateSeMembershipCount(state, payload);

    case 'se-discount-revenue':
      return updateSeDiscountRevenue(state, payload);

    case 'card-volume':
      return updateCardVolume(state, payload);

    case 'dpass-franchisee':
      if (state.activeTab === 0) {
        return updateCardMember(state, payload);
      }
      return updateFieldWithColumnTotal(state, payload);

    case 'qr-code':
      return updateQRCode(state, payload);

    case 'intes':
      return updateIntes(state, payload);

    case 'issuer-fraud':
      return updateIssuerFraud(state, payload);

    case 'royalty-sales-volume-fees':
      return updateRoyaltySalesVolume(state, payload);

    case 'royality-additions-exclusions':
      return updateRoyalty(state, payload);

    case 'income-statement-revenue-and-funding':
    case 'annual-income-statement-revenue-and-funding':
      return updateRevenueFunding(state, payload);

    case 'income-statement-credit-losses-and-expenses':
    case 'annual-income-statement-credit-losses-and-expenses':
      return updateCreditLossesAndExpense(state, payload);

    case 'income-statement-operating-expenses-and-net-income':
    case 'annual-income-statement-operating-expenses-and-net-income':
      return updateOperatingExpensesAndNetIncome(state, payload);

    case 'income-statement-supplemental-information':
      return updateSupplementalInformation(state, payload);

    case 'annual-income-statement-supplemental-information':
      return updateAnnualSupplementalInformation(state, payload);

    case 'balance-sheet-schedule-assets-and-liabilities':
    case 'annual-balance-sheet-schedule-assets-and-liabilities':
      return updateBalanceSheetScheduleForms(state, payload);

    case 'balance-sheet-schedule-shareholders-equity':
    case 'annual-balance-sheet-schedule-shareholders-equity':
      return updateBalanceSheetScheduleShareHolderEquity(state, payload);

    case 'balance-sheet-assets':
    case annualBalanceSheetAssets:
      return updateBalanceSheetFields(state, payload);

    case 'balance-sheet-liabilities':
    case annualBalanceSheetLiabilities:
      return updateBalanceSheetFields(state, payload);

    case 'balance-sheet-shareholders-equity':
    case annualBalanceSheetShareholdersEquity:
      return updateBalanceSheetFields(state, payload);

    case annualStatisticalInformation:
      return updateStatisticalInformation(state, payload);

    default:
      return defaultFieldUpdater(state, payload);
  }
};

const updateDynamicFormRow = (state, payload) => ({
  ...state,
  form: {
    ...state.form,
    tabs: state.form.tabs.map((tab, tabIndex) => {
      if (state.activeTab !== tabIndex) {
        return tab;
      }
      return {
        ...tab,
        sections: tab.sections.map((section, sectionIndex) => {
          return {
            ...section,
            fieldDetails: [...section.fieldDetails, payload]
          };
        })
      };
    })
  }
});

const updateIntesFormReducer = (state, payload) => ({
  ...state,
  form: {
    ...state.form,
    fieldDetails: [...state.form.fieldDetails, payload.updatedFields],
    total: payload.total
  }
});

const updateIssuerFraudFormReducer = (state, payload) => ({
  ...state,
  form: {
    ...state.form,
    tableData: [...state.form.tableData, payload.updatedFields],
    total: payload.total
  }
});

const updateIntesFieldFromApiReducer = (state, payload) => {
  const { updatedData, index } = payload;
  return {
    ...state,
    form: {
      ...state.form,
      fieldDetails: state.form.fieldDetails.map((field, idx) => {
        if (idx === index) {
          return updatedData;
        }
        return field;
      })
    }
  };
};

const deleteIntesFormRowReducer = (state, payload) => ({
  ...state,
  form: {
    ...state.form,
    fieldDetails: state.form.fieldDetails.filter(
      (field, idx) => idx !== payload.index
    ),
    total: payload.total
  }
});

const deleteIssuerFraudFormRowReducer = (state, payload) => ({
  ...state,
  form: {
    ...state.form,
    tableData: state.form.tableData.filter(
      (field, idx) => idx !== payload.index
    ),
    total: payload.total
  }
});

const dataFormsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_DATA_FORM:
      return {
        ...state,
        form: action.payload.form,
        tempForm: action.payload.form,
        tempFormsListNav: action.payload.tempFormsListNav,
        activeTab: action.tab === undefined ? state.activeTab : action.tab
      };

    case actions.CHANGE_TAB:
      return {
        ...state,
        activeTab: action.payload
      };

    case actions.UPDATE_INTES_FIELD:
    case actions.UPDATE_ISSUER_FRAUD_FIELD:
    case actions.UPDATE_ROYALTY_FIELD:
    case actions.UPDATE_FIELD:
      return updateFieldHandler(state, action.payload);

    case actions.UPDATE_ROYALTY_DATA:
      return updateRoyaltyFormReducer(state, action.payload);

    case actions.RESET_DATA_FORMS:
      return {
        ...state,
        form: undefined,
        activeTab: undefined
      };

    case actions.CLEAR_DATA_FORM:
      return clearDataHandler(state);

    case actions.UPDATE_INTES_DATA:
      return updateIntesFormReducer(state, action.payload);
    case actions.UPDATE_ISSUER_FRAUD_DATA:
      return updateIssuerFraudFormReducer(state, action.payload);
    case actions.SHOW_AUTO_SAVE:
      return {
        ...state,
        message: action.payload
      };
    case actions.SHOW_CODE_EXISTS:
      return {
        ...state,
        message: action.payload
      };
    case actions.SHOW_DESC_EXISTS:
      return {
        ...state,
        message: action.payload
      };
    case actions.SHOW_FRAUD_EXISTS:
      return {
        ...state,
        message: action.payload
      };
    case actions.DELETE_INTES_FORM_ROW:
      return deleteIntesFormRowReducer(state, action.payload);
    case actions.DELETE_ISSUER_FRAUD_FORM_ROW:
      return deleteIssuerFraudFormRowReducer(state, action.payload);
    case actions.DELETE_ROYALTY_FORM_ROW:
      return deleteRoyaltyFormRowReducer(state, action.payload);
    case actions.UPDATE_ROYALTY_FIELD_FROM_API:
      return updateRoyaltyFieldFromApiReducer(state, action.payload);
    case actions.UPDATE_INTES_FIELD_FROM_API:
      return updateIntesFieldFromApiReducer(state, action.payload);
    default:
      return state;
  }
};

const updateRoyaltyFormReducer = (state, payload) => {
  const { details, idx, tot } = payload;
  return {
    ...state,
    form: {
      ...state.form,
      sections: state.form.sections.map((section, index) => {
        if (index === idx) {
          return {
            ...section,
            total: tot,
            fieldDetails: [...section.fieldDetails, details]
          };
        }
        return section;
      })
    }
  };
};

const updateRoyaltyFieldFromApiReducer = (state, payload) => {
  const { updatedData, sectionIndex, index, tot } = payload;
  return {
    ...state,
    form: {
      ...state.form,
      sections: state.form.sections.map((section, sectionIdx) => {
        if (sectionIndex === sectionIdx) {
          return {
            ...section,
            total: tot,
            fieldDetails: section.fieldDetails.map((field, idx) => {
              if (idx === index) {
                return updatedData;
              }
              return field;
            })
          };
        }
        return section;
      })
    }
  };
};

const deleteRoyaltyFormRowReducer = (state, payload) => {
  const { secIdx, index, tot } = payload;
  return {
    ...state,
    form: {
      ...state.form,
      sections: state.form.sections.map((section, sectionIdx) => {
        if (sectionIdx === secIdx) {
          return {
            ...section,
            total: tot,
            fieldDetails: section.fieldDetails.filter(
              (field, idx) => idx !== index
            )
          };
        }
        return section;
      })
    }
  };
};

export default dataFormsReducer;
