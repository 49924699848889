import { base as theme } from './base';

export const input = {
  MuiOutlinedInput: {
    root: {
      minWidth: 98,
      fontSize: 15,
      '&$focused': {
        color: theme.palette.secondary.main
      },
      '&$focused $notchedOutline': {
        borderColor: theme.palette.secondary.main,
        borderWidth: 1
      },
      '&:hover $notchedOutline': {
        borderColor: theme.palette.secondary.main
      },

      '&:active $notchedOutline': {
        borderColor: theme.palette.secondary.main
      },
      '&$disabled': {
        backgroundColor: theme.palette.input.disabled
      }
    },

    notchedOutline: {
      borderColor: theme.palette.input.outline,
      borderRadius: 0
    },
    inputMarginDense: {
      padding: 15
    }
  },
  MuiInputLabel: {
    root: {
      fontSize: 15,
      '&$focused': {
        color: theme.palette.secondary.main,
        padding: 0
      }
    },
    formControl: {
      top: -6,
      '&$shrink': {
        top: 0
      }
    },

    asterisk: {
      color: theme.palette.error.main
    }
  },

  MuiInputBase: {
    root: {
      height: 43
    },
    multiline: {
      minHeight: 43,
      height: 'auto'
    }
  },

  MuiFormLabel: {
    asterisk: {
      color: 'red'
    },
    error: {
      color: 'red !important'
    }
  },

  MuiFormControlLabel: {
    root: {
      fontSize: '15px !important',
      margin: '0px 10px 10px 0px !important'
    }
  }
};
