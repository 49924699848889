import React, { MouseEvent, ReactNode } from 'react';
import { Grid, Typography, Paper, Button } from '@material-ui/core';
import './PageSkeleton.css';

interface ButtonProps {
  label: string;
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
}

interface Props {
  title: string;
  headerLeft?: ReactNode;
  headerRight?: ReactNode;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  children: ReactNode;
  container?: object;
}

const PageSkeleton = (props: Props) => {
  const {
    title,
    headerLeft,
    headerRight,
    secondaryButton,
    primaryButton,
    children,
    container
  } = props;

  return (
    <Paper
      elevation={8}
      className='page-skeleton'
      data-test='page-skeleton-component'>
      <Grid container className='header' alignItems='center'>
        <Grid item sm>
          {headerLeft}
        </Grid>
        <Typography color='secondary' variant='h5' className='title'>
          {title}
        </Typography>
        <Grid item sm>
          {headerRight ? (
            headerRight
          ) : (
            <Grid container justify='flex-end'>
              {secondaryButton && secondaryButton.label && (
                <Button
                  variant='outlined'
                  color='secondary'
                  size='small'
                  disabled={secondaryButton.disabled}
                  style={{ marginRight: 15 }}
                  onClick={secondaryButton.onClick}>
                  {secondaryButton.label}
                </Button>
              )}
              {primaryButton && primaryButton.label && (
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  disabled={primaryButton.disabled}
                  onClick={primaryButton.onClick}>
                  {primaryButton.label}
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <div style={{ padding: 30 }} {...container}>
        {children}
      </div>
    </Paper>
  );
};

PageSkeleton.defaultProps = {
  container: {}
};

export default PageSkeleton;
