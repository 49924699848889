import {
  GET_TOTAL_ORGANIZATION_ROLES,
  GET_TOTAL_ORGANIZATION_NAMES,
  IS_USER_EXISTS,
  RESET_IDLE_TIMER,
  SET_SESSION_TIME,
  GET_TOTAL_USER_ROLES
} from './types';

export const initialState = {
  totalOrganizationalRoles: [],
  totalOrganizationalNames: [],
  totalOrganizationalRegions: [],
  totalOrganizationalCountries: [],
  totalOrganizationalTypes: [],
  isUserExists: false,
  isUserProfileValidate: false,
  isResetTimer: false,
  sessionTime: undefined,
  totalUserRoles: undefined
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TOTAL_ORGANIZATION_ROLES:
      return {
        ...state,
        totalOrganizationalRoles: action.payload
      };
    case GET_TOTAL_ORGANIZATION_NAMES:
      return {
        ...state,
        totalOrganizationalNames: action.payload
      };
    case IS_USER_EXISTS:
      return {
        ...state,
        isUserExists: action.payload
      };
    case RESET_IDLE_TIMER:
      return {
        ...state,
        isResetTimer: action.payload
      };
    case SET_SESSION_TIME:
      return {
        ...state,
        sessionTime: action.payload
      };
    case GET_TOTAL_USER_ROLES:
      return {
        ...state,
        totalUserRoles: action.payload
      };
    default:
      return state;
  }
};
