import { franchisesDataActions } from './types';

const initialState = {
  dueDate: '',
  allFranchises: [],
  filteredFranchises: [],
  stats: {}
};

const franchisesDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case franchisesDataActions.SET_FRANCHISES_DATA:
      return {
        ...action.payload,
        filteredFranchises: action.payload.allFranchises.filter(
          franchise =>
            franchise.statusGroup === 'Not Started' ||
            franchise.statusGroup === 'Awaiting Data Entry' ||
            franchise.statusGroup === 'Awaiting submission' ||
            franchise.statusGroup === 'Submitted to DGN' ||
            franchise.statusGroup === 'Unlocked'
        ),
        stats: {
          total: action.payload.allFranchises.length,
          notStarted: action.payload.allFranchises.filter(
            franchise => franchise.statusGroup === 'Not Started'
          ).length,
          awaitingDEAction: action.payload.allFranchises.filter(
            franchise =>
              franchise.statusGroup === 'Awaiting Data Entry' ||
              franchise.statusGroup === 'Unlocked'
          ).length,
          awaitingGMAction: action.payload.allFranchises.filter(
            franchise => franchise.statusGroup === 'Awaiting submission'
          ).length,
          submittedtoDGN: action.payload.allFranchises.filter(
            franchise => franchise.statusGroup === 'Submitted to DGN'
          ).length,

          totalAction: action.payload.allFranchises.filter(
            franchise =>
              franchise.statusGroup === 'Awaiting Data Entry' ||
              franchise.statusGroup === 'Awaiting submission' ||
              franchise.statusGroup === 'Submitted to DGN' ||
              franchise.statusGroup === 'Unlocked' ||
              franchise.statusGroup === 'Not Started'
          ).length
        }
      };

    case franchisesDataActions.FILTER_FRANCHISES:
      return {
        ...state,
        filteredFranchises:
          action.payload.filterBy === 'Not Started'
            ? state.allFranchises.filter(
                franchise => franchise.statusGroup === 'Not Started'
              )
            : action.payload.filterBy === 'Awaiting DE Action'
            ? state.allFranchises.filter(
                franchise =>
                  franchise.statusGroup === 'Awaiting Data Entry' ||
                  franchise.statusGroup === 'Unlocked'
              )
            : action.payload.filterBy === 'Awaiting GM Action'
            ? state.allFranchises.filter(
                franchise => franchise.statusGroup === 'Awaiting submission'
              )
            : action.payload.filterBy === 'Submitted to DGN'
            ? state.allFranchises.filter(
                franchise => franchise.statusGroup === 'Submitted to DGN'
              )
            : action.payload.filterBy === 'Total Action'
            ? state.allFranchises.filter(
                franchise =>
                  franchise.statusGroup === 'Awaiting Data Entry' ||
                  franchise.statusGroup === 'Awaiting submission' ||
                  franchise.statusGroup === 'Submitted to DGN' ||
                  franchise.statusGroup === 'Unlocked' ||
                  franchise.statusGroup === 'Not Started'
              )
            : state.allFranchises
      };

    default:
      return state;
  }
};

export default franchisesDataReducer;
