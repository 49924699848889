import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import {
  UserOrganizationalRolesHeader,
  UserOrganizationalRolesForm,
  UserOrganizationalRolesList
} from '..';

class UserOrganizationalRolesSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtertotalOrganizationalNames: props.totalOrganizationalNames
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevState.filtertotalOrganizationalNames) !==
      JSON.stringify(this.props.totalOrganizationalNames)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        filtertotalOrganizationalNames: this.props.totalOrganizationalNames
      });
    }
  }

  render() {
    const {
      totalOrganizationalNames,
      totalOrganizationalRoles,
      addOrganizationData,
      user,
      manageOrgnizationData,
      pageType,
      roleMap,
      userStatus,
      impersonate,
      activeButtonStatus
    } = this.props;
    const { filtertotalOrganizationalNames } = this.state;
    // Get all orgProfiles
    let arr = [];
    user.orgRoleProfiles.forEach(element => {
      arr = [...arr, ...element.organizationProfiles];
    });

    const filtertotalOrganizationalNamesFinal = filtertotalOrganizationalNames.filter(
      elem =>
        !arr.find(
          ({ organizationProfileId }) => elem.value === organizationProfileId
        )
    );
    const allActiveOrgLength =
      user &&
      user.orgRoleProfiles &&
      user.orgRoleProfiles.reduce((count, orgRole) => {
        const activeOrgs = orgRole.organizationProfiles.filter(org => org);
        return count + activeOrgs.length;
      }, 0);

    return (
      <Grid
        container
        className='user-organization-roles-container'
        data-test='user-org-roles-section-component'>
        <UserOrganizationalRolesHeader />
        {!(roleMap || userStatus === 'I') && !impersonate && (
          <UserOrganizationalRolesForm
            totalOrganizationalNames={filtertotalOrganizationalNamesFinal}
            totalOrganizationalRoles={totalOrganizationalRoles}
            addOrganizationData={addOrganizationData}
            user={user}
            pageType={pageType}
          />
        )}
        <Grid container item justify='center' alignItems='center'>
          <UserOrganizationalRolesList
            pageType={pageType}
            user={user}
            roleMap={roleMap}
            orgRoleProfiles={user.orgRoleProfiles}
            manageOrgnizationData={manageOrgnizationData}
            totalOrganizationalRoles={totalOrganizationalRoles}
            totalOrganizationalNames={totalOrganizationalNames}
            userStatus={userStatus}
            impersonate={impersonate}
            activeButtonStatus={activeButtonStatus}
            allActiveOrgLength={allActiveOrgLength}
          />
        </Grid>
      </Grid>
    );
  }
}

UserOrganizationalRolesSection.propTypes = {
  user: PropTypes.object.isRequired,
  pageType: PropTypes.string.isRequired,
  totalOrganizationalNames: PropTypes.array.isRequired,
  totalOrganizationalRoles: PropTypes.array.isRequired,
  addOrganizationData: PropTypes.func.isRequired,
  manageOrgnizationData: PropTypes.func.isRequired
};

export default UserOrganizationalRolesSection;
