import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import './Impersonate.css';
import ExitIcon from '../../assets/icons/exit.svg';

const ImpersonateUserCancelButton = ({ onClick, userName }) => {
  return (
    <Grid container className='user-cancel-impersonate-button-container'>
      <Grid item className='user-cancel-impersonate-button'>
        <Typography
          color='secondary'
          display='inline'
          className='user-cancel-impersonate-text'>
          Impersonation Mode as {userName}
          <span
            style={{ cursor: 'pointer' }}
            className='user-cancel-impersonate-text'
            onClick={onClick}>
            {' '}
            Exit
            <img src={ExitIcon} alt='Exit' className='exit-icon' />
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ImpersonateUserCancelButton;
