import React, { useState } from 'react';
import { connect } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  MenuList,
  TextField,
  ClickAwayListener,
  InputAdornment
} from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import ErrorTooltip from './ErrorTooltip';
import PropTypes from 'prop-types';

export const Dropdown = props => {
  const fieldProps = { ...props };
  delete fieldProps.formik;
  const {
    name,
    formik: { values, handleChange, touched, errors, status },
    label,
    placeholder,
    disabled,
    highlight
  } = props;

  const [open, setOpen] = useState(false);
  const error =
    touched[name] && Boolean(errors[name] || status[name] || status.root);
  const message = touched[name] && (errors[name] || status[name]);
  return (
    <ClickAwayListener
      data-test='component-Dropdown'
      onClickAway={() => setOpen(false)}>
      <Autocomplete
        data-test='component-autocomplete'
        autoComplete
        open={open}
        value={values[name]}
        error={error}
        ListboxComponent={MenuList}
        getOptionLabel={option =>
          highlight && option && option.label && option.value
            ? `${option.value} - ${option.label}`
            : option.label || placeholder
        }
        renderInput={params => {
          return (
            <>
              <TextField
                required={props.required}
                error={error}
                {...params}
                fullWidth
                label={label}
              />
              <InputAdornment
                position='end'
                style={{ position: 'absolute', right: 25 }}>
                <ErrorTooltip message={message} />
              </InputAdornment>
            </>
          );
        }}
        renderOption={option =>
          highlight ? (
            <div>
              <span style={{ color: highlight ? '#ff6000' : 'inherit' }}>
                {option.value}
              </span>{' '}
              - {option.label}
            </div>
          ) : (
            option.label
          )
        }
        closeIcon={!error && <SearchIcon />}
        // ClearText={error ? 'Search' : ''}
        {...fieldProps}
        onChange={(e, val) => {
          const event = {
            target: {
              name,
              value: val
            }
          };
          handleChange(event);
        }}
        onClick={() => (!disabled ? setOpen(true) : setOpen(false))}
      />
    </ClickAwayListener>
  );
};

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired
};

export default connect(Dropdown);
