/* eslint-disable max-lines-per-function */
import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { HOME_TEXT } from '../../../constants/text';

const SelectFranchiseFormUpload = props => {
  const { values, megaMenuOrganizationNames } = props;
  const months = HOME_TEXT.monthsList.map((month, index) => ({
    label: month,
    value: index < 9 ? '0' + (index + 1) : (index + 1).toString()
  }));

  const getMonthLabel = monthValue => {
    const month = months.find(m => m.value === monthValue);
    return month ? month.label : '';
  };

  return (
    <Grid
      container
      justify='space-between'
      alignItems='center'
      data-test='select-franchise-main-container'>
      <Grid item data-test='select-franchise-container'>
        <form data-test='select-franchise-form'>
          <Grid container alignItems='flex-end' direction='row'>
            <TextField
              required
              disabled
              className='megamenu-manual-data-action'
              id='organization'
              aria-label='organization'
              label='Organization'
              name='organization'
              value={
                megaMenuOrganizationNames.find(
                  org => org.id === values.organization
                )?.label || ''
              }
              style={{ width: 233, background: '#fff', fontWeight: 500 }}
              InputProps={{
                readOnly: true
              }}
              data-test='user-search-combobox'
            />
            <TextField
              required
              disabled
              className='megamenu-manual-data-action'
              id='year'
              label='Year'
              name='year'
              value={values.year || ''}
              style={{ background: '#fff', fontWeight: 500 }}
              InputProps={{
                readOnly: true
              }}
              data-test='select-franchise-form-dropdown-year'
            />
            <TextField
              required
              disabled
              id='month'
              className='megamenu-manual-data-action'
              label='Month'
              name='month'
              value={getMonthLabel(values.month) || ''}
              style={{ background: '#fff', fontWeight: 500 }}
              InputProps={{
                readOnly: true
              }}
              data-test='select-franchise-form-dropdown-month'
            />
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

SelectFranchiseFormUpload.propTypes = {
  values: PropTypes.object.isRequired,
  megaMenuOrganizationNames: PropTypes.array.isRequired
};

export default SelectFranchiseFormUpload;
