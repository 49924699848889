import React, { Component } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow
} from '@material-ui/core';
import ProductLinesRow from './ProductLinesRow';

class ProductLines extends Component {
  handleConsumerChange = (e, id) => {
    const { onProductLineChange } = this.props;
    const { value } = e.target;
    const newValue = value.map(val => ({
      cardProductBandMapId: val.id,
      cardProductName: val.label,
      cardBusinessCode: 'CN',
      cardBrandName: val.brandName
    }));
    onProductLineChange(newValue, id, 'CN');
  };

  handleCommercialChange = (e, id) => {
    const { onProductLineChange } = this.props;
    const { value } = e.target;
    const newValue = value.map(val => ({
      cardProductBandMapId: val.id,
      cardProductName: val.label,
      cardBusinessCode: 'CM',
      cardBrandName: val.brandName
    }));
    onProductLineChange(newValue, id, 'CM');
  };

  filterTransform = (org, type) => {
    return org.productInfo
      .filter(
        product =>
          product.cardBusinessCode === type &&
          product.productOperationCode !== 'D'
      )
      .map(product => ({
        id: product.cardProductBandMapId,
        label: product.cardProductName,
        value: product.cardProductBandMapId,
        brandName: product.cardBrandName
      }));
  };

  render() {
    const {
      orgProfiles,
      products,
      onProductRemoved,
      unSelectProductsAll,
      readOnly,
      setErrors,
      errors,
      userRoles,
      highestRole
    } = this.props;
    if (!orgProfiles.length) {
      return null;
    }
    return (
      <>
        <Box
          bgcolor='secondary.dark'
          color='white'
          data-test='product-lines-text-container'
          style={{ padding: '5px 15px' }}>
          <Typography
            variant='body2'
            className='required'
            data-test='product-lines-text-typog'>
            Product Line
          </Typography>
        </Box>
        <Table data-test='product-lines-table-component'>
          <TableHead
            style={{ background: '#185a7e' }}
            data-test='product-lines-table-header'>
            <TableRow data-test='product-lines-table-row'>
              <TableCell
                style={{ width: '30%' }}
                data-test='product-lines-table-cell'>
                <Typography
                  variant='body2'
                  data-test='product-lines-table-cell-typo'>
                  Organization Profile Name
                </Typography>
              </TableCell>
              <TableCell
                style={{ width: '30%' }}
                data-test='product-lines-table-cell'>
                <Typography
                  variant='body2'
                  data-test='product-lines-table-cell-typo'>
                  Commercial Product
                </Typography>
              </TableCell>
              <TableCell
                style={{ width: '30%' }}
                data-test='product-lines-table-cell'>
                <Typography
                  variant='body2'
                  data-test='product-lines-table-cell-typo'>
                  Consumer Product
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-test='product-lines-table-body-data-table'>
            {orgProfiles.map(org => (
              <ProductLinesRow
                data-test='product-lines-product-line-row'
                key={org.organizationProfileId}
                setErrors={setErrors}
                errors={errors}
                readOnly={readOnly}
                highestRole={highestRole}
                org={org}
                products={products}
                unSelectProductsAll={unSelectProductsAll}
                transform={this.filterTransform}
                userRoles={userRoles}
                onConsumerChange={this.handleConsumerChange}
                onCommercialChange={this.handleCommercialChange}
                onProductRemoved={onProductRemoved}
              />
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
}

export default ProductLines;
