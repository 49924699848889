import api from './';

const announcementsEndpoints = {
  getAnnouncement: 'home/ancmnt',
  getImportantDates: 'home/duedates'
};

export const getAnnouncementApi = async () => {
  const res = await api().get(announcementsEndpoints.getAnnouncement);
  return res.data;
};

export const getImportantDatesApi = async () => {
  const res = await api().get(announcementsEndpoints.getImportantDates);
  return res.data;
};
