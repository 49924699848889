/* eslint-disable max-lines-per-function */
import React, { Component } from 'react';
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { TextFieldGrid } from '../../../components/formik';
import { TrashIcon } from '../../../components/icons';
import RoyaltyRateScaleForm from './RoyaltyRateScaleForm';

class RoyaltyRateScale extends Component {
  constructor(props) {
    super(props);
    this.inputRef = [];
  }

  render() {
    const {
      royaltyRateScale,
      onDeleteRoyaltyRateScale,
      onAddRoyaltyRateScale,
      mode
    } = this.props;
    let headers =
      mode === 'edit'
        ? ['Tier Number', 'From', 'Basis Points', '', '', '']
        : ['Tier Number', 'From', 'Basis Points', '', ''];

    headers = mode === 'view' ? ['Tier Number', 'From', 'Basis Points'] : headers;
    return (
      <Grid
        container
        data-test='component-RoyaltyRateScale'
        className='user-personal-data-component'>
        <Grid item md={12} className='row-header-bar'>
          <Typography variant='h6'>Royalty Rate Scale </Typography>
        </Grid>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#1a587e' }}>
                {headers.map((header, index) => (
                  <TableCell
                    key={String(header + index)}
                    colSpan={mode === 'edit' && index === 3 ? 6 : 1}
                    style={
                      index === 1 || index === 2 ? { textAlign: 'right' } : {}
                    }>
                    <Typography variant='body2'>{header}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {mode !== 'view' && (
                <RoyaltyRateScaleForm
                  mode={mode}
                  royaltyRateScale={royaltyRateScale || []}
                  onAddRoyaltyRateScale={onAddRoyaltyRateScale}
                />
              )}
              {royaltyRateScale &&
                royaltyRateScale.map((rate, i) => (
                  <TableRow key={rate.tireNumber}>
                    <TableCell>{rate.tireNumber}</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                      {rate.from}
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                      {rate.rate}
                    </TableCell>
                    {mode === 'edit' && (
                      <TableCell colSpan={6}>
                        <TextFieldGrid
                          fullWidth
                          label='Enter Notes'
                          inputRef={el => (this.inputRef[i] = el)}
                        />
                      </TableCell>
                    )}
                    {mode !== 'view' && (
                      <>
                        <TableCell style={{ textAlign: 'left' }}>
                          <TrashIcon
                            onClickIcon={() => {
                              mode === 'edit'
                                ? onDeleteRoyaltyRateScale(
                                    rate.tireNumber,
                                    this.inputRef[i].querySelector('input')
                                      .value
                                  )
                                : onDeleteRoyaltyRateScale(rate.tireNumber);
                            }}
                          />
                        </TableCell>
                        <TableCell />
                      </>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  }
}

export default RoyaltyRateScale;
