import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { ADMIN_TEXT, salutationOptions } from '../../../../constants/text';
import { Switch, Dropdown, TextField } from '../../../../components/material';

class UserPersonalDataSection extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object
  };

  static defaultProps = {
    values: {},
    touched: {},
    errors: {}
  };

  toggleSwitchHandle = e => {
    this.props.handleChange(e);
    this.props.toggleHandle();
  };

  sectionOne = (
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    toggleHandle,
    impersonate
  ) => {
    return (
      <Grid container className='row' data-test='user-personal-container'>
        <Grid
          item
          md={4}
          style={{ padding: '15px 15px 0 0' }}
          data-test='user-personal-outer-userId'>
          <Grid
            container
            direction='column'
            data-test='user-personal-inner-userId'>
            <TextField
              required
              fullWidth
              disabled
              label={ADMIN_TEXT.userId}
              name='userLoginId'
              data-test='user-personal-userId'
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid
          item
          md={4}
          style={{ padding: '15px 15px' }}
          data-test='user-personal-outer-salutation'>
          <Grid
            container
            direction='column'
            data-test='user-personal-inner-salutation'>
            <Dropdown
              fullWidth
              disabled={
                this.props.userStatus === 'I' ||
                this.props.roleMap ||
                this.props.impersonate
              }
              id='salutation'
              options={salutationOptions}
              placeholder='Please Select'
              label={ADMIN_TEXT.salutation}
              name='salutation'
              value={values.salutation}
              error={errors.salutation && touched.salutation}
              data-test='user-personal-drop-down'
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      handleChange,
      values,
      handleBlur,
      errors,
      touched,
      roleMap,
      userStatus,
      impersonate
    } = this.props;
    return (
      <Grid
        container
        data-test='user-personal-data-component'
        className='user-personal-data-component'>
        <Grid
          item
          md={12}
          className='user-personal-data-container'
          data-test='user-personal-data-container'>
          <Typography variant='h6' data-test='user-personal-data-personal-data'>
            {ADMIN_TEXT.personalData}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className='user-personal-data-body-section'
          data-test='user-personal-data-body-section'>
          {this.sectionOne(
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            impersonate
          )}

          <Grid container className='row' data-test='row'>
            <Grid
              item
              md={4}
              style={{ padding: '0 15px 0 0' }}
              data-test='user-personal-grid-4'>
              <Grid
                container
                direction='column'
                data-test='user-personal-data-grid-2'>
                <TextField
                  fullWidth
                  required
                  disabled={userStatus === 'I' || roleMap || impersonate}
                  name='firstName'
                  label={ADMIN_TEXT.firstName}
                  data-test='user-personal-firstName'
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={4}
              style={{ padding: '0 15px' }}
              data-test='user-personal-outer-middletName'>
              <Grid
                container
                direction='column'
                data-test='user-personal-inner-middletName'>
                <TextField
                  fullWidth
                  disabled={userStatus === 'I' || roleMap || impersonate}
                  name='middleName'
                  label={ADMIN_TEXT.middleName}
                  data-test='user-personal-middletName'
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={4}
              style={{ padding: '0 15px' }}
              data-test='user-personal-outer-lastName'>
              <Grid
                container
                direction='column'
                data-test='user-personal-inner-lastName'>
                <TextField
                  fullWidth
                  required
                  disabled={userStatus === 'I' || roleMap || impersonate}
                  label={ADMIN_TEXT.lastName}
                  name='lastName'
                  data-test='user-personal-lastName'
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className='row' data-test='user-personal-outer-'>
            <Grid
              item
              md={4}
              style={{ padding: '0 15px 0 0' }}
              data-test='user-personal-outer-emailId'>
              <Grid
                container
                direction='column'
                data-test='user-personal-inner-emailId'>
                <TextField
                  fullWidth
                  required
                  disabled={userStatus === 'I' || roleMap || impersonate}
                  label={ADMIN_TEXT.emailId}
                  name='emailId'
                  data-test='user-personal-emailId'
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={4}
              style={{ padding: '0 15px' }}
              data-test='user-personal-outer-jobTitle'>
              <Grid
                container
                direction='column'
                data-test='user-personal-inner-jobTitle'>
                <TextField
                  fullWidth
                  required
                  disabled={userStatus === 'I' || roleMap || impersonate}
                  label={ADMIN_TEXT.jobTitle}
                  name='jobTitle'
                  data-test='user-personal-jobTitle'
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={2}
              style={{ padding: '0 15px' }}
              data-test='user-personal-outer-active'>
              <Grid
                container
                style={{ height: '100%' }}
                alignItems='center'
                data-test='user-personal-inner-active'>
                <Switch
                  id='active'
                  label='Active'
                  name='active'
                  value='active'
                  checked={Boolean(values.active.length)}
                  disabled={Boolean(roleMap) || impersonate}
                  data-test='user-personal-active'
                  onChange={this.toggleSwitchHandle}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={2}
              style={{ padding: '0 15px' }}
              data-test='user-personal-outer-lockStatus'>
              <Grid
                container
                style={{ height: '100%' }}
                alignItems='center'
                data-test='user-personal-inner-lockStatus'>
                <Switch
                  id='active'
                  label='DDID Locked'
                  name='lockStatus'
                  value='active'
                  disabled={userStatus === 'I' || roleMap || impersonate}
                  checked={Boolean(values.lockStatus.length)}
                  data-test='user-personal-lockStatus'
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default UserPersonalDataSection;
