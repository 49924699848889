import React, { useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';
import ManageDataMegaMenu from './ManageData/ManageData';
import AdminMegaMenu from './AdministrationTab/BusinessAdminMenu';
import { HOME_TEXT } from '../../constants/text';
import PropTypes from 'prop-types';
import './megamenu.css';

const MegaMenu = props => {
  const {
    selectedMegaMenu,
    header,
    closeMegaMenu,
    style,
    forms,
    form,
    tempFormsListNav,
    setFormsListInMenu,
    megaMenuOrganizationNames,
    getMegaMenuOrganizationNames,
    orgReportingPeriod,
    saveOrgNameAndId,
    setTempFormsListInMenu,
    setDataForms,
    resetDataForms,
    getOrgParams,
    orgParams,
    previousMonthStatus,
    setFormType,
    formType,
    allDataForms,
    user,
    saveSelectedForm,
    selectedOrgReportedPeriod
  } = props;
  // Repopulate formsListNav with previuosly loaded organization if click on outside after selecting new org through manage tab
  useEffect(() => {
    if (selectedMegaMenu === 'none') {
      if (
        form !== undefined &&
        parseInt(form.orgProfileId) !== orgReportingPeriod.orgProfileId
      ) {
        const tempOrgReportingPeriod = tempFormsListNav
          ? tempFormsListNav.orgReportingPeriod
          : {};
        if (tempOrgReportingPeriod) {
          const {
            orgName,
            orgProfileId,
            reportingMonth,
            reportingYear
          } = orgReportingPeriod;
          localStorage.setItem(
            'orgReportingPeriod',
            JSON.stringify({
              orgProfileId,
              reportingMonth,
              reportingYear
            })
          );
          localStorage.setItem('orgName', orgName);
        }
        const updatedFormsListNav = {
          ...tempFormsListNav,
          orgReportingPeriod: orgReportingPeriod,
          selectedOrgReportedPeriod: selectedOrgReportedPeriod
        };
        setTempFormsListInMenu(updatedFormsListNav);
      }
    }
  }, [selectedMegaMenu]);

  if (selectedMegaMenu === 'none') {
    return null;
  }

  return (
    <Grid
      container
      className={header ? '' : 'padding'}
      data-test='mega-menu-component'
      style={style}
      onClick={e => e.stopPropagation()}>
      <Grid item md>
        <Paper
          square
          elevation={8}
          className={`megamenu-paper ${header &&
            'megamenu-paper-header'} mega-menu-paper`}>
          <Grid container style={{ height: '100%' }}>
            {selectedMegaMenu === HOME_TEXT.manageData && (
              <ManageDataMegaMenu
                forms={forms}
                form={form}
                setFormsListInMenu={setFormsListInMenu}
                megaMenuOrganizationNames={megaMenuOrganizationNames}
                getMegaMenuOrganizationNames={getMegaMenuOrganizationNames}
                orgReportingPeriod={orgReportingPeriod}
                saveOrgNameAndId={saveOrgNameAndId}
                setDataForms={setDataForms}
                resetDataForms={resetDataForms}
                getOrgParams={getOrgParams}
                orgParams={orgParams}
                previousMonthStatus={previousMonthStatus}
                setFormType={setFormType}
                formType={formType}
                allDataForms={allDataForms}
                user={user}
                onClose={closeMegaMenu}
                saveSelectedForm={saveSelectedForm}
              />
            )}
            {selectedMegaMenu === HOME_TEXT.administration && (
              <AdminMegaMenu role={user?.userRoleMap} onClose={closeMegaMenu} />
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

MegaMenu.propTypes = {
  selectedMegaMenu: PropTypes.string.isRequired,
  header: PropTypes.bool,
  style: PropTypes.object,
  closeMegaMenu: PropTypes.func.isRequired
};

MegaMenu.defaultProps = {
  header: false,
  style: {}
};
export default MegaMenu;
