import { franchisesDataActions } from './types';
import { catchError } from '../error/actions';
import { getFranchisesApi } from '../../api/franchises';

const setFranchisesData = franchisesData => ({
  type: franchisesDataActions.SET_FRANCHISES_DATA,
  payload: {
    dueDate: franchisesData.dueDate,
    allFranchises: franchisesData.franchises
  }
});

export const filterFranchises = filterBy => ({
  type: franchisesDataActions.FILTER_FRANCHISES,
  payload: {
    filterBy
  }
});

export const fetchFranchisesData = () => async (dispatch, getState) => {
  const body = {
    userName: getState().user.userName
  };
  try {
    const res = await getFranchisesApi(body);
    dispatch(setFranchisesData(res));
  } catch (err) {
    if (err.response.status === 404) {
      const res = {
        dueDate: '',
        franchises: []
      };
      dispatch(setFranchisesData(res));
    }
    dispatch(catchError(err));
  }
};
