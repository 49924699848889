import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { ActionBar, Loader } from '../../../../components';
import TableList from './TableList';

export class List extends Component {
  componentDidMount() {
    const { orgId } = this.props.match.params;
    this.props.getContractsByParentOrganization(orgId);
  }

  componentDidUpdate(prevProps) {
    const { orgId } = this.props.match.params;
    if (prevProps.match.params.orgId !== this.props.match.params.orgId) {
      this.props.getContractsByParentOrganization(orgId);
    }
  }

  render() {
    const { orgId } = this.props.match.params;
    const {
      contractsByParentOrganization: { contractList, organizationProfileName }
    } = this.props;
    if (!contractList) {
      return <Loader data-test='component-List' />;
    }

    const sortedContractList = contractList.sort((a, b) => {
      let comparision = 0;
      if (a.staus < b.status) {
        comparision = 1;
      } else if (b.status < a.status) {
        comparision = -1;
      }
      return comparision;
    });

    const actionBarElement = (
      <Typography variant='body1' className='contact-list-actionbar-text'>
        {organizationProfileName}
      </Typography>
    );
    return (
      <Grid container data-test='component-List'>
        <Grid item md={12}>
          <ActionBar border left1={actionBarElement} rounded='top' />
        </Grid>
        <Grid item md={12} className='contact-list-header'>
          <Typography className='txt'>Contract(s)</Typography>
        </Grid>
        <Grid item md={12} className='contract-table'>
          <TableList
            contracts={sortedContractList}
            organizationProfileId={orgId}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(List);
