import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Header from './AnnouncementsHeader';
import AnnouncementCriteria from './AnnouncementCriteria';
import AnnouncementList from './AnnouncementList';
import SelectedAnnouncement from './SelectedAnnouncement';
import NoSelectedAnnouncement from './NoSelectedAnnouncement';
import EmptyAnnouncementList from './EmptyAnnouncementList';
import './Announcements.css';
import fieldErrors from '../../../constants/fieldErrors';
import {
  getAnnouncementById,
  updateSingleAnnouncement,
  clearingReduxAnnouncementStore
} from '../../../store/announcements/actions';
import { connect } from 'react-redux';
import {
  readFileDataAsBase64,
  validateFileSizeAndFormat
} from '../../../utils/commonFunctions';
import { toastFunc } from '../../../components/ToastComponent/toastFunction';

class Announcements extends Component {
  state = {
    selectedFile: null,
    selectedFileName: '',
    newAnnouncementImageString: '',
    selectedAnnouncement: null,
    selectedAnnouncementImageURL: '',
    imageErrorMessage: '',
    selectedAnnouncementImageErrorMessage: '',
    selectedAnnouncementInitialImage: {},
    imageType: ''
  };

  componentDidMount() {
    this.props.getAllAnnouncements();
  }

  componentWillUnmount() {
    this.props.clearingReduxAnnouncementStore();
  }

  newAnnouncementSelectedImageHandler = async event => {
    const file = event.target.files[0];
    const fileAsBase64 = await readFileDataAsBase64(event);
    const base64Result = fileAsBase64.split(',')[1];
    const imageType = fileAsBase64.substring(
      'data:image/'.length,
      fileAsBase64.indexOf(';base64')
    );
    const validMessage = validateFileSizeAndFormat(
      file.size,
      imageType,
      fieldErrors
    );
    if (validMessage === 'valid') {
      this.setState({
        selectedFile: URL.createObjectURL(file),
        selectedFileName: file.name,
        newAnnouncementImageString: base64Result,
        imageType,
        imageErrorMessage: ''
      });
    } else {
      this.setState({
        selectedFile: null,
        selectedFileName: null,
        newAnnouncementImageString: '',
        imageErrorMessage: validMessage
      });
    }
  };

  removeNewAnnouncementImageHandler = () => {
    this.setState({
      selectedFile: null,
      selectedFileName: '',
      newAnnouncementImageString: null
    });
  };

  displayToast = (responseStatus, successMessage, errorMessage) => {
    if (responseStatus === 200) {
      this.props.getAllAnnouncements();
      toastFunc({
        content: successMessage,
        config: { className: 'toast-container accept' },
        toastType: 'success'
      });
      this.setState({
        imageErrorMessage: ''
      });
    } else {
      toastFunc({
        content: errorMessage,
        config: { className: 'toast-container reject' },
        toastType: 'error'
      });
    }
  };

  selectAnnouncementHandler = id => {
    this.props.getAnnouncementById(id);
  };

  render() {
    const { singleAnnouncement, updateSingleAnnouncement } = this.props;
    return (
      <Grid container item data-test='user-search-component'>
        <Grid
          container
          className='announcements-container'
          alignItems='flex-start'
          alignContent='flex-start'>
          <Header data-test='announcements-header' />
          <Grid container item className='announcements-criteria-container'>
            <AnnouncementCriteria
              newAnnouncementImageUpload={
                this.newAnnouncementSelectedImageHandler
              }
              selectedFile={this.state.selectedFile}
              selectedFileName={this.state.selectedFileName}
              imageType={this.state.imageType}
              imageRemoved={this.removeNewAnnouncementImageHandler}
              imageErrorMessage={this.state.imageErrorMessage}
              addAnnouncement={this.addAnnouncementHandler}
              announcementImageURL={this.state.newAnnouncementImageString}
              displayToast={this.displayToast}
              getAllAnnouncements={this.props.getAllAnnouncements}
            />
          </Grid>
          <Grid container item className='announcements-bottom-container'>
            {this.props.announcementList &&
            Array.isArray(this.props.announcementList) &&
            this.props.announcementList.length > 0 ? (
              <AnnouncementList
                announcementList={this.props.announcementList}
                announcementClicked={this.selectAnnouncementHandler}
                selectedAnnouncementId={
                  singleAnnouncement && singleAnnouncement.announcementId
                    ? singleAnnouncement.announcementId
                    : null
                }
              />
            ) : (
              <EmptyAnnouncementList />
            )}
            {Object.keys(singleAnnouncement).length > 0 ? (
              <SelectedAnnouncement
                updateAnnouncement={updateSingleAnnouncement}
                selectedAnnouncement={singleAnnouncement}
              />
            ) : (
              <NoSelectedAnnouncement />
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapPropsToValues = state => {
  return {
    singleAnnouncement: state.announcements.singleAnnouncement
  };
};
export default connect(mapPropsToValues, {
  getAnnouncementById,
  updateSingleAnnouncement,
  clearingReduxAnnouncementStore
})(Announcements);
