import React, { Component } from 'react';
import { PageSkeleton, CanActivate } from '../../../components';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import ParentOrganizations from './ParentOrganizations';
import './Contract.css';
import Routes from './Routes';
import PageError from '../../PageError';

const showAddContactButton = location => {
  const pathLength = location.pathname.split('/').filter(v => v !== '').length;
  return pathLength === 3 || pathLength === 2;
};

export class Contract extends Component {
  state = {
    organizationId: ''
  };

  populateOrgId = organizationId => {
    this.setState({
      organizationId
    });
  };

  render() {
    const { history, location } = this.props;
    const { organizationId } = this.state;

    return (
      <>
        {location.pathname.includes('/admin/contract/create') && (
          <Routes {...this.props} data-test='component-Contract' />
        )}

        {!location.pathname.includes('/admin/contract/create') && (
          <CanActivate
            action='contract:navigate'
            unAuthorizedView={<PageError errorCode='401' />}>
            <PageSkeleton
              data-test='component-Contract'
              title='Contract Administration'
              primaryButton={
                showAddContactButton(location) && {
                  label: 'Add Contract',
                  onClick: () =>
                    history.push(`/admin/contract/create/${organizationId}`)
                }
              }>
              <Grid container direction='row'>
                <Grid item md={3} className='contract-component-left-section'>
                  <ParentOrganizations populateOrgId={this.populateOrgId} />
                </Grid>
                <Grid item md={9} className='contract-component-right-section'>
                  <Routes {...this.props} />
                </Grid>
              </Grid>
            </PageSkeleton>
          </CanActivate>
        )}
      </>
    );
  }
}

export default withRouter(Contract);
