/* eslint-disable react/jsx-key */
/* eslint-disable max-lines-per-function */
import React from 'react';
import { TextField, Chip, Avatar, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { Checkbox } from '.';

const MultipleComboboxWithSwitch = props => {
  const {
    name,
    limit,
    onChange,
    disabled,
    classes,
    toggleState,
    onSelectAll,
    onUnselectAll
  } = props;
  const rootProps = { ...props };
  delete rootProps.onChange;
  delete rootProps.required;
  delete rootProps.error;
  delete rootProps.classes;
  delete rootProps.toggleState;
  delete rootProps.switchLabel;
  delete rootProps.switchValue;
  delete rootProps.onSelectAll;
  delete rootProps.onUnselectAll;

  const inputProps = {
    error: props.error,
    required: props.required,
    label: props.label
  };

  return (
    <Autocomplete
      autoComplete
      multiple
      disableCloseOnSelect
      data-test='component-MultipleComboboxWithSwitch'
      getOptionLabel={option => option.label}
      closeIcon={<SearchIcon />}
      clearText='Search'
      renderInput={params => (
        <TextField {...params} {...inputProps} fullWidth />
      )}
      {...rootProps}
      renderOption={(option, { selected }) => {
        if (option.id === 0) {
          return (
            <>
              <Button
                color='secondary'
                variant='outlined'
                size='small'
                onClick={e => {
                  onSelectAll();
                  e.stopPropagation();
                }}>
                Select All
              </Button>
              <Button
                color='secondary'
                variant='outlined'
                size='small'
                onClick={e => {
                  onUnselectAll();
                  e.stopPropagation();
                }}>
                Unselect All
              </Button>
            </>
          );
        }
        return (
          <>
            <Checkbox
              checked={selected}
              label={option.label}
              className={classes.menuItem}
            />
          </>
        );
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          if (index > limit) {
            return null;
          }

          if (index === limit) {
            const chipProps = { ...getTagProps({ index }) };
            delete chipProps.onDelete;
            const rest = value.slice(limit);

            return <Chip label={`${rest.length} more`} {...chipProps} />;
          }
          const avator = toggleState.find(toggle => toggle.id === option.id);

          return (
            <Chip
              key={option.label}
              label={option.label}
              disabled={disabled}
              {...getTagProps({ index })}
              avatar={(avator.status && <Avatar>A</Avatar>) || null}
            />
          );
        })
      }
      onChange={(_, value) => {
        const event = {
          target: {
            name,
            value
          }
        };
        onChange(event);
      }}
    />
  );
};

MultipleComboboxWithSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  limit: PropTypes.number,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  classes: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired
    })
  ),
  onChange: PropTypes.func
};

MultipleComboboxWithSwitch.defaultProps = {
  label: '',
  limit: 2,
  options: [],
  error: false,
  disabled: false,
  required: false,
  classes: {},
  value: '',
  onChange: () => {}
};

export default MultipleComboboxWithSwitch;
