import React, { Component } from 'react';
import { Loader } from '../../../../components';

import EditContract from './EditContract';

class Edit extends Component {
  componentDidMount() {
    const {
      match: { params },
      getChildOrganizationsByParentOrgId,
      getExclusionTypes,
      getContractById
    } = this.props;

    getChildOrganizationsByParentOrgId(params.orgId);
    getExclusionTypes();
    getContractById(params.contractId);
  }

  componentWillUnmount() {
    const {
      clearChildOrganization,
      clearContract,
      clearExclusionTypes
    } = this.props;

    clearChildOrganization();
    clearContract();
    clearExclusionTypes();
  }

  goBack = () => {
    const {
      history,
      match: { params }
    } = this.props;

    history.replace(`/admin/contract/${params.orgId}`);
  };

  render() {
    const {
      contract,
      childOrganizations,
      exclusionTypesList,
      getContractById,
      clearContract,
      match: { params }
    } = this.props;
    if (!contract.contractId) {
      getContractById(params.contractId);
      return <Loader data-test='component-edit' />;
    }
    return (
      <EditContract
        contract={contract}
        clearContract={clearContract}
        childOrganizations={childOrganizations}
        exclusionTypesList={exclusionTypesList}
        goBack={this.goBack}
        data-test='component-edit'
      />
    );
  }
}

export default Edit;
