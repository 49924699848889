import React from 'react';
import './CircleCheck.css';
import { ReactComponent as SvgIcon } from '../../../assets/icons/circle-check.svg';
import { Grid } from '@material-ui/core';

const CircleCheck = () => {
  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      data-test='circle-check-icon-component'
      className='circe-check-icon-container'>
      <Grid item>
        <SvgIcon className='circe-check-icon' />
      </Grid>
    </Grid>
  );
};

export default CircleCheck;
