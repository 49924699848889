import { connect } from 'react-redux';
import List from './List';
import { getContractsByParentOrganization } from '../../../../store/contracts/actions';

const mapStateToProps = state => ({
  contractsByParentOrganization: state.contracts.contractsByParentOrganization
});
export default connect(mapStateToProps, { getContractsByParentOrganization })(
  List
);
