import React, { Component } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { downloadHelpDocuments } from '../../store/global/actions';
import './HelpPage.css';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { ReactComponent as PdfIcon } from '../../assets/icons/pdf-icon.svg';
import Question from '../../assets/images/question.png';

export class HelpPage extends Component {
  componentDidMount() {
    document.title = 'GlobalNet | Help';
  }

  render() {
    const supervisorCategoryOne = [
      {
        linkText: 'What is Aquirer?',
        filename: 'sample.pdf'
      },
      {
        linkText:
          'What are the merchant fees associated with accepting discover global network?',
        filename: 'sample.pdf'
      },
      {
        linkText: 'Do Merchant fees vary by business or Industry?',
        filename: 'sample.pdf'
      },
      {
        linkText: 'Which global cards brancd can i accept?',
        filename: 'sample.pdf'
      },
      {
        linkText: 'What if I intend to add surcharge to Discover transactions?',
        filename: 'sample.pdf'
      },
      {
        linkText: 'What is Aquirer',
        filename: 'sample.pdf'
      }
    ];
    const gmCategoryThree = [
      {
        linkText: 'What is Aquirer?',
        filename: 'sample.pdf'
      },
      {
        linkText:
          'What are the merchant fees associated with accepting discover global network?',
        filename: 'sample.pdf'
      },
      {
        linkText: 'Do Merchant fees vary by business or Industry?',
        filename: 'sample.pdf'
      },
      {
        linkText: 'Which global cards brancd can i accept?',
        filename: 'sample.pdf'
      },
      {
        linkText: 'What if I intend to add surcharge to Discover transactions?',
        filename: 'sample.pdf'
      },
      {
        linkText: 'What is Aquirer',
        filename: 'sample.pdf'
      }
    ];
    const gmCategoryTwo = [
      {
        linkText: 'What is Aquirer?',
        filename: 'sample.pdf'
      },
      {
        linkText:
          'What are the merchant fees associated with accepting discover global network?',
        filename: 'sample.pdf'
      },
      {
        linkText: 'Do Merchant fees vary by business or Industry?',
        filename: 'sample.pdf'
      }
    ];
    const gmCategoryOne = [
      {
        linkText: 'What is Aquirer?',
        filename: 'sample.pdf'
      },
      {
        linkText:
          'What are the merchant fees associated with accepting discover global network?',
        filename: 'sample.pdf'
      },
      {
        linkText: 'Do Merchant fees vary by business or Industry?',
        filename: 'sample.pdf'
      }
    ];
    const handleFileDownload = filename => {
      const { downloadHelpDocuments } = this.props;
      downloadHelpDocuments(filename);
    };
    return (
      <Grid container className='help-bg' data-test='help-page-main-container'>
        <Grid
          container
          rowSpacing={2}
          className='help-header-container'
          data-test='help-header-container'>
          <Grid item xs={10}>
            <Typography variant='body2' className='top-line' color='secondary'>
              Help
            </Typography>
            <Typography
              variant='body2'
              className='top-line help-heading2'
              color='secondary'>
              Get information about the topics that matter to you
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <img src={Question} />
          </Grid>
          <Grid item xs={10} />
        </Grid>
        <div
          className='franchise-flow helplink-container'
          data-test='helplink-container'>
          <Grid container rowSpacing={2}>
            <Grid item xs={6}>
              <Typography
                sx={{ mt: 4, mb: 2 }}
                variant='h6'
                component='div'
                color='primary'
                className='help-content-header1'>
                Data Entry Help
              </Typography>
              <Typography
                sx={{ mt: 4, mb: 2 }}
                variant='h6'
                component='div'
                color='secondary'
                className='help-content-header2'>
                Category Title One
              </Typography>
              <List className='help-link-list'>
                {supervisorCategoryOne.map((link, index) => (
                  <ListItem>
                    <ListItemIcon className='help-link-icon'>
                      <PdfIcon />
                    </ListItemIcon>
                    <Link
                      varant='body2'
                      color='secondary'
                      href='#'
                      onClick={e => {
                        e.preventDefault();
                        handleFileDownload(link.filename);
                      }}>
                      {link.linkText}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ minWidth: 75 }}>
                <CardContent className='help-contact-container1'>
                  <Typography
                    gutterBottom
                    sx={{ fontSize: 14 }}
                    color='secondary'>
                    Help Desk Information
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Email us at
                  </Typography>
                  <Typography gutterBottom variant='body2'>
                    <a
                      href='mailto:contact@discoverglobalnet.com'
                      target='_top'
                      className='help-contact-phone'>
                      contact@discoverglobalnet.com
                    </a>
                  </Typography>
                  <Typography variant='body2'>Call us on</Typography>
                  <Typography variant='paragraph'>
                    <a
                      href='tel:+1-866-255-6019'
                      target='_blank'
                      className='help-contact-phone'
                      rel='noreferrer'>
                      1-866-255-6019
                    </a>
                  </Typography>
                  <Typography
                    variant='paragraph'
                    className='help-contact-phone'>
                    {' '}
                    and press "1" for authorization
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ minWidth: 75 }}>
                <CardContent className='help-contact-container2'>
                  <Typography
                    gutterBottom
                    sx={{ fontSize: 14 }}
                    color='secondary'>
                    Infonet Information
                  </Typography>
                  <Link
                    varant='body2'
                    color='secondary'
                    target='_blank'
                    href='https://discover.service-now.com/infonet'>
                    Infonet
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container rowSpacing={2} className='help-bottom-container'>
            <Grid item xs={12}>
              <Typography
                sx={{ mt: 4, mb: 2 }}
                variant='h6'
                component='div'
                color='primary'
                className='help-content-header1'>
                General Manager Help
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{ mt: 4, mb: 2 }}
                variant='h6'
                component='div'
                color='secondary'
                className='help-content-header2'>
                Category Title One
              </Typography>
              <List className='help-link-list'>
                {gmCategoryOne.map((link, index) => (
                  <ListItem>
                    <ListItemIcon className='help-link-icon'>
                      <PdfIcon />
                    </ListItemIcon>
                    <Link
                      varant='body2'
                      color='secondary'
                      href='#'
                      onClick={e => {
                        e.preventDefault();
                        handleFileDownload(link.filename);
                      }}>
                      {link.linkText}
                    </Link>
                  </ListItem>
                ))}
              </List>
              <Typography
                sx={{ mt: 4, mb: 2 }}
                variant='h6'
                component='div'
                color='secondary'
                className='help-content-header2'>
                Category Title Two
              </Typography>
              <List className='help-link-list'>
                {gmCategoryTwo.map((link, index) => (
                  <ListItem>
                    <ListItemIcon className='help-link-icon'>
                      <PdfIcon />
                    </ListItemIcon>
                    <Link
                      varant='body2'
                      color='secondary'
                      href='#'
                      onClick={e => {
                        e.preventDefault();
                        handleFileDownload(link.filename);
                      }}>
                      {link.linkText}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{ mt: 4, mb: 2 }}
                variant='h6'
                component='div'
                color='secondary'
                className='help-content-header2'>
                Category Title Three
              </Typography>
              <List className='help-link-list'>
                {gmCategoryThree.map((link, index) => (
                  <ListItem>
                    <ListItemIcon className='help-link-icon'>
                      <PdfIcon />
                    </ListItemIcon>
                    <Link
                      varant='body2'
                      color='secondary'
                      href='#'
                      onClick={e => {
                        e.preventDefault();
                        handleFileDownload(link.filename);
                      }}>
                      {link.linkText}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {
  downloadHelpDocuments
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HelpPage)
);
