/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import FileUploadIcon from '../../assets/icons/icon_upload.svg';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import SelectFranchiseFormUpload from '../../components/MegaMenu/ManageData/SelectFranchiseFormUpload';
import { connect } from 'react-redux';
import {
  getMegaMenuOrganizationNames,
  getOrgParams
} from '../../store/admin/actions';
import {
  saveOrgNameAndId,
  setFormsListInMenu,
  saveSelectedForm
} from '../../store/formsListNav/actions';
import { RPT_PRD_PREVIOUS_MONTH_ERR } from '../../constants/errors';

export const BrowseFile = props => {
  const {
    values,
    forms,
    megaMenuOrganizationNames,
    orgReportingPeriod,
    previousMonthStatus,
    getMegaMenuOrganizationNames
  } = props;

  const [orgReportingPeriods, setOrgReportingPeriods] = useState(
    orgReportingPeriod
  );
  const lastVisited = localStorage.getItem('orgReportingPeriod')
    ? JSON.parse(localStorage.getItem('orgReportingPeriod'))
    : '';
  const formSubmissionStatus =
    forms.length > 0 && (!forms[0].formEditable || forms[0].isUserSubmitted)
      ? RPT_PRD_PREVIOUS_MONTH_ERR
      : previousMonthStatus;

  useEffect(() => {
    if (lastVisited !== '') {
      getMegaMenuOrganizationNames();
      setOrgReportingPeriods(lastVisited);
    }
  }, []);
  return (
    <>
      <SelectFranchiseFormUpload
        data-test='select-franchise-form'
        values={values}
        megaMenuOrganizationNames={megaMenuOrganizationNames}
        orgReportingPeriod={orgReportingPeriods}
      />
      <span className='white-Space' />
      <div data-test='icon' className='upload-icon-outer'>
        <div className='upload-icon-inner'>
          <img
            src={FileUploadIcon}
            className='data-file-icon'
            alt='File Icon'
          />
        </div>
      </div>

      <Typography
        data-test='label'
        id='file-upload-div-caption'
        className='dnd-txt'
        color='secondary'
        variant='h6'>
        Drag and Drop your files
      </Typography>
      <p className='or-txt'>or</p>
      <Button
        fullWidth
        disabled={Boolean(formSubmissionStatus)}
        classes={{ root: 'browse-file-button' }}
        color='primary'
        variant='contained'
        aria-label='file browse botton'
        data-test='browse-button'
        onClick={props.fileSelectHandler}>
        <Typography variant='body1'>Browse</Typography>
      </Button>
      <p className='types-tx'>(Supported file types: .xlsx, .csv)</p>
    </>
  );
};

BrowseFile.propTypes = {
  fileSelectHandler: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};
const formConfig = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => {
    const lastVisited = localStorage.getItem('orgReportingPeriod')
      ? JSON.parse(localStorage.getItem('orgReportingPeriod'))
      : '';
    const { orgProfileId, reportingMonth, reportingYear } = lastVisited;
    return {
      year: reportingYear,
      month: reportingMonth,
      organization: orgProfileId
    };
  },

  validationSchema: Yup.object().shape({
    organization: Yup.string().required(),
    year: Yup.string().required(),
    month: Yup.string().required()
  }),

  isInitialValid: false
});
const mapStateToProps = state => ({
  megaMenuOrganizationNames: state.admin.megaMenuOrganizationNames,
  orgReportingPeriod: state.formsListNav.orgReportingPeriod,
  previousMonthStatus: state.errors.formErrors.errorMessage,
  orgParams: state.admin.orgParams,
  forms: state.formsListNav.forms,
  user: state.user
});

const mapDispatchToProps = {
  getMegaMenuOrganizationNames,
  saveOrgNameAndId,
  setFormsListInMenu,
  getOrgParams,
  saveSelectedForm
};

export const BrowseFileForm = formConfig(BrowseFile);
export default connect(mapStateToProps, mapDispatchToProps)(BrowseFileForm);
