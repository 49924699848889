import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DataForms from './DataForms';
import {
  clearDataForm,
  crossCheck,
  setDataForm,
  setDataForms,
  setQuarterlyForm,
  submitDataForms
} from '../../store/dataForms/actions';
import { setFormsListInMenu } from '../../store/formsListNav/actions';
import { resetFormErrors } from '../../store/error/actions';
import { openMegaMenu } from '../../store/ui/actions';

const mapStateToProps = state => ({
  allForms: state.formsListNav.forms,
  allUploadForms: state.formsListNav.uploadForms,
  form: state.dataForms.form,
  showAutoSave: state.dataForms.message.showAutoSave,
  showCodeExists: state.dataForms.message.showCodeExists,
  showDescExists: state.dataForms.message.showDescExists,
  showFraudExists: state.dataForms.message.showFraudExists,
  tempFormsListNav: state.dataForms.tempFormsListNav,
  formsListNav: state.formsListNav,
  formErrors: state.errors.formErrors,
  userRole: state.user?.highestRole,
  formType: state.formsListNav.formType,
  activeTab: state.dataForms.activeTab,
  userRoleMap: state.user?.userRoleMap,
  formID: state.formsListNav.orgReportingPeriod.orgProfileId
});

const mapDispatchToProps = {
  clearDataForm,
  setFormsListInMenu,
  crossCheck,
  setDataForm,
  setQuarterlyForm,
  submitDataForms,
  resetFormErrors,
  openMegaMenu,
  setDataForms
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DataForms)
);
