export const appRoles = {
  dataEntryUser: [
    'admin:navigate',
    'org-profile:navigate',
    'org-profile:search',
    'user-profile:profile',
    'dataforms'
  ],
  generalManager: [
    'admin:navigate',
    'org-profile:navigate',
    'org-profile:search',
    'user-profile:profile',
    'dataforms'
  ],
  localUserAdmin: [
    'admin:navigate',
    'admin:createuser',
    'admin:search',
    'admin:edit',
    'org-profile:navigate',
    'org-profile:search',
    'user-profile:profile',
    'user-profile:approval',
    'dataforms'
  ],
  financeAdmin: [
    'admin:navigate',
    'org-profile:navigate',
    'org-profile:search',
    'org-profile:create',
    'org-profile:edit',
    'contract:create',
    'contract:edit',
    'contract:search',
    'contract:navigate',
    'contract:view',
    'dataforms',
    'audit-report',
    'exchange-rate'
  ],
  userAdmin: [
    'admin:search',
    'admin:createuser',
    'admin:edit',
    'admin:navigate',
    'admin:user-logs',
    'user-profile:approval',
    'org-profile:navigate',
    'org-profile:search',
    'dataforms',
    'audit-report',
    'audit-report'
  ],
  productionSupportAdmin: [
    'admin:search',
    'admin:edit',
    'admin:createuser',
    'admin:navigate',
    'admin:user-logs',
    'org-profile:navigate',
    'org-profile:search',
    'dataforms',
    'audit-report'
  ],
  portalAdmin: [
    'admin:search',
    'admin:createuser',
    'admin:edit',
    'admin:navigate',
    'admin:user-logs',
    'org-profile:navigate',
    'org-profile:search',
    'org-profile:create',
    'org-profile:edit',
    'user-profile:approval',
    'contract:create',
    'contract:edit',
    'contract:search',
    'contract:navigate',
    'contract:view',
    'ancmnts',
    'dataforms',
    'productline',
    'unlock-franchisee',
    'audit-report',
    'exchange-rate',
    'extension-requests'
  ],
  generalUser: [
    'admin:createuser',
    'admin:search',
    'admin:edit',
    'admin:navigate',
    'org-profile:navigate',
    'dataforms',
    'org-profile:create',
    'org-profile:edit'
  ],
  anonymous: [],

  businessTechnologyAdmin: ['dataforms']
};

const roles = {
  OA: new Set(appRoles.localUserAdmin),
  G: new Set(appRoles.generalManager),
  D: new Set(appRoles.dataEntryUser),
  PA: new Set(appRoles.portalAdmin),
  BTA: new Set(appRoles.businessTechnologyAdmin),
  FA: new Set(appRoles.financeAdmin),
  HA: new Set(appRoles.userAdmin),
  PS: new Set(appRoles.productionSupportAdmin),
  GU: new Set(appRoles.generalUser)
};

export const rolesPrecedence = [
  'PA',
  'BTA',
  'FA',
  'HA',
  'PS',
  'OA',
  'G',
  'S',
  'D',
  'GU'
];

export const internalUserRoles = ['FA', 'HA', 'PS', 'PA', 'GU'];

export default roles;
