import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Switch } from '../../../../components/material';

class RoyaltyExclusionTerms extends Component {
  render() {
    const {
      IncludeCentralSubmissionVolume,
      allowManulaRoyalityExclusion,
      exclusionTypes
    } = this.props;
    return (
      <Grid container data-test='component-RoyaltyExclusionTerms'>
        <Grid item md={12} className='row-header-bar'>
          <Typography variant='h6'>Royalty Exclusion Terms</Typography>
        </Grid>
        <Grid item md={12} className='border-bottom px-15'>
          <Grid container className='row' alignItems='center'>
            <Grid item md={11} className='pl-15'>
              <Typography variant='h6'>
                Include Central Submission Volume in Royalty Additions Screen
              </Typography>
            </Grid>
            <Grid item md={1} className='pr-15'>
              <Switch
                disabled
                id='isIncludeCentralSubmissionVolume'
                name='isIncludeCentralSubmissionVolume'
                checked={IncludeCentralSubmissionVolume === true}
                value={IncludeCentralSubmissionVolume || ''}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} className='border-bottom px-15'>
          <Grid container className='row' alignItems='center'>
            <Grid item md={11} className='pl-15'>
              <Typography variant='h6'>
                Authorize Franchise for Manual Royalty Exclusion Input
              </Typography>
            </Grid>
            <Grid item md={1} className='pr-15'>
              <Switch
                disabled
                id='isAllowManulaRoyalityExclusion'
                name='isAllowManulaRoyalityExclusion'
                checked={
                  allowManulaRoyalityExclusion &&
                  allowManulaRoyalityExclusion === true
                }
                value={allowManulaRoyalityExclusion || ''}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className='border-bottom px-15'
          style={{
            backgroundColor: '#F2F9FC'
          }}>
          <Grid container className='row' alignItems='center'>
            <Grid item md={12} className='pl-15'>
              <Typography variant='h6'>Exclusion Type</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} className='border-bottom px-15'>
          {exclusionTypes &&
            exclusionTypes.map(exclusionType => (
              <Grid
                key={exclusionType.exclusionCode}
                item
                md={12}
                className='border-bottom px-15'>
                <Grid container className='row' alignItems='center'>
                  <Grid item md={12}>
                    <Typography variant='h6' className='pl-15'>
                      {exclusionType.exclusionName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
    );
  }
}

export default RoyaltyExclusionTerms;
