import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { TableRow, TableCell, TablePagination } from '@mui/material';
import MaterialTable from '../../../../components/material/MaterialTable';
import { Link } from 'react-router-dom';

const SearchResultTable = props => {
  const { organizations, handlePageChange, onRowsPerPageChange } = props;
  const { organizationalProfileVO, paginationInfo } = organizations;
  const [orgList, setOrgList] = useState([]);
  const [pageInfo, setPageInfo] = useState(
    paginationInfo ? paginationInfo : {}
  );
  const tableHeaders = [
    'Organization Profile Name',
    'Organization Region',
    'Operating Country',
    'Organization Type'
  ];

  useEffect(() => {
    if (organizationalProfileVO?.length > 0) {
      setOrgList(organizationalProfileVO);
    }
    if (paginationInfo) {
      setPageInfo(paginationInfo);
    }
  }, [paginationInfo]);
  return (
    <>
      <MaterialTable
        data-test='bprofile-search-result'
        tableHeaders={tableHeaders}
        headerAlign='left'
        count={pageInfo ? pageInfo.totalNumberOfRows : 0}>
        {orgList &&
          orgList.length > 0 &&
          orgList.map(org => (
            <TableRow
              data-test='data-row'
              key={org.organizationProfileId}
              style={{ inlineSize: '150px', overflow: 'hidden' }}>
              <TableCell align='left' style={{ width: '20%' }}>
                <Typography variant='body2'>
                  <Link
                    to={{
                      pathname: `/admin/organization/edit/${org.orgInfoId}`,
                      state: {
                        organizationProfileId: org.organizationProfileId,
                        organizationProfileName: org.organizationProfileName
                      }
                    }}>
                    {org.organizationProfileName}
                  </Link>
                </Typography>
              </TableCell>
              <TableCell
                style={{
                  inlineSize: '150px',
                  overflow: 'hidden',
                  width: '20%'
                }}
                align='left'>
                <Typography variant='body2'>
                  {org.orgRegionInfo.regionTypeDescription}
                </Typography>
              </TableCell>
              <TableCell
                style={{
                  inlineSize: '150px',
                  overflow: 'hidden',
                  width: '20%'
                }}
                align='left'>
                <Typography variant='body2'>
                  {org.operatingCountryCodeInfo.countryIsoName}
                </Typography>
              </TableCell>
              <TableCell
                style={{
                  inlineSize: '150px',
                  overflow: 'hidden',
                  width: '20%'
                }}
                align='left'>
                <Typography variant='body2'>
                  {org.orgTypeInfo.orgTypNm}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
      </MaterialTable>
      {pageInfo ? (
        <span className='user-Table-Pagination'>
          <TablePagination
            component='div'
            page={pageInfo.pageNumber}
            count={pageInfo.totalNumberOfRows}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            rowsPerPage={pageInfo.rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </span>
      ) : null}
    </>
  );
};
SearchResultTable.propTypes = {
  organizations: PropTypes.array,
  handlePageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired
};
SearchResultTable.defaultProps = {
  organizations: []
};
export default SearchResultTable;
