import React from 'react';
import { TextField, MenuItem, withStyles } from '@material-ui/core';

import Checkbox from './Checkbox';
import PropTypes from 'prop-types';

const styles = {
  menuItem: {
    position: 'relative',
    top: 5
  }
};

export const MultipleDropdown = props => {
  const { options, value, classes, placeholder, onChange } = props;
  const SelectProps = {
    multiple: true,
    renderValue: selected =>
      selected[0] === 'none' ? placeholder : selected.join(', ')
  };

  const forwardProps = { ...props };
  delete forwardProps.classes;

  return (
    <TextField
      data-test='MultipleDropdown'
      {...forwardProps}
      select
      SelectProps={SelectProps}
      onChange={e => {
        e.persist();
        const { value } = e.target;
        let newValue = value.filter(v => v !== 'none');
        newValue = newValue.length ? newValue : ['none'];
        e.target.value = newValue;
        onChange(e);
      }}>
      <MenuItem disabled value='none'>
        {placeholder}
      </MenuItem>

      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          <Checkbox
            checked={value.indexOf(option.value) !== -1}
            label={option.label}
            className={classes.menuItem}
          />
        </MenuItem>
      ))}
    </TextField>
  );
};

MultipleDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

MultipleDropdown.defaultProps = {
  disabled: false,
  label: '',
  options: [],
  placeholder: '',
  onChange: () => {}
};

export default withStyles(styles)(MultipleDropdown);
