import React, { Component } from 'react';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ComboboxGrid, TextFieldGrid } from '../../../components/formik';
import { TableRow, TableCell } from '@material-ui/core';
import { integerLength10Regex } from '../../../utils/regexes';
import './RoyalityExclusionsAdditions.css';
class FormRow extends Component {
  componentDidMount() {
    this.props.getRoyaltyDropDownData();
  }

  onChange = e => {
    const value = e.target.value;
    if (e.target.name === 'othDescription') {
      this.props.handleChange(e);
    } else if (integerLength10Regex.test(value)) {
      this.props.handleChange(e);
    }
  };

  render() {
    const {
      handleChange,
      handleSubmit,
      handleClear,
      dropDownData,
      values,
      formType
    } = this.props;
    const isDescDisable =
      (values.description && values.description.value !== 'OTH') ||
      values.description === '';
    return (
      <TableRow
        className='form-row'
        data-test='component-dynamic-row-container'
        style={{ backgroundColor: '#F2F9FC' }}>
        <TableCell>
          <ComboboxGrid
            fullWidth
            highlight
            data-test='royalty-dropdown-field'
            label='Description'
            name='description'
            options={dropDownData.map(data => ({
              value: data.contractId,
              label: data.contractDesc
            }))}
            className='intes-table-form-fields'
            onChange={handleChange}
          />
        </TableCell>
        <TableCell>
          <TextFieldGrid
            fullWidth
            name='othDescription'
            placeholder='Enter Description'
            inputProps={{
              maxLength: 50,
              style: { textAlign: 'left', color: '#022A48' }
            }}
            disabled={isDescDisable}
            data-test='component-table-input-description'
            aria-label='other-description'
            className='intes-table-form-fields'
            onChange={this.onChange}
          />
        </TableCell>
        <TableCell>
          <TextFieldGrid
            fullWidth
            name='value'
            inputProps={{
              maxLength: 25,
              style: { textAlign: 'right', color: '#022A48' }
            }}
            data-test='component-royalty-input'
            aria-label='Value'
            className='intes-table-form-fields'
            onChange={this.onChange}
          />
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          <span
            className='add action-button-events-disabled'
            onClick={handleSubmit}>
            <PlusIcon />
          </span>
          <span
            className={
              !formType ? 'cross action-button-events-disabled' : 'cross'
            }
            onClick={handleClear}>
            <PlusIcon />
          </span>
        </TableCell>
      </TableRow>
    );
  }
}

export default FormRow;
