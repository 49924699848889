import React, { Component } from 'react';
import { TextField, Chip, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { Checkbox } from '.';

const styles = {
  menuItem: {
    position: 'relative',
    top: 5
  }
};

export class MultipleComboboxWithoutTags extends Component {
  render() {
    const {
      classes,
      selectedValues,
      onRemove,
      onChange,
      required,
      error,
      label,
      name,
      ...rootProps
    } = this.props;

    const inputProps = {
      error,
      required,
      label
    };

    return (
      <>
        <Autocomplete
          autoComplete
          multiple
          disableCloseOnSelect
          data-test='component-MultipleComboboxWithoutTags'
          getOptionLabel={option => option.label}
          closeIcon={<SearchIcon />}
          clearText='Search'
          renderInput={params => {
            return <TextField {...params} {...inputProps} fullWidth />;
          }}
          {...rootProps}
          renderOption={(option, { selected }) => {
            return (
              <Checkbox
                checked={selected}
                label={option.label}
                className={classes.menuItem}
              />
            );
          }}
          renderTags={() => null}
          onChange={(_, value) => {
            const event = {
              target: {
                name,
                value
              }
            };
            onChange(event);
          }}
        />
        {selectedValues.map(value => (
          <Chip
            data-test='chip-component'
            key={value.label}
            label={value.label}
            style={{ margin: '8px 5px' }}
            onDelete={() => onRemove(value)}
          />
        ))}
      </>
    );
  }
}

export default withStyles(styles)(MultipleComboboxWithoutTags);
