import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CommonForm from './CommonForm/CommonForm';
import Intes from './Intes/Intes';
import IssuerFraud from './IssuerFraud/IssuerFraud';
import QRCode from './QRCode/QRCode';
import RoyalityExclusionsAdditions from './RoyalityExclusionsAdditions/RoyalityExlusionsAdditions';
import CardMember from './CardMember/CardMember';
import CardVolumeSummary from './CardVolumeSummary/CardVolumeSummary';
import CardMemberSummary from './CardMemberSummary/CardMemberSummary';
import DpassFranchisee from './DpassFranchisee/DpassFranchisee';
import { CanActivate } from '../../components';
import PageError from '../PageError';

const RoutesMap = {
  'se-sales-volume': CommonForm,
  'se-sales-volume-summary': CommonForm,
  'card-volume': CardMember,
  'card-volume-summary': CardVolumeSummary,
  'se-membership-count': CommonForm,
  'se-activation': CommonForm,
  'se-discount-revenue': CommonForm,
  'agency-volume-transaction-and-discount-revenue': CommonForm,
  'royalty-sales-volume-fees': CardMember,
  'se-transactions': CommonForm,
  'se-transactions-summary': CommonForm,
  'dpass-aquirer': DpassFranchisee,
  'card-member': CardMember,
  'card-member-summary': CardMemberSummary,
  intes: Intes,
  'issuer-fraud': IssuerFraud,
  'qr-code': QRCode,
  'dpass-franchisee': DpassFranchisee,
  'royalty-sales-loans': CardMember,
  'royality-additions-exclusions': RoyalityExclusionsAdditions,
  'income-statement-revenue-and-funding': CommonForm,
  'income-statement-credit-losses-and-expenses': CommonForm,
  'income-statement-operating-expenses-and-net-income': CommonForm,
  'income-statement-supplemental-information': CommonForm,
  'balance-sheet-schedule-assets-and-liabilities': CommonForm,
  'balance-sheet-schedule-shareholders-equity': CommonForm,
  'balance-sheet-assets': CommonForm,
  'balance-sheet-liabilities': CommonForm,
  'balance-sheet-shareholders-equity': CommonForm,
  'annual-income-statement-revenue-and-funding': CommonForm,
  'annual-income-statement-credit-losses-and-expenses': CommonForm,
  'annual-income-statement-operating-expenses-and-net-income': CommonForm,
  'annual-income-statement-supplemental-information': CommonForm,
  'annual-balance-sheet-schedule-assets-and-liabilities': CommonForm,
  'annual-balance-sheet-schedule-shareholders-equity': CommonForm,
  'annual-balance-sheet-assets': CommonForm,
  'annual-balance-sheet-liabilities': CommonForm,
  'annual-balance-sheet-shareholders-equity': CommonForm,
  'annual-statistical-information': CommonForm
};

const Routes = ({ path, firstForm }) => {
  return (
    <Fragment>
      <div
        className='franchise-flow-routes-container'
        data-test='component-franchise-flow-routes-container'>
        <CanActivate
          action='dataforms'
          unAuthorizedView={<PageError errorCode='401' />}>
          <Switch>
            <Route exact path={`${path}/`}>
              <Redirect to={`${path}/${firstForm.id}`} />
            </Route>

            {Object.entries(RoutesMap).map(([id, Component]) => {
              return (
                <Route
                  key={id}
                  exact
                  path={`${path}/${id}`}
                  render={props => <Component {...props} />}
                />
              );
            })}
          </Switch>
        </CanActivate>
      </div>
    </Fragment>
  );
};

export default Routes;
