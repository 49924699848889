/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-icon.svg';
import { toastFunc } from '../../../components/ToastComponent/toastFunction';
import {
  Typography,
  TableRow,
  TableCell,
  Button,
  TextField,
  Switch,
  InputAdornment
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { parse } from 'date-fns';
import {
  presentDate,
  minAppDate,
  formatDateToDdMmYyyy
} from '../../../constants/exchangeRates';

const ExtensionRequestTableList = ({
  exchangeRequestData,
  usersOrganizations,
  extensionRequestUpdate,
  getAllExchangeRequest
}) => {
  const [editedRows, setEditedRows] = useState({});

  const editClick = extensionRequestId => {
    const index = exchangeRequestData.findIndex(
      data => data.extensionRequestId === extensionRequestId
    );
    const originalData = exchangeRequestData[index];
    setEditedRows(prevState => ({
      ...prevState,
      [extensionRequestId]: {
        extensionRequestNote: '',
        extensionRequestIndicator: originalData.extensionRequestIndicator,
        extensionExpiryDate: originalData.extensionExpiryDate
      }
    }));
  };

  const cancelClick = () => {
    setEditedRows({});
  };

  const saveClick = async extensionRequestId => {
    const editedData = editedRows[extensionRequestId];
    const index = exchangeRequestData.findIndex(
      data => data.extensionRequestId === extensionRequestId
    );

    const originalData = exchangeRequestData[index];
    if (
      editedData.extensionRequestNote !== originalData.extensionRequestNote ||
      editedData.extensionRequestIndicator !==
        originalData.extensionRequestIndicator ||
      editedData.extensionExpiryDate !== originalData.extensionExpiryDate
    ) {
      const formattedDate =
        editedData.extensionExpiryDate !== originalData.extensionExpiryDate
          ? editedData.extensionExpiryDate
          : originalData.extensionExpiryDate;
      const data = {
        extensionExpiryDate: formattedDate,
        extensionRequestIndicator: editedData.extensionRequestIndicator,
        extensionRequestNote: editedData.extensionRequestNote
          .trimStart()
          .trimEnd(),
        formTypeCategory: originalData.formTypeCategory,
        orgProfId: originalData.orgProfId,
        extensionRequestId: originalData.extensionRequestId
      };
      extensionRequestUpdate(data)
        .then(response => {
          if (response.status === 200) {
            toastFunc({
              content: `Extension request updated successfully`,
              config: { className: 'toast-container accept' },
              toastType: 'success'
            });
            getAllExchangeRequest();
          } else {
            toastFunc({
              content: `${response.data.errorMessage}`,
              config: { className: 'toast-container reject' },
              toastType: 'error'
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }

    setEditedRows(prevState => {
      const newState = { ...prevState };
      delete newState[extensionRequestId];
      return newState;
    });
  };

  const findOrganizationName = orgProfileId => {
    const organization = usersOrganizations.find(
      profile => profile.id == orgProfileId
    );
    return organization ? organization.label : '';
  };
  const isSaveButtonDisabled = extensionRequestId => {
    const editedData = editedRows[extensionRequestId];
    const originalData = exchangeRequestData.find(
      data => data.extensionRequestId === extensionRequestId
    );
    if (
      !editedData ||
      !editedData.extensionExpiryDate ||
      !originalData ||
      !originalData.extensionExpiryDate
    ) {
      return true;
    }

    const isExtensionRequestNoteEmpty =
      !editedData.extensionRequestNote ||
      editedData.extensionRequestNote.trim() === '';
    return isExtensionRequestNoteEmpty;
  };

  return (
    <>
      {exchangeRequestData &&
        exchangeRequestData.length > 0 &&
        exchangeRequestData.map(k => (
          <TableRow
            key={k.extensionRequestId}
            className={editedRows[k.extensionRequestId] ? 'isedit' : ''}
            data-test='extension-request-main-data-row'>
            <TableCell data-test='extension-request-Table-Cell'>
              <Typography data-test='extension-request-orgname'>
                {findOrganizationName(k.orgProfId)}
              </Typography>
            </TableCell>
            <TableCell data-test='extension-request-Table-category'>
              <Typography data-test='extension-request-category'>
                {k.formTypeCategory}
              </Typography>
            </TableCell>
            <TableCell data-test='extension-request-Table-Cell-date'>
              {editedRows[k.extensionRequestId] ? (
                <DatePicker
                  autoOk
                  required
                  inputVariant='outlined'
                  format='dd-MM-yyyy'
                  label='Expiry Date'
                  id={`extensionExpiryDate-${k.extensionRequestId}`}
                  name={`extensionExpiryDate-${k.extensionRequestId}`}
                  value={parse(
                    editedRows[k.extensionRequestId].extensionExpiryDate,
                    'dd-MM-yyyy',
                    new Date()
                  )}
                  minDate={
                    editedRows[k.extensionRequestId]
                      .extensionRequestIndicator === 'Y'
                      ? minAppDate
                      : presentDate
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <CalendarIcon />
                      </InputAdornment>
                    )
                  }}
                  onChange={date => {
                    setEditedRows(prevState => ({
                      ...prevState,
                      [k.extensionRequestId]: {
                        ...prevState[k.extensionRequestId],
                        extensionExpiryDate: formatDateToDdMmYyyy(date)
                      }
                    }));
                  }}
                />
              ) : (
                <Typography
                  data-test='product-name'
                  style={{ padding: '0px 15px' }}>
                  {k.extensionExpiryDate}
                </Typography>
              )}
            </TableCell>
            <TableCell data-test='extension-request-Table-Cell-comment'>
              {editedRows[k.extensionRequestId] ? (
                <>
                  <TextField
                    required
                    data-test='pcode-input'
                    label='Comment'
                    id={`extensionRequestNote-${k.extensionRequestId}`}
                    name={`extensionRequestNote-${k.extensionRequestId}`}
                    value={
                      editedRows[k.extensionRequestId].extensionRequestNote ||
                      ''
                    }
                    className='form-input-comment'
                    onChange={e => {
                      setEditedRows(prevState => ({
                        ...prevState,
                        [k.extensionRequestId]: {
                          ...prevState[k.extensionRequestId],
                          extensionRequestNote: e.target.value
                        }
                      }));
                    }}
                  />
                  <Typography
                    data-test='product-name'
                    style={{ whiteSpace: 'pre-wrap' }}>
                    {k.extensionRequestNote}
                  </Typography>
                </>
              ) : (
                <Typography
                  data-test='product-name'
                  style={{ whiteSpace: 'pre-wrap' }}>
                  {k.extensionRequestNote}
                </Typography>
              )}
            </TableCell>
            <TableCell
              data-test='extension-request-Table-Cell-comment-switch'
              style={{ textAlign: 'center' }}>
              {editedRows[k.extensionRequestId] ? (
                <Switch
                  id={`extensionRequestIndicator-${k.extensionRequestId}`}
                  name={`extensionRequestIndicator-${k.extensionRequestId}`}
                  checked={
                    editedRows[k.extensionRequestId]
                      .extensionRequestIndicator === 'Y'
                  }
                  onChange={() =>
                    setEditedRows(prevState => ({
                      ...prevState,
                      [k.extensionRequestId]: {
                        ...prevState[k.extensionRequestId],
                        extensionRequestIndicator:
                          prevState[k.extensionRequestId]
                            .extensionRequestIndicator === 'Y'
                            ? 'N'
                            : 'Y'
                      }
                    }))
                  }
                />
              ) : (
                <Switch
                  disabled
                  id={`extensionRequestIndicator-${k.extensionRequestId}`}
                  name={`extensionRequestIndicator-${k.extensionRequestId}`}
                  checked={k.extensionRequestIndicator === 'Y'}
                />
              )}
            </TableCell>
            <TableCell
              align='center'
              data-test='extension-request-Table-Cell-buttun-container'>
              {editedRows[k.extensionRequestId] ? (
                <>
                  <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    data-test='save-button'
                    disabled={isSaveButtonDisabled(k.extensionRequestId)}
                    onClick={() => saveClick(k.extensionRequestId)}>
                    Save
                  </Button>
                  <Button
                    color='secondary'
                    variant='outlined'
                    type='reset'
                    size='small'
                    className='user-reset-btn'
                    data-test='cancel-button'
                    onClick={cancelClick}>
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  data-test='edit-button'
                  onClick={() => editClick(k.extensionRequestId)}>
                  <EditIcon />
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};

export default ExtensionRequestTableList;
