import React, { Component } from 'react';
import { Modal } from '..';
import { Grid, Typography } from '@material-ui/core';

import PropTypes from 'prop-types';
import { Dropdown } from '../material';

const rejectionList = [
  {
    value: 'Information supplied was not correct. Please review and re-submit',
    label: 'Information supplied was not correct. Please review and re-submit'
  },
  {
    value: 'Other - Please contact your Helpdesk Administrator immediately',
    label: 'Other - Please contact your Helpdesk Administrator immediately'
  }
];

class RejectModal extends Component {
  state = {
    reason: ''
  };

  handleChange = e => {
    this.setState({ reason: e.target.value });
  };

  render() {
    const { isOpen, onCancel, onConfirm } = this.props;
    return (
      <>
        <Modal
          title='Rejection Reason'
          isOpen={isOpen}
          submitBtnLabel='Confirm'
          data-test='component-reject-modal'
          submitDisabled={this.state.reason === ''}
          onCancel={onCancel}
          onSubmit={() => onConfirm(this.state.reason)}
          onClose={onCancel}>
          <Grid
            container
            justify='center'
            className='reject-modal-container'
            direction='column'>
            <Grid item md={12}>
              <Typography variant='h6' className='reject-title'>
                Please select the reason to reject the Access Request
              </Typography>
            </Grid>

            <Grid item md={12}>
              <Dropdown
                options={rejectionList}
                label=''
                value={this.state.reason}
                name='rejectionList'
                className='drop-down'
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
        </Modal>
      </>
    );
  }
}

RejectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default RejectModal;
