/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import ErrorTooltip from '../formik/ErrorTooltip';
import WarningTooltip from '../formik/WarningTooltip';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import {
  updateFieldValue,
  updateDataField
} from '../../store/dataForms/actions';
import {
  integerLength12Regex,
  numberFormatRegex,
  removeCommasRegex,
  decimalLength10Regex,
  decimal3Length12Regex
} from '../../utils/regexes';
import { slugToIDs, formsNegativeCheck } from '../../constants/dataForms';
import { forecast } from '../../constants/text/forecast';
import { totalDetails } from '../../constants/formTotals';
import { AND, OR } from '../../utils/commonFunctions';

let prevValue = null;
export class TableInput extends Component {
  componentDidMount() {
    prevValue = null;
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.field, nextProps.field);
  }

  onChange = e => {
    let value = e.target.value;
    const {
      index,
      sectionIndex,
      rowIndex,
      updateFieldValue,
      form
    } = this.props;
    const slug = slugToIDs[form.formId];
    const supplemtalInfoDecimalIndex = [0, 1, 2, 3, 4, 5];
    const quarterlyFormName = 'income-statement-supplemental-information';
    const annualFormName = 'annual-income-statement-supplemental-information';
    if (value) {
      value = value.replace(removeCommasRegex, '');
    }
    if (value && slug !== quarterlyFormName && slug !== annualFormName) {
      value = String(Number.isNaN(Number(value)) ? value : Number(value));
    }
    if (
      slug === annualFormName &&
      sectionIndex === 0 &&
      supplemtalInfoDecimalIndex.includes(parseInt(index / 3)) &&
      decimal3Length12Regex.test(value)
    ) {
      updateFieldValue(value, index, sectionIndex, rowIndex);
    } else if (
      slug === quarterlyFormName &&
      sectionIndex === 0 &&
      supplemtalInfoDecimalIndex.includes(parseInt(index / 3)) &&
      decimalLength10Regex.test(value)
    ) {
      updateFieldValue(value, index, sectionIndex, rowIndex);
    } else if (integerLength12Regex.test(value)) {
      updateFieldValue(value, index, sectionIndex, rowIndex);
    }
  };

  onBlur = e => {
    const {
      form,
      field,
      index,
      sectionIndex,
      rowIndex,
      updateFieldValue,
      updateDataField,
      activeTab
    } = this.props;
    let error = '';
    let value = e.target.value;
    const orgReportingPeriod = JSON.parse(
      localStorage.getItem('orgReportingPeriod')
    );
    if (e.target.value === '-') {
      error = 'Invalid Value';
      updateFieldValue(value, index, sectionIndex, rowIndex, error);
      return;
    }
    if (prevValue === e.target.value) {
      return;
    }
    if (value) {
      value = value.replace(removeCommasRegex, '');
    }
    let forecastSelectedYear;
    const formtype = localStorage.getItem('formType');
    if (formtype === 'annuallyDataForms') {
      const forecastOrgYear = parseInt(orgReportingPeriod.reportingYear) + 1;
      const currentOrgYear = JSON.parse(localStorage.getItem('planYear'));
      forecastSelectedYear =
        forecastOrgYear === currentOrgYear
          ? forecast.firstYear
          : forecast.secondYear;
    }
    const fieldTotals = this.getTotalFieldDetails(
      form,
      field,
      sectionIndex,
      activeTab,
      slugToIDs[form.formId] === 'royalty-sales-volume-fees' ? true : false
    );
    delete form.tabs;
    const body = {
      ...form,
      formType: forecastSelectedYear,
      fieldDetails: {
        ...field,
        value,
        error
      },
      fieldDetailsList: fieldTotals
    };
    updateDataField(body);
  };

  getTotalFieldDetails(form, field, sectionIndex, activeTab, carryFlag) {
    let columnTotal = [];
    let rowTotal = [];
    let grandTotal = [];
    let finalTotal = [];
    let activeTabWithCheck = activeTab;
    if (!totalDetails[form.formId]) {
      return [];
    }
    if (form.tabs[0].tabName === 'Summary') {
      activeTabWithCheck = activeTab - 1;
    }
    const nSections = Object.keys(form.tabs[activeTab].sections).length;
    let sIndex = sectionIndex;
    while (sIndex < nSections) {
      const total =
        totalDetails[form.formId].tabs[activeTabWithCheck].sections[sIndex];
      columnTotal = total.columnTotal
        ? total.columnTotal
            .map(column =>
              form.tabs[activeTab].sections[
                sIndex
              ].fieldDetails.find(fieldDetail =>
                AND(
                  fieldDetail.columnHeaderId === field.columnHeaderId,
                  fieldDetail.rowHeaderId === column.rowHeaderId
                )
              )
            )
            .filter(e => !OR(e === null, typeof e === 'undefined'))
        : [];
      rowTotal = total.rowTotal
        ? total.rowTotal
            .map(row =>
              form.tabs[activeTab].sections[
                sIndex
              ].fieldDetails.find(fieldDetail =>
                AND(
                  fieldDetail.columnHeaderId === row.columnHeaderId,
                  fieldDetail.rowHeaderId === field.rowHeaderId
                )
              )
            )
            .filter(e => !OR(e === null, typeof e === 'undefined'))
        : [];
      grandTotal = total.grandTotal
        ? total.grandTotal
            .map(grand =>
              form.tabs[activeTab].sections[
                sIndex
              ].fieldDetails.find(fieldDetail =>
                AND(
                  fieldDetail.columnHeaderId === grand.columnHeaderId,
                  fieldDetail.rowHeaderId === grand.rowHeaderId
                )
              )
            )
            .filter(e => !OR(e === null, typeof e === 'undefined'))
        : [];
      finalTotal = [...finalTotal, ...columnTotal, ...rowTotal, ...grandTotal];
      sIndex = sIndex + 1;
      if (carryFlag === false) {
        break;
      }
    }
    return finalTotal;
  }

  valuesWithComma(value) {
    let updatedValue = '';
    if (value) {
      updatedValue = value.toString().replace(numberFormatRegex, ',');
    }
    return updatedValue;
  }

  saveInitialValue = e => {
    prevValue = e.target.value;
  };

  checkForNullColumns = (form, field) => {
    return (
      (slugToIDs[form.formId] === 'royalty-sales-loans' &&
        field &&
        (field.columnHeaderId === '2100' || field.columnHeaderId === '2101') &&
        field.rowHeaderId < '2108') ||
      (field.columnHeaderId === '2104' && field.rowHeaderId === '2108')
    );
  };

  render() {
    const {
      field,
      form,
      isSummaryTab,
      sectionIndex,
      colName,
      rowName
    } = this.props;
    if (!field) {
      return null;
    }
    const nullCols = this.checkForNullColumns(form, field);

    if (nullCols) {
      field.value = null;
    }

    let endAdornment;
    if (field.error) {
      endAdornment = <ErrorTooltip message={field.error} />;
    } else if (field.warning) {
      endAdornment = <WarningTooltip message={field.warning} />;
    } else {
      endAdornment = null;
    }

    if (field.readOnly && field.error) {
      return (
        <div style={{ border: '1px solid red' }}>
          <span style={{ verticalAlign: 'top' }}>
            {this.valuesWithComma(field.value)}
          </span>
          {endAdornment}
        </div>
      );
    }

    if (field.readOnly) {
      return <div>{this.valuesWithComma(field.value)}</div>;
    }
    if (isSummaryTab) {
      return <div>{this.valuesWithComma(field.value)}</div>;
    }

    return form.editable && !form.tabStatus ? (
      <TextField
        data-test='component-TableInput'
        value={this.valuesWithComma(field.value)}
        error={field.error}
        InputProps={{
          endAdornment
        }}
        inputProps={{ maxLength: 19 }}
        className='generictable-tablecell'
        name='numberformat'
        onChange={e => {
          const negRowCol = formsNegativeCheck(
            slugToIDs[form.formId],
            sectionIndex,
            rowName,
            colName
          );
          const neg = e.target.value === '-' || e.target.value < 0;

          if (!negRowCol || !neg) {
            this.onChange(e);
          }
        }}
        onFocus={this.saveInitialValue}
        onBlur={this.onBlur}
      />
    ) : (
      <div>{this.valuesWithComma(field.value)}</div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  field: state.dataForms.form
    ? state.dataForms.form.tabs[state.dataForms.activeTab].sections[
        props.sectionIndex
      ].fieldDetails[props.index]
    : undefined,
  isSummaryTab: state.dataForms.form
    ? state.dataForms.form.tabs[state.dataForms.activeTab].tabName === 'Summary'
    : false,
  form: {
    formId: state.dataForms.form ? state.dataForms.form.formId : '',
    status: state.dataForms.form ? state.dataForms.form.status : '',
    orgProfileId: state.dataForms.form ? state.dataForms.form.orgProfileId : '',
    reportingMonth: state.dataForms.form
      ? state.dataForms.form.reportingMonth
      : '',
    reportingYear: state.dataForms.form
      ? state.dataForms.form.reportingYear
      : '',
    editable: state.dataForms.form ? state.dataForms.form.formEditable : false,
    tabStatus: state.dataForms.form?.tabs[state.dataForms.activeTab].submitted,
    formName: state.dataForms.form ? state.dataForms.form.formName : '',
    tabs: state.dataForms.form ? state.dataForms.form.tabs : ''
  },
  activeTab: state.dataForms.activeTab
});

const mapDispatchToProps = {
  updateFieldValue,
  updateDataField
};

export default connect(mapStateToProps, mapDispatchToProps)(TableInput);
