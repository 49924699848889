/* eslint-disable array-callback-return */
/* eslint-disable no-useless-return */
/* eslint-disable space-before-function-paren */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable max-lines-per-function */
import React, { Component } from 'react';
import { Button, Grid, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { TextField } from '../../../components/material';
import { FieldValidations } from '../../../components';
import { landingPageText } from '../../../constants/text';
import { passwordRequirement } from '../../../constants/passwordRule';

const passwordRequirements = passwordRequirement;

export class AccountInfo extends Component {
  state = {
    passwordHidden: true
  };

  componentDidMount() {
    const { setFieldTouched } = this.props;
    setFieldTouched('password', false);
    setFieldTouched('conformPassword', false);
  }

  toggleShowPassword = () => {
    this.setState(prevState => ({
      passwordHidden: !prevState.passwordHidden
    }));
  };

  handlePasswordChange = e => {
    e.persist();
    const { handleChange } = this.props;
    this.getPasswordValidations(e);
    handleChange(e);
  };

  render() {
    const { passwordHidden } = this.state;
    const { handleBlur, onPrevious, values, dirty, isValid } = this.props;

    return (
      <>
        <Grid
          item
          md
          data-test='form-input user-login-id'
          className='form-input user-login-id'>
          <TextField
            fullWidth
            disabled
            id='userId'
            label={landingPageText.setUserIdLabel}
            name='userLoginId'
            data-test='form-input-user-login-field'
            className='field'
            value={values.userLoginId}
          />
        </Grid>
        <Grid
          container
          className='form-input field'
          data-test='form-input-field'>
          <Grid item md className='two-column' data-test='password-grid'>
            <TextField
              fullWidth
              required
              id='password'
              type={passwordHidden ? 'password' : 'text'}
              label={landingPageText.createPasswordLabel}
              name='password'
              data-test='form-input-user-password-field'
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item md data-test='confirm-password-grid'>
            <TextField
              fullWidth
              required
              id='confirmPassword'
              type={passwordHidden ? 'password' : 'text'}
              label={landingPageText.confirmPasswordLabel}
              name='conformPassword'
              data-test='form-input-user-confirm-password-field'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      edge='end'
                      onClick={this.toggleShowPassword}
                      onMouseDown={this.toggleShowPassword}>
                      {passwordHidden ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <FieldValidations
          title={landingPageText.passwordRequirements}
          validations={passwordRequirements}
          value={values.password}
          data-test='field-validation'
          extra={[
            {
              label: 'Password and Confirm password should match',
              valid: Boolean(
                values.password && values.password === values.conformPassword
              )
            }
          ]}
        />

        <div className='seperator1' data-test='seperator1' />
        <Grid
          container
          direction='row-reverse'
          justify='space-between'
          data-test='seperator-grid-container'>
          <Grid item md={4} data-test='button-grid-container'>
            <Button
              fullWidth
              disabled={!dirty || !isValid}
              type='submit'
              variant='contained'
              data-test='button-submit'
              color='primary'>
              {landingPageText.submit}
            </Button>
          </Grid>

          <Button
            variant='outlined'
            color='secondary'
            data-test='button-previous'
            onClick={onPrevious}>
            {landingPageText.changePersonalDetails}
          </Button>
        </Grid>
        <div className='seperator' data-test='seperator' />
      </>
    );
  }
}

export default AccountInfo;
