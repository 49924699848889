/* eslint-disable react/jsx-child-element-spacing */
import React from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const ProfileHead = props => {
  // Const gridSize = props.hasButton === true ? 4 : 6;
  const { user } = props;
  const gridSize = 4;
  return (
    <Grid
      container
      className='profile-text-container'
      alignItems='center'
      data-test='profile-head-container'>
      {!props.hasButton && (
        <Grid item data-test='userid-grid' md={gridSize}>
          <Typography variant='h6' className='dci-blue profile-name'>
            GRANT
          </Typography>
        </Grid>
      )}
      <Grid item md={gridSize} className='information-txt'>
        <Typography variant='body1'>Last Modified (dd/mm/yyyy)</Typography>
        <Typography variant='body1'>
          <span className='heavy-txt'>{`${user.formattedLastModifiedDate} `}</span>
          by
          <span className='heavy-txt'>{` ${user.lastModifiedUserBy}`}</span>
        </Typography>
      </Grid>
      <Grid item md={gridSize} className='information-txt'>
        <Typography variant='body1'>Approved on (dd/mm/yyyy)</Typography>
        <Typography variant='body1'>
          <span className='heavy-txt'>{`${user.formattedRegistrationApprovedDate} `}</span>
          by
          <span className='heavy-txt'>{` ${user.registrationApprovedUserBy}`}</span>
        </Typography>
      </Grid>
      {props.hasButton === true && (
        <Grid item className='profile-button-container' md={gridSize}>
          <Button
            color='secondary'
            variant='outlined'
            disabled={!props.formDirty}
            size='small'
            data-test='reset-button'
            onClick={() => props.handleReset()}>
            Reset
          </Button>
          <Button
            color='primary'
            type='submit'
            disabled={!props.formDirty || !props.formValid}
            size='small'
            variant='contained'>
            Update
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

ProfileHead.propTypes = {
  hasButton: PropTypes.bool.isRequired,
  handleReset: PropTypes.func,
  formDirty: PropTypes.bool,
  user: PropTypes.object.isRequired
};

ProfileHead.defaultProps = {
  handleReset: () => {},
  formDirty: false
};
export default ProfileHead;
