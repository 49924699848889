import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from './Header';
import { logoutUser } from '../../store/auth';
import { getUser } from '../../store/user';

export const mapStateToProps = state => ({
  usrFullName: state.user.usrFullName,
  usrProfId: state.user.usrProfId,
  isAuthorised: state.user.isAuthorised,
  highestRole: state.user.highestRole,
  internalUserFlag: state.user.internalUserFlag,
  lastLoginDates: state.user.lastLoginDates,
  isUserAuthenticated: state.auth.isUserAuthenticated
});

const mapDispatchToProps = {
  getUser,
  logoutUser
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
