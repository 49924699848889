export const passwordRequirement = [
  {
    pattern: /^.{15,20}$/,
    label: 'MUST contain at least 15 and at most 20 characters'
  },
  {
    pattern: /[A-Z]/,
    label: 'MUST contain at least one uppercase letter'
  },
  {
    pattern: /[a-z]/,
    label: 'MUST contain at least one lowercase letter'
  },
  { pattern: /\d/, label: 'MUST contain at least one number' },
  {
    pattern: /[-!$%^&*()_+|~=`{}\[\]:\/;?,.@#]/,
    label: 'MUST contain at least one special character',
    special: '~!@#$%^&*()_+{}|:"?[];\',./-='
  },
  {
    pattern: /(.)\1/,
    label: 'MUST not contain repeating character',
    negate: true
  }
];
export const passwordMinLength = 15;
