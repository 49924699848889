import React from 'react';
import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Dropdown, Radio } from '../../material';
import DropdownSearch from '../../material/DropdownSearch';

const SelectFranchiseForm = ({
  handleSubmit,
  handleChange,
  values,
  megaMenuOrganizationNames,
  formType,
  entryType,
  allDataForms,
  orgYear,
  months,
  isRadioDisable,
  handleOrgChange,
  onRadioChange
}) => {
  return (
    <Grid
      container
      justify='space-between'
      alignItems='center'
      data-test='select-franchise-main-container'>
      <Grid item data-test='select-franchise-container'>
        <form data-test='select-franchise-form' onSubmit={handleSubmit}>
          <Grid container alignItems='flex-end' direction='row'>
            <DropdownSearch
              required
              className='megamenu-manual-data-action'
              id='organization'
              aria-label='organization'
              options={megaMenuOrganizationNames}
              label='Organization'
              name='organization'
              data-test='user-search-combobox'
              value={megaMenuOrganizationNames.find(
                org => org.id === values.organization
              )}
              style={{ width: 233, background: '#fff', fontWeight: 500 }}
              onChange={handleOrgChange}
            />
            <Dropdown
              required
              className='megamenu-manual-data-action'
              id='year'
              options={orgYear}
              label='Year'
              name='year'
              value={values.year || ''}
              style={{ background: '#fff', fontWeight: 500 }}
              data-test='select-franchise-form-dropdown-year'
              onChange={handleChange}
            />
            <Dropdown
              required
              id='month'
              className='megamenu-manual-data-action'
              options={months}
              label='Month'
              name='month'
              value={values.month || ''}
              style={{ background: '#fff', fontWeight: 500 }}
              data-test='select-franchise-form-dropdown-month'
              onChange={handleChange}
            />
            <Button
              className='megamenu-manual-data-action'
              variant='contained'
              color='primary'
              size='small'
              style={{ height: 40 }}
              type='submit'
              data-test='select-franchise-form-update-button'>
              Update
            </Button>
          </Grid>
        </form>
      </Grid>

      {entryType === 'manual' && (
        <Grid item data-test='radio-btns' style={{ marginRight: '-12%' }}>
          <Radio
            name='monthly'
            label='Monthly Forms'
            value='monthlyDataForms'
            checked={formType === 'monthlyDataForms'}
            disabled={isRadioDisable}
            data-test='select-franchise-monthly-radio-button'
            onChange={onRadioChange}
          />
          <Radio
            name='quarterly'
            label='Quarterly Forms'
            data-test='select-franchise-quarterly-radio-button'
            value='quarterlyDataForms'
            checked={formType === 'quarterlyDataForms'}
            disabled={
              isRadioDisable || allDataForms.quarterlyDataForms.length === 0
            }
            onChange={onRadioChange}
          />
          <Radio
            name='annually'
            label='Annual Forms'
            data-test='select-franchise-annually-radio-button'
            value='annuallyDataForms'
            checked={formType === 'annuallyDataForms'}
            disabled={
              isRadioDisable || allDataForms.annuallyDataForms.length === 0
            }
            onChange={onRadioChange}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SelectFranchiseForm;
