import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import DisplayForms from './DisplayForms';

export const DataEntry = props => {
  const {
    onClose,
    forms,
    form,
    setDataForms,
    resetDataForms,
    formType
  } = props;
  return (
    <Grid
      container
      className='megamenu-section'
      direction='column'
      justify='flex-start'
      data-test='component-manual-entry-megamenu-section'
      style={{ marginTop: 30, marginLeft: -66 }}>
      <Grid item>
        <Grid container alignItems='center' justify='flex-start'>
          <Typography
            className='megamenu-gray'
            variant='h5'
            data-test='manual-entry-heading'>
            Manual Entry{' '}
            {forms.length > 0 && forms[0].isUserSubmitted ? '(Read Only)' : ''}
          </Typography>
        </Grid>
      </Grid>
      <DisplayForms
        data-test='display-forms'
        forms={forms}
        form={form}
        setDataForms={setDataForms}
        resetDataForms={resetDataForms}
        formType={formType}
        onClose={onClose}
      />
    </Grid>
  );
};

DataEntry.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default DataEntry;
