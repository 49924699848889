import api from './';

const authEndpoints = {
  login: 'auth/user/login',
  logout: 'logout',
  getOrganizationNames: 'auth/orgs',
  isUserExists: 'auth/user/exists',
  isValidUserProfile: 'auth/user/validate',
  registerUser: 'auth/user/register',
  validateOTP: 'auth/validate/otp',
  forgotPassword: 'auth/user/forgotpassword',
  generateUserId: 'auth/generate/userid',
  gettingSessionTimeOut: 'get/session/timeout',
  extendSession: '/extend/session',
  acceptTerms: '/terms/conditions',
  legacyUserReset: 'auth/user/login/reset'
};

export const loginApi = async user => {
  return await api().post(authEndpoints.login, user);
};

export const logoutApi = async () => {
  const res = await api().get(authEndpoints.logout);
  return res.data;
};

export const isUserExistsApi = async body => {
  const res = await api().post(authEndpoints.isUserExists, body);
  return res.data;
};

export const generateUserIdApi = async body => {
  const res = await api().post(authEndpoints.generateUserId, body);
  return res.data;
};

export const isValidUserProfileApi = async body => {
  const res = await api().post(authEndpoints.isValidUserProfile, body);
  return res.data;
};

export const registerUser = async body => {
  return api().post(authEndpoints.registerUser, body);
};

export const forgotPasswordApi = async body => {
  const res = await api().post(authEndpoints.forgotPassword, body);
  return res.data;
};
export const legacyUserResetApi = async body => {
  const res = await api().post(authEndpoints.legacyUserReset, body);
  return res.data;
};

export const validateOTPApi = async otp => {
  return await api().post(authEndpoints.validateOTP, otp);
};

export const extendSessionApi = async () => {
  return await api().post(authEndpoints.extendSession);
};
export const gettingSessionTimeOutApi = async () => {
  return await api().get(authEndpoints.gettingSessionTimeOut);
};

export const acceptTermsAndConditionsApi = async body => {
  return await api().post(
    `${authEndpoints.acceptTerms}?consentFlag=${body.consentFlag}&userProfileId=${body.userProfileId}`
  );
};
