import React, { Fragment } from 'react';
import { Typography, Button } from '@material-ui/core';
import SuccessIcon from '../../assets/icons/success-icon.svg';
import miu from '../../assets/icons/miu.svg';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { connect } from 'react-redux';
const UploadSuccess = props => {
  const { forms, userName } = props;
  const [form] = forms;
  return (
    <Fragment>
      <div className='success-container'>
        <div className='upload-icon-outer'>
          <div className='upload-icon-inner'>
            <div className='successContainer'>
              <img
                src={SuccessIcon}
                data-test='success-icon'
                className='success-icon'
                alt='Json icon'
              />
            </div>
          </div>
        </div>
        <Typography
          className='upload-success-txt'
          variant='h6'
          data-test='upload-success-txt'>
          {props.successMessage}
        </Typography>
        <Typography
          variant='h6'
          data-test='file-size'
          className='file-info-txt'>
          {props.fileName} ({props.fileSize}MB)
        </Typography>
        <Typography
          variant='body1'
          data-test='uploading-info'
          className='uploading-info'>
          Uploaded by <span>{userName}</span> on{' '}
          <span data-test='date-success-span'>
            {format(new Date(), 'dd LLL yyyy  HH:mm')}
          </span>
        </Typography>
        <Link
          to={`/manage-data/upload/review/${form.formId}`}
          aria-label='logout-link'>
          <Button
            classes={{ root: 'data-review-button' }}
            color='primary'
            variant='contained'
            aria-label='data review button'
            data-test='review-button'>
            <Typography className='cancel-button-txt' variant='body1'>
              Review Data
            </Typography>
          </Button>
        </Link>
      </div>
      <Typography
        variant='body1'
        data-test='uploading-info'
        className='uploading-info uploading-info-subhead'>
        Do you think you've uploaded a wrong file?
      </Typography>
      <Typography
        role='button'
        aria-label='Upload New File'
        variant='body1'
        className='success-upload-new'
        onClick={props.fileSelectHandler}>
        Upload New File <img src={miu} alt='new-upload' />
      </Typography>
    </Fragment>
  );
};

UploadSuccess.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.string.isRequired,
  fileSelectHandler: PropTypes.func.isRequired,
  forms: PropTypes.array.isRequired,
  userName: PropTypes.string.isRequired
};
export const mapStateToProps = state => ({
  forms: state.formsListNav.forms,
  userName: state.user.usrFullName
});

export { UploadSuccess };
export default connect(mapStateToProps)(UploadSuccess);
