import React, { Component } from 'react';
import { Typography, Grid, Box, Chip } from '@material-ui/core';

export class ContractInfo extends Component {
  render() {
    const { singleContract } = this.props;

    return (
      <Grid container data-test='component-ContractInfo'>
        <Grid item md={12} className='row-header-bar'>
          <Typography variant='h6'>Contract Info</Typography>
        </Grid>
        <Grid item md={12}>
          <Grid container className='row'>
            <Grid item md={4} className='p-15'>
              <Typography variant='body2'>Contract ID</Typography>
              <Typography variant='h6' className='row-value'>
                {singleContract.contractId}
              </Typography>
            </Grid>
            <Grid item md={4} className='p-15'>
              <Typography variant='body2'>Organization Name</Typography>
              <Typography variant='h6' className='row-value'>
                {singleContract.organizationName}
              </Typography>
            </Grid>
            <Grid item md={4} className='p-15'>
              <Typography variant='body2'>Contract Type</Typography>
              <Typography variant='h6' className='row-value'>
                {singleContract.contractTypeCode}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid container className='row'>
            <Grid item md={4} className='p-15'>
              <Typography variant='body2'>
                Effective Start Date (dd/mm/yyyy)
              </Typography>
              <Typography variant='h6' className='row-value'>
                {singleContract.effectiveStartDate}
              </Typography>
            </Grid>
            <Grid item md={4} className='p-15'>
              <Typography variant='body2'>
                Effective End Date (dd/mm/yyyy)
              </Typography>
              <Typography variant='h6' className='row-value'>
                {singleContract.effectiveEndDate}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid container className='row'>
            <Grid item md={12} className='p-15'>
              <Typography variant='h6'>Organization Profile Name</Typography>
              {singleContract.organizationProfileList &&
                Object.entries(singleContract.organizationProfileList).map(
                  ([k, v]) => (
                    <Box key={k} className='child-org-child-container'>
                      <Chip label={v} />
                    </Box>
                  )
                )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default ContractInfo;
