import React from 'react';
import { connect, useFormikContext } from 'formik';
import { DatePicker as Picker } from '@material-ui/pickers';

export const DatePicker = props => {
  const { setFieldValue } = useFormikContext();
  const fieldProps = { ...props };
  delete fieldProps.formik;
  const {
    name,
    formik: { values, handleBlur, touched, errors, status }
  } = props;
  // For Announcement Screen
  if (touched.scheduled || status.scheduled) {
    touched[name] = true;
  }
  return (
    <Picker
      data-test='component-DatePicker'
      style={{ display: 'block' }}
      value={values[name]}
      error={
        touched[name] && Boolean(errors[name] || status[name] || status.root)
      }
      helperText={touched[name] && (errors[name] || status[name])}
      onChange={val => {
        setFieldValue(name, val);
      }}
      onBlur={handleBlur}
      {...fieldProps}
    />
  );
};

export default connect(DatePicker);
