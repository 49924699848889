import * as Yup from 'yup';
import rules from '../../fieldRules';

const {
  salutation,
  firstName,
  lastName,
  middleName,
  jobTitle,
  emailId
} = rules;

export const userDetailsSchema = Yup.object().shape({
  salutation,
  firstName,
  middleName,
  lastName,
  emailId,
  jobTitle
});
