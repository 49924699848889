import { connect } from 'react-redux';
import ProductLine from './ProductLine';
import {
  getAllProducts,
  createProducts
} from '../../../store/organizations/actions';

export const mapStateToProps = state => ({
  totalProducts: state.organizations.totalProducts
});

const mapDispatchToProps = {
  getAllProducts,
  createProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductLine);
