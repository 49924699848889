import React from 'react';
import PropTypes from 'prop-types';
import ComboBox from '../../../../components/material/Combobox';
import { Grid, TextField } from '@material-ui/core';
import { Dropdown } from '../../../../components/material';

const UserSearchCriteria = props => {
  return (
    <Grid container className='user-search-fields-container'>
      <Grid
        container
        alignItems='flex-end'
        direction='row'
        style={{ marginLeft: '3%' }}>
        <Grid item xs={4}>
          <TextField
            id='userLoginId'
            data-test='userID-input'
            label='User ID'
            name='userLoginId'
            className='form-input user-search-textbox'
            value={props.userLoginId}
            onChange={e =>
              props.onSearchCriteriaChange('userLoginId', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id='firstName'
            data-test='firstname-input'
            label='First Name'
            name='firstname'
            className='form-input user-search-textbox'
            value={props.firstName}
            onChange={e =>
              props.onSearchCriteriaChange(
                'firstName',
                e.target.value.replace(/[^a-z]/gi, '')
              )
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id='lastName'
            data-test='lastname-input'
            label='Last Name'
            name='lastname'
            className='form-input user-search-textbox'
            value={props.lastName}
            onChange={e =>
              props.onSearchCriteriaChange(
                'lastName',
                e.target.value.replace(/[^a-z]/gi, '')
              )
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems='flex-end'
        direction='row'
        style={{ marginLeft: '3%' }}>
        <Grid item xs={4}>
          <ComboBox
            id='businessName'
            data-test='user-search-combobox'
            name='Business Name'
            className='user-search-textbox'
            label='Select Organization Name'
            options={props.adminsOrganizations}
            style={{ marginTop: '5%' }}
            value={props.businessName}
            onChange={e =>
              props.onSearchCriteriaChange(
                'organizationProfile',
                e.target.value
              )
            }
          />
        </Grid>
        <Grid item xs={4}>
          <Dropdown
            options={[
              { label: 'Active', value: 'A' },
              { label: 'Pending', value: 'P' },
              { label: 'Inactive', value: 'I' }
            ]}
            name='userStatus'
            label='Status'
            value={props.status}
            data-test='user-search-dropdown'
            placeholder=' '
            className='user-search-textbox'
            onChange={e =>
              props.onSearchCriteriaChange('accountStatus', e.target.value)
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

UserSearchCriteria.propTypes = {
  businessName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  userLoginId: PropTypes.string.isRequired,
  onSearchCriteriaChange: PropTypes.func.isRequired,
  adminsOrganizations: PropTypes.array.isRequired
};

export default UserSearchCriteria;
