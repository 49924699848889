import api from './';

export const LABELS = {
  PASSUPD: 'user/updatepwd'
};
const userEndpoints = {
  getUserProfileInformation: 'user/profile',
  updateUserProfile: 'user/update',
  changepasswordProfile: LABELS.PASSUPD,
  getUser: 'user',
  forgotUserId: 'auth/user/forgotuserid',
  updateUserPassword: 'auth/user/changepwd'
};

export const getUserProfileInformationApi = async profileId => {
  return await api().get(
    `${userEndpoints.getUserProfileInformation}?viewUserProfileId=${profileId}`
  );
};

export const updateUserProfileApi = async body => {
  try {
    return await api().put(userEndpoints.updateUserProfile, body);
  } catch (err) {
    return err.response;
  }
};

export const changepasswordProfileApi = async body => {
  const res = await api().put(userEndpoints.changepasswordProfile, body);
  return res.data;
};

export const getUserApi = async () => {
  return await api().get(userEndpoints.getUser);
};

export const getUserIdApi = async user => {
  return await api().post(`${userEndpoints.forgotUserId}?emailId=${user}`);
};

export const changePasswordApi = async body => {
  const res = await api().put(userEndpoints.updateUserPassword, body);
  return res.data;
};
