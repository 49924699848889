import React, { Component } from 'react';
import { Typography, Grid, Chip, InputAdornment, Box } from '@material-ui/core';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import {
  MultipleCombobox,
  Dropdown,
  DatePicker as FormikDatePicker
} from '../../../../components/material';
import { Combobox } from '../../../../components/formik';

export class ContractInfo extends Component {
  render() {
    const {
      parentOrganizations,
      childOrganizations,
      deleteChildOrganizationProfile,
      handleChange,
      onChildOrgSelectAll,
      onChildOrgUnSelectAll,
      values,
      errors,
      status,
      touched,
      handleBlur,
      organizationId
    } = this.props;

    let placeHolder = '';
    if (!values.globalnetOrganizationId && organizationId) {
      values.globalnetOrganizationId = organizationId;
      const e = {
        target: {
          name: 'globalNetOrganizationId',
          value: { id: organizationId }
        }
      };
      this.props.handleChange(e);
      const organization = parentOrganizations.filter(org => {
        return org.id === organizationId;
      });
      if (organization) {
        const { label } = organization[0];
        placeHolder = label;
      }
    }

    const childOrgError =
      (touched.organizationProfileId &&
        Boolean(
          errors.organizationProfileId ||
            status.organizationProfileId ||
            status.root
        )) ||
      false;
    const childOrgMessage =
      (touched.organizationProfileId &&
        (errors.organizationProfileId || status.organizationProfileId)) ||
      '';
    const contractTypeError =
      (touched.contractType &&
        Boolean(errors.contractType || status.contractType || status.root)) ||
      false;
    const contractTypeMessage =
      (touched.contractType && (errors.contractType || status.contractType)) ||
      '';

    return (
      <Grid container data-test='component-ContractInfo'>
        <Grid item md={12} className='row-header-bar'>
          <Typography variant='h6'>Contract Info</Typography>
        </Grid>
        <Grid item md={12}>
          <Grid container direction='row' justify='space-between'>
            <Grid item md={4} className='body-item-column'>
              <Combobox
                required
                name='globalNetOrganizationId'
                id='globalNetOrganizationId'
                label='Select Organization'
                value={values.globalnetOrganizationId}
                options={parentOrganizations}
                placeholder={placeHolder}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={3} className='body-item-column'>
              <Dropdown
                required
                options={[
                  { label: 'SE', value: 'SE' },
                  { label: 'CM', value: 'CM' },
                  { label: 'CUST', value: 'CUST' }
                ]}
                name='contractType'
                helperText={contractTypeMessage}
                error={contractTypeError}
                label='Contract Type'
                value={values.contractType}
                data-test='user-search-dropdown'
                className='user-search-dropdown'
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={5} className='body-item-column'>
              <Grid container direction='row' justify='space-between'>
                <Grid item md={6}>
                  <FormikDatePicker
                    autoOk
                    disablePast
                    required
                    variant='inline'
                    inputVariant='outlined'
                    label='Effective Start Date (dd/mm/yyyy)'
                    format='dd/MM/yyyy'
                    name='effectiveStartDate'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <CalendarIcon />
                        </InputAdornment>
                      )
                    }}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item md={6}>
                  <FormikDatePicker
                    autoOk
                    disablePast
                    required
                    variant='inline'
                    inputVariant='outlined'
                    label='Effective End Date (dd/mm/yyyy)'
                    format='dd/MM/yyyy'
                    name='effectiveEndDate'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <CalendarIcon />
                        </InputAdornment>
                      )
                    }}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid container direction='row'>
            <Grid item md={4} className='body-item-column'>
              <MultipleCombobox
                required
                error={childOrgError}
                helperText={childOrgMessage}
                name='organizationProfileId'
                id='organizationProfileId'
                label='Select Organization Profile'
                options={childOrganizations.filter(
                  org =>
                    !Object.keys(values.organizationProfileList).includes(
                      org.id
                    )
                )}
                value={[]}
                onChange={handleChange}
                onSelectAll={onChildOrgSelectAll}
                onUnselectAll={onChildOrgUnSelectAll}
              />
            </Grid>
            <Grid item md={8} className='body-item-column'>
              {values.organizationProfileList &&
                Object.entries(values.organizationProfileList).map(([k, v]) => (
                  <Box key={k} className='child-org-child-container'>
                    <Chip
                      label={v}
                      onDelete={e => deleteChildOrganizationProfile(e, k)}
                    />
                  </Box>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default ContractInfo;
