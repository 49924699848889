import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box } from '@material-ui/core';
import { ADMIN_TEXT } from '../../../../constants/text';
import { Switch } from '../../../../components/material';

class UserPersonalDataSection extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  sectionOne = user => {
    return (
      <Grid container className='row'>
        <Grid item md={4}>
          <Grid container direction='column'>
            <Typography variant='body2' className='data-title'>
              User ID
            </Typography>
            <Typography variant='h6' className='data-value'>
              {user.userLoginId}
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={4}>
          <Grid container direction='column'>
            <Typography variant='body2' className='data-title'>
              {ADMIN_TEXT.salutation}
            </Typography>
            <Typography variant='h6' className='data-value'>
              {user.prefix}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { user } = this.props;
    return (
      <Grid container data-test='user-personal-data-component'>
        <Grid item md={12} className='user-personal-data-container'>
          <Typography variant='h6'>{ADMIN_TEXT.personalData}</Typography>
        </Grid>
        <Grid item md={12} className='user-personal-data-body-section'>
          {this.sectionOne(user)}

          <Grid container className='row'>
            <Grid item md={4}>
              <Grid container direction='column'>
                <Typography variant='body2' className='data-title'>
                  {ADMIN_TEXT.firstName}
                </Typography>
                <Typography variant='h6' className='data-value'>
                  {user.firstName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Grid container direction='column'>
                <Typography variant='body2' className='data-title'>
                  {ADMIN_TEXT.middleName}
                </Typography>
                <Typography variant='h6' className='data-value'>
                  {user.middleName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Grid container direction='column'>
                <Typography variant='body2' className='data-title'>
                  {ADMIN_TEXT.lastName}
                </Typography>
                <Typography variant='h6' className='data-value'>
                  {user.lastName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className='row'>
            <Grid item md={4}>
              <Grid container direction='column'>
                <Typography variant='body2' className='data-title'>
                  {ADMIN_TEXT.emailId}
                </Typography>
                <Typography variant='h6' className='data-value'>
                  {user.emailId}
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Grid container direction='column'>
                <Typography variant='body2' className='data-title'>
                  {ADMIN_TEXT.jobTitle}
                </Typography>
                <Typography variant='h6' className='data-value'>
                  {user.jobTitle}
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Grid container direction='column'>
                <Typography variant='body2' className='data-title'>
                  Active
                </Typography>
                <Box className='data-value'>
                  <Switch
                    disabled
                    checked={user.accountStatus === 'A'}
                    label=''
                    name='active'
                    value='yes'
                    labelPlacement='top'
                    onChange={e => {
                      console.log(e);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default UserPersonalDataSection;
