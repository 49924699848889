import React from 'react';
import './TextFieldSearch.css';
import { Input } from '@material-ui/core';
const TextFieldSearch = props => {
  const { onChange, value } = props;
  return (
    <div
      className='textfield-search-container'
      data-test='textfield-search-container'>
      <Input
        disableUnderline
        fullWidth
        type='search'
        value={value}
        placeholder='Search User ID'
        data-test='Search User ID'
        inputProps={{
          style: {
            padding: '0px 12px',
            fontSize: '14px',
            fontFamily: 'Meta Offc Pro, Arial',
            fontWeight: '500',
            lineHeight: '20px',
            height: '41px'
          }
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default TextFieldSearch;
