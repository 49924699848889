import React from 'react';
import DataRow from './DataRow';

import { rowHasError } from './../../utils/commonFunctions';

const RowGroup = props => {
  const { iterator, tableBody, tableHeaders, updateFunction } = props;
  return iterator.map(i => (
    <tr key={i}>
      <td className={rowHasError(tableBody[i].rowData) ? 'error' : undefined}>
        {tableBody[i].rowLabel}
      </td>
      <DataRow
        row={tableBody[i].rowData}
        rowNumber={i}
        tableHeaders={tableHeaders}
        updateFunction={updateFunction}
      />
    </tr>
  ));
};

export default RowGroup;
