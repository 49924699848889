import React from 'react';
import {
  Switch as MaterialSwitch,
  FormControlLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = {
  start: {
    marginRight: 10
  },
  end: {
    marginLeft: 10
  },
  top: {
    marginTop: 5
  },
  bottom: {
    marginBottom: 5
  }
};

export const Switch = props => {
  const { label, labelPlacement, classes, labelTextClass, labelStyle } = props;

  const rootProps = { ...props };
  delete rootProps.labelTextClass;
  delete rootProps.classes;
  delete rootProps.labelStyle;

  return (
    <FormControlLabel
      data-test='Component-Switch'
      {...rootProps}
      control={<MaterialSwitch style={{ padding: 0 }} />}
      label={
        <Typography
          variant='body2'
          style={labelStyle}
          className={`${classes[labelPlacement]} ${labelTextClass}`}>
          {label}
        </Typography>
      }
    />
  );
};

Switch.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  value: PropTypes.string.isRequired,
  labelPlacement: PropTypes.oneOf(['start', 'end', 'top', 'bottom']),
  labelTextClass: PropTypes.string,
  classes: PropTypes.object.isRequired,
  labelStyle: PropTypes.object
};

Switch.defaultProps = {
  label: '',
  checked: false,
  disabled: false,
  labelPlacement: 'start',
  labelTextClass: '',
  labelStyle: {}
};

export default withStyles(styles)(Switch);
