import React, { useEffect, useState } from 'react';
import './UserLog.css';
import { Grid, TableRow, TableCell, Button, Tooltip } from '@material-ui/core';
import { withFormik } from 'formik';
import MaterialTable from '../../../../components/material/MaterialTable';
import Header from './UserLogsHeader';
import UserLogsButtonContainer from './UserLogsButtonContainer';
import UserLogsCriteria from './UserLogsCriteria';
import { toastFunc } from '../../../../components/ToastComponent/toastFunction';
import * as Yup from 'yup';
import * as XLSX from 'xlsx';
import { blobToObject } from '../../../../store/upload/actions';
import GetAppIcon from '@material-ui/icons/GetApp';
import { format } from 'date-fns';
export const UserLog = props => {
  const {
    userProfilesLog,
    getAdminOrganizationNames,
    clearLogsProfileList,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    errors,
    touched,
    isValid,
    dirty
  } = props;
  const [formReset, setFormReset] = useState(false);
  const tableHeaders = [
    'User ID',
    'First Name',
    'Last Name',
    'Email ID',
    'Role',
    'Organization Profile Name',
    'Status',
    ''
  ];

  useEffect(() => {
    getAdminOrganizationNames();
    clearLogsProfileList();
  }, []);
  const getTextColor = value => {
    if (value === 'A') {
      return '#239B06';
    }
    if (value === 'P') {
      return '#CC8007';
    }
    if (value === 'I') {
      return '#CC8007';
    }
    return '#EB4D4D ';
  };

  const getStatus = value => {
    if (value === 'A') {
      return 'Active';
    }
    if (value === 'P') {
      return 'Pending for Approval';
    }
    if (value === 'R') {
      return 'Reject';
    }
    return 'Inactive';
  };

  const clearFieldsHandler = () => {
    resetForm();
    clearLogsProfileList();
    setFormReset(false);
  };
  const exportHandler = () => {
    const userProfilesWithoutKey = [];
    if (userProfilesLog !== undefined && userProfilesLog !== null) {
      userProfilesLog.map(data => {
        return userProfilesWithoutKey.push({
          'User ID': data.userId,
          'First Name': data.firstName,
          'Last Name': data.lastName,
          'Email Id': data.emailId,
          Role: data.roleName,
          'Organization Profile Name': data.orgName,
          Status: getStatus(data.status)
        });
      });
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    const startDate = format(values.startDate, 'yyyy-MM-dd');
    const endDate = format(values.endDate, 'yyyy-MM-dd');
    const fileName = `${values.orgProfileId.label}_${startDate}_to_${endDate}_User_Log`;
    const apiData = userProfilesWithoutKey;
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    blobToObject(data, fileName, fileExtension);
  };
  return (
    <Grid container item data-test='user-search-component'>
      <Grid
        container
        className='user-search-container'
        data-test='user-search-container'
        alignItems='flex-start'
        alignContent='flex-start'>
        <Header data-test='user-search-header' />
        <Grid
          container
          item
          className='user-search-criteria-container'
          data-test='user-search-criteria-container'>
          <form style={{ width: '100%' }}>
            <UserLogsCriteria
              adminsOrganizations={props.usersOrganizations}
              data-test='user-search-criteria'
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
            />
            <UserLogsButtonContainer
              handleSubmit={handleSubmit}
              setFormReset={setFormReset}
              resetForm={clearFieldsHandler}
              isValid={isValid}
              values={values}
              dirty={dirty}
              data-test='user-Log-Button-criteria'
            />
          </form>
        </Grid>
        {(userProfilesLog.length > 0 || formReset) && (
          <>
            <MaterialTable
              tableHeaders={tableHeaders}
              count={userProfilesLog.length ? userProfilesLog.length : 0}
              downloadIconTooltip='Export'
              data-test='user-Log-material-table'
              onDownloadIcon={Boolean(userProfilesLog.length > 0)}
              onClickIconDownload={exportHandler}>
              {userProfilesLog.length > 0
                ? userProfilesLog.map((user, index) => (
                    <TableRow key={index} data-test='user-log-table-row'>
                      <TableCell data-test='user-Log-material-table-userId'>
                        {user.userId}
                      </TableCell>
                      <TableCell data-test='user-Log-material-table-firstName'>
                        {user.firstName}
                      </TableCell>
                      <TableCell data-test='user-Log-material-table-lastName'>
                        {user.lastName}
                      </TableCell>
                      <TableCell data-test='user-Log-material-table-emailId'>
                        {user.emailId}
                      </TableCell>
                      <TableCell data-test='user-Log-material-table-roleName'>
                        {user.roleName}
                      </TableCell>
                      <TableCell data-test='user-Log-material-table-orgName'>
                        {user.orgName}
                      </TableCell>
                      <TableCell
                        style={{ color: getTextColor(user.status) }}
                        data-test='user-Log-material-table-status'>
                        {getStatus(user.status)}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </MaterialTable>
            {userProfilesLog.length > 0 ? (
              <Grid
                container
                item
                className='user-log-export-container'
                data-test='user-search-criteria'>
                <Grid
                  container
                  item
                  className='export-buttons-container'
                  data-test='export-buttons-container'>
                  <Tooltip
                    placement='bottom'
                    title='Export'
                    data-test='tooltip-export-button'>
                    <Button
                      color='primary'
                      type='submit'
                      size='small'
                      variant='contained'
                      aria-label='submit'
                      data-test='export-button'
                      onClick={exportHandler}>
                      <GetAppIcon data-test='export-icon' />
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : null}
          </>
        )}
      </Grid>
    </Grid>
  );
};

const formConfig = withFormik({
  mapPropsToValues: () => ({
    orgProfileId: '',
    status: '',
    startDate: null,
    endDate: null
  }),

  mapPropsToStatus: () => ({}),
  validationSchema: Yup.object().shape({
    orgProfileId: Yup.object().required('Select Organization Name'),
    startDate: Yup.string()
      .required('Select Start Date')
      .nullable(),
    endDate: Yup.string()
      .required('Select End Date')
      .nullable(),
    status: Yup.string()
  }),

  handleSubmit: (values, formikBag) => {
    const convertedNewStartDate = format(values.startDate, 'yyyy-MM-dd');
    const convertedNewEndDate = format(values.endDate, 'yyyy-MM-dd');
    const userLog = {
      orgProfileId: values.orgProfileId.id,
      endDate: convertedNewEndDate,
      startDate: convertedNewStartDate,
      status: values.status
    };
    formikBag.props.getUserProfileLogsResults(userLog).then(response => {
      if (response !== undefined) {
        if (response.status === 404) {
          formikBag.props.clearLogsProfileList();
        } else if (response.status === 400) {
          toastFunc({
            content: `${
              response.data
                ? response.data.errorMessage
                  ? response.data.errorMessage
                  : response.data
                : response.data
            }`,
            config: { className: 'toast-container reject' },
            toastType: 'error'
          });
          formikBag.props.clearLogsProfileList();
        } else {
          toastFunc({
            content: 'Something went wrong. Please try again.',
            config: { className: 'toast-container reject' },
            toastType: 'error'
          });
          formikBag.props.clearLogsProfileList();
        }
      }
    });
  }
});

export default formConfig(UserLog);
