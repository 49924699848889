/* eslint-disable max-lines-per-function */
import React from 'react';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import { HOME_TEXT } from '../../constants/text';

import PropTypes from 'prop-types';

const NavigationButton = props => {
  const {
    title,
    description,
    reviews,
    Icon,
    admin,
    isSelected,
    onClick
  } = props;

  return (
    <Button
      aria-label={title}
      className={`flex-1 margin nav-btn-container nav-btn w-100 ${
        isSelected ? ' nav-button-selected' : ''
      }`}
      data-test='component-navigation-button'
      onClick={onClick}>
      <Grid container alignItems='center' justify='flex-start'>
        <Grid item>
          <Icon className='nav-btn-icon' data-test='navigation-icon' />
        </Grid>

        <Grid item className='flex-1 nav-description-section'>
          <Grid container direction='column' justify='flex-start'>
            <Typography
              variant='h4'
              data-test='navigation-title'
              className='nav-btn-title'>
              {title}
            </Typography>
            <Typography
              variant='body2'
              className='nav-btn-description'
              data-test='navigation-description'>
              {description}
            </Typography>
          </Grid>
        </Grid>
        {admin && Number(reviews) !== 0 && (
          <Grid
            item
            md={3}
            lg={3}
            xs={3}
            className='nav-btn-review'
            data-test='navigation-review'>
            <Grid
              container
              direction='column'
              justify='center'
              alignItems='center'>
              <Box
                bgcolor='primary.main'
                className={`nav-btn-review-circle ${
                  isSelected ? 'nav-btn-review-circle-selected' : ''
                }`}
                data-test='navigation-button-circle'>
                <Typography
                  variant='h4'
                  data-test='navigation-review-pending-count'
                  className='nav-btn-review-title'>
                  {reviews}
                </Typography>
              </Box>
              <Typography
                variant='caption'
                className='underline pending-review-text'
                align='center'
                data-test='navigation-review-pending'>
                {HOME_TEXT.pendingReviews}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Button>
  );
};

NavigationButton.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  Icon: PropTypes.object,
  reviews: PropTypes.string,
  admin: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

NavigationButton.defaultProps = {
  Icon: {},
  reviews: '0',
  isSelected: false
};

export default NavigationButton;
