import React, { Component } from 'react';
import { TableRow, TableCell, Button } from '@material-ui/core';
import { Switch } from '../../../components/material';

import {
  TextFieldGrid,
  ComboboxGrid,
  DropdownGrid
} from '../../../components/formik';

import { withFormik } from 'formik';
import { isOrganizationProfileExistsApi } from '../../../api/organizations';
import { createStatusFromErrors } from '../../../utils/commonFunctions';
import { childOrganizationEditSchema } from '../../../constants/validations/admin/organization';

export class OrgTableRowEdit extends Component {
  handleBlur = e => {
    const { handleBlur, status, setStatus } = this.props;
    e.persist();
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };

  render() {
    const {
      org,
      currencies,
      orgTypes,
      values,
      handleChange,
      handleSubmit,
      onCancel,
      dirty
    } = this.props;
    return (
      <TableRow
        className='form'
        style={{ paddingTop: 10 }}
        data-test='org-table-row-component'>
        <TableCell className='organization-page-main-text'>
          {org && org?.organizationProfileId ? org.organizationProfileId : null}
        </TableCell>
        <TableCell>
          <TextFieldGrid
            data-test='org-profile-name'
            className='oraganization-profile-table-fields'
            label='Enter Org Profile Name'
            id={`org-profile-name-${org.organizationProfileId}`}
            style={{ width: 180 }}
            name='organizationProfileName'
            onBlur={this.handleBlur}
          />
        </TableCell>
        <TableCell>{org.orgRegionInfo.regionTypeDescription}</TableCell>
        <TableCell>{org.operatingCountryCodeInfo.countryIsoName}</TableCell>
        <TableCell>
          <DropdownGrid
            label='Select/Type Entity'
            id={`org-profile-entity-${org.organizationProfileId}`}
            className='oraganization-profile-table-fields'
            style={{ width: 200 }}
            options={orgTypes}
            name='entityType'
            onBlur={this.handleBlur}
          />
        </TableCell>
        <TableCell>
          <ComboboxGrid
            label='Select'
            className='oraganization-profile-table-fields'
            id={`org-profile-currencies-${org.organizationProfileId}`}
            options={currencies}
            style={{
              width: 150,
              display: 'flex',
              alignItems: 'center',
              position: 'relative'
            }}
            name='currency'
            onBlur={this.handleBlur}
          />
        </TableCell>
        <TableCell>{org.iicISOCode}</TableCell>
        <TableCell>{org.iicDXSCode}</TableCell>
        <TableCell>
          <Switch
            id={`org-profile-active-${org.organizationProfileId}`}
            name='extras'
            value='active'
            checked={values.extras.includes('active')}
            onChange={handleChange}
            onBlur={this.handleBlur}
          />
        </TableCell>
        <TableCell>
          <Switch
            id={`org-profile-ff-${org.organizationProfileId}`}
            name='extras'
            value='financialForce'
            checked={values.extras.includes('financialForce')}
            onChange={handleChange}
            onBlur={this.handleBlur}
          />
        </TableCell>
        <TableCell>
          <Button
            data-test='submit-button'
            size='small'
            color='primary'
            variant='contained'
            disabled={!dirty}
            onClick={handleSubmit}>
            Save
          </Button>
        </TableCell>
        <TableCell>
          <Button
            data-test='cancel-button'
            size='small'
            color='secondary'
            variant='outlined'
            onClick={() => onCancel(org.organizationProfileId)}>
            Cancel
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

const formConfig = withFormik({
  mapPropsToValues: ({ org }) => {
    const extras = [];
    if (org.activeStatus === 'T' || org.activeStatus === 'Y') {
      extras.push('active');
    }
    if (org.sendToFinancialForce === 'T' || org.sendToFinancialForce === 'Y') {
      extras.push('financialForce');
    }

    return {
      organizationProfileId: org.organizationProfileId,
      organizationProfileName: org.organizationProfileName,
      entityType: org.orgTypeInfo.orgTypNm,
      currency: {
        id: org.currencyInfo.currencyTypeCode,
        label: org.currencyInfo.currencyName,
        value: org.currencyInfo.currencyTypeCode
      },
      extras
    };
  },
  isInitialValid: true,

  validationSchema: childOrganizationEditSchema,

  mapPropsToStatus: () => ({}),

  handleSubmit: (values, formikBag) => {
    const body =
      formikBag.props.add || formikBag.props.org.status === 'A'
        ? {
            organizationProfileName: values.organizationProfileName,
            organizationProfileId: 0
          }
        : {
            organizationProfileName: values.organizationProfileName,
            organizationProfileId: values.organizationProfileId
          };
    return isOrganizationProfileExistsApi(body)
      .then(() => {
        formikBag.props.onSubmit(values);
        formikBag.resetForm();
      })
      .catch(err => {
        formikBag.setStatus(createStatusFromErrors(err));
      });
  }
});
export default formConfig(OrgTableRowEdit);
