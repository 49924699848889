import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { ActionBar } from '../../../../components';

import ContractInfo from './ContractInfo';
import RoyaltyExclusionTerms from './RoyaltyExclusionTerms';
import RoyaltyRateScale from '../RoyaltyRateScale';
import ContractNotesList from '../ContractNotesList';
import { ReactComponent as BackButtonIcon } from '../../../../assets/icons/back-icon.svg';
import { getContractById } from '../../../../store/contracts/actions';

export const HandleHeaderLeft = props => {
  const {
    history,
    match: { params }
  } = props;
  return (
    <Grid
      container
      justify='flex-start'
      style={{ cursor: 'pointer' }}
      onClick={() => history.push(`/admin/contract/${params.orgId}`)}>
      <Grid item>
        <BackButtonIcon />
      </Grid>
      <Grid item>
        <Typography className='back-btn-text row-value' variant='h6'>
          Back to Contracts
        </Typography>
      </Grid>
    </Grid>
  );
};

export class View extends Component {
  componentDidMount() {
    this.props.getContractById(this.props.match.params.contractId);
  }

  render() {
    const { singleContract } = this.props;
    return (
      <Grid container direction='row' data-test='component-View'>
        <Grid item md={12} className='contract-container'>
          <Grid container>
            <Grid item md={12}>
              <ActionBar left1={<HandleHeaderLeft {...this.props} />} />
            </Grid>
            <Grid item md={12}>
              <ContractInfo singleContract={singleContract} />
            </Grid>
            <Grid item md={12}>
              <RoyaltyExclusionTerms
                IncludeCentralSubmissionVolume={
                  singleContract.IncludeCentralSubmissionVolume
                }
                allowManulaRoyalityExclusion={
                  singleContract.allowManulaRoyalityExclusion
                }
                exclusionTypes={singleContract.exclusionTypes}
              />
            </Grid>
            <Grid item md={12}>
              <RoyaltyRateScale
                mode='view'
                royaltyRateScale={singleContract.royaltyRateScale}
              />
            </Grid>

            <Grid item md={12}>
              <ContractNotesList contractNotes={singleContract.contractNotes} />
            </Grid>
            <Grid item md={12}>
              <ActionBar left1={<HandleHeaderLeft {...this.props} />} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  singleContract: state.contracts.singleContract
});

export default connect(mapStateToProps, { getContractById })(withRouter(View));
