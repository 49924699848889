import Create from './Create';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  getTotalOrganizationCountries,
  getTotalOrganizationRegions,
  getTotalOrganizationTypes,
  getTotalCurrencies,
  getTotalProducts
} from '../../../../store/organizations/actions';

export const mapStateToProps = state => ({
  regions: state.organizations.totalOrganizationalRegions,
  countries: state.organizations.totalOrganizationalCountries,
  orgTypes: state.organizations.totalOrganizationalTypes,
  currencies: state.organizations.totalCurrencies,
  products: state.organizations.totalProducts,
  highestRole: state.user.highestRole
});

const mapDispatchToProps = {
  getTotalOrganizationCountries,
  getTotalOrganizationRegions,
  getTotalOrganizationTypes,
  getTotalCurrencies,
  getTotalProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Create));
