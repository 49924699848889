import React from 'react';
import PropTypes from 'prop-types';
import './CrossIcon.css';
import { ReactComponent as SvgIcon } from '../../../assets/icons/cross-slim.svg';
import { Grid } from '@material-ui/core';

const CrossIcon = ({ onClickIcon }) => {
  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      className='cross-icon-container'
      data-test='cross-icon-component'
      onClick={onClickIcon}>
      <Grid item>
        <SvgIcon className='cross-icon' />
      </Grid>
    </Grid>
  );
};

CrossIcon.propTypes = {
  onClickIcon: PropTypes.func.isRequired
};

export default CrossIcon;
