import { connect } from 'react-redux';
import UnlockFranchisee from './UnlockFranchisee';
import { getMegaMenuOrganizationNames } from '../../../store/admin/actions';

export const mapStateToProps = state => {
  return {
    megaMenuOrganizationNames: state.admin.megaMenuOrganizationNames
  };
};

export default connect(mapStateToProps, {
  getMegaMenuOrganizationNames
})(UnlockFranchisee);
