import { connect } from 'react-redux';
import View from './View';
import { getContractsByParentOrganization } from '../../../../store/contracts/actions';

const mapStateToProps = state => ({
  contractsByParentOrganization: state.contracts.contractsByParentOrganization
});
export default connect(mapStateToProps, { getContractsByParentOrganization })(
  View
);
