export const salutationOptions = [
  {
    value: 'Mr.',
    label: 'Mr.'
  },
  {
    value: 'Ms.',
    label: 'Ms.'
  },
  {
    value: 'Mrs.',
    label: 'Mrs.'
  }
];
