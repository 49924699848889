import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer
} from '@material-ui/core';
import { TableInput } from '../generictable';
import { range } from '../../utils/commonFunctions';
import { formsRowCheck, formsExceptionCheck } from '../../constants/dataForms';

function Section({ section, sectionIndex, formID }) {
  const cols = Object.entries(section.columnHeaders);
  const nCols = cols.length;
  const noExceptions = formsExceptionCheck(formID, sectionIndex);
  const rHeaders = Object.entries(section.rowHeaders);
  return (
    <TableContainer style={{ overflowX: 'unset' }}>
      <Table
        data-test='component-table'
        className={`data-form-table ${formID} section-${sectionIndex}`}>
        <TableHead
          data-test='component-table-head'
          className={sectionIndex !== 0 ? 'header-2' : ''}>
          {sectionIndex === 0 && (
            <TableRow className='header-1' data-test='component-table-head-row'>
              <TableCell className='col-0' />
              {cols.map(([colId, colName]) => {
                return (
                  <TableCell key={colId} data-test='component-table-head-cell'>
                    {colName}
                  </TableCell>
                );
              })}
            </TableRow>
          )}
          <TableRow
            data-test='component-table-head-row'
            className={sectionIndex === 0 ? 'header-2' : ''}>
            <TableCell className='col-0'>{section.sectionName}</TableCell>
            <TableCell colSpan={cols.length} />
          </TableRow>
        </TableHead>
        <TableBody data-test='component-table-body'>
          {Object.entries(section.rowHeaders).map(
            ([rowKey, rowVal], rindex, array) => {
              let isLastRow = rindex === array.length - 1;
              const extraBoldRow = formsRowCheck(formID, sectionIndex, array);
              if (extraBoldRow !== false) {
                isLastRow =
                  rindex === extraBoldRow || rindex === array.length - 1;
              }
              const rowBolded = isLastRow && noExceptions;
              return (
                <TableRow
                  key={rowKey}
                  data-test='component-table-body-row'
                  className={`dataform-row row-${rindex}`}>
                  <td
                    className={`row-label row-${rindex} col-0 ${
                      rowBolded ? 'bold-row-total' : ''
                    }`}
                    aria-label={`row ${rowVal}`}>
                    <div>{rowVal}</div>
                  </td>
                  {range(nCols).map(col => (
                    <td
                      key={col}
                      className={`row-${rindex} col-${col + 1} ${
                        rowBolded ? 'bold-row-total' : ''
                      }`}>
                      <TableInput
                        index={col + rindex * nCols}
                        sectionIndex={sectionIndex}
                        rowIndex={rindex}
                        rowName={rHeaders[rindex][1]}
                      />
                    </td>
                  ))}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Section;
