import { useState } from 'react';
import { connect } from 'react-redux';
import './HomeHelpPage.css';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  MobileStepper,
  Button
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReactComponent as BackButtonIcon } from '../../assets/icons/back-icon.svg';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import {
  pdfLinks as documents,
  videoLinks as steps
} from '../../constants/helpPageLinks';
import { downloadHelpDocuments } from '../../store/global/actions';

const HomeHelpPage = () => {
  const supportLink = 'GPOSupportTeam@discover.com';
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <Grid container className='home-help-bg' data-test='home-help-container'>
      <Grid
        container
        className='home-help-header-container'
        data-test='home-help-header-container'>
        <Grid item xs={12}>
          <Grid container>
            <Grid item className='home-help-backheader-icon'>
              <Link to='/home'>
                <BackButtonIcon />
              </Link>
            </Grid>
            <Grid item className='home-help-backheader-text'>
              <Link to='/home' data-test='home-help-back-link'>
                <Typography
                  className='back-btn-text row-value'
                  variant='h6'
                  color='secondary'>
                  Back
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant='h2'
            className='home-help-heading'
            color='secondary'>
            Help Section
          </Typography>
        </Grid>
      </Grid>
      <Grid container className='home-help-main-container'>
        <Grid item xs={3}>
          <Card sx={{ minWidth: 50 }}>
            <CardContent className='home-help-documentation-container'>
              <Typography
                className='home-help-documentation-text-header'
                gutterBottom
                variant='h4'
                color='secondary'>
                Documentation
              </Typography>
              {documents.map((document, index) => (
                <Button
                  onClick={() => {
                    downloadHelpDocuments(document.fileName);
                  }}>
                  <Typography
                    className='home-help-documentation-text'
                    variant='h5'
                    color='primary'>
                    {document.name}
                  </Typography>
                </Button>
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={7}>
          <Card sx={{ minWidth: 50 }}>
            <CardContent className='home-help-videos-container'>
              <Typography gutterBottom variant='h4' color='secondary'>
                Videos
              </Typography>
              <Grid container className='home-help-videos-inner'>
                <Grid item xs={12}>
                  <iframe
                    className='home-help-video'
                    src={steps[activeStep].vidPath}
                    title={steps[activeStep].label}
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'></iframe>
                </Grid>
                <Grid item xs={12}>
                  <MobileStepper
                    data-test='stepper'
                    steps={maxSteps}
                    position='static'
                    variant={maxSteps > 1 ? 'dots' : ''}
                    activeStep={activeStep}
                    sx={{ maxWidth: 400, flexGrow: 1 }}
                    nextButton={
                      <Button
                        data-test='stepper-next-button'
                        size='small'
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}>
                        <Typography
                          color={
                            activeStep === maxSteps - 1 ? 'inherit' : 'primary'
                          }
                          className='stepper-component-text'>
                          Next
                        </Typography>
                        <KeyboardArrowRight
                          style={{ marginBottom: '4px' }}
                          color={
                            activeStep === maxSteps - 1 ? 'inherit' : 'primary'
                          }
                        />
                      </Button>
                    }
                    backButton={
                      <Button
                        data-test='stepper-back-button'
                        size='small'
                        onClick={handleBack}
                        disabled={activeStep === 0}>
                        <KeyboardArrowLeft
                          style={{ marginBottom: '4px' }}
                          color={activeStep === 0 ? 'inherit' : 'primary'}
                        />
                        <Typography
                          color={activeStep === 0 ? 'inherit' : 'primary'}
                          className='stepper-component-text'>
                          Back
                        </Typography>
                      </Button>
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card sx={{ minWidth: 50 }}>
            <CardContent className='home-help-support-container'>
              <Typography gutterBottom variant='h4' color='secondary'>
                Support Information
              </Typography>
              <Typography className='home-help-support-text' color='secondary'>
                For questions regarding any of the information provided in this
                application, please complete the 'Ask DCI a Question' form found
                in the Support section of InfoNet.
              </Typography>
              <Typography className='home-help-support-text' color='secondary'>
                For any requests/incidents please contact your Account
                Executive.
              </Typography>
              <Typography
                className='home-help-support-text last-text'
                color='secondary'>
                For any other user access-based issues, please contact Global
                Partner Operation Support Team -&nbsp;
                <a
                  href={`mailto:${supportLink}`}
                  target='_top'
                  className='home-help-support-link'>
                  {supportLink}
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  downloadHelpDocuments
};

export default connect(null, mapDispatchToProps)(HomeHelpPage);
