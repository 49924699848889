/* eslint-disable quotes */
export const ERRORS = {
  404: "Sorry! We're currently experiencing technical difficulties and are unable to complete the process at this time."
};

export const BAD_REQUEST = '400';
export const INT_SERVER_ERR = '500';
export const RPT_PRD_NEXT_MONTH_ERR =
  'Please submit data for previous month to enter data for current month';

export const RPT_PRD_PREVIOUS_MONTH_ERR =
  'Data for Selected month is submitted';

export const VALIDATE_ERR = 'Email should be unique';
