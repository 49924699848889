import api from './';

const franchisesEndpoints = {
  getFranchises: 'home/franchiseprofile'
};

export const getFranchisesApi = async body => {
  const res = await api().get(franchisesEndpoints.getFranchises, body);
  return res.data;
};
