import { uiActions } from './types';

export const initialState = {
  selectedMegaMenu: 'none',
  isDataMenuActive: false,
  isAdminMenuActive: false,
  isSettingsMenuActive: false,
  manageDataMenu: false
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case uiActions.OPEN_MEGA_MENU:
      return {
        ...state,
        selectedMegaMenu: action.payload.menu
      };

    case uiActions.CLOSE_MEGA_MENU:
      return {
        ...state,
        selectedMegaMenu: 'none'
      };
    case uiActions.SET_ADMIN_MENU_ACTIVE:
      return {
        ...state,
        isAdminMenuActive: true,
        isDataMenuActive: false,
        isSettingsMenuActive: false
      };
    case uiActions.SET_DATA_MENU_ACTIVE:
      return {
        ...state,
        isAdminMenuActive: false,
        isDataMenuActive: true,
        isSettingsMenuActive: false
      };
    case uiActions.SET_SETTINGS_MENU_ACTIVE:
      return {
        ...state,
        isAdminMenuActive: false,
        isDataMenuActive: false,
        isSettingsMenuActive: true
      };
    case uiActions.SET_MENU_INACTIVE:
      return {
        ...state,
        isAdminMenuActive: false,
        isDataMenuActive: false,
        isSettingsMenuActive: false
      };
    case uiActions.SET_MANAGE_DATA_MENU_ACTIVE:
      return {
        ...state,
        manageDataMenu: true
      };
    default:
      return state;
  }
};

export default uiReducer;
