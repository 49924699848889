import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const Header = () => (
  <Grid item md={12} className='user-search-header-container'>
    <Grid container justify='space-between' alignItems='center'>
      <Typography
        variant='h4'
        className='announcements-header-title'
        data-test='announcements-header-title'>
        Announcements
      </Typography>
    </Grid>
  </Grid>
);

export default Header;
