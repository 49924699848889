import React from 'react';
import { Typography, Grid, Chip, Avatar } from '@material-ui/core';
import BlockHeader from './BlockHeader';
import PropTypes from 'prop-types';

const ProfileRole = props => {
  const { roles } = props;
  const rolesWithOutLUA = roles.filter(
    role => role.organizationRoleVO.roleTypeCode !== 'OA'
  );
  let onlyLuaRoles = roles.find(
    role => role.organizationRoleVO.roleTypeCode === 'OA'
  );
  const responseWithOA = rolesWithOutLUA;
  if (onlyLuaRoles) {
    responseWithOA.push(onlyLuaRoles);
    onlyLuaRoles = responseWithOA;
  } else {
    onlyLuaRoles = rolesWithOutLUA;
  }

  return (
    <div
      aria-label='Organizational Roles section'
      data-test='org-roles-section'>
      <BlockHeader title='Organizational Roles' />
      <Grid container className='organization-role-header'>
        <Grid item md={4}>
          <Typography variant='h6' data-test='role-header'>
            Role
          </Typography>
        </Grid>
        <Grid item md={5}>
          <Typography variant='h6' data-test='grantable-organization-header'>
            Grantable Organizations for the Selected Role
          </Typography>
        </Grid>
        <Grid item className='profile-legend-container' md={3}>
          <div className='profile-pending-legend'>
            <Typography variant='h6'>
              <span /> Pending
            </Typography>
          </div>
          <div className='profile-approved-legend'>
            <Typography variant='h6'>
              <span /> Approved
            </Typography>
          </div>
        </Grid>
      </Grid>
      {rolesWithOutLUA.map(role => (
        <Grid
          key={role.organizationRoleVO.roleTypeCode}
          container
          className='personal-data-roles-container'>
          <Grid item md={4}>
            <Typography variant='h6'>
              {role.organizationRoleVO.roleTypeName}
            </Typography>
          </Grid>
          <Grid item md={8}>
            {role.organizationProfileVO.map(org => {
              const val = onlyLuaRoles.some(role => {
                return org.organizationProfileId === role.organizationProfileId;
              });

              return (
                <Chip
                  key={org.organizationProfileId}
                  classes={{
                    root: `profile-tag-chip ${
                      org.status === 'A' ? '' : 'profile-tag-chip-pending'
                    }`
                  }}
                  avatar={val ? <Avatar>A</Avatar> : null}
                  label={org.organizationProfileName}
                />
              );
            })}
          </Grid>
        </Grid>
      ))}
      <Grid container className='personal-data-roles-container empty-row' />
    </div>
  );
};

ProfileRole.propTypes = {
  roles: PropTypes.array.isRequired
};
export default ProfileRole;
