/* eslint-disable max-lines-per-function */
import React, { Component } from 'react';
import { withFormik } from 'formik';

import {
  TableCell,
  IconButton,
  Button,
  TableRow,
  Box
} from '@material-ui/core';
import { Switch } from '../../../components/material';
import {
  TextFieldGrid,
  DropdownGrid,
  ComboboxGrid
} from '../../../components/formik';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-icon.svg';
import { childOrganizationSchema } from '../../../constants/validations/admin/organization';
import { isOrganizationProfileExistsApi } from '../../../api/organizations';
import { createStatusFromErrors } from '../../../utils/commonFunctions';

export class AddOrgProfileRow extends Component {
  handleBlur = e => {
    const { handleBlur, status, setStatus } = this.props;
    e.persist();
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };

  render() {
    const {
      countries,
      orgTypes,
      regions,
      currencies,
      values,
      handleChange,
      handleReset,
      handleSubmit,
      isInternalUser
    } = this.props;

    return (
      <TableRow className='form' data-test='add-org-profile-row-component'>
        {isInternalUser && <TableCell />}
        <TableCell>
          <TextFieldGrid
            className='oraganization-profile-table-fields'
            id='org-profile-name'
            label='Enter Org Profile Name'
            style={{ width: 205 }}
            name='organizationProfileName'
            onBlur={this.handleBlur}
          />
        </TableCell>
        <TableCell>
          <DropdownGrid
            label='Select/Type Org Region'
            id='org-profile-region'
            className='oraganization-profile-table-fields'
            style={{ width: 200 }}
            options={regions}
            name='region'
            value={values.region}
            onBlur={this.handleBlur}
            onChange={handleChange}
          />
        </TableCell>
        <TableCell>
          <ComboboxGrid
            limit={1}
            label='Select/Type Country'
            id='org-profile-country'
            className='oraganization-profile-table-fields'
            options={countries}
            name='operatingCountry'
            value={values.operatingCountry}
            style={{
              width: 215,
              display: 'flex',
              alignItems: 'center',
              position: 'relative'
            }}
            onChange={handleChange}
            onBlur={this.handleBlur}
          />
        </TableCell>
        <TableCell>
          <DropdownGrid
            label='Select/Type Org Type'
            id='org-profile-entity'
            className='oraganization-profile-table-fields'
            style={{ width: 200 }}
            options={orgTypes}
            name='entityType'
            value={values.entityType}
            onBlur={this.handleBlur}
            onChange={handleChange}
          />
        </TableCell>
        <TableCell>
          <ComboboxGrid
            label='Select'
            id='org-profile-currency'
            className='oraganization-profile-table-fields'
            data-test='org-profile-currency'
            style={{
              width: 150,
              display: 'flex',
              alignItems: 'center',
              position: 'relative'
            }}
            options={currencies}
            name='currency'
            value={values.currency}
            onBlur={this.handleBlur}
            onChange={handleChange}
          />
        </TableCell>
        <TableCell>
          <TextFieldGrid
            className='oraganization-profile-table-fields'
            id='org-profile-iicCode'
            label='Enter'
            style={{ width: 120 }}
            name='iicCode'
            value={values.iicCode}
            onBlur={this.handleBlur}
          />
        </TableCell>
        <TableCell>
          <TextFieldGrid
            className='oraganization-profile-table-fields'
            id='org-profile-dxsCode'
            label='Enter'
            style={{ width: 120 }}
            name='dxsCode'
            value={values.dxsCode}
            onBlur={this.handleBlur}
          />
        </TableCell>
        <TableCell>
          <Switch
            id='org-profile-active'
            name='extras'
            value='active'
            checked={values.extras.includes('active')}
            onChange={handleChange}
            onBlur={this.handleBlur}
          />
        </TableCell>
        <TableCell>
          <Box style={{ display: 'flex', width: 155, alignItems: 'center' }}>
            <Switch
              name='extras'
              id='org-profile-ff'
              value='financialForce'
              checked={values.extras.includes('financialForce')}
              onChange={handleChange}
              onBlur={this.handleBlur}
            />
          </Box>
        </TableCell>
        <TableCell>
          <IconButton
            className='organization-table-form-add-icon'
            onClick={handleSubmit}>
            <AddIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <Button
            variant='contained'
            size='small'
            className='oraganization-profile-table-fields oraganization-profile-table-button'
            onClick={handleReset}>
            Clear
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

const formConfig = withFormik({
  mapPropsToValues: () => ({
    organizationProfileName: '',
    region: '',
    operatingCountry: '',
    entityType: '',
    currency: '',
    iicCode: '',
    dxsCode: '',
    extras: []
  }),

  isInitialValid: false,
  validationSchema: childOrganizationSchema,

  mapPropsToStatus: () => ({}),

  handleSubmit: (values, formikBag) => {
    const body = {
      organizationProfileName: values.organizationProfileName,
      organizationProfileId: 0
    };
    return isOrganizationProfileExistsApi(body)
      .then(response => {
        if (response.data === false) {
          formikBag.props.onSubmit(values);
          formikBag.resetForm();
        }
      })
      .catch(err => {
        formikBag.setStatus(createStatusFromErrors(err));
      });
  }
});

export default formConfig(AddOrgProfileRow);
