import React, { Fragment } from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography
} from '@material-ui/core';
import '../Contract.css';
import { Link } from 'react-router-dom';
const tableHead = [
  'Contract ID',
  'Effective Start Date (dd/mm/yyyy)',
  'Effective End Date (dd/mm/yyyy)',
  'Status'
];

const getStatusColor = statusText => {
  if (statusText === true) {
    return <span style={{ color: '#0B9B11' }}>Active</span>;
  }
  return <span style={{ color: '#293329' }}>Inactive</span>;
};
const TableList = props => {
  const { contracts, organizationProfileId } = props;
  return (
    <Fragment>
      <TableContainer data-test='component-TableList'>
        <Table>
          <TableHead>
            <TableRow className='contract-list-table-header'>
              {tableHead.map((header, index) => {
                return (
                  <TableCell key={index} align='left'>
                    {header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {contracts.length > 0 ? (
              contracts.map(contractDetail => {
                const url = `/admin/contract/${organizationProfileId}/edit/${contractDetail.contractId}`;
                return (
                  <TableRow key={contractDetail.contractId}>
                    <TableCell align='left'>
                      <Link to={url}>{contractDetail.contractId}</Link>
                    </TableCell>
                    <TableCell align='left'>
                      {contractDetail.effectiveStartDate}
                    </TableCell>
                    <TableCell align='left'>
                      {contractDetail.effectiveEndDate}
                    </TableCell>
                    <TableCell align='left'>
                      {getStatusColor(contractDetail.status)}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colspan={4}>
                  <Typography
                    className='table-list   search-bprofile-result-empty-row'
                    variant='h5'>
                    No Records Found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};
export default TableList;
