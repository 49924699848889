import React from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { Dropdown } from '../../../components/material';
import { range } from '../../../utils/commonFunctions';
import { HOME_TEXT } from '../../../constants/text';
import SuccessIcon from '../../../assets/icons/success-icon.svg';

function SelectedFranchisee(props) {
  const {
    orgName,
    values,
    handleChange,
    handleSubmit,
    resetForm,
    successMessage,
    isValid,
    dirty
  } = props;
  const years = range(HOME_TEXT.fromYear, HOME_TEXT.toYear).map(year => ({
    label: year,
    value: year
  }));
  const months = HOME_TEXT.monthsList.map((month, index) => ({
    label: month,
    value: index < 9 ? '0' + (index + 1) : index + 1
  }));
  return (
    <Grid
      item
      xs
      className='franchisees-main-container'
      data-test='franchisees-main-container'>
      <Grid
        className='franchisees-header-container'
        data-test='franchisees-header-container'>
        <Grid
          className='action-buttons-orgname-container'
          data-test='action-buttons-orgname-container'>
          <Typography
            variant='h6'
            className='search-list-org'
            data-test='search-list-org'
            name='orgName'>
            {orgName}
          </Typography>
        </Grid>
        <Grid
          className='action-buttons-container'
          data-test='action-buttons-container'>
          <Dropdown
            required
            id='month'
            data-test='unlock-data-action'
            className='unlock-data-action'
            options={months}
            label='Month'
            name='month'
            value={values.month}
            onChange={handleChange}
          />
          <Dropdown
            required
            className='unlock-data-action'
            data-test='unlock-data-action'
            id='year'
            options={years}
            label='Year'
            name='year'
            value={values.year}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      {successMessage !== undefined && successMessage !== '' && (
        <Grid
          container
          item
          md={12}
          className='success-actions-container'
          data-test='success-actions-container'>
          <div
            className='success-unlock-icon-outer'
            data-test='success-unlock-icon-outer'>
            <div
              className='success-unlock-icon-inner'
              data-test='success-unlock-icon-inner'>
              <div
                className='success-unlock-container'
                data-test='success-unlock-container'>
                <img
                  src={SuccessIcon}
                  className='success-unlock-icon'
                  data-test='success-unlock-icon'
                  alt='Json icon'
                />
              </div>
            </div>
          </div>
          <Typography
            variant='h6'
            className='success-unlock-txt'
            data-test='success-unlock-txt'>
            {successMessage}
          </Typography>
        </Grid>
      )}

      <Grid
        container
        item
        md={12}
        data-test='actions-container'
        className='actions-container'>
        <Button
          variant='outlined'
          type='button'
          size='small'
          color='secondary'
          data-test='franchise-button'
          style={{ marginRight: '22px' }}
          disabled={!dirty}
          onClick={resetForm}>
          Clear
        </Button>
        <Button
          variant='contained'
          type='submit'
          size='small'
          color='primary'
          data-test='franchise-button'
          style={{ marginRight: '36px' }}
          disabled={!isValid || !dirty}
          onClick={handleSubmit}>
          Unlock
        </Button>
      </Grid>
    </Grid>
  );
}

SelectedFranchisee.propTypes = {};

export default SelectedFranchisee;
