import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const DisplayFormsList = props => {
  const { forms, handler } = props;
  return forms.map(form => (
    <div key={form.formId}>
      {form.enabled ? (
        <Link
          to={`/manage-data/manual/${form.formId}`}
          onClick={() => handler(form.formName)}>
          <Typography
            className={`data-entry-form ${
              form.enabled ? '' : 'data-entry-form-disabled'
            }`}
            variant='body2'
            display='inline'
            color='secondary'>
            {form.formName}
          </Typography>
        </Link>
      ) : (
        <Typography
          key={form.formName}
          className={`data-entry-form ${
            form.enabled ? '' : 'data-entry-form-disabled'
          }`}
          variant='body2'
          color='secondary'>
          {form.formName}
        </Typography>
      )}
    </div>
  ));
};

export default DisplayFormsList;
