import React from 'react';
import { Grid, Box } from '@material-ui/core';
import './PlaceHolder.css';

const PlaceHolder = () => {
  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      data-test='place-holder-component'>
      <Grid item className='place-holder'>
        <Grid container justify='center' alignItems='center'>
          <Grid item className='ph-item'>
            <Box className='ph-circle' />
          </Grid>
          <Grid item className='ph-item'>
            <Box className='ph-one' />
            <Box className='ph-two' />
            <Box className='ph-three' />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlaceHolder;
