import { base } from './base';
import { button } from './button';
import { checkbox } from './checkbox';
import { chip } from './chip';
import { combobox } from './combobox';
import { input } from './input';
import { menu } from './menu';
import { radio } from './radio';
import { stepper } from './stepper';
import { Switch } from './switch';
import { table } from './table';

const theme = base;

theme.overrides = {
  ...theme.overrides,
  ...button,
  ...checkbox,
  ...chip,
  ...combobox,
  ...input,
  ...menu,
  ...radio,
  ...stepper,
  ...Switch,
  ...table
};
export default theme;
