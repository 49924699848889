import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './IdleTimerContainer.css';
import { decisionTime, promptBeforeIdle } from '../../constants/idleTimer';
import IdleTimer from './IdleTimer';
import { extendSessionApi } from '../../api/auth';

export class IdleTimerContainer extends Component {
  componentDidMount() {
    const { sessionTime, getSessionTime } = this.props;
    if (sessionTime === undefined) {
      getSessionTime();
    }
  }

  render() {
    const {
      usrProfId,
      isResetTimer,
      logoutUser,
      resetTimer,
      sessionTime
    } = this.props;
    return (
      <div data-test='idle-timer-container'>
        {sessionTime && (
          <IdleTimer
            usrProfId={usrProfId}
            sessionTime={(sessionTime - promptBeforeIdle) * 1000}
            promptTimeout={decisionTime * 1000}
            decisionTime={decisionTime}
            logoutUser={logoutUser}
            resetTimer={resetTimer}
            isResetTimer={isResetTimer}
            extendSessionApi={extendSessionApi}
          />
        )}
      </div>
    );
  }
}

IdleTimerContainer.propTypes = {
  usrProfId: PropTypes.number.isRequired,
  isResetTimer: PropTypes.bool.isRequired,
  sessionTime: PropTypes.number.isRequired
};

export default IdleTimerContainer;
