import React, { Component } from 'react';
import { Grid, Typography, Fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import './ErrorContainer.css';

class ErrorContainer extends Component {
  render() {
    const { message, classStyle } = this.props;
    let error = true;
    if (!message) {
      error = false;
    }

    return (
      <Fade in={error}>
        <div className={classStyle ? classStyle : 'error-container-div'}>
          <Grid
            container
            data-test='component-error-container'
            className={`error-container ${error ? 'has-error' : ''}`}
            alignItems='center'
            aria-label='error'
            role='region'>
            {error && (
              <>
                <div className='error-icon'>!</div>
                <div className='error-text'>
                  <Typography
                    variant='caption'
                    aria-label='error-message'
                    data-test='error-message'>
                    {message}
                  </Typography>
                </div>
              </>
            )}
          </Grid>
        </div>
      </Fade>
    );
  }
}

ErrorContainer.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

ErrorContainer.defaultProps = {
  message: ''
};

export default ErrorContainer;
