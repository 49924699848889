import React from 'react';
import FormRow from './FormRow';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { numberFormatRegex } from '../../../utils/regexes';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody
} from '@material-ui/core';
import './RoyalityExclusionsAdditions.css';
import RoyaltyTableInput from './RoyaltyTableInput';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { Modal } from '../../../components';
import TEXT from '../../../constants/text/common';

export class RoyalityExclusionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      royaltyDeleteModalOpen: false,
      secId: '',
      idx: ''
    };
  }

  deleteFormRowHandler = (secIdx, idx) => {
    let form = this.props.form;
    form = {
      ...form,
      sections: [
        {
          sectionIndex: secIdx,
          total: this.props.total,
          fieldDetails: [
            {
              ...this.props.fieldDetails[idx],
              operationCode: 'D'
            }
          ]
        }
      ]
    };
    this.props.deleteRoyaltyFormRow(idx, form);
  };

  toggleRoyaltyDeleteModal = (index = '', secIdx = '') => {
    this.setState(prevState => ({
      ...prevState,
      royaltyDeleteModalOpen: !prevState.royaltyDeleteModalOpen,
      idx: index,
      secId: secIdx
    }));
  };

  render() {
    const {
      handleChange,
      values,
      handleSubmit,
      fieldDetails,
      resetForm,
      columnHeaders,
      isHeader,
      form,
      sectionIndex,
      total,
      dropDownData,
      getRoyaltyDropDownData,
      formType
    } = this.props;
    const { royaltyDeleteModalOpen, secId, idx } = this.state;
    if (!columnHeaders) {
      return 'Loading...';
    }

    return (
      <>
        <Modal
          data-test='royalty-delete-modal'
          isOpen={royaltyDeleteModalOpen}
          submitBtnLabel='OK'
          onSubmit={() => {
            this.deleteFormRowHandler(secId, idx);
            this.toggleRoyaltyDeleteModal();
          }}
          onClose={this.toggleRoyaltyDeleteModal}
          onCancel={this.toggleRoyaltyDeleteModal}>
          <>
            {' '}
            Only Delete if contract with the selected business is terminated.
            Are you sure?{' '}
          </>
        </Modal>
        <TableContainer className='intes-table'>
          <Table>
            <TableHead>
              {isHeader && (
                <TableRow>
                  <TableCell
                    padding='none'
                    colspan={Object.values(columnHeaders).length}
                    style={{
                      backgroundColor: '#022a48',
                      paddingTop: 5,
                      paddingRight: 15,
                      paddingBottom: 5,
                      paddingLeft: 15,
                      borderTopRightRadius: 10,
                      borderTopLeftRadius: 10,
                      borderBottom: 0
                    }}>
                    Description(s)
                  </TableCell>
                </TableRow>
              )}

              <TableRow
                style={{
                  backgroundColor: '#1A587E',
                  color: '#ffff'
                }}>
                {columnHeaders
                  ? Object.entries(columnHeaders).map(
                      ([k, v], index, array) => {
                        const isLastColumn = index === array.length - 1;
                        const selectedWidth = isLastColumn
                          ? '13%'
                          : `${87 / (array.length - 1)}%`;
                        return !form.formEditable && v === 'Actions' ? null : (
                          <TableCell
                            key={k}
                            className={
                              !form.formEditable && v === 'Value'
                                ? 'royality-addition-tablehead royalty-readonly'
                                : 'royality-addition-tablehead'
                            }
                            style={{
                              width: selectedWidth
                            }}>
                            {v}
                          </TableCell>
                        );
                      }
                    )
                  : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {form.formEditable ? (
                <FormRow
                  values={values}
                  handleChange={handleChange}
                  handleClear={resetForm}
                  dropDownData={dropDownData}
                  handleSubmit={handleSubmit}
                  getRoyaltyDropDownData={getRoyaltyDropDownData}
                  formType={formType}
                />
              ) : null}
              {fieldDetails && fieldDetails.length > 0 ? (
                fieldDetails.map((data, index) => {
                  return (
                    <TableRow key={data.royAddExclDtlId}>
                      <TableCell>
                        <Typography
                          className='royalty-table-form-fields'
                          variant='body1'>
                          <span className='code-Highlight'>
                            {data.orgCntrctExclTypId}
                          </span>{' '}
                          - {data.orgCntrctExclTypDsc}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {!data.readOnly ? (
                          <RoyaltyTableInput
                            fullWidth
                            field={data}
                            index={index}
                            total={total}
                            sectionIndex={sectionIndex}
                            name='othDescription'
                          />
                        ) : (
                          <Typography
                            className='royalty-table-form-fields'
                            variant='body1'>
                            {data.othDescription}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {data.readOnly === false ? (
                          <RoyaltyTableInput
                            fullWidth
                            field={data}
                            index={index}
                            total={total}
                            sectionIndex={sectionIndex}
                            name='value'
                          />
                        ) : (
                          <Typography
                            className='intes-table-form-fields'
                            variant='body1'>
                            {data.value
                              ? data.value
                                  .toString()
                                  .replace(numberFormatRegex, ',')
                              : null}
                          </Typography>
                        )}
                      </TableCell>
                      {form.formEditable ? (
                        <TableCell align='center'>
                          {(data.delete && (
                            <Typography
                              className='intes-table-form-delete-button action-button-events-disabled'
                              variant='body1'>
                              <DeleteIcon
                                data-test='royalty-delete-icon'
                                onClick={() =>
                                  this.toggleRoyaltyDeleteModal(
                                    index,
                                    sectionIndex
                                  )
                                }
                              />
                            </Typography>
                          )) ||
                            null}
                        </TableCell>
                      ) : null}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colspan={Object.values(columnHeaders).length}
                    style={{ textAlign: 'center' }}>
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
              <TableRow className='royality-exclusion-additions-table'>
                <TableCell padding='none' style={{ color: '#2477AB' }}>
                  Total
                </TableCell>
                <TableCell />
                <TableCell padding='5px' align='center'>
                  <span style={{ color: '#022a48' }}>
                    {total.toString().replace(numberFormatRegex, ',')}
                  </span>
                </TableCell>
                {form.formEditable ? (
                  <TableCell padding='none' align='center' />
                ) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

const formConfig = withFormik({
  mapPropsToValues: () => ({
    description: '',
    othDescription: '',
    value: ''
  }),
  validationSchema: Yup.object().shape(
    {
      description: Yup.object().required(),
      othDescription: Yup.mixed().when(
        ['description'],
        (description, schema, node) => {
          if (
            node.from &&
            node.from.length > 0 &&
            node.from[0].value.description &&
            node.from[0].value.description.value === 'OTH'
          ) {
            return Yup.string().required(TEXT.royaltyOthDescRequiredErrorMsg);
          }
          return schema;
        }
      ),
      value: Yup.string().required()
    },
    [['description', 'othDescription']]
  ),
  mapPropsToStatus: () => ({}),
  handleSubmit: (values, formikBag) => {
    const body = {
      ...formikBag.props.form,
      sections: [
        {
          sectionIndex: formikBag.props.sectionIndex,
          total: formikBag.props.total,
          fieldDetails: [
            {
              orgCntrctExclTypDsc: values.description.label,
              orgCntrctExclTypId: values.description.value,
              value: values.value,
              othDescription: values.othDescription,
              operationCode: 'A'
            }
          ]
        }
      ]
    };
    formikBag.props.updateRoyaltyForm(body);
    formikBag.resetForm();
  }
});

export default formConfig(RoyalityExclusionsTable);
