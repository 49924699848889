import React, { Fragment } from 'react';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import XlsIcon from '../../assets/icons/xls-icon.svg';
import CsvIcon from '../../assets/icons/csv-icon.svg';
import TxtIcon from '../../assets/icons/txt-icon.svg';
import JsonIcon from '../../assets/icons/json-icon.svg';
import PropTypes from 'prop-types';

const getFileType = extension => {
  extension = extension
    .split('.')
    .pop()
    .toLowerCase();
  let fileType = '';
  fileType = extension === 'csv' ? 'csv' : '';
  fileType = extension === 'txt' ? 'txt' : fileType;
  fileType = extension === 'json' ? 'json' : fileType;
  fileType = extension === 'xls' || extension === 'xlsx' ? 'xls' : fileType;
  return fileType;
};
const FileUploading = props => {
  const { successHandler } = props;
  const [completed, setCompleted] = React.useState(10);
  const fileType = getFileType(props.fileName);
  React.useEffect(() => {
    function progress() {
      setCompleted(prevCompleted => {
        if (prevCompleted === 100) {
          successHandler();
          return 110;
        }
        return prevCompleted + 10;
      });
    }

    const timer = setInterval(progress, 500);
    return () => {
      clearInterval(timer);
    };
  }, [successHandler]);

  return (
    <Fragment>
      <div className='upload-icon-outer' data-test='upload-icon-outer'>
        <div className='upload-icon-inner' data-test='upload-icon-inner'>
          <div className='progressContainer' data-test='progressContainer'>
            {fileType === 'xls' && (
              <img
                src={XlsIcon}
                data-test='excel-icon'
                className='uploading-icon'
                alt='excel file icon'
              />
            )}
            {fileType === 'csv' && (
              <img
                src={CsvIcon}
                data-test='csv-icon'
                className='uploading-csv-icon'
                alt='csv file icon'
              />
            )}
            {fileType === 'txt' && (
              <img
                src={TxtIcon}
                data-test='text-icon'
                className='uploading-icon'
                alt='text file icon'
              />
            )}
            {fileType === 'json' && (
              <img
                src={JsonIcon}
                data-test='json-icon'
                className='uploading-icon'
                alt='Json file icon'
              />
            )}
            <CircularProgress
              variant='static'
              value={completed}
              size={65}
              thickness={2}
              color='primary'
              data-test='upload-progress'
            />
          </div>
        </div>
      </div>
      <Typography
        id='file-upload-div-caption'
        className='dnd-txt'
        color='secondary'
        variant='h6'
        data-test='file-name'>
        {props.fileName}
      </Typography>
      <p data-test='fiel-size' className='or-txt'>
        {props.fileSize} MB
      </p>
      <Button
        classes={{ root: 'upload-cancel-button' }}
        variant='outlined'
        color='secondary'
        aria-label='upload cancel botton'
        data-test='cancel-button'
        onClick={props.cancelHandler}>
        <Typography
          className='cancel-button-txt'
          data-test='cancel-button-txt'
          variant='body1'>
          Cancel
        </Typography>
      </Button>
    </Fragment>
  );
};
FileUploading.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.string.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  successHandler: PropTypes.func.isRequired
};
export default FileUploading;
