export const AdminAccessDetails = {
  categories: [
    '',
    'TABLE MAINTENANCE',
    'USER PROFILES',
    'BUSINESS PROFILE',
    'AUDIT REPORTS',
    'ROLL OVER THE MONTH'
  ],
  features: [
    {
      tag: 'BUSINESS PROFILE',
      title: 'Search Business Profile',
      link: '/admin/organization/search',
      accessKey: 'org-profile:search'
    },
    {
      tag: 'USER PROFILES',
      title: 'User Profile Search',
      link: '/admin/users/search',
      accessKey: 'admin:search'
    },
    {
      tag: 'USER PROFILES',
      title: 'User Profile Logs',
      link: '/admin/users/user-logs',
      accessKey: 'admin:user-logs'
    },
    {
      tag: 'USER PROFILES',
      title: 'User Profile',
      link: '/profile',
      accessKey: 'user-profile:profile'
    },
    {
      tag: 'USER PROFILES',
      title: 'User Approval Pages',
      link: '/admin/users/approval',
      accessKey: 'user-profile:approval'
    },
    {
      tag: 'BUSINESS PROFILE',
      title: 'Contract',
      link: '/admin/contract',
      accessKey: 'contract:navigate'
    },
    {
      tag: '',
      title: 'Announcements',
      link: '/admin/announcements',
      accessKey: 'ancmnts'
    },
    {
      tag: '',
      title: 'Product Line',
      link: '/admin/product-line',
      accessKey: 'productline'
    },
    {
      tag: '',
      title: 'Unlock Business',
      link: '/admin/unlock-franchisee',
      accessKey: 'unlock-franchisee'
    },
    {
      tag: '',
      title: 'Exchange Rates',
      link: '/admin/exchange-rates',
      accessKey: 'exchange-rate'
    },
    {
      tag: 'AUDIT REPORTS',
      title: 'Audit Report',
      link: '/admin/audit-report',
      accessKey: 'audit-report'
    },
    {
      tag: 'ROLL OVER THE MONTH',
      title: 'Extension Requests',
      link: '/admin/extension-requests',
      accessKey: 'extension-requests'
    }
  ]
};
