import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import './UserOrganizationalRolesForm.css';
import { Combobox } from '../material';
import MultipleComboboxWithSwitch from '../material/MultipleComboboxWithSwitch';
import { CircleCheck, CirclePlusIcon } from '../icons';

import { toastFunc } from '../ToastComponent/toastFunction';

class UserOrganizationalRolesForm extends Component {
  state = {
    organizationRole: {},
    selectedOrganizationNames: [],
    selectedOrganizationAdmin: [],
    canAddOrgRoles: false
  };

  resetForm = () => {
    this.setState({
      organizationRole: {},
      selectedOrganizationNames: [],
      selectedOrganizationAdmin: [],
      canAddOrgRoles: false
    });
  };

  enableAddButton = () => {
    const {
      organizationRole,
      selectedOrganizationNames,
      selectedOrganizationAdmin
    } = this.state;
    const organizationRoleLength = Object.keys(organizationRole).length;
    const selectedOrganizationNamesLength = selectedOrganizationNames.length;
    const selectedOrganizationAdminLength = selectedOrganizationAdmin.length;

    const canAddOrgRoles =
      (organizationRoleLength &&
        selectedOrganizationNamesLength &&
        selectedOrganizationAdminLength) ||
      false;

    this.setState({
      canAddOrgRoles
    });
  };

  // Org Roles Methods

  handleOrganizationRoleChange = e => {
    if (e.target.value !== null) {
      this.setState({ organizationRole: e.target.value }, () =>
        this.enableAddButton()
      );
    }
  };

  // Org Names Methods

  handleOrganizationNamesChange = e => {
    const {
      target: { value }
    } = e;
    this.setState(
      prevState => ({
        selectedOrganizationNames: value,
        selectedOrganizationAdmin: value.map(({ id }) => {
          const prevToggled = prevState.selectedOrganizationAdmin.find(
            org => org.id === id
          );
          return {
            id,
            status: prevToggled ? prevToggled.status : false
          };
        })
      }),
      () => {
        this.enableAddButton();
      }
    );
  };

  onSelectAll = () => {
    let orgNames = this.props.totalOrganizationalNames;
    orgNames = orgNames.filter(({ id }) => id !== 0);
    if (this.state.selectedOrganizationNames.length >= orgNames.length) {
      return false;
    }
    const toggles = orgNames.map(({ id }) => {
      const current = this.state.selectedOrganizationAdmin.find(
        org => org.id === id
      );
      return {
        id,
        status: current || false
      };
    });
    this.setState(
      {
        selectedOrganizationNames: orgNames,
        selectedOrganizationAdmin: toggles
      },
      () => this.enableAddButton()
    );
  };

  onUnselectAll = () => {
    this.setState(
      {
        selectedOrganizationNames: [],
        selectedOrganizationAdmin: []
      },
      () => this.enableAddButton()
    );
  };

  onToggleHandler = id => {
    this.setState(prevState => ({
      selectedOrganizationAdmin: prevState.selectedOrganizationAdmin.map(
        org => {
          if (org.id === id) {
            return {
              ...org,
              status: !org.status
            };
          }
          return org;
        }
      )
    }));
  };

  addOrganizationRole = (
    organizationRole,
    selectedOrganizationNames,
    selectedOrganizationAdmin
  ) => {
    const selectedOrgNamesWithAdmin = [];

    for (let i = 0; i < selectedOrganizationNames.length; i++) {
      selectedOrgNamesWithAdmin.push({
        ...selectedOrganizationNames[i],
        ...selectedOrganizationAdmin[i]
      });
    }
    const orgAdminRoles = {
      roleData: { id: 'OA', value: 'OA' },
      profileData: []
    };

    selectedOrganizationAdmin.forEach(adminOrg => {
      if (adminOrg.status) {
        orgAdminRoles.profileData.push({
          id: adminOrg.id,
          label: '',
          value: adminOrg.id
        });
      }
    });

    const otherRoles = {
      roleData: organizationRole,
      profileData: selectedOrgNamesWithAdmin
    };

    const finalOrgData = {
      orgAdminRoles,
      otherRoles,
      userProfileId: this.props.user.userProfileId,
      status: 'A',
      pageType: this.props.pageType
    };

    this.props.addOrganizationData(finalOrgData);
    if (this.props.pageType !== 'add') {
      toastFunc({
        content: 'Access has been Accepted Successfully',
        config: { className: 'toast-container accept' }
      });
    }

    this.resetForm();
  };

  render() {
    const {
      organizationRole,
      selectedOrganizationNames,
      selectedOrganizationAdmin,
      canAddOrgRoles
    } = this.state;
    const {
      totalOrganizationalNames,
      totalOrganizationalRoles,
      pageType
    } = this.props;
    return (
      <Grid container data-test='user-org-roles-form-component'>
        <Grid item md={12} className='user-org-roles-form-container'>
          <Grid container>
            <Grid item md={3}>
              <Combobox
                name='organizationRole'
                id='organizationRole'
                label='Select/Type Role'
                className='select-box'
                value={organizationRole}
                options={totalOrganizationalRoles}
                onChange={this.handleOrganizationRoleChange}
              />
            </Grid>
            <Grid item md={7}>
              <MultipleComboboxWithSwitch
                limit={1}
                label='Select Organization'
                name='Select Organization'
                id='selectOrganization'
                switchLabel='Local User Admin'
                switchValue='Yes'
                className='multi-select-box'
                options={totalOrganizationalNames}
                value={selectedOrganizationNames}
                toggleState={this.state.selectedOrganizationAdmin}
                onToggle={this.onToggleHandler}
                onSelectAll={this.onSelectAll}
                onUnselectAll={this.onUnselectAll}
                onChange={this.handleOrganizationNamesChange}
              />
            </Grid>
            <Grid item md={2} className='actions-container-clear'>
              <Grid
                data-test='add-org-role'
                item
                md={2}
                className={`circle-check-icon ${(!canAddOrgRoles &&
                  'events-disabled') ||
                  ''}`}
                onClick={() => {
                  this.addOrganizationRole(
                    organizationRole,
                    selectedOrganizationNames,
                    selectedOrganizationAdmin
                  );
                }}>
                {(pageType === 'add' && <CirclePlusIcon />) || <CircleCheck />}
              </Grid>
              <Grid item md={10}>
                <Button
                  color='secondary'
                  variant='outlined'
                  type='button'
                  size='small'
                  className='user-reset-btn'
                  onClick={this.resetForm}>
                  Clear
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

UserOrganizationalRolesForm.propTypes = {
  totalOrganizationalNames: PropTypes.array,
  totalOrganizationalRoles: PropTypes.array,
  pageType: PropTypes.string.isRequired,
  user: PropTypes.object,
  addOrganizationData: PropTypes.func.isRequired
};
UserOrganizationalRolesForm.defaultProps = {
  user: {},
  totalOrganizationalRoles: [],
  totalOrganizationalNames: []
};

export default UserOrganizationalRolesForm;
