import { connect } from 'react-redux';
import ExtensionRequests from './ExtensionRequest';
import { getAdminOrganizationNames } from '../../../store/admin/actions';
import {
  createExtensionRequest,
  getAllExchangeRequest,
  extensionRequestUpdate
} from '../../../store/organizations/actions';
export const mapStateToProps = state => {
  return {
    usersOrganizations: state.admin.totalAdminOrganizationalNames,
    exchangeRequestData: state.organizations.totalExchangeRequest
  };
};

export default connect(mapStateToProps, {
  getAdminOrganizationNames,
  createExtensionRequest,
  getAllExchangeRequest,
  extensionRequestUpdate
})(ExtensionRequests);
