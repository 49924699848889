import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import RowGroup from './RowGroup';

import { range } from '../../utils/commonFunctions';

const SummaryTable = props => {
  const {
    className,
    tableHeaders,
    beginningRow,
    tableBody,
    summaryTotals,
    statusTotals,
    accountsTotals,
    updateFunction
  } = props;

  const rowGroupProps = { tableHeaders, tableBody, updateFunction };
  return (
    <Fragment>
      <table className={className}>
        <thead className='column-headers'>
          <tr>{tableHeaders}</tr>
        </thead>
        <tbody>
          {/* Card In Force */}
          <tr className='section-title-row'>
            <td>Card in Force</td>
            <td colSpan={tableHeaders.length - 1} />
          </tr>
          <tr className='period-data-row'>
            <td>Beginning of Period</td>
            {beginningRow}
          </tr>
          <RowGroup {...rowGroupProps} iterator={range(1, 5)} />

          <tr className='period-data-row'>
            <td>End of Period</td>
            {summaryTotals}
          </tr>

          {/* CIF Status Count */}
          <tr className='section-title-row'>
            <td>CIF Status Count</td>
            <td colSpan={tableHeaders.length - 1} />
          </tr>
          <RowGroup {...rowGroupProps} iterator={range(5, 9)} />
          <tr className='period-data-row'>
            <td>Total End of Period</td>
            {statusTotals}
          </tr>

          {/* Other CIF Details */}
          <tr className='section-title-row'>
            <td>Other CIF Details</td>
            <td colSpan={tableHeaders.length - 1} />
          </tr>
          <RowGroup {...rowGroupProps} iterator={range(9, 15)} />
          <tr className='period-data-row'>
            <td>Accounts (End of Period)</td>
            {accountsTotals}
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

SummaryTable.propTypes = {
  className: PropTypes.string.isRequired,
  tableHeaders: PropTypes.array.isRequired,
  accountsTotals: PropTypes.array.isRequired,
  beginningRow: PropTypes.array.isRequired,
  statusTotals: PropTypes.array.isRequired,
  summaryTotals: PropTypes.array.isRequired,
  tableBody: PropTypes.array.isRequired,
  updateFunction: PropTypes.func
};

SummaryTable.defaultProps = {
  updateFunction: () => {}
};

export default SummaryTable;
