import React from 'react';
import { Dropdown } from '../../../components/material';
import ComboBox from '../../../components/material/Combobox';
import { Grid } from '@material-ui/core';
import { range } from '../../../utils/commonFunctions';
import { HOME_TEXT } from '../../../constants/text';
const years = range(HOME_TEXT.fromYear, HOME_TEXT.toYear).map(year => ({
  label: year,
  value: year
}));
const months = HOME_TEXT.monthsList.map((month, index) => ({
  label: month,
  value: index < 9 ? '0' + (index + 1) : index + 1
}));
const AuditReportSearchContainer = props => {
  const { values, handleChange, reportType } = props;
  return (
    <Grid
      container
      data-test='audit-report-fields-container'
      className='audit-report-fields-container'>
      <Grid
        container
        alignItems='flex-end'
        direction='row'
        style={{ marginLeft: '5%' }}
        data-test='audit-report-container'>
        <Grid item xs={3} data-test='audit-report-comboBox-grid'>
          <ComboBox
            required
            id='report'
            name='report'
            data-test='audit-report-combobox'
            className='audit-report-textbox'
            label='Report you want to run'
            options={reportType}
            value={values.report}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          xs={1}
          style={{ marginRight: '2%' }}
          data-test='audit-report-month-grid'>
          <Dropdown
            required
            id='month'
            className='megamenu-manual-data-action'
            data-test='audit-report-month'
            options={months}
            label='Month'
            name='month'
            value={values.month}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          xs={2}
          style={{ marginLeft: '2%' }}
          data-test='audit-report-year-grid'>
          <Dropdown
            required
            className='megamenu-manual-data-action'
            id='year'
            data-test='audit-report-year'
            options={years}
            label='Year'
            name='year'
            value={values.year}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AuditReportSearchContainer;
