import api from './';
import {
  slugToIDs,
  quarterlyFormsIDs,
  annualFormsIDs
} from '../constants/dataForms';

export const dataFormEndpoints = {
  retrieveFromsList: 'org/forms/retrieve',
  retrieveSummary: 'org/form/summary',
  retrieve: 'org/form/retrieve',
  retrieveIntes: 'org/intesform/retrieve',
  retrieveIssuerFraud: 'org/issuerfraudform/retrieve',
  royaltyDropDown: 'royalty/exclusiontypesfororg',
  royaltyRetrieve: 'org/royalty/retrieve',
  updateField: 'form/field/save',
  crossCheck: 'org/form/crosscheck',
  royaltyCrossCheck: 'org/royalty/crosscheck',
  saveForm: 'org/form/save',
  saveFormIntes: 'org/intesform/save',
  saveFormIssuerFraud: 'org/issuerfraudform/save',
  saveRoyaltyForm: 'org/royalty/save',
  clearDataForm: 'org/formdata/delete',
  clearRoyaltyForm: 'org/royalty/clear',
  modifyRoyaltyForm: 'org/royalty/modify',
  intesCrossCheck: 'org/intesform/crosscheck',
  addIntesForm: 'org/intesformdata/modify',
  intesClearDataForm: 'org/intesformdata/clear',
  issuerFraudClearDataForm: 'org/issuerfraudform/cleardata',
  getIntesDropDown: 'intes/retrieve',
  getIssuerFraudCountriesDropDown: 'org/countries',
  getIssuerFraudTypeOfFraudDropDown: 'issuer/typeoffraud',
  issuerFraudCrossCheck: 'org/issuerfraudform/crosscheck',
  addIssuerFraudForm: 'org/issuerfraudform/modify',
  submitForms: 'org/forms/submit',
  downloadSubmittedForm: 'org/forms/download',
  quarterlyRetrieve: 'org/form/quarterly/retrieve',
  quarterlyCrossCheck: 'org/form/quarterly/crosscheck',
  quarterlyUpdateField: 'form/field/quarterly/save',
  quarterlySaveForm: 'org/form/quarterly/save',
  quarterlyClearData: 'org/form/quarterly/cleardata',
  quarterlySubmitForms: 'org/forms/quarterly/submit',
  downloadQuarterlyForms: 'org/forms/quarterly/download',
  annuallyRetrieve: 'org/form/annual/retrieve',
  annuallyCrossCheck: 'org/form/annual/crosscheck',
  annuallyUpdateField: 'form/field/annual/save',
  annuallySaveForm: 'org/form/annual/save',
  annuallyClearData: 'org/form/annual/cleardata',
  annuallySubmitForms: 'org/forms/annual/submit',
  downloadAnnuallyForms: 'org/forms/annual/download'
};

export const getFormApi = async (
  formId,
  orgReportingPeriod,
  formType,
  formName
) => {
  let formEndpoints;
  const data = {
    formId,
    reportingMonth: orgReportingPeriod.reportingMonth,
    reportingYear: orgReportingPeriod.reportingYear,
    orgProfileId: orgReportingPeriod.orgProfileId
  };
  if (slugToIDs[formId] === 'intes') {
    formEndpoints = dataFormEndpoints.retrieveIntes;
  } else if (slugToIDs[formId] === 'issuer-fraud') {
    formEndpoints = dataFormEndpoints.retrieveIssuerFraud;
  } else if (slugToIDs[formId] === 'royality-additions-exclusions') {
    formEndpoints = dataFormEndpoints.royaltyRetrieve;
  } else if (
    slugToIDs[formId] === 'card-member-summary' ||
    slugToIDs[formId] === 'card-volume-summary' ||
    slugToIDs[formId] === 'se-sales-volume-summary' ||
    slugToIDs[formId] === 'se-transactions-summary'
  ) {
    formEndpoints = dataFormEndpoints.retrieveSummary;
  } else if (quarterlyFormsIDs.includes(parseInt(formId))) {
    formEndpoints = dataFormEndpoints.quarterlyRetrieve;
  } else if (annualFormsIDs.includes(parseInt(formId))) {
    formEndpoints = dataFormEndpoints.annuallyRetrieve;
  } else {
    formEndpoints = dataFormEndpoints.retrieve;
  }

  const response = formType
    ? // To fetch data for Annual Forms
      await api().post(formEndpoints, {
        ...data,
        formType,
        formName
      })
    : // To fetch data for Quarterly and Monthy Forms
      await api().post(formEndpoints, {
        ...data
      });
  return response.data;
};

export const clearDataApi = async form => {
  const { formId } = form;
  let clearDataFormEndpoints;
  if (slugToIDs[formId] === 'intes') {
    clearDataFormEndpoints = dataFormEndpoints.intesClearDataForm;
  } else if (slugToIDs[formId] === 'issuer-fraud') {
    clearDataFormEndpoints = dataFormEndpoints.issuerFraudClearDataForm;
  } else if (slugToIDs[formId] === 'royality-additions-exclusions') {
    clearDataFormEndpoints = dataFormEndpoints.clearRoyaltyForm;
  } else if (quarterlyFormsIDs.includes(parseInt(formId))) {
    clearDataFormEndpoints = dataFormEndpoints.quarterlyClearData;
  } else if (annualFormsIDs.includes(parseInt(formId))) {
    clearDataFormEndpoints = dataFormEndpoints.annuallyClearData;
  } else {
    clearDataFormEndpoints = dataFormEndpoints.clearDataForm;
  }
  const res = await api().post(clearDataFormEndpoints, form);
  return res.data;
};

export const crossCheckApi = async body => {
  const { formId } = body;
  let crossCheckformEndpoints;
  if (slugToIDs[formId] === 'intes') {
    crossCheckformEndpoints = dataFormEndpoints.intesCrossCheck;
  } else if (slugToIDs[formId] === 'issuer-fraud') {
    crossCheckformEndpoints = dataFormEndpoints.issuerFraudCrossCheck;
  } else if (slugToIDs[formId] === 'royality-additions-exclusions') {
    crossCheckformEndpoints = dataFormEndpoints.royaltyCrossCheck;
  } else if (quarterlyFormsIDs.includes(parseInt(formId))) {
    crossCheckformEndpoints = dataFormEndpoints.quarterlyCrossCheck;
  } else if (annualFormsIDs.includes(parseInt(formId))) {
    crossCheckformEndpoints = dataFormEndpoints.annuallyCrossCheck;
  } else {
    crossCheckformEndpoints = dataFormEndpoints.crossCheck;
  }
  const res = await api().post(crossCheckformEndpoints, body);
  return res.data;
};

export const saveFormApi = async body => {
  const { formId } = body;
  let saveFormEndpoints;
  if (slugToIDs[formId] === 'intes') {
    saveFormEndpoints = dataFormEndpoints.saveFormIntes;
  } else if (slugToIDs[formId] === 'issuer-fraud') {
    saveFormEndpoints = dataFormEndpoints.saveFormIssuerFraud;
  } else if (slugToIDs[formId] === 'royality-additions-exclusions') {
    saveFormEndpoints = dataFormEndpoints.saveRoyaltyForm;
  } else if (quarterlyFormsIDs.includes(parseInt(formId))) {
    saveFormEndpoints = dataFormEndpoints.quarterlySaveForm;
  } else if (annualFormsIDs.includes(parseInt(formId))) {
    saveFormEndpoints = dataFormEndpoints.annuallySaveForm;
  } else {
    saveFormEndpoints = dataFormEndpoints.saveForm;
  }
  const res = await api().post(saveFormEndpoints, body);
  return res.data;
};

export const submitDataFormsApi = async body => {
  const { formId } = body;
  const submitFormEndPoint = quarterlyFormsIDs.includes(parseInt(formId))
    ? dataFormEndpoints.quarterlySubmitForms
    : dataFormEndpoints.submitForms;
  return await api().post(submitFormEndPoint, body);
};

export const updateDataFieldApi = async body => {
  const { formId } = body;
  let updateFieldEndpoint;
  if (quarterlyFormsIDs.includes(parseInt(formId))) {
    updateFieldEndpoint = dataFormEndpoints.quarterlyUpdateField;
  } else if (annualFormsIDs.includes(parseInt(formId))) {
    updateFieldEndpoint = dataFormEndpoints.annuallyUpdateField;
  } else {
    updateFieldEndpoint = dataFormEndpoints.updateField;
  }
  const res = await api().post(updateFieldEndpoint, body);
  return res.data;
};

export const formsListApi = async body => {
  const res = await api().post(dataFormEndpoints.retrieveFromsList, body);
  return {
    monthlyDataForms:
      (res.data.monthlyDataForms &&
        res.data.monthlyDataForms.map((row, i) => {
          return {
            ...row,
            enabled: row.formEditable || Boolean(res.data.isMonthlySubmitted),
            isUserSubmitted:
              Boolean(res.data.isMonthlySubmitted) ||
              Boolean(row.status === 'Submitted to DGN')
          };
        })) ||
      [],
    quarterlyDataForms:
      (res.data.quarterlyDataForms &&
        res.data.quarterlyDataForms.map((row, i) => {
          return {
            ...row,
            enabled: row.formEditable || Boolean(res.data.isQuarterlySubmitted),
            isUserSubmitted:
              Boolean(res.data.isQuarterlySubmitted) ||
              Boolean(row.status === 'Submitted to DGN')
          };
        })) ||
      [],
    annuallyDataForms:
      (res.data.annuallyDataForms &&
        res.data.annuallyDataForms.map((row, i) => {
          return {
            ...row,
            enabled: row.formEditable || Boolean(res.data.isAnnuallySubmitted),
            isUserSubmitted: Boolean(row.status === 'Submitted to DGN')
          };
        })) ||
      []
  };
};

export const updateRoyaltyFormApi = async body => {
  return await api().post(dataFormEndpoints.modifyRoyaltyForm, body);
};

export const getRoyaltyDropDown = async orgProfileId => {
  try {
    const res = await api().get(dataFormEndpoints.royaltyDropDown, {
      params: {
        orgProfileId
      }
    });
    return res.data;
  } catch (e) {}
};

export const getIntesDataApi = async () => {
  try {
    const res = await api().get(dataFormEndpoints.getIntesDropDown);
    return res.data;
  } catch (e) {}
};

export const updateIntesDataFormApi = async body => {
  return await api().post(dataFormEndpoints.addIntesForm, body);
};

export const updateIssuersFraudDataFormApi = async body => {
  return await api().post(dataFormEndpoints.addIssuerFraudForm, body);
};

export const countriesApi = async () => {
  try {
    const res = await api().get(
      dataFormEndpoints.getIssuerFraudCountriesDropDown
    );
    return res.data;
  } catch (e) {}
};

export const typeOfFraudApi = async () => {
  try {
    const res = await api().get(
      dataFormEndpoints.getIssuerFraudTypeOfFraudDropDown
    );
    return res.data;
  } catch (e) {}
};

export const downloadSubmittedFormApi = async body => {
  const {
    reportingMonth,
    orgProfileId,
    orgName,
    reportingYear
  } = body.orgReportingPeriod;
  let formDataType;
  if (body.formType === 'monthlyDataForms') {
    formDataType = 'Monthly';
  } else if (body.formType === 'quarterlyDataForms') {
    formDataType = 'Quarterly';
  }
  try {
    return await api().get(dataFormEndpoints.downloadSubmittedForm, {
      params: {
        orgName,
        orgProfileId,
        reportingMonth,
        reportingYear,
        formType: formDataType
      }
    });
  } catch (e) {
    return e.response;
  }
};
