import React from 'react';
import './TrashIcon.css';
import PropTypes from 'prop-types';
import { ReactComponent as SvgIcon } from '../../../assets/icons/delete.svg';
import { Grid } from '@material-ui/core';

const TrashIcon = ({ onClickIcon }) => {
  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      className='trash-icon-container'
      data-test='check-icon-component'
      onClick={onClickIcon}>
      <Grid item>
        <SvgIcon className='trash-icon' />
      </Grid>
    </Grid>
  );
};

TrashIcon.propTypes = {
  onClickIcon: PropTypes.func.isRequired
};

export default TrashIcon;
