/* eslint-disable max-lines-per-function */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import './UserOrganizationalRolesList.css';
import { OrganizationChipComponent } from '..';
import { toastFunc } from '../ToastComponent/toastFunction';

class UserOrganizationalRolesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgRoleProfiles: props.orgRoleProfiles,
      luaStatus: ''
    };
  }

  // ComponentDidUpdate(_, prevState) {
  //   if (
  //     JSON.stringify(prevState.orgRoleProfiles) !==
  //     JSON.stringify(this.props.orgRoleProfiles)
  //   ) {
  //     this.setState({
  //       orgRoleProfiles: this.props.orgRoleProfiles
  //     });
  //   }
  // }

  approveUser = data => {
    const { status, roleTypeCode, organizationProfileId, userProfileId } = data;
    const obj = {
      userProfileId,
      orgRoleProfiles: [
        {
          organizationProfileVO: [
            {
              organizationProfileId,
              rejectedReason: '',
              status
            }
          ],
          organizationRoleVO: {
            roleTypeCode
          }
        }
      ]
    };
    this.props.manageOrgnizationData(obj).then(response => {
      let content = '';
      let type = '';
      if (response.status === 200) {
        content = 'Access has been Accepted Successfully';
        type = 'accept';
        this.setState(prevState => {
          return {
            ...prevState,
            isApproved: 'A'
          };
        });
      } else {
        content = response.statusText;
        type = 'reject';
      }

      toastFunc({
        content,
        config: { className: `toast-container ${type}` }
      });
    });
  };

  onDragStart = (e, orgId, name, roleId, userProfileId) => {
    const idObj = JSON.stringify({ orgId, roleId, userProfileId });
    e.dataTransfer.setData('text', idObj);
  };

  onDrop = (e, newRoleId) => {
    e.persist();
    const idObj = e.dataTransfer.getData('text');
    const { roleId, orgId, userProfileId } = JSON.parse(idObj);
    if (newRoleId === roleId) {
      return false;
    }
    const data = {
      status: 'A',
      roleTypeCode: newRoleId,
      organizationProfileId: orgId,
      userProfileId
    };
    const userRoleMap = this.props.user.orgRoleProfiles;
    const matchingRole = userRoleMap.find(
      role => role.roleTypeCode === data.roleTypeCode
    );
    if (matchingRole) {
      const organizationProfiles = matchingRole.organizationProfiles;
      const matchingOrganization = organizationProfiles.find(
        profile => profile.organizationProfileId === data.organizationProfileId
      );
      if (matchingOrganization) {
        toastFunc({
          content: 'User already has requested access.',
          config: { className: 'toast-container reject' },
          toastType: 'error'
        });
      } else {
        this.approveUser(data);
        this.resetDraggableAreaBorder(e);
      }
    } else {
      this.approveUser(data);
      this.resetDraggableAreaBorder(e);
    }
  };

  onDragOver = ev => {
    ev.preventDefault();
  };

  resetDraggableAreaBorder = e => {
    if (e.currentTarget.classList.contains('draggable-area')) {
      e.currentTarget.style.border = 'none';
    }
  };

  addDraggableAreaBorder = e => {
    if (e.currentTarget.classList.contains('draggable-area')) {
      e.currentTarget.style.border = '1px solid #d9e7ef';
    }
  };

  setLuaData = status => {
    this.setState({
      luaStatus: status
    });
  };

  render() {
    const {
      manageOrgnizationData,
      user,
      totalOrganizationalRoles,
      totalOrganizationalNames,
      pageType,
      orgRoleProfiles,
      modifyOrganizationData,
      deleteOrganizationData,
      roleMap,
      userStatus,
      impersonate,
      activeButtonStatus,
      allActiveOrgLength
    } = this.props;
    return (
      <Grid
        container
        justify='center'
        alignItems='center'
        data-test='user-org-roles-list-component'>
        {pageType === 'add' &&
          orgRoleProfiles &&
          orgRoleProfiles.map(organizationalRole => {
            return (
              <Grid
                key={organizationalRole.roleTypeCode}
                item
                md={12}
                className='organization-role-row'>
                <Grid
                  container
                  className='organization-role-row-container'
                  justify='flex-start'
                  alignItems='center'>
                  <Grid item md={3}>
                    <Typography variant='body2' className='org-role-type'>
                      {organizationalRole.roleTypeName}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={7}
                    className='draggable-area'
                    onDragOver={this.onDragOver}
                    onDrop={e =>
                      this.onDrop(e, organizationalRole.roleTypeCode)
                    }
                    onDragEnter={this.addDraggableAreaBorder}
                    onDragLeave={this.resetDraggableAreaBorder}
                    data-test='draggable-area'>
                    <Grid
                      container
                      className='organization-role-row-list-container'>
                      {organizationalRole.organizationProfiles &&
                        organizationalRole.organizationProfiles.map(
                          organizationProfile => (
                            <Grid
                              key={organizationProfile.organizationProfileId}
                              item
                              className='org-names-container'>
                              <OrganizationChipComponent
                                data-test='org-component'
                                actionsEnable
                                name={
                                  organizationProfile.organizationProfileName
                                }
                                isAdmin={organizationProfile.localUserAdmin}
                                organizationProfileId={
                                  organizationProfile.organizationProfileId
                                }
                                user={user}
                                roleId={organizationalRole.value}
                                status={organizationProfile.status}
                                manageOrgnizationData={manageOrgnizationData}
                                pageType={pageType}
                                modifyOrganizationData={modifyOrganizationData}
                                deleteOrganizationData={deleteOrganizationData}
                                onDragStart={this.onDragStart}
                                onChangeSwitch={status => {
                                  this.setLuaData(status);
                                }}
                                userStatus={userStatus}
                                activeButtonStatus={activeButtonStatus}
                                allActiveOrgLength={allActiveOrgLength}
                              />
                            </Grid>
                          )
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        {pageType !== 'add' &&
          totalOrganizationalRoles &&
          totalOrganizationalRoles.map(organizationalRole => {
            return (
              <Grid
                key={organizationalRole.value}
                item
                md={12}
                className='organization-role-row'>
                <Grid
                  container
                  className='organization-role-row-container'
                  justify='flex-start'
                  alignItems='center'>
                  <Grid item md={3}>
                    <Typography variant='body2' className='org-role-type'>
                      {organizationalRole.label}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={7}
                    className={`draggable-area ${
                      (roleMap || userStatus === 'I' || impersonate) &&
                      activeButtonStatus !== true
                        ? 'non-draggable-area'
                        : ''
                    }`}
                    onDragOver={this.onDragOver}
                    onDrop={e => this.onDrop(e, organizationalRole.value)}
                    onDragEnter={this.addDraggableAreaBorder}
                    onDragLeave={this.resetDraggableAreaBorder}
                    data-test='draggable-component'>
                    <Grid
                      container
                      className='organization-role-row-list-container'>
                      {orgRoleProfiles.map(org => {
                        if (org && organizationalRole.id === org.roleTypeCode) {
                          return (
                            org.organizationProfiles &&
                            org.organizationProfiles.map(
                              organizationProfile => {
                                const access = totalOrganizationalNames.some(
                                  orgT =>
                                    orgT.value ===
                                    organizationProfile.organizationProfileId
                                );
                                return (
                                  <Grid
                                    key={
                                      organizationProfile.organizationProfileId
                                    }
                                    item
                                    className='org-names-container'>
                                    <OrganizationChipComponent
                                      name={
                                        organizationProfile.organizationProfileName
                                      }
                                      isAdmin={
                                        organizationProfile.localUserAdmin
                                      }
                                      organizationProfileId={
                                        organizationProfile.organizationProfileId
                                      }
                                      user={user}
                                      roleId={organizationalRole.value}
                                      actionsEnable={access}
                                      status={organizationProfile.status}
                                      manageOrgnizationData={
                                        manageOrgnizationData
                                      }
                                      pageType={pageType}
                                      onDragStart={this.onDragStart}
                                      onChangeSwitch={status => {
                                        this.setLuaData(status);
                                      }}
                                      userStatus={userStatus}
                                      impersonate={impersonate}
                                      activeButtonStatus={activeButtonStatus}
                                      allActiveOrgLength={allActiveOrgLength}
                                      data-test='org-component'
                                    />
                                  </Grid>
                                );
                              }
                            )
                          );
                        }
                        return null;
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    );
  }
}

UserOrganizationalRolesList.propTypes = {
  orgRoleProfiles: PropTypes.array.isRequired,
  pageType: PropTypes.string.isRequired,
  totalOrganizationalRoles: PropTypes.array,
  user: PropTypes.object,
  manageOrgnizationData: PropTypes.func,
  totalOrganizationalNames: PropTypes.array
};
UserOrganizationalRolesList.defaultProps = {
  totalOrganizationalRoles: [],
  totalOrganizationalNames: [],
  user: {},
  manageOrgnizationData: () => {}
};

export default UserOrganizationalRolesList;
