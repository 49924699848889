/* eslint-disable react/jsx-child-element-spacing */
import React, { Component } from 'react';
import { Modal } from '..';
import { Grid, Typography } from '@material-ui/core';

import PropTypes from 'prop-types';
import CreateIcon from '../../assets/icons/create_plus_icon.png';

class UserCreateConfirmModal extends Component {
  render() {
    const { isOpen, onConfirm, email, userLoginId } = this.props;
    return (
      <>
        <Modal
          title=''
          isOpen={isOpen}
          submitBtnLabel='OK'
          data-test='component-create-confirm-modal'
          onCancel={null}
          onSubmit={onConfirm}
          onClose={null}>
          <Grid
            container
            justify='center'
            className='reject-modal-container'
            direction='column'
            alignItems='center'>
            <Grid item md={12}>
              <img
                src={CreateIcon}
                alt='title'
                style={{ height: '50px', width: '50px' }}
              />
            </Grid>
            <Grid item md={12}>
              <Typography
                variant='h6'
                style={{
                  textAlign: 'center',
                  marginTop: '20px',
                  color: '#2477AB'
                }}>
                {userLoginId}
              </Typography>
              <Typography
                variant='h6'
                style={{ textAlign: 'center', marginTop: '20px' }}>
                {' '}
                GlobalNet account has been created and
                <br />
                the user's temporary password sent to the provided email ID
              </Typography>
              <Typography
                variant='h6'
                style={{
                  textAlign: 'center',
                  color: '#2477AB',
                  fontWeight: '600',
                  margin: '7px'
                }}>
                {email}
              </Typography>
            </Grid>
          </Grid>
        </Modal>
      </>
    );
  }
}

UserCreateConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default UserCreateConfirmModal;
