import React from 'react';
import './CirclePlusIcon.css';
import { ReactComponent as SvgIcon } from '../../../assets/icons/plus.svg';
import { Grid } from '@material-ui/core';

const CirclePlusIcon = () => {
  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      data-test='circle-plus-icon-component'
      className='circe-plus-icon-container'>
      <Grid item>
        <SvgIcon className='circe-plus-icon' />
      </Grid>
    </Grid>
  );
};

export default CirclePlusIcon;
