import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { ADMIN_TEXT, salutationOptions } from '../../../../constants/text';
import { Switch, Dropdown, TextField } from '../../../../components/material';

class UserPersonalDataSection extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object
  };

  static defaultProps = {
    values: {},
    touched: {},
    errors: {}
  };

  sectionOne = (values, handleChange, handleBlur, errors, touched) => {
    return (
      <Grid container className='row'>
        <Grid item md={4} style={{ padding: '0 15px' }}>
          <Grid container direction='column'>
            <Dropdown
              fullWidth
              id='salutation'
              options={salutationOptions}
              placeholder='Please Select'
              label={ADMIN_TEXT.salutation}
              name='salutation'
              value={values.salutation}
              error={errors.salutation && touched.salutation}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { handleChange, values, handleBlur, errors, touched } = this.props;

    return (
      <Grid
        container
        data-test='user-personal-data-component'
        className='user-personal-data-component'>
        <Grid item md={12} className='user-personal-data-container'>
          <Typography variant='h6'>{ADMIN_TEXT.personalData}</Typography>
        </Grid>
        <Grid item md={12} className='user-personal-data-body-section'>
          {this.sectionOne(values, handleChange, handleBlur, errors, touched)}

          <Grid container className='row'>
            <Grid item md={4} style={{ padding: '0 15px' }}>
              <Grid container direction='column'>
                <TextField
                  fullWidth
                  required
                  name='firstName'
                  label={ADMIN_TEXT.firstName}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Grid item md={4} style={{ padding: '0 15px' }}>
              <Grid container direction='column'>
                <TextField
                  fullWidth
                  name='middleName'
                  label={ADMIN_TEXT.middleName}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Grid item md={4} style={{ padding: '0 15px' }}>
              <Grid container direction='column'>
                <TextField
                  fullWidth
                  required
                  label={ADMIN_TEXT.lastName}
                  name='lastName'
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className='row'>
            <Grid item md={4} style={{ padding: '0 15px' }}>
              <Grid container direction='column'>
                <TextField
                  fullWidth
                  required
                  label={ADMIN_TEXT.emailId}
                  name='emailId'
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Grid item md={4} style={{ padding: '0 15px' }}>
              <Grid container direction='column'>
                <TextField
                  fullWidth
                  required
                  label={ADMIN_TEXT.jobTitle}
                  name='jobTitle'
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Grid item md={4} style={{ padding: '0 15px' }}>
              <Grid container style={{ height: '100%' }} alignItems='center'>
                <Switch
                  id='active'
                  label='Active'
                  name='active'
                  value='active'
                  checked={Boolean(values.active.length)}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default UserPersonalDataSection;
