import { announcementsActions } from './types';

export const initialState = {
  importantDates: [],
  announcement: {},
  singleAnnouncement: {}
};

const announcementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case announcementsActions.SET_ANNOUNCEMENT:
      return {
        ...state,
        announcement: action.payload
      };

    case announcementsActions.SET_IMPORTANT_DATES:
      return {
        ...state,
        importantDates: action.payload
      };
    case announcementsActions.GET_SINGLE_ANNOUNCEMENT:
      return {
        ...state,
        singleAnnouncement: action.payload
      };
    default:
      return state;
  }
};

export default announcementsReducer;
