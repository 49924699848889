import { connect } from 'react-redux';
import UserSearch from './UserSearch';
import {
  getUserProfileSearchResults,
  getAdminOrganizationNames,
  getSearchedUserProfile
} from '../../../../store/admin/actions';

export const mapStateToProps = state => {
  return {
    searchedUserProfile: state.admin.searchedUserProfile,
    userProfiles: state.admin.userProfile,
    usersOrganizations: state.admin.totalAdminOrganizationalNames,
    userProfileId: state.user.usrProfId,
    userPageInfo: state.admin.userPageInfo
  };
};

export default connect(mapStateToProps, {
  getSearchedUserProfile: userProfile => getSearchedUserProfile(userProfile),
  getUserProfileSearchResults: userProfile =>
    getUserProfileSearchResults(userProfile),
  getAdminOrganizationNames
})(UserSearch);
