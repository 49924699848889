import React from 'react';
import { connect } from 'formik';
import { TextField as Input } from '@material-ui/core';

import PropTypes from 'prop-types';

export const TextField = props => {
  const fieldProps = { ...props };
  delete fieldProps.formik;
  const {
    name,
    formik: { values, handleChange, handleBlur, touched, errors, status }
  } = props;

  return (
    <Input
      data-test='component-TextField'
      value={values[name]}
      error={
        touched[name] && Boolean(errors[name] || status[name] || status.root)
      }
      helperText={touched[name] && (errors[name] || status[name])}
      onChange={handleChange}
      onBlur={handleBlur}
      {...fieldProps}
    />
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired
};

export default connect(TextField);
