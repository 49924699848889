import React from 'react';

import { Grid } from '@material-ui/core';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const tranformDateToShowMonthName = date => {
  if (date) {
    const dateParts = date.split('/');
    return [dateParts[0], months[dateParts[1] - 1], dateParts[2]].join(' ');
  }
};

const getStatusCodeStyles = statusCode => {
  if (statusCode === 'A') {
    return 'announcement-listing-active';
  }
  if (statusCode === 'U') {
    return 'announcement-listing-upcoming';
  }
  return '';
};

const getStatusCodeText = statusCode => {
  if (statusCode === 'A') {
    return 'Active';
  }
  if (statusCode === 'U') {
    return 'Upcoming';
  }
  return '';
};

function AnnouncementListing({
  announcement,
  announcementClicked,
  selectedAnnouncementId
}) {
  return (
    <Grid
      data-test='main-grid-component'
      container
      item
      direction='row'
      className={`announcement-listing-item ${
        announcement.announcementId === selectedAnnouncementId
          ? 'announcements-selected-item'
          : ''
      }`}
      alignItems='center'
      onClick={() => announcementClicked(announcement.announcementId)}>
      <Grid item className='announcement-listing-title-container'>
        <p className='announcement-listing-title'>
          {announcement.announcementTitle}
        </p>
      </Grid>
      <Grid item className='announcement-listing-active-container'>
        <p className={getStatusCodeStyles(announcement.announcementStatus)}>
          {getStatusCodeText(announcement.announcementStatus)}
        </p>
      </Grid>
      <Grid item className='announcement-listing-start-date-container'>
        <p className='announcement-listing-start-date' align='right'>
          {tranformDateToShowMonthName(announcement.announcementStartDate)}
        </p>
      </Grid>
    </Grid>
  );
}

AnnouncementListing.propTypes = {};

export default AnnouncementListing;
