import { base as theme } from './base';

export const combobox = {
  MuiAutocomplete: {
    option: {
      marginBottom: 1,
      fontSize: 15,
      width: '-webkit-fill-available',
      '&[data-focus="true"]': {
        backgroundColor: `${theme.palette.input.selected} !important`
      },
      '&[aria-selected="true"]': {
        backgroundColor: `${theme.palette.input.selected} !important`,
        color: `${theme.palette.secondary.main} !important`
      },
      '&:hover': {
        backgroundColor: theme.palette.input.selected
      },
      '&:seleted': {
        backgroundColor: theme.palette.input.selected
      }
    },
    tag: {
      position: 'relative',
      top: -6
    },
    paper: {
      borderRadius: 0,
      border: `1px solid ${theme.palette.secondary.main}`,
      padding: 0,
      margin: 0
    },
    input: {
      position: 'relative',
      top: -6
    },
    clearIndicator: {
      pointerEvents: 'none',
      visibility: 'visible'
    },
    inputRoot: {
      height: '43px'
    },
    inputFocused: {
      position: 'relative'
    },
    popupIndicator: {
      backgroundColor: 'inherit !important'
    },
    noOptions: {
      padding: '6px 16px 6px 16px',
      fontSize: 15
    },
    listbox: {
      padding: 0
    }
  }
};
