export const GET_PENDING_APPROVAL_USERS = 'GET_PENDING_APPROVAL_USERS';
export const SELECT_SINGLE_PENDING_APPROVAL_USER =
  'SELECT_SINGLE_PENDING_APPROVAL_USER';
export const ADD_SINGLE_USER_ORGANIZATION_ROLE =
  'ADD_SINGLE_USER_ORGANIZATION_ROLE';
export const REMOVE_SINGLE_USER_ORGANIZATION_ROLE =
  'REMOVE_SINGLE_USER_ORGANIZATION_ROLE';
export const GET_USER_PAGE_INFO = 'GET_USER_PAGE_INFO';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_SEARCHED_USER_PROFILE = 'GET_SEARCHED_USER_PROFILE';
export const GET_SINGLE_PENDING_APPROVAL_USER =
  'GET_SINGLE_PENDING_APPROVAL_USER';
export const ADD_ORGANIZATION_DATA = 'ADD_ORGANIZATION_DATA';
export const GET_ADMIN_ORGANIZATION_NAMES = 'GET_ADMIN_ORGANIZATION_NAMES';
export const CREATE_USER = 'CREATE_USER';
export const MANAGE_ORGANIZATION_DATA = 'MANAGE_ORGANIZATION_DATA';
export const ORG_ROLE_PROFILES_FOR_ADD = 'ORG_ROLE_PROFILES_FOR_ADD';
export const GET_ALL_ANNOUNCEMENTS = 'GET_ALL_ANNOUNCEMENTS';
export const GET_MEGA_MENU_ORGANIZATION_NAMES =
  'GET_MEGA_MENU_ORGANIZATION_NAMES';
export const GET_ORG_PARAMS = 'GET_ORG_PARAMS';
export const GET_AUDIT_REPORT = 'GET_AUDIT_REPORT';
export const GET_EXCHANGE_RATES = 'GET_EXCHANGE_RATES';
export const SEARCHED_RATES_FORM = 'SEARCHED_RATES_FORM';
export const CHECK_EXCHANGE_RATES = 'CHECK_EXCHANGE_RATES';
export const CLEAR_EXCHANGE_RATES = 'CLEAR_EXCHANGE_RATES';
