import GenericTable from './GenericTable';
import TableInput from './TableInput';
import { connect } from 'react-redux';

export const mapStateToProps = state => {
  return {
    form: state.dataForms.form
      ? state.dataForms.form.tabs[state.dataForms.activeTab]
      : [],
    activeTab: state.dataForms.activeTab,
    formId: state.dataForms.form ? state.dataForms.form.formId : ''
  };
};

export default connect(mapStateToProps)(GenericTable);

export { TableInput };
