/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@material-ui/core';
import {
  TextField,
  Dropdown,
  MultipleCombobox
} from '../../../components/material';
import { landingPageText } from '../../../constants/text';

const salutations = [
  {
    label: 'Mr.',
    value: 'Mr.'
  },
  {
    label: 'Ms.',
    value: 'Ms.'
  },
  {
    label: 'Mrs.',
    value: 'Mrs.'
  }
];

const PersonalDetails = props => {
  const {
    orgs,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    totalUserRoles,
    luaStatus
  } = props;
  const [state, setstate] = useState(true);
  const [buisnesNameOrg, setBuisnesNameOrg] = useState(0);

  useEffect(() => {
    setBuisnesNameOrg(
      values.businessName.filter(id => id.luaavailable === true).length
    );

    if (
      buisnesNameOrg > 0 &&
      values.firstName.length > 0 &&
      values.lastName.length > 0 &&
      values.emailId.length > 0 &&
      values.jobTitle.length > 0 &&
      values.roleLabel.length > 0 &&
      values.businessName.length > 0 &&
      luaStatus !== 3
    ) {
      setstate(false);
    } else {
      setstate(true);
    }
  }, [values, buisnesNameOrg]);
  return (
    <div className='w-100'>
      <MultipleCombobox
        required
        limit={1}
        id='businessName'
        options={orgs}
        placeholder='Please Select'
        label={landingPageText.businessNameLabel}
        name='businessName'
        error={errors.businessName && touched.businessName}
        helperText={touched.businessName && errors.businessName}
        value={values.businessName}
        className='form-input'
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Grid item md className='two-column'>
        <Dropdown
          required
          fullWidth
          id='roleLabel'
          options={totalUserRoles}
          placeholder='Please Select'
          label={landingPageText.roleLabel}
          name='roleLabel'
          error={errors.roleLabel && touched.roleLabel}
          value={values.roleLabel}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
      <br />
      <Grid container className='form-input'>
        <Grid item md className='two-column'>
          <Dropdown
            fullWidth
            id='salutation'
            options={salutations}
            placeholder='Please Select'
            label={landingPageText.salutationLabel}
            name='salutation'
            error={errors.salutation && touched.salutation}
            value={values.salutation}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item md>
          <TextField
            fullWidth
            required
            id='first-name'
            label={landingPageText.firstNameLabel}
            name='firstName'
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
      <Grid container className='form-input'>
        <Grid item md className='two-column'>
          <TextField
            fullWidth
            id='middle-name'
            label={landingPageText.middleNameLabel}
            name='middleName'
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item md>
          <TextField
            fullWidth
            required
            id='last-name'
            label={landingPageText.lastNameLabel}
            name='lastName'
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
      <TextField
        fullWidth
        required
        id='job-title'
        label={landingPageText.jobTitleLabel}
        name='jobTitle'
        className='form-input'
        onBlur={handleBlur}
      />
      <TextField
        fullWidth
        required
        id='email-id'
        label={landingPageText.emailIdLabel}
        name='emailId'
        className='form-input'
        onBlur={handleBlur}
      />
      <Grid container direction='row-reverse'>
        <Grid item md={4}>
          <Button
            fullWidth
            type='submit'
            variant='contained'
            color='primary'
            disabled={state}>
            {landingPageText.next}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PersonalDetails;
