import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import GenericTable from '../../../components/generictable';
import {
  setDataForms,
  changeTab,
  resetDataForms,
  uploadReviewUrl
} from './../../../store/dataForms/actions';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Loader } from '../../../components';

export class CommonForm extends Component {
  componentDidMount() {
    const formType = localStorage.getItem('formType');
    if (formType === 'annuallyDataForms') {
      const orgReportingPeriod = JSON.parse(
        localStorage.getItem('orgReportingPeriod')
      );
      const planYear = localStorage.getItem('planYear')
        ? parseInt(localStorage.getItem('planYear'))
        : parseInt(orgReportingPeriod.reportingYear) + 1;
      this.props.setDataForms(this.props.location.pathname, planYear);
    } else {
      this.props.setDataForms(this.props.location.pathname);
    }
  }

  componentWillUnmount() {
    this.props.resetDataForms();
  }

  render() {
    const { tabs, activeTab, changeTab } = this.props;
    if (!tabs.length) {
      return (
        <Grid
          container
          item
          justify='center'
          alignItems='center'
          data-test='component-franchise'
          data-testid='component-se-sales-volume-loader'>
          <Loader data-test='component-loader' />
        </Grid>
      );
    }

    return (
      <div
        className={`table-wrapper ${uploadReviewUrl(
          this.props.location.pathname
        )}`}
        data-test='component-se-sales-volume'>
        {tabs.length >= 1 && (
          <div className='tabs' data-test='tabs'>
            {tabs.map((tab, index) => {
              if (tab.name !== null) {
                return (
                  <button
                    key={tab.id}
                    type='button'
                    data-test='Commonform-button'
                    className={`cursor-hover ${
                      index === activeTab ? 'active' : ''
                    }`}
                    onClick={() =>
                      changeTab(index, this.props.location.pathname)
                    }>
                    <span data-test='span-tab-name'>{tab.name}</span>
                    {tab.hasError && (
                      <span className='error-dot' data-test='error-dot' />
                    )}
                    {tab.isActualQuarter && !tab.isQuarterSubmitted && (
                      <span className='actual-dot' data-test='actual-dot' />
                    )}
                    {tab.isQuarterSubmitted && (
                      <span className='tick-mark' data-test='tick-mark' />
                    )}
                  </button>
                );
              }
            })}
          </div>
        )}

        <GenericTable />
      </div>
    );
  }
}

CommonForm.propTypes = {
  tableName: PropTypes.string.isRequired,
  tabs: PropTypes.array
};

export const mapStateToProps = state => {
  return {
    tabs:
      state.dataForms.form && state.dataForms.form.tabs
        ? state.dataForms.form.tabs.map(
            ({ tabIdentifier, tabName, hasError, actual, submitted }) => ({
              id: tabIdentifier,
              name: tabName,
              isActualQuarter: actual ? actual : null,
              isQuarterSubmitted: submitted ? submitted : null,
              hasError
            })
          )
        : [],
    activeTab: state.dataForms.activeTab,
    tableName: 'CommonForm'
  };
};

export const mapDispatchToProps = {
  setDataForms,
  changeTab,
  resetDataForms
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CommonForm));
