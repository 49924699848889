import React, { Fragment } from 'react';
import { Typography, Button } from '@material-ui/core';
import ErrorIcon from '../../assets/icons/error.svg';
import PropTypes from 'prop-types';

const UploadError = props => (
  <Fragment>
    <span className='white-Space' />
    <div className='upload-icon-outer'>
      <div className='upload-icon-inner'>
        <img
          src={ErrorIcon}
          className='data-file-error-icon'
          data-test='file-error-icon'
          alt='Error Icon'
        />
      </div>
    </div>
    <Typography
      data-test='error-msg-txt'
      className='error-txt'
      color='secondary'
      variant='h6'>
      {props.errorMessage}
    </Typography>
    <Typography
      id='file-upload-div-caption'
      className='dnd-txt'
      color='secondary'
      variant='h6'>
      Drag and Drop your files
    </Typography>
    <p className='or-txt'>or</p>
    <Button
      classes={{ root: 'browse-file-button' }}
      color='primary'
      variant='contained'
      aria-label='file browse botton'
      data-test='browse-button-error'
      onClick={props.fileSelectHandler}>
      <Typography variant='body1'>Browse</Typography>
    </Button>
    <p className='types-tx'>(Supported file types: .xlsx, .csv)</p>
  </Fragment>
);

UploadError.propTypes = {
  fileSelectHandler: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired
};

export default UploadError;
