import React, { Component } from 'react';
import { ReactComponent as GlobeIcon } from '../../assets/icons/globe.svg';
import { Modal } from '..';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setNetworkSuccess } from '../../store/error/actions';
import './NetworkError.css';
import { COMMON_TEXT } from '../../constants/text';
import { withRouter } from 'react-router-dom';

export class NetworkError extends Component {
  render() {
    const { networkError } = this.props;
    return (
      <Modal
        isOpen={networkError}
        title='Network Error'
        submitBtnLabel='Reconnect'
        data-test='component-network-error'
        onClose={this.props.setNetworkSuccess}
        onSubmit={() => {
          this.props.setNetworkSuccess();
          if (
            this.props.location.pathname !== '/home' &&
            this.props.location.pathname !== '/'
          ) {
            this.props.history.push('/home');
          }
        }}>
        <Grid container direction='column' justify='center' alignItems='center'>
          <Grid item className='network-grid-item'>
            <GlobeIcon />
            <div className='warning-icon-container'>
              <div>!</div>
            </div>
          </Grid>
          <Grid item>
            <span>{COMMON_TEXT.networkErrorText}</span>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

NetworkError.propTypes = {
  networkError: PropTypes.bool.isRequired,
  setNetworkSuccess: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStatetoProps = state => ({
  networkError: state.errors.networkError
});

export default connect(mapStatetoProps, { setNetworkSuccess })(
  withRouter(NetworkError)
);
