import { userActions } from './types';
import {
  getUserProfileInformationApi,
  updateUserProfileApi,
  getUserApi,
  changepasswordProfileApi
} from '../../api/user';
import { catchError } from '../error/actions';
import { login } from '../auth';

export const setUserProfile = payload => ({
  type: userActions.SET_USER_PROFILE,
  payload
});

export const resetUserProfile = () => ({
  type: userActions.RESET_USER_PROFILE
});

export const setUpdateUserProfile = payload => ({
  type: userActions.UPDATE_USER_PROFILE,
  payload
});

export const setChangepasswordProfile = payload => ({
  type: userActions.CHANGE_PASS_PROFILE,
  payload
});

export const setChangepasswordTimestamp = payload => ({
  type: userActions.CHANGE_PASS_TIME,
  payload
});

export const setUpdateUserProfileValue = isProfileUpdated => dispatch => {
  if (isProfileUpdated) {
    dispatch(setUpdateUserProfile(!isProfileUpdated));
  }
};

export const setUpdateUserPasswordValue = isProfilePasswordUpdated => dispatch => {
  if (isProfilePasswordUpdated) {
    dispatch(setChangepasswordProfile(!isProfilePasswordUpdated));
  }
};

export const fetchUserProfile = profileId => async dispatch => {
  try {
    const res = await getUserProfileInformationApi(profileId);
    let fullName =
      res.data.middleName === null
        ? res.data.firstName + ' ' + res.data.lastName
        : res.data.firstName +
          ' ' +
          res.data.middleName +
          ' ' +
          res.data.lastName;

    if (res.headers['x-csrf-token'] !== undefined) {
      dispatch(login(res.headers['x-csrf-token']));
    }
    dispatch({
      type: userActions.SET_USER_PROFILE,
      payload: {
        ...res.data,
        usrFullName: fullName
      }
    });
  } catch (err) {
    dispatch(catchError(err, 'MY_PROFILE'));
  }
};

export const updateUserProfile = body => async dispatch => {
  try {
    const res = await updateUserProfileApi(body);
    dispatch(setUpdateUserProfile(res.data));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(catchError(err, 'MY_PROFILE'));
  }
};
export const changepasswordProfile = body => async dispatch => {
  try {
    const res = await changepasswordProfileApi(body);
    if (res) {
      dispatch(setChangepasswordProfile(res));
    }
  } catch (err) {
    dispatch(catchError(err, 'MY_PROFILE'));
  }
};

export const getUser = () => async dispatch => {
  try {
    const res = await getUserApi();
    if (res.headers['x-csrf-token'] !== undefined) {
      dispatch(
        login(res.headers['x-csrf-token'], res.data.ddidStatus === 'review')
      );
    }
    dispatch(setUserProfile(res.data));
  } catch (err) {
    dispatch({
      type: 'LOGOUT'
    });
  }
};

export const updatedTerms = data => async dispatch => {
  dispatch(setUserProfile(data));
};
