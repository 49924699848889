import React from 'react';
import Routes from './Routes';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Header, Footer, MegaMenuContainer } from './components';
import { noMegaMenuPath, noHeaderPath } from './constants/paths';
import { closeMegaMenu } from './store/ui';

export const App = props => {
  const {
    location,
    isDataMenuActive,
    isAdminMenuActive,
    isSettingsMenuActive,
    isUserAuthenticated
  } = props;
  return (
    <Grid
      container
      direction='row'
      className='app'
      data-test='component-routes'
      onClick={() => props.closeMegaMenu(location.pathname)}>
      {!noHeaderPath.includes(location.pathname) && (
        <Header data-test='header-component' />
      )}
      {!noMegaMenuPath.includes(location.pathname) && isUserAuthenticated && (
        <MegaMenuContainer
          isDataMenuActive={isDataMenuActive}
          isAdminMenuActive={isAdminMenuActive}
          isSettingsMenuActive={isSettingsMenuActive}
        />
      )}
      <Grid
        item
        md={12}
        id='main-content'
        data-test='main-content'
        className={`${
          !noMegaMenuPath.includes(location.pathname) ? 'bg' : ''
        }`}>
        <Routes />
      </Grid>
      <Footer data-test='footer-component' />
    </Grid>
  );
};

App.propTypes = {
  location: PropTypes.object.isRequired,
  closeMegaMenu: PropTypes.func.isRequired,
  isDataMenuActive: PropTypes.bool.isRequired,
  isAdminMenuActive: PropTypes.bool.isRequired,
  isSettingsMenuActive: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isDataMenuActive: state.ui.isDataMenuActive,
  isAdminMenuActive: state.ui.isAdminMenuActive,
  isUserAuthenticated: state.auth.isUserAuthenticated,
  isSettingsMenuActive: state.ui.isSettingsMenuActive
});
const mapDispatchToProps = {
  closeMegaMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
