import * as Yup from 'yup';
import rules from '../../fieldRules';

const {
  royaltyRateScaleFrom,
  royaltyRateScaleRate,
  contractOrganizationName,
  contractNotes,
  contractType,
  effectiveStartDate,
  effectiveEndDate
} = rules;

export const royaltyRateScaleSchema = props =>
  Yup.object().shape({
    tireNumber: Yup.string()
      .notOneOf(
        props.royaltyRateScale.map(rate => String(rate.tireNumber)),
        'Tire Number Should be Unique'
      )
      .matches(
        /^\d{1,2}$|^.{0}$/,
        'Tier Number is Invalid. Please enter a value from 0 to 9.'
      )
      .required('Please enter a value for Tire Number'),
    from: royaltyRateScaleFrom,
    rate: royaltyRateScaleRate
  });

export const createContractSchema = Yup.object().shape({
  globalNetOrganizationId: contractOrganizationName,
  notes: contractNotes,
  contractType,
  effectiveStartDate,
  effectiveEndDate
});

export const editContractSchema = Yup.object().shape({
  contractType,
  effectiveStartDate,
  effectiveEndDate
});
