import React, { forwardRef, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import SearchIcon from '@material-ui/icons/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Grid, Button, Typography, Tooltip } from '@material-ui/core';
import * as XLSX from 'xlsx';
import { blobToObject } from '../../../store/upload/actions';
import { HOME_TEXT } from '../../../constants/text';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { format } from 'date-fns';

const RatesResultTable = props => {
  const [isFilterEnable, setIsFilterEnable] = useState(false);
  const tableRef = useRef();
  const { exchangeRates, headers, values, loadExchangeRate, clearForm } = props;
  const [rates, setRates] = useState([]);
  useEffect(() => {
    if (!loadExchangeRate) {
      const data = exchangeRates.map(rate => ({
        ...rate,
        loadedDate: rate.loadedDate
          ? format(new Date(rate.loadedDate), 'yyyy-MM-dd')
          : '',
        settlementDate: rate.settlementDate
          ? format(new Date(rate.settlementDate), 'yyyy-MM-dd')
          : ''
      }));
      setRates(data);
    } else {
      const data = exchangeRates.map(rate => ({
        ...rate,
        organizationName: null,
        currency: rate.sellCurrency,
        exchangeRate: rate.fxRate,
        loadedDate: format(new Date(), 'yyyy-MM-dd'),
        settlementDate: rate.settlementDate,
        provider: rate.provider.code
      }));
      setRates(data);
    }
  }, [loadExchangeRate, exchangeRates]);
  const columns = [];
  headers.map(header => {
    columns.push({ title: header.title, field: header.value, sorting: true });
  });
  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#1A587E'
      },
      '& .MuiTableRow-root-458': {
        color: '#ffffff'
      }
    },
    overrides: {
      MuiTableSortLabel: {
        root: {
          color: '#ffffff',
          '&:hover': {
            color: '#ffffff',
            fontSize: '15px'
          }
        },
        active: {
          color: '#ffffff !important'
        },
        icon: {
          color: '#ffffff !important',
          userSelect: 'all'
        }
      }
    }
  });
  const getFileName = () => {
    const startYear = new Date(values.startDate).getFullYear();
    const endYear = new Date(values.endDate).getFullYear();
    const startMonth = String(
      HOME_TEXT.monthsList[new Date(values.startDate).getMonth()]
    ).toUpperCase();
    const endMonth = String(
      HOME_TEXT.monthsList[new Date(values.endDate).getMonth()]
    ).toUpperCase();
    let period = `${startMonth}`;
    if (startYear === endYear) {
      if (startMonth === endMonth) {
        period = `${period}_${endYear}`;
      } else {
        period = `${period}-${endMonth}_${endYear}`;
      }
    } else {
      period = `${period}_${endYear}-${endMonth}_${endYear}`;
    }
    return `FX_RATES_${period}`;
  };
  const exportData = tableRef => {
    const filters = tableRef?.current?.state;
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    const fileName = getFileName();
    const filterData = filters?.data;
    const filteredData = [];
    filterData?.map(rate => {
      filteredData.push({
        [headers[0].title]:
          rate.rateId === null ? rate.organizationName : rate.rateId,
        Currency: rate.currency,
        'Monthly Exchange Rate': rate.exchangeRate,
        'Loaded Date': rate.loadedDate,
        'Settlement Date': rate.settlementDate
      });
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { FX_Rates: ws }, SheetNames: ['FX_Rates'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    blobToObject(data, fileName, fileExtension);
  };
  return (
    <>
      <Grid
        container
        justify='space-between'
        className='search-rates-result-txt-cont'>
        <Typography variant='h6' className='search-rates-result-txt'>
          Search Results <span>({rates.length} records found)</span>
        </Typography>
        {!loadExchangeRate && (
          <Grid item md={4} className='search-rates-button-container-item'>
            <Tooltip
              placement='bottom'
              title='Filters'
              data-test='tooltip-filter-button'>
              <Button
                color='primary'
                type='submit'
                size='small'
                data-test='filter-button'
                variant='contained'
                onClick={() => setIsFilterEnable(!isFilterEnable)}>
                {isFilterEnable ? <SearchOffIcon /> : <SearchIcon />}
              </Button>
            </Tooltip>
            <Tooltip
              placement='bottom'
              title='Download'
              data-test='tooltip-submit-button'>
              <Button
                color='primary'
                type='submit'
                size='small'
                data-test='submit-button'
                variant='contained'
                onClick={() => exportData(tableRef)}>
                <GetAppIcon data-test='download-icon' />
              </Button>
            </Tooltip>
          </Grid>
        )}
        {loadExchangeRate && (
          <Grid item md={4} className='search-rates-button-container-item'>
            <Tooltip
              placement='bottom'
              title='Clear the Load Fx Rate'
              data-test='tooltip-filter-button'>
              <Button
                color='primary'
                type='submit'
                size='small'
                data-test='filter-button'
                variant='contained'
                onClick={clearForm}>
                Clear
              </Button>
            </Tooltip>
          </Grid>
        )}
      </Grid>

      <ThemeProvider theme={theme}>
        <MaterialTable
          data-test='Exchange-Rates-Table'
          tableRef={tableRef}
          columns={columns}
          data={rates}
          components={{
            Toolbar: () => null
          }}
          icons={{
            Filter: () => <SearchIcon />,
            SortArrow: forwardRef((props, ref) => (
              <ArrowDownward {...props} ref={ref} />
            )),
            FirstPage: () => <SkipPreviousIcon />,
            LastPage: () => <SkipNextIcon />,
            NextPage: () => <NavigateNextIcon />,
            PreviousPage: () => <NavigateBeforeIcon />
          }}
          options={{
            showTitle: false,
            selection: false,
            search: false,
            filtering: isFilterEnable,
            filterCellStyle: {
              fontFamily: 'Meta Offc Pro, Arial',
              fontSize: '13px',
              fontWeight: '500',
              lineHeight: '22px',
              color: '#022a48',
              cursor: 'default !important'
            },
            headerStyle: {
              backgroundColor: '#022a48',
              color: '#ffffff',
              fontWeight: '500',
              textAlign: 'left',
              fontFamily:
                '"MetaOffcPro Normal",Helvetica,Arial,Verdana,Tahoma,sans-serif',
              cursor: 'default !important'
            },
            rowStyle: {
              fontFamily: 'Meta Offc Pro, Arial',
              fontSize: '13px',
              fontWeight: '500',
              lineHeight: '22px',
              color: '#022a48',
              cursor: 'default !important'
            }
          }}
        />
      </ThemeProvider>
    </>
  );
};
RatesResultTable.propTypes = {
  exchangeRates: PropTypes.array,
  headers: PropTypes.array,
  values: PropTypes.object
};
RatesResultTable.defaultProps = {
  exchangeRates: [],
  headers: [],
  values: {}
};
export default RatesResultTable;
