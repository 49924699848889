import api from './';

const contractsEndPoints = {
  create: 'org/contract/create',
  parentOrganizations: 'org/parents/retrieve',
  childOrganizations: '/org/childs/retrieve',
  singleContract: 'org/contract/retrieve',
  contractByOrgID: 'org/contracts',
  exclusionTypes: 'org/contract/exclusiontypes',
  orgContractUpdate: 'org/contract/update',
  royaltyRateUpdate: 'contract/royaltyrate/update',
  contractExclusionsUpdate: 'contract/exclusions/update'
};

export const createContractApi = async body => {
  try {
    return await api().post(contractsEndPoints.create, body);
  } catch (e) {
    return e.response;
  }
};

export const getParentOrganizationsApi = async () => {
  try {
    const response = await api().get(contractsEndPoints.parentOrganizations);
    return response.data;
  } catch (e) {}
};

export const getChildOrganizationsByParentOrgIdApi = async globalnetOrganizationId => {
  try {
    const response = await api().get(contractsEndPoints.childOrganizations, {
      params: { orgInfoId: globalnetOrganizationId }
    });
    return response.data;
  } catch (e) {}
};

export const getContractByIdApi = async contractId => {
  try {
    return await api().get(contractsEndPoints.singleContract, {
      params: { contractId }
    });
  } catch (e) {
    return e.response;
  }
};
export const getContractByOrgId = async orgId => {
  try {
    const response = await api().get(contractsEndPoints.contractByOrgID, {
      params: { globalnetOrganizationId: orgId }
    });
    return response.data;
  } catch (e) {
    return e.response;
  }
};

export const getExclusionTypesApi = async () => {
  try {
    const response = await api().get(contractsEndPoints.exclusionTypes);
    return response.data;
  } catch (e) {}
};

export const updateContractApi = async body => {
  const response = await api().post(contractsEndPoints.orgContractUpdate, body);
  return response.data;
};

export const updateRoyaltyRate = async body => {
  const response = await api().post(contractsEndPoints.royaltyRateUpdate, body);
  return response.data;
};

export const updateExclusionTypes = async body => {
  const response = await api().post(
    contractsEndPoints.contractExclusionsUpdate,
    body
  );
  return response.data;
};
