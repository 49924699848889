import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { TextField } from '../../../components/formik';

function ContractNotesForm({ notes, handleChange, handleBlur, mode }) {
  return (
    <Grid
      item
      md={12}
      className='contract-notes-text-area-item'
      data-test='component-ContractNotesForm'>
      <TextField
        multiline
        fullWidth
        rows={5}
        className='bg-white'
        label='Enter Notes'
        required={mode === 'create'}
        name='notes'
        value={notes}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Grid>
  );
}

ContractNotesForm.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired
};

ContractNotesForm.defaultProps = {
  value: ''
};

export default ContractNotesForm;
