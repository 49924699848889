import React from 'react';
import ComboBox from '../../../../components/material/Combobox';
import { Grid, InputAdornment } from '@material-ui/core';
import {
  Dropdown,
  DatePicker as FormikDatePicker
} from '../../../../components/material';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';

const UserLogsCriteria = props => {
  const {
    values,
    handleChange,
    handleBlur,
    adminsOrganizations,
    errors,
    touched
  } = props;
  return (
    <Grid
      container
      className='user-search-fields-container'
      data-test='user-search-fields-container'>
      <Grid
        container
        alignItems='flex-start'
        direction='row'
        data-test='user-search-row-container'
        style={{ marginLeft: '3%' }}>
        <Grid item xs={3} data-test='user-search-item4-container'>
          <ComboBox
            required
            id='orgProfileId'
            name='orgProfileId'
            className='user-search-textbox'
            data-test='user-search-textbox'
            label='Select Organization Name'
            options={adminsOrganizations}
            value={values.orgProfileId}
            error={touched.orgProfileId && errors.orgProfileId}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid
          item
          xs={3}
          data-test='user-search-item2-container'
          className='user-search-item2-container'
          style={{ display: 'flex' }}>
          <FormikDatePicker
            style={{ flex: '1' }}
            autoOk
            required
            variant='inline'
            inputVariant='outlined'
            label='Start Date (dd/mm/yyyy)'
            format='dd/MM/yyyy'
            data-test='user-datepicker-container'
            name='startDate'
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <CalendarIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          data-test='user-search-item2-container'
          className='user-search-item2-container'
          style={{ display: 'flex' }}>
          <FormikDatePicker
            style={{ flex: '1' }}
            autoOk
            required
            variant='inline'
            inputVariant='outlined'
            label='End Date (dd/mm/yyyy)'
            format='dd/MM/yyyy'
            data-test='user-datepicker-container'
            name='endDate'
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <CalendarIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          data-test='user-search-item4-container'
          className='user-search-item3-container'>
          <Dropdown
            options={[
              { label: 'Active', value: 'A' },
              { label: 'Pending', value: 'P' },
              { label: 'Inactive', value: 'I' },
              { label: 'Rejected', value: 'R' }
            ]}
            name='status'
            label='Status'
            data-test='user-search-dropdown'
            className='user-search-textbox'
            value={values.status}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserLogsCriteria;
