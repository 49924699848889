import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import {
  addOrganizationData,
  getAdminOrganizationNames,
  manageOrgnizationData
} from '../../../../store/admin/actions';
import { getTotalOrganizationRoles } from '../../../../store/global/actions';

import UserDetailsHeaderSection from './UserDetailsHeaderSection';
import UserPersonalDataSection from './UserPersonalDataSection';
import { UserOrganizationalRolesSection } from '../../../../components';

export class UserDetailsScreen extends Component {
  componentDidMount() {
    this.props.getTotalOrganizationRoles();
    this.props.getAdminOrganizationNames();
  }

  render() {
    const {
      selectedUser: user,
      totalAdminOrganizationalNames,
      totalOrganizationalRoles,
      addOrganizationData,
      manageOrgnizationData,
      impersonate
    } = this.props;
    return (
      <>
        <Grid
          container
          justify='flex-start'
          alignItems='flex-start'
          className='user-details-screen-container'>
          <Grid item md={12}>
            <UserDetailsHeaderSection user={user} />
            <UserPersonalDataSection
              user={user}
              totalOrganizationalNames={totalAdminOrganizationalNames}
            />

            <UserOrganizationalRolesSection
              pageType='list'
              user={user}
              totalOrganizationalNames={totalAdminOrganizationalNames}
              totalOrganizationalRoles={totalOrganizationalRoles}
              addOrganizationData={addOrganizationData}
              manageOrgnizationData={manageOrgnizationData}
              userStatus={user.accountStatus}
              impersonate={impersonate}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

UserDetailsScreen.propTypes = {
  getTotalOrganizationRoles: PropTypes.func.isRequired,
  getAdminOrganizationNames: PropTypes.func.isRequired,
  addOrganizationData: PropTypes.func.isRequired,
  manageOrgnizationData: PropTypes.func.isRequired,
  totalOrganizationalRoles: PropTypes.array,
  totalAdminOrganizationalNames: PropTypes.array,
  selectedUser: PropTypes.object.isRequired
};

UserDetailsScreen.defaultProps = {
  totalOrganizationalRoles: [],
  totalAdminOrganizationalNames: []
};

export const mapStateToProps = state => ({
  totalAdminOrganizationalNames: state.admin.totalAdminOrganizationalNames,
  totalOrganizationalRoles: state.global.totalOrganizationalRoles
});

export default connect(mapStateToProps, {
  addOrganizationData,
  getAdminOrganizationNames,
  getTotalOrganizationRoles,
  manageOrgnizationData
})(UserDetailsScreen);
