import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ContractNotesForm from '../ContractNotesForm';

function ContractNotes({ handleChange, values, handleBlur }) {
  return (
    <Grid
      container
      data-test='contract-notes-component'
      className='contract-notes-component'>
      <Grid item md={12} className='row-header-bar'>
        <Typography variant='h6'> Contract Notes</Typography>
      </Grid>
      <ContractNotesForm
        mode='create'
        notes={values.notes}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
    </Grid>
  );
}

export default ContractNotes;
