/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const Header = props => {
  const [impersonate, setImpersonate] = useState('');
  useEffect(() => {
    const impersonateData = localStorage.getItem('impersonateUserData')
      ? JSON.parse(localStorage.getItem('impersonateUserData'))
      : {};
    setImpersonate(Boolean(Object.keys(impersonateData).length > 0));
  }, []);
  return (
    <Grid item md={12} className='user-search-header-container'>
      <Grid container justify='space-between' alignItems='center'>
        <Typography
          variant='h6'
          className='user-search-header-title'
          data-test='user-search-header-title'>
          Profile Search
        </Typography>
        {impersonate
          ? null
          : (props.roles === 'Portal Admin' ||
              props.roles === 'Local User Admin' ||
              props.roles === 'User Admin') && (
              <Button
                component={Link}
                to='/admin/users/create'
                color='primary'
                variant='contained'
                type='button'
                size='small'
                data-test='user-search-add-profile-btn'
                className='user-search-add-profile-btn'>
                Add Profile
              </Button>
            )}
      </Grid>
    </Grid>
  );
};

export default Header;
