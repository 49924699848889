import React, { Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { copyrightYear } from '../../utils/commonFunctions';
import { noHeaderPath } from '../../constants/paths';
import './footer.css';

const Footer = props => {
  if (noHeaderPath.includes(props.location.pathname) && !props.hasError) {
    return <Fragment />;
  }
  return (
    <Grid
      container
      item
      alignItems='center'
      justify='center'
      className='h-80 dci-gray-border-top dci-gray-border-bottom footer'
      data-test='component-footer'
      role='contentinfo'
      style={{ alignSelf: 'flex-end' }}>
      <Typography
        variant='caption'
        aria-label='copyright-text'
        data-test='copyright-text'>
        {`Copyright ©${copyrightYear()}, Discover Financial Services`}
      </Typography>
    </Grid>
  );
};

Footer.propTypes = {
  location: PropTypes.object.isRequired
};

export default Footer;
