import React, { Component, Fragment } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Tooltip,
  Box,
  Icon
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getUser } from '../../store/user/actions';
import './Header.css';
import { ReactComponent as HeaderLastLoginIcon } from '../../assets/icons/header-lastlogin.svg';
import { ReactComponent as HeaderSettingsIcon } from '../../assets/icons/header-settings.svg';
import { COMMON_TEXT } from '../../constants/text';
import ImpersonateUserCancelButton from '../Impersonate/ImpersonateUserCancelButton';
import { cancelImpersonateUserApi } from '../../api/admin';

export class Header extends Component {
  state = {
    impersonateData: {}
  };

  componentDidMount() {
    const { getUser, isUserAuthenticated } = this.props;
    const impersonateData = localStorage.getItem('impersonateUserData')
      ? JSON.parse(localStorage.getItem('impersonateUserData'))
      : {};
    if (
      isUserAuthenticated === false ||
      (impersonateData && impersonateData.isImpersonating)
    ) {
      getUser();
      this.setState({ impersonateData });
    }
  }

  cancelImpersonation = () => {
    const impData = {
      isImpersonating: false,
      redirectUrl: this.state.impersonateData.redirectUrl,
      firstName: this.state.impersonateData.firstName,
      lastName: this.state.impersonateData.lastName,
      accountStatus: this.state.impersonateData.accountStatus,
      organizationProfileId: this.state.impersonateData.organizationProfileId
    };

    const { getUser } = this.props;
    cancelImpersonateUserApi()
      .then(async res => {
        if (res) {
          await getUser();
          localStorage.setItem('impersonateUserData', JSON.stringify(impData));
          this.setState({ impersonateData: impData });
          this.props.history.push(this.state.impersonateData.redirectUrl);
        } else {
          this.props.history.replace('/error');
        }
      })
      .catch(e => {
        this.props.history.replace('/error');
      });
  };

  // eslint-disable-next-line max-lines-per-function
  renderRightSection = () => {
    const {
      usrFullName,
      highestRole,
      lastLoginDates,
      logoutUser,
      isUserAuthenticated,
      isChallenged,
      otpValidated,
      internalUserFlag
    } = this.props;
    if (!isUserAuthenticated || (isChallenged && !otpValidated)) {
      return null;
    }
    const isDateNotNull = Boolean(lastLoginDates);
    // C this.props.getUser();
    let lastLoginDate;
    let remainingLoginDates;
    if (isDateNotNull) {
      [lastLoginDate, ...remainingLoginDates] = lastLoginDates.length
        ? lastLoginDates
        : [];
    }
    return (
      <div data-test='right-container-div' className='right-header-container'>
        <Grid item data-test='header-right-container-grid'>
          <Grid container alignItems='center' data-test='headercontainer-grid'>
            <Grid item data-test='right-container-div'>
              <Typography
                color='secondary'
                display='inline'
                className='header-last-login'
                data-test='header-last-login'>
                {COMMON_TEXT.lastLoggedIn}
              </Typography>
              <Typography
                color='primary'
                display='inline'
                className='header-last-login'
                data-test='header-date-login'
                aria-label='last-login'>
                {isDateNotNull && lastLoginDate}
              </Typography>
            </Grid>
            <Tooltip
              data-test='header-login-tooltip'
              placement='bottom'
              classes={{ tooltip: 'last-login-tooltip' }}
              title={
                <Fragment>
                  {isDateNotNull &&
                    remainingLoginDates.map(date => (
                      <Typography
                        key={date}
                        color='textPrimary'
                        variant='caption'
                        display='block'
                        className='tooltip-date'
                        data-test='tooltip-date'>
                        {date}
                      </Typography>
                    ))}
                </Fragment>
              }>
              <Icon
                className='header-last-login-icon header-icon'
                aria-hidden='false'
                data-test='tooltip-date-icon'>
                <HeaderLastLoginIcon
                  role='img'
                  aria-label='Last Logins icon'
                  data-test='HeaderLastLoginIcon-icon'
                />
              </Icon>
            </Tooltip>
            {(!internalUserFlag || internalUserFlag == 'N') && (
              <Icon
                className='header-icon'
                aria-hidden='false'
                data-test='settings-icon'>
                <Link to='/profile'>
                  <HeaderSettingsIcon
                    role='img'
                    aria-label='Settings icon'
                    data-test='HeaderSettingsIcon-icon'
                  />
                </Link>
              </Icon>
            )}
            <Tooltip
              placement='bottom'
              title='Help'
              data-test='help-tooltip-container'>
              <Icon
                className='header-icon'
                aria-hidden='false'
                data-test='help-header'>
                <Link to='/home-help-page'>
                  <Box
                    aria-label='Help icon'
                    className='header-help-icon'
                    data-test='help-icon'>
                    <Typography color='secondary' data-test='help-symbol'>
                      ?
                    </Typography>
                  </Box>
                </Link>
              </Icon>
            </Tooltip>
            <div className='flex-1' />
            <Grid
              item
              className='header-user-container'
              data-test='header-user-container'>
              <Grid container direction='column' alignItems='flex-end'>
                <Typography
                  color='secondary'
                  className='header-username'
                  aria-label='username'
                  data-test='header-username'>
                  {COMMON_TEXT.hello} {usrFullName}
                </Typography>
                <Grid item data-test='header-user-role-container'>
                  <Grid container justify='space-between'>
                    <Typography
                      className='dci-grey header-role'
                      display='inline'
                      aria-label='user-role'
                      data-test='header-user-role'>
                      ({highestRole})
                    </Typography>
                    <Typography
                      className='dci-grey header-seperator'
                      display='inline'
                      data-test='header-logout-typo'>
                      |
                    </Typography>
                    <Link
                      aria-label='logout-link'
                      data-test='header-logout-link'
                      onClick={logoutUser}>
                      <Typography
                        display='inline'
                        className='header-role'
                        data-test='header-logout'>
                        {COMMON_TEXT.logout}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        {this.state.impersonateData &&
        this.state.impersonateData.isImpersonating ? (
          <ImpersonateUserCancelButton
            userName={this.state.impersonateData.userName}
            onClick={this.cancelImpersonation}
          />
        ) : null}
        <AppBar
          elevation={1}
          position='static'
          className='app-header'
          role='banner'
          aria-label='header'
          data-test='component-header'>
          <Toolbar className='header-container' data-test='header-container'>
            <Grid
              container
              justify='space-between'
              alignItems='center'
              data-test='header-grid'>
              <Grid item aria-label='branding' data-test='header-branding'>
                <div
                  container
                  justify='flex-start'
                  data-test='header-container-img'
                  className='header-container-header'>
                  <div className='header-container-hearderMain'>GlobalNet</div>
                  <div className='header-container-headerSub'>
                    Partner Reporting Solution
                  </div>
                </div>
              </Grid>
              {this.renderRightSection()}
            </Grid>
          </Toolbar>
        </AppBar>
      </Fragment>
    );
  }
}

Header.propTypes = {
  fullName: PropTypes.string,
  appRole: PropTypes.string,
  location: PropTypes.object.isRequired,
  lastLoginDates: PropTypes.array,
  isUserAuthenticated: PropTypes.bool.isRequired,
  logoutUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

Header.defaultProps = {
  fullName: undefined,
  appRole: undefined,
  lastLoginDates: []
};

const mapStateToProps = state => ({
  isUserAuthenticated: state.auth.isUserAuthenticated,
  isChallenged: state.auth.isChallenged,
  otpValidated: state.auth.otpValidated
});

const mapDispatchToProps = {
  getUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
