import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import authReducer from './auth/reducer';
import announcementsReducer from './announcements/reducer';
import franchisesDataReducer from './franchisesData/reducer';
import userReducer from './user/reducer';
import errorReducer from './error/reducer';
import uiReducer from './ui/reducer';
import adminReducer from './admin/reducer';
import globalReducer from './global/reducer';
import organizationsReducer from './organizations/reducer';
import contractsReducer from './contracts/reducer';
import dataFormsReducer from './dataForms/reducer';
import formsListNavReducer from './formsListNav/reducer';
import { authActions } from './auth/types';

const middlewares = [thunk /* Other Middlewares */];

const combinedReducer = combineReducers({
  auth: authReducer,
  announcements: announcementsReducer,
  errors: errorReducer,
  franchisesData: franchisesDataReducer,
  user: userReducer,
  ui: uiReducer,
  admin: adminReducer,
  global: globalReducer,
  organizations: organizationsReducer,
  contracts: contractsReducer,
  dataForms: dataFormsReducer,
  formsListNav: formsListNavReducer
});

export const rootReducer = (state, action) => {
  if (action.type === authActions.LOGOUT) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = createStore(
  rootReducer,
  composeWithDevTools({
    trace: true,
    traceLimit: 25
  })(applyMiddleware(...middlewares))
);
export default store;
