import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Home,
  LandingPage,
  DataForms,
  UploadFlow,
  Profile,
  Contracts,
  Organizations,
  AdminUser,
  Announcements,
  ProductLine,
  ExtensionRequests,
  UnlockFranchisee,
  AuditReport,
  ExchangeRatesForm
} from './containers';
import Inputs from './Inputs';
import PageError from './containers/PageError/PageError';
import { CanActivate, IdleTimerContainer } from './components';
import ErrorBoundary from './containers/PageError/ErrorBoundary';
import OtpPage from './containers/LandingPage/OtpPage';
import HelpPage from './containers/LandingPage/HelpPage';
import LoginHelpPage from './containers/LandingPage/LoginHelpPage';
import HomeHelpPage from './containers/Home/HomeHelpPage';

export const PublicViews = props => {
  const { isUserAuthenticated, isChallenged, changePassword } = props;
  if (changePassword) {
    return <Redirect to='/change-password' />;
  }

  if (isUserAuthenticated && !isChallenged) {
    return <Redirect to='/home' />;
  }

  if (isUserAuthenticated && isChallenged) {
    return <Redirect to='/otp' />;
  }

  return (
    <Switch>
      <Route exact path='/'>
        <LandingPage data-test='component-login' />
      </Route>
      <Route exact path='/register'>
        <LandingPage data-test='component-register' />
      </Route>
      <Route exact path='/forgot-userid'>
        <LandingPage data-test='component-forgot-userid' />
      </Route>
      <Route exact path='/userid-verification'>
        <LandingPage data-test='component-forgot-userid' />
      </Route>
      <Route exact path='/forgot-password'>
        <LandingPage data-test='component-forgot-password' />
      </Route>
      <Route>
        <PageError />
      </Route>
    </Switch>
  );
};

export const PrivateViews = props => {
  const {
    isLoggedOut,
    isUserAuthenticated,
    isChallenged,
    otpValidated
  } = props;
  if (isLoggedOut) {
    return <Redirect to='/' />;
  }

  if (isUserAuthenticated && isChallenged && !otpValidated) {
    return <Redirect to='/otp' />;
  }

  if (!isUserAuthenticated) {
    return (
      <Route exact path='/home'>
        <Home />
      </Route>
    );
  }

  return (
    <>
      <IdleTimerContainer />
      <Switch>
        <Route exact path='/home'>
          <Home data-test='component-home' />
        </Route>
        <Route path='/manage-data/upload'>
          <UploadFlow data-test='component-franchise' />
        </Route>
        <Route path='/manage-data/manual'>
          <DataForms data-test='component-franchise' />
        </Route>
        <Route path='/profile'>
          <Profile data-test='component-profile' />
        </Route>
        <Route path='/profile-internal'>
          <Profile edit={false} data-test='component-profile-internal' />
        </Route>
        <Route path='/admin/users'>
          <AdminUser />
        </Route>
        <Route path='/admin/organization'>
          <Organizations />
        </Route>
        <Route path='/admin/contract'>
          <Contracts />
        </Route>
        <Route path='/admin/product-line'>
          <ProductLine />
        </Route>
        <Route path='/admin/extension-requests'>
          <ExtensionRequests />
        </Route>
        <Route path='/admin/announcements'>
          <CanActivate
            action='ancmnts'
            unAuthorizedView={<PageError errorCode='401' />}>
            <Announcements data-test='component-announcements' />
          </CanActivate>
        </Route>
        <Route path='/admin/unlock-franchisee'>
          <UnlockFranchisee />
        </Route>
        <Route path='/admin/audit-report'>
          <AuditReport />
        </Route>
        <Route path='/admin/exchange-rates'>
          <ExchangeRatesForm />
        </Route>
        <Route exact path='/home-help-page'>
          <HomeHelpPage />
        </Route>
        <Route>
          <PageError />
        </Route>
      </Switch>
    </>
  );
};

export const SecureViews = props => {
  const {
    isLoggedOut,
    isUserAuthenticated,
    isChallenged,
    otpValidated
  } = props;

  if (isLoggedOut) {
    return <Redirect to='/' />;
  }

  if (
    (isUserAuthenticated && !isChallenged) ||
    (isUserAuthenticated && isChallenged && otpValidated)
  ) {
    return <Redirect to='/home' />;
  }

  return (
    <Switch>
      <Route exact path='/otp'>
        <OtpPage data-test='component-otp' />
      </Route>
      <Route>
        <PageError />
      </Route>
    </Switch>
  );
};

export const PreSecureViews = props => {
  const { isUserAuthenticated, changePassword } = props;
  const userProfile = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null;

  if (!isUserAuthenticated && !changePassword && !userProfile) {
    return <Redirect to='/' />;
  }

  return (
    <Switch>
      <Route exact path='/change-password'>
        <LandingPage data-test='component-change-password' />
      </Route>
      <Route>
        <PageError />
      </Route>
    </Switch>
  );
};

export const Routes = props => {
  const {
    isUserAuthenticated,
    isLoggedOut,
    isChallenged,
    otpValidated,
    changePasswordMsg
  } = props;
  return (
    <Fragment>
      <ErrorBoundary>
        <Switch>
          <Route exact path='/help-page'>
            <HelpPage />
          </Route>
          <Route exact path='/login-help-page'>
            <LoginHelpPage />
          </Route>
          <Route
            exact
            path='(/|/register|/forgot-userid|/forgot-password|/userid-verification)'>
            <PublicViews
              isUserAuthenticated={isUserAuthenticated}
              isChallenged={isChallenged}
              changePassword={changePasswordMsg}
            />
          </Route>
          <Route path='/(home|admin|manage-data|profile|home-help-page)'>
            <PrivateViews
              isLoggedOut={isLoggedOut}
              isUserAuthenticated={isUserAuthenticated}
              isChallenged={isChallenged}
              otpValidated={otpValidated}
            />
          </Route>
          <Route path='(/otp)'>
            <SecureViews
              isLoggedOut={isLoggedOut}
              isUserAuthenticated={isUserAuthenticated}
              isChallenged={isChallenged}
              otpValidated={otpValidated}
            />
          </Route>
          <Route path='(/change-password)'>
            <PreSecureViews
              isUserAuthenticated={isUserAuthenticated}
              changePassword={changePasswordMsg}
            />
          </Route>
          <Route exact path='/components'>
            <Inputs />
          </Route>
          <Route>
            <PageError />
          </Route>
        </Switch>
      </ErrorBoundary>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  isLoggedOut: state.auth.isLoggedOut,
  isUserAuthenticated: state.auth.isUserAuthenticated,
  isChallenged: state.auth.isChallenged,
  otpValidated: state.auth.otpValidated,
  changePasswordMsg: state.user.changePasswordMsg
});

export default connect(mapStateToProps)(Routes);
