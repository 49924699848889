export const SEARCH_ORGANIZATION_START = 'SEARCH_ORGANIZATION_START';
export const SEARCH_ORGANIZATION_SUCCESS = 'SEARCH_ORGANIZATION_SUCCESS';
export const SEARCH_ORGANIZATION_ERROR = 'SEARCH_ORGANIZATION_ERROR';
export const GET_TOTAL_ORGANIZATION_TYPES = 'GET_TOTAL_ORGANIZATION_TYPES';
export const GET_TOTAL_ORGANIZATION_REGIONS = 'GET_TOTAL_ORGANIZATION_REGIONS';
export const GET_TOTAL_CURRENCIES = 'GET_TOTAL_CURRENCIES';
export const GET_TOTAL_PRODUCTS = 'GET_TOTAL_PRODUCTS';
export const ADD_PRODUCT_LINE = 'ADD_PRODUCT_LINE';
export const GET_TOTAL_ORGANIZATION_COUNTRIES =
  'GET_TOTAL_ORGANIZATION_COUNTRIES';
export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const CLEAR_ORGANIZATION = 'CLEAR_ORGANIZATION';
export const GET_SEARCHED_ORGANIZATION_PROFILE =
  'GET_SEARCHED_ORGANIZATION_PROFILE';
export const GET_TOTAL_EXCHANGE_REQUEST = 'GET_TOTAL_EXCHANGE_REQUEST';
