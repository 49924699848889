import { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import './LoginHelpPage.css';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'react-router-dom';
import { ReactComponent as BackButtonIcon } from '../../assets/icons/back-icon.svg';
import { queries } from '../../constants/loginHelpPage';

const LoginHelpPage = () => {
  const supportLink = 'GPOSupportTeam@discover.com';
  const [expanded, setExpanded] = useState(false);
  const toggle = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Grid container className='login-help-bg'>
      <Grid
        container
        className='login-help-header-container'
        data-test='login-help-header-container'>
        <Grid item xs={12}>
          <Grid container>
            <Grid item className='login-help-backheader-icon'>
              <Link to='/home'>
                <BackButtonIcon />
              </Link>
            </Grid>
            <Grid item className='login-help-backheader-text'>
              <Link to='/home' data-test='login-help-back-link'>
                <Typography
                  className='back-btn-text row-value'
                  variant='h6'
                  color='secondary'>
                  Back
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant='h2'
            className='login-help-heading'
            color='secondary'>
            Frequently Asked Questions
          </Typography>
        </Grid>
      </Grid>
      <Grid container className='login-help-main-container'>
        <Grid item xs={8}>
          {queries.map((query, index) => (
            <Accordion
              disableGutters
              expanded={expanded == `panel_${index}`}
              elevation={0}
              key={query.id}
              data-test={`login-help-page-accordion-${index}`}
              onChange={toggle(`panel_${index}`)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'>
                <Typography variant='h5' color='primary'>
                  {query.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className='login-help-list-answer'>
                <Typography color='secondary'>
                  {query.answer.first}
                  {query.answer.second && (
                    <ol>
                      {query.answer.second.map((item, index) => (
                        <li key={item.id}>{item.text}</li>
                      ))}
                    </ol>
                  )}
                  {query.answer.third}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ minWidth: 50 }}>
            <CardContent className='login-help-contact-container'>
              <Typography gutterBottom variant='h4' color='secondary'>
                For More Information
              </Typography>
              <Typography className='login-help-contact-text' color='secondary'>
                For all other questions regarding GlobalNet Reporting System
                login, please contact Global Partner Operation Support Team
                via&nbsp;
              </Typography>
              <a
                href={`mailto:${supportLink}`}
                target='_top'
                className='login-help-contact-phone'>
                {supportLink}
              </a>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginHelpPage;
