/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import XlsIcon from '../../assets/icons/xls-icon.svg';
import CsvIcon from '../../assets/icons/csv-icon.svg';
import { downloadFormTemplate } from '../../store/upload/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export class ResourceLinks extends React.Component {
  static propTypes = {
    downloadFormTemplate: PropTypes.func.isRequired
  };

  handleFileDownload = fileType => {
    const { orgReportingPeriod, orgParams, downloadFormTemplate } = this.props;
    let orgProfileId = orgReportingPeriod
      ? orgReportingPeriod.orgProfileId
      : '';
    const defaultOrgProfileId = orgParams
      ? orgParams.organizationProfileId
      : '';
    orgProfileId =
      orgProfileId !== undefined || orgProfileId == defaultOrgProfileId
        ? orgProfileId
        : defaultOrgProfileId;
    downloadFormTemplate(fileType, orgProfileId, orgReportingPeriod);
  };

  render() {
    const downloadLinks = [
      {
        icon: XlsIcon,
        href: '#home',
        alt: 'excel file Icon',
        text: 'xlsx'
      },
      {
        icon: CsvIcon,
        href: '#home',
        alt: 'csv file Icon',
        text: 'csv'
      }
    ];
    return (
      <Grid
        container
        className='resource-container'
        direction='column'
        justify='space-between'>
        <Grid
          item
          role='region'
          className='resource-links-heading-container'
          aria-labelledby='resource-links-heading'>
          <Typography
            className='resource-links-heading'
            data-test='resource-links-heading'
            color='primary'
            varient='h5'
            id='resource-links-heading'>
            RESOURCES
          </Typography>
          <Typography
            className='resource-links-subheading'
            data-test='resource-links-subheading'
            color='secondary'
            varient='caption'
            aria-label='Template For File Upload'>
            Template For File Upload
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            className='links-container'
            data-test='links-container'>
            {downloadLinks.map((link, index) => (
              <Grid key={index} container className='link-container'>
                <Grid item className='link-type' md={6}>
                  <Typography variant='caption' color='secondary'>
                    {link.text}
                  </Typography>
                </Grid>
                <Grid item className='link-download' md={6}>
                  <a
                    href={link.href}
                    onClick={e => {
                      e.preventDefault();
                      this.handleFileDownload(link.text);
                    }}>
                    <Typography variant='caption' color='secondary'>
                      Download{' '}
                      <img
                        src={link.icon}
                        data-test='download-icon'
                        className='download-icon'
                        alt={link.alt}
                      />
                    </Typography>
                  </a>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
export default connect(null, { downloadFormTemplate })(ResourceLinks);
