import React from 'react';
import { Grid, Button, InputAdornment } from '@material-ui/core';
import { Dropdown, DatePicker } from '../../../components/material';
import {
  maxAppDate,
  minAppDate,
  maxMonthDate
} from '../../../constants/exchangeRates';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';

export const ExchangeRatesForm = props => {
  const {
    isValid,
    dirty,
    values,
    handleChange,
    handleSubmit,
    resetForm,
    exchangeTypes
  } = props;

  return (
    <form
      noValidate
      aria-label='exchange rates search form'
      data-test='exchange-rates-search-form'
      onSubmit={handleSubmit}>
      <Grid
        container
        className='search-fields-container'
        data-test='exchange-rates-search-form-main-container'>
        <Grid
          item
          className='each-field-container'
          data-test='each-field-container'
          xs={3}>
          <Dropdown
            required
            options={exchangeTypes}
            label='Exchange Type'
            value={values.rateType}
            data-test='exchange-type-dropdown'
            name='rateType'
            style={{ width: '100%' }}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          className='each-field-container'
          data-test='from-date-picker-container'
          xs={2}>
          <DatePicker
            required
            autoOk
            variant='inline'
            inputVariant='outlined'
            format='MMM-yyyy'
            data-test='from-date-picker'
            views={['year', 'month']}
            label='From Date'
            name='startDate'
            value={values.startDate || null}
            disableFuture='true'
            minDate={minAppDate}
            maxDate={values.endDate === '' ? maxAppDate : values.endDate}
            style={{ width: '100%' }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <CalendarIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid
          item
          className='each-field-container'
          data-test='to-date-picker-container'
          xs={2}>
          <DatePicker
            required
            autoOk
            variant='inline'
            format='MMM-yyyy'
            data-test='to-date-picker'
            views={['year', 'month']}
            name='endDate'
            value={values.endDate || null}
            label='To Date'
            disableFuture='true'
            minDate={values.startDate === '' ? minAppDate : values.startDate}
            maxDate={maxMonthDate}
            style={{ width: '100%' }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <CalendarIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        className='search-rates-buttons'
        data-test='search-rates-buttons'>
        <Grid item md={8} data-test='search-rates-buttons-grid' />
        <Grid
          item
          md={4}
          className='search-rates-button-container-item'
          data-test='search-rates-button-container-item'>
          <Button
            color='secondary'
            variant='outlined'
            size='small'
            data-test='reset-button'
            disabled={!dirty}
            onClick={resetForm}>
            Clear
          </Button>
          <Button
            color='primary'
            type='submit'
            size='small'
            disabled={!isValid || !dirty}
            data-test='submit-button'
            variant='contained'>
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ExchangeRatesForm;
