import React, { useRef, useState } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { Switch } from '../../../components/material';
import FormHelperText from '@material-ui/core/FormHelperText';

import { announcementValidationSchema } from '../../../constants/validations/admin/announcement';
import { withFormik } from 'formik';

import FormikTextField from '../../../components/material/TextField';
import FormikDatePicker from '../../../components/material/DatePicker';
import AnnouncementButtons from './AnnouncementButtons';
import formText from '../../../constants/text/admin';
import { addAnnouncementApi } from '../../../api/admin';
import moment from 'moment';
import { createStatusFromErrors } from '../../../utils/commonFunctions';

const styles = theme => ({
  input: {
    height: 140,
    alignItems: 'flex-start',
    font: '400 18px Meta Offc Pro'
  },
  inputFont: {
    font: '400 18px Meta Offc Pro'
  }
});

export const AnnouncementCriteria = withStyles(styles)(props => {
  const {
    classes,
    newAnnouncementImageUpload,
    selectedFile,
    imageRemoved,
    imageErrorMessage,
    values,
    handleReset,
    dirty,
    isValid
  } = props;
  const fileInput = useRef(null);
  const [isScheduled, setIsScheduled] = useState(values.scheduled);

  const handleBrowse = () => {
    fileInput.current.click();
  };
  const handleBlur = e => {
    const { handleBlur, status, setStatus } = props;
    e.persist();
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };
  const handleSchedule = e => {
    const { setFieldValue } = props;
    setIsScheduled(!isScheduled);
    setFieldValue('scheduled', !isScheduled);
  };

  return (
    <form
      data-test='announcement-form'
      noValidate
      style={{ width: '100%' }}
      onSubmit={props.handleSubmit}>
      <Grid container className='announcements-fields-container'>
        <Grid
          item
          container
          alignItems='flex-start'
          direction='row'
          style={{ marginLeft: '3%' }}>
          <Grid item xs={4}>
            <FormikTextField
              required
              id='announcement-title'
              data-test='announcement-title'
              label='Announcement Title'
              name='announcementTitle'
              className='user-search-textbox'
              helperText='Max Character Limit: 4000'
              InputProps={{ className: classes.inputFont }}
              InputLabelProps={{ className: classes.inputFont }}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedInput
              readOnly
              label='Upload Image'
              name='lastname'
              className='user-search-textbox'
              value='Upload Image'
              style={{ paddingRight: 0 }}
              aria-describedby='standard-weight-helper-text'
              inputProps={{
                'aria-label': 'weight'
              }}
              endAdornment={
                <>
                  <input
                    ref={fileInput}
                    accept='image/*'
                    className={classes.input}
                    style={{ display: 'none' }}
                    id='uploadImage'
                    type='file'
                    name='uploadImage'
                    onBlur={handleBlur}
                    onChange={e => newAnnouncementImageUpload(e)}
                    onClick={event => {
                      event.target.value = null;
                    }}
                  />
                  <Button
                    style={{
                      fontWeight: 'normal',
                      height: 43,
                      borderRadius: 0,
                      width: '70%'
                    }}
                    color='secondary'
                    variant='contained'
                    onClick={handleBrowse}>
                    Browse
                  </Button>
                </>
              }
            />
            <FormHelperText
              id='standard-weight-helper-text'
              error={imageErrorMessage}
              className='form-helper-text'>
              {imageErrorMessage ? imageErrorMessage : formText.maxImageSize}
            </FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <Switch
              data-test='login-form-switch-component'
              id='scheduled'
              label='Scheduled'
              name='scheduled'
              value=''
              className='login-form-switch'
              labelPlacement='top'
              checked={isScheduled}
              onBlur={handleBlur}
              onChange={handleSchedule}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          style={{ marginLeft: '3%', height: '80px', marginTop: '1%' }}>
          <Grid item xs={4}>
            <FormikTextField
              required
              multiline
              id='announcement-desc'
              data-test='announcement-desc-input'
              label='Announcement Description'
              name='announcementDescription'
              className='user-search-textbox'
              aria-describedby='new-announcement-description-helper-text'
              helperText='Max Character Limit: 4000'
              InputProps={{ className: classes.input }}
              InputLabelProps={{ className: classes.inputFont }}
              rowsMax={5}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={4}>
            {selectedFile ? (
              <img
                src={selectedFile}
                style={{ maxWidth: '210px', maxHeight: '120px' }}
                alt='announcement banner'
              />
            ) : null}
            {selectedFile ? (
              <Typography
                variant='h6'
                className='announcements-image-remove-text'
                onClick={imageRemoved}>
                Remove
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <Grid container direction='row'>
              <Grid item xs={5}>
                <FormikDatePicker
                  autoOk
                  disablePast
                  required
                  variant='inline'
                  inputVariant='outlined'
                  label='Start Date (dd/mm/yyyy)'
                  format='dd/MM/yyyy'
                  name='announcementStartDate'
                  disabled={!isScheduled}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <CalendarIcon />
                      </InputAdornment>
                    )
                  }}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={5} className='announcement-date-margin'>
                <FormikDatePicker
                  autoOk
                  disablePast
                  required
                  variant='inline'
                  inputVariant='outlined'
                  label='End Date (dd/mm/yyyy)'
                  format='dd/MM/yyyy'
                  name='announcementEndDate'
                  disabled={!isScheduled}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <CalendarIcon />
                      </InputAdornment>
                    )
                  }}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems='flex-end'
          direction='row'
          style={{ marginLeft: '3%' }}
        />
      </Grid>
      <AnnouncementButtons
        clearForm={handleReset}
        clearResetLabel='Clear'
        addUpdateLabel='Add'
        imageRemoved={imageRemoved}
        dirty={dirty}
        valid={isValid}
        values={values}
        mode='add'
      />
    </form>
  );
});

const checkIfServerErrorExists = apiResponse => {
  return apiResponse && apiResponse.data && apiResponse.data.errorMap;
};

const formConfig = withFormik({
  mapPropsToValues: () => ({
    announcementTitle: '',
    announcementDescription: '',
    announcementStartDate: new Date(),
    announcementEndDate: new Date(),
    scheduled: false
  }),

  mapPropsToStatus: () => ({}),

  validateOnChange: true,

  validationSchema: announcementValidationSchema,

  handleSubmit: (values, formikBag) => {
    const announcement = {
      announcementDescription: values.announcementDescription,
      announcementEndDate: moment(values.announcementEndDate).format(
        'DD/MM/YYYY'
      ),
      announcementImage: formikBag.props.announcementImageURL,
      announcementStartDate: moment(values.announcementStartDate).format(
        'DD/MM/YYYY'
      ),
      announcementTitle: values.announcementTitle,
      scheduled: values.scheduled,
      imageType: formikBag.props.imageType,
      imageName: formikBag.props.selectedFileName
    };
    addAnnouncementApi(announcement).then(response => {
      if (response && response.status === 400) {
        const err = {};
        err.response = response;
        formikBag.setStatus(createStatusFromErrors(err));
      } else {
        formikBag.resetForm();
        formikBag.props.imageRemoved();
        formikBag.props.displayToast(
          response.status,
          'Announcement created successfully',
          'Error creating announcement'
        );
      }
    });
  }
});

export default formConfig(AnnouncementCriteria);
