import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CreateContract from './Create';
import EditContract from './Edit';
import ListContract from './List';
import ViewContract from './View';
import { EmptyDetailsScreen, CanActivate } from '../../../components';
import PageError from '../../PageError';

const EmptyDetailsScreenComponent = () => (
  <EmptyDetailsScreen
    content='Select Contract to View Details'
    arrowDirection='Left'
    contentVariant='h6'
  />
);

const RoutesArray = [
  {
    path: '',
    component: EmptyDetailsScreenComponent,
    accessKey: 'contract:create'
  },
  { path: 'create', component: CreateContract, accessKey: 'contract:create' },
  {
    path: 'create/:organizationId',
    component: CreateContract,
    accessKey: 'contract:create'
  },
  { path: ':orgId', component: ListContract, accessKey: 'contract:search' },
  {
    path: ':orgId/edit/:contractId',
    component: EditContract,
    accessKey: 'contract:edit'
  },
  {
    path: ':orgId/view/:contractId',
    component: ViewContract,
    accessKey: 'contract:view'
  }
];

const Routes = props => {
  const { url } = props.match;
  return (
    <>
      <Switch>
        {RoutesArray.map(route => {
          return (
            <Route
              key={route.path}
              exact
              path={`${url}/${route.path}`}
              render={props => (
                <CanActivate
                  action={route.accessKey}
                  unAuthorizedView={<PageError errorCode='401' />}>
                  <route.component {...props} />
                </CanActivate>
              )}
            />
          );
        })}
      </Switch>
    </>
  );
};

export default Routes;
