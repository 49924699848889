import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ContractNotesList from '../ContractNotesList';
import ContractNotesForm from '../ContractNotesForm';

function ContractNotes({ handleChange, values, handleBlur }) {
  return (
    <Grid
      container
      data-test='contract-notes-component'
      className='contract-notes-component'>
      <Grid item md={12} className='row-header-bar'>
        <Typography variant='h6'> Contract Notes</Typography>
      </Grid>
      <Grid item md={12} className='contract-notes-text-area-item'>
        <ContractNotesForm
          mode='edit'
          notes={values.notes}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Grid>
      <Grid item md={12}>
        <ContractNotesList contractNotes={values.contractNotes} />
      </Grid>
    </Grid>
  );
}

export default ContractNotes;
