import api from './';

const orgEndpoints = {
  getOrganizationNames: 'user/orgs',
  getOrganizationWithContracts: 'user/megamenu/orgs',
  getUserProfile: 'user/search',
  getUserLogs: 'user/logs',
  getPendingUsers: 'user/pending',
  getSingleUser: 'user/profile',
  createUser: 'user/create',
  updateUser: 'user/update',
  tempPassword: 'user/pwdtemp',
  manageAccess: 'user/org/access',
  getAllAnnouncements: 'admin/ancmnts',
  addAnnouncement: 'admin/ancmt/create',
  updateAnnouncement: 'admin/ancmnt/update',
  getSingleAnnouncement: 'admin/ancmnt/retrieve',
  impersonateUser: '/admin/impersonate',
  cancelImpersonateUser: '/admin/impersonate/cancel',
  orgParams: '/user/org/params',
  auditReport: '/audit/report/download',
  exchangeRates: '/admin/fx/rates',
  checkExchangeRates: '/admin/fx/check',
  loadExchangeRates: 'admin/fx/load'
};

export const pendingApprovalUsersApi = async () => {
  try {
    const response = await api().get(orgEndpoints.getPendingUsers);
    return response.data;
  } catch (e) {}
};

export const getSingleUserApi = async userProfileId => {
  try {
    const response = await api().get(orgEndpoints.getSingleUser, {
      params: {
        viewUserProfileId: userProfileId
      }
    });
    return response.data;
  } catch (e) {}
};

export const impersonateUserApi = async payload => {
  const response = await api().post(orgEndpoints.impersonateUser, payload);
  return response.data;
};

export const cancelImpersonateUserApi = async () => {
  const response = await api().get(orgEndpoints.cancelImpersonateUser);
  return response.data;
};

export const getUserProfileSearchApi = async payload => {
  try {
    const response = await api().post(orgEndpoints.getUserProfile, payload);
    return response.data;
  } catch (err) {}
};
export const getUserProfileLogsApi = async payload => {
  const response = await api().post(orgEndpoints.getUserLogs, payload);
  return response.data;
};

export const getAdminOrganizationNamesApi = async () => {
  try {
    const response = await api().get(orgEndpoints.getOrganizationNames);
    return response.data;
  } catch (e) {}
};

export const createUserApi = async user => {
  try {
    return await api().post(orgEndpoints.createUser, user);
  } catch (err) {
    return err.response;
  }
};

export const updateUserApi = async user => {
  try {
    return await api().put(orgEndpoints.updateUser, user);
  } catch (err) {
    return err.response;
  }
};

export const manageOrgnizationDataApi = async data => {
  try {
    return await api().put(orgEndpoints.manageAccess, data);
  } catch (e) {
    return e.response;
  }
};

export const generateTempararyPasswordApi = async user => {
  return await api().post(orgEndpoints.tempPassword, user);
};

export const addAnnouncementApi = async announcement => {
  try {
    return await api().post(orgEndpoints.addAnnouncement, announcement);
  } catch (err) {
    return err.response;
  }
};

export const getAllAnnouncementsApi = async () => {
  try {
    return await api().get(orgEndpoints.getAllAnnouncements);
  } catch (err) {
    return err;
  }
};

export const updateAnnouncementApi = async announcement => {
  let response;
  try {
    response = await api().put(orgEndpoints.updateAnnouncement, announcement);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getSingleAnnouncementApi = async announcementId => {
  try {
    const response = await api().get(orgEndpoints.getSingleAnnouncement, {
      params: {
        announcementId
      }
    });
    return response.data;
  } catch (e) {}
};

export const getMegaMenuOrganizationNamesApi = async () => {
  try {
    const response = await api().get(orgEndpoints.getOrganizationWithContracts);
    return response.data;
  } catch (e) {}
};

export const getAuditReportApi = async data => {
  return await api().get(orgEndpoints.auditReport, {
    params: {
      auditReportWantsToRun: data.report,
      auditReportingMonth: data.month,
      auditReportingYear: data.year
    }
  });
};

export const getExchangeRatesApi = async data => {
  const fromDate = new Date(new Date(data.startDate).setDate(1))
    .toISOString()
    .split('T')[0];
  const to = new Date(
    new Date(
      new Date(data.endDate).setMonth(new Date(data.endDate).getMonth())
    ).setDate(1)
  );
  return await api().get(orgEndpoints.exchangeRates, {
    params: {
      exchangeType: data.rateType,
      startDate: fromDate,
      endDate: to.toISOString().split('T')[0]
    }
  });
};

export const loadExchangeRatesApi = async data => {
  return await api().get(orgEndpoints.loadExchangeRates);
};

export const checkExchangeRatesApi = async data => {
  return await api().get(orgEndpoints.checkExchangeRates);
};

export const getOrgParamsApi = async defaultOrgName => {
  try {
    const response = await api().get(orgEndpoints.orgParams, {
      params: {
        organizationProfileId: defaultOrgName
      }
    });
    return response.data;
  } catch (e) {}
};
