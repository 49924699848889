import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.min.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './index.css';
import ReactDOM from 'react-dom/client';
import App from './App';
import store from './store';
import theme from './theme';
import NetworkError from './components/NetworkError/NetworkError';

toast.configure();

const root = ReactDOM.createRoot(document.getElementById('root'));
window.store = store;
root.render(
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <Router>
            <NetworkError />
            <App />
          </Router>
        </StylesProvider>
      </MuiThemeProvider>
    </Provider>
  </MuiPickersUtilsProvider>
);
