export const radio = {
  MuiRadio: {
    root: {
      padding: '1px 10px 1px 3px',
      '&:hover': {
        backgroundColor: 'transparent !important'
      },
      '&:selected': {
        backgroundColor: 'transparent !important'
      }
    }
  }
};
