import api from './';

const uploadEndpoints = {
  downloadTemplate: 'form/template',
  uploadFile: 'file/upload',
  downloadLicenseTemplate: '/license/template'
};

export const downloadFormTemplateApi = async (
  fileType,
  orgProfileId,
  orgReportingPeriod = {}
) => {
  try {
    const response = await api().get(uploadEndpoints.downloadTemplate, {
      params: {
        fileType,
        orgProfileId,
        month: orgReportingPeriod.reportingMonth,
        year: orgReportingPeriod.reportingYear
      }
    });
    return response.data;
  } catch (e) {}
};

export const uploadFormDataApi = async formData => {
  return await api().post(uploadEndpoints.uploadFile, formData);
};

export const downloadLicenseTemplateApi = async () => {
  try {
    const response = await api().get(uploadEndpoints.downloadLicenseTemplate, {
      params: {
        fileName: 'License_Agreement.pdf'
      }
    });
    return response.data;
  } catch (e) {}
};
