import Edit from './Edit';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getTotalOrganizationCountries,
  getTotalOrganizationRegions,
  getTotalOrganizationTypes,
  getTotalCurrencies,
  getTotalProducts,
  getOrganizationProfile,
  clearOrganizationProfile
} from '../../../../store/organizations/actions';

export const mapStateToProps = state => ({
  regions: state.organizations.totalOrganizationalRegions,
  countries: state.organizations.totalOrganizationalCountries,
  orgTypes: state.organizations.totalOrganizationalTypes,
  currencies: state.organizations.totalCurrencies,
  products: state.organizations.totalProducts,
  organization: state.organizations.orgProfile,
  userRoles: state.user.internalUserFlag,
  highestRole: state.user.highestRole
});

const mapDispatchToProps = {
  getTotalOrganizationCountries,
  getTotalOrganizationRegions,
  getTotalOrganizationTypes,
  getTotalCurrencies,
  getTotalProducts,
  getOrganizationProfile,
  clearOrganizationProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Edit));
