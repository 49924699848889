import React, { Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const ImportantDates = props => (
  <div className='margin'>
    <Grid
      container
      className='important-dates'
      direction='column'
      justify='space-between'>
      <Grid item role='region' aria-labelledby='important-date-heading'>
        <Typography
          className='date-heading'
          data-test='date-heading'
          color='primary'
          variant='h5'
          id='important-date-heading'>
          IMPORTANT DUE DATES
        </Typography>
      </Grid>
      <Grid item>
        <Grid
          container
          className='date-container'
          data-test='date-container'
          direction='column'>
          {props.dates.map((date, index) => (
            <Fragment key={index}>
              <Grid
                item
                container
                className='date-label'
                data-test='date-row'
                justify='space-between'
                alignItems='center'>
                <Typography
                  variant='body1'
                  color='secondary'
                  className='date-title'>
                  {date.name}
                </Typography>
                <Typography
                  variant='body1'
                  color='secondary'
                  className='date-body'>
                  {date.day} {date.monthName} {date.year}
                </Typography>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Grid>
    </Grid>
  </div>
);

ImportantDates.propTypes = {
  dates: PropTypes.array
};

export default ImportantDates;
