/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { ReactComponent as DownArrowIcon } from '../../../../assets/icons/down-arrow.svg';
import { sortObject } from '../../../../utils/commonFunctions';
import UsersSortContainer from './UsersSortContainer';
import TextFieldSearch from './TextField/TextFieldSearch';

class PendingUsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: this.props.users,
      sortedKey: '',
      sortedValue: '',
      searchText: ''
    };
  }

  componentDidMount() {
    const { sortedKey, users, sortedValue } = this.state;
    if (sortedKey !== '') {
      const sortedResponse = sortObject(users, sortedKey);
      this.setState({
        users: sortedResponse,
        sortedKey,
        sortedValue
      });
    }
  }

  componentDidUpdate(_, prevState) {
    const { sortedKey, users, sortedValue } = this.state;
    if (JSON.stringify(prevState.users) !== JSON.stringify(this.props.users)) {
      this.setState({
        users: this.props.users
      });
    }
    if (sortedKey !== '' && prevState.sortedKey !== sortedKey) {
      this.setState(() => {
        const sortedResponse = sortObject(users, sortedKey);
        return {
          users: sortedResponse,
          sortedKey,
          sortedValue
        };
      });
    }
  }

  handleSortBox = () => {
    const headerIconContainer = document.querySelector(
      '.list-header-icon-container'
    );
    const sortBox = document.querySelector('.users-sort-box');
    headerIconContainer.classList.toggle('active');
    sortBox.classList.toggle('active');
  };

  sortUsersListBy = (type, value) => {
    this.handleSortBox();
    this.setState(prevState => {
      const sortedResponse = sortObject(prevState.users, type);
      return {
        users: sortedResponse,
        sortedKey: type,
        sortedValue: value
      };
    });
  };

  handleOnMouseLeave = () => {
    const headerIconContainer = document.querySelector(
      '.list-header-icon-container'
    );
    if (headerIconContainer.classList.contains('active')) {
      this.handleSortBox();
    }
  };

  handleChange = e => {
    this.setState({
      searchText: e.target.value
    });
  };

  render() {
    const { selectUser, selectedUser } = this.props;
    const { users, sortedKey, sortedValue } = this.state;
    let usersApprovalList = this.props.users.filter(org => {
      return org.id !== 0;
    });
    if (this.state.searchText) {
      usersApprovalList = users.filter(user => {
        return user.userLoginId
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase());
      });
    }

    return (
      <Grid
        container
        className='pending-approval-users-list-component'
        data-test='pending-user-list-component'>
        <Grid
          item
          md={12}
          className='p-relative'
          data-test='pending-p-relative'
          onMouseLeave={this.handleOnMouseLeave}>
          <Grid
            container
            className='list-header'
            justify='space-between'
            data-test='list-Header'>
            <Grid item data-test='list-Header-item'>
              <Typography variant='h6' data-test='list-Header-typo'>
                Sort By <span className='sorted-value'>{sortedValue}</span>
              </Typography>
            </Grid>
            <Grid
              item
              className='list-header-icon-container'
              data-test='list-header-icon-container'
              onClick={this.handleSortBox}>
              <DownArrowIcon
                className='list-header-icon'
                data-test='list-header-icon'
              />
            </Grid>
          </Grid>
          <UsersSortContainer
            sortUsersListBy={this.sortUsersListBy}
            sortedKey={sortedKey}
            data-test='users-sort-container'
          />

          <TextFieldSearch
            value={this.state.searchText}
            data-test='text-field-container'
            onChange={this.handleChange}
          />
        </Grid>

        <Grid
          item
          md={12}
          className='pending-approval-user-main-container'
          data-test='pending-approval-user-main-container'>
          {usersApprovalList.map(user => (
            <Grid
              key={user.userProfileId}
              container
              justify='space-between'
              data-test='pending-approval-user-container'
              className={`pending-approval-user-container ${
                selectedUser &&
                Object.keys(selectedUser).length &&
                selectedUser.userProfileId === user.userProfileId
                  ? 'active'
                  : ''
              }`}
              onClick={() => selectUser(user.userProfileId)}>
              <Grid item data-test='pending-approval-main-container'>
                <Grid
                  container
                  data-test='pending-approval-container'
                  direction='column'
                  justify='space-between'
                  alignItems='flex-start'>
                  <Grid
                    item
                    className='pending-approval-user-id'
                    data-test='pending-approval-user-id'>
                    {user.userLoginId}
                  </Grid>
                  <Grid
                    item
                    className='pending-approval-user-name'
                    data-test='pending-approval-user-name'>
                    {user.lastName}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item data-test='pending-user-data-container'>
                <Grid
                  container
                  direction='column'
                  justify='space-between'
                  alignItems='flex-end'
                  data-test='pending-user-container'>
                  <Grid
                    item
                    className='pending-approval-user-date'
                    data-test='pending-approval-user-date'>
                    {user.createDate}
                  </Grid>
                  <Grid
                    item
                    className='pending-approval-user-email'
                    data-test='pending-approval-user-email'>
                    {user.emailId}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }
}

PendingUsersList.propTypes = {
  selectUser: PropTypes.func.isRequired,
  selectedUser: PropTypes.object,
  users: PropTypes.array
};

PendingUsersList.defaultProps = {
  users: [],
  selectedUser: {}
};

export default PendingUsersList;
