import { authActions } from './types';
import {
  loginApi,
  logoutApi,
  isValidUserProfileApi,
  isUserExistsApi,
  validateOTPApi,
  generateUserIdApi
} from '../../api/auth';

import { setUserProfile } from '../user';
import { catchError } from '../error/actions';

export const login = (token, isChallenged = false) => ({
  type: authActions.LOGIN,
  payload: {
    isUserAuthenticated: true,
    token,
    isChallenged
  }
});

export const logout = () => ({
  type: authActions.LOGOUT,
  payload: {
    isUserAuthenticated: false
  }
});

export const resetChallenge = () => ({
  type: authActions.RESET_CHALLENGE
});

export const otp = isValid => ({
  type: authActions.OTP,
  payload: {
    isUserAuthenticated: true,
    isChallenged: true,
    otpValidated: isValid
  }
});

export const loginUser = (user, loginFn = loginApi) => async dispatch => {
  try {
    const res = await loginFn(user);
    if (res) {
      dispatch(login(res.headers['x-csrf-token']));
      // C dispatch(setUserProfile(res));
      dispatch(
        setUserProfile({
          usrProfId: res.data.usrProfId,
          usrFullName: res.data.usrFullName,
          highestRole: res.data.highestRole,
          lastLoginDates: res.data.lastLoginDates
        })
      );
    }
  } catch (err) {
    dispatch(catchError(err, 'FORM'));
  }
};

export const logoutUser = () => async dispatch => {
  try {
    const res = await logoutApi();
    if (res) {
      localStorage.clear();
      dispatch(logout());
    }
  } catch (err) {}
};

export const isValidPersonalDetails = body => async dispatch => {
  try {
    await isValidUserProfileApi(body);
  } catch (err) {
    dispatch(catchError(err, 'FORM'));
  }
};

export const isUserExists = body => async dispatch => {
  try {
    const payload = await isUserExistsApi(body);
    dispatch({
      type: authActions.IS_USER_EXISTS,
      payload
    });
  } catch (err) {}
};

export const userValidateOTP = body => async dispatch => {
  try {
    await validateOTPApi(body);
  } catch (err) {
    dispatch(catchError(err, 'FORM'));
  }
};

export const generateUserId = body => async dispatch => {
  try {
    await generateUserIdApi(body);
  } catch (err) {
    dispatch(catchError(err, 'FORM'));
  }
};
