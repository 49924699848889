import { base as theme } from './base';
export const table = {
  MuiTableCell: {
    root: {
      padding: 0
    },
    head: {
      color: theme.palette.white,
      padding: '5px 15px'
    },
    body: {
      padding: '5px 10px'
    }
  }
};
