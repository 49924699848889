import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const BlockHeader = props => (
  <div data-test='myprofile-data-header' className='myprofile-data-header'>
    <Typography variant='h6'>{props.title}</Typography>
  </div>
);

BlockHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default BlockHeader;
