import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import { ADMIN_TEXT } from '../../../../constants/text';

class UserDetailsHeaderSection extends Component {
  render() {
    const { user } = this.props;
    return (
      <Grid
        container
        justify='flex-start'
        alignItems='center'
        className='user-details-screen-header'
        data-test='user-details-header-component'>
        <Grid item md={4}>
          <Grid container direction='column'>
            <Typography variant='caption'>
              {ADMIN_TEXT.lastModified} (dd/mm/yyyy)
            </Typography>
            <Typography variant='body2' className='last-modify'>
              {user.formattedLastModifiedDate}{' '}
              <span style={{ color: '#63666B' }}>by</span>{' '}
              {user.lastModifiedUserBy}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

UserDetailsHeaderSection.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserDetailsHeaderSection;
