import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import './DataUpload.css';
import DataTips from './DataTips';

import FileUpload from './FileUpload';
import { ResourceLinks } from './ResourceLinks';
import {
  downloadFormTemplate,
  uploadFormData
} from '../../store/upload/actions';
import {
  RPT_PRD_NEXT_MONTH_ERR,
  RPT_PRD_PREVIOUS_MONTH_ERR
} from '../../constants/errors';
export class DataUpload extends Component {
  fileInput = React.createRef();

  state = {
    phase: 'initial',
    fileName: '',
    fileSize: '',
    errorMessage: 'Something went wrong. Please upload file again',
    successMessage: 'Data has been saved successfully for review',
    file: null
  };

  handleFileSelect = () => {
    this.fileInput.current.click();
  };

  handleProcessing = (file, size) => {
    this.setState({
      phase: 'uploading',
      fileName: file.name,
      fileSize: size,
      file
    });
  };

  handleError = () => {
    this.setState({
      phase: 'error',
      errorMessage: 'Something went wrong. Please upload file again'
    });
  };

  handleUnsupportedFileError = () => {
    this.setState({
      phase: 'error',
      errorMessage: 'Invalid file format'
    });
  };

  handleErrorResponse = err => {
    this.setState({
      phase: 'error',
      errorMessage: err.data
        ? err.data.errorMessage
          ? err.data.errorMessage
          : err.data
        : err.data
    });
  };

  handelFileChange = e => {
    if (
      typeof e.target.files[0] !== 'undefined' &&
      e.target.files[0] !== null
    ) {
      const file = e.target.files[0];
      e.target.value = null;
      this.fileProcess(file);
    }
  };

  onDropHandler = event => {
    if (this.state.phase === 'initial' || this.state.phase === 'error') {
      event.preventDefault();
      let isUploadBtnDisable = this.props.isPreviousMonthSubmitted;
      if (this.props.forms.length > 0 && !this.props.forms[0].formEditable) {
        isUploadBtnDisable = RPT_PRD_PREVIOUS_MONTH_ERR;
      }
      if (
        isUploadBtnDisable &&
        (RPT_PRD_NEXT_MONTH_ERR === isUploadBtnDisable ||
          RPT_PRD_PREVIOUS_MONTH_ERR === isUploadBtnDisable)
      ) {
        return false;
      }
      if (event.dataTransfer.files.length !== 1) {
        this.handleError();
        return false;
      }
      this.fileProcess(event.dataTransfer.files[0]);
    } else {
      return false;
    }
  };

  onDragHandler = event => {
    event.preventDefault();
  };

  fileProcess = file => {
    if (typeof file !== 'undefined') {
      const supportedExt = ['csv', 'xlsx', 'xls', 'json'];
      const extension = file.name
        .split('.')
        .pop()
        .toLowerCase();
      const isSupported = supportedExt.includes(extension);

      if (isSupported) {
        let size = file.size / (1024 * 1024);
        size = size.toFixed(2);
        this.handleProcessing(file, size);
      }
      if (!isSupported) {
        this.handleUnsupportedFileError();
      }
    }
  };

  handelCancel = () => {
    this.setState({
      phase: 'initial',
      fileName: '',
      fileSize: '',
      file: null
    });
    this.handleError();
  };

  handelSuccess = () => {
    const { uploadFormData } = this.props;
    uploadFormData(this.state.file)
      .then(res => {
        if (res) {
          this.setState({
            phase: 'success',
            successMessage: res.data.message
          });
        }
      })
      .catch(err => {
        this.handleErrorResponse(err);
      });
  };

  render() {
    const { downloadFormTemplate, orgReportingPeriod, orgParams } = this.props;
    const tipsData = {
      title: 'FILE CREATION TIPS',
      tips: [
        'Download the template format.',
        'Enter only the relevant data value (not the currency symbol etc.) with your business.',
        'If INTES Code and/or Royalty Additions and Exclusions Descriptions are necessary for data entry, the information can be taken from related manual data entry screens.',
        'Save the data.',
        'Drag and drop the saved document or click the “Browse” button and select the saved document and click “Open”.'
      ],
      showDownload: false
    };
    const reviewData = {
      title: 'REVIEW TIPS',
      tips: [
        'Start reviewing the uploaded data by clicking “Review Data” button.',
        'Make the necessary changes by reviewing page by page.',
        'Submit the Data to General Manager by clicking the “Submit to General Manager” button. '
      ],
      showDownload: true
    };
    const { phase, fileName, fileSize } = this.state;
    return (
      <Grid container data-test='upload-section' className='upload-section'>
        <Grid item md={9} className='file-upload-container'>
          <Grid
            container
            data-test='upload-dropzone'
            className='file-upload'
            onDragOver={this.onDragHandler}
            onDragLeave={this.onDragHandler}
            onDrop={this.onDropHandler}>
            <input
              ref={this.fileInput}
              type='file'
              name='file'
              className='hide'
              data-test='file-input'
              onChange={this.handelFileChange}
            />
            <FileUpload
              phase={phase}
              fileName={fileName}
              fileSize={fileSize}
              cancelHandler={this.handelCancel}
              fileSelectHandler={this.handleFileSelect}
              successHandler={this.handelSuccess}
              errorMessage={this.state.errorMessage}
              successMessage={this.state.successMessage}
            />
          </Grid>
        </Grid>
        <Grid
          item
          data-test='tips-link-section'
          md={3}
          className='resources-tips-container'>
          <div className='resources-tips'>
            <div className='tip-container'>
              {this.state.phase !== 'success' && (
                <DataTips data-test='file-tips' {...tipsData} />
              )}
              {this.state.phase === 'success' && (
                <DataTips data-test='review-tips' {...reviewData} />
              )}
            </div>
            {this.state.phase !== 'success' && (
              <div className='resource-container'>
                <ResourceLinks
                  downloadFormTemplate={downloadFormTemplate}
                  orgParams={orgParams}
                  orgReportingPeriod={orgReportingPeriod}
                  {...tipsData}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
}

DataUpload.propTypes = {
  setIsUploadedData: PropTypes.func.isRequired,
  downloadFormTemplate: PropTypes.func.isRequired
};

export const mapStateToProps = state => ({
  isPreviousMonthSubmitted: state.errors.formErrors?.errorMessage,
  orgReportingPeriod: state.formsListNav.orgReportingPeriod,
  orgParams: state.admin.orgParams,
  forms: state.formsListNav.forms
});

export const mapDispatchToProps = {
  downloadFormTemplate,
  uploadFormData
};

export default connect(mapStateToProps, mapDispatchToProps)(DataUpload);
