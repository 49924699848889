import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const Header = () => (
  <Grid
    item
    md={12}
    className='user-search-header-container'
    data-test='user-search-header-container'>
    <Grid
      container
      justify='space-between'
      alignItems='center'
      data-test='user-search-header'>
      <Typography
        variant='h4'
        className='franchisees-header-title'
        data-test='franchisees-header-title'>
        Unlock Business Data
      </Typography>
    </Grid>
  </Grid>
);

export default Header;
