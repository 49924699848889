/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import TableInput from './TableInput';
import { range } from '../../utils/commonFunctions';
import {
  annualFormsIDs,
  quarterlyFormsIDs,
  slugToIDs,
  formsExceptionCheck
} from '../../constants/dataForms';

class GenericTable extends Component {
  render() {
    const { form, formId, activeTab } = this.props;
    const id = parseInt(formId);
    const annualIdCheck = id >= 300 && id < 318;
    return (
      (form &&
        form.sections.map((section, sectionIndex) => {
          const cols = Object.entries(section.columnHeaders);
          const nCols = cols.length;
          const noExceptions = formsExceptionCheck(
            slugToIDs[formId],
            sectionIndex
          );
          return (
            <TableContainer
              key={sectionIndex}
              data-test='org-profile-table-component'>
              <Table
                key={sectionIndex}
                aria-label='component-table'
                data-test='component-table'
                className={`data-form-table section-${sectionIndex}`}>
                <TableHead data-test='component-table-head'>
                  {quarterlyFormsIDs.includes(parseInt(formId)) ||
                  annualFormsIDs.includes(parseInt(formId)) ? (
                    sectionIndex === 0 ? (
                      <>
                        <TableRow
                          className='header-1'
                          data-test='component-table-head-row'>
                          <TableCell className='col-0' />
                          {cols.map(([colId, colName], cIndex) => {
                            return (
                              <TableCell
                                key={colId}
                                className={`col-${cIndex + 1}`}
                                data-test='component-table-head-cell'>
                                {colName}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        {section.sectionName && (
                          <TableRow
                            className='header-2'
                            data-test='component-table-head-row'>
                            <TableCell
                              className='col-0'
                              style={{ height: '1em' }}>
                              {' '}
                              {annualIdCheck ? '' : section.sectionName}{' '}
                            </TableCell>
                            <TableCell colSpan={cols.length} />
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <TableRow
                        className='header-2'
                        data-test='component-table-head-row'>
                        <TableCell className='col-0' style={{ height: '1em' }}>
                          {' '}
                          {annualIdCheck ? '' : section.sectionName}{' '}
                        </TableCell>
                        <TableCell colSpan={cols.length} />
                      </TableRow>
                    )
                  ) : (
                    <TableRow
                      className='header-1'
                      data-test='component-table-head-row'>
                      <TableCell className='col-0'>
                        {section.sectionName}
                      </TableCell>
                      {cols.map(([colId, colName], cIndex) => {
                        return (
                          <TableCell
                            key={colId}
                            className={`col-${cIndex + 1}`}
                            data-test='component-table-head-cell'>
                            {colName}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  )}
                </TableHead>

                <TableBody data-test='component-table-body'>
                  {Object.entries(section.rowHeaders).map(
                    ([rowKey, rowVal], rindex, array) => {
                      const isLastRow = rindex === array.length - 1;
                      const rowBolded = isLastRow && noExceptions;
                      return (
                        <TableRow
                          key={rowKey}
                          data-test='component-table-body-row'
                          className={`dataform-row row-${rindex}`}>
                          <TableCell
                            className={`row tab-${activeTab} row-${rindex} ${
                              rowBolded ? 'bold-row-total' : ''
                            } col-0`}
                            aria-label={`row tab-${activeTab}  row-${rindex} col-0`}>
                            <div>{rowVal}</div>
                          </TableCell>
                          {range(nCols).map(col => (
                            <TableCell
                              key={col}
                              className={`table-cell tab-${activeTab}  row-${rindex} col-${col +
                                1} ${rowBolded ? 'bold-row-total' : ''}`}>
                              <TableInput
                                index={col + rindex * nCols}
                                sectionIndex={sectionIndex}
                                colName={cols[col][1]}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          );
        })) ||
      null
    );
  }
}

GenericTable.propTypes = {};

GenericTable.defaultProps = {};

export default GenericTable;
