/* eslint-disable max-lines-per-function */
import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import FranchiseTable from './FranchiseTable';
import PropTypes from 'prop-types';
import { HOME_TEXT } from '../../constants/text';
import CircularDivComponent from './CircularDivComponent';
import { Loader } from '../../components';
class FranchiseComponent extends Component {
  render() {
    const {
      data: { filteredFranchises, stats, dueDate, allFranchises },
      onFilter
    } = this.props;
    return (
      <Grid container direction='column'>
        <Grid item>
          <Grid
            container
            style={{
              marginBottom: '20px'
            }}
            data-test='component-participant'>
            <Grid item className='flex-basis-100'>
              <Grid
                container
                direction='row'
                justify='space-between'
                alignItems='center'
                className='franchise-component-section'>
                <Grid item md={7} lg={8} role='region'>
                  <Typography
                    variant='caption'
                    className='lh-18'
                    display='block'>
                    {HOME_TEXT.franchiseDescription1}
                  </Typography>
                  <Typography variant='caption' className='lh-18'>
                    {HOME_TEXT.franchiseDescription2}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              className='franchise-component-circular-div-section flex-basis-100'>
              <Grid
                container
                direction='row'
                justify='space-between'
                className='franchise-component-circular-div-container'>
                <Grid item>
                  <CircularDivComponent
                    value={stats.notStarted ? stats.notStarted : 0}
                    totalValue={stats.total ? stats.total : 0}
                    textContent={HOME_TEXT.participantStatusNotStarted}
                    data-test='component-franchise-circular-div'
                    onClickFilter={() => onFilter('Not Started')}
                  />
                </Grid>
                <Grid item>
                  <CircularDivComponent
                    value={stats.awaitingDEAction ? stats.awaitingDEAction : 0}
                    totalValue={stats.total ? stats.total : 0}
                    textContent={HOME_TEXT.participantStatusDEAction}
                    data-test='component-franchise-circular-div'
                    onClickFilter={() => onFilter('Awaiting DE Action')}
                  />
                </Grid>
                <Grid item>
                  <CircularDivComponent
                    value={stats.awaitingGMAction ? stats.awaitingGMAction : 0}
                    totalValue={stats.total ? stats.total : 0}
                    textContent={HOME_TEXT.participantStatusDGNAction}
                    onClickFilter={() => onFilter('Awaiting GM Action')}
                  />
                </Grid>
                <Grid item>
                  <CircularDivComponent
                    value={stats.submittedtoDGN ? stats.submittedtoDGN : 0}
                    totalValue={stats.total ? stats.total : 0}
                    textContent={HOME_TEXT.participantStatusComplete}
                    onClickFilter={() => onFilter('Submitted to DGN')}
                  />
                </Grid>
                <Grid item>
                  <CircularDivComponent
                    value={stats.totalAction ? stats.totalAction : 0}
                    totalValue={stats.total ? stats.total : 0}
                    textContent={HOME_TEXT.participantStatusGMPending}
                    onClickFilter={() => onFilter('Total Action')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className='flex-basis-100 franchise-table-container'>
              {allFranchises.length > 0 ? (
                <FranchiseTable
                  franchises={filteredFranchises}
                  dueDate={dueDate}
                />
              ) : (
                <Grid
                  container
                  item
                  justify='center'
                  alignItems='center'
                  data-test='component-franchise'
                  data-testid='component-se-sales-volume-loader'>
                  <Loader data-test='component-loader' />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

FranchiseComponent.propTypes = {
  data: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired
};

export default FranchiseComponent;
