import { connect } from 'react-redux';
import AuditReport from './AuditReport';
import {
  getAdminOrganizationNames,
  getAuditReport
} from '../../../store/admin/actions';

export const mapStateToProps = state => {
  return {
    usersOrganizations: state.admin.totalAdminOrganizationalNames
  };
};

export default connect(mapStateToProps, {
  getAdminOrganizationNames,
  getAuditReport
})(AuditReport);
