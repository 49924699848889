import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import { Grid, Typography, Button, Tooltip } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#022A48',
    color: theme.palette.common.white
  },
  body: {
    color: '#022A48',
    fontSize: 14,
    '&:nth-of-type(1)': {
      color: '#2477AB'
    }
  }
}))(TableCell);

const MaterialTable = props => {
  const {
    tableHeaders,
    children,
    count,
    downloadIconTooltip,
    onDownloadIcon = null,
    onClickIconDownload,
    headerAlign
  } = props;
  return (
    <Grid
      container
      item
      style={{ padding: '15px 30px' }}
      data-test='component-MaterialTable'>
      <Grid
        container
        item
        xs={12}
        direction='row'
        data-test='component-Material-Table-container'
        style={{ paddingTop: '15px', paddingBottom: '15px' }}>
        <Grid
          item
          xs={3}
          data-test='Table-search-result'
          style={{
            display: 'flex'
          }}>
          <Typography
            variant='h5'
            data-test='Table-search-result-typo'
            style={{ color: '#1A587E' }}>
            Search Results&nbsp;
          </Typography>
          <Typography
            variant='h5'
            data-test='Table-profile-result-typo'
            style={{ color: '#FF6000' }}>
            ({count} profiles found)
          </Typography>
        </Grid>
        <Grid
          item
          data-test='Table-icon-container'
          xs={9}
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            paddingRight: '12px'
          }}>
          {onDownloadIcon && (
            <Tooltip
              placement='bottom'
              title={downloadIconTooltip}
              data-test='tooltip-export-button'>
              <Button
                color='primary'
                type='submit'
                size='small'
                variant='contained'
                aria-label='submit'
                data-test='export-button'
                onClick={onClickIconDownload}>
                <GetAppIcon data-test='export-icon' />
              </Button>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Grid container item xs={12} data-test='Table-body-container'>
        <TableContainer
          data-test='Table-container'
          style={{ border: 'solid #d9e7ef 1px', borderRadius: '6px' }}>
          <Table aria-label='table' data-test='Table'>
            <TableHead data-test='Table-container-head'>
              <TableRow data-test='Table-container-row'>
                {tableHeaders.map(columnName => (
                  <StyledTableCell
                    key={columnName}
                    data-test='Table-StyledTableCell-row'
                    align={headerAlign ? headerAlign : 'left'}>
                    {columnName}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody data-test='Table-child-body'>
              {children && children.length > 0 ? (
                children
              ) : (
                <TableRow key={0}>
                  <StyledTableCell
                    style={{
                      textAlign: 'center',
                      color: '#dbdbdb',
                      fontSize: '15pt'
                    }}
                    className='search-bprofile-result-empty-row'
                    colSpan={tableHeaders.length}>
                    No Results Found
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default MaterialTable;

MaterialTable.propTypes = {
  tableHeaders: PropTypes.array.isRequired
};
