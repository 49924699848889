/* eslint-disable max-lines-per-function */
import React, { Component } from 'react';
import { Typography, Grid, Chip, InputAdornment, Box } from '@material-ui/core';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';

import {
  MultipleCombobox,
  DatePicker as FormikDatePicker
} from '../../../../components/material';
import { TextField } from '../../../../components/formik';

export class ContractInfo extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.initialValues.effectiveStartDate.toString() ===
        this.props.values.effectiveStartDate.toString() &&
      this.props.initialValues.effectiveEndDate.toString() ===
        this.props.values.effectiveEndDate.toString()
    ) {
      this.props.values.notesEffectivePeriod = '';
    }
    if (
      JSON.stringify(this.props.values.organizationProfileList) ===
      JSON.stringify(this.props.initialValues.organizationProfileList)
    ) {
      this.props.values.notesOrganizationProfile = '';
    }
  }

  render() {
    const {
      childOrganizations,
      handleChange,
      values,
      onChildOrgSelectAll,
      onChildOrgUnSelectAll,
      deleteChildOrganizationProfile,
      handleBlur,
      errors,
      status,
      initialValues
    } = this.props;
    const childOrgError =
      Boolean(
        errors.organizationProfileId ||
          status.organizationProfileId ||
          status.root
      ) || false;
    const childOrgMessage =
      errors.organizationProfileId || status.organizationProfileId || '';
    return (
      <Grid container data-test='component-ContractInfo'>
        <Grid
          item
          md={12}
          className='row-header-bar'
          data-test='row-header-bar'>
          <Typography data-test='row-header-bar-text' variant='h6'>
            Contract Info
          </Typography>
        </Grid>
        <Grid item md={12} data-test='row-Contract-ID'>
          <Grid container className='row' data-test='row-Contract-ID-bar'>
            <Grid
              item
              md={4}
              className='p-15'
              data-test='row-Contract-ID-bar-grid'>
              <Typography variant='body2' data-test='row-Contract-ID-bar-text'>
                Contract ID
              </Typography>
              <Typography
                variant='h6'
                className='dci-blue'
                data-test='row-Contract-ID-bar-data'>
                {values.contractId}
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              className='p-15'
              data-test='row-Organization-Name-bar'>
              <Typography
                variant='body2'
                data-test='row-Organization-Name-bar-text'>
                Organization Name
              </Typography>
              <Typography
                variant='h6'
                className='dci-blue'
                data-test='row-Organization-Name-value'>
                {values.organizationName}
              </Typography>
            </Grid>
            <Grid item md={4} className='body-item-column'>
              <Typography variant='body2'>Contract Type</Typography>
              <Typography variant='h6' className='dci-blue'>
                {values.contractType}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} data-test='row-date-picker-bar'>
          <Grid
            container
            direction='row'
            justify='space-around'
            data-test='row-date-picker-grid'>
            <Grid
              item
              md={4}
              className='p-15'
              data-test='row-date-picker-Name-'>
              <FormikDatePicker
                autoOk
                required
                variant='inline'
                inputVariant='outlined'
                label='Effective Start Date (dd/mm/yyyy)'
                format='dd/MM/yyyy'
                name='effectiveStartDate'
                data-test='row-date-picker-function'
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <CalendarIcon />
                    </InputAdornment>
                  )
                }}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item md={4} className='p-15' data-test='row-date-picker-grid'>
              <FormikDatePicker
                autoOk
                required
                variant='inline'
                inputVariant='outlined'
                label='Effective End Date (dd/mm/yyyy)'
                format='dd/MM/yyyy'
                name='effectiveEndDate'
                data-test='row-date-picker-function'
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <CalendarIcon />
                    </InputAdornment>
                  )
                }}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item md={4} className='p-15' data-test='row-text-bar'>
              <TextField
                fullWidth
                className='bg-white'
                label='Enter Notes'
                data-test='row-text-field-bar'
                disabled={
                  initialValues.effectiveStartDate.toString() ==
                    values.effectiveStartDate.toString() &&
                  initialValues.effectiveEndDate.toString() ==
                    values.effectiveEndDate.toString()
                }
                name='notesEffectivePeriod'
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} data-test='row-org-bar'>
          <Grid container className='row' data-test='row-org-container'>
            <Grid
              item
              md={7}
              className='body-item-column'
              data-test='row-org-column'>
              <Grid containe data-test='row-org-container'>
                <Grid item md={12} data-test='row-org-bar-inner'>
                  <MultipleCombobox
                    required
                    name='organizationProfileId'
                    id='organizationProfileId'
                    error={childOrgError}
                    helperText={childOrgMessage}
                    label='Select Organization Profile'
                    options={childOrganizations.filter(
                      org =>
                        !Object.keys(values.organizationProfileList).includes(
                          org.id
                        )
                    )}
                    value={[]}
                    data-test='row-multi-combo'
                    onChange={handleChange}
                    onSelectAll={onChildOrgSelectAll}
                    onUnselectAll={onChildOrgUnSelectAll}
                  />
                </Grid>
                <Grid item md={12} data-test='row-chip-container'>
                  {values.organizationProfileList &&
                    Object.entries(values.organizationProfileList).map(
                      ([k, v]) => (
                        <Box
                          key={k}
                          className='child-org-child-container'
                          data-test='child-org-child-container'>
                          <Chip
                            data-test='child-org-chip'
                            label={v}
                            onDelete={() => deleteChildOrganizationProfile(k)}
                          />
                        </Box>
                      )
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={1} data-test='row-blank-bar' />
            <Grid item md={4} className='body-item-column'>
              <TextField
                multiline
                fullWidth
                rows={8}
                disabled={
                  JSON.stringify(values.organizationProfileList) ==
                  JSON.stringify(initialValues.organizationProfileList)
                }
                className='bg-white'
                label='Enter Notes'
                name='notesOrganizationProfile'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default ContractInfo;
