import * as Yup from 'yup';
import rules from '../fieldRules';

const { password, conformPassword } = rules;

export const updatePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required(
    'Please enter a value for Current Password'
  ),
  password,
  conformPassword
});
