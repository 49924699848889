import { authActions } from './types';

export const initialState = {
  isUserAuthenticated: false,
  isLoggedOut: false,
  isChallenged: false,
  otpValidated: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.LOGIN:
      return action.payload;

    case authActions.RESET_CHALLENGE:
      return { ...state, isChallenged: false };

    case authActions.OTP:
      return {
        ...state,
        isUserAuthenticated: action.payload.isUserAuthenticated,
        isChallenged: action.payload.isChallenged,
        otpValidated: action.payload.otpValidated
      };

    case authActions.LOGOUT:
      return { isUserAuthenticated: false, isLoggedOut: true };

    default:
      return state;
  }
};

export default authReducer;
