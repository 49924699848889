import React from 'react';
import CircularIcon from './CircularIcon';
import icon from '../../assets/icons/success-icon.svg';

const SuccessIcon = () => (
  <CircularIcon
    icon={icon}
    outerCircle='#f3fafc'
    innerCircle='#e2eef9'
    iconContainer='#fff'
    iconContainerBorder='#68c172'
  />
);

export default SuccessIcon;
