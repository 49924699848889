import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const TableInput = ({
  initialValue,
  name,
  regexProp,
  row,
  error,
  column,
  ariaLabel,
  updateFunction
}) => {
  const [cellValue, setInput] = useState(initialValue || '');

  useEffect(() => {
    if (typeof updateFunction === 'function') {
      updateFunction(cellValue, row, column);
    }
  });

  const handleChange = e => {
    const currentValue = e.currentTarget.value;
    if (regexProp.test(currentValue)) {
      setInput(currentValue);
    }
  };

  return (
    <input
      name={name}
      value={cellValue}
      data-test='component-table-input'
      aria-label={ariaLabel}
      onChange={handleChange}
    />
  );
};

TableInput.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  regexProp: PropTypes.object.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  row: PropTypes.any.isRequired,
  column: PropTypes.any.isRequired,
  updateFunction: PropTypes.func
};

TableInput.defaultProps = {
  initialValue: '',
  updateFunction: () => {}
};

export default TableInput;
