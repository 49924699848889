import React from 'react';
import { Grid } from '@material-ui/core';
import './Loader.css';

function Loader() {
  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      className='loader-wrapper'>
      <Grid item>
        <Grid
          container
          justify='center'
          alignItems='center'
          className='loader-div'
          data-test='component-loader'
          direction='column'>
          <Grid item className='saving'>
            <span>.</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </Grid>
          <Grid item>Please wait...</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Loader;
