import api from './';

const organizationsEndPoints = {
  searchorganizations: 'org/search',
  getOrganizationTypes: 'org/types',
  getOrganizationRegions: 'org/regions',
  getOrganizationsCountries: 'org/countries',
  getCurrencies: 'org/currencies',
  getProducts: 'org/products',
  getAllProducts: 'org/products/all',
  create: 'org/create',
  update: 'update/org',
  getOrgProfile: 'org/profile',
  addOrgProfs: 'add/orgprofs',
  deleteOrgProfs: 'delete/orgprofs',
  updateOrgProfs: 'update/orgprofs',
  orgProfileExists: 'orgprofilename/exists',
  unlockOrgEndPoint: 'org/unlock/formdata',
  extensionRequestsFetchAll: '/org/extension/requests',
  extensionRequestCreate: '/org/extention/create',
  extensionRequestUpdate: '/org/extention/update'
};

export const searchOrganizationsApi = async body => {
  const res = await api().post(
    organizationsEndPoints.searchorganizations,
    body
  );
  return res.data;
};

export const getTotalOrganizationTypesApi = async () => {
  try {
    const response = await api().get(
      organizationsEndPoints.getOrganizationTypes
    );
    return response.data;
  } catch (e) {}
};
export const getTotalOrganizationRegionsApi = async () => {
  try {
    const response = await api().get(
      organizationsEndPoints.getOrganizationRegions
    );
    return response.data;
  } catch (e) {}
};
export const getTotalOrganizationCountriesApi = async () => {
  try {
    const response = await api().get(
      organizationsEndPoints.getOrganizationsCountries
    );
    return response.data;
  } catch (e) {}
};

export const getTotalCurrenciesApi = async () => {
  try {
    const response = await api().get(organizationsEndPoints.getCurrencies);
    return response.data;
  } catch (e) {}
};

export const getProductsApi = async cardBusinessTypeCode => {
  try {
    const response = await api().get(organizationsEndPoints.getProducts, {
      params: {
        cardBusinessTypeCode
      }
    });
    return response.data;
  } catch (e) {}
};
export const getAllProductsApi = async () => {
  try {
    const response = await api().get(organizationsEndPoints.getAllProducts);
    return response.data;
  } catch (e) {}
};
export const addProductLineApi = async body => {
  try {
    return await api().post(organizationsEndPoints.getProducts, body);
  } catch (e) {
    return e.response;
  }
};

export const createOrganizationApi = async body => {
  try {
    return await api().post(organizationsEndPoints.create, body);
  } catch (e) {
    return e.response;
  }
};

export const getOrganizationProfileApi = async organizationProfileId => {
  const response = await api().get(organizationsEndPoints.getOrgProfile, {
    params: { organizationProfileId }
  });
  return response.data;
};

export const updateOrgProfileApi = async data => {
  try {
    return await api().put(organizationsEndPoints.updateOrgProfs, data);
  } catch (err) {
    return err.response;
  }
};
export const deleteOrgProfileApi = async body => {
  try {
    return await api().post(organizationsEndPoints.deleteOrgProfs, body);
  } catch (err) {
    return err.response;
  }
};
export const addOrgProfileApi = async data => {
  try {
    return await api().put(organizationsEndPoints.addOrgProfs, data);
  } catch (err) {
    return err.response;
  }
};

export const updateOrganizationApi = async data => {
  return await api().put(organizationsEndPoints.update, data);
};

export const isOrganizationProfileExistsApi = async data => {
  return await api().post(organizationsEndPoints.orgProfileExists, data);
};

export const unlockOrganizationApi = async parameter => {
  try {
    return await api().post(organizationsEndPoints.unlockOrgEndPoint, null, {
      params: {
        month: parameter.month,
        orgProfileId: parameter.orgProfileId,
        year: parameter.year
      }
    });
  } catch (e) {
    return e.response;
  }
};
export const extensionRequestsFetchAllApi = async () => {
  try {
    const response = await api().get(
      organizationsEndPoints.extensionRequestsFetchAll
    );
    return response.data;
  } catch (e) {
    return e.response;
  }
};

export const extensionRequestCreateApi = async data => {
  try {
    return await api().post(
      organizationsEndPoints.extensionRequestCreate,
      data
    );
  } catch (e) {
    return e.response;
  }
};
export const extensionRequestUpdateApi = async data => {
  try {
    return await api().post(
      organizationsEndPoints.extensionRequestUpdate,
      data
    );
  } catch (e) {
    return e.response;
  }
};
