import React, { Component } from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';

import UserPersonalDataSection from './UserPersonalDataSection';
import CreateUserHeader from './CreateUserHeader';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { createUser } from '../../../../store/admin/actions';
import UserCreateConfirmModal from '../../../../components/UserCreateConfirmModal/UserCreateConfirmModal';

import { userDetailsSchema } from '../../../../constants/validations/admin/user';
import { createStatusFromErrors } from '../../../../utils/commonFunctions';

export class FormContainer extends Component {
  state = {
    rejectModalIsOpen: false
  };

  openModal = () => {
    this.setState({ rejectModalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ rejectModalIsOpen: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.values.isSuccess === true) {
      this.openModal();
      this.props.resetForm();
    }
  }

  preFormSubmit = e => {
    e.preventDefault();
    this.props.handleSubmit();
  };

  formSubmit = () => {
    this.closeModal();
  };

  handleBlur = e => {
    const { handleBlur } = this.props;
    handleBlur(e);
  };

  render() {
    const {
      errors,
      touched,
      values,
      status,
      handleChange,
      handleReset,
      orgProfilesLength,
      isValid,
      dirty
    } = this.props;

    return (
      <form
        noValidate
        aria-label='my profile form'
        data-test='form-container-component'
        style={{ width: '100%' }}
        onReset={handleReset}
        onSubmit={e => this.preFormSubmit(e)}>
        <Grid item md={12} className='create-user-header-item'>
          <CreateUserHeader
            orgProfilesLength={orgProfilesLength}
            isValid={isValid}
            dirty={dirty}
          />
        </Grid>
        <Grid item md={12} className='create-user-body-item user-personal-data'>
          <UserPersonalDataSection
            handleChange={handleChange}
            values={values}
            errors={errors}
            touched={touched}
            status={status}
            handleBlur={this.handleBlur}
          />
        </Grid>
        <UserCreateConfirmModal
          isOpen={this.state.rejectModalIsOpen}
          userLoginId={localStorage.getItem('userLoginId')}
          email={localStorage.getItem('emailId')}
          onConfirm={this.formSubmit}
          onCancel={this.closeModal}
        />
      </form>
    );
  }
}

const formConfig = withFormik({
  mapPropsToValues: ({ form }) => ({
    internalUserFlag: 'false',
    salutation: form ? form.salutation : 'none',
    firstName: form ? form.firstName : '',
    middleName: form ? form.middleName : '',
    lastName: form ? form.lastname : '',
    jobTitle: form ? form.jobTitle : '',
    emailId: form ? form.emailId : '',
    active: form ? form.accountStatus : ['active'],
    isSuccess: false
  }),
  mapPropsToStatus: () => ({}),
  isInitialValid: false,
  validationSchema: userDetailsSchema,
  handleSubmit: async (values, formikBag) => {
    const user = { ...values };
    const UserDetails = Object.fromEntries(
      Object.entries(user).map(([k, v]) =>
        k === 'salutation' ? ['prefix', v] : [k, v]
      )
    );
    UserDetails.accountStatus = values.active.length ? 'A' : 'I';
    delete UserDetails.active;
    const res = await formikBag.props.createUser(UserDetails);
    if (res.status === 200) {
      localStorage.setItem('userLoginId', res.data.userLoginId);
      localStorage.setItem('emailId', res.data.emailId);
      values.isSuccess = true;
    } else {
      const err = {};
      err.response = res;
      values.isSuccess = false;
      formikBag.setStatus(createStatusFromErrors(err));
    }
  }
});

FormContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  orgProfilesLength: PropTypes.number.isRequired,
  isSuccess: PropTypes.bool
};

const mapDispatchToProps = {
  createUser
};

export const FormContainerWithFormik = formConfig(FormContainer);

export default connect(null, mapDispatchToProps)(FormContainerWithFormik);
