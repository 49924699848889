/* eslint-disable max-lines-per-function */
import React, { Component } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import './MegaMenuContainer.css';
import { MegaMenu } from '../';
import { ReactComponent as DownArrow } from '../../assets/icons/down-arrow.svg';
import { HOME_TEXT, COMMON_TEXT } from '../../constants/text';

export class MegaMenuContainer extends Component {
  constructor(props) {
    super(props);
    const storedProfileTitle = localStorage.getItem('profileTitle');
    this.state = {
      profileTitle: storedProfileTitle ? JSON.parse(storedProfileTitle) : ''
    };
  }

  handleClick = (e, menu) => {
    const { location, history } = this.props;
    const isManageDataPath = /\/manage-data\//.test(location.pathname);
    if (isManageDataPath && menu !== 'Administration') {
      this.props.setManageDataActive();
      history.push('/home');
    } else {
      this.props.openMegaMenu(menu, location.pathname);
      e.stopPropagation();
    }
  };

  homeHandleClick = e => {
    e.stopPropagation();
    this.props.setMenuInactive();
    this.props.history.push('/home');
  };

  componentDidMount() {
    this.handlePathActivation();
    this.updateProfileTitle();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedOrgReportedPeriod !==
      this.props.selectedOrgReportedPeriod
    ) {
      this.updateProfileTitle();
    }
  }

  handlePathActivation = () => {
    const {
      location: { pathname },
      setAdminMenuActive,
      setDataMenuActive,
      setSettingsMenuActive
    } = this.props;
    if (pathname.includes(COMMON_TEXT.manageData)) {
      setDataMenuActive();
    }
    if (
      pathname.includes(COMMON_TEXT.admin) ||
      pathname.includes(COMMON_TEXT.profile)
    ) {
      setAdminMenuActive();
    }
    if (pathname.includes(COMMON_TEXT.settings)) {
      setSettingsMenuActive();
    }
  };

  updateProfileTitle = () => {
    const { selectedOrgReportedPeriod } = this.props;
    if (!selectedOrgReportedPeriod.selectedOrganization) {
      return;
    }

    let profileTitle = selectedOrgReportedPeriod.selectedOrganization;
    if (
      selectedOrgReportedPeriod.month ||
      selectedOrgReportedPeriod.reportingMonth
    ) {
      const monthIndex =
        selectedOrgReportedPeriod.month ||
        selectedOrgReportedPeriod.reportingMonth;
      profileTitle += ` : ${HOME_TEXT.monthsList[monthIndex - 1]}`;
    }
    if (
      selectedOrgReportedPeriod.year ||
      selectedOrgReportedPeriod.reportingYear
    ) {
      profileTitle += ` ${selectedOrgReportedPeriod.year ||
        selectedOrgReportedPeriod.reportingYear}`;
    }

    if (profileTitle) {
      localStorage.setItem('profileTitle', JSON.stringify(profileTitle));
      this.setState({ profileTitle });
    }
  };

  render() {
    const {
      selectedMegaMenu,
      isDataMenuActive,
      isAdminMenuActive
    } = this.props;
    const { profileTitle } = this.state;
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <Grid
          container
          className='megamenu-container'
          data-test='component-mega-menu-container'>
          <Grid item className='megamenu' md={10}>
            <Grid
              container
              alignItems='center'
              style={{ height: '100%' }}
              role='navigation'>
              <Button
                className='megamenu-item'
                data-test='home-button'
                onClick={this.homeHandleClick}>
                Home
              </Button>
              <Button
                className={`megamenu-item ${
                  selectedMegaMenu === HOME_TEXT.manageData ? 'selected' : ''
                } ${isDataMenuActive ? 'menu-active' : ''}`}
                data-test={
                  selectedMegaMenu === HOME_TEXT.manageData
                    ? 'manage-data-button-selected'
                    : 'manage-data-button'
                }
                onClick={e => this.handleClick(e, HOME_TEXT.manageData)}>
                Manage Data{' '}
                <DownArrow className='megamenu-container-down-arrow' />
              </Button>
              <Button
                className={`megamenu-item ${
                  isAdminMenuActive ? 'menu-active' : ''
                } ${
                  selectedMegaMenu === HOME_TEXT.administration
                    ? 'selected'
                    : ''
                }`}
                data-test={
                  selectedMegaMenu === HOME_TEXT.administration
                    ? 'administration-button-selected'
                    : 'administration-button'
                }
                onClick={e => this.handleClick(e, HOME_TEXT.administration)}>
                Administration{' '}
                <DownArrow className='megamenu-container-down-arrow' />
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            data-test='business-profile-section'
            role='region'
            aria-label={profileTitle}
            className='business-profile-section'
            md={2}>
            <Grid container>
              <Grid item md={12}>
                <div className='business-proile-section-content'>
                  <Typography className='profile-txt'>
                    {profileTitle}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MegaMenu header />
      </div>
    );
  }
}

MegaMenuContainer.propTypes = {
  openMegaMenu: PropTypes.func.isRequired,
  selectedMegaMenu: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  isDataMenuActive: PropTypes.bool.isRequired,
  isAdminMenuActive: PropTypes.bool.isRequired,
  isSettingsMenuActive: PropTypes.bool.isRequired,
  selectedOrgReportedPeriod: PropTypes.object.isRequired
};

export default MegaMenuContainer;
