import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

function UsersSortContainer({ sortUsersListBy, sortedKey }) {
  return (
    <Box className='users-sort-box' data-test='user-sort-component'>
      <Grid container>
        <Grid item md={12}>
          <Typography
            variant='body2'
            color='secondary'
            className={`sort-item ${sortedKey === 'emailId' && ' active'}`}
            onClick={() => sortUsersListBy('emailId', 'Email')}>
            Email
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography
            variant='body2'
            color='secondary'
            className={`sort-item ${sortedKey === 'createDate' && ' active'}`}
            onClick={() => sortUsersListBy('createDate', 'Created Date')}>
            Created Date
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography
            variant='body2'
            color='secondary'
            className={`sort-item ${sortedKey === 'lastName' && ' active'}`}
            onClick={() => sortUsersListBy('lastName', 'Last Name')}>
            Last Name
          </Typography>
        </Grid>

        <Grid item md={12}>
          <Typography
            variant='body2'
            color='secondary'
            className={`sort-item ${sortedKey === 'userLoginId' && ' active'}`}
            onClick={() => sortUsersListBy('userLoginId', 'User Id')}>
            User Id
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default UsersSortContainer;
