import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import Modal from '../../../components/GenericModal/GenericModal';
import { Checkbox } from '../../../components/material';
import './TermsAndConditions.css';
import { logoutUser } from '../../../store/auth';
import { useHistory } from 'react-router-dom';
import { acceptTermsAndConditionsApi } from '../../../api/auth';
import { updatedTerms } from '../../../store/user';
import { toastFunc } from '../../../components/ToastComponent/toastFunction';
import urlLinks from '../../../constants/urlLinks';
import { downloadLicenseTemplate } from '../../../store/upload/actions';

const TermsAndConditions = props => {
  const { logoutUser, user, updatedTerms } = props;
  const history = useHistory();
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [licenseAgreement, setLicenseAgreement] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const privacyPolicyLink = urlLinks.privacyPolicy;
  const termsOfUseLink = urlLinks.termsOfUse;

  useEffect(() => {
    if (privacyPolicy && termsOfUse && licenseAgreement) {
      setConfirmDisabled(false);
    } else {
      setConfirmDisabled(true);
    }
  }, [privacyPolicy, termsOfUse, licenseAgreement]);

  const handleCheckboxChange = e => {
    const selectedCheck = e.target.value;
    if (selectedCheck === 'Privacy Policy') {
      setPrivacyPolicy(!privacyPolicy);
    } else if (selectedCheck === 'Terms of Use') {
      setTermsOfUse(!termsOfUse);
    } else {
      setLicenseAgreement(!licenseAgreement);
    }
  };

  const handleConfirmButton = () => {
    const body = {
      userProfileId: user.usrProfId.toString(),
      consentFlag: 'T'
    };
    acceptTermsAndConditionsApi(body).then(response => {
      if (response.status === 200) {
        const updatedUser = {
          ...user,
          consentFlag: true
        };
        updatedTerms(updatedUser);
        history.push('/home');
      } else {
        toastFunc({
          content: 'Someting went wrong',
          config: { className: 'toast-container reject' }
        });
        logoutUser();
      }
    });
  };

  const handleLicenseAgreement = () => {
    downloadLicenseTemplate();
  };

  return (
    <Modal
      className='terms-conditions-modal'
      isOpen={true}
      submitBtnLabel='Confirm'
      data-test='component-terms-and-conditions'
      onSubmit={false}
      onCancel={false}
      onClose={false}>
      <Grid
        container
        className='terms-conditions-body'
        data-test='component-body-terms-and-conditions'>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className='terms-conditions-header'>
              <Typography
                color='primary'
                variant='h4'
                className='terms-conditions-header-text'>
                Terms of Service and Privacy Policy Notice
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                color='secondary'
                className='terms-conditions-subheader'>
                After carefully reading and agreeing to the terms and conditions
                given below, select 'I Agree'. (Please click on the provided
                links to review the documents)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className='terms-conditions-checkbox-container'>
            <Grid item xs={12}>
              <Checkbox
                checked={privacyPolicy}
                className='terms-conditions-checkbox'
                data-test='privacy-policy-checkbox'
                onChange={handleCheckboxChange}
                label={
                  <Typography color='secondary'>
                    Agree to &nbsp;
                    <a
                      href={privacyPolicyLink}
                      target='_blank'
                      className='privacy-policy-link'>
                      Privacy Policy
                    </a>
                  </Typography>
                }
                value='Privacy Policy'></Checkbox>
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                checked={termsOfUse}
                className='terms-conditions-checkbox'
                data-test='terms-of-use-checkbox'
                onChange={handleCheckboxChange}
                label={
                  <Typography color='secondary'>
                    Agree to &nbsp;
                    <a
                      href={termsOfUseLink}
                      target='_blank'
                      className='terms-of-use-link'>
                      Terms of Use
                    </a>
                  </Typography>
                }
                value='Terms of Use'></Checkbox>
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                checked={licenseAgreement}
                className='terms-conditions-checkbox'
                data-test='license-agreement-checkbox'
                onChange={handleCheckboxChange}
                label={
                  <Typography color='secondary'>
                    Agree to &nbsp;
                    <a
                      href='#'
                      data-test='license-agreement-link'
                      onClick={handleLicenseAgreement}
                      className='license-agreement-link'>
                      License Agreement
                    </a>
                  </Typography>
                }
                value='License Agreement'></Checkbox>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className='terms-conditions-button-section'>
          <Grid container>
            <Grid item xs={6} className='terms-conditions-buttons'>
              <Button
                onClick={logoutUser}
                color='secondary'
                variant='outlined'
                className='terms-conditions-cancel-button'>
                I do not Agree
              </Button>
            </Grid>
            <Grid item xs={6} className='terms-conditions-buttons'>
              <Button
                data-test='terms-conditions-confirm-button'
                disabled={confirmDisabled}
                onClick={handleConfirmButton}
                color='primary'
                variant='contained'
                className='terms-conditions-confirm-button'>
                I Agree
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

const mapDispatchToProps = {
  logoutUser,
  updatedTerms
};

export default connect(null, mapDispatchToProps)(TermsAndConditions);
