import React, { Fragment } from 'react';
import FormRow from './FormRow';
import './Intes.css';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody
} from '@material-ui/core';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import IntesTableInput from './IntesTableInput';
import { Modal } from '../../../components';
import { numberCommaFormat } from '../../../utils/commonFunctions';

class IntesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intesDeleteModalOpen: false,
      idx: ''
    };
  }

  deleteFormRowHandler = idx => {
    let form = this.props.form;
    form = {
      ...form,
      fieldDetails: [
        {
          ...this.props.fieldDetails[idx],
          operationCode: 'D'
        }
      ]
    };
    this.props.deleteIntesFormRow(idx, form);
  };

  toggleIntesDeleteModal = (index = '') => {
    this.setState(prevState => ({
      ...prevState,
      intesDeleteModalOpen: !prevState.intesDeleteModalOpen,
      idx: index
    }));
  };

  render() {
    const {
      handleChange,
      handleSubmit,
      fieldDetails,
      resetForm,
      columnHeaders,
      getIntesData,
      intesDropdownData,
      form,
      total
    } = this.props;
    const { intesDeleteModalOpen, idx } = this.state;
    if (!columnHeaders) {
      return 'Loading...';
    }

    return (
      <Fragment>
        <Modal
          data-test='intes-delete-modal'
          isOpen={intesDeleteModalOpen}
          submitBtnLabel='OK'
          onSubmit={() => {
            this.deleteFormRowHandler(idx);
            this.toggleIntesDeleteModal();
          }}
          onClose={this.toggleIntesDeleteModal}
          onCancel={this.toggleIntesDeleteModal}>
          <>
            {' '}
            Only Delete if contract with the selected business is terminated.
            Are you sure?{' '}
          </>
        </Modal>
        <TableContainer className='intes-table'>
          <Table>
            <TableHead>
              <TableRow className='intes-table-header'>
                {Object.entries(columnHeaders).map(([k, v]) =>
                  !form.formEditable && v === 'Actions' ? null : (
                    <TableCell
                      key={k}
                      className={
                        !form.formEditable && v === 'New Year to Date Total'
                          ? 'intes-tablehead intes-readonly'
                          : 'intes-tablehead'
                      }
                      style={{
                        width: `${100 / Object.entries(columnHeaders).length}%`
                      }}>
                      {v}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {form.formEditable ? (
                <FormRow
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  handleClear={resetForm}
                  getIntesData={getIntesData}
                  intesDropdownData={intesDropdownData}
                />
              ) : null}
              {fieldDetails && fieldDetails.length > 0 ? (
                <>
                  {fieldDetails.map((data, index) => {
                    return (
                      <TableRow key={data.intlEstabDtlId}>
                        <TableCell>
                          <Typography
                            className='intes-table-form-fields'
                            variant='body1'>
                            <span className='code-Highlight'>
                              {data.intlEstabCde}
                            </span>{' '}
                            - {data.intlEstabNm}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IntesTableInput
                            fullWidth
                            field={data}
                            index={index}
                            name='totMrchCnt'
                            total={total}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography
                            className='intes-table-form-fields'
                            variant='body1'>
                            {numberCommaFormat(data.ytdSalesVol)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IntesTableInput
                            fullWidth
                            field={data}
                            index={index}
                            name='currMthlySalesVolAmt'
                            total={total}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography
                            className='intes-table-form-fields'
                            variant='body1'>
                            {numberCommaFormat(data.newYtdTotal)}
                          </Typography>
                        </TableCell>
                        {form.formEditable ? (
                          <TableCell align='center'>
                            <Typography
                              className='intes-table-form-delete-button'
                              variant='body1'>
                              <DeleteIcon
                                data-test='intes-delete-icon'
                                onClick={() =>
                                  this.toggleIntesDeleteModal(index)
                                }
                              />
                            </Typography>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
                  {total && (
                    <TableRow className='intes-table-form-total-row'>
                      <TableCell>
                        <Typography
                          className='intes-table-form-total'
                          variant='body1'>
                          Total
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          className={
                            form.formEditable === true
                              ? 'intes-table-form-total total-centered'
                              : 'intes-table-form-total'
                          }
                          variant='body1'>
                          {numberCommaFormat(total.totMrchCnt)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          className='intes-table-form-total'
                          variant='body1'>
                          {numberCommaFormat(total.ytdSalesVol)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          className={
                            form.formEditable === true
                              ? 'intes-table-form-total total-centered'
                              : 'intes-table-form-total'
                          }
                          variant='body1'>
                          {numberCommaFormat(total.currMthlySalesVolAmt)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          className='intes-table-form-total'
                          variant='body1'>
                          {numberCommaFormat(total.newYtdTotal)}
                        </Typography>
                      </TableCell>
                      {form.formEditable === true ? <TableCell /> : null}
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan='6' style={{ textAlign: 'center' }}>
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
}
const formConfig = withFormik({
  mapPropsToValues: () => ({
    intesCode: '',
    merchantLocations: '',
    currentSalesVolume: ''
  }),
  validationSchema: Yup.object().shape({
    intesCode: Yup.object().required(),
    merchantLocations: Yup.string().required(),
    currentSalesVolume: Yup.string().required()
  }),
  mapPropsToStatus: () => ({}),
  handleSubmit: (values, formikBag) => {
    const body = {
      ...formikBag.props.form,
      fieldDetails: [
        {
          intlEstabCde: values.intesCode.value,
          intlEstabNm: values.intesCode.label,
          totMrchCnt: values.merchantLocations,
          currMthlySalesVolAmt: values.currentSalesVolume,
          operationCode: 'A'
        }
      ]
    };
    formikBag.props.updateIntesForm(body);
    formikBag.resetForm();
  }
});
export { IntesTable };
export default formConfig(IntesTable);
