import React, { Component } from 'react';
import PropTypes from 'prop-types';
import successIcon from '../../assets/icons/success-icon.svg';
import errorIcon from '../../assets/icons/icon-error.svg';
import './ToastComponent.css';

class ToastComponent extends Component {
  static propTypes = {
    content: PropTypes.string.isRequired,
    toastType: PropTypes.string
  };

  render() {
    return (
      <>
        <div className='icon-section'>
          <div className='outer-div'>
            <div className='inner-div'>
              <div
                className={`icon-container-toast-${this.props.toastType} icon-container-toast `}>
                <img
                  src={
                    this.props.toastType === 'error' ? errorIcon : successIcon
                  }
                  className='image-icon'
                  alt='icon'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='content-section'>{this.props.content}</div>
      </>
    );
  }
}

export default ToastComponent;
