export const queries = [
  {
    id: 1,
    question:
      'What are the login credentials and how can I login to the GlobalNet Reporting System?',
    answer: {
      first: `GlobalNet Reporting system uses two-factor authentication security system that requires two separate, distinct forms of identification to access to the system.
                The first factor is the user’s credential (User ID & Password) and the second is a verification code which will be sent to user’s email address which is used for registration.
                
                Discover (Internal) users can use their SSO sign in credentials for accessing the GlobalNet Reporting system.
                `
    }
  },
  {
    id: 2,
    question: 'I forgot my password, what do I do?',
    answer: {
      first:
        'GlobalNet Reporting system external users (Data Entry User, General Manager, and Local User Admin) can use “Forgot Password” function placed left side of the login button on login page and can reset their password by following below steps.',
      second: [
        {
          id: 1,
          text: 'Click “Forgot Password” on the login page.'
        },
        {
          id: 2,
          text:
            'Enter your “User ID” and “Email ID” and click the “Submit” button.'
        },
        {
          id: 3,
          text: 'Success message will be populated on the screen.'
        },
        {
          id: 4,
          text:
            'GlobalNet Reporting system will send you an email with a temporary password.'
        },
        {
          id: 5,
          text:
            'Login GlobalNet System with your “User ID” and “Temporary Password” '
        },
        {
          id: 6,
          text:
            'System will automatically lead you to “Change Password” page to set up a new password.'
        },
        {
          id: 7,
          text:
            'You can create your new password by entering “Temporary Password” “New Password” and “Confirm New Password” according to GlobalNet reporting system password requirements and click the “Submit” button.'
        },
        {
          id: 8,
          text:
            'The system will notify you once your new password is created successfully. You can use “Go to Login” button for login page.'
        }
      ],
      third:
        'Discover (Internal) users can use their SSO sign in credentials for accessing the GlobalNet Reporting system.'
    }
  },
  {
    id: 3,
    question: 'I forgot my User ID, what do I do?',
    answer: {
      first:
        'GlobalNet Reporting system allows external users (Data Entry User, General Manager, and Local User Admin) to remind their User ID by using “Forgot User ID” function placed left side of the login button in login page.',
      second: [
        {
          id: 1,
          text: 'Select “Forgot User ID” on the login page.'
        },
        {
          id: 2,
          text: 'Enter your “Email ID” and click the “Submit” button.'
        },
        {
          id: 3,
          text:
            'GlobalNet Reporting system will send you an email with your User ID.'
        },
        {
          id: 4,
          text: 'Success message will be populated on the screen.'
        }
      ],
      third:
        'Discover (Internal) users can use their SSO sign in credentials for accessing the GlobalNet Reporting system.'
    }
  },
  {
    id: 4,
    question: 'What are the browser requirements?',
    answer: {
      first:
        'The GlobalNet 2.0 web site requires Microsoft Edge, Chrome, Firefox, or Safari for Windows. '
    }
  }
];
