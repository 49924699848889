import React from 'react';
import { createMuiTheme } from '@material-ui/core';
import MetaWebProWoff from '../assets/fonts/MetaWebPro-Light.woff';
import { typography } from './typography';

import { ReactComponent as ChipDeleteIcon } from '../assets/icons/chip-delete-icon.svg';

const metaWebPro = {
  fontFamily: 'Meta Web',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Meta Web'),
    local('Meta Web-Regular'),
    url(${MetaWebProWoff}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};

export const base = createMuiTheme({
  palette: {
    primary: {
      main: '#FF6000'
    },

    secondary: {
      main: '#1A587E'
    },

    error: {
      main: '#FF0000'
    },

    success: {
      main: '#0EB50E'
    },

    white: '#FFF',

    text: {
      primary: '#63666B',
      hint: '#727272'
    },

    input: {
      disabled: '#F8F8F8',
      hover: '#63666B',
      outline: '#DBDBDB',
      border: '#DBDBDB',
      selected: '#E6F4F9'
    },

    chip: {
      border: '#D4EBF4',
      background: '#E6F4F9'
    },

    button: {
      primary: {
        main: '#FF6000',
        hover: '#BE502F'
      },
      secondary: {
        main: '#022A48'
      }
    }
  },

  typography,
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0px 3px 6px #00000026'
  ],

  shape: {
    borderRadius: 9
  },

  props: {
    MuiButtonBase: {
      disableTouchRipple: true
    },

    MuiTextField: {
      variant: 'outlined'
    },

    MuiChip: {
      deleteIcon: <ChipDeleteIcon />
    }
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [metaWebPro]
      }
    }
  }
});
