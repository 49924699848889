import { connect } from 'react-redux';
import Announcements from './Announcements';
import { getAllAnnouncements } from '../../../store/admin/actions';

export const mapStateToProps = state => {
  return {
    announcementList: state.admin.announcementList
  };
};

export default connect(mapStateToProps, {
  getAllAnnouncements
})(Announcements);
