import { connect } from 'react-redux';
import PendingApprovals from './PendingApprovals';
import {
  getPendingApprovalUsers,
  selectPendingUser
} from '../../../../store/admin/actions';

export const mapStateToProps = state => {
  return {
    pendingApprovalUsers: state.admin.pendingApprovals.all,
    selectedPendingUser: state.admin.pendingApprovals.selected,
    roleMap: state.user.highestRole
  };
};

export default connect(mapStateToProps, {
  getPendingApprovalUsers,
  selectPendingUser
})(PendingApprovals);
