/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { CanActivate } from '../../../../components';
import PageError from '../../../PageError';

class CreateUserHeader extends Component {
  static propTypes = {
    isValid: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
    orgProfilesLength: PropTypes.number.isRequired
  };

  render() {
    const { isValid, dirty, orgProfilesLength } = this.props;
    return (
      <CanActivate
        action='admin:createuser'
        unAuthorizedView={<PageError errorCode='401' />}>
        <Grid
          container
          justify='space-between'
          alignItems='center'
          data-test='create-user-header-component'>
          <Typography variant='h6' className='pending-approval-header-title'>
            Create Profile
          </Typography>
          <Grid item>
            <Button
              color='secondary'
              variant='outlined'
              type='reset'
              size='small'
              className='user-reset-btn'
              disabled={!dirty}>
              Reset
            </Button>
            <Button
              color='primary'
              variant='contained'
              type='submit'
              size='small'
              disabled={!isValid || !orgProfilesLength}>
              Add User
            </Button>
          </Grid>
        </Grid>
      </CanActivate>
    );
  }
}

export default CreateUserHeader;
