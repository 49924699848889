import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ADMIN_TEXT } from '../../constants/text';
import './UserOrganizationalRolesHeader.css';

const UserOrganizationalRolesHeader = () => (
  <Grid
    item
    md={12}
    className='user-org-roles-header'
    data-test='user-org-roles-header-component'>
    <Grid container>
      <Grid item md={12} className='title'>
        <Typography variant='h6'>
          {ADMIN_TEXT.organizationRoles}
          <span className='danger'>*</span>
        </Typography>
      </Grid>
      <Grid item md={12} className='sub-title'>
        <Grid container>
          <Grid item md={4}>
            <Typography variant='h6'>{ADMIN_TEXT.role}</Typography>
          </Grid>
          <Grid item md={8}>
            <Typography variant='h6'>
              {ADMIN_TEXT.grantableOrganizations}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default UserOrganizationalRolesHeader;
