/* eslint-disable max-lines-per-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './OrganizationChipComponent.css';
import { Grid, Typography } from '@material-ui/core';
import { CheckIcon, CrossIcon } from '../icons';
import RejectModal from '../RejectModal/RejectModal';
import { toastFunc } from '../ToastComponent/toastFunction';
import { CanActivate } from '../../components/';

class OrganizationChipComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switchVal: props.isAdmin,
      rejectModalIsOpen: false,
      rejected: false
    };
  }

  openModal = () => {
    this.setState({ rejectModalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ rejectModalIsOpen: false });
  };

  rejectUser = data => {
    const {
      rejectedReason,
      status,
      roleTypeCode,
      organizationProfileId,
      userProfileId,
      organizationProfileName
    } = data;
    const obj = {
      userProfileId,
      orgRoleProfiles: [
        {
          organizationProfileVO: [
            {
              organizationProfileId,
              organizationProfileName,
              rejectedReason,
              status
            }
          ],
          organizationRoleVO: {
            roleTypeCode
          }
        }
      ]
    };

    this.setState(() => ({ rejected: true, rejectModalIsOpen: false }));

    this.props.manageOrgnizationData(obj).then(response => {
      let content = '';
      let type = '';
      if (response.status === 200) {
        content = 'Access has been Rejected Successfully';
        type = 'accept';
      } else {
        content = response.statusText;
        type = 'reject';
      }
      toastFunc({
        content,
        config: { className: `toast-container ${type}` }
      });
    });
  };

  onChangeSwitchVal = data => {
    this.setState(
      prevState => {
        return {
          switchVal: !prevState.switchVal
        };
      },
      () => {
        data.status = this.state.switchVal ? 'A' : 'R';
        if (this.props.pageType !== 'add') {
          data.rejectedReason = '';
          if (this.state.switchVal) {
            this.approveUser(data);
          } else {
            this.rejectUser(data);
          }
        } else {
          this.props.modifyOrganizationData(data);
        }
      }
    );
  };

  approveUser = data => {
    const { status, roleTypeCode, organizationProfileId, userProfileId } = data;
    const obj = {
      userProfileId,
      orgRoleProfiles: [
        {
          organizationProfileVO: [
            {
              organizationProfileId,
              rejectedReason: '',
              status
            }
          ],
          organizationRoleVO: {
            roleTypeCode
          }
        }
      ]
    };
    this.props.manageOrgnizationData(obj).then(response => {
      let content = '';
      let type = '';
      if (response.status === 200) {
        content = 'Access has been Accepted Successfully';
        type = 'accept';
      } else {
        content = response.statusText;
        type = 'reject';
      }

      toastFunc({
        content,
        config: { className: `toast-container ${type}` }
      });
    });
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    manageOrgnizationData: PropTypes.func.isRequired,
    organizationProfileId: PropTypes.number.isRequired,
    onDragStart: PropTypes.func.isRequired,
    roleId: PropTypes.string.isRequired,
    actionsEnable: PropTypes.bool,
    user: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired
  };

  static defaultProps = {
    actionsEnable: false
  };

  render() {
    const {
      name,
      organizationProfileId,
      onDragStart,
      roleId,
      actionsEnable,
      status,
      user,
      deleteOrganizationData,
      userStatus,
      impersonate,
      activeButtonStatus,
      allActiveOrgLength
    } = this.props;
    const statusCheck = Boolean(
      status === 'P' || userStatus === 'A' || activeButtonStatus === true
    );
    const totalOrgs = Boolean(allActiveOrgLength > 1);
    return (
      <>
        <Grid
          container
          justify='center'
          alignItems='center'
          data-test='orgnization-name-component'
          className={`org-section-container ${(status === 'A' &&
            'is-org-approved') ||
            ''}`}
          draggable={actionsEnable}
          onDragStart={e =>
            onDragStart(
              e,
              organizationProfileId,
              name,
              roleId,
              user.userProfileId
            )
          }>
          <Grid item className='org-section-item' data-test='org-section-item'>
            <Typography
              variant='caption'
              className='caption'
              data-test='caption'>
              {name}
            </Typography>
          </Grid>
          <CanActivate action='user-profile:approval' unAuthorizedView={null}>
            {statusCheck && actionsEnable && !impersonate && (
              <>
                {totalOrgs && (
                  <Grid
                    item
                    className='org-section-item'
                    data-test='org-section-item'>
                    <CrossIcon
                      data-test='org-section-item--crossIcon'
                      onClickIcon={() => {
                        if (this.props.pageType !== 'add') {
                          this.openModal();
                        } else {
                          deleteOrganizationData(organizationProfileId);
                        }
                      }}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  className={`org-section-item ${(status === 'A' &&
                    'is-org-item-approved') ||
                    ''}`}>
                  <CheckIcon
                    data-test='check-icon-component'
                    onClickIcon={() => {
                      if (status !== 'A') {
                        this.approveUser({
                          status: 'A',
                          roleTypeCode: roleId,
                          organizationProfileId,
                          userProfileId: user.userProfileId
                        });
                      }
                    }}
                  />
                </Grid>
              </>
            )}
          </CanActivate>
        </Grid>
        <RejectModal
          isOpen={this.state.rejectModalIsOpen}
          rejected={this.state.rejected}
          user={user}
          onConfirm={rejectedReason =>
            this.rejectUser({
              rejectedReason,
              status: 'R',
              roleTypeCode: roleId,
              organizationProfileId,
              organizationProfileName: name,
              userProfileId: user.userProfileId
            })
          }
          onCancel={this.closeModal}
        />
      </>
    );
  }
}

export default OrganizationChipComponent;
