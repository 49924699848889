import React from 'react';
import PropTypes from 'prop-types';

import './CircularIcon.css';

const CircularIcon = props => {
  const {
    icon,
    alt,
    outerCircle,
    innerCircle,
    iconContainer,
    iconContainerBorder
  } = props;
  return (
    <div
      className='circular-icon-outer'
      style={{ backgroundColor: outerCircle }}
      data-test='circular-icon-component'>
      <div
        className='circular-icon-inner'
        style={{ backgroundColor: innerCircle }}>
        <div
          className='circular-icon-container'
          style={{
            backgroundColor: iconContainer,
            boxShadow: `inset 0px 0px 0px 3px ${iconContainerBorder}`,
            MozBoxShadow: `inset 0px 0px 0px 3px ${iconContainerBorder}`,
            WebkitBoxShadow: `inset 0px 0px 0px 3px ${iconContainerBorder}`
          }}>
          <img src={icon} className='circular-icon' alt={alt} />
        </div>
      </div>
    </div>
  );
};

CircularIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  outerCircle: PropTypes.string.isRequired,
  innerCircle: PropTypes.string.isRequired,
  iconContainer: PropTypes.string.isRequired,
  iconContainerBorder: PropTypes.string.isRequired
};

export default CircularIcon;
