import {
  GET_TOTAL_ORGANIZATION_ROLES,
  GET_TOTAL_ORGANIZATION_NAMES,
  GET_TOTAL_USER_ROLES,
  RESET_IDLE_TIMER,
  SET_SESSION_TIME
} from './types';

import {
  getTotalOrganizationRolesApi,
  getTotalOrganizationNamesApi,
  downloadHelpDocumentsApi,
  getUserRolesApi
} from '../../api/global';

import { blobToObject } from '../upload/actions';
import { gettingSessionTimeOutApi } from '../../api/auth';
import { Buffer } from 'buffer';

export const getTotalOrganizationRoles = () => async dispatch => {
  try {
    let payload = await getTotalOrganizationRolesApi();
    if (payload !== undefined && payload !== null) {
      payload = payload.map(({ roleTypeCode, roleTypeName }) => ({
        id: roleTypeCode,
        label: roleTypeName,
        value: roleTypeCode
      }));
    } else {
      payload = [];
    }
    dispatch({
      type: GET_TOTAL_ORGANIZATION_ROLES,
      payload
    });
  } catch (err) {}
};

export const getTotalUserRoles = () => async dispatch => {
  try {
    let payload = await getUserRolesApi();
    if (payload !== undefined && payload !== null) {
      payload = payload.map(
        ({ roleTypeCode, roleTypeName, roleTypeDescription }) => ({
          id: roleTypeCode,
          label: roleTypeName,
          value: roleTypeCode,
          roleTypeCode,
          roleTypeDescription,
          roleTypeName
        })
      );
    } else {
      payload = [];
    }

    dispatch({
      type: GET_TOTAL_USER_ROLES,
      payload
    });
  } catch (err) {}
};

export const getTotalOrganizationNames = () => async dispatch => {
  try {
    let payload = await getTotalOrganizationNamesApi();
    if (payload !== undefined && payload !== null) {
      payload = payload.map(
        ({ organizationProfileId, organizationProfileName, luaavailable }) => ({
          id: organizationProfileId,
          label: organizationProfileName,
          value: organizationProfileId,
          luaavailable
        })
      );
    } else {
      payload = [];
    }

    dispatch({
      type: GET_TOTAL_ORGANIZATION_NAMES,
      payload
    });
  } catch (err) {}
};

export const downloadHelpDocuments = async fileName => {
  const data = await downloadHelpDocumentsApi(fileName);
  if (data !== undefined) {
    const content = data.content;
    const extension = data.fileName.split('.').pop();
    const fileName = data.fileName;
    const decodedData = Buffer.from(content, 'base64');
    if (extension === 'pdf') {
      const type = { type: 'application/pdf' };
      blobToObject(decodedData, fileName, type);
    }
  }
};

export const resetTimer = () => async dispatch => {
  dispatch({
    type: RESET_IDLE_TIMER,
    payload: false
  });
};

export const getSessionTime = () => async dispatch => {
  try {
    const resp = await gettingSessionTimeOutApi();
    if (!(resp === null || resp.data === null)) {
      dispatch({
        type: SET_SESSION_TIME,
        payload: resp.data
      });
    } else {
      dispatch({
        type: SET_SESSION_TIME,
        payload: 900
      });
    }
  } catch (err) {
    dispatch({
      type: SET_SESSION_TIME,
      payload: 900
    });
  }
};
