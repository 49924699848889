import * as Yup from 'yup';
import rules from '../fieldRules';

const {
  businessName,
  salutation,
  firstName,
  lastName,
  middleName,
  jobTitle,
  emailId,
  userLoginId,
  password,
  conformPassword
} = rules;

export const personalDetailsSchema = Yup.object().shape({
  businessName,
  salutation,
  firstName,
  middleName,
  lastName,
  jobTitle,
  emailId
});

export const accountInfoSchema = Yup.object().shape({
  userLoginId,
  password,
  conformPassword
});
