import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import './Impersonate.css';

const ImpersonateUserButton = ({ onSubmit, className }) => {
  return (
    <Button
      variant='outlined'
      size='small'
      color='secondary'
      className={className}
      data-test='impersonate-button'
      onClick={onSubmit}>
      Impersonate
    </Button>
  );
};

ImpersonateUserButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired
};

export default ImpersonateUserButton;
