import React, { Component, Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextFieldSearch from './TextField';
import './Contract.css';
import { getParentOrganizations } from '../../../store/contracts/actions';
import { sortObject } from '../../../utils/commonFunctions';

export class ParentOrganizations extends Component {
  state = {
    searchText: ''
  };

  componentDidMount() {
    this.props.getParentOrganizations();
  }

  handleChange = e => {
    this.setState({
      searchText: e.target.value
    });
  };

  getOrgIdFromUrl = url => {
    const urlId = url.split('/');
    return urlId[3];
  };

  render() {
    const { history, populateOrgId } = this.props;
    let parentOrganizations = this.props.parentOrganizations.filter(org => {
      return org.id !== 0;
    });

    if (this.state.searchText) {
      parentOrganizations = parentOrganizations.filter(org => {
        return org.label
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase());
      });
    }

    parentOrganizations =
      parentOrganizations.length > 0 &&
      sortObject(parentOrganizations, 'label');

    return (
      <Fragment>
        <Grid
          container
          direction='column'
          className='contract-list-container'
          data-test='component-ParentOrganizations'>
          <Grid item md={12}>
            <Grid container className='list-header'>
              <Grid item md={12}>
                <TextFieldSearch
                  value={this.state.searchText}
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <Grid item md={12}>
              {!parentOrganizations && this.state.searchText !== '' && (
                <Grid
                  container
                  className='parent-organization-no-records-container'>
                  <Grid item md={12}>
                    <Typography variant='h6' className='no-records-item'>
                      No Records Found
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {parentOrganizations.length > 0 && (
                <Grid container className='org-scroll'>
                  {parentOrganizations.map(list => {
                    return (
                      <Grid
                        key={list.id}
                        item
                        md={12}
                        className={`contract-org-container ${this.getOrgIdFromUrl(
                          history.location.pathname
                        ) === String(list.id) && 'active'}`}
                        onClick={() => {
                          populateOrgId(`${list.id}`);
                          history.push(`/admin/contract/${list.id}`);
                        }}>
                        <Typography variant='h6' className='search-list'>
                          {list.label}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  parentOrganizations: state.contracts.parentOrganizations
});

export default connect(mapStateToProps, { getParentOrganizations })(
  withRouter(ParentOrganizations)
);
