import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import './ForecastToggleButton.css';

const ForecastToggleButton = props => {
  const {
    orgReportingPeriod,
    setDataForms,
    toggleStatus,
    toggleStatusHandler
  } = props;
  const [firstForecastActive, setFirstForecastActive] = useState(true);
  const [secondForecastActive, setSecondForecastActive] = useState(false);
  const firstForecastYear = parseInt(orgReportingPeriod.reportingYear) + 1;
  const secondForecastYear = parseInt(orgReportingPeriod.reportingYear) + 2;
  const location = useLocation();

  const forecastToggleFirst = () => {
    localStorage.setItem('planYear', JSON.stringify(firstForecastYear));
    setSecondForecastActive(false);
    setFirstForecastActive(true);
    if (toggleStatus === false) {
      toggleStatusHandler();
    }
  };

  const forecastToggleSecond = () => {
    localStorage.setItem('planYear', JSON.stringify(secondForecastYear));
    setFirstForecastActive(false);
    setSecondForecastActive(true);
    if (toggleStatus === true) {
      toggleStatusHandler();
    }
  };

  const callForecastYear = () => {
    if (orgReportingPeriod.orgProfileId) {
      const planYear = toggleStatus ? firstForecastYear : secondForecastYear;
      setDataForms(location.pathname, planYear);
    }
  };
  useEffect(() => {
    if (toggleStatus === false) {
      forecastToggleSecond();
    } else {
      forecastToggleFirst();
    }
    callForecastYear();
  }, [toggleStatus]);

  return (
    <div className='forecast-button-wrapper' data-test='forecast-toggle-button'>
      <Button
        className={`forecast-button first ${
          firstForecastActive ? 'is-active' : ''
        }`}
        variant='contained'
        aria-label='first-toggle-button'
        data-test='first-toggle-button'
        onClick={forecastToggleFirst}>
        {firstForecastYear}
      </Button>
      <Button
        className={`forecast-button second ${
          secondForecastActive ? 'is-active' : ''
        }`}
        variant='contained'
        aria-label='second-toggle-button'
        data-test='second-toggle-button'
        onClick={forecastToggleSecond}>
        {secondForecastYear}
      </Button>
    </div>
  );
};

export default ForecastToggleButton;
