import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import FileUploadIcon from '../../../assets/icons/icon_upload.svg';
import FileUploadIconDisable from '../../../assets/icons/icon_upload-disabled.svg';
import {
  RPT_PRD_NEXT_MONTH_ERR,
  RPT_PRD_PREVIOUS_MONTH_ERR
} from '../../../constants/errors';
export const Upload = props => {
  const {
    history,
    onClose,
    previousMonthStatus,
    buttonRadioStatus,
    userRole,
    forms,
    userRoleMap,
    orgProfileId
  } = props;
  const isUploadBtnDisable =
    RPT_PRD_NEXT_MONTH_ERR === previousMonthStatus ||
    RPT_PRD_PREVIOUS_MONTH_ERR === previousMonthStatus;
  const [uploadStatus, setUploadStatus] = useState(true);
  const formSubmittedStatus =
    forms?.length > 0 && (!forms[0].formEditable || forms[0].isUserSubmitted);
  const dateEntryUserMap = userRoleMap.D ? userRoleMap.D : [];
  const statusCheck =
    isUploadBtnDisable ||
    forms.length === 0 ||
    buttonRadioStatus ||
    formSubmittedStatus;
  const uploadToggleCheck = () => {
    const isAdmin = userRole === 'Portal Admin';
    const isLocalUserAdminOrManager =
      userRole === 'Local User Admin' || userRole === 'General Manager';
    const isDataEntry =
      userRole === 'Data Entry' || userRole === 'Data Entry User';

    if (
      isAdmin ||
      (isLocalUserAdminOrManager &&
        dateEntryUserMap.includes(String(orgProfileId))) ||
      isDataEntry
    ) {
      setUploadStatus(Boolean(statusCheck));
    } else {
      setUploadStatus(true);
    }
  };
  useEffect(() => {
    uploadToggleCheck();
  }, [uploadStatus, buttonRadioStatus, forms]);
  return (
    <Grid
      container
      className='megamenu-section upload'
      direction='column'
      justify='space-around'
      data-test='component-upload-mega-menu'>
      <Typography
        variant='h5'
        className='heading megamenu-gray'
        data-test='upload-mega-menu-heading'>
        Upload Data File
      </Typography>
      <Grid item>
        <Grid container direction='column' justify='center' alignItems='center'>
          <Button
            disabled={uploadStatus}
            className='upload-icon-Invisible-Button'
            data-test='upload-button'
            onClick={() => {
              history.push('/manage-data/upload');
              onClose();
            }}>
            <div data-test='upload-icon' className='icon-container'>
              <div
                data-test='icon'
                className={
                  uploadStatus
                    ? 'upload-icon-outer-disabled'
                    : 'upload-icon-outer'
                }>
                <div
                  data-test='upload-icon-inner-disabled'
                  className={
                    uploadStatus
                      ? 'upload-icon-inner-disabled'
                      : 'upload-icon-inner'
                  }>
                  <img
                    data-test='upload-icon-initial'
                    src={uploadStatus ? FileUploadIconDisable : FileUploadIcon}
                    className='data-file-icon icon upload-icon-initial'
                    alt='File Icon'
                  />
                </div>
              </div>
            </div>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

Upload.propTypes = {
  history: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withRouter(Upload);
