import React, { useEffect, useState, useRef } from 'react';
import './AuditReport.css';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import AuditReportHeader from './AuditReportHeader';
import AuditReportButtonContainer from './AuditReportButtonContainer';
import AuditReportSearchContainer from './AuditReportSearchContainer';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { Modal } from '../../../components';
import XlsIcon from '../../../assets/icons/xls-icon.svg';
import SuccessIcon from '../../../assets/icons/success-icon.svg';
import { blobToObject } from '../../../store/upload/actions';
import { toastFunc } from '../../../components/ToastComponent/toastFunction';
import { Buffer } from 'buffer';
export const reportType = [
  { value: 'Profile Changes', label: 'Profile Changes' },
  { value: 'Data Changes', label: 'Data Changes' },
  { value: 'Admin Actions', label: 'Admin Actions' },
  { value: 'Last Login', label: 'Last Login' },
  { value: 'Monthly submission report', label: 'Monthly submission report' },
  { value: 'Reporting Month', label: 'Reporting Month' }
];

export const AuditReport = props => {
  const {
    isValid,
    dirty,
    values,
    handleChange,
    handleSubmit,
    resetForm,
    errors,
    status: { emptyError }
  } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const timer = useRef();
  useEffect(() => {
    clearTimeout(timer.current);
    if (emptyError !== undefined) {
      setModalOpen(false);
    }
  }, [emptyError]);

  const handleAuditSubmit = () => {
    setModalOpen(true);
    handleSubmit();
  };
  const handleOnCloseSubmit = () => {
    setModalOpen(false);
  };
  return (
    <>
      <Modal
        data-test='audit-report-modal'
        isOpen={modalOpen}
        submitBtnLabel='Close'
        onCancel={false}
        onClose={() => handleOnCloseSubmit()}
        onSubmit={() => handleOnCloseSubmit()}>
        <div
          className='audit-report-modal-container'
          data-test='audit-report-modal-container'>
          {props.status.success ? (
            <>
              <div className='upload-icon-outer' data-test='upload-icon-outer'>
                <div
                  className='upload-icon-inner'
                  data-test='upload-icon-inner'>
                  <div
                    className='progressContainer'
                    data-test='progressContainer'>
                    <img
                      src={SuccessIcon}
                      data-test='audit-success-icon'
                      className='audit-success-icon'
                      alt='excel file icon'
                    />
                  </div>
                </div>
              </div>
              <Typography
                id='file-upload-div-caption'
                color='secondary'
                variant='h6'
                data-test='file-name'
                className='dnd-txt audit-report-download-filename'>
                Audit Report for
                <span
                  className='audit-report-filename'
                  data-test='audit-report-filename'>
                  {` ${values.report.label} `}
                </span>
                has been downloaded successfully.
              </Typography>
              <br />
              <span
                className='audit-report-filename-download'
                data-test='audit-report-filename-download'>
                {` ${values.report.label}_${values.month}_${values.year}.xlsx`}
              </span>
            </>
          ) : (
            <>
              <div className='upload-icon-outer' data-test='upload-icon-outer'>
                <div
                  className='upload-icon-inner'
                  data-test='upload-icon-inner'>
                  <div
                    className='progressContainer'
                    data-test='progressContainer'>
                    <img
                      src={XlsIcon}
                      data-test='excel-icon'
                      className='uploading-icon'
                      alt='excel file icon'
                    />

                    <CircularProgress
                      size={65}
                      thickness={2}
                      color='primary'
                      data-test='upload-progress'
                    />
                  </div>
                </div>
              </div>
              <Typography
                id='file-upload-div-caption'
                className='dnd-txt audit-report-download-filename'
                color='secondary'
                variant='h6'
                data-test='file-name'>
                Downloading :
                <span
                  className='audit-report-filename'
                  data-test='audit-report-filename'>
                  {` ${values.report.label}-${values.month}-${values.year}.xlsx`}
                </span>
              </Typography>
            </>
          )}
        </div>
      </Modal>
      <Grid container item data-test='audit-report-component'>
        <Grid
          container
          className='audit-report-container'
          data-test='audit-report-container'
          alignItems='flex-start'
          alignContent='flex-start'>
          <AuditReportHeader data-test='audit-report-header' />
          <Grid
            container
            item
            className='audit-report-criteria-container'
            data-test='audit-report-criteria-container'>
            <AuditReportSearchContainer
              values={values}
              dirty={dirty}
              handleChange={handleChange}
              errors={errors}
              reportType={reportType}
              data-test='audit-report-search-container'
            />
            <AuditReportButtonContainer
              dirty={dirty}
              handleSubmit={handleAuditSubmit}
              resetForm={resetForm}
              errors={errors}
              isValid={isValid}
              data-test='audit-report-button-container'
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const formConfig = withFormik({
  mapPropsToValues: () => ({
    report: '',
    month: '',
    year: ''
  }),
  validationSchema: Yup.object().shape({
    report: Yup.object().required(),
    year: Yup.string().required(),
    month: Yup.string().required()
  }),
  mapPropsToStatus: () => ({}),
  handleSubmit: (values, formikbag) => {
    formikbag.setStatus({ success: false });
    const auditReportValues = {
      month: values.month,
      report: values.report.value,
      year: values.year
    };
    formikbag.props
      .getAuditReport(auditReportValues)
      .then(response => {
        if (response.status === 200) {
          const content = response.data.content;
          const fileName = response.data.fileName;
          const decodedData = Buffer.from(content, 'base64');
          const type = {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
          };
          blobToObject(decodedData, fileName, type);
          formikbag.setStatus({ success: true });
        }
      })
      .catch(err => {
        setTimeout(() => {
          formikbag.setStatus({ emptyError: err.response.data.errorMessage });
          toastFunc({
            content: err.response.data.errorMessage,
            config: { className: 'toast-container reject' },
            toastType: 'error'
          });
        }, 2000);
      });
  }
});

export default formConfig(AuditReport);
