import React, { Fragment } from 'react';
import { Typography, Button } from '@material-ui/core';
import SuccessIcon from '../../assets/icons/success-icon.svg';
import { Link } from 'react-router-dom';
import CircleArrowIcon from './../../assets/icons/circle-arrow.svg';
const SubmitSuccess = props => {
  const {
    currentForm,
    allForms,
    navigateToLastForm,
    orgReportingPeriod,
    successDownloadButton
  } = props;
  const currentPageTitle =
    allForms[currentForm] && allForms[currentForm].formName;
  return (
    <Fragment>
      <div
        className='success-container'
        id='submit-success-container'
        data-test='success-container'>
        <div
          className='action-buttons'
          data-test='component-franchise-flow-action-buttons'>
          <Link data-test='last-form-page-link' onClick={navigateToLastForm}>
            <Typography
              variant='body1'
              className='submit-action-block'
              data-test='submit-action-block'>
              <img
                alt='arrow left'
                src={CircleArrowIcon}
                data-test='arrow-left'
              />
              <span
                className='submit-current-title'
                data-test='submit-current-title'>
                {currentPageTitle}
              </span>
            </Typography>
          </Link>
        </div>
        <div
          className='upload-icon-outer upload-icon-outer-submit'
          data-test='upload-icon-outer upload-icon-outer-submit'>
          <div className='upload-icon-inner' data-test='upload-icon-inner'>
            <div className='successContainer' data-test='successContainer'>
              <img
                src={SuccessIcon}
                data-test='success-icon'
                className='success-icon'
                alt='Json icon'
              />
            </div>
          </div>
        </div>
        <Typography variant='h6' data-test='org-info' className='file-info-txt'>
          {orgReportingPeriod.orgName} {orgReportingPeriod.reportingMonth}/
          {orgReportingPeriod.reportingYear}
        </Typography>
        <Typography
          variant='body1'
          data-test='uploading-info'
          className='uploading-info'>
          <span data-test='date-success-span'>
            Forms submitted successfully
          </span>
        </Typography>
        <Button
          classes={{ root: 'data-review-button' }}
          color='primary'
          variant='contained'
          aria-label='data review button'
          data-test='review-button'
          onClick={successDownloadButton}>
          <Typography
            className='cancel-button-txt'
            data-test='cancel-button-txt'
            variant='body1'>
            Download
          </Typography>
        </Button>
      </div>
    </Fragment>
  );
};

export default SubmitSuccess;
