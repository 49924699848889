import {
  SET_FORMS_LIST_IN_MENU,
  SET_UPLOADFORMS_LIST_IN_MENU,
  SAVE_ORG_NAME_AND_ID,
  GET_INTES_DROPDOWN_DATA,
  GET_COUNTRIES_DROPDOWN_DATA,
  GET_TYPE_OF_FRAUD_DROPDOWN_DATA,
  GET_ROYALTY_DROPDOWN_DATA,
  RESET_FORMS_LIST_IN_MENU,
  SET_FORM_TYPE_IN_MENU,
  SAVE_SELECTED_FORM
} from './types';

export const initialState = {
  forms: [],
  allDataForms: {
    monthlyDataForms: [],
    quarterlyDataForms: [],
    annuallyDataForms: []
  },
  formType: 'monthlyDataForms',
  allFormTypes: ['monthlyDataForms', 'quarterlyDataForms', 'annuallyDataForms'],
  uploadForms: [],
  orgReportingPeriod: {},
  selectedOrgReportedPeriod: {}
};

const formsListNavReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case SET_FORMS_LIST_IN_MENU:
      return {
        ...state,
        allDataForms: actions.payload.allDataForms,
        forms: actions.payload.allDataForms[state.formType],
        orgReportingPeriod: {
          ...state.orgReportingPeriod,
          ...actions.payload.orgReportingPeriod
        },
        uploadForms: actions.payload.uploadedForm
      };
    case SET_UPLOADFORMS_LIST_IN_MENU:
      return {
        ...state,
        uploadForms: actions.payload
      };
    case SET_FORM_TYPE_IN_MENU:
      return {
        ...state,
        formType: actions.payload,
        forms: state.allDataForms[actions.payload],
        orgReportingPeriod: {
          ...state.orgReportingPeriod,
          formType: actions.payload
        }
      };
    case SAVE_ORG_NAME_AND_ID:
      return {
        ...state,
        orgReportingPeriod: {
          ...state.orgReportingPeriod,
          orgName: actions.payload.orgName,
          orgProfileId: actions.payload.orgId
        }
      };
    case RESET_FORMS_LIST_IN_MENU:
      return {
        ...state,
        forms: initialState.forms,
        allDataForms: initialState.allDataForms
      };
    case GET_ROYALTY_DROPDOWN_DATA:
      return {
        ...state,
        royaltyDescDropDown: actions.payload
      };
    case GET_INTES_DROPDOWN_DATA:
      return {
        ...state,
        intesDropdownData: actions.payload
      };
    case GET_COUNTRIES_DROPDOWN_DATA:
      return {
        ...state,
        countriesDropdownData: actions.payload
      };
    case GET_TYPE_OF_FRAUD_DROPDOWN_DATA:
      return {
        ...state,
        typeOfFraudDropdownData: actions.payload
      };
    case SAVE_SELECTED_FORM:
      return {
        ...state,
        selectedOrgReportedPeriod: actions.payload
      };
    default:
      return state;
  }
};

export default formsListNavReducer;
