import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import './GenericModal.css';

const GenericModal = props => {
  const {
    title,
    isOpen,
    submitBtnLabel,
    cancelBtnLabel,
    onClose,
    onSubmit,
    onCancel,
    submitDisabled,
    isOkButton = false
  } = props;
  return (
    <Dialog
      open={isOpen}
      maxWidth='md'
      PaperProps={{
        className: 'modal',
        elevation: 1
      }}
      data-test='component-modal'
      onClose={onClose}>
      {title && (
        <DialogTitle disableTypography className='modal-title'>
          <Typography
            variant='h6'
            color='secondary'
            data-test='modal-title'
            className='modal-title-text'>
            {title}
          </Typography>
        </DialogTitle>
      )}

      <DialogContent className='modal-content' data-test='modal-content'>
        {props.children}
      </DialogContent>

      {(onCancel || onSubmit || isOkButton) && (
        <DialogActions className='modal-actions' data-test='modal-actions'>
          {isOkButton && (
            <Button
              variant='outlined'
              color='secondary'
              className='modal-action-btns'
              data-test='cancel-btn'
              size='small'
              onClick={onCancel}>
              OK
            </Button>
          )}
          {onCancel && !isOkButton && (
            <Button
              variant='outlined'
              color='secondary'
              className='modal-action-btns'
              data-test='cancel-btn'
              size='small'
              onClick={onCancel}>
              {cancelBtnLabel ? cancelBtnLabel : 'Cancel'}
            </Button>
          )}

          {onSubmit && !isOkButton && (
            <Button
              color='primary'
              variant='contained'
              className='modal-action-btns'
              data-test='submit-btn'
              size='small'
              disabled={submitDisabled}
              onClick={onSubmit}>
              {submitBtnLabel ? submitBtnLabel : 'Submit'}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

GenericModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  submitBtnLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitDisabled: PropTypes.bool
};

GenericModal.defaultProps = {
  submitDisabled: false,
  title: '',
  onSubmit: () => {},
  onCancel: () => {}
};

export default GenericModal;
