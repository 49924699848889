import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

const Review = props => {
  const { reviews, onClose } = props;
  return (
    <Grid
      container
      direction='column'
      className='megamenu-section review'
      data-test='component-review-megamenu-section'>
      <Typography
        variant='h5'
        className='heading megamenu-gray'
        data-test='review-megamenu-heading'>
        Review
      </Typography>
      <div className='reviews-container'>
        {reviews.map(r => (
          <div key={r.organization} data-test='pending-review'>
            <Typography
              display='inline'
              variant='body2'
              className='item megamenu-gray'>
              {r.date}
            </Typography>
            <Link
              to='/'
              style={{ marginLeft: 15 }}
              data-test='pending-review-link'
              onClick={onClose}>
              <Typography display='inline' variant='body2'>
                {r.organization}
              </Typography>
            </Link>
          </div>
        ))}
      </div>
    </Grid>
  );
};

Review.propTypes = {
  reviews: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Review;
