import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-icon.svg';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';

export const YesOrNo = ({ value }) => {
  if (value === 'T' || value === 'Y') {
    return <Typography style={{ color: 'green' }}>Yes</Typography>;
  }

  return <Typography>No</Typography>;
};

const OrgTableRow = ({ org, onEdit, readOnly, isInternalUser }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setTimeout(() => setOpen(false), 2500);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <TableRow data-test='org-table-row-component'>
      {isInternalUser && (
        <TableCell
          className='organization-page-main-text'
          data-test='org-table-cell-component'>
          {typeof org.organizationProfileId !== 'string'
            ? org.organizationProfileId
            : ''}
          {typeof org.organizationProfileId !== 'string' ? (
            <Tooltip
              disableFocusListener
              disableTouchListener
              disableHoverListener
              open={open}
              title='Copied!'
              placement='right'
              data-test='organization-id-copy-icon-tooltip'
              onMouseOut={handleTooltipClose}>
              <IconButton
                className='organization-id-copy-icon-button'
                data-test='organization-id-copy-icon-button'
                onClick={() => {
                  handleTooltipOpen();
                  navigator.clipboard.writeText(org.organizationProfileId);
                }}>
                {open ? (
                  <DoneIcon
                    fontSize='small'
                    className='organization-id-copy-icon'
                  />
                ) : (
                  <ContentCopyIcon
                    fontSize='small'
                    className='organization-id-copy-icon'
                  />
                )}
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )}
        </TableCell>
      )}
      <TableCell className='organization-page-main-text'>
        {org.organizationProfileName}
      </TableCell>
      <TableCell className='organization-page-main-text'>
        {org.orgRegionInfo.regionTypeDescription}
      </TableCell>
      <TableCell className='organization-page-main-text'>
        {org.operatingCountryCodeInfo.countryIsoName}
      </TableCell>
      <TableCell className='organization-page-main-text'>
        {org.orgTypeInfo.orgTypNm}
      </TableCell>
      <TableCell className='organization-page-main-text'>
        {org.currencyInfo.currencyTypeCode}
      </TableCell>
      <TableCell className='organization-page-main-text'>
        {org.iicISOCode}
      </TableCell>
      <TableCell className='organization-page-main-text'>
        {org.iicDXSCode}
      </TableCell>
      <TableCell>
        <YesOrNo value={org.activeStatus} />
      </TableCell>
      <TableCell>
        <YesOrNo value={org.sendToFinancialForce} />
      </TableCell>
      {!readOnly && (
        <>
          <TableCell>
            <IconButton onClick={onEdit}>
              <EditIcon />
            </IconButton>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default OrgTableRow;
