import React, { useState } from 'react';
import { Grid, Typography, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';

const AnnouncementBanner = props => {
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [descModalOpen, setDescModalOpen] = useState(false);
  const { announcement } = props;
  const isAnnouncementDescLarge =
    announcement.announcementDescription &&
    announcement.announcementDescription.length > 300;
  const toggleImageModal = () => {
    setImgModalOpen(prevImgModalOpen => !prevImgModalOpen);
  };
  const toggleDescModal = () => {
    setDescModalOpen(prevDescModalOpen => !prevDescModalOpen);
  };
  const announcementHeaderTitle = type => {
    return (
      <>
        <Typography
          color='secondary'
          data-test={`announcement-${type}-head`}
          variant='h6'
          id='announcement-heading'>
          ANNOUNCEMENT
        </Typography>
        <Typography
          color='primary'
          data-test={`announcement-${type}-title`}
          className='announcement-title'
          variant='h4'
          dangerouslySetInnerHTML={{ __html: announcement.announcementTitle }}
        />
      </>
    );
  };
  return (
    <>
      <Modal
        open={imgModalOpen}
        data-test='announcement-img-modal'
        onClose={toggleImageModal}>
        <div className='announcement-img'>
          <img
            data-test='announcement-banner-modal-image'
            className='announcement-banner-image'
            src={`data:image/${announcement.imageType};base64,${announcement.announcementImage}`}
            alt='Announcement banner'
          />
          <button
            className='announcement-img-close-btn'
            onClick={toggleImageModal}>
            X
          </button>
        </div>
      </Modal>

      <Modal
        open={descModalOpen}
        data-test='announcement-description-modal'
        onClose={toggleDescModal}>
        <div className='announcement-img announcement-desc'>
          {announcementHeaderTitle('modal')}
          <Typography
            data-test='announcement-modal-body'
            className='announcement-body'
            variant='body1'
            dangerouslySetInnerHTML={{
              __html: announcement.announcementDescription
            }}
          />
          <button
            className='announcement-img-close-btn'
            onClick={toggleDescModal}>
            X
          </button>
        </div>
      </Modal>

      <Grid
        container
        alignItems='center'
        className='announcement-banner margin'
        data-test='announcement-banner-container'>
        <Grid
          item
          role='region'
          md={8}
          aria-labelledby='announcement-heading'
          className='announcement-text-container'>
          <div className='announcement-text'>
            {announcementHeaderTitle('banner')}
            <Typography
              data-test='announcement-banner-body'
              className='announcement-body'
              variant='body1'>
              {isAnnouncementDescLarge
                ? `${announcement.announcementDescription.slice(0, 300)}..... `
                : announcement.announcementDescription}
              {isAnnouncementDescLarge && (
                <span
                  className='announcement-desc-text'
                  onClick={toggleDescModal}>
                  Read more
                </span>
              )}
            </Typography>
          </div>
        </Grid>
        <Grid item className='announcement-banner-container' md={4}>
          {announcement.announcementImage &&
            announcement.announcementImage.length > 0 && (
              <img
                data-test='announcement-banner-image'
                className='announcement-banner-image announcement-banner-image-over'
                src={`data:image/${announcement.imageType};base64,${announcement.announcementImage}`}
                alt='Announcement banner'
                onClick={toggleImageModal}
              />
            )}
        </Grid>
      </Grid>
    </>
  );
};

AnnouncementBanner.propTypes = {
  announcement: PropTypes.object.isRequired
};

export default AnnouncementBanner;
