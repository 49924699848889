export const errorTypes = {
  NETWORK_ERROR: 'NETWORK_ERROR',
  NETWORK_SUCCESS: 'NETWORK_SUCCESS',
  SET_LOGIN_FORM_ERROR: 'SET_LOGIN_FORM_ERROR',
  RESET_LOGIN_FORM_ERROR: 'RESET_LOGIN_FORM_ERROR',
  SET_ANNOUNCEMENT_ERROR: 'SET_ANNOUNCEMENT_ERROR',
  RESET_ANNOUNCEMENT_ERROR: 'RESET_ANNOUNCEMENT_ERROR',
  GLOBAL_ERROR: 'GLOBAL_ERROR',
  SET_REGISTRATION_ERRORS: 'SET_REGISTRATION_ERRORS',
  SET_MY_PROFILE_ERROR: 'SET_MY_PROFILE_ERROR',
  RESET_MY_PROFILE_ERROR: 'RESET_MY_PROFILE_ERROR',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
  RESET_FORM_ERRORS: 'RESET_FORM_ERRORS'
};
