import React, { Component } from 'react';
import { Button, TableRow, TableCell, IconButton } from '@material-ui/core';
import { CirclePlusIcon } from '../../../components/icons';
import { TextFieldGrid } from '../../../components/formik';
import { withFormik } from 'formik';
import { royaltyRateScaleSchema } from '../../../constants/validations/admin/contracts';
import { Dropdown } from '../../../components/material';

const buisnessTierNumber = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' }
];
export class RoyaltyRateScaleForm extends Component {
  handleBlur = e => {
    const { handleBlur, status, setStatus } = this.props;
    e.persist();
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };

  render() {
    const {
      mode,
      isValid,
      handleReset,
      handleChange,
      handleSubmit,
      values
    } = this.props;

    return (
      <TableRow
        data-test='component-RoyaltyRateScaleForm'
        style={{
          backgroundColor: '#F2F9FC'
        }}>
        <TableCell>
          <Dropdown
            options={buisnessTierNumber}
            label='Select Number'
            name='tireNumber'
            className='bg-white buisness-tier-number'
            value={values.tireNumber}
            onChange={handleChange}
            onBlur={this.handleBlur}
          />
        </TableCell>

        <TableCell style={{ textAlign: 'right' }}>
          <TextFieldGrid
            placeholder='Enter Value'
            className='bg-white ml-30'
            inputProps={{ style: { textAlign: 'right' } }}
            name='from'
            onBlur={this.handleBlur}
          />
        </TableCell>
        <TableCell style={{ textAlign: 'right' }}>
          <TextFieldGrid
            placeholder='Enter Bps'
            className='bg-white'
            style={{ width: 130 }}
            inputProps={{ style: { textAlign: 'right' } }}
            name='rate'
            onBlur={this.handleBlur}
          />
        </TableCell>
        {mode === 'edit' && <TableCell colSpan={6} />}
        <TableCell style={{ textAlign: 'center' }}>
          <IconButton
            aria-label='add'
            disabled={!isValid}
            size='small'
            onClick={handleSubmit}>
            <CirclePlusIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <Button
            color='secondary'
            variant='outlined'
            type='button'
            size='small'
            className='user-reset-btn'
            onClick={handleReset}>
            Clear
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

const formConfig = withFormik({
  mapPropsToValues: () => ({
    tireNumber: '',
    from: '',
    rate: ''
  }),

  isInitialValid: false,
  validationSchema: royaltyRateScaleSchema,

  mapPropsToStatus: () => ({}),

  handleSubmit: (values, formikBag) => {
    const rateScale = {
      tireNumber: Number(values.tireNumber),
      rate: Number(values.rate),
      from: Number(values.from),
      royaltyOperationCode: 'A'
    };
    formikBag.props.onAddRoyaltyRateScale(rateScale);
    formikBag.resetForm();
  }
});

export default formConfig(RoyaltyRateScaleForm);
