/* eslint-disable max-lines-per-function */
import React, { Component } from 'react';
import { withFormik } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import * as moment from 'moment';
import { ActionBar, Modal } from '../../../../components';
import { ReactComponent as BackButtonIcon } from '../../../../assets/icons/back-icon.svg';
import ContractInfo from './ContractInfo';
import RoyaltyExclusionTerms from './RoyaltyExclusionTerms';
import RoyaltyRateScale from '../RoyaltyRateScale';
import ContractNotes from './ContractNotes';
import {
  updateContractApi,
  updateExclusionTypes,
  updateRoyaltyRate
} from '../../../../api/contracts';
import { createStatusFromErrors } from '../../../../utils/commonFunctions';
import { toastFunc } from '../../../../components/ToastComponent/toastFunction';
import { editContractSchema } from '../../../../constants/validations/admin/contracts';

export class EditContract extends Component {
  state = {
    modalOpen: false
  };

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }));
  };

  handleChange = e => {
    const { setFieldValue, values, handleChange } = this.props;
    const { name, value } = e.target;

    if (value && name === 'organizationProfileId') {
      let obj = {};
      value.forEach(val => {
        const { id, label } = val;
        const newObj = { [id]: label };
        obj = { ...obj, ...newObj };
      });
      setFieldValue('organizationProfileList', {
        ...values.organizationProfileList,
        ...obj
      });
    }
    handleChange(e);
  };

  handleBlur = e => {
    const { handleBlur, status, setStatus } = this.props;
    e.persist();
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };

  addRoyaltyRateScale = data => {
    const { setFieldValue, values } = this.props;
    setFieldValue('royaltyRateScale', [...values.royaltyRateScale, data]);
  };

  deleteRoyaltyRateScale = (tireNumber, notes) => {
    const { setFieldValue, values } = this.props;
    if (
      values.royaltyRateScale.findIndex(
        rate =>
          rate.tireNumber === tireNumber && rate.royaltyOperationCode === 'A'
      ) !== -1
    ) {
      setFieldValue(
        'royaltyRateScale',
        values.royaltyRateScale.filter(
          royalty => royalty.tireNumber !== tireNumber
        )
      );
    } else {
      setFieldValue(
        'royaltyRateScale',
        values.royaltyRateScale.map(royalty => {
          if (royalty.tireNumber !== tireNumber) {
            return royalty;
          }
          return {
            ...royalty,
            royaltyOperationCode: 'D',
            notes
          };
        })
      );
    }
  };

  deleteChildOrganizationProfile = key => {
    const { setFieldValue, values } = this.props;
    const filterValues = { ...values.organizationProfileList };
    delete filterValues[key];
    setFieldValue('organizationProfileList', filterValues);
  };

  addRoyaltyExclusionTerms = ({ exclusionCode }) => {
    const { setFieldValue, values } = this.props;
    if (
      values.exclusionTypes.findIndex(
        exclusion => exclusion.exclusionCode === exclusionCode.value
      ) === -1
    ) {
      setFieldValue('exclusionTypes', [
        ...values.exclusionTypes,
        {
          exclusionCode: exclusionCode.value,
          exclusionName: exclusionCode.label,
          exclusionOperationCode: 'A',
          notes: ''
        }
      ]);
    } else {
      setFieldValue(
        'exclusionTypes',
        values.exclusionTypes.map(exclusion => {
          if (exclusion.exclusionCode === exclusionCode.value) {
            return {
              ...exclusion,
              exclusionOperationCode: 'P'
            };
          }
          return exclusion;
        })
      );
    }
  };

  updateRoyaltyExclusionTerms = (exclusionCode, notes) => {
    const { setFieldValue, values } = this.props;
    if (
      values.exclusionTypes.findIndex(
        exclusion => exclusion.exclusionCode === exclusionCode
      ) != -1
    ) {
      setFieldValue(
        'exclusionTypes',
        values.exclusionTypes.map(exclusion => {
          if (exclusion.exclusionCode === exclusionCode) {
            return {
              ...exclusion,
              notes
            };
          }
          return exclusion;
        })
      );
    }
  };

  deleteRoyaltyExclusionTerms = (exclusionCode, notes) => {
    const { setFieldValue, values } = this.props;
    if (
      values.exclusionTypes.findIndex(
        exclusion =>
          exclusion.exclusionCode === exclusionCode &&
          exclusion.exclusionOperationCode === 'P'
      ) !== -1
    ) {
      setFieldValue(
        'exclusionTypes',
        values.exclusionTypes.map(exclusion => {
          if (exclusion.exclusionCode !== exclusionCode) {
            return exclusion;
          }
          return {
            ...exclusion,
            exclusionOperationCode: 'D',
            notes
          };
        })
      );
    } else {
      setFieldValue(
        'exclusionTypes',
        values.exclusionTypes.filter(
          exclusion => exclusion.exclusionCode !== exclusionCode
        )
      );
    }
  };

  onChildOrgUnSelectAll = () => {
    const { setFieldValue } = this.props;
    setFieldValue('organizationProfileList', {});
  };

  onChildOrgSelectAll = () => {
    const { setFieldValue, childOrganizations } = this.props;

    let obj = {};

    childOrganizations.forEach(val => {
      const { id, label } = val;
      if (id !== 0) {
        const newObj = { [id]: label };
        obj = { ...obj, ...newObj };
      }
    });
    setFieldValue('organizationProfileList', obj);
  };

  renderHeaderLeft = () => {
    const { goBack } = this.props;
    return (
      <Grid
        container
        justify='flex-start'
        style={{ cursor: 'pointer' }}
        onClick={goBack}>
        <Grid item>
          <BackButtonIcon />
        </Grid>
        <Grid item>
          <Typography className='back-btn-text row-value' variant='h6'>
            Back to Contract(s)
          </Typography>
        </Grid>
      </Grid>
    );
  };

  renderHeader = () => {
    const { dirty, isValid, handleReset } = this.props;
    return (
      <ActionBar
        left1={this.renderHeaderLeft()}
        primaryButton={{
          label: 'Save',
          onClick: this.toggleModal,
          disabled: !isValid
        }}
        secondaryButton={{
          label: 'Reset',
          onClick: handleReset,
          disabled: !dirty
        }}
      />
    );
  };

  renderModal = () => {
    const { handleSubmit } = this.props;

    return (
      <Modal
        isOpen={this.state.modalOpen}
        submitBtnLabel='Confirm'
        onSubmit={() => {
          this.toggleModal();
          handleSubmit();
        }}
        onCancel={this.toggleModal}
        onClose={this.toggleModal}>
        <Typography color='secondary' variant='body1'>
          Are you sure you want to Update Contract?
        </Typography>
      </Modal>
    );
  };

  render() {
    const {
      childOrganizations,
      exclusionTypesList,
      values,
      errors,
      touched,
      status,
      contract,
      handleChange,
      initialValues
    } = this.props;
    return (
      <>
        {this.renderModal()}
        <Grid
          container
          className='contract-container'
          data-test='component-EditContract'>
          <Grid item md={12} data-test='component-Edit-Contract-Grid'>
            {this.renderHeader()}
          </Grid>
          <Grid item md={12} data-test='component-edit-contract-info-grid'>
            <ContractInfo
              childOrganizations={childOrganizations}
              values={values}
              initialValues={initialValues}
              contract={contract}
              errors={errors}
              touched={touched}
              status={status}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              deleteChildOrganizationProfile={
                this.deleteChildOrganizationProfile
              }
              data-test='component-contract-info'
              onChildOrgSelectAll={this.onChildOrgSelectAll}
              onChildOrgUnSelectAll={this.onChildOrgUnSelectAll}
            />
          </Grid>
          <Grid
            item
            md={12}
            data-test='component-edit-Royalty-ExclusionTerms-grid'>
            <RoyaltyExclusionTerms
              exclusionTypesList={exclusionTypesList}
              values={values}
              initialValues={initialValues}
              contract={contract}
              handleChange={handleChange}
              updateRoyaltyExclusionTerms={this.updateRoyaltyExclusionTerms}
              data-test='component-Royalty-Exclution'
              onAddRoyaltyExclusionTerms={this.addRoyaltyExclusionTerms}
              onDeleteRoyaltyExclusionTerms={this.deleteRoyaltyExclusionTerms}
            />
          </Grid>
          <Grid item md={12} data-test='component-edit-Royalty-rate-scale-grid'>
            <RoyaltyRateScale
              mode='edit'
              royaltyRateScale={values.royaltyRateScale.filter(
                royalty => royalty.royaltyOperationCode !== 'D'
              )}
              data-test='component-edit-Royalty-rate-scale'
              onAddRoyaltyRateScale={this.addRoyaltyRateScale}
              onDeleteRoyaltyRateScale={this.deleteRoyaltyRateScale}
            />
          </Grid>

          <Grid item md={12} data-test='component-edit-Contract-notes-grid'>
            <ContractNotes
              values={values}
              errors={errors}
              touched={touched}
              status={status}
              handleChange={handleChange}
              handleBlur={this.handleBlur}
              data-test='component-edit-Contract-notes'
            />
          </Grid>
          <Grid item md={12} data-test='component-edit-render-header'>
            {this.renderHeader()}
          </Grid>
        </Grid>
      </>
    );
  }
}

const formConfig = withFormik({
  validationSchema: editContractSchema,
  mapPropsToValues: ({ contract }) => {
    const effStartDateArr = contract.effectiveStartDate.split('/');
    const effEndDateArr = contract.effectiveEndDate.split('/');

    return {
      contractId: contract.contractId,
      organizationName: contract.organizationName,
      organizationProfileList: contract.organizationProfileList,
      effectiveStartDate: new Date(
        effStartDateArr[2],
        effStartDateArr[1] - 1,
        effStartDateArr[0]
      ),
      effectiveEndDate: new Date(
        effEndDateArr[2],
        effEndDateArr[1] - 1,
        effEndDateArr[0]
      ),
      includeCentralSubmissionVolume: Boolean(
        contract.includeCentralSubmissionVolume
      ),
      allowManualRoyalityExclusion: Boolean(
        contract.allowManualRoyalityExclusion
      ),

      exclusionTypes: contract.exclusionTypes
        ? contract.exclusionTypes.map(exclusion => ({
            ...exclusion,
            exclusionOperationCode: 'P'
          }))
        : [],

      royaltyRateScale: contract.royaltyRateScale
        ? contract.royaltyRateScale.map(rate => ({
            tireNumber: rate.tireNumber,
            from: rate.from,
            rate: rate.rate,
            royaltyOperationCode: 'P'
          }))
        : [],
      contractNotes: contract.contractNotes,
      contractType:
        contract.contractTypeCode !== undefined &&
        contract.contractTypeCode !== null
          ? contract.contractTypeCode
          : '',
      globalNetOrganizationId: contract.globalNetOrganizationId,
      notes: '',
      notesEffectivePeriod: '',
      notesOrganizationProfile: '',
      notesIncludeCentralSubmissionVolume: '',
      notesAllowManualRoyalty: '',
      notesMinCurrentAssetsLiabilityRatio: '',
      notesNetWorthOfReceivables: '',
      notesNetWorthOfSalesVolume: ''
    };
  },
  isInitialValid: false,

  mapPropsToStatus: () => ({}),

  handleSubmit: async (values, formikBag) => {
    const { contract } = formikBag.props;
    const initialProfiles = formikBag.props.contract.organizationProfileList;
    const totalOrgProfileIds = [
      ...new Set([
        ...Object.keys(initialProfiles),
        ...Object.keys(values.organizationProfileList)
      ])
    ];
    const updatedOrgProfiles = {};
    for (const id of totalOrgProfileIds) {
      if (initialProfiles[id] && values.organizationProfileList[id]) {
        continue;
      }
      if (initialProfiles[id]) {
        updatedOrgProfiles[id] = 'D';
      } else {
        updatedOrgProfiles[id] = 'A';
      }
    }
    const deletedOrgProfileLength = Object.values(updatedOrgProfiles).filter(
      data => data === 'D'
    ).length;
    const addedOrgProfileLength = Object.values(updatedOrgProfiles).filter(
      data => data === 'A'
    ).length;
    const totalOrgProfileLength = Object.keys(initialProfiles).length;
    if (
      totalOrgProfileLength -
        deletedOrgProfileLength +
        addedOrgProfileLength ===
      0
    ) {
      return formikBag.setErrors({
        organizationProfileId:
          'Please enter a value for Organization Profile Name.'
      });
    }

    const actions = [];
    if (
      Object.keys(updatedOrgProfiles).length &&
      values.notesOrganizationProfile !== ''
    ) {
      actions.push({
        actionName: 'Updated Organization Profiles',
        notes: values.notesOrganizationProfile
      });
    }
    if (
      contract.effectiveStartDate !==
        moment(values.effectiveStartDate).format('DD/MM/YYYY') ||
      contract.effectiveEndDate !==
        moment(values.effectiveEndDate).format('DD/MM/YYYY')
    ) {
      if (
        contract.effectiveStartDate !==
          moment(values.effectiveStartDate).format('DD/MM/YYYY') &&
        values.notesEffectivePeriod !== ''
      ) {
        actions.push({
          actionName: 'Updated Effective Start Date',
          notes: values.notesEffectivePeriod
        });
      }

      if (
        contract.effectiveEndDate !==
          moment(values.effectiveEndDate).format('DD/MM/YYYY') &&
        values.notesEffectivePeriod
      ) {
        actions.push({
          actionName: 'Updated Effective End Date',
          notes: values.notesEffectivePeriod
        });
      }
    }
    if (
      values.includeCentralSubmissionVolume !==
      Boolean(contract.includeCentralSubmissionVolume)
    ) {
      actions.push({
        actionName:
          'Updated Include Central Submission Volume in Royalty Additions Screen',
        notes: values.notesIncludeCentralSubmissionVolume
      });
    }

    if (values.notes) {
      actions.push({
        actionName: 'Update Contract',
        notes: values.notes
      });
    }

    const body = {
      contractId: values.contractId,
      globalNetOrganizationId: values.globalNetOrganizationId,
      effectiveStartDate: moment(values.effectiveStartDate).format(
        'DD/MM/YYYY'
      ),
      effectiveEndDate: moment(values.effectiveEndDate).format('DD/MM/YYYY'),
      contractNotes: [{ actions }],
      contractTypeCode: values.contractType,
      organizationProfileList: updatedOrgProfiles,
      includeCentralSubmissionVolume: values.includeCentralSubmissionVolume
    };

    const updatedRoyaltyRates = values.royaltyRateScale.filter(
      rate => rate.royaltyOperationCode !== 'P'
    );

    const royaltyRateBody = {
      contractId: values.contractId,
      royaltyRateScale: updatedRoyaltyRates,
      actions: updatedRoyaltyRates.map(rate => {
        if (rate.royaltyOperationCode === 'A' && rate.notes !== '') {
          return {
            actionName: `Added Royalty with tier number ${rate.tireNumber}`,
            notes: rate.notes
          };
        }
        return {
          actionName: `Delete Royalty with tier number ${rate.tireNumber}`,
          notes: rate.notes || null
        };
      })
    };
    const updatedExclusions = values.exclusionTypes.filter(
      exclusion => exclusion.exclusionOperationCode !== 'P'
    );

    const exclusionsActions = [];
    if (
      values.allowManualRoyalityExclusion !==
      contract.allowManualRoyalityExclusion
    ) {
      exclusionsActions.push({
        actionName:
          'Updated Authorize Franchise for Manual Royalty Exclusion Input',
        notes: values.notesAllowManualRoyalty || null
      });
    }

    updatedExclusions.forEach(exclusion => {
      if (exclusion.exclusionOperationCode === 'A') {
        exclusionsActions.push({
          actionName: `Added excluison ${exclusion.exclusionName}`,
          notes: exclusion.notes || null
        });
      } else {
        exclusionsActions.push({
          actionName: `Deleted exclusion ${exclusion.exclusionName}`,
          notes: exclusion.notes || null
        });
      }
    });
    const exclusionsBody = {
      contractId: values.contractId,
      allowManulaRoyalityExclusion: values.allowManualRoyalityExclusion,
      exclusionTypes: updatedExclusions,
      actions: exclusionsActions
    };
    try {
      const res = await updateContractApi(body);
      const royaltyRes = await updateRoyaltyRate(royaltyRateBody);
      const excResult = await updateExclusionTypes(exclusionsBody);

      if (res && royaltyRes && excResult) {
        toastFunc({
          content: 'Contract has been Updated Successfully',
          config: { className: 'toast-container accept' }
        });
        formikBag.props.clearContract();
      }
    } catch (error) {
      const errMsg = createStatusFromErrors(error);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        delete errMsg.root;
      }
      formikBag.setStatus(errMsg);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorMap &&
        error.response.data.errorMap[
          'Effective Start Date and Effective End Date'
        ]
      ) {
        toastFunc({
          content: `${error.response.data.errorMap['Effective Start Date and Effective End Date']}`,
          config: { className: 'toast-container reject' },
          toastType: 'error'
        });
      } else if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        toastFunc({
          content: `${error.response.data.errorMessage}`,
          config: { className: 'toast-container reject' },
          toastType: 'error'
        });
      }
    }
  }
});

export default formConfig(EditContract);
