import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer
} from '@material-ui/core';
import { Loader } from '../../../components';
import { TableInput } from '../../../components/generictable';
import { range } from '../../../utils/commonFunctions';
import {
  setDataForms,
  changeTab,
  resetDataForms
} from '../../../store/dataForms/actions';

export class CardMemberSummary extends Component {
  componentDidMount() {
    this.props.setDataForms(this.props.location.pathname);
  }

  componentWillUnmount() {
    this.props.resetDataForms();
  }

  renderSection1 = section => {
    const cols = Object.entries(section.columnHeaders);
    const nCols = cols.length;

    return (
      <TableContainer>
        <Table
          data-test='component-table'
          className='data-form-table card-member section-0'>
          <TableHead data-test='component-table-head'>
            <TableRow className='header-1' data-test='component-table-head-row'>
              <TableCell
                className='col-0'
                style={{ width: `${100 / (cols.length + 1)}%` }}
              />
              {cols.map(([colId, colName]) => {
                return (
                  <TableCell
                    key={colId}
                    style={{ width: `${100 / (cols.length + 1)}%` }}
                    data-test='component-table-head-cell'>
                    {colName}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow data-test='component-table-head-row' className='header-2'>
              <TableCell className='col-0' colSpan={cols.length + 1}>
                {section.sectionName}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-test='component-table-body'>
            {Object.entries(section.rowHeaders).map(
              ([rowKey, rowVal], rindex, array) => {
                const rowBolded = rindex === 0 || rindex === array.length - 1;
                return (
                  <TableRow
                    key={rowKey}
                    data-test='component-table-body-row'
                    className={`dataform-row row-${rindex}`}>
                    <TableCell
                      className={`row-label row-${rindex} col-0 ${
                        rowBolded ? 'bold-row-total' : ''
                      }`}
                      aria-label={`row ${rowVal}`}>
                      {rowVal}
                    </TableCell>
                    {range(nCols).map(col => (
                      <TableCell
                        key={col}
                        className={`row-${rindex} col-${col + 1} ${
                          rowBolded ? 'bold-row-total' : ''
                        }`}>
                        <TableInput
                          index={col + rindex * nCols}
                          sectionIndex={0}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  renderSection2 = section => {
    const cols = Object.entries(section.columnHeaders);
    const nCols = cols.length;
    return (
      <TableContainer>
        <Table
          data-test='component-table'
          className='data-form-table card-member section-1'>
          <TableHead data-test='component-table-head' className='header-2'>
            <TableRow data-test='component-table-head-row'>
              <TableCell className='col-0'>{section.sectionName}</TableCell>
              <TableCell colSpan={cols.length} />
            </TableRow>
          </TableHead>
          <TableBody data-test='component-table-body'>
            {Object.entries(section.rowHeaders).map(
              ([rowKey, rowVal], rindex, array) => {
                const isLastRow = rindex === array.length - 1;
                return (
                  <TableRow
                    key={rowKey}
                    data-test='component-table-body-row'
                    className={`dataform-row row-${rindex}`}>
                    <TableCell
                      className={`row-label row-${rindex} col-0 ${
                        isLastRow ? 'bold-row-total' : ''
                      }`}
                      aria-label={`row ${rowVal}`}
                      style={{ width: `${100 / (cols.length + 1)}%` }}>
                      {rowVal}
                    </TableCell>
                    {range(nCols).map(col => (
                      <TableCell
                        key={col}
                        className={`row-${rindex} col-${col + 1} ${
                          isLastRow ? 'bold-row-total' : ''
                        }`}
                        style={{ width: `${100 / (cols.length + 1)}%` }}>
                        <TableInput
                          index={col + rindex * nCols}
                          sectionIndex={1}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  renderSection3 = section => {
    const cols = Object.entries(section.columnHeaders);
    const nCols = cols.length;
    return (
      <TableContainer>
        <Table
          data-test='component-table'
          className='data-form-table card-member section-2'>
          <TableHead data-test='component-table-head' className='header-2'>
            <TableRow data-test='component-table-head-row'>
              <TableCell className='col-0'>{section.sectionName}</TableCell>
              <TableCell colSpan={cols.length} />
            </TableRow>
          </TableHead>
          <TableBody data-test='component-table-body'>
            {Object.entries(section.rowHeaders).map(
              ([rowKey, rowVal], rindex) => {
                return (
                  <TableRow
                    key={rowKey}
                    data-test='component-table-body-row'
                    className={`dataform-row row-${rindex}`}>
                    <TableCell
                      className={`row-label row-${rindex} col-0`}
                      aria-label={`row ${rowVal}`}
                      style={{ width: `${100 / (cols.length + 1)}%` }}>
                      {rowVal}
                    </TableCell>
                    {range(nCols).map(col => (
                      <TableCell
                        key={col}
                        className={`row-${rindex} col-${col + 1}`}
                        style={{ width: `${100 / (cols.length + 1)}%` }}>
                        <TableInput
                          index={col + rindex * nCols}
                          sectionIndex={2}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  render() {
    const { forms } = this.props;
    const form = forms && forms.tabs ? forms.tabs[0] : undefined;

    if (!form || !forms || !form.sections || form.sections.length < 3) {
      return (
        <Grid
          container
          item
          justify='center'
          alignItems='center'
          data-test='component-franchise'>
          <Loader data-test='component-loader' />
        </Grid>
      );
    }

    return (
      <div
        className={`table-wrapper ${
          this.props.location.pathname.split('/')[3]
        }`}>
        {this.renderSection1(form.sections[0])}
        {this.renderSection2(form.sections[1])}
        {this.renderSection3(form.sections[2])}
      </div>
    );
  }
}

CardMemberSummary.propTypes = {
  tableName: PropTypes.string.isRequired
};

export const mapStateToProps = state => {
  return {
    forms: state.dataForms.form,
    activeTab: state.dataForms.activeTab,
    tableName: 'CardMemberSummary'
  };
};

export const mapDispatchToProps = {
  setDataForms,
  changeTab,
  resetDataForms
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CardMemberSummary));
