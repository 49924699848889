/* eslint-disable capitalized-comments */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Home from './Home';
import {
  fetchFranchisesData,
  filterFranchises
} from '../../store/franchisesData';
import {
  fetchAnnouncement,
  fetchImportantDates
} from '../../store/announcements';
import { getMegaMenuOrganizationNames } from '../../store/admin/actions';
import { openMegaMenu } from '../../store/ui';

export const mapStatetoProps = state => ({
  isAuthenticated: state.auth.isUserAuthenticated,
  importantDates: state.announcements.importantDates,
  announcement: state.announcements.announcement,
  franchisesData: state.franchisesData,
  announcementError: state.errors.announcementError,
  selectedMegaMenu: state.ui.selectedMegaMenu,
  user: state.user,
  manageDataMenuActive: state.ui.manageDataMenu
});

export const mapDispatchToProps = {
  fetchFranchisesData,
  fetchAnnouncement,
  fetchImportantDates,
  filterFranchises,
  openMegaMenu,
  getMegaMenuOrganizationNames
};

export default connect(mapStatetoProps, mapDispatchToProps)(withRouter(Home));
