import React, { useState, useEffect } from 'react';
import { Grid, Typography, Tabs, Tab } from '@material-ui/core';
import { Loader, PageSkeleton } from '../../../components';
import ProductLineTable from './ProductLineTable';
import './ProductLineTable.css';

const tableHeaders = [
  {
    title: 'Product Name',
    key: 'Product',
    value: ''
  },
  {
    title: 'Code',
    key: 'Code',
    value: ''
  },
  {
    title: 'Branding Type',
    key: 'Branding',
    value: ''
  },
  {
    title: ''
  }
];

const TabPanel = props => {
  const { children, value, index, ...other } = props;
  if (value === index) {
    return (
      <Typography component='div' rol='tabpanel' {...other}>
        {children}
      </Typography>
    );
  }
  return null;
};

export const ProductLine = props => {
  const { totalProducts, getAllProducts, createProducts } = props;

  const [value, setValue] = useState(0);

  useEffect(() => {
    getAllProducts();
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  if (
    totalProducts.commercial.length === 0 ||
    totalProducts.consumer.length === 0
  ) {
    return (
      <Grid
        container
        item
        justify='center'
        alignItems='center'
        data-test='component-product-line'>
        <Loader data-test='component-loader-product-line' />
      </Grid>
    );
  }

  return (
    <PageSkeleton title='Product Line' data-test='Product Line'>
      <Tabs
        className={`tabs ${value ? 'active' : ''}`}
        data-test='product-tab'
        value={value}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#ff6000',
            width: '100%',
            height: '4px'
          }
        }}
        onChange={handleTabChange}>
        <Tab
          className='product-tab-commercial'
          data-test='product-tab-commercial'
          label='COMMERCIAL'
        />
        <Tab
          className='product-tab-consumer'
          data-test='product-tab-consumer'
          label='CONSUMER'
        />
      </Tabs>
      <TabPanel
        data-test='product-tab-panel-commercial'
        value={value}
        index={0}>
        <ProductLineTable
          productBuisnessCode='CM'
          tableHeaders={tableHeaders}
          data={totalProducts.commercial}
          getAllProducts={getAllProducts}
          createProducts={createProducts}
        />
      </TabPanel>
      <TabPanel data-test='product-tab-panel-consumer' value={value} index={1}>
        <ProductLineTable
          productBuisnessCode='CN'
          tableHeaders={tableHeaders}
          data={totalProducts.consumer}
          getAllProducts={getAllProducts}
          createProducts={createProducts}
        />
      </TabPanel>
    </PageSkeleton>
  );
};

export default ProductLine;
