export const stepper = {
  MuiStepper: {
    root: {
      backgroundColor: 'transparent !important',
      padding: 0
    }
  },
  MuiStep: {
    horizontal: {
      paddingRight: 0,
      paddingLeft: 0
    }
  }
};
