/* eslint-disable max-lines-per-function */
import React, { Component } from 'react';
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton
} from '@material-ui/core';
import { Switch } from '../../../../components/material';
import { TrashIcon } from '../../../../components/icons';
import RoyaltyExclusionTermsForm from '../RoyaltyExclusionTermsForm';
import { TextFieldGrid } from '../../../../components/formik';

class RoyaltyExclusionTerms extends Component {
  constructor(props) {
    super(props);
    this.inputRef = [];
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.initialValues.includeCentralSubmissionVolume ==
      this.props.values.includeCentralSubmissionVolume
    ) {
      this.props.values.notesIncludeCentralSubmissionVolume = '';
    }
    if (
      this.props.values.allowManualRoyalityExclusion ==
      this.props.initialValues.allowManualRoyalityExclusion
    ) {
      this.props.values.notesAllowManualRoyalty = '';
    }
  }

  render() {
    const {
      exclusionTypesList,
      handleChange,
      values,
      contract,
      onDeleteRoyaltyExclusionTerms,
      onAddRoyaltyExclusionTerms,
      initialValues,
      updateRoyaltyExclusionTerms
    } = this.props;
    const activeExclusions = values.exclusionTypes.filter(
      exclusion => exclusion.exclusionOperationCode !== 'D'
    );
    const exclusions = activeExclusions.map(
      exclusion => exclusion.exclusionCode
    );

    const filteredExclusions = exclusionTypesList.filter(
      exclusion => !exclusions.includes(exclusion.value)
    );

    return (
      <Grid container data-test='component-RoyaltyExclusionTerms'>
        <Grid
          item
          md={12}
          className='row-header-bar'
          data-test='row-header-bar'>
          <Typography data-test='row-header-text' variant='h6'>
            Royalty Exclusion Terms
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className='border-bottom px-15'
          data-test='row-sub-header'>
          <Grid container alignItems='center' data-test='row-sub-header-center'>
            <Grid item md={4} className='pl-15' data-test='row-sub-header-para'>
              <Typography variant='h6' data-test='row-sub-header-text'>
                Include Central Submission Volume in Royalty Additions Screen
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              className='pr-15'
              data-test='row-switch-container'>
              <Switch
                value
                data-test='row-switch-function'
                id='includeCentralSubmissionVolume'
                name='includeCentralSubmissionVolume'
                checked={values.includeCentralSubmissionVolume}
                disabled={!contract.active}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={1} data-test='row-blank-container' />
            <Grid
              item
              md={3}
              data-test='row-notesIncludeCentralSubmissionVolume'>
              <TextFieldGrid
                fullWidth
                data-test='row-notesIncludeCentralSubmissionVolume-text'
                disabled={
                  values.includeCentralSubmissionVolume ==
                  initialValues.includeCentralSubmissionVolume
                }
                className='bg-white'
                label='Enter Notes'
                name='notesIncludeCentralSubmissionVolume'
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          className='border-bottom px-15'
          data-test='row-manual-Royalty-Exclusion'>
          <Grid
            container
            alignItems='center'
            data-test='row-manual-Royalty-Exclusion-container'>
            <Grid
              item
              md={4}
              className='pl-15'
              data-test='row-manual-Royalty-Exclusion-Typo'>
              <Typography
                variant='h6'
                data-test='row-manual-Royalty-Exclusion-text'>
                Authorize Franchise for Manual Royalty Exclusion Input
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              className='pr-15'
              data-test='row-manual-Royalty-Exclusion-switch'>
              <Switch
                value
                data-test='row-manual-Royalty-Exclusion-switch-function'
                id='isAllowManulaRoyalityExclusion'
                name='allowManualRoyalityExclusion'
                checked={values.allowManualRoyalityExclusion}
                disabled={activeExclusions.length}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={1} data-test='row-manual-Royalty-Exclusion-switch' />
            <Grid item md={3} data-test='row-manual-Royalty-Exclusion-switch'>
              <TextFieldGrid
                fullWidth
                data-test='row-manual-Royalty-Exclusion-text-field'
                disabled={
                  values.allowManualRoyalityExclusion ==
                  initialValues.allowManualRoyalityExclusion
                }
                className='bg-white'
                label='Enter Notes'
                name='notesAllowManualRoyalty'
              />
            </Grid>
          </Grid>
        </Grid>
        <TableContainer data-test='royalty-exclusion-terms-table-container'>
          <Table
            s
            data-test='royalty-exclusion-terms-table'
            className='royalty-exclusion-terms-table'>
            <TableBody>
              <RoyaltyExclusionTermsForm
                exclusionTypesList={filteredExclusions}
                onAddRoyaltyExclusionTerms={onAddRoyaltyExclusionTerms}
              />
              {activeExclusions.map((exclusionType, i) => (
                <TableRow
                  key={exclusionType.exclusionCode}
                  data-test='royalty-exclusion-terms-table-row'>
                  <TableCell data-test='royalty-exclusion-terms-table-row-cell'>
                    <Typography
                      variant='h6'
                      className='pl-15'
                      data-test='royalty-exclusion-terms-table-row-header'>
                      {exclusionType.exclusionName}
                    </Typography>
                  </TableCell>
                  <TableCell data-test='royalty-exclusion-terms-table-row-header-table-cell'>
                    <TextFieldGrid
                      fullWidth
                      data-test='royalty-exclusion-terms-table-row-header-textfield'
                      label='Enter Notes'
                      inputRef={el => (this.inputRef[i] = el)}
                      onChange={() =>
                        updateRoyaltyExclusionTerms(
                          exclusionType.exclusionCode,
                          this.inputRef[i].querySelector('input').value
                        )
                      }
                    />
                  </TableCell>
                  <TableCell data-test='royalty-exclusion-terms-table-row-header-icon-container'>
                    <IconButton
                      data-test='royalty-exclusion-terms-table-row-header-icon'
                      className='icon-button'
                      onClick={() =>
                        onDeleteRoyaltyExclusionTerms(
                          exclusionType.exclusionCode,
                          this.inputRef[i].querySelector('input').value
                        )
                      }>
                      <TrashIcon data-test='royalty-exclusion-terms-table-trash-icon' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  }
}

export default RoyaltyExclusionTerms;
