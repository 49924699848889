import { announcementsActions } from './types';
import {
  getAnnouncementApi,
  getImportantDatesApi
} from '../../api/announcements';
import {
  getSingleAnnouncementApi,
  updateAnnouncementApi
} from '../../api/admin';
import { catchError, resetAnnouncementError } from '../error/actions';
import { getAllAnnouncements } from '../admin/actions';

const monthsArr = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const setAnnouncement = announcement => ({
  type: announcementsActions.SET_ANNOUNCEMENT,
  payload: announcement
});

export const setImportantDates = dates => ({
  type: announcementsActions.SET_IMPORTANT_DATES,
  payload: dates
});

export const fetchAnnouncement = (
  getAnnouncementApiFn = getAnnouncementApi
) => async (dispatch, getState) => {
  const announcementError = getState().errors.announcementError;
  if (announcementError) {
    dispatch(resetAnnouncementError());
  }
  try {
    const res = await getAnnouncementApiFn();
    dispatch(setAnnouncement(res));
  } catch (err) {
    dispatch(catchError(err, 'ANNOUNCEMENT'));
  }
};
export const fetchImportantDates = () => async dispatch => {
  try {
    const dueDateResponse = await getImportantDatesApi();
    const dueDateFormatResponse = [];
    const dueDateFormat = (date, dateName) => {
      const dueDate = new Date(date);
      dueDateFormatResponse.push({
        name: dateName,
        day: dueDate.getUTCDate(),
        monthName: monthsArr[dueDate.getMonth()],
        year: dueDate.getFullYear()
      });
    };
    if (dueDateResponse.Monthly) {
      dueDateFormat(dueDateResponse.Monthly, 'Monthly');
    }
    if (dueDateResponse.Quarterly) {
      dueDateFormat(dueDateResponse.Quarterly, 'Quarterly');
    }
    if (dueDateResponse.Annual) {
      dueDateFormat(dueDateResponse.Annual, 'Annually');
    }
    dispatch(setImportantDates(dueDateFormatResponse));
  } catch (err) {}
};

export const getAnnouncementById = anmtID => async dispatch => {
  try {
    const payload = await getSingleAnnouncementApi(anmtID);
    if (payload && payload.announcementImage && payload.imageType) {
      payload.imageUrl = `data:image/${payload.imageType};base64,${payload.announcementImage}`;
    }
    dispatch({
      type: announcementsActions.GET_SINGLE_ANNOUNCEMENT,
      payload
    });
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
  }
};

export const updateSingleAnnouncement = anmctObj => async dispatch => {
  try {
    const payload = await updateAnnouncementApi(anmctObj);
    if (payload && payload.status === 200) {
      await getAnnouncementById(anmctObj.announcementId)(dispatch);
      await getAllAnnouncements()(dispatch);
    }
    return Promise.resolve(payload);
  } catch (err) {}
};

export const clearingReduxAnnouncementStore = () => ({
  type: announcementsActions.GET_SINGLE_ANNOUNCEMENT,
  payload: {}
});
