import React, { Component } from 'react';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-icon.svg';
import { TextFieldGrid } from '../../../components/formik';
import { Typography, TableRow, TableCell, Button } from '@material-ui/core';
import { Dropdown } from '../../../components/material';

class FormContainer extends Component {
  render() {
    const {
      values,
      handleChange,
      handleBlur,
      handleSubmit,
      resetForm,
      branding,
      errors,
      touched,
      isValid,
      dirty
    } = this.props;

    return (
      <TableRow
        className='product-line-table-sub-header'
        data-test='product-line-add'>
        <TableCell data-test='Form-Container-Table'>
          <TextFieldGrid
            id='Product Name'
            data-test='pname-input'
            label='Enter Product Name'
            name='pname'
            value={values.pname}
            className='form-product-input product-textbox'
            onChange={event => {
              const regex = /^[a-zA-Z ]*$/;
              if (event.target.value === '' || regex.test(event.target.value)) {
                handleChange(event);
              }
            }}
          />
        </TableCell>
        <TableCell data-test='Form-Container-Table'>
          <TextFieldGrid
            id='Product Code'
            data-test='pcode-input'
            label='Enter Product Code'
            name='pcode'
            inputProps={{ maxLength: 3 }}
            value={values.pcode}
            className='form-product-input product-textbox'
            onChange={event => {
              const regex = /^[a-zA-Z0-9]*$/;
              if (event.target.value === '' || regex.test(event.target.value)) {
                handleChange(event);
              }
            }}
          />
        </TableCell>

        <TableCell data-test='Form-Container-Table'>
          <Dropdown
            required
            data-test='product-dropbox'
            id='brandingType'
            options={branding}
            label='Select Branding Type'
            name='brand'
            className='brand-dropdown'
            value={values.brand}
            error={Boolean(touched.brand) && Boolean(errors.brand)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </TableCell>
        <TableCell align='center' data-test='Form-Container-Table'>
          <Typography data-test='Form-Container-Table-Inner'>
            <>
              <Button
                className='add-icon-button'
                disabled={!isValid || !dirty}
                data-test='Form-Container-Icon'
                onClick={handleSubmit}>
                <AddIcon data-test='Icon' />
              </Button>

              <Button
                color='secondary'
                variant='outlined'
                type='reset'
                size='small'
                className='user-reset-btn'
                data-test='clear-button'
                onClick={resetForm}>
                Clear
              </Button>
            </>
          </Typography>
        </TableCell>
      </TableRow>
    );
  }
}

export default React.memo(FormContainer);
