import MegaMenu from './MegaMenu';
import { connect } from 'react-redux';
import { closeMegaMenu } from '../../store/ui';
import {
  setFormsListInMenu,
  saveOrgNameAndId,
  setTempFormsListInMenu,
  setFormType,
  saveSelectedForm
} from '../../store/formsListNav/actions';
import { fetchUserProfile } from '../../store/user';
import {
  getMegaMenuOrganizationNames,
  getOrgParams
} from '../../store/admin/actions';
import { resetDataForms, setDataForms } from '../../store/dataForms/actions';

const mapStateToProps = state => ({
  selectedMegaMenu: state.ui.selectedMegaMenu,
  forms: state.formsListNav.forms,
  form: state.dataForms.form,
  tempFormsListNav: state.dataForms.tempFormsListNav,
  megaMenuOrganizationNames: state.admin.megaMenuOrganizationNames,
  orgReportingPeriod: state.formsListNav.orgReportingPeriod,
  selectedOrgReportedPeriod: state.formsListNav.selectedOrgReportedPeriod,
  orgParams: state.admin.orgParams,
  previousMonthStatus: state.errors.formErrors.errorMessage,
  formType: state.formsListNav.formType,
  allDataForms: state.formsListNav.allDataForms,
  user: state.user
});

const mapDispatchToProps = {
  closeMegaMenu,
  setFormsListInMenu,
  getMegaMenuOrganizationNames,
  saveOrgNameAndId,
  setTempFormsListInMenu,
  setDataForms,
  resetDataForms,
  getOrgParams,
  setFormType,
  saveSelectedForm,
  fetchUserProfile: profileId => fetchUserProfile(profileId)
};

export { MegaMenu };
export default connect(mapStateToProps, mapDispatchToProps)(MegaMenu);
