import React from 'react';
import { Grid } from '@material-ui/core';
import EmptyDetailsScreen from '../../../components/EmptyDetailsScreen/EmptyDetailsScreen';

function NoSelectedFranchisee(props) {
  return (
    <Grid
      item
      xs
      className='franchisees-listing-container'
      data-test='franchisees-listing-container'>
      <EmptyDetailsScreen
        content='Select Organization to Unlock'
        arrowDirection='Left'
        contentVariant='h4'
        data-test='empty-details-screen'
      />
    </Grid>
  );
}

NoSelectedFranchisee.propTypes = {};

export default NoSelectedFranchisee;
