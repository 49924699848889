export const exchangeType = [
  { value: 'Month Exchange Rates', label: 'Month Exchange Rates' },
  { value: 'Org Exchange Rates', label: 'Organization Exchange Rates' }
];

export const monthHeaders = [
  { title: 'Rate Id', value: 'rateId' },
  { title: 'Currency', value: 'currency' },
  { title: 'Exchange Rate', value: 'exchangeRate' },
  { title: 'Loaded Date (yyyy/mm/dd)', value: 'loadedDate' },
  { title: 'Settlement Date (yyyy/mm/dd)', value: 'settlementDate' },
  { title: 'Provider', value: 'provider' }
];
export const orgHeaders = [
  { title: 'Organization Name', value: 'organizationName' },
  { title: 'Currency', value: 'currency' },
  { title: 'Exchange Rate', value: 'exchangeRate' },
  { title: 'Loaded Date (yyyy/mm/dd)', value: 'loadedDate' },
  { title: 'Settlement Date (yyyy/mm/dd)', value: 'settlementDate' },
  { title: 'Provider', value: 'provider' }
];
export const minAppDate = new Date(
  new Date().getFullYear() - 7,
  new Date().getMonth(),
  new Date().getDate()
);
export const maxAppDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth() - 1
);
export const maxMonthDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth()
);

export const presentDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);

export const formatDateToDdMmYyyy = utcDateStr => {
  const utcDate = new Date(utcDateStr);
  const day = utcDate.getDate();
  const month = utcDate.getMonth() + 1;
  const year = utcDate.getFullYear();
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  return `${formattedDay}-${formattedMonth}-${year}`;
};
