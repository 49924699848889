import React, { Component, Fragment } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import { TextField, LinkButton } from '../../components/material';
import { landingPageText } from '../../constants/text';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { forgotUserIdSchema } from '../../constants/validations/forgotUserId';
import Success from './Success';
import { getUserIdApi } from '../../api/user';
import { ErrorContainer } from '../../components';
import { createStatusFromErrors } from '../../utils/commonFunctions';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
export class ForgotUserId extends Component {
  componentDidMount() {
    document.title = 'GlobalNet | Forgot User Id';
  }

  handleBlur = e => {
    const { handleBlur, status, setStatus } = this.props;
    e.persist();
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };

  renderForm = () => {
    return (
      <Fragment>
        <Grid
          container
          justify='space-between'
          alignItems='center'
          className='login-form-title'>
          <Typography
            data-test='forgot-userid-title'
            variant='h5'
            aria-label='forgot-userid-form forgot-userid-title'>
            {landingPageText.forgotUserIdTitle}
          </Typography>
          <Typography
            data-test='forgot-userid-text'
            variant='h6'
            aria-label='forgot-userid-form forgot-userid-text'
            style={{ marginTop: '10px' }}>
            {landingPageText.forgotUserIdText}
          </Typography>
        </Grid>
        <Grid item container direction='column'>
          <Grid item container>
            <TextField
              fullWidth
              required
              id='userEmailId'
              className='form-input'
              data-test='email-id-input'
              label={landingPageText.emailIdLabel}
              name='emailId'
              onBlur={this.handleBlur}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  render() {
    const { handleSubmit, status } = this.props;
    if (status.emailStatus) {
      return <Success requestName='User ID' />;
    }
    return (
      <>
        <form
          noValidate
          data-test='component-forgot-userid-form'
          className='login-form'
          aria-label='forgot-userid'
          onSubmit={handleSubmit}>
          <Grid container direction='column'>
            <ErrorContainer
              message={status.root}
              data-test='component-error-container'
            />
            {this.renderForm()}
            <Grid item className='login-form-actions'>
              <Grid
                container
                justify='flex-start'
                direction='row'
                align-items='center'>
                <Grid item>
                  <Button
                    color='primary'
                    size='small'
                    variant='contained'
                    type='submit'
                    data-test='forgot-userid-submit-button'
                    style={{ height: 40 }}>
                    {landingPageText.submit}
                  </Button>
                </Grid>
                <Grid item style={{ marginLeft: '15px' }}>
                  <LinkButton
                    to='/'
                    color='secondary'
                    size='small'
                    variant='outlined'
                    type='button'
                    data-test='forgot-userid-cancel-button'
                    style={{ height: 40 }}>
                    {landingPageText.cancel}
                  </LinkButton>
                </Grid>
                <Typography
                  data-test='forgot-sso-text'
                  variant='h6'
                  aria-label='forgot-userid-form forgot-userid-text'
                  className='forgot-sso-text'>
                  <InfoOutlinedIcon style={{ fontSize: '100%' }} />{' '}
                  {landingPageText.ssoPasswordText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

ForgotUserId.propTypes = {
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

const formConfig = withFormik({
  mapPropsToValues: () => ({
    emailId: ''
  }),

  mapPropsToStatus: () => ({}),

  validationSchema: forgotUserIdSchema,

  handleSubmit: (values, formikBag) => {
    getUserIdApi(values.emailId)
      .then(res => {
        if (res) {
          formikBag.setStatus({ emailStatus: true });
        }
      })
      .catch(err => {
        if (err.response.data.errorMap !== null) {
          const [errorMessage] = Object.values(err.response.data.errorMap);
          err.response.data.errorMessage = errorMessage;
        }
        formikBag.setStatus(createStatusFromErrors(err));
      });
  }
});

export default formConfig(ForgotUserId);
