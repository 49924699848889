import React, { Component } from 'react';
import { TableRow, TableCell, TableHead } from '@material-ui/core';

class TableHeader extends Component {
  render() {
    return (
      <TableHead data-test='product-line-table-header'>
        <TableRow
          className='product-line-table-header'
          data-test='product-line-table-header-row'>
          <TableCell style={{ width: '18%' }}>
            Participants/Franchise Name
          </TableCell>
          <TableCell style={{ width: '25%' }}>Screen Category</TableCell>
          <TableCell style={{ width: '15%' }}>
            Extension Date (dd/mm/yyyy)
          </TableCell>
          <TableCell style={{ width: '22%' }}>Comment (dd/mm/yyyy)</TableCell>
          <TableCell style={{ width: '5%' }}>Waiver Form</TableCell>
          <TableCell style={{ width: '15%' }}></TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

export default TableHeader;
