import React from 'react';
import { Grid, Button } from '@material-ui/core';

const AnnouncementButtons = props => {
  return (
    <Grid item md={12} className='user-search-buttons-container'>
      <Grid container justify='flex-end' alignItems='center'>
        <Button
          variant='outlined'
          type='button'
          size='small'
          color='secondary'
          data-test='announcement-button'
          style={{ marginRight: '22px' }}
          disabled={!props.dirty}
          onClick={() => {
            props.clearForm();
            if (props.mode === 'add') {
              props.imageRemoved();
            }
          }}>
          {props.clearResetLabel}
        </Button>
        <Button
          variant='contained'
          type='submit'
          size='small'
          color='primary'
          data-test='announcement-button'
          style={{ marginRight: '36px' }}
          disabled={
            props.mode === 'update'
              ? !props.dirty
              : !(props.valid && props.dirty)
          }>
          {props.addUpdateLabel}
        </Button>
      </Grid>
    </Grid>
  );
};

AnnouncementButtons.propTypes = {};
AnnouncementButtons.defaultProps = {};

export default AnnouncementButtons;
