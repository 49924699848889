import Edit from './Edit';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  getChildOrganizationsByParentOrgId,
  getExclusionTypes,
  getContractById,
  clearChildOrganization,
  clearContract,
  clearExclusionTypes
} from '../../../../store/contracts/actions';

const mapStateToProps = state => ({
  childOrganizations: state.contracts.childOrganizations,
  exclusionTypesList: state.contracts.exclusionTypesList,
  contract: state.contracts.singleContract
});

const mapDispatchToProps = {
  getChildOrganizationsByParentOrgId,
  getExclusionTypes,
  getContractById,
  clearChildOrganization,
  clearContract,
  clearExclusionTypes
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Edit));
