import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import NavigationButton from './NavigationButton';
import { HOME_TEXT } from '../../constants/text';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as Settings1Icon } from '../../assets/icons/settings-1.svg';
import { ReactComponent as Settings2Icon } from '../../assets/icons/settings-2.svg';
import { CanActivate } from '../../components';

class NavigationButtons extends Component {
  componentDidMount() {
    const { manageDataMenuActive } = this.props;
    if (manageDataMenuActive) {
      const syntheticEvent = {
        stopPropagation: () => {}
      };
      this.openMegaMenu(syntheticEvent, HOME_TEXT.manageData);
    }
  }

  openMegaMenu = (e, menu) => {
    e.stopPropagation();
    this.props.openMegaMenu(menu);
  };

  render() {
    const { admin, fullWidth, reviews, selectedMegaMenu } = this.props;
    return (
      <Grid
        container
        justify='center'
        role='navigation'
        data-test='component-navigation-buttons'>
        <Grid item md={12} style={{ textAlign: 'center', display: 'flex' }}>
          <NavigationButton
            title={HOME_TEXT.manageData}
            description={HOME_TEXT.manageDataDescription}
            Icon={UploadIcon}
            reviews={reviews}
            fullWidth={fullWidth}
            admin={admin}
            data-test='component-navigation-button'
            link='/home1'
            isSelected={selectedMegaMenu === HOME_TEXT.manageData}
            onClick={e => this.openMegaMenu(e, HOME_TEXT.manageData)}
          />

          <CanActivate
            action='admin:navigate'
            unAuthorizedView={
              <NavigationButton
                title={HOME_TEXT.businessSettings}
                description={HOME_TEXT.businessSettingsDescrioption}
                Icon={Settings1Icon}
                fullWidth={fullWidth}
                admin={admin}
                data-test='component-navigation-button'
                link='/home2'
                isSelected={selectedMegaMenu === HOME_TEXT.businessSettings}
                onClick={e => this.openMegaMenu(e, HOME_TEXT.businessSettings)}
              />
            }>
            <NavigationButton
              title={HOME_TEXT.administration}
              description={HOME_TEXT.administrationDescription}
              Icon={Settings2Icon}
              fullWidth={fullWidth}
              admin={admin}
              data-test='component-navigation-button'
              link='/home3'
              isSelected={selectedMegaMenu === HOME_TEXT.administration}
              onClick={e => this.openMegaMenu(e, HOME_TEXT.administration)}
            />
          </CanActivate>
        </Grid>
      </Grid>
    );
  }
}

NavigationButtons.propTypes = {
  fullWidth: PropTypes.bool,
  admin: PropTypes.bool,
  reviews: PropTypes.string,
  selectedMegaMenu: PropTypes.string.isRequired,
  openMegaMenu: PropTypes.func.isRequired,
  manageDataMenuActive: PropTypes.bool
};

NavigationButtons.defaultProps = {
  fullWidth: false,
  admin: false,
  reviews: '0',
  manageDataMenuActive: false
};

export default NavigationButtons;
