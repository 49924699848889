import React from 'react';
import { Button } from '@material-ui/core';
import { DATA_ENTRY_TEXT } from '../../constants/text';
import { toastFunc } from '../../components/ToastComponent/toastFunction';
const ActionButtons = props => {
  const {
    isFooterBlock,
    crossCheckHandler,
    isSubmit,
    isReject,
    clearDataHandler,
    submitHandler,
    showAutoSave,
    showCodeExists,
    showDescExists,
    showFraudExists,
    toggleSubmitFormsModal,
    isSubmitted,
    isSubmitAllow,
    downloadButton,
    userRoleMap,
    formID
  } = props;
  const getSubmitRole = () => {
    const form = formID ? formID.toString() : '';
    if (userRoleMap.D && userRoleMap.D.includes(form)) {
      return 'General Manager';
    }
    if (userRoleMap.G && userRoleMap.G.includes(form)) {
      return 'DGN';
    }
    return '';
  };
  return (
    <div
      className={`action-buttons ${isFooterBlock ? 'footer-block' : ''}`}
      data-test='component-franchise-flow-action-buttons'>
      {showAutoSave && <span className='data-auto-save'>Data Auto Saved…</span>}
      {showCodeExists &&
        toastFunc({
          content:
            'This INTES code has already been added to the table. Please add new one.',
          config: { className: 'toast-container reject' },
          toastType: 'error'
        })}
      {showDescExists && (
        <span className='intes-data-duplicate desc'>
          Same type of Fraud already exists for selected country
        </span>
      )}
      {showFraudExists && (
        <span className='intes-data-duplicate fraud'>
          This fraud type already exists, please review values entered.
        </span>
      )}
      <Button
        color='secondary'
        size='small'
        variant='outlined'
        aria-label='clear data'
        data-test='clear-data-button'
        tabindex='-1'
        disabled={isSubmitted}
        onClick={clearDataHandler}>
        {DATA_ENTRY_TEXT.clearDataText}
      </Button>
      <Button
        color='secondary'
        size='small'
        variant='outlined'
        aria-label='cross check'
        data-test='cross-check-button'
        disabled={isSubmitted}
        onClick={crossCheckHandler}>
        {DATA_ENTRY_TEXT.crossCheckText}
      </Button>
      {isReject && (
        <Button
          color='secondary'
          size='small'
          variant='outlined'
          aria-label='reject'
          data-test='reject-button'
          onClick={submitHandler}>
          {DATA_ENTRY_TEXT.reject}
        </Button>
      )}
      {formID && getSubmitRole() && isSubmit && downloadButton && (
        <Button
          color='primary'
          size='small'
          variant='contained'
          aria-label='submit'
          data-test='submit-button'
          disabled={!isSubmitAllow}
          onClick={toggleSubmitFormsModal}>
          {`${DATA_ENTRY_TEXT.submitText} to ${getSubmitRole()}`}
        </Button>
      )}
    </div>
  );
};

export default React.memo(ActionButtons);
