/* istanbul ignore file */
export default {
  announcement: 'ANNOUNCEMENT',
  importantDates: 'IMPORTANT DATES',
  manageData: 'Manage Data',
  manageDataDescription: 'Upload data from file, manually enter and review',
  businessSettings: 'Business Settings',
  businessSettingsDescrioption:
    'Manage Product Lines and Business Profile details',
  administration: 'Administration',
  administrationDescription: 'Manage users and settings',
  pendingReviews: 'PENDING REVIEWS',
  franchiseDescription1:
    'The franchises that you have access to are listed below.',
  franchiseDescription2:
    'Please take a moment to review the status of your current data. Past due screens are highlighted.',
  participantStatusNotStarted: 'Not Started',
  participantStatusDEAction: 'Awaiting Data Entry Action',
  participantStatusDGNAction: 'Awaiting General Manager Action',
  participantStatusComplete: 'Submitted to DGN',
  participantStatusGMPending: 'Total Action',
  franchiseDataTable: {
    headers: {
      participant: 'Participant',
      type: 'Type',
      exhangeRate: 'Exchange Rate',
      dataType: 'Data Type',
      status: 'Status'
    }
  },
  fromYear: new Date().getFullYear() - 6,
  toYear: new Date().getFullYear() + 1,
  monthsList: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
};
