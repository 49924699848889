import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import CircleArrowIcon from './../../assets/icons/circle-arrow.svg';
import { slugToIDs } from '../../constants/dataForms';

const FranchiseFlowNavigation = ({
  currentForm,
  decrementLink,
  incrementLink,
  updateCurrentLink,
  allForms,
  form
}) => {
  const formId = form ? form.formId : '';
  const previousPage = currentForm - 1;
  const nextPage = currentForm + 1;
  const nextPageTitle = allForms[nextPage] && allForms[nextPage].formName;
  const nextPagePath = allForms[nextPage] && allForms[nextPage].formId;
  const previousPagePath =
    allForms[previousPage] && allForms[previousPage].formId;
  const previousPageTitle =
    allForms[previousPage] && allForms[previousPage].formName;
  const currentPageTitle =
    allForms[currentForm] && allForms[currentForm].formName;
  const initialPage = currentForm + 1 === 1;
  useEffect(() => {
    const updatedLink = allForms.findIndex(form => {
      const foundElement = Object.keys(slugToIDs).find(
        k => slugToIDs[k] === slugToIDs[parseInt(formId)]
      );
      const formName = foundElement !== undefined && slugToIDs[foundElement];
      return form.formId === formName;
    });
    if (updatedLink !== currentForm) {
      updateCurrentLink(updatedLink);
    }
  }, [formId]);

  return (
    <nav className='table-nav' data-test='component-franchise-flow-nav'>
      {currentForm > 0 && (
        <div
          className='left-link'
          data-test='component-franchise-flow-left-link'>
          <Link to={previousPagePath} onClick={decrementLink}>
            <Typography variant='body1'>
              <img alt='arrow left' src={CircleArrowIcon} />
              {previousPageTitle}
            </Typography>
          </Link>
        </div>
      )}
      {currentForm !== -1 && currentForm <= allForms.length - 1 && (
        <div
          className='middle-text'
          data-test='component-franchise-flow-middle-text'>
          <div>
            <Typography variant='h6' className='dci-blue'>
              {currentPageTitle}
            </Typography>
          </div>
          <div>
            <Typography variant='body2'>
              <span className='dci-orange'>{`${currentForm + 1}`}</span>
              <span className='dci-outof'>/</span>
              {`${allForms.length}`}
            </Typography>
          </div>
        </div>
      )}
      {currentForm > 0 && currentForm < allForms.length - 1 && (
        <div
          className='right-link'
          data-test='component-franchise-flow-right-link'>
          <Link onClick={() => incrementLink(nextPagePath)}>
            <Typography variant='body1'>
              {nextPageTitle}
              <img alt='arrow right' src={CircleArrowIcon} />
            </Typography>
          </Link>
        </div>
      )}
      {initialPage && (
        <div
          className='right-link'
          data-test='component-franchise-flow-right-link-initial'>
          <Link onClick={() => incrementLink(nextPagePath)}>
            <Typography variant='body1'>
              {nextPageTitle}
              <img alt='arrow right' src={CircleArrowIcon} />
            </Typography>
          </Link>
        </div>
      )}
    </nav>
  );
};

export default FranchiseFlowNavigation;
