import React, { Component } from 'react';
import { Modal } from '..';
import { Grid, Typography } from '@material-ui/core';
import './UserImpersonateModal.css';
import PropTypes from 'prop-types';

class UserImpersonateModal extends Component {
  render() {
    const { isOpen, onCancel, onConfirm, userName, userProfileId } = this.props;
    return (
      <>
        <Modal
          title=''
          isOpen={isOpen}
          submitBtnLabel='Confirm'
          data-test='component-create-confirm-modal'
          onCancel={onCancel}
          onSubmit={() => onConfirm(userProfileId, userName)}
          onClose={onCancel}>
          <Grid
            container
            justify='center'
            className='reject-modal-container'
            direction='column'
            alignItems='center'>
            <Grid item md={8}>
              <Typography
                variant='h6'
                className='user-impersonate-modal-typography'>
                Are you sure you want to View as
              </Typography>
              <Typography
                variant='h6'
                style={{ textAlign: 'center', marginTop: '10px' }}>
                <b>Impersonation</b> of <b>{userName}</b>
              </Typography>
            </Grid>
          </Grid>
        </Modal>
      </>
    );
  }
}

UserImpersonateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
  userProfileId: PropTypes.any.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

UserImpersonateModal.defaultProps = {
  userProfileId: ''
};

export default UserImpersonateModal;
