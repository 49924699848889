import { Buffer } from 'buffer';
import {
  downloadFormTemplateApi,
  uploadFormDataApi,
  downloadLicenseTemplateApi
} from '../../api/upload';

const downloadFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName;
  anchor.click();
  window.URL.revokeObjectURL(anchor.href);
  anchor.remove();
};

export const blobToObject = (content, fileName, type) => {
  const blob = new Blob([content], type);
  downloadFile(blob, fileName);
};

export const downloadFormTemplate = (
  fileType,
  orgProfileId,
  orgReportingPeriod
) => async () => {
  const data = await downloadFormTemplateApi(
    fileType,
    orgProfileId,
    orgReportingPeriod
  );
  if (data !== undefined) {
    const content = data.content;
    // Comments console.log(Base64.atob(content))
    const extension = data.fileName.split('.').pop();
    const fileName = data.fileName;
    const decodedData = Buffer.from(content, 'base64');
    // Const decodedData = buff.toString('ascii');
    if (extension === 'txt') {
      const type = { type: 'text/plain' };
      blobToObject(decodedData, fileName, type);
    }
    if (extension === 'csv') {
      const type = { type: 'text/csv' };
      blobToObject(decodedData, fileName, type);
    }
    if (extension === 'xlsx') {
      const type = { type: 'application/vnd.ms-excel' };
      blobToObject(decodedData, fileName, type);
    }
  }
};

export const uploadFormData = fileUpload => async (_, getState) => {
  const { orgReportingPeriod } = getState().formsListNav;
  const getNestedValue = (obj, key) =>
    obj?.orgProfileId?.[key] ?? obj?.[key] ?? '';

  const orgProfileId = getNestedValue(orgReportingPeriod, 'orgProfileId');
  const reportingYear = getNestedValue(orgReportingPeriod, 'reportingYear');
  const reportingMonth = getNestedValue(orgReportingPeriod, 'reportingMonth');
  const formData = new FormData();
  formData.append('file', fileUpload);
  formData.append('month', reportingMonth);
  formData.append('orgProfileId', orgProfileId);
  formData.append('year', reportingYear);
  return new Promise((resolve, reject) => {
    uploadFormDataApi(formData)
      .then(res => {
        if (res) {
          resolve(res);
        }
      })
      .catch(err => {
        reject(err.response);
      });
  });
};

export const downloadLicenseTemplate = async () => {
  const data = await downloadLicenseTemplateApi();
  const decodedData = Buffer.from(data.content, 'base64');
  const fileName = data.fileName;
  const type = { type: 'text/pdf' };
  blobToObject(decodedData, fileName, type);
};
