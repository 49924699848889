import React from 'react';
import { Grid, Button } from '@material-ui/core';

const UserLogsButtonContainer = props => {
  const {
    handleSubmit,
    resetForm,
    isValid,
    values,
    dirty,
    setFormReset
  } = props;
  return (
    <Grid
      item
      md={12}
      className='user-search-buttons-container'
      data-test='user-search-buttons-container'>
      <Grid
        container
        justify='flex-end'
        alignItems='center'
        data-test='user-search-buttons-center-container'>
        <Button
          variant='outlined'
          type='button'
          size='small'
          color='secondary'
          className='user-search-button'
          data-test='user-clear-button'
          disabled={!dirty}
          onClick={resetForm}>
          Clear
        </Button>
        <Button
          variant='contained'
          type='submit'
          size='small'
          color='primary'
          className='user-search-button'
          data-test='user-search-button'
          disabled={
            !isValid ||
            !(
              values.orgProfileId !== '' &&
              values.startDate !== null &&
              values.endDate !== null
            )
          }
          onClick={e => {
            e.preventDefault();
            handleSubmit();
            setFormReset(true);
          }}>
          Search
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserLogsButtonContainer;
