import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';

const UserSearchButtonContainer = ({ userProfile, clearFields, onSubmit }) => {
  return (
    <Grid
      item
      md={12}
      className='user-search-buttons-container'
      data-test='user-search-buttons-container'>
      <Grid
        container
        justify='flex-end'
        alignItems='center'
        data-test='user-search-container'>
        <Button
          variant='outlined'
          type='button'
          size='small'
          color='secondary'
          className='user-search-button'
          data-test='user-clear-button'
          onClick={clearFields}>
          Clear
        </Button>
        <Button
          variant='contained'
          type='submit'
          size='small'
          color='primary'
          className='user-search-button'
          disabled={
            !userProfile.firstName &&
            !userProfile.lastName &&
            !userProfile.accountStatus &&
            !userProfile.userLoginId &&
            (!userProfile.organizationProfile ||
              (Object.entries(userProfile.organizationProfile).length === 0 &&
                userProfile.organizationProfile.constructor === Object))
          }
          data-test='user-search-button'
          onClick={onSubmit}>
          Search
        </Button>
      </Grid>
    </Grid>
  );
};

UserSearchButtonContainer.propTypes = {
  userProfile: PropTypes.object,
  clearFields: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
UserSearchButtonContainer.defaultProps = {
  userProfile: {}
};

export default UserSearchButtonContainer;
