import React, { Fragment } from 'react';
import FormRow from './FormRow';
import './IssuerFraud.css';
import { withFormik } from 'formik';
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import IssuerFraudTableInput from './IssuerFraudTableInput';
import { Modal } from '../../../components';
import * as Yup from 'yup';
import { numberCommaFormat } from '../../../utils/commonFunctions';

class IssuerFraudTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      issuerFraudDeleteModalOpen: false,
      idx: ''
    };
  }

  toggleIssuerFraudDeleteModal = (index = '') => {
    this.setState(prevState => ({
      ...prevState,
      issuerFraudDeleteModalOpen: !prevState.issuerFraudDeleteModalOpen,
      idx: index
    }));
  };

  deleteFormRowHandler = idx => {
    let form = this.props.form;
    form = {
      ...form,
      tableData: [
        {
          ...form.tableData[idx],
          operationCode: 'D'
        }
      ]
    };
    this.props.deleteIssuerFraudFormRow(idx, form);
  };

  render() {
    const {
      values,
      handleChange,
      handleSubmit,
      form,
      resetForm,
      columnHeaders,
      countriesDropdownData,
      getCountriesData,
      typeOfFraudDropdownData,
      getTypeOfFraudData,
      total
    } = this.props;
    const { issuerFraudDeleteModalOpen, idx } = this.state;
    const tableData = form && form.tableData ? form.tableData : [];

    if (!columnHeaders) {
      return 'Loading...';
    }

    return (
      <Fragment>
        <Modal
          data-test='issuerfraud-delete-modal'
          isOpen={issuerFraudDeleteModalOpen}
          submitBtnLabel='OK'
          onSubmit={() => {
            this.deleteFormRowHandler(idx);
            this.toggleIssuerFraudDeleteModal();
          }}
          onClose={this.toggleIssuerFraudDeleteModal}
          onCancel={this.toggleIssuerFraudDeleteModal}>
          <> Are you sure to delete? </>
        </Modal>
        <TableContainer className='intes-table'>
          <Table>
            <TableHead>
              <TableRow className='issuer-table-header'>
                {Object.entries(columnHeaders).map(([k, v]) =>
                  !form.formEditable && v === 'Actions' ? null : (
                    <TableCell
                      key={k}
                      className={
                        !form.formEditable && v === 'Description of Others'
                          ? `intes-tablehead col-${v} issuer-readonly`
                          : !form.formEditable && v === 'No. of Cases'
                          ? `intes-tablehead col-${v} issuer-readonly`
                          : `intes-tablehead col-${v}`
                      }
                      style={{
                        width: `${100 / Object.entries(columnHeaders).length}%`
                      }}>
                      {v}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {form.formEditable ? (
                <FormRow
                  values={values}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  handleClear={resetForm}
                  countriesDropdownData={countriesDropdownData}
                  getCountriesData={getCountriesData}
                  typeOfFraudDropdownData={typeOfFraudDropdownData}
                  getTypeOfFraudData={getTypeOfFraudData}
                />
              ) : null}

              {tableData && tableData.length > 0 ? (
                <>
                  {tableData.map((data, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography
                            className='issuer-fraud-form-fields'
                            variant='body1'>
                            <span className='code-Highlight'>
                              {data.country}
                            </span>{' '}
                            - {data.countryName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            className='issuer-fraud-form-fields'
                            variant='body1'>
                            <span className='code-Highlight'>
                              {data.typeOfFraud}
                            </span>{' '}
                            - {data.typeOfFraudName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IssuerFraudTableInput
                            fullWidth
                            field={data}
                            index={index}
                            name='description'
                          />
                        </TableCell>
                        <TableCell>
                          <IssuerFraudTableInput
                            fullWidth
                            field={data}
                            index={index}
                            name='monetaryValue'
                          />
                        </TableCell>
                        <TableCell>
                          <IssuerFraudTableInput
                            fullWidth
                            field={data}
                            index={index}
                            name='noOfCases'
                          />
                        </TableCell>
                        {form.formEditable ? (
                          <TableCell>
                            <Typography
                              variant='body1'
                              className='issuer-fraud-table-form-delete-button'>
                              <DeleteIcon
                                data-test='issuer-delete-icon'
                                onClick={() =>
                                  this.toggleIssuerFraudDeleteModal(index)
                                }
                              />
                            </Typography>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
                  {total && (
                    <TableRow className='issuer-fraud-table-form-total-row'>
                      <TableCell>
                        <Typography
                          className='issuer-fraud-table-form-total'
                          variant='body1'>
                          Total
                        </Typography>
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell>
                        <Typography
                          className={
                            form.formEditable === true
                              ? 'issuer-fraud-table-form-total total-centered'
                              : 'issuer-fraud-table-form-total'
                          }
                          variant='body1'>
                          {numberCommaFormat(total.monetaryValue)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          className={
                            form.formEditable === true
                              ? 'issuer-fraud-table-form-total total-centered'
                              : 'issuer-fraud-table-form-total'
                          }
                          variant='body1'>
                          {numberCommaFormat(total.noOfCases)}
                        </Typography>
                      </TableCell>
                      {form.formEditable === true ? <TableCell /> : null}
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan='6' style={{ textAlign: 'center' }}>
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
}
const formConfig = withFormik({
  mapPropsToValues: () => ({
    country: '',
    fraudType: '',
    description: '',
    value: '',
    casesCount: ''
  }),
  validationSchema: Yup.object().shape(
    {
      country: Yup.object().required(),
      fraudType: Yup.object().required(),
      description: Yup.mixed().when(
        ['fraudType'],
        (fraudType, schema, node) => {
          if (
            node.from &&
            node.from.length > 0 &&
            node.from[0].value.fraudType &&
            node.from[0].value.fraudType.label === 'OTHER_FRAUD_AMT'
          ) {
            return Yup.string().required();
          }
          return schema;
        }
      ),
      value: Yup.string(),
      casesCount: Yup.string()
    },
    [['fraudType', 'description']]
  ),
  mapPropsToStatus: () => ({}),
  handleSubmit: (values, formikBag) => {
    const body = {
      ...formikBag.props.form,
      tableData: [
        {
          country: values.country.value,
          countryName: values.country.label,
          typeOfFraud: values.fraudType.value,
          typeOfFraudName: values.fraudType.label,
          description: values.description,
          monetaryValue: values.value,
          noOfCases: values.casesCount,
          operationCode: 'A'
        }
      ]
    };
    formikBag.props.updateIssuersFraudForm(body);
    formikBag.resetForm();
  }
});
export { IssuerFraudTable };
export default formConfig(IssuerFraudTable);
