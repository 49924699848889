/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Chip,
  withStyles,
  Tooltip,
  Button,
  Box
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import PropTypes from 'prop-types';

import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { Checkbox } from '.';

const styles = {
  menuItem: {
    position: 'relative',
    top: 5
  }
};

export const MultipleCombobox = props => {
  const {
    name,
    limit,
    onChange,
    disabled,
    classes,
    onSelectAll,
    onUnselectAll,
    orgProductLineDataStatus,
    options
  } = props;
  const [totalOptions, setTotalOptions] = useState(0);
  const rootProps = { ...props };
  delete rootProps.onChange;
  delete rootProps.required;
  delete rootProps.error;
  delete rootProps.classes;
  delete rootProps.helperText;
  delete rootProps.onSelectAll;
  delete rootProps.onUnselectAll;

  const [clickedOnce, setClickedOnce] = useState(false);

  const inputProps = {
    error: props.error,
    required: props.required,
    label: props.label,
    helperText: props.helperText
  };

  useEffect(() => {
    setTotalOptions(options.length);
  }, []);

  useEffect(() => {
    if (options.length !== totalOptions) {
      setClickedOnce(false);
    }
  }, [options]);

  return (
    <Autocomplete
      autoComplete
      multiple
      disableCloseOnSelect
      data-test='component-MultipleCombobox'
      getOptionLabel={option => option.label}
      closeIcon={<SearchIcon />}
      clearText='Search'
      renderInput={params => (
        <TextField {...params} {...inputProps} fullWidth />
      )}
      {...rootProps}
      renderOption={(option, { selected }) => {
        if (option.id === 0) {
          return (
            <Box
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <Button
                color='secondary'
                variant='outlined'
                size='small'
                disabled={options.length === 1 || clickedOnce}
                onClick={e => {
                  setClickedOnce(true);
                  onSelectAll();
                  e.stopPropagation();
                }}>
                Select All
              </Button>
              <Button
                color='secondary'
                variant='outlined'
                size='small'
                onClick={e => {
                  setClickedOnce(false);
                  onUnselectAll();
                  e.stopPropagation();
                }}>
                Unselect All
              </Button>
            </Box>
          );
        }
        return (
          <Checkbox
            checked={selected}
            label={
              orgProductLineDataStatus
                ? `${option.label} - ${option.brandName}`
                : option.label
            }
            className={classes.menuItem}
          />
        );
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          if (index > limit) {
            return null;
          }

          if (index === limit) {
            const chipProps = { ...getTagProps({ index }) };
            delete chipProps.onDelete;
            const rest = value.slice(limit);

            return <Chip label={`${rest.length} more`} {...chipProps} />;
          }
          let text = option.label;
          if (text) {
            if (text.length > 16) {
              text = text.slice(0, 16) + '...';
            }
          }
          return (
            <Tooltip key={option.label} title={option.label} placement='top'>
              <Chip
                label={text}
                disabled={disabled}
                {...getTagProps({ index })}
              />
            </Tooltip>
          );
        })
      }
      onChange={(_, value) => {
        if (value[0]?.id !== 0) {
          const event = {
            target: {
              name,
              value
            }
          };
          onChange(event);
        }
      }}
    />
  );
};

MultipleCombobox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  limit: PropTypes.number,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  classes: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired
    })
  ),
  onChange: PropTypes.func
};

MultipleCombobox.defaultProps = {
  label: '',
  helperText: '',
  limit: 2,
  options: [],
  error: false,
  disabled: false,
  required: false,
  classes: {},
  onChange: () => {}
};

export default withStyles(styles)(MultipleCombobox);
