import React from 'react';
import { Grid, Button } from '@material-ui/core';

const AuditReportButtonContainer = props => {
  const { isValid, dirty, handleSubmit, resetForm } = props;
  return (
    <Grid
      item
      md={12}
      className='audit-report-buttons-container'
      data-test='audit-report-buttons-container'>
      <Grid
        container
        justify='flex-end'
        alignItems='center'
        data-test='audit-report-container'>
        <Button
          variant='outlined'
          type='button'
          size='small'
          color='secondary'
          className='audit-report-button'
          data-test='audit-report-button'
          onClick={resetForm}>
          Clear
        </Button>
        <Button
          variant='contained'
          type='submit'
          size='small'
          color='primary'
          className='audit-report-button'
          data-test='audit-report-button'
          disabled={!isValid || !dirty}
          onClick={handleSubmit}>
          Download
        </Button>
      </Grid>
    </Grid>
  );
};

export default AuditReportButtonContainer;
