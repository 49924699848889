/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import DataEntry from './DataEntry';
import Review from './Review';
import Upload from './Upload';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import SelectFranchiseForm from './SelectFranchiseForm';
import { RPT_PRD_PREVIOUS_MONTH_ERR } from '../../../constants/errors';
import { Loader } from '../../../components';
import { range } from '../../../utils/commonFunctions';
import { HOME_TEXT } from '../../../constants/text';

const mockReview = [
  { date: '31 DEC 2019', organization: 'CCB China' },
  { date: '31 DEC 2019', organization: 'DC AIBMS - Australia' },
  { date: '31 DEC 2019', organization: 'DC Austria' }
];

export const ManageDataMegaMenu = props => {
  const {
    values,
    handleChange,
    handleSubmit,
    onClose,
    forms,
    form,
    setFormsListInMenu,
    megaMenuOrganizationNames,
    orgParams,
    getMegaMenuOrganizationNames,
    orgReportingPeriod,
    saveOrgNameAndId,
    setDataForms,
    resetDataForms,
    isValid,
    getOrgParams,
    previousMonthStatus,
    setFormType,
    formType,
    allDataForms,
    user,
    saveSelectedForm
  } = props;
  const [reviews, setReviews] = useState([]);
  const [buttonRadioStatus, setButtonRadioStatus] = useState(false);
  const [orgYear, setOrgYear] = useState([]);
  const [months, setMonths] = useState(
    HOME_TEXT.monthsList.map((month, index) => ({
      label: month,
      value: index < 9 ? '0' + (index + 1) : (index + 1).toString()
    }))
  );

  const lastVisited = localStorage.getItem('orgReportingPeriod')
    ? JSON.parse(localStorage.getItem('orgReportingPeriod'))
    : { orgProfileId: '', reportingMonth: '', reportingYear: '' };

  const [orgDefaultId, setOrgDefaultId] = useState('');
  const { formDefaultSubmissionYear, formDefaultSubmissionMonth } = orgParams;

  const lastSubmitYear =
    lastVisited &&
    lastVisited.reportingYear !== '' &&
    lastVisited.reportingYear !== 'undefined'
      ? lastVisited.reportingYear
      : formDefaultSubmissionYear;

  const lastSubmitMonth =
    lastVisited.reportingMonth !== '' &&
    lastVisited.reportingMonth !== 'undefined'
      ? lastVisited.reportingMonth
      : formDefaultSubmissionMonth;

  const formSubmissionStatus =
    forms.length > 0 && (!forms[0].formEditable || forms[0].isUserSubmitted)
      ? RPT_PRD_PREVIOUS_MONTH_ERR
      : previousMonthStatus;

  const manageDataFormLoad =
    lastSubmitYear !== undefined &&
    lastSubmitYear !== '' &&
    lastSubmitMonth !== undefined &&
    lastSubmitMonth !== '';

  useEffect(() => {
    const fetchData = async () => {
      await getMegaMenuOrganizationNames();

      const defaultOrgId =
        lastVisited.orgProfileId ||
        (megaMenuOrganizationNames.length > 0
          ? Number(Object.values(megaMenuOrganizationNames[0])[0])
          : '');

      setOrgDefaultId(defaultOrgId);

      if (defaultOrgId) {
        getOrgParams(defaultOrgId);

        if (manageDataFormLoad) {
          const data = {
            orgProfileId: defaultOrgId,
            reportingMonth: lastSubmitMonth,
            reportingYear: lastSubmitYear
          };
          const selectedOrganization = megaMenuOrganizationNames.find(
            org => org.id == data.orgProfileId
          );
          const selectedData = {
            ...data,
            selectedOrganization: selectedOrganization?.label
          };
          saveSelectedForm(selectedData);
          setFormsListInMenu(data);
          saveOrgNameAndId(selectedOrganization?.label, data);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const minAppDate = new Date(
      new Date().getFullYear() - 7,
      new Date().getMonth(),
      new Date().getDate()
    );
    const maxAppDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth()
    );
    const years = range(
      minAppDate.getFullYear() + 1,
      maxAppDate.getFullYear() + 1
    ).map(year => ({
      label: year,
      value: year
    }));

    setOrgYear(
      parseInt(values.month, 10) > new Date().getMonth()
        ? range(
            minAppDate.getFullYear() + 1,
            maxAppDate.getFullYear() + 1
          ).map(year => ({ label: year, value: year }))
        : years
    );
  }, [values.month, values.year]);

  const saveOrgLabelAndId = orgId => {
    const selectedOrganization = megaMenuOrganizationNames.find(
      org => org.id == orgId
    );
    saveOrgNameAndId(selectedOrganization?.label, orgId);
  };

  const handleOrgChange = e => {
    const orgId = e.target.value;
    setOrgDefaultId(orgId);
    handleChange(e);
    saveOrgLabelAndId(orgId);
    localStorage.setItem('orgProfileId', orgId);
  };

  const handleRadioChange = e => {
    setFormType(e.target.value);
    setButtonRadioStatus(e.target.value !== 'monthlyDataForms');
    localStorage.setItem('formType', e.target.value);
  };

  const onSubmit = e => {
    e.preventDefault();
    handleSubmit(e);
    const selectedOrganization = megaMenuOrganizationNames.find(
      org => org.id == values.organization
    );
    const selectedData = {
      ...values,
      selectedOrganization: selectedOrganization?.label
    };
    saveSelectedForm(selectedData);
    saveOrgLabelAndId(values.organization);
  };

  if (!orgDefaultId) {
    return (
      <Grid
        container
        item
        justify='center'
        alignItems='center'
        data-test='component-franchise'
        data-testid='component-se-sales-volume-loader'>
        <Loader data-test='component-loader' />
      </Grid>
    );
  }

  return (
    <>
      <Grid item>
        <div className='manage-data-positioning'>
          <SelectFranchiseForm
            data-test='select-franchise-form'
            handleSubmit={onSubmit}
            handleChange={handleChange}
            values={values}
            isValid={isValid}
            megaMenuOrganizationNames={megaMenuOrganizationNames}
            saveOrgNameAndId={saveOrgNameAndId}
            orgReportingPeriod={orgReportingPeriod}
            formType={formType}
            entryType='manual'
            setFormType={setFormType}
            allDataForms={allDataForms}
            previousMonthStatus={formSubmissionStatus}
            setButtonRadioStatus={setButtonRadioStatus}
            saveSelectedForm={saveSelectedForm}
            orgYear={orgYear}
            months={months}
            isRadioDisable={RPT_PRD_PREVIOUS_MONTH_ERR === previousMonthStatus}
            handleOrgChange={handleOrgChange}
            onRadioChange={handleRadioChange}
          />
        </div>
      </Grid>
      <Grid item md={3} className='padding'>
        <Upload
          data-test='component-upload'
          userRole={user.highestRole}
          buttonRadioStatus={buttonRadioStatus}
          previousMonthStatus={formSubmissionStatus}
          forms={forms}
          userRoleMap={user.userRoleMap}
          orgProfileId={orgReportingPeriod.orgProfileId}
          onClose={onClose}
        />
      </Grid>
      <Grid item md className='padding'>
        <DataEntry
          data-test='component-data-entry'
          forms={forms}
          form={form}
          setFormsListInMenu={setFormsListInMenu}
          megaMenuOrganizationNames={megaMenuOrganizationNames}
          orgParams={orgParams}
          setReviews={() => setReviews(mockReview)}
          orgReportingPeriod={orgReportingPeriod}
          saveOrgNameAndId={saveOrgNameAndId}
          setDataForms={setDataForms}
          resetDataForms={resetDataForms}
          formType={formType}
          onClose={onClose}
        />
      </Grid>
      {reviews.length ? (
        <Grid item md={3} className='padding'>
          <Review
            data-test='component-review'
            reviews={reviews}
            onClose={onClose}
          />
        </Grid>
      ) : null}
    </>
  );
};

const formConfig = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({
    orgReportingPeriod,
    megaMenuOrganizationNames,
    orgParams
  }) => {
    const hasOrgReportingPeriod = Boolean(
      orgReportingPeriod && orgReportingPeriod.orgProfileId
    );

    const getOrgProfileId = () => {
      return (
        orgReportingPeriod?.orgProfileId?.orgProfileId ??
        orgReportingPeriod?.orgProfileId ??
        ''
      );
    };

    const getReportingYear = () => {
      return (
        orgReportingPeriod?.orgProfileId?.reportingYear ??
        orgReportingPeriod?.reportingYear
      );
    };

    const getReportingMonth = () => {
      return (
        orgReportingPeriod?.orgProfileId?.reportingMonth ??
        orgReportingPeriod?.reportingMonth
      );
    };

    const defaultYear =
      megaMenuOrganizationNames.length && orgParams
        ? orgParams.formDefaultSubmissionYear
        : '';

    const defaultMonth =
      megaMenuOrganizationNames.length && orgParams
        ? orgParams.formDefaultSubmissionMonth
        : '';

    return {
      year: hasOrgReportingPeriod
        ? getReportingYear() || defaultYear
        : defaultYear,
      month: hasOrgReportingPeriod
        ? getReportingMonth() || defaultMonth
        : defaultMonth,
      organization: hasOrgReportingPeriod
        ? getOrgProfileId()
        : megaMenuOrganizationNames.length
        ? Number(Object.values(megaMenuOrganizationNames[0])[0])
        : ''
    };
  },
  validationSchema: Yup.object().shape({
    organization: Yup.string().required('Organization is required'),
    year: Yup.string().required('Year is required'),
    month: Yup.string().required('Month is required')
  }),
  isInitialValid: false,
  handleSubmit: (values, { props }) => {
    const data = {
      orgProfileId: values.organization,
      reportingMonth: values.month,
      reportingYear: values.year
    };
    props.setFormsListInMenu(data);
  }
});

export default formConfig(ManageDataMegaMenu);
