import React from 'react';
import './CheckIcon.css';
import PropTypes from 'prop-types';
import { ReactComponent as SvgIcon } from '../../../assets/icons/check-slim.svg';
import { Grid } from '@material-ui/core';

const CheckIcon = ({ onClickIcon }) => {
  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      className='check-icon-container'
      data-test='check-icon-component'
      onClick={onClickIcon}>
      <Grid item>
        <SvgIcon className='check-icon' />
      </Grid>
    </Grid>
  );
};

CheckIcon.propTypes = {
  onClickIcon: PropTypes.func.isRequired
};

export default CheckIcon;
