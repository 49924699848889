import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SearchForm from './SearchForm';
import SearchResultTable from './SearchResultTable';
import { PageSkeleton, Loader, CanActivate } from '../../../../components';
import { getAdminOrganizationNames } from '../../../../store/admin/actions';
import {
  getOrganizationSearchList,
  getSearchedOrganizationProfile,
  getTotalOrganizationRegions,
  getTotalOrganizationCountries,
  getTotalOrganizationTypes,
  getClearOrganizationSearchList
} from '../../../../store/organizations/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Search.css';
import { Grid } from '@material-ui/core';

export class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      isFormSubmitted: false,
      defaultData: false,
      controller: {
        pageNumber: 0,
        rowsPerPage: 5
      }
    };
  }

  setClearButton = () => {
    this.setSubmitted(false);
    this.props.getClearOrganizationSearchList();
  };

  setSubmitted = val => {
    this.setState({ isFormSubmitted: val });
  };

  async componentDidMount() {
    document.title = 'GlobalNet | Organization Search';
    await this.props.getAdminOrganizationNames();
    await this.props.getTotalOrganizationRegions();
    await this.props.getTotalOrganizationCountries();
    await this.props.getTotalOrganizationTypes();
    await this.setState({ showLoader: false });
    const { searchedOrganization } = this.props;
    if (
      searchedOrganization !== undefined &&
      Object.keys(searchedOrganization).length > 0 &&
      this.props.history?.location &&
      this.props.history?.location.state !== undefined &&
      this.props.history?.location.state.formSearched
    ) {
      const orgData = {
        organizationProfileId:
          searchedOrganization.organizationName &&
          searchedOrganization.organizationName.id
            ? searchedOrganization.organizationName.id
            : null,
        regionTypeCode: searchedOrganization.organizationRegion
          ? searchedOrganization.organizationRegion
          : null,
        countryIsoCode:
          searchedOrganization.organizationTerritory &&
          searchedOrganization.organizationTerritory.value
            ? searchedOrganization.organizationTerritory.value
            : null,
        orgTypCde: searchedOrganization.organizationType
          ? searchedOrganization.organizationType
          : null
      };
      const pageInfo = {
        pageNumber: this.state.controller.pageNumber,
        rowsPerPage: this.state.controller.rowsPerPage
      };
      this.props.getOrganizationSearchList({
        organizationalSearchVO: orgData,
        paginationInfo: pageInfo
      });
      this.setState({ isFormSubmitted: true });
    }
    if (!this.props?.history?.location) {
      this.setState({ defaultData: true });
    }
  }

  searchForBusinessProfUpdate = async () => {
    const { controller } = this.state;
    const { organizations } = this.props;
    const { searchedOrganization } = organizations;

    const orgData = {
      organizationProfileId:
        searchedOrganization.organizationName &&
        searchedOrganization.organizationName.id
          ? searchedOrganization.organizationName.id
          : null,
      regionTypeCode: searchedOrganization.organizationRegion
        ? searchedOrganization.organizationRegion
        : null,
      countryIsoCode:
        searchedOrganization.organizationTerritory &&
        searchedOrganization.organizationTerritory.value
          ? searchedOrganization.organizationTerritory.value
          : null,
      orgTypCde: searchedOrganization.organizationType
        ? searchedOrganization.organizationType
        : null
    };
    const pageInfo = {
      pageNumber: controller.pageNumber,
      rowsPerPage: controller.rowsPerPage
    };
    this.setState({ isFormSubmitted: true });
    await this.props.getOrganizationSearchList({
      organizationalSearchVO: orgData,
      paginationInfo: pageInfo
    });
  };

  handlePageChange = async (event, newPage) => {
    const { paginationInfo } = this.props.organizations.organizationSearchList;
    await this.setState({
      controller: {
        rowsPerPage: paginationInfo.rowsPerPage,
        pageNumber: newPage
      }
    });
    this.searchForBusinessProfUpdate();
  };

  handleChangeRowsPerPage = async event => {
    await this.setState({
      controller: {
        rowsPerPage: parseInt(event.target.value, 10),
        pageNumber: 0
      }
    });
    this.searchForBusinessProfUpdate();
  };

  render() {
    const {
      getOrganizationSearchList,
      getSearchedOrganizationProfile,
      adminOrganizationNames,
      organizations,
      searchedOrganization
    } = this.props;
    const { defaultData } = this.state;
    if (this.state.showLoader === true) {
      return (
        <Grid
          container
          item
          justify='center'
          alignItems='center'
          data-test='profile-search-loader-container'>
          <Loader data-test='component-loader' />
        </Grid>
      );
    }
    return (
      <CanActivate
        action='org-profile:search'
        unAuthorizedView={
          <PageSkeleton title='Organization Profile Search'>
            <div className='search-bprofile-container'>
              <SearchForm
                data-test='search-bprofile-form'
                organizations={adminOrganizationNames}
                defaultData={defaultData}
                regions={organizations.totalOrganizationalRegions}
                setSubmitted={this.setSubmitted}
                countries={organizations.totalOrganizationalCountries}
                types={organizations.totalOrganizationalTypes}
                getOrganizationSearchList={getOrganizationSearchList}
                getSearchedOrganizationProfile={getSearchedOrganizationProfile}
                clearButton={this.setClearButton}
                searchedOrganization={searchedOrganization}
                isFormSubmitted={this.state.isFormSubmitted}
              />
            </div>
            {organizations.organizationIsSearching === true &&
              this.state.isFormSubmitted === true && (
                <Grid
                  container
                  item
                  justify='center'
                  alignItems='center'
                  data-test='profile-search-loader-container'>
                  <Loader data-test='component-loader' />
                </Grid>
              )}
            {this.state.isFormSubmitted && (
              <SearchResultTable
                organizations={organizations.organizationSearchList}
                handlePageChange={this.handlePageChange}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            )}
          </PageSkeleton>
        }>
        <CanActivate
          action='org-profile:create'
          unAuthorizedView={
            <PageSkeleton title='Organization Profile Search'>
              <div className='search-bprofile-container'>
                <SearchForm
                  defaultData={defaultData}
                  data-test='search-bprofile-form'
                  organizations={adminOrganizationNames}
                  regions={organizations.totalOrganizationalRegions}
                  setSubmitted={this.setSubmitted}
                  countries={organizations.totalOrganizationalCountries}
                  types={organizations.totalOrganizationalTypes}
                  getOrganizationSearchList={getOrganizationSearchList}
                  getSearchedOrganizationProfile={
                    getSearchedOrganizationProfile
                  }
                  clearButton={this.setClearButton}
                  searchedOrganization={searchedOrganization}
                  isFormSubmitted={this.state.isFormSubmitted}
                />
              </div>
              {this.state.isFormSubmitted && (
                <SearchResultTable
                  organizations={organizations.organizationSearchList}
                  handlePageChange={this.handlePageChange}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              )}
            </PageSkeleton>
          }>
          <PageSkeleton
            title='Organization Profile Search'
            primaryButton={{
              label: 'Add Business profile',
              onClick: () => {
                this.props.history.push('/admin/organization/create');
              }
            }}>
            <div className='search-bprofile-container'>
              <SearchForm
                defaultData={defaultData}
                data-test='search-bprofile-form'
                organizations={adminOrganizationNames}
                regions={organizations.totalOrganizationalRegions}
                setSubmitted={this.setSubmitted}
                countries={organizations.totalOrganizationalCountries}
                types={organizations.totalOrganizationalTypes}
                getOrganizationSearchList={getOrganizationSearchList}
                getSearchedOrganizationProfile={getSearchedOrganizationProfile}
                clearButton={this.setClearButton}
                searchedOrganization={searchedOrganization}
                isFormSubmitted={this.state.isFormSubmitted}
              />
            </div>
            {this.state.isFormSubmitted && (
              <SearchResultTable
                data-test='search-result-table'
                organizations={organizations.organizationSearchList}
                handlePageChange={this.handlePageChange}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            )}
          </PageSkeleton>
        </CanActivate>
      </CanActivate>
    );
  }
}

Search.propTypes = {
  getAdminOrganizationNames: PropTypes.func.isRequired,
  getOrganizationSearchList: PropTypes.func.isRequired,
  getTotalOrganizationRegions: PropTypes.func.isRequired,
  getTotalOrganizationTypes: PropTypes.func.isRequired,
  getTotalOrganizationCountries: PropTypes.func.isRequired,
  adminOrganizationNames: PropTypes.array.isRequired,
  organizations: PropTypes.object.isRequired,
  searchedOrganization: PropTypes.object,
  getSearchedOrganizationProfile: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  getAdminOrganizationNames,
  getTotalOrganizationRegions,
  getTotalOrganizationCountries,
  getTotalOrganizationTypes,
  getOrganizationSearchList,
  getSearchedOrganizationProfile,
  getClearOrganizationSearchList
};

const mapStateToProps = state => ({
  adminOrganizationNames: state.admin.totalAdminOrganizationalNames,
  organizations: state.organizations,
  searchedOrganization: state.organizations.searchedOrganization,
  userPageInfo: state.admin.businessPageInfo
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
