/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Typography, Grid } from '@material-ui/core';
import FormContainer from './FormContainer';
import ProfileRole from './ProfileRole';
import UpdatePassword from './UpdatePassword';
import { connect } from 'react-redux';
import { setSettingsMenuActive } from '../../store/ui';
import PropTypes from 'prop-types';
import { fetchUserProfile } from '../../store/user';
import { Loader } from '../../components';
import './Profile.css';

export class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        userId: '',
        firstName: '',
        middleName: '',
        lastName: '',
        emailId: '',
        jobTitle: '',
        salutation: 'none'
      },
      passwordData: {
        showPasswordSection: false
      },
      impersonate: ''
    };
    document.title = 'GlobalNet | My Profile';
  }

  componentDidMount = async () => {
    try {
      // This.props.setSettingsMenuActive();
      if (this.props.user.usrProfId !== undefined) {
        this.props.fetchUserProfile(this.props.user.usrProfId);
      }
      const impersonateData = localStorage.getItem('impersonateUserData')
        ? JSON.parse(localStorage.getItem('impersonateUserData'))
        : {};
      this.setState({
        impersonate: Boolean(Object.keys(impersonateData).length > 0)
      });
    } catch (e) {}
  };

  componentDidUpdate(prevProps) {
    try {
      if (
        this.props.user.usrProfId !== undefined &&
        prevProps.user.usrProfId !== this.props.user.usrProfId
      ) {
        this.props.fetchUserProfile(this.props.user.usrProfId);
      }
    } catch (e) {}
  }

  togglePasswordSection = () => {
    this.setState(prevState => {
      return {
        passwordData: {
          showPasswordSection: !prevState.passwordData.showPasswordSection
        }
      };
    });
  };

  render() {
    const { form, passwordData, impersonate } = this.state;
    const { user, edit, myProfileError, myProfileErrorMsg } = this.props;
    const roles =
      user.orgRoleProfiles && user.orgRoleProfiles.length !== 0
        ? user.orgRoleProfiles
        : [];
    if (!user.userLoginId) {
      return (
        <Grid
          container
          item
          justify='center'
          alignItems='center'
          data-test='profile-loader-container'>
          <Loader data-test='component-loader' />
        </Grid>
      );
    }
    return (
      <div className='common-profile-container' data-test='profile-component'>
        <div aria-label='My Profile' className='head-text'>
          <Typography variant='h6' className='dci-blue'>
            My Profile
          </Typography>
        </div>
        <div className='common-profile-data-container'>
          <div className='border-div'>
            <FormContainer
              edit={edit}
              user={user}
              form={form}
              impersonate={impersonate}
            />
            {roles.length !== 0 && (
              <ProfileRole roles={roles} impersonate={impersonate} />
            )}
            <UpdatePassword
              toggleDiv={this.togglePasswordSection}
              passwordData={passwordData}
              edit={edit}
              updateInfo={{
                timeStamp: user.passwordLastUpdatedTimeStamp,
                updatedBy: user.usrFullName
              }}
              myProfileError={myProfileError}
              myProfileErrorMsg={myProfileErrorMsg}
              impersonate={impersonate}
            />
          </div>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  edit: PropTypes.bool,
  setSettingsMenuActive: PropTypes.func.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  myProfileError: PropTypes.bool
};
Profile.defaultProps = {
  edit: true,
  myProfileError: false
};
const mapStateToProps = state => {
  return {
    user: state.user,
    myProfileError: state.errors.myProfileError,
    myProfileErrorMsg: state.errors.myProfileFormError
  };
};
const mapDispatchToProps = {
  setSettingsMenuActive,
  fetchUserProfile: profileId => fetchUserProfile(profileId)
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
