import React from 'react';
import { connect } from 'formik';
import { InputAdornment } from '@material-ui/core';
import { Dropdown as Input } from '../material';
import ErrorTooltip from './ErrorTooltip';
import './formik.css';

import PropTypes from 'prop-types';

export const Dropdown = props => {
  const fieldProps = { ...props };
  delete fieldProps.formik;
  const {
    name,
    formik: { values, handleChange, handleBlur, touched, errors, status }
  } = props;

  return (
    <Input
      data-test='component-DropdownGrid'
      value={values[name]}
      error={
        touched[name] && Boolean(errors[name] || status[name] || status.root)
      }
      InputProps={{
        endAdornment: (
          <InputAdornment position='end' style={{ marginRight: 7 }}>
            <ErrorTooltip
              message={touched[name] && (errors[name] || status[name])}
            />
          </InputAdornment>
        )
      }}
      onChange={handleChange}
      onBlur={handleBlur}
      {...fieldProps}
    />
  );
};

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired
};

export default connect(Dropdown);
