import {
  SEARCH_ORGANIZATION_START,
  SEARCH_ORGANIZATION_SUCCESS,
  SEARCH_ORGANIZATION_ERROR,
  GET_TOTAL_ORGANIZATION_REGIONS,
  GET_TOTAL_ORGANIZATION_COUNTRIES,
  GET_TOTAL_ORGANIZATION_TYPES,
  GET_TOTAL_CURRENCIES,
  GET_TOTAL_PRODUCTS,
  GET_ORGANIZATION,
  CLEAR_ORGANIZATION,
  GET_SEARCHED_ORGANIZATION_PROFILE,
  GET_TOTAL_EXCHANGE_REQUEST
} from './types';

import {
  searchOrganizationsApi,
  getTotalOrganizationTypesApi,
  getTotalOrganizationRegionsApi,
  getTotalOrganizationCountriesApi,
  getTotalCurrenciesApi,
  getProductsApi,
  getAllProductsApi,
  addProductLineApi,
  getOrganizationProfileApi,
  extensionRequestCreateApi,
  extensionRequestsFetchAllApi,
  extensionRequestUpdateApi
} from '../../api/organizations';

export const setSearchOrganizationStart = () => ({
  type: SEARCH_ORGANIZATION_START
});

export const setSearchOrganizationError = () => ({
  type: SEARCH_ORGANIZATION_ERROR
});

export const getOrganizationSearchList = data => async dispatch => {
  try {
    dispatch(setSearchOrganizationStart());
    const payload = await searchOrganizationsApi(data);
    dispatch({
      type: SEARCH_ORGANIZATION_SUCCESS,
      payload
    });
  } catch (err) {
    dispatch(setSearchOrganizationError());
  }
};

export const getSearchedOrganizationProfile = orgInfo => async dispatch => {
  try {
    dispatch({
      type: GET_SEARCHED_ORGANIZATION_PROFILE,
      payload: orgInfo
    });
  } catch (err) {}
};

export const getTotalOrganizationRegions = () => async dispatch => {
  try {
    let payload = await getTotalOrganizationRegionsApi();
    if (payload !== undefined && payload !== null) {
      payload = payload.map(({ regionTypeCode, regionTypeDescription }) => ({
        id: regionTypeCode,
        label: regionTypeDescription,
        value: regionTypeCode
      }));
    } else {
      payload = [];
    }

    dispatch({
      type: GET_TOTAL_ORGANIZATION_REGIONS,
      payload
    });
  } catch (err) {}
};

export const getTotalOrganizationCountries = () => async dispatch => {
  try {
    let payload = await getTotalOrganizationCountriesApi();
    if (payload !== undefined && payload !== null) {
      payload = payload.map(
        ({ countryIsoCode, countryIsoName, countryIsoNumber }) => ({
          id: countryIsoNumber,
          label: countryIsoName,
          value: countryIsoCode
        })
      );
      payload.sort((c1, c2) => (c1.label < c2.label ? -1 : 1));
    } else {
      payload = [];
    }

    dispatch({
      type: GET_TOTAL_ORGANIZATION_COUNTRIES,
      payload
    });
  } catch (err) {}
};

export const getTotalOrganizationTypes = () => async dispatch => {
  try {
    let payload = await getTotalOrganizationTypesApi();
    if (payload !== undefined && payload !== null) {
      payload = payload.map(({ orgTypCde, orgTypNm, orgTypVal }) => ({
        id: orgTypCde,
        label: orgTypNm,
        value: orgTypVal
      }));
    } else {
      payload = [];
    }
    dispatch({
      type: GET_TOTAL_ORGANIZATION_TYPES,
      payload
    });
  } catch (err) {}
};

export const getTotalCurrencies = () => async dispatch => {
  try {
    let payload = await getTotalCurrenciesApi();
    if (payload !== undefined && payload !== null) {
      payload = payload.map(({ currencyTypeCode, currencyName }) => ({
        id: currencyTypeCode,
        label: currencyName,
        value: currencyTypeCode
      }));
    } else {
      payload = [];
    }

    dispatch({
      type: GET_TOTAL_CURRENCIES,
      payload
    });
  } catch (err) {}
};

export const getTotalProducts = () => async dispatch => {
  try {
    let commercial = await getProductsApi('CM');
    if (commercial !== undefined && commercial !== null) {
      commercial = commercial.map(
        ({ cardProductBandMapId, cardProductName, cardBrandName }) => ({
          id: cardProductBandMapId,
          label: cardProductName,
          value: cardProductBandMapId,
          brandName: cardBrandName
        })
      );
      commercial.unshift({ id: 0, label: '', value: '', brandName: '' });
    } else {
      commercial = [];
    }
    let consumer = await getProductsApi('CN');
    if (consumer !== undefined && consumer !== null) {
      consumer = consumer.map(
        ({ cardProductBandMapId, cardProductName, cardBrandName }) => ({
          id: cardProductBandMapId,
          label: cardProductName,
          value: cardProductBandMapId,
          brandName: cardBrandName
        })
      );
      consumer.unshift({ id: 0, label: '', value: '', brandName: '' });
    } else {
      consumer = [];
    }
    dispatch({
      type: GET_TOTAL_PRODUCTS,
      payload: {
        commercial,
        consumer
      }
    });
  } catch (err) {}
};

export const getAllProducts = () => async dispatch => {
  try {
    const allProducts = await getAllProductsApi();
    const commercial = allProducts.filter(
      product => product.cardBusinessCode === 'CM'
    );
    const consumer = allProducts.filter(
      product => product.cardBusinessCode === 'CN'
    );
    dispatch({
      type: GET_TOTAL_PRODUCTS,
      payload: {
        commercial,
        consumer
      }
    });
  } catch (err) {}
};
export const createProducts = addedProductLine => async dispatch => {
  try {
    return await addProductLineApi(addedProductLine);
  } catch (err) {}
};

export const getOrganizationProfile = organizationProfileId => async dispatch => {
  try {
    const organization = await getOrganizationProfileApi(organizationProfileId);
    dispatch({ type: GET_ORGANIZATION, payload: organization });
  } catch (e) {
    console.log(e.message); // eslint-disable-line no-console
  }
};

export const clearOrganizationProfile = () => ({
  type: CLEAR_ORGANIZATION
});

export const getClearOrganizationSearchList = () => dispatch => {
  dispatch({
    type: SEARCH_ORGANIZATION_SUCCESS,
    payload: []
  });
};

export const getAllExchangeRequest = () => async dispatch => {
  try {
    const allExchangeRequest = await extensionRequestsFetchAllApi();

    dispatch({
      type: GET_TOTAL_EXCHANGE_REQUEST,
      payload: allExchangeRequest
    });
  } catch (err) {}
};
export const createExtensionRequest = data => async dispatch => {
  try {
    return await extensionRequestCreateApi(data);
  } catch (err) {}
};
export const extensionRequestUpdate = data => async dispatch => {
  try {
    return await extensionRequestUpdateApi(data);
  } catch (err) {}
};
