import React, { Component, Fragment } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import { TextField } from '../../components/material';
import { withFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { landingPageText } from '../../constants/text';
import { logoutUser, resetChallenge, otp } from '../../store/auth/actions';
import { createStatusFromErrors } from '../../utils/commonFunctions';
import { validateOTPApi } from '../../api/auth';
import { otpSchema } from '../../constants/validations/otp';
import { ErrorContainer } from '../../components';

export class OtpPage extends Component {
  componentDidMount() {
    document.title = 'GlobalNet | Otp';
  }

  handleBlur = e => {
    const { handleBlur, status, setStatus } = this.props;
    e.persist();
    setStatus({ ...status, root: '', [e.target.name]: '' });
    handleBlur(e);
  };

  renderForm = () => {
    return (
      <Fragment>
        <Grid item container className='login-form-title' direction='column'>
          <Typography
            data-test='otp-form-title'
            variant='h5'
            aria-label='otp-form otp-form-title'>
            {landingPageText.otpTitle}
          </Typography>
          <Typography
            data-test='otp-form-text'
            variant='h6'
            aria-label='otp-form otp-form-text'
            style={{ marginTop: '10px', color: '#63666B' }}>
            {landingPageText.otpText}
          </Typography>
        </Grid>
        <Grid item container>
          <Grid item container>
            <TextField
              fullWidth
              required
              id='userOtp'
              className='form-input'
              data-test='otp-pin-input'
              label={landingPageText.otpLabel}
              name='otpPin'
              onBlur={this.handleBlur}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  render() {
    const { handleSubmit, logoutUser, status } = this.props;
    return (
      <form
        noValidate
        data-test='component-otp-form'
        aria-label='otp-pin'
        className='otp-form'
        onSubmit={handleSubmit}>
        <Grid container direction='column'>
          <ErrorContainer
            message={status.root}
            data-test='component-error-container'
          />
          {this.renderForm()}
          <Grid item className='login-form-actions'>
            <Grid container justify='flex-start'>
              <Grid item>
                <Button
                  color='primary'
                  size='small'
                  variant='contained'
                  type='submit'
                  data-test='otp-pin-submit-button'
                  style={{ height: 40 }}>
                  {landingPageText.submit}
                </Button>
              </Grid>
              <Grid item style={{ marginLeft: '15px' }}>
                <Button
                  color='secondary'
                  size='small'
                  variant='outlined'
                  type='button'
                  data-test='otp-pin-cancel-button'
                  style={{ height: 40 }}
                  onClick={logoutUser}>
                  {landingPageText.cancel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const formConfig = withFormik({
  mapPropsToValues: () => ({
    otpPin: ''
  }),

  mapPropsToStatus: () => ({}),

  validationSchema: otpSchema,

  handleSubmit: (values, formikBag) => {
    const user = formikBag.props.user;
    const userOTP = {
      otpPin: values.otpPin,
      usrProfId: user.usrProfId,
      ddidRequestId: user.ddidRequestId
    };
    validateOTPApi(userOTP)
      .then(res => {
        formikBag.props.otp(res.data);
      })
      .catch(err => {
        formikBag.setStatus(createStatusFromErrors(err));
      });
  }
});

const mapStateToProps = state => ({
  error: state.errors.formErrors,
  user: state.user
});

const mapDispatchToProps = {
  logoutUser,
  resetChallenge,
  otp
};

export const OtpPageWithFormik = formConfig(OtpPage);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OtpPageWithFormik)
);
