import * as Yup from 'yup';
import errors from '../../fieldErrors';
const {
  enteredAnnouncementTitle,
  announcementDesc,
  announcementStartDate,
  announcementEndDate
} = errors;

const todaysDate = new Date();
todaysDate.setHours(0, 0, 0, 0);
export const announcementValidationSchema = () => {
  return Yup.lazy(values => {
    return Yup.object().shape({
      announcementTitle: Yup.string()
        .required(enteredAnnouncementTitle.empty)
        .min(1, enteredAnnouncementTitle.length)
        .max(4000, enteredAnnouncementTitle.length)
        .matches(/^[0-9a-zA-Z,.*%$#:()?^\-_'\s]+$/, {
          message: enteredAnnouncementTitle.invalid,
          excludeEmptyString: true
        }),
      announcementDescription: Yup.string()
        .required(announcementDesc.empty)
        .min(1, announcementDesc.length)
        .max(4000, announcementDesc.length)
        .matches(/^[0-9a-zA-Z,.*%$#:()?^\-_'\s]+$/, {
          message: announcementDesc.invalid,
          excludeEmptyString: true
        }),
      announcementStartDate: Yup.date().when('scheduled', {
        is: () => {
          return Boolean(values.scheduled.length) === true;
        },
        then: Yup.date()
          .min(todaysDate, announcementStartDate.invalid)
          .max(Yup.ref('announcementEndDate'), announcementStartDate.invalid)
      }),
      announcementEndDate: Yup.date().when('scheduled', {
        is: () => {
          return Boolean(values.scheduled.length) === true;
        },
        then: () => {
          return Yup.date().min(
            Yup.ref('announcementStartDate'),
            announcementEndDate.invalid
          );
        }
      })
    });
  });
};
