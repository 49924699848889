import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PageError from '.';
import { Header, Footer } from '../../components';
import PropTypes from 'prop-types';
import { noHeaderPath } from '../../constants/paths';

let locationKey = '';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hasError && this.state.hasError !== prevState.hasError) {
      locationKey = this.props.location.key;
    }
    if (
      this.state.hasError &&
      locationKey !== '' &&
      locationKey !== this.props.location.key
    ) {
      locationKey = '';
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      if (noHeaderPath.includes(this.props.location.pathname)) {
        return (
          <>
            <Header />
            <PageError />
            <Footer hasError={this.state.hasError} />
          </>
        );
      }
      return <PageError />;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(ErrorBoundary);
