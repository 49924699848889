import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import { ReactComponent as BackButtonIcon } from '../../../../assets/icons/back-icon.svg';
import { PageSkeleton, Loader, CanActivate } from '../../../../components';
import { Link } from 'react-router-dom';
import EditOrganization from './EditOrganization';

import '../Organization.css';

class Edit extends React.Component {
  state = {
    formSearched: false
  };

  componentDidMount() {
    const {
      getTotalOrganizationCountries,
      getTotalOrganizationRegions,
      getTotalOrganizationTypes,
      getTotalCurrencies,
      getTotalProducts,
      countries,
      products,
      orgTypes,
      regions,
      currencies,
      highestRole
    } = this.props;

    if (!countries.length) {
      getTotalOrganizationCountries();
    }

    if (!regions.length) {
      getTotalOrganizationRegions();
    }

    if (!orgTypes.length) {
      getTotalOrganizationTypes();
    }

    if (!currencies.length) {
      getTotalCurrencies();
    }
    if (!products.consumer.length) {
      getTotalProducts();
    }
    this.setState({ formSearched: true });
  }

  componentWillUnmount() {
    this.props.clearOrganizationProfile();
  }

  handleHeaderLeft = () => {
    const { history } = this.props;
    const { formSearched } = this.state;
    return (
      <Fragment>
        <Link
          to={{
            pathname: '/admin/organization/search',
            state: { formSearched }
          }}
          onClick={() => history.replace('/admin/organization/search')}>
          <div className='organization-profile-backbutton'>
            <BackButtonIcon />
          </div>
          <div className='oragnization-profile-backtext'>
            <Typography variant='body2'>Back to Search</Typography>
          </div>
        </Link>
      </Fragment>
    );
  };

  render() {
    const {
      organization,
      countries,
      products,
      orgTypes,
      regions,
      currencies,
      getOrganizationProfile,
      match: { params },
      clearOrganizationProfile,
      history,
      userRoles,
      highestRole
    } = this.props;

    if (!organization.organizationName) {
      getOrganizationProfile(params.id);
    }

    const result =
      organization.organizationName &&
      countries.length !== 0 &&
      products.consumer.length !== 0 &&
      orgTypes.length !== 0 &&
      regions.length !== 0 &&
      currencies.length !== 0;

    const title =
      'Organization Profile Search Results - View Organization Profile';
    return (
      <PageSkeleton
        title={title}
        headerLeft={this.handleHeaderLeft()}
        data-test='edit-org-component'>
        {result ? (
          <CanActivate
            action='org-profile:edit'
            orgId={params.id}
            unAuthorizedView={
              <EditOrganization
                readOnly
                userRoles={userRoles}
                organization={organization}
                countries={countries}
                products={products}
                orgTypes={orgTypes}
                regions={regions}
                currencies={currencies}
                clearOrgProfile={clearOrganizationProfile}
                history={history}
                highestRole={highestRole}
              />
            }>
            <EditOrganization
              organization={organization}
              countries={countries}
              products={products}
              orgTypes={orgTypes}
              regions={regions}
              currencies={currencies}
              clearOrgProfile={clearOrganizationProfile}
              history={history}
              highestRole={highestRole}
            />
          </CanActivate>
        ) : (
          <Loader />
        )}
      </PageSkeleton>
    );
  }
}

export default Edit;
