import React, { Component } from 'react';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ComboboxGrid, TextFieldGrid } from '../../../components/formik';
import { decimalLength10Regex } from '../../../utils/regexes';

class FormRow extends Component {
  componentDidMount() {
    this.props.getCountriesData();
    this.props.getTypeOfFraudData();
  }

  onChange = e => {
    let value = e.target.value;
    if (value) {
      value = String(Number.isNaN(Number(value)) ? value : Number(value));
    }
    if (e.target.name === 'description') {
      if (this.props.values.fraudType.label === 'OTHER_FRAUD_AMT') {
        this.props.handleChange(e);
      }
    } else if (decimalLength10Regex.test(value)) {
      this.props.handleChange(e);
    }
  };

  render() {
    const {
      values,
      handleChange,
      handleSubmit,
      handleClear,
      countriesDropdownData,
      typeOfFraudDropdownData
    } = this.props;
    return (
      <tr className='form-row' data-test='component-dynamic-row-container'>
        <td className='country-dropdown'>
          <ComboboxGrid
            highlight
            name='country'
            label='Select/Type Country'
            placeholder=''
            options={countriesDropdownData.map(data => ({
              value: data.countryIsoCode,
              label: data.countryIsoName
            }))}
            className='intes-table-form-fields'
            onChange={handleChange}
          />
        </td>
        <td>
          <ComboboxGrid
            highlight
            name='fraudType'
            label='Select/Type'
            placeholder=''
            options={typeOfFraudDropdownData.map(data => ({
              value: data.fraudCode,
              label: data.fraudName.split('-')[1]
            }))}
            className='intes-table-form-fields'
            onChange={handleChange}
          />
        </td>
        <td>
          <TextFieldGrid
            fullWidth
            name='description'
            placeholder='Enter Description'
            disabled={Boolean(
              values.fraudType && values.fraudType.label !== 'OTHER_FRAUD_AMT'
            )}
            data-test='component-table-input-description'
            aria-label='Number of Merchant Locations at End of Period'
            className='intes-table-form-fields'
            onChange={this.onChange}
          />
        </td>
        <td>
          <TextFieldGrid
            fullWidth
            data-test='component-table-input-value'
            name='value'
            label=''
            className='intes-table-form-fields'
            inputProps={{
              style: { textAlign: 'right', color: '#022A48' }
            }}
            onChange={this.onChange}
          />
        </td>
        <td>
          <TextFieldGrid
            fullWidth
            name='casesCount'
            aria-label='Current Month Sales Volume'
            className='intes-table-form-fields'
            inputProps={{
              style: { textAlign: 'right', color: '#022A48' }
            }}
            onChange={this.onChange}
          />
        </td>

        <td className='intes-form-actions-fields'>
          <span className='add' onClick={handleSubmit}>
            <PlusIcon />
          </span>
          <span className='cross' onClick={handleClear}>
            <PlusIcon />
          </span>
        </td>
      </tr>
    );
  }
}

export default FormRow;
