import {
  GET_PARENT_ORGANIZATIONS,
  GET_CHILD_ORGANIZATIONS,
  GET_CONTRACT,
  GET_ALL_CONTRACTS,
  GET_CONTRACTS_BY_PARENT_ORGANIZATION,
  GET_EXCLUSION_TYPES,
  RESET_CHILD_ORGANIZATIONS,
  RESET_CONTRACT,
  RESET_EXCLUSION_TYPES
} from './types';

export const initialState = {
  allContracts: [],
  contractsByParentOrganization: {},
  parentOrganizations: [],
  childOrganizations: [],
  singleContract: {},
  exclusionTypesList: []
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PARENT_ORGANIZATIONS:
      return {
        ...state,
        parentOrganizations: action.payload
      };
    case GET_CHILD_ORGANIZATIONS:
      return {
        ...state,
        childOrganizations: action.payload
      };
    case GET_ALL_CONTRACTS:
      return {
        ...state,
        allContracts: action.payload
      };

    case GET_CONTRACT:
      return {
        ...state,
        singleContract: action.payload
      };
    case GET_CONTRACTS_BY_PARENT_ORGANIZATION:
      return {
        ...state,
        contractsByParentOrganization: action.payload
      };
    case GET_EXCLUSION_TYPES:
      return {
        ...state,
        exclusionTypesList: action.payload
      };

    case RESET_CHILD_ORGANIZATIONS:
      return {
        ...state,
        childOrganizations: []
      };

    case RESET_CONTRACT:
      return {
        ...state,
        singleContract: {}
      };

    case RESET_EXCLUSION_TYPES:
      return {
        ...state,
        exclusionTypesList: []
      };

    default:
      return state;
  }
};
