import React, { useState } from 'react';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-icon.svg';
import { toastFunc } from '../../../components/ToastComponent/toastFunction';
import {
  Typography,
  TableRow,
  TableCell,
  Button,
  TextField
} from '@material-ui/core';

const ProductLineList = props => {
  const { data, getAllProducts, createProducts } = props;
  const [idx, setIdx] = useState(null);
  const [editDisableChange, setEditDisableChange] = useState(false);
  const [productName, setProductName] = useState('');
  const [originalProductName, setOriginakProductName] = useState('');

  const editClick = index => {
    setIdx(index);
    const originalData = data[index];
    setEditDisableChange(true);
    setProductName(originalData.cardProductName);
    setOriginakProductName(originalData.cardProductName);
  };

  const cancelClick = () => {
    setIdx(null);
  };

  const saveClick = index => {
    const originalData = data[index];
    const updatedProductLine = {
      cardBrandCode: originalData.cardBrandCode,
      cardBrandName: originalData.cardBrandName,
      cardBusinessCode: originalData.cardBusinessCode,
      cardProductBandMapId: originalData.cardProductBandMapId,
      cardProductName: productName,
      productOperationCode: originalData.productOperationCode
    };

    createProducts(updatedProductLine).then(response => {
      if (response.status === 200) {
        toastFunc({
          content: 'New Product Updated successfully',
          config: { className: 'toast-container accept' },
          toastType: 'success'
        });
        getAllProducts();
        setIdx(null);
      } else if (response.status === 400) {
        toastFunc({
          content: `${response.data.errorMessage}`,
          config: { className: 'toast-container reject' },
          toastType: 'error'
        });
      } else {
        toastFunc({
          content: 'Something went wrong. Please try again.',
          config: { className: 'toast-container reject' },
          toastType: 'error'
        });
      }
    });
  };

  const handleProductNameChange = e => {
    e.preventDefault();

    if (e.target.value && originalProductName !== e.target.value) {
      setEditDisableChange(false);
    } else {
      setEditDisableChange(true);
    }
    setProductName(e.target.value);
  };
  return (
    <>
      {data.map((k, index) => (
        <TableRow
          key={k.cardProductBandMapId}
          className={`${idx !== null && idx === index && 'isedit'}`}
          data-test='Product-Line-Table-List'>
          <TableCell data-test='Product-Line-Table-Cell'>
            {idx !== null && idx === index ? (
              <TextField
                id={`Product_Name ${index}`}
                data-test='firstname-input'
                label='Enter Product Name'
                name='pname'
                value={
                  originalProductName !== '' ? productName : k.cardProductName
                }
                className='form-product-input product-textbox'
                onChange={event => {
                  const regex = /^[a-zA-Z ]*$/;
                  if (
                    event.target.value === '' ||
                    regex.test(event.target.value)
                  ) {
                    handleProductNameChange(event);
                  }
                }}
                onFocus={() => setOriginakProductName(k.cardProductName)}
              />
            ) : (
              <Typography data-test='product-name'>
                {k.cardProductName}
              </Typography>
            )}
          </TableCell>
          <TableCell data-test='table-product-code'>
            <Typography data-test='product-code'>
              {k.productOperationCode}
            </Typography>
          </TableCell>
          <TableCell data-test='table-product-brand'>
            <Typography data-test='product-brand'>{k.cardBrandName}</Typography>
          </TableCell>
          <TableCell align='center' data-test='product-list-table-cell'>
            <Typography data-test='product-list-table-cell-selector'>
              {idx !== null && idx === index ? (
                <>
                  <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    disabled={editDisableChange}
                    data-test='save-button'
                    onClick={() => saveClick(index)}>
                    Save
                  </Button>

                  <Button
                    color='secondary'
                    variant='outlined'
                    type='reset'
                    size='small'
                    className='user-reset-btn'
                    data-test='cancel-button'
                    onClick={cancelClick}>
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  data-test='edit-button'
                  onClick={() => editClick(index)}>
                  <EditIcon />
                </Button>
              )}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default ProductLineList;
