import React, { Component } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import './FieldValidations.css';

class FieldValidations extends Component {
  getValidatedResult = () => {
    const { validations, value, extra } = this.props;
    const result = validations.map(validation => {
      let valid = Boolean(validation.negate && value && value.length > 0);
      if (validation.negate) {
        if (value && value.search(validation.pattern) !== -1) {
          valid = false;
        }
      } else if (value && value.search(validation.pattern) !== -1) {
        valid = true;
      }
      return {
        ...validation,
        valid
      };
    });
    return [...result, ...extra];
  };

  render() {
    const { title } = this.props;
    return (
      <div
        className='field-validations'
        data-test='field-validations-component'>
        <Typography
          variant='body2'
          color='secondary'
          className='title'
          data-test='field-validations-title'>
          {title}
        </Typography>
        <List data-test='field-validations-list'>
          {this.getValidatedResult().map(validation => (
            <ListItem
              key={validation.label}
              disableGutters
              className='list-item'
              data-test='field-validations-list-item'>
              <ListItemAvatar
                className='avatar'
                data-test='field-validations-list-avatar'>
                {validation.valid ? (
                  <CheckIcon className='check' />
                ) : (
                  <CrossIcon className='cross' />
                )}
              </ListItemAvatar>
              <ListItemText
                disableTypography
                className='list-item-text'
                data-test='field-validations-list-item-text'>
                <Typography
                  variant='body2'
                  data-test='field-validations-list-body'>
                  {validation.label}
                  {validation.special && ' ('}
                  <Typography
                    variant='body2'
                    display='inline'
                    component='span'
                    color='primary'>
                    {validation.special}
                  </Typography>
                  {validation.special && ')'}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

FieldValidations.propTypes = {
  title: PropTypes.string,
  validations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      valid: PropTypes.bool.isRequired
    })
  ),
  extra: PropTypes.array
};

FieldValidations.defaultProps = {
  title: '',
  validations: [],
  extra: []
};

export default FieldValidations;
