/* eslint-disable react/no-array-index-key */
import React from 'react';

import { TableInput } from '../index';
import { integerRegex } from './../../utils/regexes';

const DataRow = ({ row, rowNumber, tableHeaders, updateFunction }) => {
  const ariaLabel = (row, col) => {
    const rowLabel = (row && row.rowLabel) || rowNumber;
    const columnLabel =
      (tableHeaders && tableHeaders[col].props.children) || col;
    return `row ${rowLabel} column ${columnLabel}`;
  };

  return row.map((col, index) => {
    return (
      <td key={index} className={col.hasError ? 'error' : ''}>
        {col.isReadOnly ? (
          <span
            data-test='component-table-body-span'
            aria-label={ariaLabel(row, index + 1)}>
            {col.value}
          </span>
        ) : (
          <TableInput
            name={`row${index}`}
            regexProp={integerRegex}
            initialValue={col.value || ''}
            row={rowNumber}
            column={index}
            aria-label={ariaLabel(row, index + 1)}
            updateFunction={updateFunction}
          />
        )}
      </td>
    );
  });
};

export default DataRow;
