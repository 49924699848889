import React from 'react';
import { Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ErrorContainer } from '../../../components';
import { landingPageText } from '../../../constants/text';
const RegistrationWarning = props => {
  const { status, luaStatus, values } = props;
  return (
    <div data-test='registration-main-container'>
      <ErrorContainer
        classStyle={luaStatus != 1 ? 'registration-error-container' : ''}
        message={status.root}
        data-test='registration-error-container'
      />
      {values.businessName.length > 0 && luaStatus != 1 && (
        <Typography
          data-test='registration-lua-warning'
          variant='h6'
          className='registration-lua-warning'>
          {luaStatus == 2 && (
            <>
              <InfoOutlinedIcon
                data-test='registration-lua-warning-icon'
                style={{ fontSize: '100%', marginRight: '4px' }}
              />
              {landingPageText.luaPassingWarning}
            </>
          )}
          {luaStatus == 3 && (
            <>
              <InfoOutlinedIcon
                data-test='registration-lua-warning-icon'
                style={{ fontSize: '100%', marginRight: '4px' }}
              />
              {landingPageText.luaWarning}
            </>
          )}
        </Typography>
      )}
    </div>
  );
};

export default RegistrationWarning;
