import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './PendingApprovals.css';
import { Grid, Typography } from '@material-ui/core';
import UserDetailsScreen from './UserDetailsScreen';
import PendingUsersList from './PendingUsersList';
import {
  PageSkeleton,
  EmptyDetailsScreen,
  Loader,
  CanActivate
} from '../../../../components';

export class PendingApprovals extends Component {
  state = {
    impersonate: ''
  };

  componentDidMount() {
    this.props.getPendingApprovalUsers();
    const impersonateData = localStorage.getItem('impersonateUserData')
      ? JSON.parse(localStorage.getItem('impersonateUserData'))
      : {};
    this.setState({
      impersonate: Boolean(Object.keys(impersonateData).length > 0)
    });
  }

  noUsers = () => {
    const { pendingApprovalUsers } = this.props;

    return (
      pendingApprovalUsers.length === 0 && (
        <Grid container data-test='pending-approval-component'>
          <Grid item md={12}>
            <Typography
              variant='h6'
              style={{ marginTop: '10px', textAlign: 'center' }}>
              No Users found
            </Typography>
          </Grid>
        </Grid>
      )
    );
  };

  pendingList = () => {
    const {
      pendingApprovalUsers,
      selectedPendingUser,
      selectPendingUser
    } = this.props;
    const { impersonate } = this.state;
    return (
      pendingApprovalUsers.length > 0 && (
        <Grid container data-test='pending-approval-component'>
          <Grid item md={3} className='pending-approval-component-left-section'>
            <PendingUsersList
              users={pendingApprovalUsers}
              selectUser={selectPendingUser}
              selectedUser={selectedPendingUser}
            />
          </Grid>
          <Grid
            item
            md={9}
            className='pending-approval-component-right-section'>
            {selectedPendingUser &&
            Object.keys(selectedPendingUser).length > 0 ? (
              <UserDetailsScreen
                selectedUser={selectedPendingUser}
                impersonate={impersonate}
              />
            ) : (
              <EmptyDetailsScreen content='Select Profile to View Details' />
            )}
          </Grid>
        </Grid>
      )
    );
  };

  render() {
    const { pendingApprovalUsers, history, roleMap } = this.props;
    const { impersonate } = this.state;
    if (!pendingApprovalUsers) {
      return <Loader data-test='pending-approval-component' />;
    }

    return (
      <CanActivate
        action='admin:createuser'
        unAuthorizedView={
          <PageSkeleton title='User Pending Approval'>
            {this.noUsers()}
            {this.pendingList()}
          </PageSkeleton>
        }>
        <PageSkeleton
          title='User Pending Approval'
          primaryButton={
            impersonate
              ? null
              : roleMap === 'Portal Admin' ||
                roleMap === 'Local User Admin' ||
                roleMap === 'User Admin'
              ? {
                  label: 'Add User',
                  onClick: () => history.push('/admin/users/create')
                }
              : null
          }>
          {this.noUsers()}
          {this.pendingList()}
        </PageSkeleton>
      </CanActivate>
    );
  }
}

PendingApprovals.propTypes = {
  getPendingApprovalUsers: PropTypes.func.isRequired,
  selectPendingUser: PropTypes.func.isRequired,
  pendingApprovalUsers: PropTypes.array,
  history: PropTypes.object.isRequired,
  selectedPendingUser: PropTypes.object
};

PendingApprovals.defaultProps = {
  pendingApprovalUsers: [],
  selectedPendingUser: {}
};

export default withRouter(PendingApprovals);
