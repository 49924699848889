import { errorTypes } from './types';

export const setGlobalError = error => ({
  type: errorTypes.GLOBAL_ERROR,
  error
});

export const setLoginFormError = error => ({
  type: errorTypes.SET_LOGIN_FORM_ERROR,
  error
});

export const resetLoginFormError = () => ({
  type: errorTypes.RESET_LOGIN_FORM_ERROR
});

export const setAnnouncementError = () => ({
  type: errorTypes.SET_ANNOUNCEMENT_ERROR
});

export const resetAnnouncementError = () => ({
  type: errorTypes.RESET_ANNOUNCEMENT_ERROR
});

export const setMyProfileError = errors => dispatch => {
  dispatch({
    type: errorTypes.SET_MY_PROFILE_ERROR,
    errors
  });
  setTimeout(() => {
    dispatch({
      type: errorTypes.RESET_MY_PROFILE_ERROR
    });
  }, 5000);
};

export const setNetworkError = () => ({
  type: errorTypes.NETWORK_ERROR
});

export const setNetworkSuccess = () => dispatch => {
  dispatch({ type: errorTypes.NETWORK_SUCCESS });
};

export const setRegistrationErrors = errors => dispatch => {
  dispatch({
    type: errorTypes.SET_REGISTRATION_ERRORS,
    errors
  });
};

export const setFormErrors = error => ({
  type: errorTypes.SET_FORM_ERRORS,
  error: {
    formErrors: {
      errorCode: error.errorCode,
      errorMessage: error.errorMessage ? error.errorMessage : '',
      errorMap: error.errorMap ? error.erroMap : {}
    }
  }
});

export const resetFormErrors = () => ({
  type: errorTypes.RESET_FORM_ERRORS,
  error: { formErrors: {} }
});

export const catchError = (error, componentName) => dispatch => {
  let errorHandler;
  switch (componentName) {
    case 'FORM':
      errorHandler = setFormErrors;
      break;

    case 'LOGIN_FORM':
      errorHandler = setLoginFormError;
      break;
    case 'ANNOUNCEMENT':
      errorHandler = setAnnouncementError;
      break;
    case 'MY_PROFILE':
      dispatch(
        setMyProfileError(
          error.response.data.errorMessage
            ? { error: error.response.data.errorMessage }
            : error.response.data.errorMap
        )
      );
      return;
    default:
      errorHandler = setGlobalError;
  }

  const { response } = error;
  if (response) {
    dispatch(errorHandler(response.data));
  }

  if (!navigator.onLine) {
    dispatch(setNetworkError());
  }
};
