/* eslint-disable max-lines-per-function */
/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import SearchIcon from '@material-ui/icons/Search';
import { format } from 'date-fns';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const tableIcons = {
  Filter: React.forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
  SortArrow: React.forwardRef(() => null),
  FirstPage: React.forwardRef((props, ref) => (
    <SkipPreviousIcon {...props} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => (
    <SkipNextIcon {...props} ref={ref} />
  )),
  NextPage: React.forwardRef((props, ref) => (
    <NavigateNextIcon {...props} ref={ref} />
  )),
  PreviousPage: React.forwardRef((props, ref) => (
    <NavigateBeforeIcon {...props} ref={ref} />
  ))
};
const FranchiseTable = props => {
  const todaysDate = new Date();
  todaysDate.setHours(0, 0, 0, 0);
  const date = format(todaysDate, 'yyyy-MM-dd HH:mm:ss');

  const statusRowModifiedDate = data => {
    if (data.statusGroup !== 'Submitted to DGN') {
      if (data.dataType === 'Monthly') {
        if (data.monthlyDueDate < date) {
          return `${data.statusGroup} !`;
        }
        return data.statusGroup;
      }
      if (data.dataType === 'Quarterly') {
        if (data.quaterlyDueDate < date) {
          return `${data.statusGroup} !`;
        }
        return data.statusGroup;
      }
      return data.statusGroup;
    }
    return data.statusGroup;
  };
  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#1A587E'
      },
      '& .MuiTableRow-root-458': {
        color: '#ffffff'
      }
    }
  });

  const columns = [
    {
      title: 'Participant',
      field: 'orgName',
      sorting: false
    },
    {
      title: 'Type',
      field: 'type',
      lookup: {
        Franchise: 'Franchise',
        Acquirer: 'Acquirer',
        Issuer: 'Issuer'
      },
      sorting: false
    },
    {
      title: 'Exchange Rate',
      field: 'exchangeRate',
      sorting: false
    },
    {
      title: 'Data Type',
      field: 'dataType',
      lookup: {
        Monthly: 'Monthly'
      },
      sorting: false
    },
    {
      title: 'Status',
      field: 'statusGroup',
      lookup: {
        'Not Started': 'Not Started',
        'Awaiting Data Entry': 'Awaiting Data Entry',
        'Awaiting submission': 'Awaiting submission',
        'Submitted to DGN': 'Submitted to DGN',
        Unlocked: 'Unlocked'
      },
      cellStyle: (e, rowData) => {
        if (rowData.statusGroup !== 'Submitted to DGN') {
          if (rowData.dataType === 'Monthly') {
            if (rowData.monthlyDueDate < date) {
              return { color: '#ff6000' };
            }
          } else if (rowData.dataType === 'Quarterly') {
            if (rowData.quaterlyDueDate < date) {
              return { color: '#ff6000' };
            }
          } else {
            return { color: '#022a48' };
          }
        } else {
          return { color: '#022a48' };
        }
      },
      render: rowData => statusRowModifiedDate(rowData),
      sorting: false
    },
    {
      title: 'Reporting Period',
      field: 'reportingDate',
      sorting: false
    }
  ];
  return (
    <ThemeProvider theme={theme} data-test='Participant-Table-provider'>
      <MaterialTable
        data-test='Participant-Table'
        columns={columns}
        data={props.franchises}
        components={{
          Toolbar: () => null
        }}
        icons={tableIcons}
        options={{
          showEmptyDataSourceMessage: true,
          showTitle: false,
          selection: false,
          search: false,
          filtering: true,
          filterCellStyle: {
            fontFamily: 'Meta Offc Pro, Arial',
            fontSize: '13px',
            fontWeight: '500',
            lineHeight: '22px',
            color: '#022a48',
            cursor: 'default !important'
          },
          headerStyle: {
            backgroundColor: '#022a48',
            color: '#ffffff',
            fontWeight: '500',
            textAlign: 'center',
            fontFamily:
              '"MetaOffcPro Normal",Helvetica,Arial,Verdana,Tahoma,sans-serif',
            cursor: 'default !important'
          },
          rowStyle: {
            fontFamily: 'Meta Offc Pro, Arial',
            fontSize: '13px',
            fontWeight: '500',
            lineHeight: '22px',
            color: '#022a48',
            cursor: 'default !important'
          }
        }}
      />
    </ThemeProvider>
  );
};
FranchiseTable.propTypes = {
  franchises: PropTypes.array.isRequired
};

export default FranchiseTable;
