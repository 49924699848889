import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Loader } from '../../../components';
import {
  setDataForms,
  changeTab,
  resetDataForms,
  uploadReviewUrl
} from './../../../store/dataForms/actions';
import Section from '../../../components/DataForms/Section';
import { slugToIDs } from '../../../constants/dataForms';
export function CardMember(props) {
  useEffect(() => {
    props.setDataForms(props.location.pathname);
    return () => {
      props.resetDataForms();
    };
  }, []);

  const { forms, tabs, activeTab, changeTab } = props;
  const formID = forms && slugToIDs[forms.formId];
  const form =
    formID === 'card-member' ||
    formID === 'card-volume' ||
    formID === 'royalty-sales-loans' ||
    formID === 'royalty-sales-volume-fees'
      ? forms.tabs[activeTab]
      : undefined;

  if (!form) {
    return (
      <Grid
        container
        item
        justify='center'
        alignItems='center'
        data-test='card-member-loader'
        data-testid='component-se-sales-volume'>
        <Loader data-test='component-loader' />
      </Grid>
    );
  }

  return (
    <div
      className={`table-wrapper ${uploadReviewUrl(props.location.pathname)}`}
      data-test='table-wrapper'>
      {tabs.length > 1 && (
        <div className='tabs' data-test='tabs'>
          {tabs.map((tab, index) => (
            <button
              key={index}
              type='button'
              data-test='CardMember-button'
              className={index === activeTab ? 'active' : ''}
              onClick={() => changeTab(index, props.location.pathname)}>
              <span data-test='span-tab-name'>{tab.name}</span>
              {tab.hasError && (
                <span data-test='error-dot' className='error-dot' />
              )}
            </button>
          ))}
        </div>
      )}
      <div className='card-member-table' data-test='card-member-table'>
        <Section
          formID={formID}
          section={form.sections[0]}
          sectionIndex={0}
          data-test='card-member-table-section'
        />
        {form.sections[1] && (
          <Section
            formID={formID}
            section={form.sections[1]}
            sectionIndex={1}
            data-test='card-member-table-section'
          />
        )}
        {form.sections[2] && (
          <Section
            formID={formID}
            section={form.sections[2]}
            sectionIndex={2}
            data-test='card-member-table-section'
          />
        )}
      </div>
    </div>
  );
}

export const mapStateToProps = state => {
  return {
    tabs:
      state.dataForms.form && state.dataForms.form.tabs
        ? state.dataForms.form.tabs.map(
            ({ tabIdentifier, tabName, hasError }) => ({
              id: tabIdentifier,
              name: tabName,
              hasError
            })
          )
        : [],
    forms: state.dataForms.form,
    activeTab: state.dataForms.activeTab
  };
};

export const mapDispatchToProps = {
  setDataForms,
  changeTab,
  resetDataForms
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CardMember));
