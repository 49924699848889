import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody
} from '@material-ui/core';
import AddOrgProfileRow from './AddOrgProfileRow';
import OrgTableRow from './OrgTableRow';
import OrgTableRowEdit from './OrgTableRowEdit';
import { connect } from 'react-redux';
import { internalUserRoles } from '../../../roles';

const OrganizationProfileTable = props => {
  const {
    orgProfiles,
    onOrgProfileAdd,
    onOrgProfileEdit,
    onOrgProfileDelete,
    onOrgProfileUpdate,
    onOrgProfileEditCancel,
    countries,
    orgTypes,
    regions,
    currencies,
    readOnly,
    add,
    userRole
  } = props;

  const sortedOrgProfiles = orgProfiles.sort((a, b) => {
    const aText = a.organizationProfileName.toUpperCase();
    const bText = b.organizationProfileName.toUpperCase();
    if (aText < bText) {
      return -1;
    }
    if (bText < aText) {
      return 1;
    }
    return 0;
  });

  const isInternalUser = internalUserRoles.includes(Object.keys(userRole)[0]);
  let headers = [
    'Organization Profile Id',
    'Organization Profile Name',
    'Organization Region',
    'Operating Country / Territory',
    'Organization Type',
    'Currency',
    'IIC Code-ISO',
    'IIC Code-DXS',
    'Active',
    'Send to Financial Force'
  ];
  if (isInternalUser === false) {
    headers = headers.filter(header => header !== 'Organization Profile Id');
  }
  return (
    <TableContainer data-test='org-profile-table-component'>
      <Table
        aria-label='organization-profile-table'
        className='organization-profile-table'>
        <TableHead>
          <TableRow className='oraganization-profile-table-tableheader'>
            {headers.map(header => (
              <TableCell key={header}>
                <Typography
                  variant='body2'
                  className={
                    isInternalUser && header === 'Organization Profile Id'
                      ? ''
                      : 'required'
                  }>
                  {header}
                </Typography>
              </TableCell>
            ))}
            {!readOnly && (
              <>
                {' '}
                <TableCell />
                <TableCell style={{ width: '100%' }} />
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!readOnly && (
            <AddOrgProfileRow
              orgProfiles={sortedOrgProfiles}
              countries={countries}
              orgTypes={orgTypes}
              regions={regions}
              currencies={currencies}
              onSubmit={onOrgProfileAdd}
              isInternalUser={isInternalUser}
            />
          )}

          {sortedOrgProfiles.length === 0 && (
            <TableRow>
              <TableCell colSpan='9' style={{ textAlign: 'center' }}>
                No Organization Profiles created
              </TableCell>
            </TableRow>
          )}

          {sortedOrgProfiles.map(org =>
            org.isEditable ? (
              <OrgTableRowEdit
                data-test='edit-mode'
                key={org.organizationProfileId}
                add={add}
                orgProfiles={sortedOrgProfiles}
                org={org}
                currencies={currencies}
                orgTypes={orgTypes}
                onCancel={onOrgProfileEditCancel}
                onSubmit={onOrgProfileUpdate}
              />
            ) : (
              <OrgTableRow
                data-test='non-edit-mode'
                key={org.organizationProfileId}
                readOnly={readOnly}
                org={org}
                onEdit={() => onOrgProfileEdit(org.organizationProfileId)}
                onDelete={() =>
                  onOrgProfileDelete(
                    org.organizationProfileId,
                    org.status !== 'A'
                  )
                }
                isInternalUser={isInternalUser}
              />
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = state => ({
  userRole: state.user.userRoleMap
});

export default connect(mapStateToProps)(OrganizationProfileTable);
