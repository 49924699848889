import React from 'react';
import { Grid } from '@material-ui/core';
import AnnouncementListing from './AnnouncementListing';

function AnnouncementList(props) {
  const {
    announcementClicked,
    announcementList,
    selectedAnnouncementId
  } = props;
  return (
    <Grid
      container
      item
      direction='column'
      xs={5}
      className='announcements-listing-container'
      style={{ marginRight: '1%' }}
      wrap='nowrap'>
      <Grid item className='announcements-list-header'>
        <p>Announcements List</p>
      </Grid>
      <div className='announcement-list-top-filler'>
        {announcementList
          ? announcementList.map((announcement, index) => (
              <AnnouncementListing
                key={index}
                announcement={announcement}
                announcementClicked={announcementClicked}
                selectedAnnouncementId={selectedAnnouncementId}
              />
            ))
          : null}
      </div>
    </Grid>
  );
}

AnnouncementList.propTypes = {};

export default AnnouncementList;
