import React, { Component } from 'react';
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody
} from '@material-ui/core';

class ContractNotesList extends Component {
  render() {
    const { contractNotes } = this.props;
    const headers = ['User Name', 'Date (dd/mm/yyyy)', 'Action', 'Notes'];

    return (
      <Grid
        container
        data-test='contract-notes-list-component'
        className='user-personal-data-component'>
        <Grid
          item
          md={12}
          className='row-header-bar'
          style={{ backgroundColor: '#1A587E' }}>
          <Typography variant='h6'>Contract Notes </Typography>
        </Grid>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: '#F2F9FC'
                }}>
                {headers.map((header, index) => (
                  <TableCell
                    key={String(header + index)}
                    style={{
                      color: '#1A587E',
                      width: (index !== 3 && '20%') || '40%'
                    }}>
                    <Typography variant='body2'>{header}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {contractNotes &&
                contractNotes.map((data, i) =>
                  data.actions.map((v, j) => (
                    <TableRow key={String(i + j)}>
                      <TableCell>
                        <Typography variant='body2'>
                          {j === 0 && data.userName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='body2'>
                          {j === 0 && data.date}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='body2'>
                          {v.actionName &&
                            v.actionName !== 'null' &&
                            v.actionName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='body2'>
                          {v.notes && v.notes !== 'null' && v.notes}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  }
}

export default ContractNotesList;
