/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { PageSkeleton } from '../../../components';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { toastFunc } from '../../../components/ToastComponent/toastFunction';
import {
  exchangeType,
  monthHeaders,
  orgHeaders
} from '../../../constants/exchangeRates';
import './ExchangeRates.css';
import RatesResultTable from './RatesResultTable';
import { ExchangeRatesForm } from './ExchangeRatesForm';

export const ExchangeRates = props => {
  const {
    values,
    dirty,
    isValid,
    handleSubmit,
    handleChange,
    exchangeRates,
    searchedRatesForm,
    resetForm,
    isLoaded,
    checkExchangeRatesLoaded,
    clearloadExchangeRates
  } = props;
  const [loadExchangeRate, setLoadExchangeRate] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);

  const clearForm = () => {
    resetForm();
    setLoadExchangeRate(false);
    setFormSubmit(false);
    clearloadExchangeRates();
  };
  const handleSubmitFormSearch = e => {
    clearloadExchangeRates();
    setFormSubmit(true);
    setLoadExchangeRate(false);
    handleSubmit(e);
    setLoadExchangeRate(false);
  };

  useEffect(() => {
    if (isLoaded === null || isLoaded === undefined) {
      checkExchangeRatesLoaded();
    }
  }, [loadExchangeRate, formSubmit]);
  return (
    <>
      <PageSkeleton title='Exchange Rates'>
        <div className='search-rates-container'>
          <ExchangeRatesForm
            data-test='search-rates-form'
            values={values}
            exchangeTypes={exchangeType}
            exchangeRates={exchangeRates}
            handleSubmit={handleSubmitFormSearch}
            handleChange={handleChange}
            isValid={isValid}
            dirty={dirty}
            resetForm={clearForm}
          />
        </div>
        {exchangeRates && (formSubmit || loadExchangeRate) && (
          <div
            data-test='search-rates-result'
            className='search-rates-container'>
            <RatesResultTable
              data-test='rates-result-test'
              headers={
                loadExchangeRate
                  ? monthHeaders
                  : exchangeRates.length === 0 ||
                    exchangeRates[0].rateId === null
                  ? orgHeaders
                  : monthHeaders
              }
              values={searchedRatesForm}
              loadExchangeRate={loadExchangeRate}
              exchangeRates={exchangeRates}
              clearForm={clearForm}
            />
          </div>
        )}
      </PageSkeleton>
    </>
  );
};

const formConfig = withFormik({
  mapPropsToValues: () => ({
    rateType: '',
    startDate: '',
    endDate: ''
  }),
  validationSchema: Yup.object().shape({
    rateType: Yup.string().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().required()
  }),
  mapPropsToStatus: () => ({}),
  handleSubmit: (values, formikbag) => {
    const rateValues = {
      rateType: values.rateType,
      startDate: values.startDate.toISOString(),
      endDate: values.endDate.toISOString()
    };
    formikbag.props
      .getExchangeRates(rateValues)
      .then(response => {
        if (response.status === 200) {
          formikbag.setStatus({ success: true });
          formikbag.props.setRatesFormData(rateValues);
        } else {
          toastFunc({
            content: 'Something went wrong. Please try again.',
            config: { className: 'toast-container reject' },
            toastType: 'error'
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
});

export default formConfig(ExchangeRates);
