import React from 'react';
import { Grid } from '@material-ui/core';
import EmptyDetailsScreen from '../../../components/EmptyDetailsScreen/EmptyDetailsScreen';

function NoSelectedAnnouncement(props) {
  return (
    <Grid
      item
      xs
      className='announcements-listing-container'
      style={{ border: 'none' }}>
      <EmptyDetailsScreen
        content='Select Announcement to View Details'
        arrowDirection='Left'
        contentVariant='h4'
      />
    </Grid>
  );
}

NoSelectedAnnouncement.propTypes = {};

export default NoSelectedAnnouncement;
